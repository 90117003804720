import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  appConfigLoading: false,
  appConfigData: {},
  appConfigError: null
});
const modifyAppConfigData = data => {
  const returnList = data;
  if (!returnList.featureConfig) {
    returnList.featureConfig = {
      lever: false
    };
  }
  return returnList;
};
export default function appConfig(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('appConfigData', 'success').type:
      return state.set('appConfigData', modifyAppConfigData(action.data));

    case createServiceAction('appConfigData', 'loading').type:
      return state.set('appConfigLoading', action.data);

    case createServiceAction('appConfigData', 'clear').type:
      return state.set('appConfigData', action.data);

    case createServiceAction('appConfigData', 'error').type:
      return state.set('appConfigError', action.data);

    default:
      return state;
  }
}
export const getAppConfigData = state => state.appConfig.get('appConfigData');
export const getAppConfigLoading = state =>
  state.appConfig.get('appConfigLoading');
export const getAppConfigError = state => state.appConfig.get('appConfigError');
