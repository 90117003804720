import React from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';

export const Indicator = ({ item }) => {
  const content = {
    display: false,
    text: ''
  };
  if (
    !isNaN(item.capacity) &&
    !isNaN(item.leverQty) &&
    item.leverQty &&
    item.capacity &&
    Number(Math.sign(item.leverQty)) !== Number(Math.sign(item.capacity))
  ) {
    content.display = true;
    content.text = 'Lever quantity sign(-/+) must match Capacity sign(-/+)';
  } else if (
    !isNaN(item.capacity) &&
    !isNaN(item.leverQty) &&
    item.leverQty &&
    item.capacity &&
    Number(Math.abs(item.leverQty)) > Number(Math.abs(item.capacity))
  ) {
    content.display = true;
    content.text = 'Lever quantity exceeds capacity';
  }
  if (item.srcDecision === 'Expired') {
    content.display = true;
    content.text = 'Lever has expired';
  }
  return (
    <div key={`indicator${Math.random() * (100000 - 10000) + 10000}`}>
      {content.display && (
        <Tooltip
          key={`indicator-tool-tip-${Math.random() * (100000 - 10000) + 10000}`}
          placement="right"
          overlay={
            <span className="c-f">
              <span className="lever-icon-text">{content.text}</span>
            </span>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <span className="lever-icon-space">
            <span className={`lever-icon  bgRed`}></span>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default Indicator;
