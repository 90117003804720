import { connect } from 'react-redux';
import React from 'react';
const Footer = () => (
  <div className="footer-container   footer">
    <div className="ncss-container footer-margin-adjust">
      <div className="ncss-row ">
        <div className="ncss-col-md-6  ncss-col-sm-12  ">
          <div className=" d-sm-h">
            <span>NIKE CAPACITY VIZ FAQ</span>
            <span className="vertical-line" />
            <span>FEEDBACK</span>
            <span className="vertical-line" />
            <span>TERMS & CONDITIONS</span> <span className="vertical-line" />
            <span>POLICES</span>
            <span className="vertical-line" />
            <span>CONTACT</span>
          </div>
        </div>
        <div className="ncss-col-md-6  ncss-col-sm-12  ta-sm-c ta-md-r">
          <span className="text-color-grey fs13-sm lh13-sm fl-sm-r">
            &copy; {new Date().getFullYear()} NIKE INC
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default connect()(Footer);
