/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'react-day-picker/lib/style.css';

export default class dayPickerFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null
    };
  }

  componentDidMount() {
    this.picker = flatpickr(this.refs.flatpickr, {
      onChange: this.onDateChanged.bind(this),
      dateFormat: 'm/d/Y',
      wrap: true
    });

    this.picker.calendarContainer.classList.add('ag-custom-component-popup');
  }

  //* ********************************************************************************
  //          METHODS REQUIRED BY AG-GRID
  //* ********************************************************************************

  getDate() {
    // ag-grid will call us here when in need to check what the current date value is hold by this
    // component.
    return this.state.date;
  }

  setDate(date) {
    // ag-grid will call us here when it needs this component to update the date that it holds.
    this.setState({ date });
    this.picker.setDate(date);
  }

  //* ********************************************************************************
  //          LINKS THE INTERNAL STATE AND AG-GRID
  //* ********************************************************************************
  onDateChanged = selectedDates => {
    const thisdate = selectedDates[0] ? new Date(selectedDates[0]) : null;

    // const newdate = moment(thisdate).format('MM/DD/YYYY');

    this.setState({ date: thisdate });
    this.updateAndNotifyAgGrid(thisdate);
  };

  updateAndNotifyAgGrid = date => {
    this.setState(
      {
        date
      },
      // Callback after the state is set. This is where we tell ag-grid that the date has changed so
      // it will proceed with the filtering and we can then expect ag-Grid to call us back to getDate
      // eslint-disable-next-line react/prop-types
      this.props.onDateChanged
    );
  };

  render() {
    // Inlining styles to make simpler the component
    return (
      <div className="ag-input-text-wrapper custom-date-filter" ref="flatpickr">
        <input type="text" data-input />
        <a className="input-button" title="clear" data-clear>
          <i className="fa fa-times" />
        </a>
      </div>
    );
  }

  //* ********************************************************************************
  //          LINKING THE UI, THE STATE AND AG-GRID
  //* ********************************************************************************
}

dayPickerFilter.defaultProps = {
  onDateChanged: PropTypes.any
};
