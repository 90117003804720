import { toast } from 'react-toastify';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const data = action.data.fields;
    const updateURL = `${apiConfig.savePreferenceData.endPointUrl}/dashboard/${action.data.dashboard}`;
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(putRequest, updateURL, data, true);
    if (res.status === 201) {
      yield put(createServiceAction(serviceType, 'success')(res));
      const message = 'User Preference Saved Succesfully';
      toast.success(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      const message = 'Not able to save the user preference';
      toast.error(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* savePreferenceDataSagas() {
  const serviceType = 'savePreferenceData';
  yield takeEvery(
    createServiceAction(serviceType, 'submited').type,
    listener,
    serviceType
  );
}

export default savePreferenceDataSagas;
