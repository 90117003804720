import authSagas from './authSagas.js';
import notificationSagas from './notificationSagas.js';
import dashboardDataSagas from './dashboardDataSagas.js';
import dashboardGridColumnsSagas from './dashboardGridColumnsSagas.js';
import updateDashboardDataSagas from './updateDashboardDataSagas.js';
import updateDashboardCommentSagas from './updateDashboardCommentSagas.js';
import adminDataSagas from './adminDataSagas.js';
import updateAdminDataSagas from './updateAdminDataSagas.js';
import savePreferenceDataSagas from './savePreferenceDataSagas.js';
import getProfileSagas from './getProfileSagas';
import userFactGrpSearchDataSagas from './userFactGrpSearchDataSagas.js';
import appConfigDataSagas from './appConfigDataSagas.js';
import userUpdatesDataSagas from './userUpdatesDataSagas.js';
import userUpdatesGridColumnsSagas from './userUpdatesGridColumnsSagas.js';
import userNotifyDataSagas from './getNotifyDataSagas';
import userProfileSettingsDataSagas from './userProfileSettingsDataSagas.js';
import updateUserProfileSettingsDataSagas from './updateUserProfileSettingsDataSagas.js';
import commentsLogsDataSagas from './commentsLogsDataSagas.js';
import updateUserUpdateDataSagas from './updateUserUpdateDataSagas.js';

import growthDimensionDataSagas from './growthDimensionDataSagas.js';
import growthDimensionGridColumnsSagas from './growthDimensionGridColumnsSagas.js';
import updateGrowthDimensionDataSagas from './updateGrowthDimensionDataSagas.js';
import growthLeverDataSagas from './growthLeverDataSagas.js';
import growthLeverGridColumnSagas from './growthLeverGridColumnSaga.js';
import updateGrowthLeverDataSagas from './updateGrowthLeverDataSagas.js';

import updateLeverAdminDataSagas from './updateLeverAdminDataSagas.js';

import updateGrowthLeverQuarterDataSagas from './updateGrowthLeverQuarterDataSagas.js';

import { fork, all } from 'redux-saga/effects';
import leverAdminDataSagas from './leverAdminDataSagas.js';
import leverLoggingDataSagas from './leverLoggingDataSagas.js';
import updateLeverCommentSaga from './updateLeverCommentSaga';
import lrcpDataSagas from './lrcpDataSagas.js';
import updateLrcpDataSagas from './updateLrcpDataSagas.js';
import addLrcpDataSaga from './addLrcpDataSaga.js';
import growthLeverQuarterDataSagas from './growthLeverQuarterDataSagas.js';
import growthLeverListSagas from './growthLeverListSagas.js';

function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(notificationSagas),
    fork(dashboardDataSagas),
    fork(dashboardGridColumnsSagas),
    fork(updateDashboardDataSagas),
    fork(updateDashboardCommentSagas),
    fork(updateLeverCommentSaga),
    fork(adminDataSagas),
    fork(leverAdminDataSagas),
    fork(leverLoggingDataSagas),
    fork(lrcpDataSagas),
    fork(updateLrcpDataSagas),
    fork(addLrcpDataSaga),
    fork(updateAdminDataSagas),
    fork(savePreferenceDataSagas),
    fork(getProfileSagas),
    fork(userFactGrpSearchDataSagas),
    fork(appConfigDataSagas),
    fork(userUpdatesDataSagas),
    fork(userUpdatesGridColumnsSagas),
    fork(userNotifyDataSagas),
    fork(userProfileSettingsDataSagas),
    fork(updateUserProfileSettingsDataSagas),
    fork(commentsLogsDataSagas),
    fork(updateUserUpdateDataSagas),
    fork(growthDimensionDataSagas),
    fork(growthLeverDataSagas),
    fork(growthLeverListSagas),
    fork(growthLeverQuarterDataSagas),
    fork(growthLeverGridColumnSagas),
    fork(growthDimensionGridColumnsSagas),
    fork(updateGrowthDimensionDataSagas),
    fork(updateGrowthLeverDataSagas),

    fork(updateLeverAdminDataSagas),

    fork(updateGrowthLeverQuarterDataSagas)
  ]);
}

export default rootSaga;
