import _ from 'lodash';

import { columnConstants } from '../constants/columnConstants.js';
import { featuresConstants } from '../constants/featuresConstants.js';
export function normalizeArray(keyProperty, array) {
  return array.reduce((result, element) => {
    result[element[keyProperty]] = element;
    return result;
  }, {});
}

export function toLowerCaseFn(theText) {
  return theText ? theText.toLowerCase() : '';
}

export function isEmpty(val) {
  return val === undefined || val == null || val.length <= 0 ? true : false;
}

export const isEquivalent = (a, b) => {
  // const aProps = Object.getOwnPropertyNames(a);
  // const bProps = Object.getOwnPropertyNames(b);
  const aProps =
    a && typeof a === 'object' ? Object.getOwnPropertyNames(a) : [];
  const bProps =
    b && typeof b === 'object' ? Object.getOwnPropertyNames(b) : [];

  if (aProps.length !== bProps.length) {
    // return false;
  }
  if (
    (aProps.length === 0 && bProps.length > 0) ||
    (aProps.length > 0 && bProps.length === 0)
  ) {
    return false;
  }
  const editableColumns = columnConstants.editableFields4AllUsers;
  let inc = 0;
  aProps.forEach((item, i) => {
    var propName = aProps[i];
    if (editableColumns.includes(propName)) {
      const aProp = isEmpty(a[propName]) ? '' : a[propName];
      const bProp = isEmpty(b[propName]) ? '' : b[propName];
      if (aProp !== bProp) {
        inc += 1;
      }
    }
  });
  if (inc > 0) {
    return false;
  }

  return true;
};

export function isSame(arrayOne, arrayTwo) {
  let a = _.uniq(arrayOne),
    b = _.uniq(arrayTwo);
  if (a.length === b.length) {
    let count = 0;
    a.forEach((ai, i) => {
      if (JSON.stringify(ai) !== JSON.stringify(b[i])) {
        count += 1;
      }
    });
    return count === 0 ? true : false;
  } else {
    return false;
  }
}

export function verifyTrial(name) {
  const code = featuresConstants.find(o => o.name === name);
  if (code && (!code.trial || code.defaultVal === localStorage.getItem(name))) {
    return false;
  } else {
    return true;
  }
}

export function numberWithCommas(num) {
  //
  if (num) {
    if (num % 1 !== 0) {
      return Number(parseFloat(num).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2
      });
    } else {
      return Number(num).toLocaleString('en', {
        minimumFractionDigits: 0
      });
    }
  }
  return num;
}

export function numberWithCommasForLevers(num) {
  // Rounding off values
  if (num) {
    return Math.round(num).toLocaleString('en', {
      minimumFractionDigits: 0
    });
  }
  return num;
}
