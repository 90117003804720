import React from 'react';

import PropTypes from 'prop-types';

export const HighLightText = ({ text, highlight }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          className={
            part.toLowerCase() === highlight.toLowerCase() ? 'c-f-500' : ''
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

HighLightText.propTypes = {
  text: PropTypes.any,
  highlight: PropTypes.any
};
export default HighLightText;
