import React from 'react';
import PropTypes from 'prop-types';

const badgeStyle = { position: 'relative' };
const Badge = ({ count, className }) =>
  count ? (
    <span style={badgeStyle} className={className}>
      <span className="badge-notification ncss-brand circle">{count}</span>
    </span>
  ) : null;

Badge.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string
};
export default Badge;
