import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import ProgressBar from './progressBar.js';
import LeverFields from './LeverFields.js';
import { numberWithCommasForLevers } from '../../util/general.js';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import LeverLoggingModal from '../../components/leverLogging/leverLogging.js';

export class LeversTabSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allocatedCapacity: 0,
      levers: [],
      progressBarParcentage: 0,
      remaningCapacity: 0,
      totalLength: 0,
      currentRowLogs: [],
      currentRowComments: [],
      commentTextArea: '',
      isCommentTextUpdating: false,
      isToggleOn: false,
      agGridQuarterParams: {}
    };

    this.storeDataHandler = this.storeDataHandler.bind(this);
    this.updateCapAndProgressbar = this.updateCapAndProgressbar.bind(this);
    this.RemCapacityCalculation = this.RemCapacityCalculation.bind(this);
    this.toggleLeverLoggingModel = this.toggleLeverLoggingModel.bind(this);
    this.uploadStatusUpdate = this.uploadStatusUpdate.bind(this);
  }

  toggleLeverLoggingModel = (quarterid, varianceCode) => {
    const { LeverLoggingModel } = this.state;
    this.setState({
      LeverLoggingModel: !LeverLoggingModel,
      commentTextArea: '',
      currentRowComments: []
    });
  };

  // update progress bar and remaning capacity
  updateCapAndProgressbar = () => {
    let { allocatedCapacity, progressBarParcentage } = this.state;
    const { totalCapacity } = this.props;
    const remaningCapacity = totalCapacity - allocatedCapacity;
    const parcentageCalculation =
      totalCapacity === 0 ? 100 : (remaningCapacity / totalCapacity) * 100;
    progressBarParcentage = (100 - parcentageCalculation).toFixed(2);
    this.setState({ ...this.state, progressBarParcentage, remaningCapacity });
  };
  // calculate remanning capacit
  RemCapacityCalculation = () => {
    let { levers, allocatedCapacity } = this.state;
    allocatedCapacity = 0;
    levers.forEach(lever => {
      allocatedCapacity =
        Number(lever.values.capacity ? lever.values.capacity : 0) +
        Number(allocatedCapacity);
    });
    this.setState(
      {
        ...this.state,
        allocatedCapacity
      },
      () => {
        this.updateCapAndProgressbar();
      }
    );
  };

  // store data in state on enter value in any filed
  storeDataHandler = ({ e, id, columnName }) => {
    const value = e.target ? e.target.value : e.value;
    const filedName = e.target ? e.target.name : columnName;
    let { levers } = this.state;
    const tempLeversArray = levers.filter(lever => {
      if (lever.leverTempId === id) {
        return (lever.values = {
          ...lever.values,
          [filedName]: value
        });
      }
      return lever;
    });
    // store levers value in same levers array in value key
    const stateFun = preState => {
      return {
        ...preState,
        levers: tempLeversArray
      };
    };
    this.setState(stateFun);
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      this.setState({
        editGrid: true,
        UploadingData: false,
        UploadModel: false
      });
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  render() {
    const {
      variance,
      factoryType,
      index,
      addVarianceLever,
      deleteVarianceLever,
      updateVarianceLever,
      varianceLever,
      userRole,
      partialQuarter,
      validationResult,
      isCommentTextUpdating,
      growthLeverQuarterId,
      isHistoryEnabled
    } = this.props;
    let sumCapacity = 0;
    if (variance && variance.leverlist && variance.leverlist.length > 0) {
      sumCapacity = variance.leverlist
        .map(item => Math.round(item.capacity))
        .reduce((prev, next) => prev + next);
    }
    const total = factoryType === 'FW' ? variance.value : variance.adjValue;
    const balance = Number(total) - sumCapacity;
    let percentage = (sumCapacity / total) * 100;
    let indicatorValue = '';
    if (total > 0) {
      indicatorValue = percentage > 100 ? 101 : percentage;
    } else {
      indicatorValue = percentage < 0 || percentage > 100 ? 101 : percentage;
    }
    let selectedLevers = [];
    if (variance.leverlist && variance.leverlist.length > 0) {
      selectedLevers = variance.leverlist.map(data =>
        data.lever ? data.lever : null
      );
    }
    const { LeverLoggingModel } = this.state;
    return (
      <div className="ncss-container">
        <div className="ncss-row ">
          <div className="ncss-col-sm-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m pl3-sm">
            <h3 className="lever-tab-heading ">
              {factoryType === 'FW' ? 'Itemized Levers' : ''}
            </h3>
          </div>
          <div className="ncss-col-sm-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
            <div className="ncss-row">
              <div className="ncss-col-sm-12  pl0-sm pr0-sm pt2-sm">
                <div className="fl-sm-r ">
                  <button
                    className="ncss-btn-black  dashboard-button"
                    onClick={() => this.toggleLeverLoggingModel(variance)}
                    disabled={!isHistoryEnabled}
                  >
                    <span className="d-sm-h d-md-ib">History</span>
                  </button>
                  <button
                    className="ncss-btn-black  dashboard-button"
                    onClick={() => addVarianceLever(index)}
                    disabled={
                      userRole !== 'FACTORY' ||
                      partialQuarter ||
                      (total || total === 0 ? false : true)
                    }
                  >
                    <span className="d-sm-h d-md-ib">Add lever</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ncss-row ">
          <div className="ncss-col-sm-12">
            <div className="ncss-col-sm-6">
              <div className="lever-tab-count-f20">
                {(total || total === 0) && (sumCapacity || sumCapacity === 0)
                  ? numberWithCommasForLevers(sumCapacity)
                  : '—'}
              </div>
              <div className="d-sm-b">
                <span className="fl-sm-l  progressBarCapCount bgCapcoler"></span>
                <span className="d-sm-ib">Total Capacity Itemized</span>
              </div>
            </div>
            <div className="ncss-col-sm-6">
              <div className="lever-tab-count-f20">
                {balance || balance === 0
                  ? numberWithCommasForLevers(balance)
                  : '—'}
              </div>
              <div className="d-sm-b">
                <span className="fl-sm-l  progressBarCapCount "></span>
                <span className="d-sm-ib">Capacity Left to Itemize</span>
              </div>
            </div>
          </div>
          <div className="ncss-col-sm-12  pt4-sm pb8-sm pl3-sm pr3-sm">
            <ProgressBar
              bgcolor={indicatorValue > 100 ? '#FF4D29' : '#3A86FF'}
              completed={percentage}
              total={total}
            />
          </div>
        </div>
        <div className={` ncss-row `}>
          <div className="ncss-col-sm-12">
            <div className="lever-quarter-entry-container">
              {variance.leverlist && variance.leverlist.length > 0 ? (
                <div className="lever-quarter-table-container">
                  <table className="lever-quarter-table">
                    <thead>
                      <tr>
                        {varianceLever &&
                          varianceLever.columns &&
                          varianceLever.columns.map(column => (
                            <th key={`p${index}-${column.field}`}>
                              {column.headerName || ''}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {variance.leverlist.map((row, i) => {
                        return (
                          <LeverFields
                            key={`row-${index}-b-${row.devIndex}`}
                            leverColumns={varianceLever.columns || []}
                            varianceCode={variance.varianceCode}
                            row={row}
                            index={index}
                            i={i}
                            userRole={userRole}
                            deleteVarianceLever={deleteVarianceLever}
                            updateVarianceLever={updateVarianceLever}
                            selectedLevers={selectedLevers}
                            partialQuarter={partialQuarter}
                            validationResult={
                              validationResult && validationResult.leverlist[i]
                            }
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="c-f-all u-full-width  u-clearfix  d-sm-flx-new  flx-jc-sm-c ">
                  <div className="flex-item p4-sm ta-sm-l flx-as-sm-c  ">
                    <h4 className="  pb2-sm u-bold "> No Levers Available</h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <LeverLoggingModal
          LeverLoggingModel={LeverLoggingModel}
          variance={variance}
          toggleLeverLoggingModel={this.toggleLeverLoggingModel}
          growthLeverQuarterId={growthLeverQuarterId}
          isCommentTextUpdating={isCommentTextUpdating}
          factoryType={factoryType}
        />
      </div>
    );
  }
}
LeversTabSection.propTypes = {
  totalCapacity: PropTypes.any,
  currentuseremail: PropTypes.string
};

export default LeversTabSection;
