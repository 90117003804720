import { toast } from 'react-toastify';
import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch usrFactGrpSearch_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'usrFactGrpSearchData')({}));

    let options = {};
    let getDashDataUrl = `${apiConfig.usrFactGrpSearch.endPointUrl}?${action.data}`;
    if (action.data.type === 'static') {
      getDashDataUrl = apiConfig.usrFactGrpSearch.endPointUrl;
      options = { dashboardType: action.data.dashboard };
    }

    // Trigger Call to API endpoint
    const res = yield call(getRequest, getDashDataUrl, options, true);
    // Parse response object
    const response = yield res.json();
    yield put(createServiceAction(serviceType, 'loading')(false));
    if (res.status === 200 || res.status === 201) {
      yield put(createServiceAction(serviceType, 'success')(response));
    } else {
      yield put(createServiceAction(serviceType, 'error')(res));
      toast.error(response, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    // Dispatch usrFactGrpSearch_LOADING action with response object
  } catch (e) {
    // Dispatch usrFactGrpSearch_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* usrFactGrpSearchSagas() {
  const serviceType = 'usrFactGrpSearchData';
  // Listen to usrFactGrpSearch_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default usrFactGrpSearchSagas;
