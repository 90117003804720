import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  leverLoggingLoading: false,
  leverLoggingData: {}
});

const modifyLoggingData = data => {
  const returnData = data.data.Items;
  let result = [];

  if (returnData) {
    returnData.sort((a, b) => b.modificationDate - a.modificationDate);

    for (let i = 0; i < returnData.length; i++) {
      const element = returnData[i];

      let object = {};
      object['modifiedUserId'] = element.modifiedUserId;
      object['modificationDate'] = element.modificationDate;
      if (element.leverInfo.comments) {
        object['comments'] = element.leverInfo.comments.comments;
      } else {
        object['comments'] = undefined;
      }
      if (element.leverInfo.variances) {
        object['leverlist'] = element.leverInfo.variances.leverlist;
      } else {
        object['leverlist'] = undefined;
      }
      object['lrcpTag'] = element.lrcpTag;
      result.push(object);
    }
  }
  return result;
};

export default function leverLogging(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('leverLoggingData', 'loading').type:
      return state.set('leverLoggingLoading', action.data);
    case createServiceAction('leverLoggingData', 'success').type:
      return state.set('leverLoggingData', modifyLoggingData(action.data));
    case createServiceAction('leverLoggingData', 'error').type:
      return state.set('leverLoggingError', action.data);
    default:
      return state;
  }
}

export const getLeverLoggingData = state =>
  state.leverLogging.get('leverLoggingData');
export const getLeverLoggingLoading = state =>
  state.leverLogging.get('leverLoggingLoading');
export const getLeverLoggingError = state =>
  state.leverLogging.get('leverLoggingError');
