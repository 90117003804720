import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { NavLink, withRouter } from 'react-router-dom';
import Timeline from 'react-timelines';
import moment from 'moment';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import history from '../../util/history.js';
import { msgConstants } from '../../constants/msgConstants.js';
import { createServiceAction } from '../../util/actionHelper.js';
import uuid from 'react-uuid';
import {
  buildElementBGColor,
  buildTimebar,
  getIsFutureEvent,
  now,
  getStartEndYear
} from './builders';
import 'react-timelines/lib/css/style.css';
import './lrcpAdmin.css';
import { Button, H3, IconButton, SingleInput } from '../../components/index.js';
import { getLrcpData } from '../../reducers/lrcpReducer.js';
import { getUserProfileData } from '../../reducers/userProfileReducer.js';
import datePickerMonthsYears from '../../components/headerSearch/datePickerMonthsYears.js';
import LrcpHistory from '../../components/lrcp/lrcpHistory.js';

const zoom = 2;
const lrcpEventDurationInMonths = 60;

export class LrcpAdmin extends Component {
  constructor(props) {
    super(props);

    const { lrcpData } = this.props;
    this.state = {
      tracks: [],
      lrcpEvents: [],
      presentEvent: null,
      lrcpData: this.getLrcpRecord(lrcpData),
      lrcpBackupData: [],
      selectedTrack: null,
      addEditLRCP: {
        eventName: '',
        effectiveDate: ''
      },
      addEditLRCPMsg: {
        eventName: '',
        effectiveDate: ''
      },
      isEditLRCPModal: false,
      isAddEditLRCPModalOpen: false,
      isSaveEnabled: false,
      timebar: buildTimebar(moment().year(), 5),
      start: moment().year(),
      end: moment().year() + 5,
      lrcpHistoryList: []
    };

    this.handleDeleteLRCPSubmitButton = this.handleDeleteLRCPSubmitButton.bind(
      this
    );
  }
  getLrcpRecord = lrcpData => (lrcpData.latest && lrcpData.latest[0]) || {};

  componentDidMount() {
    const { lrcpData } = this.state;
    const {
      requestLrcpData,
      requestUserProfileData,
      requestLrcpListData
    } = this.props;
    requestLrcpData();

    requestUserProfileData();
    this.buildTracks(lrcpData.lrcpEvents);
    this.buildTracks(lrcpData);
    requestLrcpListData(this.state);

    localStorage.setItem('isLRCPDataChanged', 'false');
  }

  componentDidUpdate(prevProps, prevState) {
    const { lrcpData } = this.state;
    if (prevState.lrcpData !== lrcpData) {
      this.buildTracks(lrcpData.lrcpEvents);
    }

    const { lrcpData: propsLrcpData } = this.props;
    if (prevProps.lrcpData !== propsLrcpData) {
      const lrcpData = this.getLrcpRecord(propsLrcpData);
      this.setState({
        lrcpData: lrcpData,
        lrcpBackupData: JSON.parse(JSON.stringify(lrcpData)),
        lrcpHistoryList: this.createLRCPHistory(propsLrcpData)
      });
    }
  }

  createLRCPHistory(lrcpHistoryList) {
    const { history } = lrcpHistoryList;
    const historyList = [];

    if (history && history.length) {
      const sortedHistory = history.sort(function(a, b) {
        return new Date(b.modifiedOn) - new Date(a.modifiedOn);
      });
      sortedHistory.forEach((item, index) => {
        let nxtHistory = history[index + 1]
          ? sortedHistory[index + 1].lrcpEvents
          : [];

        let diffArray = [];
        if (nxtHistory && nxtHistory.length > 0) {
          const arr1 = _.differenceWith(item.lrcpEvents, nxtHistory, _.isEqual);
          const arr2 = _.differenceWith(nxtHistory, item.lrcpEvents, _.isEqual);
          diffArray = _.unionBy(arr1, arr2, 'id');
        } else {
          diffArray = item.lrcpEvents;
        }
        if (diffArray && diffArray.length > 0) {
          diffArray.forEach((dItem, dindex) => {
            let hItem = {
              iterate: item.modifiedOn || item.createdOn,
              id: item.id,
              modifiedOn: item.modifiedOn || item.createdOn,
              modifiedBy: item.modifiedBy || item.createdBy,
              eventName: dItem.eventName,
              effectiveDate: moment(dItem.effectiveDate).format('MM/DD/YYYY')
            };
            hItem.action = 'Add';
            let IsExistNow = item.lrcpEvents.find(o => o.id === dItem.id);
            let IsExistEarlier = nxtHistory.find(o => o.id === dItem.id);
            if (IsExistNow) {
              if (IsExistEarlier && IsExistEarlier.id === IsExistNow.id) {
                hItem.action = 'Modify';
                if (IsExistEarlier.eventName !== IsExistNow.eventName) {
                  hItem.oldName = IsExistEarlier.eventName;
                }
                if (IsExistEarlier.effectiveDate !== IsExistNow.effectiveDate) {
                  hItem.oldDate = IsExistEarlier.effectiveDate;
                }
              }
            } else {
              hItem.action = 'Delete';
            }

            historyList.push(hItem);
          });
        }
      });
    }

    return historyList;
  }

  buildTracks = (lrcpData = []) => {
    if (!lrcpData.length) return;

    const sortedData = lrcpData.sort(function(a, b) {
      return new Date(a.effectiveDate) - new Date(b.effectiveDate);
    });
    const tracks = [
      {
        id: 'LRCP',
        title: '',
        elements: sortedData.map((i, index) => {
          const start = new Date(moment(i.effectiveDate).format());
          let end;
          const nextEvent = sortedData[index + 1];
          if (nextEvent) {
            end = new Date(
              moment(nextEvent.effectiveDate)
                .subtract('days', 1)
                .format()
            );
          } else {
            end = new Date(
              moment(i.effectiveDate)
                .add('months', lrcpEventDurationInMonths)
                .format()
            );
          }

          const elementId = `t-1-el-1-${index}`;

          const eventConfig = buildElementBGColor(start, end);

          const { selectedTrack } = this.state;
          const preparedEvent = {
            elementId: elementId,
            title: i.eventName,
            start,
            end,
            style: {
              backgroundColor: eventConfig.bgColor,
              color: '#000000',
              borderRadius: '4px',
              boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
              border:
                selectedTrack && elementId === selectedTrack.elementId
                  ? '2px solid #1b6600'
                  : null
            },
            isFutureEvent: getIsFutureEvent(start, end),
            ...i
          };

          if (eventConfig.isPresent) {
            this.setState({
              presentEvent: preparedEvent
            });
          }

          return preparedEvent;
        })
      }
    ];

    const { START_YEAR, END_YEAR, NUM_OF_YEARS } = getStartEndYear(lrcpData);

    this.setState({
      tracks,
      timebar: buildTimebar(START_YEAR, NUM_OF_YEARS || 5),
      start: START_YEAR,
      end: END_YEAR
    });
  };

  handleAddLRCP = () => {
    this.setState({
      isAddEditLRCPModalOpen: true,
      isEditLRCPModal: false
    });
  };

  handleEditLRCP = () => {
    const { selectedTrack } = this.state;
    if (selectedTrack) {
      this.setState({
        isAddEditLRCPModalOpen: true,
        isEditLRCPModal: true,
        addEditLRCP: {
          eventName: selectedTrack.title,
          effectiveDate: selectedTrack.start
        }
      });
    }
  };

  resetAddEditLRCP = (eventName = '', effectiveDate = '') => {
    this.setState({
      addEditLRCP: {
        eventName,
        effectiveDate
      }
    });
  };

  handleEventNameChange = e => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    const { addEditLRCP } = this.state;
    const addEditLRCPMsg = {
      eventName: '',
      effectiveDate: ''
    };
    if (value && value.length > 30) {
      addEditLRCPMsg.eventName = 'Name should not exceed 30 characters';
      this.setState({
        addEditLRCPMsg
      });
    } else {
      this.setState({
        addEditLRCPMsg,
        addEditLRCP: {
          ...addEditLRCP,
          eventName: value
        }
      });
    }
  };

  handleEffectiveDateChange = value => {
    const { addEditLRCP } = this.state;
    this.setState({
      addEditLRCP: {
        ...addEditLRCP,
        effectiveDate: value
      }
    });
  };

  validateLRCPForm = () => {
    const { addEditLRCP, tracks, selectedTrack } = this.state;
    const { eventName, effectiveDate } = addEditLRCP;

    if (!eventName) {
      toast.error('Please enter Event Name.');
      return false;
    } else if (!effectiveDate) {
      toast.error('Please enter Start Date.');
      return false;
    }

    const event =
      tracks[0] &&
      tracks[0].elements &&
      tracks[0].elements.find(
        item =>
          (selectedTrack && selectedTrack.id) !== item.id &&
          (item.eventName === eventName ||
            moment(item.start, 'YYYY/MM/DD').diff(
              moment(effectiveDate, 'YYYY/MM/DD')
            ) === 0)
      );

    if (event) {
      if (event.eventName === eventName) {
        toast.error('Event Name already exists.');
      } else if (
        moment(event.start, 'YYYY/MM/DD').diff(
          moment(effectiveDate, 'YYYY/MM/DD')
        ) === 0
      ) {
        toast.error('Event already exists on the given date.');
      }

      return false;
    }

    return true;
  };

  handleModifyLrcpEvents = newList => {
    const { lrcpData } = this.state;
    this.setState({
      lrcpData: {
        ...lrcpData,
        lrcpEvents: newList.sort(
          (a, b) =>
            moment(a.effectiveDate).unix() - moment(b.effectiveDate).unix()
        )
      }
    });
  };

  handleAddLRCPSubmitButton = () => {
    if (this.validateLRCPForm()) {
      const { addEditLRCP, lrcpData } = this.state;
      const { userProfileData } = this.props;
      const { eventName, effectiveDate } = addEditLRCP;

      if (lrcpData.lrcpEvents) {
        const newLrcpDataEvent = {
          eventName,
          effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
          createdBy: userProfileData.userId,
          createdOn: moment().toISOString(),
          id: uuid()
        };

        localStorage.setItem('isLRCPDataChanged', 'true');

        this.handleModifyLrcpEvents([...lrcpData.lrcpEvents, newLrcpDataEvent]);
      }

      setTimeout(() => {
        this.handleActiveSaveButton();
        this.handleAddEditLRCPModalClose();
      }, 100);
    }
  };

  handleDeleteLRCPSubmitButton = () => {
    const { lrcpData, selectedTrack } = this.state;
    if (lrcpData.lrcpEvents && selectedTrack) {
      const filteredList = lrcpData.lrcpEvents.filter(item => {
        return item.effectiveDate !== selectedTrack.effectiveDate;
      });

      localStorage.setItem('isLRCPDataChanged', 'true');
      this.setState(
        {
          lrcpData: {
            ...lrcpData,
            lrcpEvents: filteredList
          },
          selectedTrack: null
        },
        () => {
          setTimeout(() => {
            this.handleActiveSaveButton();
          }, 100);
        }
      );
    }
  };

  handleEditLRCPSubmitButton = () => {
    if (this.validateLRCPForm()) {
      const { addEditLRCP, selectedTrack, lrcpData } = this.state;
      // const { userProfileData } = this.props;
      const { eventName, effectiveDate } = addEditLRCP;

      if (lrcpData.lrcpEvents) {
        const lrcpEvents = [...lrcpData.lrcpEvents];
        const foundItem = lrcpEvents.find(item => {
          return item.effectiveDate === selectedTrack.effectiveDate;
        });
        if (
          eventName !== foundItem.eventName ||
          foundItem.effectiveDate !== moment(effectiveDate).format('YYYY-MM-DD')
        ) {
          foundItem.eventName = eventName;
          foundItem.effectiveDate = moment(effectiveDate).format('YYYY-MM-DD');

          localStorage.setItem('isLRCPDataChanged', 'true');
          this.handleModifyLrcpEvents(lrcpEvents);
        }
      }
      this.handleActiveSaveButton();
      this.handleAddEditLRCPModalClose();
    }
  };

  handleActiveSaveButton = () => {
    const { lrcpData, lrcpBackupData } = this.state;

    if (lrcpData.lrcpEvents && lrcpBackupData.lrcpEvents) {
      const newList = lrcpData.lrcpEvents.sort(function(a, b) {
        return new Date(b.modifiedOn) - new Date(a.modifiedOn);
      });
      const oldList = lrcpBackupData.lrcpEvents.sort(function(a, b) {
        return new Date(b.modifiedOn) - new Date(a.modifiedOn);
      });
      const isSaveEnabled = _.isEqual(newList, oldList);
      this.setState({
        isSaveEnabled: !isSaveEnabled
      });
    }
  };

  handleAddEditLRCPModalClose = () => {
    const { lrcpData } = this.state;
    this.setState(
      {
        isAddEditLRCPModalOpen: false,
        selectedTrack: null
      },
      () => {
        this.buildTracks(lrcpData.lrcpEvents);

        this.resetAddEditLRCP();
      }
    );
  };

  handleSaveChanges = () => {
    const { lrcpData } = this.state;
    const { updateLrcpData, userProfileData } = this.props;
    lrcpData.modifiedBy = userProfileData.userId;
    lrcpData.modifiedOn = moment().toISOString();
    if (lrcpData.lrcpEvents) {
      updateLrcpData(lrcpData);
      this.setState({
        isSaveEnabled: false
      });
    }
    localStorage.setItem('isLRCPDataChanged', 'false');
  };

  handleElementClick = (value, a) => {
    const { tracks, lrcpData, selectedTrack } = this.state;
    if (value.isFutureEvent && lrcpData.lrcpEvents) {
      const newSelectedTrack =
        tracks[0] &&
        tracks[0].elements &&
        tracks[0].elements.find(t => t.elementId === value.elementId);
      if (
        !selectedTrack ||
        (selectedTrack && selectedTrack.id !== newSelectedTrack.id)
      ) {
        this.setState({ selectedTrack: newSelectedTrack }, () => {
          this.buildTracks(lrcpData.lrcpEvents);
        });
      } else {
        this.setState({ selectedTrack: null }, () => {
          this.buildTracks(lrcpData.lrcpEvents);
        });
      }
    }
  };

  render() {
    const {
      tracks,
      timebar,
      selectedTrack,
      isEditLRCPModal,
      isAddEditLRCPModalOpen,
      addEditLRCP,
      start,
      end,
      lrcpHistoryList,
      isSaveEnabled,
      addEditLRCPMsg
    } = this.state;
    const { match } = this.props;
    const { DC: pageType } = match.params;

    const scaleStart = new Date(`${start}`);
    const scaleEnd = new Date(`${end}`);
    const customStyles = {
      content: {
        maxWidth: '500px',
        display: 'flex',
        marginRight: 'auto',
        marginLeft: 'auto',
        minHeight: '425px'
      }
    };
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      <div className="page-align-items">
        <div>
          <div className="ncss-container u-clearfix ">
            <div className="ncss-row page-header pb0-sm pb2-md">
              <div className="ncss-col-lg-6 ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                <h3 className="pt3-sm main-heading c-f-500">
                  <span>
                    {pageType === 'AP' ? 'Apparel ' : 'Footwear '} LRCP Event
                    Management
                  </span>
                </h3>
              </div>
              <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm pb1-md ">
                <Button
                  onClick={() => this.handleSaveChanges()}
                  extraClassName="ncss-btn-black u-uppercase dashboard-button"
                  disabled={!isSaveEnabled}
                >
                  Save
                </Button>
              </div>
            </div>

            <div className="ncss-row page-menu-header">
              <NavLink
                className="menuOpt1Item"
                to={`/admin/manageView/${pageType}`}
                onClick={e => {
                  e.preventDefault();
                  if (localStorage.getItem('isLRCPDataChanged') === 'true') {
                    confirmAlert({
                      message: msgConstants.pageChangeAlert,
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            localStorage.setItem('isLRCPDataChanged', 'false');
                            history.push(`/admin/manageView/${pageType}`);
                          }
                        },
                        {
                          label: 'No',
                          onClick: () => {}
                        }
                      ]
                    });
                  } else {
                    history.push(`/admin/manageView/${pageType}`);
                  }
                }}
              >
                <span className="text">Capacity</span>
              </NavLink>

              <NavLink
                className="menuOpt1Item"
                to={`/admin/manageLevers/${pageType}`}
                onClick={e => {
                  e.preventDefault();
                  if (localStorage.getItem('isLRCPDataChanged') === 'true') {
                    confirmAlert({
                      message: msgConstants.pageChangeAlert,
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            localStorage.setItem('isLRCPDataChanged', 'false');
                            history.push(`/admin/manageLevers/${pageType}`);
                          }
                        },
                        {
                          label: 'No',
                          onClick: () => {}
                        }
                      ]
                    });
                  } else {
                    history.push(`/admin/manageLevers/${pageType}`);
                  }
                }}
              >
                <span className="text">Levers</span>
              </NavLink>

              <NavLink
                className="menuOpt1Item active"
                to={`/admin/manageLrcp/${pageType}`}
                onClick={e => {
                  e.preventDefault();
                  if (localStorage.getItem('isLRCPDataChanged') === 'true') {
                    confirmAlert({
                      message: msgConstants.pageChangeAlert,
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            localStorage.setItem('isLRCPDataChanged', 'false');
                            history.push(`/admin/manageLevers/${pageType}`);
                          }
                        },
                        {
                          label: 'No',
                          onClick: () => {}
                        }
                      ]
                    });
                  } else {
                    history.push(`/admin/manageLevers/${pageType}`);
                  }
                }}
              >
                <span className="text">LRCP</span>
              </NavLink>
            </div>
            <div className="bg-white c-f-border-5 p3-sm mb5-sm">
              <div className="ncss-row">
                <div className="ncss-col-sm-12 ncss-col-md-12 ncss-col-lg-12 va-sm-t">
                  <div className="adminPanel c-f-all">
                    <div>
                      <div className="va-sm-t open-sans-c u-bold ">
                        <div>
                          <div className="ncss-col-sm-4 va-sm-t open-sans-c u-bold"></div>

                          <div className="ncss-col-sm-8">
                            <button
                              onClick={() => this.handleAddLRCP()}
                              className="fl-sm-r c-f ncss-btn-primary-dark"
                            >
                              <i className="fas fa-plus" /> Add New
                            </button>

                            <button
                              onClick={() => this.handleEditLRCP()}
                              className="fl-sm-r c-f ncss-btn-primary-dark"
                              style={{ marginRight: '8px' }}
                              disabled={!selectedTrack}
                            >
                              <i className="fas fa-edit" /> Edit Selected
                            </button>
                            <button
                              onClick={() =>
                                this.handleDeleteLRCPSubmitButton()
                              }
                              className="fl-sm-r c-f ncss-btn-primary-dark"
                              style={{ marginRight: '8px' }}
                              disabled={!selectedTrack}
                            >
                              <i class="fas fa-trash"></i> Delete Selected
                            </button>
                          </div>
                          <Modal
                            isOpen={isAddEditLRCPModalOpen}
                            id="add-lrcp-modal"
                            overlayClassName="modal-overlay"
                            ariaHideApp={false}
                            style={customStyles}
                          >
                            <IconButton
                              icon="times"
                              className="modal-close-btn"
                              onClick={() => this.handleAddEditLRCPModalClose()}
                            />
                            <div className="border">
                              <H3 className="pt2-sm pb4-sm">
                                <span className="line-up">
                                  {isEditLRCPModal ? 'Edit LRCP' : 'Add LRCP'}
                                </span>
                              </H3>

                              <div className="ncss-container">
                                <div className="ncss-row">
                                  <div className="ncss-col-sm-4">
                                    Event Name
                                  </div>
                                  <div className="ncss-col-sm-6">
                                    <SingleInput
                                      inputType="text"
                                      title=""
                                      name="eventName"
                                      controlFunc={e =>
                                        this.handleEventNameChange(e)
                                      }
                                      content={addEditLRCP.eventName}
                                      className={`validation-text-field`}
                                      placeholder="Event Name"
                                    />
                                  </div>
                                </div>
                                <div className="ncss-row pt2-sm">
                                  <div className="ncss-col-sm-10 ta-sm-r text-color-grey ">
                                    <small> {addEditLRCPMsg.eventName}</small>
                                  </div>
                                </div>
                                <div className="ncss-row pt2-sm">
                                  <div className="ncss-col-sm-4">
                                    Effective Date
                                  </div>
                                  <div className="ncss-col-sm-6 lrcpDatePicker">
                                    <DatePicker
                                      selected={addEditLRCP.effectiveDate || ''}
                                      dateFormat="MM/dd/yyyy"
                                      onChange={date => {
                                        this.handleEffectiveDateChange(date);
                                      }}
                                      onChangeRaw={e => e.preventDefault()} // to make it uneditable
                                      isClearable // to clear the date
                                      placeholderText={'MM/DD/YYYY'}
                                      renderCustomHeader={datePickerMonthsYears} // months and year dropDown
                                      minDate={tomorrow}
                                    />
                                  </div>
                                </div>
                                <div className="ncss-row pt4-sm pb4-sm modal-footer">
                                  <div className="ncss-col-sm-12">
                                    {isEditLRCPModal ? (
                                      <button
                                        onClick={() =>
                                          this.handleEditLRCPSubmitButton()
                                        }
                                        className="c-f ncss-btn-primary-dark"
                                      >
                                        Update
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          this.handleAddLRCPSubmitButton()
                                        }
                                        className="c-f ncss-btn-primary-dark"
                                      >
                                        Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="ncss-row">
                                  <div className="ncss-col-sm-12 text-color-error border-top-light-grey pt4-sm mt4-sm pb8-sm">
                                    <strong> Note : </strong> Add / Update will
                                    modifies only in Interface all changes needs
                                    Save Action to reflect in Levers
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        </div>
                        <Timeline
                          scale={{
                            start: scaleStart,
                            end: scaleEnd,
                            zoom
                          }}
                          clickElement={this.handleElementClick}
                          timebar={timebar}
                          tracks={tracks}
                          now={new Date(now.format())}
                          scrollToNow
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {lrcpHistoryList && lrcpHistoryList.length > 0 && (
              <div className="bg-white c-f-border-5 p3-sm mb5-sm">
                <LrcpHistory historyData={lrcpHistoryList} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

LrcpAdmin.propTypes = {};

const mapStateToProps = state => ({
  lrcpData: getLrcpData(state),
  userProfileData: getUserProfileData(state)
});
const mapDispatchToProps = dispatch => ({
  requestLrcpData: status => {
    // Get data to show on timeline
    dispatch(createServiceAction('lrcpData', 'requested')(status));
  },
  requestLrcpListData: status => {
    // Get data to show on timeline
    dispatch(createServiceAction('lrcpListData', 'requested')(status));
  },
  updateLrcpData: status => {
    // Update lrcp data
    dispatch(createServiceAction('updateLrcpData', 'submited')(status));
  },
  requestUserProfileData: () => {
    // get current user data
    dispatch(createServiceAction('getUserProfile', 'requested')());
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LrcpAdmin)
);
