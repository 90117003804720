import { createElement } from 'react';

const defaultClasses = {
  1: 'fs30-sm lh30-sm fs30-md lh30-md',
  2: 'fs20-sm lh20-sm fs28-md lh28-md fs34-lg lh34-lg',
  3: 'fs20-sm lh20-sm fs24-md lh24-md fs28-lg lh28-lg',
  4: 'fs16-sm lh16-sm',
  5: '',
  6: ''
};

function header(size) {
  return ({ className = '', useBareClass, children, ...props }) =>
    createElement(
      `h${size}`,
      {
        ...props,
        className: useBareClass
          ? className
          : `${defaultClasses[size]} ${className}`
      },
      children
    );
}

export const H1 = header(1);
export const H2 = header(2);
export const H3 = header(3);
export const H4 = header(4);
export const H5 = header(5);
export const H6 = header(6);
