import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  userNotifyLoading: false,
  userNotifyData: {},
  userNotifyError: {}
});

export default function userNotify(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('userNotifyData', 'success').type:
      return state.set('userNotifyData', action.data);

    case createServiceAction('userNotifyData', 'loading').type:
      return state.set('userNotifyLoading', action.data);

    case createServiceAction('userNotifyData', 'clear').type:
      return state.set('userNotifyData', {});

    case createServiceAction('userNotifyData', 'error').type:
      return state.set('userNotifyError', action.data);

    default:
      return state;
  }
}
export const getUserNotifyData = state =>
  state.userNotify.get('userNotifyData');
export const getUserNotifyLoading = state =>
  state.userNotify.get('userNotifyLoading');
export const getUserNotifyError = state =>
  state.userNotify.get('userNotifyError');
