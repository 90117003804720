import { MONTHS_PER_YEAR } from './constants';

export const addMonthsToYear = (year, monthsToAdd) => {
  let y = year;
  let m = monthsToAdd;
  while (m >= MONTHS_PER_YEAR) {
    m -= MONTHS_PER_YEAR;
    y += 1;
  }
  return { year: y, month: m + 1 };
};

export const addMonthsToYearAsDate = (year, monthsToAdd) => {
  const r = addMonthsToYear(year, monthsToAdd);
  return new Date(`${r.year}-${r.month}`);
};
