import { fromJS } from 'immutable';
import * as moment from 'moment';
import _ from 'lodash';
import { createServiceAction } from '../util/actionHelper.js';
import { growthDimensionGridConfigure } from '../util/growthDimensionGridConfigure.js';

const defaultState = fromJS({
  growthDimensionDataLoading: false,
  growthDimensionGridLoading: false,
  growthDimensionDataUpdate: {}
});
const modifyGrowthDimensionCapHeader = headerResponse => {
  const returnArray = headerResponse;
  if (returnArray.reportColumns && returnArray.reportColumns.length > 0) {
    const changeArr = [
      'StrQMaxCapVariance',
      'StrQMaxCapAdjVariance',
      'RvsdQMaxCapVariance',
      'RvsdQMaxCapAdjVariance'
    ];
    returnArray.reportColumns.forEach((c, ci) => {
      if (c.children && c.children.length > 0) {
        c.children.forEach((d, di) => {
          if (d.children && d.children.length > 0) {
            d.children.forEach((l, li) => {
              //
              l.field = l.field.replace('StrQ', 'CapQ');
              l.field = l.field.replace('RvsdQ', 'CapQ');
            });
            //
          } else if (d.field) {
            d.field = d.field.replace('StrQ', 'CapQ');
            d.field = d.field.replace('RvsdQ', 'CapQ');
          }
        });
        c.headerName = c.headerName.replace('Strat QOQ', 'Cap QOQ');
        c.headerName = c.headerName.replace('Rvsd QOQ', 'Cap QOQ');
        //  c.headerName = c.headerName.replace('GBL - ', 'Cap - ');
        //
      } else if (changeArr.includes(c.field)) {
        c.field = c.field.replace('StrQ', 'CapQ');
        c.field = c.field.replace('RvsdQ', 'CapQ');
        c.headerName = c.headerName.replace('STRAT ', '');
        c.headerName = c.headerName.replace('RVSD ', '');
      }
    });
  }
  return returnArray;
};
const modifyGrowthDimensionHeader = headerResponse => {
  const returnArray = headerResponse;
  const capResp = modifyGrowthDimensionCapHeader(headerResponse);
  returnArray.columns = growthDimensionGridConfigure(capResp);
  return returnArray;
};
const modifyGrowthDimensionData = growthDimensionDataResponse => {
  const dataResponse = growthDimensionDataResponse.growthDimensionData;

  const { data, pagination } = dataResponse;

  if (data) {
    data.forEach(dataItem => {
      const momentdate =
        moment(dataItem.weekStartDate).format('MM/DD/YYYY') || '';
      dataItem.weekStartDate = Date.parse(momentdate);
      Object.keys(dataItem).forEach(key => {
        if (key.endsWith('Cap') || key.endsWith('Cost')) {
          if (
            dataItem[key] === 0 ||
            (dataItem[key] && dataItem[key].toString().length > 0)
          ) {
            dataItem[key] = Number(dataItem[key]);
          }
        }
        dataItem.ChangeByUpload = 0;
        dataItem.colModified = [];
      });
    });
  }
  if (pagination) {
    if (
      pagination.back &&
      Object.keys(pagination.back).length > 0 &&
      (pagination.back.count === null || pagination.back.count === 'null')
    ) {
      pagination.back = {};
    }
    if (
      pagination.next &&
      Object.keys(pagination.next).length > 0 &&
      (pagination.back.next === null || pagination.next.count === 'null')
    ) {
      pagination.back = {};
    }
  }

  dataResponse.data = _.orderBy(
    data,
    [
      function(item) {
        return item.factoryCode.a;
      },
      function(item) {
        return item.capacityTypeCode.b;
      },
      function(item) {
        return item.weekStartDate.b;
      }
    ],
    ['asc', 'asc', 'asc']
  );
  dataResponse.pagination = pagination;
  dataResponse.time = new Date().getTime();
  return dataResponse;
};

const modifyFactGrpSearchData = resp => {
  let retArr = [];
  let setArray = [];
  if (resp) {
    if (Array.isArray(resp)) {
      if (resp.length > 0) {
        resp.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    } else {
      let cArr = resp.capacities;
      if (cArr.length > 0) {
        cArr.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    }
  }
  if (setArray.length > 0) {
    setArray.forEach((icc, i) => {
      let newCode = {
        name: icc.capacityTypeCode,
        value: icc.capacityTypeCode,
        key: icc.capacityTypeCode,
        label: icc.capacityTypeCode, //+ ' (' + icc.factoryCode + ')',
        fc: icc.factoryCode || '',
        id: `${i + 1000}`
      };
      retArr.push(newCode);
      //  retArr = [...retArr, ...newCode];
    });
  }

  const retUnQ = _.uniqBy(retArr, 'key'); //retArr; //
  let retUnAll = [];
  if (retUnQ.length > 1) {
    const firstItem = {
      name: 'ALL',
      value: '',
      key: 'ALL',
      label: 'ALL',
      id: 10001
    };
    if (resp.length === 1) {
      firstItem.key = `All${resp[0].factoryCode}`;
      firstItem.name = `All ${resp[0].factoryCode}`;
      firstItem.label = `All ${resp[0].factoryCode}`;
    }
    retUnAll = [firstItem, ...retUnQ];
  } else {
    retUnAll = retUnQ;
  }
  return retUnAll;
};

export default function growthDimension(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('growthDimensionData', 'loading').type:
      return state.set('growthDimensionDataLoading', action.data);

    case createServiceAction('growthDimensionData', 'success').type:
      return state.set(
        'growthDimensionData',
        modifyGrowthDimensionData(action.data)
      );

    case createServiceAction('growthDimensionData', 'clear').type:
      return state.set('growthDimensionData', action.data);

    case createServiceAction('growthDimensionData', 'error').type:
      return state.set('growthDimensionError', action.data);

    case createServiceAction('growthDimensionGridColumns', 'loading').type:
      return state.set('growthDimensionGridLoading', action.data);

    case createServiceAction('growthDimensionGridColumns', 'success').type:
      return state.set(
        'growthDimensionGridColumns',
        modifyGrowthDimensionHeader(action.data)
      );

    case createServiceAction('growthDimensionGridColumns', 'clear').type:
      return state.set('growthDimensionGridColumns', action.data);

    case createServiceAction('growthDimensionGridColumns', 'error').type:
      return state.set('growthDimensionGridError', action.data);

    case createServiceAction('growthDimensionDataUpdate', 'success').type:
      return state.set('growthDimensionDataUpdate', action.data);

    case createServiceAction('growthDimensionDataUpdate', 'error').type:
      return state.set('growthDimensionDataUpdate', action.data);

    case createServiceAction('growthDimensionDataUpdate', 'clear').type:
      return state.set('growthDimensionDataUpdate', action.data);

    case createServiceAction('updateGrowthDimensionComment', 'success').type:
      return state.set('onUpdateGrowthDimensionComment', action.data);

    case createServiceAction('testDetails', 'success').type:
      return state.set('testDetailData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'loading').type:
      return state.set('usrFactGrpSearchLoading', action.data);

    case createServiceAction('usrFactGrpSearchData', 'success').type:
      return state.set(
        'usrFactGrpSearchData',
        modifyFactGrpSearchData(action.data)
      );

    case createServiceAction('usrFactGrpSearchData', 'clear').type:
      return state.set('usrFactGrpSearchData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'error').type:
      return state.set('usrFactGrpSearchError', action.data);

    default:
      return state;
  }
}

export const getGrowthDimensionDataLoading = state =>
  state.growthDimension.get('growthDimensionDataLoading');
export const getGrowthDimensionData = state =>
  state.growthDimension.get('growthDimensionData');
export const getGrowthDimensionError = state =>
  state.growthDimension.get('growthDimensionError');

export const getGrowthDimensionGridLoading = state =>
  state.growthDimension.get('growthDimensionGridLoading');
export const getGrowthDimensionGridError = state =>
  state.growthDimension.get('growthDimensionGridError');
export const getGrowthDimensionGridColumns = state =>
  state.growthDimension.get('growthDimensionGridColumns');

export const getusrFactGrpSearchLoading = state =>
  state.growthDimension.get('usrFactGrpSearchLoading');
export const getusrFactGrpSearchError = state =>
  state.growthDimension.get('usrFactGrpSearchError');
export const getusrFactGrpSearchData = state =>
  state.growthDimension.get('usrFactGrpSearchData');

export const updateGrowthDimensionData = state =>
  state.growthDimension.get('growthDimensionDataUpdate');

export const getGrowthDimensionDataUpdate = state =>
  state.growthDimension.get('growthDimensionDataUpdate');

export const updateGrowthDimensionComment = state =>
  state.growthDimension.get('updateGrowthDimensionComment');

export const getUpdateGrowthDimensionCommentStatus = state =>
  state.growthDimension.get('onUpdateGrowthDimensionComment');

export const getTestDetailData = state =>
  state.growthDimension.get('testDetailData');
