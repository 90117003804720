import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper';

const defaultState = fromJS({
  growthLeverListData: {},
  growthLeverListDataLoading: false,
  growthLeverListDataUpdate: {}
});

const modifyGrowthLeverListData = data => {
  let editableFields = [];
  if (data && data.role) {
    if (data.role === 'WHQ') {
      editableFields = ['srcDecision', 'leverQty', 'comment'];
    } else if (data.role === 'FACTORY') {
      editableFields = [
        'lever',
        'capacity',
        'cost',
        'validityDate',
        'comment',
        'action'
      ];
    }
  }
  const Decision = [
    { id: 22, label: 'TBD', value: 'TBD' },
    { id: 23, label: 'Yes', value: 'Yes' },
    { id: 24, label: 'No', value: 'No' },
    { id: 25, label: 'Deferred', value: 'Deferred' }
  ];

  const retData = [];
  if (data && data.variancecolumns && data.variancecolumns.length > 0) {
    data.variancecolumns.forEach((item, i) => {
      const varianceCols = [];
      const leversList = [];
      if (item.CategoryList && item.CategoryList.length > 0) {
        item.CategoryList.forEach(category => {
          if (category.dimensionList && category.dimensionList.length > 0) {
            category.dimensionList.forEach(dimension => {
              if (dimension.leverList && dimension.leverList.length > 0) {
                dimension.leverList.forEach((lever, index) => {
                  const lev = {
                    id: index,
                    label: lever.leverName,
                    name: lever.leverName,
                    category: category.categoryName,
                    dimension: dimension.dimensionName,
                    leverName: lever.leverName,
                    leverCode: lever.leverCode
                  };
                  if (
                    category.categoryStatus &&
                    dimension.dimensionStatus &&
                    lever.leverVisible
                  ) {
                    leversList.push(lev);
                  }
                });
              }
            });
          }
        });
      }
      data.leverColumns.forEach((leverCol, j) => {
        const newLevCol = leverCol;
        newLevCol.editable = editableFields.includes(leverCol.field);
        if (['lever', 'srcDecision'].includes(leverCol.field)) {
          newLevCol.inputType = 'typeahead';

          if (leverCol.field === 'lever') {
            newLevCol.options = leversList;
          } else if (leverCol.field === 'srcDecision') {
            newLevCol.options = Decision;
            newLevCol.inputType = 'select';
          }
        } else if (['capacity', 'cost', 'leverQty'].includes(leverCol.field)) {
          newLevCol.inputType = 'number';
        } else if (['validityDate'].includes(leverCol.field)) {
          newLevCol.inputType = 'date';
        } else if (['comment'].includes(leverCol.field)) {
          newLevCol.inputType = 'text';
        }
        varianceCols.push(newLevCol);
      });

      const variance = {
        headerName: item.headerName,
        field: item.field,
        columns: varianceCols
      };

      retData.push(variance);
    });
  }
  return { varianceList: retData, role: data.role };
};
export default function growthLeverList(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('growthLeverListData', 'loading').type:
      return state.set('growthLeverListDataLoading', action.data);

    case createServiceAction('growthLeverListData', 'success').type:
      return state.set(
        'growthLeverListData',
        modifyGrowthLeverListData(action.data)
      );

    case createServiceAction('growthLeverListData', 'clear').type:
      return state.set('growthLeverListData', action.data);

    case createServiceAction('growthLeverListData', 'error').type:
      return state.set('growthLeverListError', action.data);

    case createServiceAction('growthLeverListDataUpdate', 'success').type:
      return state.set('growthLeverListDataUpdate', action.data);

    case createServiceAction('growthLeverListDataUpdate', 'error').type:
      return state.set('growthLeverListDataUpdate', action.data);

    case createServiceAction('growthLeverListDataUpdate', 'clear').type:
      return state.set('growthLeverListDataUpdate', action.data);

    default:
      return state;
  }
}

export const getGrowthLeverListDataLoading = state =>
  state.growthLeverList.get('growthLeverListDataLoading');
export const getGrowthLeverListData = state =>
  state.growthLeverList.get('growthLeverListData');
export const getGrowthLeverListError = state =>
  state.growthLeverList.get('growthLeverListError');
export const updateGrowthLeverListData = state =>
  state.growthLeverList.get('growthLeverListDataUpdate');

export const getGrowthLeverListDataUpdate = state =>
  state.growthLeverList.get('growthLeverListDataUpdate');
