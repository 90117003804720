import { fromJS } from 'immutable';
import {
  startAuthCheck,
  finishAuthCheck,
  setUserLogin,
  setAuthCheckError
} from '../actions/authActions.js';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  hasAuthCheckStarted: false,
  hasAuthCheckFinished: false,
  isLoading: false,
  loginError: null,
  userToken: null,
  userProfileData: null,
  userProfileError: null
});

const groupValue = groupV => {
  const groupVal = groupV.toUpperCase();
  let groupValRet = '';
  if (groupVal.includes('Factory') || groupVal.includes('FACTORY')) {
    groupValRet = 'FACTORY';
  } else if (groupVal.includes('NLO')) {
    groupValRet = 'NLO';
  } else if (groupVal.includes('WHQ')) {
    groupValRet = 'WHQ';
  }
  return groupValRet;
};
const userGroupConfigureOnLoad = token => {
  const userToken = token.toObject();
  const userGroupSrc = userToken.groups;
  const userGroups = [];
  userGroupSrc.forEach(userGrpItem => {
    const userGrpArr = userGrpItem.split('.');
    const userGrpNameIs = userGrpArr[userGrpArr.length - 1]; // userGrpItem.split('.').splice(3).join('-')1]);
    const userGrpI = {
      code: userGrpItem,
      name: userGrpNameIs === 'WHQ' ? 'FWS & AP' : userGrpNameIs,
      value: groupValue(userGrpItem)
    };
    userGroups.push(userGrpI);
  });
  window.sessionStorage.setItem('currentUserGroup', userGroups[0].value);
  return userGroups;
};

export default function auth(state = defaultState, action) {
  state = fromJS(state); // Force immutable in case of rehydration
  switch (action.type) {
    case startAuthCheck.type:
      return state
        .set('hasAuthCheckStarted', true)
        .set('hasAuthCheckFinished', false);
    case setAuthCheckError.type:
      return state
        .set('hasAuthCheckFinished', true)
        .set('loginError', action.payload);
    case finishAuthCheck.type:
      return state.set('hasAuthCheckFinished', true);
    case setUserLogin.type:
      return state
        .set('userToken', action.payload ? fromJS(action.payload) : null)
        .set(
          'userGroups',
          action.payload ? userGroupConfigureOnLoad(fromJS(action.payload)) : []
        );

    case createServiceAction('getUserProfile', 'success').type:
      return state.set('getUserProfile', action.data);

    case createServiceAction('getUserProfile', 'modify').type:
      return state.set('getUserProfile', action.data);

    case createServiceAction('getUserProfile', 'clear').type:
      return state.set('getUserProfile', action.data);

    case createServiceAction('getUserProfile', 'error').type:
      return state.set('getUserProfile', action.data);
    default:
      return state;
  }
}

export const userLoggedIn = state => state.auth.get('userToken') !== null;
export const selectUserDetails = state =>
  state.auth.get('userToken') ? state.auth.get('userToken').toObject() : {};
export const selectUserProfile = state => state.auth.get('getUserProfile');
export const selectUsername = state =>
  state.auth.getIn(['userToken', 'username']);
export const selectUserId = state => state.auth.getIn(['userToken', 'userId']);
export const selectEmail = state => state.auth.getIn(['userToken', 'email']);
export const selectFullName = state =>
  state.auth.getIn(['userToken', 'fullName']);
export const selectLegacyUserName = state =>
  state.auth.getIn(['userToken', 'legacyUsername']);
export const hasAuthCheckStarted = state =>
  state.auth.get('hasAuthCheckStarted');
export const hasAuthCheckFinished = state =>
  state.auth.get('hasAuthCheckFinished');
export const selectUserAccessToken = state =>
  state.auth.getIn(['userToken', 'accessToken']);
export const selectLoginError = state => state.auth.get('loginError');
export const selectGroup = state => state.auth.getIn(['userToken', 'group']);
export const selectGroups = state => state.auth.get('userGroups');
export const isLoading = state => state.auth.get('isLoading');

// identify groups(User group/type)
// Both supplier and Factory users have the same access level.
// So Factory users are also identified as suppliers in the application.

// actual function to be used
export const isFactoryUser = state =>
  state.auth.getIn(['userToken', 'group']) === 'FACTORY';
export const isFWSUser = state =>
  state.auth.getIn(['userToken', 'group']) === 'FWS';
export const isNLOUser = state =>
  state.auth.getIn(['userToken', 'group']) === 'NLO';
/**/
/* creating hardcoded Value
 // "!==" will be true
 // "===" will be false


export const isFactoryUser = state => state.auth.get('userToken') !== null;
export const isNikeUser = state => state.auth.get('userToken') === null;
export const isNLOUser = state => state.auth.get('userToken') === null;
 */
