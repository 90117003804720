import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { H4, H5, IconButton, Spinner } from '../index.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Grid from '../grid/Grid.js';
import CommentItem from '../dashboard/commentitem';
import _ from 'lodash';
import { createServiceAction } from '../../util/actionHelper.js';
import * as moment from 'moment';
import { selectEmail } from '../../reducers/authReducer.js';
import { updateNewCommentEntry } from '../../services/dashboardService.js';
import { msgConstants } from '../../constants/msgConstants.js';
import {
  getLeverLoggingData,
  getLeverLoggingLoading,
  getLeverLoggingError
} from '../../reducers/leverLoggingReducer.js';

const gridDefault = {
  resizable: true,
  editable: false,
  sortable: true,
  filter: true
};

const autoGroupColumnDef = {
  headerName: 'Saved By',
  width: 280,
  headerClass: 'litegrey',
  filter: 'agTextColumnFilter',
  editable: false,
  suppressMenu: true,
  cellRendererParams: {
    suppressCount: true
  },
  autoHeight: true,
  wrapText: true
  // pinned: 'left'
};

let columnDefs = [
  {
    headerName: 'Saved By',
    field: 'modificationDate',
    rowGroup: true,
    filter: 'agTextColumnFilter',
    editable: false,
    suppressMenu: true,
    width: 250,
    hide: true,
    valueFormatter: params => {
      if (
        params &&
        params.node &&
        params.node.allLeafChildren &&
        params.node.allLeafChildren.length > 0
      ) {
        const {
          modificationDate,
          modifiedUserId
        } = params.node.allLeafChildren[0].data;
        return (
          (modifiedUserId
            ? modifiedUserId.substring(0, modifiedUserId.indexOf('@'))
            : '') +
          ' \n ' +
          moment(modificationDate).format('MM/DD/YYYY LT')
        );
      }
      return '';
    }
  },
  {
    headerName: 'Saved By',
    field: 'modifiedUserId',
    hide: true
  },
  {
    headerName: 'Action',
    field: 'action',
    filter: 'agSetColumnFilter',
    editable: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    cellClass: 'nonEditableColumn',
    width: 100,
    cellRenderer: params => {
      if (params.value) {
        let colour = '';
        switch (params.value) {
          case 'Delete':
            colour = 'text-color-error';
            break;
          case 'Modify':
            colour = 'text-color-warn';
            break;
          default:
            colour = 'text-color-success';
        }
        return '<i class="' + colour + '">●</i>&nbsp;' + params.value + ' ';
      }
    }
  },
  {
    headerName: 'LRCP',
    field: 'lrcpTag',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    width: 200,
    headerClass: 'litegrey',
    cellRenderer: params => {
      if (params && params.value) {
        return '<span class="tag">' + params.value + '</span>';
      }
    }
  },
  {
    headerName: 'Lever',
    field: 'lever',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 300,
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { action } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            return '<span>' + params.value + '</span>';
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Capacity',
    field: 'capacity',
    filter: 'agNumberColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 100,
    cellRenderer: params => {
      if (params && params.data && (params.value || params.value === 0)) {
        const { action, oldCapacity } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            if (oldCapacity || oldCapacity === 0) {
              return (
                '<span class="u-strikethrough">' +
                oldCapacity +
                '</span> <br/><span class="text-color-success">' +
                params.value +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Cost',
    field: 'cost',
    filter: 'agNumberColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 100,
    cellRenderer: params => {
      if (params && params.data && (params.value || params.value === 0)) {
        const { action, oldCost } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            if (oldCost || oldCost === 0) {
              return (
                '<span class="u-strikethrough">' +
                oldCost +
                '</span> <br/><span class="text-color-success">' +
                params.value +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Decision Deadline',
    field: 'validityDate',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 100,
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { action, oldValidityDate } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            if (oldValidityDate) {
              return (
                '<span class="u-strikethrough">' +
                oldValidityDate +
                '</span> <br/><span class="text-color-success">' +
                params.value +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Sourcing Decision',
    field: 'srcDecision',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 100,
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { action, oldSrcDecision } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            if (oldSrcDecision) {
              return (
                '<span class="u-strikethrough">' +
                oldSrcDecision +
                '</span> <br/><span class="text-color-success">' +
                params.value +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Lever Quantity',
    field: 'leverQty',
    filter: 'agNumberColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 100,
    cellRenderer: params => {
      if (params && params.data && (params.value || params.value === 0)) {
        const { action, oldLeverQty } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            if (oldLeverQty || oldLeverQty === 0) {
              return (
                '<span class="u-strikethrough">' +
                oldLeverQty +
                '</span> <br/><span class="text-color-success">' +
                params.value +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Comments',
    field: 'comment',
    filter: 'agTextColumnFilter',
    suppressMenu: true,

    headerClass: 'litegrey',
    width: 200,
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { action, oldComment } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            if (oldComment) {
              return (
                '<span class="u-strikethrough">' +
                oldComment +
                '</span> <br/><span class="text-color-success">' +
                params.value +
                '</span>'
              );
            } else {
              return '<span>' + params.value + '</span>';
            }
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    },
    wrapText: true,
    autoHeight: true
  }
];

class LeverLoggingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyParams: {},
      context: { componentParent: this },
      currentReasonCodeGrid: [],
      defaultTabIndex: 0,
      getRowHeight(params) {
        let newlen = 1;
        let drange = 50;
        let dLen = 50;
        if (params && params.data) {
          const objLenArr = Object.keys(params.data)
            .map(function(key) {
              return params.data[key] ? params.data[key].length : 0;
            })
            .filter(item => item);
          const objLenMax = Math.max(...objLenArr);
          if (objLenMax > 50) {
            dLen = objLenMax;
          }
        }
        if (dLen > drange) {
          newlen = Math.ceil(dLen / drange);
        }
        return 50 * newlen;
      },
      varianceName: '',
      currentRowComments: [],
      currentRowLogs: [],
      commentTextArea: ''
    };

    this.setHistoryParams = this.setHistoryParams.bind(this);
    this.cellRenderAction = this.cellRenderAction.bind(this);
    this.addComment2Grid = this.addComment2Grid.bind(this);
    this.handleCommentTextAreaChange = this.handleCommentTextAreaChange.bind(
      this
    );
    this.handleLogView = this.handleLogView.bind(this);
  }

  componentDidMount() {
    const {
      requestLeverLoggingData,
      variance,
      growthLeverQuarterId,
      factoryType
    } = this.props;

    const varianceCode = variance.varianceCode;

    const payload = { growthLeverQuarterId, varianceCode };
    const costIndex = columnDefs.findIndex(o => o.field === 'cost');
    if (costIndex > -1) {
      columnDefs[costIndex].hide = factoryType === 'AP' ? true : false;
    }
    requestLeverLoggingData(payload);

    this.setState({
      leverLoggingLoading: false
    });
  }

  createChangeLogs(leverData) {
    const changeLogs = [];
    if (leverData && leverData.length) {
      const sortedHistory = leverData.sort(function(a, b) {
        return new Date(b.modificationDate) - new Date(a.modificationDate);
      });

      sortedHistory.forEach((item, index) => {
        let nxtHistory = sortedHistory[index + 1]
          ? sortedHistory[index + 1].leverlist
          : [];
        let updatedNxtHistory = nxtHistory.map(item => {
          let historyItem = { ...item };
          delete historyItem.newItem;
          delete historyItem.devIndex;
          return historyItem;
        });
        let updatedLeverList = item.leverlist.map(item => {
          let leverItem = { ...item };
          delete leverItem.newItem;
          delete leverItem.devIndex;
          return leverItem;
        });

        let diffArray = [];
        if (updatedNxtHistory && updatedNxtHistory.length > 0) {
          const arr1 = _.differenceWith(
            updatedLeverList,
            updatedNxtHistory,
            _.isEqual
          );
          const arr2 = _.differenceWith(
            updatedNxtHistory,
            updatedLeverList,
            _.isEqual
          );
          diffArray = _.unionBy(arr1, arr2, 'leverCode');
        } else {
          diffArray = item.leverlist;
        }
        if (diffArray && diffArray.length > 0) {
          diffArray.forEach((dItem, dindex) => {
            let hItem = {
              capacity: dItem.capacity,
              cost: dItem.cost,
              lever: dItem.lever,
              leverCode: dItem.leverCode,
              validityDate: dItem.validityDate,
              srcDecision: dItem.srcDecision,
              leverQty: dItem.leverQty,
              comment: dItem.comment,
              lrcpTag: item.lrcpTag,
              modificationDate: item.modificationDate,
              modifiedUserId: item.modifiedUserId
            };

            hItem.action = 'Add';
            let IsExistNow = updatedLeverList.find(o => {
              if (
                o.leverCode === dItem.leverCode &&
                o.validityDate === dItem.validityDate
              ) {
                return true;
              }
              return false;
            });

            let IsExistEarlier = updatedNxtHistory.find(o => {
              if (
                o.leverCode === dItem.leverCode &&
                o.validityDate === dItem.validityDate
              ) {
                return true;
              }
              return false;
            });
            if (IsExistNow) {
              if (
                IsExistEarlier &&
                IsExistEarlier.leverCode === IsExistNow.leverCode
              ) {
                hItem.action = 'Modify';
                if (IsExistEarlier.capacity !== IsExistNow.capacity) {
                  hItem.oldCapacity = IsExistEarlier.capacity;
                }
                if (IsExistEarlier.cost !== IsExistNow.cost) {
                  hItem.oldCost = IsExistEarlier.cost;
                }
                if (IsExistEarlier.validityDate !== IsExistNow.validityDate) {
                  hItem.oldValidityDate = IsExistEarlier.validityDate;
                }
                if (IsExistEarlier.comment !== IsExistNow.comment) {
                  hItem.oldComment = IsExistEarlier.comment;
                }
                if (IsExistEarlier.leverQty !== IsExistNow.leverQty) {
                  hItem.oldLeverQty = IsExistEarlier.leverQty;
                }
              }
            } else {
              hItem.action = 'Delete';
            }
            changeLogs.push(hItem);
          });
        }
      });
    }
    return changeLogs;
  }

  componentWillReceiveProps(nextProps) {
    let currentRowComments = [];
    const { leverLoggingData } = this.props;
    if (
      !nextProps.leverLoggingLoading &&
      leverLoggingData !== nextProps.leverLoggingData
    ) {
      const leverData = nextProps.leverLoggingData;
      const changeLogs = this.createChangeLogs(leverData);

      // Adding comments
      if (leverData !== undefined || leverData.length > 0) {
        if (leverData[0] && leverData[0].comments) {
          for (let i = 0; i < leverData[0].comments.length; i++) {
            currentRowComments.push(leverData[0].comments[i]);
          }
        }
      }
      this.setState({
        currentRowLogs: changeLogs,
        currentRowComments
      });
    }
  }

  setHistoryParams = params => {
    if (params) {
      this.setState({ historyParams: params });
      if (params.columnApi.getAllColumns()) {
        try {
          params.api.closeToolPanel();
          params.api.sizeColumnsToFit();
          params.api.setSuppressRowDrag(false);
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  cellRenderAction = (rowIndex, column) => {
    const { OpenHistoryRCModel } = this.props;
    OpenHistoryRCModel(rowIndex);
  };

  addComment2Grid = () => {
    const {
      updateLeverLoggingComment,
      variance,
      growthLeverQuarterId,
      currentuseremail
    } = this.props;
    const { commentTextArea, totalLength, userType, isToggleOn } = this.state;
    const varianceCode = variance.varianceCode;

    let { currentRowComments, commentSession } = this.state;
    const commentsUpdateStatus = {
      status: true,
      message: msgConstants.cmntAddSuccess
    };

    if (commentTextArea.length > 0) {
      this.setState({ isCommentTextUpdating: true });

      const newcomment = updateNewCommentEntry(
        commentTextArea,
        currentRowComments,
        currentuseremail,
        userType,
        isToggleOn
      );

      const updateSingleRowChange = {
        comment: commentTextArea,
        varianceCode: varianceCode,
        id: growthLeverQuarterId,
        timestamp: newcomment[newcomment.length - 1].timestamp
      };
      commentSession = updateSingleRowChange;
      this.setState({
        currentRowComments,
        commentTextArea: '',
        totalLength: totalLength + 1,
        commentSession,
        currentRowCommentsUpdating: true
      });
      updateLeverLoggingComment(updateSingleRowChange);
    } else {
      commentsUpdateStatus.status = false;
      commentsUpdateStatus.message = msgConstants.cmntAddInvalid;
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };

  handleCommentTextAreaChange(event) {
    this.setState({ commentTextArea: event.target.value });
  }

  handleLogView(growthLeverQuarterId, varianceCode) {
    const { requestLeverLoggingData } = this.props;
    const payload = { growthLeverQuarterId, varianceCode };

    requestLeverLoggingData(payload);
  }

  render() {
    const {
      commentTextArea,
      getRowHeight,
      currentRowComments,
      currentRowLogs
    } = this.state;
    const {
      LeverLoggingModel,
      toggleLeverLoggingModel,
      variance,
      isCommentTextUpdating,
      growthLeverQuarterId,
      leverLoggingLoading
    } = this.props;
    return (
      <Modal
        isOpen={LeverLoggingModel}
        className="modal-right-container"
        overlayClassName="modal-overlay"
      >
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() =>
            toggleLeverLoggingModel(growthLeverQuarterId, variance.varianceCode)
          }
        />
        <div className="row">
          <div className="ncss-col-sm-10 ">
            <H4 className="lever-header">
              History /{' '}
              <span className="lever-header-breadcrumb">
                {variance.headerName}
              </span>
            </H4>
          </div>
        </div>
        <div className="ncss-col-sm-12">
          <Tabs>
            <TabList>
              <Tab className="menuOpt1Item">
                <span className="text">Comments</span>
              </Tab>
              <Tab className="menuOpt1Item">
                <span
                  className="text"
                  onClick={() =>
                    this.handleLogView(
                      growthLeverQuarterId,
                      variance.varianceCode
                    )
                  }
                >
                  Change Log
                </span>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="ncss-col-sm-12 ">
                <div className="ncss-container comment-container">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12 lever-comment-section">
                      <div className="comments">
                        {!leverLoggingLoading ? (
                          <div
                            style={{
                              height: 'calc(100vh - 365px)',
                              overflowY: 'scroll'
                            }}
                          >
                            {currentRowComments.length > 0 ? (
                              <div>
                                {currentRowComments.map(
                                  ({
                                    comment,
                                    userId,
                                    userRole,
                                    timestamp,
                                    modifiedVersion
                                  }) => (
                                    <div
                                      className={`com${modifiedVersion}`}
                                      key={`com${timestamp}`}
                                    >
                                      <CommentItem
                                        type={'lever'}
                                        content={comment}
                                        field=""
                                        date={String(timestamp)}
                                        user={userId}
                                        role={userRole}
                                        isQuarterly={modifiedVersion}
                                      />
                                    </div>
                                  )
                                )}
                                <div
                                  style={{ float: 'left', clear: 'both' }}
                                  ref={el => {
                                    this.commentSectionEnd = el;
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                className="lever-commentNoDataMsg c-f"
                                style={{ height: 'calc(100vh - 365px)' }}
                              >
                                <H5>No Comments Added Yet! </H5>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="commentNoDataMsg c-f"
                            style={{ height: 'calc(100vh - 365px)' }}
                          >
                            <Spinner large />
                          </div>
                        )}
                        <div className=" comment-input pt4-sm">
                          <div className="comment-box">
                            <textarea
                              value={commentTextArea}
                              onChange={this.handleCommentTextAreaChange}
                              className="textareaComment p4-sm"
                              placeholder="your comments here"
                            />
                            <div className="comment-foot">
                              <i className="fas fa-comments" />
                              {isCommentTextUpdating ? (
                                <span> updating your comments</span>
                              ) : (
                                <span> Add your comments</span>
                              )}

                              <button
                                type="button"
                                className="comment-add-button"
                                onClick={() => this.addComment2Grid()}
                              >
                                <i className="fas fa-plus" /> ADD
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="ncss-col-sm-12 ">
                <div className="ncss-container comment-container">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12 lever-log-section">
                      <div className="log capvis-page-container c-f-all">
                        <div id="myGrid" className="ag-theme-balham">
                          {!leverLoggingLoading ? (
                            <div>
                              {currentRowLogs && currentRowLogs.length > 0 ? (
                                <Grid
                                  defaultColDef={gridDefault}
                                  columnDefs={columnDefs}
                                  rowData={currentRowLogs}
                                  onGridReady={this.onChildGridReady}
                                  dbGridFunctions={false}
                                  headerHeight={60}
                                  setheight={'calc(100vh - 265px)'}
                                  groupDisplayType={'singleColumn'}
                                  autoGroupColumnDef={autoGroupColumnDef}
                                  getRowHeight={getRowHeight}
                                />
                              ) : (
                                <div className="lever-log-msg c-f">
                                  <H5>No Logs Available! </H5>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="commentNoDataMsg c-f">
                              <Spinner large />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    );
  }
}

LeverLoggingModal.propTypes = {
  LeverLoggingModel: PropTypes.bool,
  toggleLeverLoggingModel: PropTypes.func,
  handleLogView: PropTypes.func,
  historyData: PropTypes.any,
  handleCommentTextAreaChange: PropTypes.func,
  addComment2Grid: PropTypes.func,
  currentRowComments: PropTypes.any,
  updateLeverLoggingComment: PropTypes.func,
  currentRowLogs: PropTypes.any
};

const mapStateToProps = state => ({
  currentuseremail: selectEmail(state),
  leverLoggingLoading: getLeverLoggingLoading(state),
  leverLoggingData: getLeverLoggingData(state),
  leverLoggingError: getLeverLoggingError(state)
});

const mapDispatchToProps = dispatch => ({
  requestLeverLoggingData: data => {
    dispatch(createServiceAction('leverLoggingData', 'requested')(data));
  },
  updateLeverLoggingComment: data => {
    dispatch(
      createServiceAction('updateLeverLoggingComment', 'submited')({
        ...data
      })
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeverLoggingModal);
