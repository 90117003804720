import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch ADMIN_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')({}));
    yield put(createServiceAction(serviceType, 'dashboardData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));

    const options = {};

    // Trigger Call to API endpoint
    const res = yield call(
      getRequest,
      apiConfig.getUserAvailableFactories.endPointUrl,
      options,
      true
    );
    const prefResp = yield call(
      getRequest,
      apiConfig.getUserPrefferedFactories.endPointUrl,
      options,
      true
    );
    if (res.status === 200 && prefResp.status === 200) {
      // Parse response object
      const response = yield res.json();
      const prefResData = yield prefResp.json();
      // Dispatch ADMIN_DATA_LOADING action with response object
      yield put(createServiceAction(serviceType, 'loading')(false));
      // yield put(createServiceAction(serviceType, 'success')(response));
      yield put(
        createServiceAction(serviceType, 'success')({
          options: response,
          preference: prefResData
        })
      );
    } else {
      const ToUpdateStatus =
        Number(res.status) !== 200
          ? Number(res.status)
          : Number(prefResp.status);
      const ToUpdateMessage =
        Number(res.status) !== 200 ? res.statusText : prefResp.statusText;
      const errorMsg = { status: ToUpdateStatus, msg: ToUpdateMessage };
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'error')(errorMsg));
    }
  } catch (e) {
    // Dispatch ADMIN_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'loading')(false));
    const errorMsg = { status: 500, msg: e };
    yield put(createServiceAction(serviceType, 'error')(errorMsg));
  }
}

function* userProfileSettingsDataSagas() {
  const serviceType = 'userProfileSettingsData';
  // Listen to ADMIN_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default userProfileSettingsDataSagas;
