import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from '../index.js';
import PropTypes from 'prop-types';

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectHome: false
    };

    this.getParameterByName = this.getParameterByName.bind(this);
  }

  getParameterByName = name => {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  };
  onToggle() {
    const { redirectHome } = this.state;
    const { status } = this.props;
    if (status) {
      window.location.reload();
    } else {
      this.setState({ redirectHome: !redirectHome });
    }
  }

  render() {
    const { status } = this.props;
    const searchFields =
      Number(status) || Number(this.getParameterByName('status'));
    const { redirectHome } = this.state;
    const statusError = {
      title: 'Not Found',
      msg: 'The resource you requested was not found'
    };
    switch (searchFields) {
      case 401:
        statusError.title = 'Not Authorized';
        statusError.msg =
          'The resource you requested was not assigned to you. Please contact the Administrator or Support team to get access.';

        break;
      case 403:
        statusError.title = 'Forbidden';
        statusError.msg =
          'User does not have the necessary permissions to access the resource.';
        break;
      case 405:
        statusError.title = 'Not Permission to access ';
        statusError.msg =
          'The resource you requested was not assigned to you. Please contact the Administrator or Support team to get access.';

        break;
      case 500:
        statusError.title = ' Internal Server Error ';
        statusError.msg =
          'unexpected condition was encountered. Please try after some time.';
        break;
      default:
        statusError.title = 'Not Found';
    }
    return (
      <Container className={`errorCenterpl2-sm pr2-sm e${searchFields}`}>
        <div className=" ncss-row">
          <div className="ncss-col-sm-12 ">
            <div className="c-f-all u-full-width  u-clearfix  d-sm-flx-new  flx-jc-sm-c errorCenter">
              <div className="flex-item p4-sm ta-sm-l flx-as-sm-c  ">
                <h4 className="  pb2-sm u-bold "> {statusError.title} </h4>
                <p>{statusError.msg}</p>
                <p>
                  {status
                    ? 'Click below to refresh the page!'
                    : 'Click below to go back to home page!'}
                </p>
                <p className="errorContent pt4-sm ta-sm-l ">
                  <button
                    type="button"
                    className="d-sm-ib ncss-btn-primary-dark  pt1-sm pr3-sm pb1-sm pl3-sm "
                    onClick={() => this.onToggle()}
                  >
                    {status ? 'Refresh' : 'Go Home'}
                  </button>
                </p>
                {redirectHome && <Redirect to="/" push />}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

NotFound.propTypes = {
  status: PropTypes.string
};
