export const featuresConstants = [
  {
    name: 'ccn',
    label: 'Capacity Change Notification',
    defaultVal: '01EC39',
    value: '01EC39',
    status: true,
    trial: true
  }
];
