import * as moment from 'moment';
const dashBoardConstants = {
  logHeaderGridDefault: {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true
  },
  scoreCardCount: [
    {
      name: 'All',
      icon: 'fas fa-th',
      id: 5,
      unreadCount: 0,
      pendingCount: 0,
      statusCode: '',
      isActive: true
    },
    {
      name: 'Factory',
      icon: 'far fa-edit',
      id: 0,
      unreadCount: 0,
      pendingCount: 0,
      statusCode: 'Factory',
      isActive: false,

      showUnread: true,
      showPending: true
    },
    {
      name: 'Confirmed',
      icon: 'fas fa-check',
      id: 1,
      unreadCount: 0,
      pendingCount: 0,
      statusCode: 'Confirmed',
      isActive: false,
      showUnread: true,
      showPending: true
    },
    {
      name: 'Approved',
      icon: 'fas fa-check-double text-color-success',
      id: 2,
      unreadCount: 0,
      pendingCount: 0,
      statusCode: 'Approved',
      isActive: false,
      showUnread: true
    },
    {
      name: 'Max',
      icon: 'fas fa-eye',
      id: 4,
      unreadCount: 0,
      pendingCount: 0,
      statusCode: 'Max',
      isActive: false,
      showUnread: true
    }
  ],
  columns: [
    {
      editable: false,
      field: 'userUpdatesFlag',
      pinned: 'left',
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: false
      },

      headerName: '',
      hide: false,
      icons: {},
      suppressMenu: true,
      unSortIcon: false,
      width: 50,
      sortable: true,
      cellRenderer: 'cellLinkRenderer'
    },
    {
      editable: false,
      field: 'userUpdatesCheck',
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: false
      },

      headerName: '',
      hide: false,
      icons: {},
      suppressMenu: true,
      unSortIcon: false,
      width: 35,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: true
    },
    {
      headerName: 'Prod Qtr',
      tooltipField: 'Prod Qtr',
      field: 'productionQuarter',
      hide: false,
      width: 100,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,

      filter: 'agSetColumnFilter',
      cellEditor: 'numericEditor',
      filterParams: {
        selectAllOnMiniFilter: true
      },

      sortable: true,
      cellRenderer: 'cellLinkRenderer'
    },
    {
      headerName: 'Factory Group',
      toolPanelClass: '18404480-49a4-11e9-b620-b5839ee244f5',

      field: 'factoryGroup',
      hide: false,
      width: 115,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agTextColumnFilter',

      sortable: true
    },
    {
      headerName: 'Factory Code',
      field: 'factoryCode',
      hide: false,
      width: 115,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agTextColumnFilter',
      sortable: true
    },
    {
      headerName: 'Capacity Type Code',
      field: 'capacityTypeCode',
      hide: false,
      width: 150,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        selectAllOnMiniFilter: true
      },
      sortable: true
    },
    {
      headerName: 'Capacity Type Desc.',
      field: 'capacityTypeDescription',
      hide: false,
      width: 150,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agTextColumnFilter',
      sortable: true
    },
    {
      headerName: 'Status',
      field: 'changeType',
      hide: false,
      width: 100,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agSetColumnFilter',

      sortable: true,
      cellRenderer: 'cellLinkRenderer'
    },
    {
      headerName: 'Quarterly Change',
      field: 'delta',
      hide: false,
      width: 180,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      sortable: true,
      cellRenderer: 'cellLinkRenderer'
    },
    {
      headerName: 'Changed By',
      field: 'changedBy',
      hide: false,
      width: 220,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,
      filter: 'agTextColumnFilter',

      sortable: true
    },
    {
      headerName: 'Date',
      field: 'lastModified',
      hide: false,
      width: 170,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      editable: false,

      filter: 'agDateColumnFilter',
      sortable: true,
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'greaterThan'],
        suppressAndOrCondition: true,
        comparator(filterLocalDateAtMidnight, cellValue) {
          const dateAsString = cellValue;

          if (dateAsString == null) {
            return 0;
          } // * 1000
          const cellDate = Date.parse(moment(cellValue).format('MM/DD/YYYY'));
          const filterDate = Date.parse(
            moment(filterLocalDateAtMidnight).format('MM/DD/YYYY')
          );
          if (cellDate < filterDate) {
            return -1;
          } else if (cellDate > filterDate) {
            return 1;
          }
          return 0;
        },
        browserDatePicker: true
      },
      cellRenderer: 'cellLinkRenderer'
    },
    {
      headerName: 'time',
      field: 'lastModified',
      hide: true,
      width: 140,
      suppressMenu: true,
      editable: false,
      filter: false,
      sortable: true
    }
  ]
};

export default dashBoardConstants;
