const updatesSearchConstants = {
  groups: [
    {
      name: 'Factory Group',
      id: 'dsc112',
      field: 'factoryGroup',
      value: '',
      type: 'select',
      format: 'text',
      placeHolder: '',
      cssClass: 'float',
      required: true,
      warnings: '',
      show: true,
      options: [],
      selected: {},
      maxLimit: 1,
      multiSelect: false
    },
    {
      name: 'Factory Code',
      id: 'dsc111',
      field: 'factoryCode',
      value: '',
      type: 'select',
      format: 'text',
      placeHolder: '',
      cssClass: 'float',
      required: true,
      warnings: '',
      show: true,
      options: [],
      selected: {},
      maxLimit: 5,
      multiSelect: false
    },
    {
      name: 'Change Status',
      id: 'dsc3',
      field: 'readStatus',
      value: '',
      type: 'select',
      format: 'text',
      placeHolder: '',
      cssClass: 'float',
      required: false,
      warnings: '',
      show: true,
      disabled: false,
      selected:  {
        name: 'Unread',
        value: 'Unread',
        key: 'Unread',
        label: 'Unread'
      },
      maxLimit: 5,
      multiSelect: false,
      options: [
        {
          name: 'All Changes',
          value: '',
          key: 'AllChanges',
          label: 'All Changes'
        },
        {
          name: 'Unread',
          value: 'Unread',
          key: 'Unread',
          label: 'Unread'
        },
        {
          name: 'Read',
          value: 'Read',
          key: 'Read',
          label: 'Read'
        },
        {
          name: 'Pending',
          value: 'Pending',
          key: 'Pending',
          label: 'Pending'
        },
        {
          name: '1 day ago',
          value: 1,
          key: '1dayago',
          label: '1 day ago'
        },
        {
          name: '2 days ago',
          value: 2,
          key: '2dayago',
          label: '2 days ago'
        }
      ]
    }
  ],

  fields: []
};
export default updatesSearchConstants;
