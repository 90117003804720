import React from 'react';
import PropTypes from 'prop-types';
import { Container, H1 } from '../index.js';
import { msgConstants } from '../../constants/msgConstants.js';

export const PageError = ({ response }) => {
  const errorContent = {
    title: msgConstants.invalidSearch,

    content: ''
  };
  if (response && response.status === 400) {
    errorContent.title = 'Something Went Wrong !';
    errorContent.content = response.message;
  }
  return (
    <Container
      section
      className={`apiErrorCode apiErrorCode${response && response.status}`}
    >
      <H1> {errorContent.title}</H1>
      <p className="errorContent pt2-sm">{errorContent.content} </p>
    </Container>
  );
};

PageError.propTypes = {
  response: PropTypes.any
};
export default PageError;
