import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { H5, IconButton, Spinner } from '../index.js';

export default class UploadModal extends Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
    this.openFileBrowser = this.openFileBrowser.bind(this);
  }

  openFileBrowser = () => {
    this.fileInput.current.click();
  };

  render() {
    const {
      UploadModel,
      UploadingData,
      toggleUploadModel,
      fileHandler,
      recentDownloadedGridFile
    } = this.props;

    return (
      <Modal
        isOpen={UploadModel}
        className="modal-container"
        overlayClassName="modal-overlay"
      >
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() => toggleUploadModel()}
        />

        <H5 className="u-bold description p2-top-sm p2-bottom-sm">
          Upload Excel sheet
        </H5>

        <div className="ncss-col-sm-12 ">
          {UploadingData ? (
            <div className="border">
              <H5 className="u-bold  p2-top-sm p2-bottom-sm text-color-success">
                <Spinner />
                Uploading and validating data
              </H5>
            </div>
          ) : (
            <div className="border">
              <H5 className="u-bold  p2-top-sm p2-bottom-sm text-color-error">
                <i className="g72-alert fs32-sm pr2-sm d-sm-ib va-sm-m" />
                Instructions
              </H5>
              <ol className="ncss-list-ol">
                <li>Download Excel File from current session</li>
                <li>Modify and Upload the same file within session</li>
                <li>Do not add or remove any records or columns</li>
                <li>Make changes only in Green/ Yellow Background Columns</li>
              </ol>
              {recentDownloadedGridFile ? (
                <div className="bg-success  ta-sm-c d-none">
                  <H5> Expected File ID : {recentDownloadedGridFile}</H5>
                </div>
              ) : (
                <div className="bg-accent  mt2-sm   mb2-sm ta-sm-c">
                  <H5>
                    No Downloaded File record available in current session
                  </H5>
                </div>
              )}
              <br />
            </div>
          )}

          <div className="bottom-section p4-top-sm popup-bottom">
            <button
              className="ncss-btn-black  dashboard-button"
              onClick={this.openFileBrowser.bind(this)}
            >
              <i className="fas fa fa-upload" /> Browse &hellip;
            </button>
            <input
              type="file"
              onChange={fileHandler.bind(this)}
              ref={this.fileInput}
              hidden
              onClick={event => {
                event.target.value = null;
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

UploadModal.propTypes = {
  UploadModel: PropTypes.bool,
  toggleUploadModel: PropTypes.func,
  fileHandler: PropTypes.func,
  UploadingData: PropTypes.bool,
  recentDownloadedGridFile: PropTypes.any
};
