/* eslint-disable max-statements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ExcelRenderer } from 'react-excel-renderer';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Beforeunload } from 'react-beforeunload';
import { H1, PageError, Spinner } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import NumericCellEditor from '../../components/grid/numericCellEditor.js';
import UploadModal from '../../components/dashboard/uploadModal.js';
import UploadUpdateModal from '../../components/dashboard/UploadUpdateModal.js';
import GBDVarianceValidationModal from '../../components/dashboard/GBDVarianceValidationModal.js';
import { divisionCodes } from '../../constants/appConstants';
import SearchNew from '../../components/dashboard/searchNew';
import TabHeader from '../../components/dashboard/tabHeader';
import GBDPagination from '../../components/dashboard/GBDPagination.js';
import LeversCustomCell from '../../components/grid/LeversCustomCell.js';
import dayPickerFilter from '../../components/grid/dayPickerFilter.js';
import NotFound from '../../components/errors/NotFound.js';
import {
  getGrowthDimensionDataLoading,
  getGrowthDimensionData,
  getGrowthDimensionError,
  getGrowthDimensionGridLoading,
  getGrowthDimensionGridColumns,
  getGrowthDimensionGridError,
  getusrFactGrpSearchData,
  getusrFactGrpSearchLoading,
  getusrFactGrpSearchError,
  getGrowthDimensionDataUpdate,
  getUpdateGrowthDimensionCommentStatus
} from '../../reducers/growthDimensionReducer.js';
import {
  getCommentsLogsDataLoading,
  getCommentsLogsData
} from '../../reducers/commentsLogsReducer.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { getUserNotifyData } from '../../reducers/userNotifyReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { AppCipher, AppDeCipher } from '../../util/cipher.js';
import {
  searchValidator,
  searchQueryFrame,
  searchQueryCTFrame,
  agGridDownloadDataFormat,
  fetchAllQueryValue,
  updateErrorReport,
  updateFailedReport,
  agGridDownloadFileName,
  updateUploadDataFormat,
  getGbdAllLevers,
  getGbdGroupedColumns,
  validateAllDataOnUpload,
  frameDataOnUpload,
  onConsoleArr,
  quarterlyGroupedGBDData,
  generateGbDWeekRecordsFromQuarter,
  validateDataByRowOnlyOnUpload,
  gbdExcelUploadRowCompare,
  GBDVarianceValidationResult
} from '../../services/growthDimensionService';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';
import { msgConstants } from '../../constants/msgConstants.js';
import pageConstants from './Dashboard.constants.js';
import growthDimensionSearchConstants from './growthDimensionSearch.constants.js';
import growthDimensionConstants from './growthDimensionConstants.js';
import {
  getAppConfigData,
  getAppConfigLoading,
  getAppConfigError
} from '../../reducers/appConfigReducer.js';

const searchfirst = true;
let noSearchInitiated = true;
const maxDownloadLimit = 4000;
const maxQuarterLimit = 60;
export class GrowthbyDimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGrid: false,
      userType: '',
      UploadModel: false,
      UploadUpdateModel: false,
      varianceValidationModal: false,
      UploadUpdateModelType: 'Grid',
      UploadStatusData: [],
      UploadingData: false,
      frameworkComponents: {
        NumericCellEditor: NumericCellEditor,
        LeversCustomCell: LeversCustomCell,
        agDateInput: dayPickerFilter
      },
      scoreCardCount: pageConstants.scoreCardCount,
      context: { componentParent: this },
      defaultStatus: 'MaxAvailable',
      editGrid: false,
      gridOptions: growthDimensionConstants.gridOptions,
      sideBar: growthDimensionConstants.sideBar,
      excelStyles: growthDimensionConstants.excelStyles,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',

      statusCode: '',

      commentTextRowId: '',
      commentTextRowDataId: [],
      currentRowComments: [],
      currentRowLogs: [],
      totalLength: 0,
      // eslint-disable-next-line react/no-unused-state
      uploadErrorState: [],
      gridPagination: {},
      searchGroups: growthDimensionSearchConstants.groups,
      searchParams: growthDimensionSearchConstants.fields,
      newSearchgroups: [],
      newSearchParams: [],

      getRowHeight(params) {
        let defaultheight = 34;

        return defaultheight;
      },

      changedRowsData: [],
      gridCurrentPageData: [],
      gridColumnConfig: [],
      defaultSortAfterLoad: [],
      defaultSortNotifyListAfterLoad: [
        {
          colId: 'weekStartDate',
          sort: 'asc'
        }
      ],
      pageSearch: 0,
      gbdAllLevers: [],
      gbdGroupedColumns: [],
      recentDownloadedGridFile: '',
      recentDownloadedGridFileId: '',
      updateCycleCompleteModel: false,
      updateCycleCompleteMsg: false,
      updateCycleReport: {},
      revisedHorizonWeeks: 0,
      displayView: 0,
      prodQuarterColumns: [],
      gbdWeeklyData: [],
      gbdWeeklyDataBackUp: [],
      gbdProdQuartersData: [],
      gbdProdQuartersDataBackUp: [],
      varianceValidationResult: [],
      agGridQuarterParams: {},
      quarterRowDataChanged: [],
      quarterGridCurrentFilter: {},
      commentSession: {},
      thresholdCounts: { passed: 0, all: 0 },
      actualStartWeekDate: '',
      gblColumnData: [],
      currentPage: 1,
      totalPage: 1
    };

    this.getGrowthDimensionType = this.getGrowthDimensionType.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.saveDataChanges = this.saveDataChanges.bind(this);

    this.uploadStatusUpdate = this.uploadStatusUpdate.bind(this);

    this.toogleUploadModel = this.toogleUploadModel.bind(this);
    this.toogleUploadUpdateModel = this.toogleUploadUpdateModel.bind(this);
    this.callExternalFilter = this.callExternalFilter.bind(this);
    this.onChildGridReady = this.onChildGridReady.bind(this);

    this.fileHandler = this.fileHandler.bind(this);
    this.onSearchEnter = this.onSearchEnter.bind(this);
    this.onSearchEnterApi = this.onSearchEnterApi.bind(this);
    this.onSearchFactoryGrpApi = this.onSearchFactoryGrpApi.bind(this);
    this.onSearchCall = this.onSearchCall.bind(this);
    this.onSearchFieldUpdate = this.onSearchFieldUpdate.bind(this);
    this.onSearchGroupUpdate = this.onSearchGroupUpdate.bind(this);

    this.goToPage = this.goToPage.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.onQuarterCellValueChanged = this.onQuarterCellValueChanged.bind(this);
    this.getTableHeight = this.getTableHeight.bind(this);
  }

  componentDidMount() {
    // On page load, loading max available growthDimension as default
    let defaultStatus = 'MaxAvailable';
    const { location } = this.props;
    const { pathname } = location;
    // checking url is to load max available or short horizon growthDimension
    if (pathname && pathname.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }

    // Function to call API for Grid Data
    this.getGrowthDimensionType(defaultStatus);
    localStorage.setItem('isRowDataChanged', 'false');
    localStorage.setItem('isQuarterRowDataChanged', 'false');
    localStorage.setItem('isGBDQuarterRowDataChanged', 'false');

    this.quarterScoreCardCalculation();
  }

  getTableHeight = () => {
    let height = window.innerHeight - 440;

    if (height < 300) {
      height = 300;
    } else {
      return null;
    }
    return height;
  };
  // eslint-disable-next-line complexity
  componentWillReceiveProps(nextProps) {
    // On page load, loading max available growthDimension as default
    let defaultStatus = 'MaxAvailable';
    const {
      searchParams,
      searchGroups,
      UploadStatusData,
      userType,
      gbdGroupedColumns,
      actualStartWeekDate
    } = this.state;
    const {
      location,
      growthDimensionData,
      usrFactGrpSearchData,
      userProfileData,
      growthDimensionDataUpdate,
      appConfigData,
      growthDimensionGridColumns,
      requestAppConfigData,
      clearGrowthDimensionData,
      userNotifyData
    } = this.props;
    const { pathname, search } = location;
    const newPathName = nextProps.location.pathname;
    const oldPathName = pathname;

    if (!nextProps.location.search) {
      searchParams.find(o => o.field === 'capacityTypeCode').value = '';
      this.setState({
        searchParams
      });
    }

    if (
      search !== nextProps.location.search &&
      nextProps.location.search === ''
    ) {
      this.setState({
        gridCurrentPageData: [],
        updateCycleComplete: false,
        loadGrid: true,
        changedRowsData: [],
        quarterRowDataChanged: []
      });

      clearGrowthDimensionData({});
    }

    if (newPathName && newPathName.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    if (userProfileData !== nextProps.userProfileData) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        const allFG = [
          {
            name: 'All Factory Groups',
            value: '',
            key: 'AllFactoryGroups',
            label: 'All Factory Groups'
          }
        ];
        newUsrProData.factoryGroups = _.sortBy(
          newUsrProData.factoryGroups,
          'name'
        );
        searchGroups.find(o => o.field === 'factoryGroup').options =
          [...allFG, ...newUsrProData.factoryGroups] || [];
        const CodeIndx = searchGroups.findIndex(o => o.field === 'factoryCode');
        if (CodeIndx >= 0) {
          const AllFactoryCodes = newUsrProData.factoryCodes;
          if (AllFactoryCodes[0].name === 'ALL') {
            AllFactoryCodes.shift();
          }
          searchGroups[CodeIndx].options = AllFactoryCodes;
          searchGroups[CodeIndx].backOptions = AllFactoryCodes;
        }
        this.setState({ searchGroups });
      }
      requestAppConfigData();
    }
    searchParams.forEach(gridItem => {
      if (gridItem.field === 'capacityTypeCode') {
        if (usrFactGrpSearchData !== nextProps.usrFactGrpSearchData) {
          if (
            nextProps.usrFactGrpSearchData &&
            nextProps.usrFactGrpSearchData.length >= 0
          ) {
            const codeLists = _.sortBy(nextProps.usrFactGrpSearchData, 'value');
            gridItem.options = codeLists || [];
            gridItem.backOptions = codeLists || [];
          }
          gridItem.disabled =
            gridItem.options && gridItem.options.length > 0 ? false : true;

          gridItem.value = '';
        }
      } else {
        gridItem.disabled = false;
      }
    });

    this.setState({ searchParams });
    if (growthDimensionDataUpdate !== nextProps.growthDimensionDataUpdate) {
      const updateData = nextProps.growthDimensionDataUpdate;
      let UploadStatusDataNew = [];
      if (
        updateData &&
        (updateData.status === 201 ||
          (Array.isArray(updateData.status) && updateData.status.includes(201)))
      ) {
        if (updateData.outputData) {
          UploadStatusDataNew = updateErrorReport(
            updateData.outputData,
            UploadStatusData
          );
        }
      } else {
        UploadStatusDataNew = updateFailedReport(UploadStatusData);
      }
      this.setState({
        updateCycleComplete: true,
        UploadStatusData: UploadStatusDataNew
      });
      this.reloadGridContentAfterSave(nextProps.growthDimensionDataUpdate);
    }

    let revisedHorizonWeeks = '';
    if (appConfigData) {
      revisedHorizonWeeks = appConfigData.revisedHorizonWeeks
        ? appConfigData.revisedHorizonWeeks
        : 0;
    }
    if (appConfigData !== nextProps.appConfigData) {
      if (
        nextProps.appConfigData &&
        Object.keys(nextProps.appConfigData).length !== 0
      ) {
        revisedHorizonWeeks = nextProps.appConfigData.revisedHorizonWeeks
          ? nextProps.appConfigData.revisedHorizonWeeks
          : revisedHorizonWeeks;

        this.setState({
          revisedHorizonWeeks: revisedHorizonWeeks
        });
      }
    }
    if (growthDimensionGridColumns !== nextProps.growthDimensionGridColumns) {
      if (
        nextProps.growthDimensionGridColumns &&
        nextProps.growthDimensionGridColumns.role
      ) {
        // PQ COL
        const gbdAllLevers = getGbdAllLevers(
          nextProps.growthDimensionGridColumns,
          defaultStatus,
          userType
        );
        const gbdGroupedColumns = getGbdGroupedColumns(
          nextProps.growthDimensionGridColumns,
          defaultStatus,
          userType
        );
        this.setState({
          gbdAllLevers,
          gbdGroupedColumns,
          userType: nextProps.growthDimensionGridColumns.role,
          gblColumnData: nextProps.growthDimensionGridColumns.columns
        });
      }
    }

    if (
      !_.isEqual(nextProps.growthDimensionData, growthDimensionData) &&
      nextProps.growthDimensionData.data
    ) {
      const gbdProdQuartersData = quarterlyGroupedGBDData(
        nextProps.growthDimensionData.data,
        defaultStatus,
        gbdGroupedColumns,
        actualStartWeekDate
      );
      const gbdProdQuartersDataBackUp = JSON.parse(
        JSON.stringify(gbdProdQuartersData)
      );
      let varianceValidationResult = [];
      gbdProdQuartersData.forEach(qrItem => {
        if (userType === 'FACTORY') {
          const { result } = GBDVarianceValidationResult(
            qrItem,
            gbdGroupedColumns,
            defaultStatus
          );

          if (result.length > 0) {
            result.forEach(log => {
              const logNew = { ...log };
              varianceValidationResult.push(logNew);
            });
          }
        }
      });
      this.setState({
        gbdWeeklyData: nextProps.growthDimensionData.data,
        gbdProdQuartersData,
        gbdProdQuartersDataBackUp,
        varianceValidationResult,
        varianceValidationModal:
          varianceValidationResult.length > 0 ? true : false
      });
    }

    if (newPathName !== oldPathName) {
      noSearchInitiated = true;
      this.getGrowthDimensionType(defaultStatus);
    }
    try {
      if (
        nextProps.growthDimensionData.role !== growthDimensionData.role ||
        nextProps.growthDimensionData.growthDimension !==
          growthDimensionData.dashboard
      ) {
        this.setState({
          loadGrid: false
        });
      }
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }

    this.setState({ userNotifyData });
  }
  onSearchAllClear() {
    const { searchGroups, searchParams } = this.state;
    searchGroups.forEach(gridItem => {
      gridItem.value = '';
      let thisrel = this.refs[`typeAhead${gridItem.field}`];
      if (thisrel) {
        thisrel.getInstance().clear();
      }
      gridItem.selected = [];
      if (gridItem.field === 'factoryCode') {
        gridItem.options = gridItem.backOptions;
      }
    });

    searchParams.forEach(gridItem => {
      gridItem.value = '';
      let thisrel = this.refs[`typeAhead${gridItem.field}`];
      if (thisrel) {
        thisrel.getInstance().clear();
      }
      if (gridItem.field === 'capacityTypeCode') {
        gridItem.options = [];
      }
      gridItem.selected = [];
    });
    this.setState({ searchGroups, searchParams, pageSearch: 0 });
  }
  onSearchFieldUpdate(searchParams) {
    const { searchGroups } = this.state;
    let cc = 0;
    searchGroups.forEach(item => {
      if (item.selected.length > 0 || item.selected.key) {
        cc += 1;
      }
    });
    if (cc === 0) {
      searchParams.find(o => o.field === 'capacityTypeCode').disabled = true;
      searchParams.find(o => o.field === 'capacityTypeCode').options = [];
    }
    // update the state of search fields from the component
    this.setState({ searchParams });
  }

  onSearchGroupUpdate(searchGroups) {
    const { clearUsrFactGrpSearchData } = this.props;
    // update the state of search group fields from the component
    clearUsrFactGrpSearchData();
    this.setState({ searchGroups, pageSearch: 0 });
  }

  onSearchValidation(num) {
    // search form validation
    const { searchGroups, searchParams } = this.state;
    return searchValidator(searchGroups, searchParams);
  }

  onSearchEnterApi() {
    // after selecting all required search params, retriving data
    this.setState({
      gridCurrentPageData: [],
      updateCycleComplete: false
    });

    const {
      defaultStatus,
      userType,
      newSearchParams,
      newSearchgroups,
      gridPagination,
      revisedHorizonWeeks,
      currentPage
    } = this.state;

    const {
      requestGrowthDimensionData,
      requestGrowthDimensionGridColumns,
      currentusername,
      clearGrowthDimensionData
    } = this.props;

    const onSearchValidStatus = this.onSearchValidation(2);
    if (onSearchValidStatus.status) {
      noSearchInitiated = false;
      const searchfields = searchQueryCTFrame(
        newSearchParams,
        revisedHorizonWeeks,
        defaultStatus,
        maxQuarterLimit
      );

      const pageStart =
        currentPage === 1 ? 1 : (currentPage - 1) * searchfields.count + 1;
      const pageEnd =
        currentPage === 1
          ? searchfields.count
          : currentPage * searchfields.count;
      const getGrowthDimensionData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchfields,
        revisedHorizonWeeks: revisedHorizonWeeks,
        pageStart: pageStart,
        pageEnd: pageEnd,
        type: 'bulk',
        factoryCode: fetchAllQueryValue(
          newSearchgroups.find(o => o.field === 'factoryCode'),
          true
        ),
        maxDownloadLimit: maxDownloadLimit
      };
      const actualStartWeekDate = searchfields.actualStartWeekDate;
      this.setState({
        loadGrid: true,
        changedRowsData: [],
        quarterRowDataChanged: [],
        actualStartWeekDate: actualStartWeekDate,
        totalPage: searchfields.pages
      });
      localStorage.setItem('isRowDataChanged', 'false');
      localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
      if (Object.keys(gridPagination).length === 0) {
        // requestGrowthDimensionGridColumns(getGrowthDimensionData);
      }
      requestGrowthDimensionGridColumns(getGrowthDimensionData);
      requestGrowthDimensionData(getGrowthDimensionData); // API  Request function for Grid Data
    } else {
      this.uploadStatusUpdate(onSearchValidStatus);
      noSearchInitiated = true;
      clearGrowthDimensionData({});
    }
  }

  onSearchFactoryGrpApi() {
    const {
      searchGroups,
      searchParams,
      gridPagination,
      revisedHorizonWeeks,
      defaultStatus
    } = this.state;
    noSearchInitiated = true;
    const { getusrFactGrpSearchData } = this.props;
    const allString = searchQueryFrame(
      1,
      searchGroups,
      searchParams,
      gridPagination,
      maxDownloadLimit,
      revisedHorizonWeeks,
      defaultStatus
    );
    if (allString) {
      getusrFactGrpSearchData(allString);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSearchEnter() {
    const { searchGroups, searchParams } = this.state;
    this.setState({
      newSearchgroups: JSON.parse(JSON.stringify(searchGroups)),
      newSearchParams: JSON.parse(JSON.stringify(searchParams))
    });
    // updates the seach box entries, frames query and calls API
    if (
      localStorage.getItem('isRowDataChanged') === 'false' &&
      localStorage.getItem('isGBDQuarterRowDataChanged') === 'false'
    ) {
      this.setState({ changedRowsData: [], quarterRowDataChanged: [] });
    }
    this.setState({
      gridCurrentPageData: [],
      statusCode: '',
      currentPage: 1
    });
    const { changedRowsData, quarterRowDataChanged } = this.state;
    if (changedRowsData.length === 0 && quarterRowDataChanged.length === 0) {
      setTimeout(() => {
        this.onSearchEnterApi();
      }, 300);
      return false;
    } else {
      confirmAlert({
        message: msgConstants.pageChangeAlert,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.setState({ changedRowsData: [], quarterRowDataChanged: [] });
              this.onSearchEnterApi();
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
  }

  onSearchCall() {
    this.setState({ gridPagination: {} });

    setTimeout(() => {
      this.onSearchEnter();
    }, 100);
  }

  getGrowthDimensionType(defaultStatus) {
    // Function call on page load and reload, to initate API request for Grid Header and Data

    const {
      requestGrowthDimensionData,
      requestGrowthDimensionGridColumns,
      currentusername,
      currentusergroups,
      clearGrowthDimensionData,
      requestUserProfileData,
      clearUserProfileData,
      clearUsrFactGrpSearchData,
      requestAppConfigData
    } = this.props;
    this.onSearchAllClear();
    clearUserProfileData({});
    clearGrowthDimensionData({});
    clearUsrFactGrpSearchData();
    requestUserProfileData();
    requestAppConfigData();
    noSearchInitiated = true;
    this.setState({ gridColumnConfig: [], pageSearch: 0 });
    // eslint-disable-next-line prefer-const
    let { userType } = this.state;
    const sessionUserType = window.sessionStorage.getItem('currentUserGroup');
    if (sessionUserType) {
      userType = sessionUserType;
    } else {
      userType = currentusergroups[0].value;
    }
    // setting the factory codes for the default dropdown in serch box

    // end of update
    this.setState({ defaultStatus, userType }); // set new dashboard type
    // condition check to load the entire grid data at first itself or to load only after seach with user inputs
    if (!searchfirst) {
      const { searchBoxFields } = this.state;
      const getGrowthDimensionData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchBoxFields,
        type: 'api'
      };
      requestGrowthDimensionGridColumns(getGrowthDimensionData);
      requestGrowthDimensionData(getGrowthDimensionData); // API  Request function for Grid Data
      localStorage.setItem('isRowDataChanged', 'false');
    }
    /* */
  }

  onSaveClick() {
    this.saveDataChanges();
  }
  saveDataChanges() {
    this.saveProdQuarterData();
  }
  saveProdQuarterData = () => {
    const { updateGrowthDimensionData } = this.props;
    const {
      gbdWeeklyData,
      agGridQuarterParams,
      quarterRowDataChanged,
      gbdAllLevers,
      gbdGroupedColumns,
      defaultStatus,
      userType,
      gbdProdQuartersDataBackUp,
      isToggleOn,
      gridCurrentPageData,
      thresholdCounts,
      gblColumnData
    } = this.state;
    let changedRowsData = [];
    const validatedUploadPQData = [];
    const validatedUploadPQResult = [];
    const currentFilter = agGridQuarterParams.api.getFilterModel();
    if (quarterRowDataChanged.length > 0) {
      const quarterCurrentGrid = [];
      const AllColNames = [];
      const getAllColumns = agGridQuarterParams.columnApi.getAllColumns();
      getAllColumns.forEach(item => {
        const itemis = {
          name: item.colDef.headerName,
          code: item.colId,
          field: item.colDef.field
        };
        AllColNames.push(itemis);
      });

      agGridQuarterParams.api.forEachNodeAfterFilterAndSort(node =>
        quarterCurrentGrid.push(node.data)
      );
      changedRowsData = generateGbDWeekRecordsFromQuarter(
        validatedUploadPQData,
        gbdWeeklyData,
        gbdAllLevers
      );

      quarterRowDataChanged.forEach(qrItem => {
        const foundIndex = quarterCurrentGrid.findIndex(
          i => i.uniQId === qrItem.uniQId
        );
        const backupRow = gbdProdQuartersDataBackUp.find(
          i => i.uniQId === qrItem.uniQId
        );
        if (userType === 'FACTORY' || userType === 'WHQ') {
          const { newActRow, result } = validateDataByRowOnlyOnUpload(
            qrItem,
            JSON.parse(JSON.stringify(qrItem)),
            backupRow,
            userType,
            defaultStatus,
            gbdGroupedColumns,
            gblColumnData
          );
          if (result.length > 0) {
            result.forEach(log => {
              const logNew = { ...log };
              logNew.id = qrItem.uniQId;

              logNew.row = foundIndex + 1;
              logNew.productionQuarter = qrItem.productionQuarter;
              logNew.factoryCode = qrItem.factoryCode;
              logNew.capacityTypeCode = qrItem.capacityTypeCode;
              logNew.weekStartDate = qrItem.weekStartDate;
              validatedUploadPQResult.push(logNew);
            });
          }
          let rowNode = agGridQuarterParams.api.getDisplayedRowAtIndex(
            foundIndex
          );
          rowNode.data = newActRow;
          agGridQuarterParams.api.redrawRows(foundIndex);

          if (newActRow.ChangeByUpload > 0) {
            newActRow.colModified = [];
            validatedUploadPQData.push(newActRow);
          }
        }
      });
      if (validatedUploadPQData.length > 0) {
        const modFormattedUploadData = updateUploadDataFormat(
          validatedUploadPQData,
          gridCurrentPageData,
          defaultStatus,
          isToggleOn
        );
        onConsoleArr(modFormattedUploadData);
        const AllGridData = {
          dashboard: defaultStatus,
          data: modFormattedUploadData,
          type: 'row Update',
          thresholdCounts
        }; // current grid
        updateGrowthDimensionData(AllGridData);
        const AllGridDataCopy = [];
        agGridQuarterParams.api.forEachNode(node =>
          AllGridDataCopy.push(node.data)
        );
        this.setState({
          changedRowsData: [],
          gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
        });

        localStorage.setItem('isRowDataChanged', 'false');
      }
      // this.downloadJSONFile('q', validatedUploadPQData);
      // this.downloadJSONFile('w', changedRowsData);
    }
    let showOnlyPQReport = false;
    if (changedRowsData.length === 0 && validatedUploadPQResult.length > 0) {
      showOnlyPQReport = true;
    }
    this.setState({
      quarterRowDataChanged: [],
      changedRowsData,
      validatedUploadPQResult,
      UploadStatusData: [],
      UploadUpdateModelType: 'Grid',
      UploadUpdateModel: showOnlyPQReport,
      quarterGridCurrentFilter: currentFilter
    });
    localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
    agGridQuarterParams.api.redrawRows();
  };

  downloadJSONFile = async (format, DataSource) => {
    const type = format === 'q' ? 'QUARTERLY' : 'WEELKY';
    const fileName = `${type}-${Date.now()}`;
    const json = JSON.stringify(DataSource);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  callExternalFilter = statusCode => {
    // based on the status wise count, on click on the numbers, paticular data will be filtered in grid
    const { agGridQuarterParams } = this.state;
    this.setState(
      {
        statusCode
      },
      () => {
        let gridParams = agGridQuarterParams;
        if (statusCode === '') {
          gridParams.api.setFilterModel(null);
        }
        gridParams.api.onFilterChanged();
      }
    );
  };

  downloadData = () => {
    // Function to download the current grid data to excel sheet

    // to apply custom footer which will have to drop down values for short horizon and status
    const { defaultStatus, userType, agGridQuarterParams } = this.state;
    const { currentusername } = this.props;
    const fileInfo = agGridDownloadFileName(
      defaultStatus,
      userType,
      'bulk',
      currentusername,
      true
    );

    this.setState({
      recentDownloadedGridFile: fileInfo.fileName,
      recentDownloadedGridFileId: fileInfo.fileID
    });
    const params = {
      fileName: fileInfo.fileName,
      sheetName: fileInfo.sheetName,
      skipColumnGroupHeaders: false,
      processHeaderCallback(hedpar) {
        if (
          hedpar &&
          hedpar.column &&
          hedpar.column.userProvidedColDef &&
          hedpar.column.userProvidedColDef.category &&
          hedpar.column.userProvidedColDef.dimension
        ) {
          return (
            hedpar.column.colDef.headerName +
            ' :: ' +
            hedpar.column.userProvidedColDef.dimension +
            ' :: ' +
            hedpar.column.userProvidedColDef.category
          );
        }
        return hedpar.column.colDef.headerName;
      },
      processCellCallback(paramsl) {
        // data format change for excel
        return agGridDownloadDataFormat(paramsl);
      }
    };
    // for custom header
    let customHeader = JSON.parse(JSON.stringify(pageConstants.excelHeader));
    customHeader[0][1].data.value = AppCipher(fileInfo.fileID);
    params.customHeader = customHeader;
    params.columnWidth = 10;
    params.skipColumnGroupHeaders = false;
    agGridQuarterParams.api.exportDataAsExcel(params);

    toast.success(msgConstants.excelDownloadSuccess, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  toogleUploadModel = () => {
    const { UploadModel } = this.state;
    if (
      localStorage.getItem('isRowDataChanged') === 'false' &&
      localStorage.getItem('isGBDQuarterRowDataChanged') === 'false'
    ) {
      this.setState({
        changedRowsData: [],
        quarterRowDataChanged: [],
        UploadModel: !UploadModel
      });
    } else {
      const { changedRowsData, quarterRowDataChanged } = this.state;
      if (changedRowsData.length > 0 || quarterRowDataChanged.length > 0) {
        confirmAlert({
          message: msgConstants.pageChangeAlert,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  changedRowsData: [],
                  quarterRowDataChanged: []
                });
                localStorage.setItem('isRowDataChanged', 'false');
                localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
                // toggles the upload dialog
                this.setState({ UploadModel: !UploadModel });
              }
            },
            {
              label: 'No'
            }
          ]
        });
      }
    }
  };
  toogleUploadUpdateModel = () => {
    // toggles the upload dialog
    let { UploadStatusData, validatedUploadPQResult } = this.state;
    const { UploadUpdateModel, agGridQuarterParams } = this.state;
    if (UploadUpdateModel) {
      const AllGridDataCopy = [];
      agGridQuarterParams.api.forEachNode(node =>
        AllGridDataCopy.push(node.data)
      );
      this.setState({
        gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
      });
      UploadStatusData = [];
      validatedUploadPQResult = [];
    }
    this.setState({
      UploadUpdateModel: !UploadUpdateModel,
      UploadStatusData,
      validatedUploadPQResult
    });
  };

  toogleValidationModel = () => {
    let { varianceValidationResult } = this.state;
    const { varianceValidationModal } = this.state;
    if (varianceValidationModal) {
      varianceValidationResult = [];
    }
    this.setState({
      varianceValidationModal: !varianceValidationModal,
      varianceValidationResult
    });
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      this.setState({
        editGrid: true,
        UploadingData: false,
        UploadModel: false
      });
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  uploadProcess = data => {
    let jsonDataRaw = data;
    let jsonDataMapped = [];
    const {
      agGridQuarterParams,
      defaultStatus,
      userType,
      prodQuartersDataBackUp,
      gbdGroupedColumns,
      gbdAllLevers,
      gblColumnData
    } = this.state;
    const { updateGrowthDimensionData } = this.props;
    const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
    const userFactory = JSON.parse(userFacList);
    const divisionCode = divisionCodes[userFactory.factoryType];
    const uploadStatus = {
      status: false,
      message: msgConstants.uploadFailNoData
    };
    const activeGridSet = [];
    agGridQuarterParams.api.forEachNode(node => activeGridSet.push(node.data));

    const gridColumns = agGridQuarterParams.columnApi.getAllColumns();

    // validating the raw array and updating the columns structure
    if (jsonDataRaw.length > 0) {
      const toCheck =
        userType === 'FACTORY'
          ? [...gbdAllLevers]
          : divisionCode === 20
          ? ['targetCpty']
          : ['targetHldCpty'];

      jsonDataMapped = frameDataOnUpload(
        jsonDataRaw,
        activeGridSet.length,
        gridColumns,
        pageConstants.excelHeader.length
      );
      jsonDataRaw = [];
      const gbdExcelEditedRows = [];
      jsonDataMapped.forEach((item, i) => {
        const index = activeGridSet.findIndex(i => i.id === item.id);
        const cleanedRow = gbdExcelUploadRowCompare(
          item,
          activeGridSet[index],
          toCheck
        );
        if (cleanedRow && Object.keys(cleanedRow).length > 0) {
          cleanedRow.ChangeByUpload = 1;
          cleanedRow.excelRow = index + 5;
          gbdExcelEditedRows.push(cleanedRow);
        }
      });
      // validating the mapped columns and updating only the editable columns
      if (jsonDataMapped.length > 0) {
        const { activeGrid, validationReport } = validateAllDataOnUpload(
          gbdExcelEditedRows,
          activeGridSet,
          userType,
          defaultStatus,
          gridColumns,
          gbdGroupedColumns,
          gblColumnData
        );

        // assigning the new data to the grid in dashboard
        if (activeGrid.length > 0) {
          if (activeGrid.length > 0) {
            agGridQuarterParams.api.setRowData(activeGrid);
            // send data only for api
            let dataToUploadApi = [];

            // distribute quarterly aggregated values into corresponding weeks.
            const updatedQuarters = activeGrid.filter(
              quarter => quarter.ChangeByUpload > 0
            );

            dataToUploadApi = updateUploadDataFormat(
              updatedQuarters,
              prodQuartersDataBackUp,
              agGridQuarterParams,
              true,
              defaultStatus
            );

            if (validationReport.length > 0) {
              this.setState({
                validatedUploadPQResult: validationReport,
                UploadUpdateModel: true,
                UploadUpdateModelType: 'Excel'
              });
            }

            if (dataToUploadApi.length > 0) {
              const AllGridData = {
                dashboard: defaultStatus,
                data: dataToUploadApi,
                type: 'file Update'
              }; // current grid
              updateGrowthDimensionData(AllGridData);
            } else {
              this.setState({
                updateCycleComplete: true
              });
              if (validationReport.length) {
                uploadStatus.message = msgConstants.uploadFailNoMatchData;
              }
              this.uploadStatusUpdate(uploadStatus);
            }
          } else {
            this.uploadStatusUpdate(uploadStatus);
          }
        } else {
          this.uploadStatusUpdate(uploadStatus);
        }
      } else {
        uploadStatus.message = msgConstants.uploadFailNoMatchData;
        this.uploadStatusUpdate(uploadStatus);
      }
    } else {
      uploadStatus.message = msgConstants.uploadFailFetchErr;
      this.uploadStatusUpdate(uploadStatus);
    }
    this.setState({
      editGrid: true,
      UploadingData: false,
      UploadModel: false,
      isCommentAddedInQuarterView: true
    });
  };

  saveColState = params => {
    // save the column preference and update API
    this.setState({ editGrid: true });

    // Function to call Data Save API to update changes made in AG grid data, either by editing or by uploading
    const getColumnState = params.columnApi.getColumnState();
    const getColumnDefs = params.columnApi.getAllGridColumns();
    const {
      savePreferenceData,
      history,
      currentusername,
      currentuseremail
    } = this.props;
    const { defaultStatus, agGridQuarterParams } = this.state;
    const pQColsAll = [];
    const pQCols2Show = [];
    const pQCols2Hide = [];
    agGridQuarterParams.columnApi.getAllColumns().forEach(column => {
      if (
        ![
          'productionQuarter',
          'factoryCode',
          'capacityTypeCode',
          'id'
        ].includes(column.colId)
      ) {
        pQColsAll.push(column.colId);
      }
    });
    const updateColumnPref = [];
    getColumnState.forEach((column, key) => {
      const eachCol = {
        id: getColumnDefs[key].colDef.toolPanelClass,
        displayUser: !column.hide,
        field: column.colId
      };
      updateColumnPref.push(eachCol);

      if (pQColsAll.includes(column.colId)) {
        if (column.hide) {
          pQCols2Hide.push(column.colId);
        } else {
          pQCols2Show.push(column.colId);
        }
      }
    });
    agGridQuarterParams.columnApi.setColumnsVisible(pQCols2Show, true);
    agGridQuarterParams.columnApi.setColumnsVisible(pQCols2Hide, false);

    const UpdateData = {
      fields: updateColumnPref,
      user: currentusername,
      email: currentuseremail,
      dashboard: defaultStatus
    };
    this.setState({
      saveViewToogle: 1
    });
    savePreferenceData(UpdateData, history);
  };

  onChildGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  getContextMenuItems(params) {
    var result = ['copy'];
    return result;
  }

  fileHandler = event => {
    if (event.target.files.length) {
      this.setState({ UploadingData: true });
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.fileFormatError
      };

      const fileObj = event.target.files[0];
      const fileName = fileObj.name;
      const { recentDownloadedGridFile } = this.state;
      // check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
        if (fileObj.size < 1000) {
          // default file size of
          commentsUpdateStatus.message = msgConstants.uploadFailNoContent;
          this.uploadStatusUpdate(commentsUpdateStatus);
        } else {
          // for conflict name valdiation
          if (recentDownloadedGridFile.length === 0) {
            commentsUpdateStatus.message =
              msgConstants.uploadFailInvalidSession;
            this.uploadStatusUpdate(commentsUpdateStatus);
          } else if (fileName.includes(recentDownloadedGridFile)) {
            //ExcelRenderer to start here
            this.excelRendererFn(fileObj, commentsUpdateStatus);
          } else {
            confirmAlert({
              message: msgConstants.uploadWarnNoMatchFileName,
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    this.excelRendererFn(fileObj, commentsUpdateStatus);
                  }
                },
                {
                  label: 'No',
                  onClick: () => {
                    this.setState({ UploadingData: false, UploadModel: false });
                  }
                }
              ]
            });
          }
        }
      } else {
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    } else {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.uploadFailNoFile
      };

      this.setState({ UploadingData: false, UploadModel: false });
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };

  excelRendererFn(fileObj, commentsUpdateStatus) {
    const { recentDownloadedGridFileId } = this.state;

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        this.uploadStatusUpdate(commentsUpdateStatus);
      } else {
        const fileID = AppDeCipher(resp.rows[0][1]);

        const errorCase = { status: true, message: '' };
        if (!recentDownloadedGridFileId.includes(fileID)) {
          errorCase.status = false;
          errorCase.message = msgConstants.uploadFailNoMatchId;
          this.uploadStatusUpdate(errorCase);
        } else {
          this.uploadProcess(resp.rows);
        }
      }
    });

    setTimeout(() => {
      const { UploadModel } = this.state;
      if (UploadModel) {
        this.setState({
          editGrid: true,
          UploadingData: false,
          UploadModel: false
        });
        commentsUpdateStatus.message = msgConstants.noChangeUpdate;
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    }, 45000);
  }

  goToPage(action) {
    this.setState({ currentPage: action });
    setTimeout(() => {
      this.onSearchEnterApi();
    }, 100);
  }

  reloadGridContentAfterSave = onSaveResp => {
    const { agGridQuarterParams } = this.state;
    if (
      onSaveResp &&
      onSaveResp.outputData &&
      onSaveResp.outputData.updateStatus
    ) {
      const AllGridDataCopy = [];
      agGridQuarterParams.api.forEachNode(node =>
        AllGridDataCopy.push(node.data)
      );
      this.setState({
        gbdProdQuartersDataBackUp: JSON.parse(JSON.stringify(AllGridDataCopy))
      });
    } else {
      const commentsUpdateStatus = {
        status: false,
        message: 'SAVE FAILED!  RETRY'
      };
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };

  setQuarterParams = params => {
    const { gridCurrentPageData, userNotify } = this.state;

    if (params) {
      this.setState({ agGridQuarterParams: params });
      if (gridCurrentPageData.length === 0 || userNotify) {
        const AllGridData = []; // current grid

        params.api.forEachNode(node => AllGridData.push(node.data));
        this.setState({
          gridCurrentPageData: JSON.parse(JSON.stringify(AllGridData))
        });
      }
      setTimeout(() => {
        try {
          //params.api.sizeColumnsToFit();
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      });
    }
  };

  onQuarterCellValueChanged = event => {
    const {
      quarterRowDataChanged,
      agGridQuarterParams,
      gbdProdQuartersDataBackUp,
      userType,
      gbdAllLevers
    } = this.state;

    let rowNode = agGridQuarterParams.api.getDisplayedRowAtIndex(
      event.rowIndex
    );

    const oldRowValue = gbdProdQuartersDataBackUp.find(
      o => o.id === rowNode.data.id
    );
    const editedColumn = event.colDef.field;
    const isValidCostCapacityColumnValue =
      editedColumn.endsWith('Cost') &&
      event.newValue &&
      (isNaN(event.newValue) ||
        Number(event.newValue) < 0 ||
        !event.newValue.toString().match(/^[0-9]+$/))
        ? false
        : true;
    if (
      event.newValue &&
      editedColumn.endsWith('Cap') &&
      event.newValue.length > 11
    ) {
      rowNode.data[editedColumn] = oldRowValue[editedColumn];
    }
    if (
      (oldRowValue[editedColumn] && !event.newValue) ||
      !isValidCostCapacityColumnValue
    ) {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.inValFormatData
      };
      this.uploadStatusUpdate(commentsUpdateStatus);
      rowNode.data[editedColumn] = oldRowValue[editedColumn];
      agGridQuarterParams.api.redrawRows(event.rowIndex);
    } else {
      const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
      const userFactory = JSON.parse(userFacList);
      const divisionCode = divisionCodes[userFactory.factoryType];
      const toCheck =
        userType === 'FACTORY'
          ? [...gbdAllLevers]
          : divisionCode === 20
          ? ['targetCpty']
          : ['targetHldCpty'];

      const cleanedRow = gbdExcelUploadRowCompare(
        rowNode.data,
        oldRowValue,
        toCheck
      );
      agGridQuarterParams.api.redrawRows(event.rowIndex);
      const index = quarterRowDataChanged.findIndex(
        i => i.uniQId === rowNode.data.uniQId
      );
      const sameOld =
        cleanedRow && Object.keys(cleanedRow).length > 0 ? false : true;
      if (sameOld) {
        quarterRowDataChanged.splice(index, 1);
      } else {
        const newRowData = JSON.parse(JSON.stringify(rowNode.data));
        if (index >= 0) {
          quarterRowDataChanged[index] = newRowData;
        } else {
          quarterRowDataChanged.push(newRowData);
        }
      }
      if (quarterRowDataChanged.length > 0) {
        localStorage.setItem('isGBDQuarterRowDataChanged', 'true');
      } else {
        localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
      }
      this.setState({ quarterRowDataChanged });
    }
  };

  // eslint-disable-next-line complexity
  render() {
    // rendering the dashboard content
    const {
      growthDimensionDataLoading,
      growthDimensionData,
      growthDimensionError,
      growthDimensionGridLoading,
      growthDimensionGridColumns,
      growthDimensionGridError,
      userProfileData,
      userProfileError,
      usrFactGrpSearchData,
      usrFactGrpSearchLoading,
      userNotifyData,
      location,
      appConfigData
    } = this.props; // receive from API response

    const {
      defaultStatus,
      searchGroups,
      searchParams,
      pageSearch,
      defaultColDef,
      sideBar,
      frameworkComponents,
      excelStyles,
      context,
      statusCode,
      getRowHeight,
      UploadModel,
      UploadUpdateModel,
      varianceValidationModal,
      UploadStatusData,
      UploadUpdateModelType,
      UploadingData,
      changedRowsData,
      updateCycleComplete,
      updateCycleReport,
      recentDownloadedGridFile,
      gbdProdQuartersData,
      quarterRowDataChanged,
      validatedUploadPQResult,
      varianceValidationResult,
      revisedHorizonWeeks,
      totalPage,
      currentPage,
      userType
    } = this.state;
    const { featureConfig } = appConfigData || {};
    let gridHeight =
      window.innerHeight - 335 < 300 ? 300 : window.innerHeight - 335;
    return (
      <div className={`page-align-items  `}>
        {featureConfig && !featureConfig.lever && <NotFound status="400" />}
        {featureConfig && featureConfig.lever && (
          <Beforeunload
            onBeforeunload={event => {
              if (changedRowsData.length === 0) {
                return true;
              }
              event.preventDefault();
            }}
          >
            {!userProfileError &&
              userProfileData &&
              userProfileData.factoryGroups &&
              userProfileData.factoryGroups.length > 0 &&
              searchfirst && (
                <div className="search-header-row">
                  <div className="search-div">
                    <SearchNew
                      searchGroups={searchGroups}
                      searchFields={searchParams}
                      pageSearch={pageSearch}
                      searchOptions={userProfileData}
                      onSearchEnter={this.onSearchCall}
                      onSearchFactoryGrpApi={this.onSearchFactoryGrpApi}
                      onSearchFieldUpdate={this.onSearchFieldUpdate}
                      onSearchGroupUpdate={this.onSearchGroupUpdate}
                      usrFactGrpSearchData={usrFactGrpSearchData}
                      pageType="searchgrid"
                      key="searchgrid"
                      isToggleOn={true}
                      onToggleClick={this.onToggleClick}
                      userNotify={false}
                      location={location}
                      revisedHorizonWeeks={revisedHorizonWeeks}
                      parentPage="Growth"
                      defaultStatus={defaultStatus}
                    />
                  </div>
                </div>
              )}

            <div className="ncss-container">
              <div className="ncss-row page-header pb0-sm pb2-md">
                <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                  <h3 className="pt3-sm main-heading  c-f-500">
                    {defaultStatus === 'MaxAvailable'
                      ? 'Strategic '
                      : 'Revised '}
                    Capacity Levers
                  </h3>
                </div>
                <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12  pl0-sm pr0-sm pt2-sm">
                      {growthDimensionData &&
                        growthDimensionData.data &&
                        growthDimensionData.data.length !== 0 && (
                          <div className="fl-sm-r ">
                            <button
                              onClick={() => this.onSaveClick()}
                              disabled={
                                (changedRowsData.length === 0 &&
                                  quarterRowDataChanged.length === 0) ||
                                userType === 'NLO'
                              }
                              className="ncss-btn-black  dashboard-button"
                            >
                              <span className="d-sm-h d-md-ib"> Save </span>
                            </button>
                            <button
                              className="ncss-btn-black  dashboard-button"
                              onClick={() => this.toogleUploadModel()}
                              disabled={userType === 'NLO'}
                            >
                              <span className="d-sm-h d-md-ib">Upload</span>
                            </button>
                            <button
                              className="ncss-btn-black  dashboard-button"
                              onClick={() => this.downloadData()}
                            >
                              <span className="d-sm-h d-md-ib">Download</span>
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {!userProfileError &&
                userProfileData &&
                userProfileData.factoryGroups &&
                userProfileData.factoryGroups.length > 0 &&
                searchfirst && (
                  <div className={` `}>
                    <div className="ncss-row page-menu-header">
                      <TabHeader
                        defaultStatus={defaultStatus}
                        userNotifyData={userNotifyData}
                        active="dimension"
                        location={location}
                        featureConfig={featureConfig}
                      />
                      <div className="ncss-col-sm-12 ncss-col-md-7 ta-sm-r"></div>
                    </div>
                  </div>
                )}

              {/* Show Search bar on conditions */}

              <div className={` ncss-row `}>
                <div className="ncss-col-sm-12">
                  <div className="c-f capvis-page-container u-clearfix ">
                    {/* Show Search bar on conditions */}
                    {userProfileError ||
                      (userProfileData && !userProfileData.factoryGroups && (
                        <NotFound status="401" />
                      ))}
                    {/* Show Page Loading Error on failure of API */}
                    {!growthDimensionError &&
                      !growthDimensionGridError &&
                      (!growthDimensionData ||
                        !growthDimensionData.data ||
                        growthDimensionData.data.length === 0) && (
                        <div className="pt2-sm ncss-row text-normal">
                          <div
                            className="flex-parent d-sm-flx-new  flx-jc-sm-c"
                            style={{ minHeight: gridHeight }}
                          >
                            <div className="flex-item p4-sm ta-sm-c flx-as-sm-c fs-18px text-color-grey1">
                              {noSearchInitiated && (
                                <div className="d-sm-flx-new flx-as-sm-c ">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.92 12.8L20 17.9L17.9 20L12.82 14.92C11.4067 15.88 9.86001 16.36 8.18 16.36C5.92666 16.36 4.00001 15.56 2.4 13.96C0.799992 12.36 0 10.4333 0 8.18C0 5.92666 0.799992 4.00001 2.4 2.4C4.00001 0.799992 5.92666 0 8.18 0C10.4333 0 12.36 0.799992 13.96 2.4C15.56 4.00001 16.36 5.92666 16.36 8.18C16.36 9.87334 15.88 11.4133 14.92 12.8ZM13.62 8.18C13.62 6.67333 13.0933 5.38667 12.04 4.32C10.9867 3.25333 9.70001 2.72 8.18 2.72C6.65999 2.72 5.37334 3.25333 4.32 4.32C3.26666 5.38667 2.73333 6.67333 2.72 8.18C2.70667 9.68667 3.23999 10.9667 4.32 12.02C5.40001 13.0733 6.68666 13.6067 8.18 13.62C9.67334 13.6333 10.96 13.1 12.04 12.02C13.12 10.94 13.6467 9.66001 13.62 8.18Z"
                                      fill="#757575"
                                    />
                                  </svg>
                                  &nbsp; Search for a Factory Group or Code to
                                  begin
                                </div>
                              )}
                              {!noSearchInitiated &&
                                !growthDimensionDataLoading && (
                                  <div className="flx-as-sm-c">
                                    <strong> No data Available </strong>
                                    <br />
                                    The current account might not be mapped with
                                    any factories or roles
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}
                    {(growthDimensionDataLoading ||
                      growthDimensionGridLoading ||
                      usrFactGrpSearchLoading) && (
                      <div>
                        <Spinner large />
                      </div>
                    )}

                    {/* Show Page Loading Error on failure of API */}
                    {!noSearchInitiated &&
                      (growthDimensionError || growthDimensionGridError) &&
                      (!growthDimensionDataLoading ||
                        !growthDimensionGridLoading) && (
                        <PageError response={growthDimensionError} />
                      )}

                    {/* Show Counts and Grid */}
                    <div className="vertical-align-baseline   c-f-all c-f-border-5">
                      <div className="container p0-top-sm m0-sm">
                        {growthDimensionData &&
                          !growthDimensionDataLoading &&
                          (growthDimensionGridColumns &&
                            !growthDimensionGridLoading) && (
                            <div className={`u-clearfix `}>
                              <div>
                                {growthDimensionData.data &&
                                growthDimensionData.data.length > 0 &&
                                growthDimensionGridColumns.columns ? (
                                  <div>
                                    <div className="ncss-row    p0-sm   ml0-sm mr0-sm">
                                      {''}
                                    </div>
                                    <div
                                      className={`r-${growthDimensionData.data.length}  c-${growthDimensionGridColumns.columns.length}`}
                                    >
                                      {gbdProdQuartersData &&
                                        gbdProdQuartersData.length > 0 && (
                                          <div
                                            id="myQuarterGrid"
                                            className="ag-theme-balham g-b-d "
                                          >
                                            <Grid
                                              columnDefs={
                                                growthDimensionGridColumns.columns
                                              }
                                              defaultColDef={{
                                                resizable: true,
                                                enableBrowserTooltips: false,
                                                ...defaultColDef
                                              }}
                                              rowData={gbdProdQuartersData}
                                              floatingFilter
                                              showFilter
                                              frameworkComponents={
                                                frameworkComponents
                                              }
                                              excelStyles={excelStyles}
                                              context={context}
                                              statusCode={statusCode}
                                              defaultStatus={defaultStatus}
                                              getRowHeight={getRowHeight}
                                              headerHeight={42}
                                              dbGridFunctions
                                              getContextMenuItems={
                                                this.getContextMenuItems
                                              }
                                              setParams={this.setQuarterParams}
                                              onCellValueChanged={
                                                this.onQuarterCellValueChanged
                                              }
                                              enableRangeSelection={true}
                                              sideBar={sideBar}
                                              setheight={gridHeight}
                                            />
                                            {/* Pagination       */}
                                            {growthDimensionData.data && (
                                              <GBDPagination
                                                totalPage={totalPage}
                                                currentPage={currentPage}
                                                goToPage={this.goToPage}
                                              />
                                            )}
                                          </div>
                                        )}
                                    </div>
                                    <div className="u-clearfix" />
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          )}

                        {usrFactGrpSearchData &&
                          usrFactGrpSearchData.length === 0 &&
                          pageSearch === 1 && (
                            <div className="bg-white border-light-grey p3-sm ta-sm-l">
                              <H1>
                                No Factory Codes under selected Factory Group is
                                Associated for You !
                              </H1>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <UploadModal
                    UploadModel={UploadModel}
                    UploadingData={UploadingData}
                    fileHandler={this.fileHandler}
                    toogleUploadModel={this.toogleUploadModel}
                    recentDownloadedGridFile={recentDownloadedGridFile}
                  />
                  <UploadUpdateModal
                    UploadUpdateModel={UploadUpdateModel}
                    UploadStatusData={UploadStatusData}
                    validatedUploadPQResult={validatedUploadPQResult}
                    toogleUploadUpdateModel={this.toogleUploadUpdateModel}
                    UploadUpdateModelType={UploadUpdateModelType}
                    updateCycleComplete={updateCycleComplete}
                    updateCycleReport={updateCycleReport}
                    isQuarterlyUpdate={true}
                  />
                  <GBDVarianceValidationModal
                    varianceValidationModal={varianceValidationModal}
                    varianceValidationResult={varianceValidationResult}
                    toogleValidationModel={this.toogleValidationModel}
                  />
                </div>
              </div>
            </div>
          </Beforeunload>
        )}
      </div>
    );
  }
}

GrowthbyDimension.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  requestAppConfigData: PropTypes.func,
  clearUserProfileData: PropTypes.func,
  growthDimensionDataLoading: PropTypes.bool,
  growthDimensionData: PropTypes.object,
  growthDimensionError: PropTypes.any,
  growthDimensionGridLoading: PropTypes.bool,
  growthDimensionGridColumns: PropTypes.object,
  growthDimensionGridError: PropTypes.any,
  requestGrowthDimensionData: PropTypes.func,
  clearGrowthDimensionData: PropTypes.func,
  requestGrowthDimensionGridColumns: PropTypes.func,
  updateGrowthDimensionGridColumns: PropTypes.func,
  updateGrowthDimensionData: PropTypes.func,
  clearUpdateGrowthDimensionData: PropTypes.func,
  updateGrowthDimensionComment: PropTypes.func,
  savePreferenceData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  getUsrFactGrpSearchData: PropTypes.object,
  growthDimensionDataUpdate: PropTypes.any,
  appConfigData: PropTypes.object,
  appConfigLoading: PropTypes.any,
  appConfigError: PropTypes.object,
  commentUpdateStatus: PropTypes.any,
  commentsLogsData: PropTypes.any
};

const mapStateToProps = state => ({
  growthDimensionDataLoading: getGrowthDimensionDataLoading(state),
  growthDimensionData: getGrowthDimensionData(state),
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  growthDimensionError: getGrowthDimensionError(state),
  growthDimensionGridLoading: getGrowthDimensionGridLoading(state),
  growthDimensionGridColumns: getGrowthDimensionGridColumns(state),
  growthDimensionGridError: getGrowthDimensionGridError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  usrFactGrpSearchData: getusrFactGrpSearchData(state),
  usrFactGrpSearchLoading: getusrFactGrpSearchLoading(state),
  usrFactGrpSearchError: getusrFactGrpSearchError(state),
  growthDimensionDataUpdate: getGrowthDimensionDataUpdate(state),
  appConfigData: getAppConfigData(state),
  appConfigLoading: getAppConfigLoading(state),
  appConfigError: getAppConfigError(state),
  commentUpdateStatus: getUpdateGrowthDimensionCommentStatus(state),
  commentsLogsData: getCommentsLogsData(state),
  commentsLogsDataLoading: getCommentsLogsDataLoading(state),
  userNotifyData: getUserNotifyData(state)
});

const mapDispatchToProps = dispatch => ({
  requestGrowthDimensionData: status => {
    // Get data to show on grid
    dispatch(createServiceAction('growthDimensionData', 'requested')(status));
  },
  requestUserProfileData: () => {
    dispatch(createServiceAction('getUserProfile', 'requested')());
  },
  requestAppConfigData: () => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('appConfigData', 'requested')());
  },
  clearUserProfileData: () => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('getUserProfile', 'clear')(null));
  },
  getusrFactGrpSearchData: query => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'requested')(query));
  },
  clearUsrFactGrpSearchData: () => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'clear')(null));
  },
  clearGrowthDimensionData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('growthDimensionData', 'clear')(status));
    dispatch(createServiceAction('growthDimensionError', 'clear')({}));
  },
  clearGrowthDimensionGridColumns: columndata => {
    // Get data to show on graph and count
    dispatch(
      createServiceAction('growthDimensionGridColumns', 'clear')(columndata)
    );
    dispatch(createServiceAction('growthDimensionGridError', 'clear')({}));
  },
  updateGrowthDimensionGridColumns: columndata => {
    dispatch(
      createServiceAction('growthDimensionGridColumns', 'update')(columndata)
    );
  },
  requestGrowthDimensionGridColumns: requestHeader => {
    // Get data to show on graph and count
    dispatch(
      createServiceAction('growthDimensionGridColumns', 'requested')(
        requestHeader
      )
    );
  },
  savePreferenceData: (data, history) => {
    dispatch(
      createServiceAction('savePreferenceData', 'submited')({
        ...data,
        history
      })
    );
  },
  updateGrowthDimensionComment: (data, history) => {
    dispatch(
      createServiceAction('updateGrowthDimensionComment', 'submited')({
        ...data,
        history
      })
    );
  },
  updateGrowthDimensionData: data => {
    dispatch(
      createServiceAction('growthDimensionDataUpdate', 'submited')({ ...data })
    );
  },
  clearUpdateGrowthDimensionData: data => {
    dispatch(createServiceAction('growthDimensionDataUpdate', 'clear')({}));
  },
  requestCommentsLogsData: data => {
    dispatch(createServiceAction('commentsLogsData', 'requested')(data));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GrowthbyDimension)
);
