import * as moment from 'moment';
import _ from 'lodash';
import gridConstants from '../views/dashboard/Dashboard.constants.js';

export const isEditable = (field, userType, dashboard, appConfig) => {
  const factoryEditableColumns = [
    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'shortHorizonCapacityChange',
    'maxAvailableReasonCode',
    'shortHorizonReasonCode',
    'FctyMaxCapacity',
    'maxCapacityReasonCode'
  ];
  const maxCapacityColumns = ['FctyMaxCapacity', 'maxCapacityReasonCode'];
  const NloEditableColumns = [
    'approvedRevisedCapacity',
    'maxAvailableUnitsConfirmed',
    'shortHorizonUnitsConfirmed'
  ];
  const WhqEditableColumns = ['approvedRevisedCapacity', 'approvedMaxCapacity'];

  if (
    (userType === 'FACTORY' || userType === 'GSM') &&
    factoryEditableColumns.includes(field)
  ) {
    if (maxCapacityColumns.includes(field)) {
      if (
        dashboard === 'SHORTHORIZON' &&
        appConfig &&
        appConfig.revisedViewConfig
      ) {
        const revConfig = appConfig.revisedViewConfig;
        return revConfig.maxCapacityEditable;
      }
      // MAXAVAILABLE

      return true;
    }
    return true;
  }
  if (userType === 'NLO' && NloEditableColumns.includes(field)) {
    return true;
  }
  if (userType === 'WHQ' && WhqEditableColumns.includes(field)) {
    return true;
  }
  if (field === 'MaxAvailableNotes' || field === 'ShortHorizonNotes') {
    return true;
  }
  return false;
};

export const getWidth = (field, filter, role) => {
  let defaultCellWidth = 120;
  if (filter === 'NUMBER') {
    defaultCellWidth = 60;
  } else if (filter === 'DATE') {
    defaultCellWidth = 130;
  } else if (filter === 'OPTIONS') {
    defaultCellWidth = 200;
  }
  if (gridConstants.gridWidth) {
    const ConditionalWidth = gridConstants.gridWidth.find(
      o => o.field === field
    );
    if (ConditionalWidth && ConditionalWidth.width) {
      defaultCellWidth = ConditionalWidth.width;
    }
  }
  if (role === 'FACTORY' && field === 'factoryCode') {
    defaultCellWidth = 150;
  }
  return defaultCellWidth;
};

export const stratDeltaCalculator = (capacity, weeklyCapacityQuantity) => {
  let deltaPercent = '';
  if (capacity || capacity === 0 || capacity === '0') {
    if (weeklyCapacityQuantity > 0) {
      let temp =
        ((Number(capacity) - Number(weeklyCapacityQuantity)) /
          Number(weeklyCapacityQuantity)) *
        100;
      deltaPercent =
        temp >= 100
          ? 100
          : temp <= -100
          ? -100
          : (Math.round(temp * 10) / 10).toFixed(1);
    } else if (
      (weeklyCapacityQuantity === 0 || weeklyCapacityQuantity === '0') &&
      (capacity === 0 || capacity === '0')
    ) {
      deltaPercent = 0;
    } else if (weeklyCapacityQuantity === 0 || weeklyCapacityQuantity === '0') {
      deltaPercent = 100;
    }
  }
  return `${deltaPercent}`;
};

export const rvsdDeltaCalculator = ({
  shortHorizonCapacity: capacity,
  weeklyCapacityQuantity: weCaQu,
  weeklyRevisedCapacityQuantity: weReCaQu
}) => {
  let deltaPercent = '';
  let revisedDeltaCondition = '';

  if (weReCaQu || weReCaQu === 0 || weReCaQu === '0')
    revisedDeltaCondition = Number(weReCaQu);
  else if (weCaQu || weCaQu === 0 || weCaQu === '0')
    revisedDeltaCondition = Number(weCaQu);

  if (capacity || capacity === 0 || capacity === '0') {
    if (revisedDeltaCondition > 0) {
      let temp =
        ((Number(capacity) - Number(revisedDeltaCondition)) /
          Number(revisedDeltaCondition)) *
        100;

      deltaPercent =
        temp >= 100
          ? 100
          : temp <= -100
          ? -100
          : (Math.round(temp * 10) / 10).toFixed(1);
    } else if (
      revisedDeltaCondition === 0 &&
      (capacity === 0 || capacity === '0')
    ) {
      deltaPercent = 0;
    } else if (revisedDeltaCondition === 0) {
      deltaPercent = 100;
    }
  }
  return `${deltaPercent}`;
};

export const holidayCalculator = (field, paramsdata) => {
  let {
    workingDays,
    numberOfHolidays,
    maxAvailableCapacity,
    maxAvailableUnitsConfirmed,
    approvedMaxCapacity,
    shortHorizonCapacity,
    shortHorizonUnitsConfirmed,
    approvedRevisedCapacity,
    FctyMaxCapacity,
    targetCpty,
    targetHldCpty
  } = paramsdata;
  let retVal = '';
  workingDays = workingDays ? workingDays : 0;
  const holidays = numberOfHolidays || 0;
  const availableWorkingDays =
    workingDays - holidays > 0 ? workingDays - holidays : 0;
  let field2calculate = '';

  if (workingDays !== 0) {
    switch (field) {
      case 'FctyStratHldCpty':
        field2calculate = maxAvailableCapacity;
        break;
      case 'ConfrndStratHldCpty':
        field2calculate = maxAvailableUnitsConfirmed;
        break;
      case 'AprvdStratHldCpty':
        field2calculate = approvedMaxCapacity;
        break;
      case 'FctyRvsdHldCpty':
        field2calculate = shortHorizonCapacity;
        break;
      case 'ConfrndRvsdHldCpty':
        field2calculate = shortHorizonUnitsConfirmed;
        break;
      case 'AprvdRvsdHldCpty':
        field2calculate = approvedRevisedCapacity;
        break;
      case 'FctyMaxHldCpty':
        field2calculate = FctyMaxCapacity;
        break;
      case 'targetHldCpty':
        field2calculate = targetCpty;
        break;
      case 'targetCpty':
        field2calculate = targetHldCpty;
        break;

      default:
        field2calculate = '';
    }
    if (
      field2calculate === 0 ||
      field2calculate === '0' ||
      (field2calculate && Number(field2calculate) > 0)
    ) {
      if (field === 'targetCpty') {
        retVal =
          Number(field2calculate) === 0 && Number(availableWorkingDays) === 0
            ? 0
            : (field2calculate / availableWorkingDays) * workingDays;
      } else {
        retVal =
          Number(field2calculate) === 0 && Number(workingDays) === 0
            ? 0
            : (field2calculate / workingDays) * availableWorkingDays;
      }

      if (
        retVal % 1 !== 0 &&
        (field === 'targetCpty' || field === 'targetHldCpty')
      ) {
        retVal = Math.round(retVal);
      } else if (retVal % 1 !== 0) {
        retVal = (Math.round(retVal * 10) / 10).toFixed(1); // retVal.toFixed(1);
      }
      if (retVal === 'Infinity' || retVal === Infinity) {
        retVal = 0;
      }
    }
  }

  return retVal;
};

export const numberFormatter = value => {
  if (!isNaN(value)) {
    const na = String(value).split('.');
    const n2 = na[1] ? `.${na[1]}` : '';
    return `${na[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${n2}`;
  }
  return value.toLocaleString('en-US');
};

export const configureByHeaderName = (name, configuration) => {
  const columnPreference = configuration;
  if (name === 'Notes') {
    columnPreference.cellRenderer = 'cellLinkRenderer';
  }
  if (name === 'Notes' || name === 'Id') {
    columnPreference.filter = false;
    columnPreference.unSortIcon = false;
    columnPreference.floatingFilterComponentParams = {
      suppressFilterButton: true
    };
  }
  return columnPreference;
};

export const configureByHeaderField = (
  field,
  configuration,
  userFactoryType
) => {
  const columnPreference = configuration;
  if (field === 'maxAvailableDelta' || field === 'shortHorizonDelta') {
    columnPreference.filter = 'agTextColumnFilter';
    columnPreference.filterParams = { filterOptions: ['equals'] };
  }
  if (field === 'maxAvailableDelta') {
    columnPreference.valueGetter = params => {
      const { maxAvailableCapacity, weeklyCapacityQuantity } = params.data;
      return stratDeltaCalculator(maxAvailableCapacity, weeklyCapacityQuantity);
    };
    columnPreference.valueFormatter = params =>
      `${params.value}${params.value ? '%' : ''}`;
  }
  if (field === 'shortHorizonDelta') {
    columnPreference.valueGetter = params => {
      const {
        shortHorizonCapacity,
        weeklyCapacityQuantity,
        weeklyRevisedCapacityQuantity
      } = params.data;
      return rvsdDeltaCalculator({
        shortHorizonCapacity,
        weeklyCapacityQuantity,
        weeklyRevisedCapacityQuantity
      });
    };
    columnPreference.valueFormatter = params =>
      `${params.value}${params.value ? '%' : ''}`;
  }

  const holidayConfigureFields = [
    'FctyStratHldCpty',
    'ConfrndStratHldCpty',
    'AprvdStratHldCpty',
    'FctyRvsdHldCpty',
    'ConfrndRvsdHldCpty',
    'AprvdRvsdHldCpty',
    'FctyMaxHldCpty'
  ];
  // const targetCptyFld =
  //   userFactoryType === 'FW' ? 'targetHldCpty' : 'targetCpty';
  // if (!holidayConfigureFields.includes(targetCptyFld)) {
  //   holidayConfigureFields.push(targetCptyFld);
  // }
  if (holidayConfigureFields.includes(field)) {
    columnPreference.valueGetter = params => {
      return holidayCalculator(field, params.data);
    };
  }

  // to hide id from grid
  if (field === 'id' || field === 'Id') {
    columnPreference.width = 1;
    columnPreference.cellStyle = { width: 10, opacity: 0, padding: 0 };
    columnPreference.lockVisible = true;
  }
  if (field === 'productionQuarter') {
    columnPreference.filter = 'agSetColumnFilter';
    columnPreference.filterParams = { selectAllOnMiniFilter: true };

    columnPreference.valueFormatter = params => {
      const yearIs = String(params.value) || '';
      if (params.value) {
        return `${yearIs.substring(0, 4)}-Q${yearIs.substring(4, 5)}`;
      }
      return params.value;
    };
  }
  if (field === 'capacityTypeCode') {
    columnPreference.filter = 'agSetColumnFilter';
    columnPreference.filterParams = { selectAllOnMiniFilter: true };
  }
  if (
    field === 'factoryCode' ||
    field === 'weekStartDate' ||
    field === 'capacityTypeCode'
  ) {
    columnPreference.pinned = 'left';
  }

  if (field === 'numberOfHolidays') {
    columnPreference.valueFormatter = params =>
      `${params.value ? params.value : 0}`;
  }
  return columnPreference;
};

export const configureByHeaderOptions = (
  role,
  item,
  columnOptions,
  configuration
) => {
  const columnPreference = configuration;
  // if data has option to select values from drop down assigning the values and declaring select edit field for column cell
  columnOptions.forEach(colitem => {
    if (item.field === colitem.header) {
      const optSelectArr = [''];
      const optFilterArr = [null];
      colitem.options.forEach(colmItemOptn => {
        if (colmItemOptn.status === 'ACTIVE') {
          optSelectArr.push(colmItemOptn.value);
        }
        optFilterArr.push(colmItemOptn.value);
      });
      if (
        (role !== 'factory' || role !== 'GSM') &&
        (item.field === 'shortHorizonStatus' ||
          item.field === 'maxAvailableStatus')
      ) {
        // optSelectArr.push('Rejected');optFilterArr.push('Rejected');
      }

      if (item.filter === 'OPTIONS') {
        columnPreference.filter = 'agSetColumnFilter';
        columnPreference.filterParams = {
          cellHeight: 20,
          values: _.union(optFilterArr),
          debounceMs: 1000,
          suppressRemoveEntries: true
        };
      }

      columnPreference.cellEditor = 'agRichSelectCellEditor';
      columnPreference.cellStyle = {
        fontSize: '13px',
        lineHeight: '22px'
      };
      columnPreference.cellEditorParams = () => ({
        values: _.union(optSelectArr),
        formatValue(value) {
          return value === '' ? '(blank)' : value;
        }
      });
    }
  });
  return columnPreference;
};

export const configureClass = field => {
  let className = '';
  switch (field) {
    case '':
      className = 'LapisBlue';
      break;
    case 'maxAvailableCapacity':
    case 'FctyStratHldCpty':
    case 'maxAvailableDelta':
    case 'maxAvailableReasonCode':
    case 'shortHorizonReasonCode':
    case 'shortHorizonDelta':
    case 'FctyRvsdHldCpty':
    case 'shortHorizonCapacity':
      className = 'SapphireBlue';
      break;
    case 'shortHorizonConfirmedBy':
    case 'ConfrndRvsdHldCpty':
    case 'shortHorizonUnitsConfirmed':
    case 'shortHorizonStatus':
    case 'maxAvailableStatus':
    case 'maxAvailableUnitsConfirmed':
    case 'ConfrndStratHldCpty':
    case 'maxAvailableConfirmedBy':
      className = 'Violet';
      break;

    case 'approvedRevisedCapacity':
    case 'AprvdRvsdHldCpty':
    case 'shortHorizonApprovedBy':
    case 'maxAvailableNotes':
    case 'shortHorizonNotes':
    case 'approvedMaxCapacity':
    case 'AprvdStratHldCpty':
    case 'maxAvailableApprovedBy':
      className = 'SilkBlue';
      break;
    default:
      className = 'GreyCloud';
  }
  return className;
};

export const dashboardGridConfigure = data => {
  // Function to create AG grid based header based on Colum API values
  const {
    reportColumns,
    columnOptions,
    role,
    dashboard,
    appConfig,
    userFactoryType
  } = data;

  const gridConfig = [];

  if (reportColumns) {
    reportColumns.forEach(item => {
      // setting providing width based on header character length -
      // update default header column properties for each
      let columnPreference = {
        headerName: item.headerName,
        toolPanelClass: item.id,
        tooltipField: item.headerName,
        field: item.field,
        hide: !item.displayUser,

        width: getWidth(item.field, item.field, role),
        suppressMenu: true,
        icons: {
          sortNone: '<i class="fas fa-sort"></i>',
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        editable: isEditable(item.field, role, dashboard, appConfig),
        headerClass: `head${configureClass(item.field)}`
      };

      if (item.src === 'PMD') {
        columnPreference.headerClass = 'headGreyCloud';
      } else if (item.src === 'CapVis') {
        columnPreference.headerClass = 'headLapisBlue';
      }

      // identify the grid column filter type  and update with ag grid value in filtertype
      // identify the grid column field type  and update with ag grid value in editorType
      if (item.filter === 'TEXT') {
        columnPreference.filter = 'agTextColumnFilter';
      } else if (item.filter === 'NUMBER') {
        columnPreference.filter = 'agNumberColumnFilter';
        columnPreference.cellEditor = 'numericEditor';
        const hideNumberFormat = [
          'maxAvailableDelta',
          'shortHorizonDelta',
          'productionQuarter'
        ];
        if (hideNumberFormat.indexOf(item.field) === -1) {
          columnPreference.valueFormatter = params => {
            if (params.value && !isNaN(params.value)) {
              return numberFormatter(params.value);
            }

            return params.value;
          };
        }
      }
      if (item.filter === 'DATE') {
        columnPreference.filter = 'agDateColumnFilter';
        columnPreference.valueFormatter = params =>
          moment(params.value).format('MM/DD/YYYY');
        columnPreference.filterParams = {
          comparator(filterLocalDateAtMidnight, cellValue) {
            const dateAsString = cellValue;
            if (dateAsString == null) return -1;
            const momentdate = moment(cellValue).format('MM/DD/YYYY');
            const cellDate = Date.parse(momentdate);
            const filterdate = Date.parse(filterLocalDateAtMidnight);
            if (filterdate === cellDate) {
              return 0;
            }
            if (cellDate < filterdate) {
              return -1;
            }
            if (cellDate > filterdate) {
              return 1;
            }
          },
          browserDatePicker: true
        };
      }

      // configuration declared based on header fields
      const configureByHeaderFieldOptions = [
        'maxAvailableDelta',
        'shortHorizonDelta',
        'id',
        'Id',
        'productionQuarter',
        'factoryCode',
        'weekStartDate',
        'capacityTypeCode',
        'workingDays',
        'numberOfHolidays',
        'FctyStratHldCpty',
        'ConfrndStratHldCpty',
        'AprvdStratHldCpty',
        'FctyRvsdHldCpty',
        'ConfrndRvsdHldCpty',
        'AprvdRvsdHldCpty',
        'FctyMaxHldCpty',
        'targetCpty',
        'targetHldCpty'
      ];
      if (configureByHeaderFieldOptions.includes(item.field)) {
        columnPreference = configureByHeaderField(
          item.field,
          columnPreference,
          userFactoryType
        );
      }

      // configuration declared based on header names
      const configureByHeaderNameOptions = ['Notes', 'Id'];
      if (configureByHeaderNameOptions.includes(item.headerName)) {
        columnPreference = configureByHeaderName(
          item.headerName,
          columnPreference
        );
      }

      if (item.nonInputType) {
        columnPreference.cellRenderer = item.nonInputType;
      }
      // configuration declared based on header options
      // hardcoding reasoncodes to maxre

      // identify the grid column header type and update header class

      columnPreference.cellClass = params => {
        let editableClass = configureClass(item.field);
        if (item.src === 'PMD') {
          editableClass = 'GreyCloud';
        } else if (item.src === 'CapVis') {
          editableClass = 'SilkBlue';
        }
        if (columnPreference.editable === true) {
          editableClass = 'editableCol';
        }
        if (
          params.value === 0 ||
          (params.value && params.value.toString().length > 0)
        ) {
          const {
            shortHorizonEditedBy,
            maxAvailableEditedBy,
            maxAvailableCapacity,
            shortHorizonCapacity
          } = params.data;

          const NLOEditable = [
            'maxAvailableUnitsConfirmed',
            'approvedMaxCapacity',
            'maxAvailableConfirmedBy',
            'maxAvailableApprovedBy',
            'shortHorizonUnitsConfirmed',
            'approvedRevisedCapacity',
            'shortHorizonConfirmedBy',
            'shortHorizonApprovedBy'
          ];

          const WHQEditable = [
            'approvedMaxCapacity',
            'maxAvailableApprovedBy',
            'approvedRevisedCapacity',
            'shortHorizonApprovedBy'
          ];

          const CPCol =
            dashboard === 'SHORTHORIZON'
              ? shortHorizonEditedBy
              : maxAvailableEditedBy;

          if (CPCol === 'FACTORY' && NLOEditable.includes(item.field)) {
            editableClass = 'editableYellowColumn';
          }
          if (CPCol === 'NLO' && WHQEditable.includes(item.field)) {
            editableClass = 'editableYellowColumn';
          }
          if (item.field === 'FctyMaxCapacity') {
            const CapVal =
              dashboard === 'SHORTHORIZON'
                ? shortHorizonCapacity
                : maxAvailableCapacity;

            if (Number(CapVal) > Number(params.value)) {
              editableClass = 'peachOrange';
            }
          }

          if (item.field === 'id') {
            editableClass = `whiteText`;
          }
          return editableClass;
        }

        return editableClass;
      };

      gridConfig.push(columnPreference);
    });
  }

  if (columnOptions && reportColumns) {
    const reasonCode = columnOptions.find(o => o.header.includes('ReasonCode'));

    const reasonCodeOptions = reasonCode.options || [];

    if (
      reportColumns.findIndex(o => o.field === 'maxCapacityReasonCode') >= 0
    ) {
      columnOptions.push({
        header: 'maxCapacityReasonCode',
        options: reasonCodeOptions
      });
    }
    gridConfig.forEach(colItem => {
      const colOpt =
        columnOptions.find(o => o.header === colItem.field) || null;

      if (colOpt) {
        const optSelectArr = [''];
        const optFilterArr = [null];
        colOpt.options.forEach(colmItemOptn => {
          if (colmItemOptn.status === 'ACTIVE') {
            optSelectArr.push(colmItemOptn.value);
          }
          optFilterArr.push(colmItemOptn.value);
        });

        colItem.filter = 'agSetColumnFilter';
        colItem.cellEditor = 'agRichSelectCellEditor';
        colItem.cellStyle = {
          fontSize: '13px',
          lineHeight: '22px'
        };
        colItem.filterParams = {
          cellHeight: 20,
          values: _.union(optFilterArr),
          debounceMs: 1000,
          suppressRemoveEntries: true
        };

        colItem.cellEditorParams = () => ({
          values: _.union(optSelectArr),
          formatValue(value) {
            return value === '' ? '(blank)' : value;
          }
        });
      }
    });
  }

  return gridConfig;
};
