import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch ADMIN_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')({}));
    yield put(createServiceAction(serviceType, 'dashboardData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));

    const options = {
      adminType: action.data
    };

    // Trigger Call to API endpoint
    const res = yield call(
      getRequest,
      apiConfig.adminData.endPointUrl,
      options,
      true
    );

    if (res.status === 200) {
      // Parse response object
      const response = yield res.json();
      // Dispatch ADMIN_DATA_LOADING action with response object
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'success')(response));
    } else {
      const errorMsg = { status: res.status, msg: res.statusText };
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'error')(errorMsg));
    }
  } catch (e) {
    // Dispatch ADMIN_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* adminDataSagas() {
  const serviceType = 'adminData';
  // Listen to ADMIN_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default adminDataSagas;
