// Temporarly disabling the replaceApiUrl feature and giving static url,
// as cors and header details need to be manually updated in API gateway for each endpoint.
import 'dotenv/config';

const API_URL = process.env.REACT_APP_API;
const CRI_CAPVIS_API_PATH = process.env.REACT_APP_CRI_CAPVIS_API_PATH;
const CRI_NOTIFY_API_PATH = process.env.REACT_APP_CRI_NOTIFY_API_PATH;

export function getAPIHost(type) {
  let retUrl = `${API_URL}`; // use default dev environment
  const cURl = window.location.href;

  const apiPorts = {
    dev: {
      host: 'execute-api.us-west-2.amazonaws.com/dev',
      defaultPath: '7g3qcplff6',
      notificationPath: 'ygmanxvvl6'
    },
    stage: {
      host: 'execute-api.us-west-2.amazonaws.com/stage',
      defaultPath: 'ynmiesylg7',
      notificationPath: 's4d6nyws94'
    },
    prod: {
      host: 'execute-api.us-west-2.amazonaws.com/prod',
      defaultPath: 'z5tlt5sxfh',
      notificationPath: '5d49l1knfl'
    },
    regression: {
      host: 'execute-api.us-west-2.amazonaws.com/dev',
      defaultPath: CRI_CAPVIS_API_PATH || '7g3qcplff6',
      notificationPath: CRI_NOTIFY_API_PATH || 'ygmanxvvl6'
    }
  };
  if (!API_URL) {
    let envr = 'prod';
    if (cURl.includes('localhost')) {
      let apiLocEnv = localStorage.getItem('apiLocEnv');
      if (apiLocEnv) {
        envr = localStorage.getItem('apiLocEnv');
      } else {
        envr = 'dev';
        localStorage.setItem('apiLocEnv', 'dev');
      }
    } else if (cURl.includes('gsm-capviz-client-dev')) {
      envr = 'dev';
    } else if (
      cURl.includes('gsm-capviz-client-stage') ||
      cURl.includes('capacityvisibility-nonprod')
    ) {
      envr = 'stage';
    } else if (cURl.includes('capvis-regression')) {
      envr = 'regression';
    }
    let pathIs = apiPorts[envr].defaultPath;
    switch (type) {
      case 'Notification':
        pathIs = apiPorts[envr].notificationPath;
        break;

      default:
        pathIs = apiPorts[envr].defaultPath;
    }
    retUrl = `https://${pathIs}.${apiPorts[envr].host}`;
  }
  return `${retUrl}/`;
}

export const serverHost = getAPIHost();
export const serverHostNotifications = getAPIHost('Notification');
const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
const userFactory = JSON.parse(userFacList);
export const apiConfig = {
  dashboardData: {
    name: 'dashboardData',
    endPointUrl: `${serverHost}sample-data/`,
    method: 'GET'
  },
  dashboardPmdData: {
    name: 'dashboardPmdData',
    endPointUrl: `${serverHost}get-factory-capacity/`,
    method: 'GET'
  },
  dashboardMassData: {
    name: 'dashboardMassData',
    endPointUrl: `${serverHost}get-factory-weekly-capacity/horizon/`,
    method: 'GET'
  },
  dashboardGridColumns: {
    name: 'dashboardGridColumns',
    endPointUrl: `${serverHost}get-base-ui/`,
    method: 'GET'
  },
  updateDashboardData: {
    name: 'updateDashboardData',
    endPointUrl: `${serverHost}update-factory-capacity/dashboard/`,
    method: 'POST'
  },
  updateDashboardDataNew: {
    name: 'updateDashboardData',
    endPointUrl: `${serverHost}update-capacity/dashboard/`,
    method: 'POST'
  },
  updateGBDData: {
    name: 'updateGBDData',
    endPointUrl: `${serverHost}update-gbd-data-table/dashboard/`,
    method: 'POST'
  },
  updateGrowthLeverData: {
    name: 'updateGrowthLeverData',
    endPointUrl: `${serverHost}update-gbl-data/dashboard/`,
    method: 'POST'
  },
  updateGrowthLeverQuarterData: {
    name: 'updateGrowthLeverData',
    endPointUrl: `${serverHost}update-gbl-quarterly-data/dashboard/`,
    method: 'POST'
  },
  updateDashboardComment: {
    name: 'updateDashboardData',
    endPointUrl: `${serverHost}add-comments/dashboard/`,
    method: 'POST'
  },
  updateLeverLoggingComment: {
    name: 'updateDashboardData',
    endPointUrl: `${serverHost}addGblComments/`,
    method: 'PUT'
  },
  savePreferenceData: {
    name: 'savePreferenceData',
    endPointUrl: `${serverHost}put-user-preferences`,
    method: 'PUT'
  },
  adminData: {
    name: 'adminData',
    endPointUrl: `${serverHost}get-admin-configurations`,
    method: 'GET'
  },
  updateAdminData: {
    name: 'updateAdminData',
    endPointUrl: `${serverHost}save-admin-configurations`,
    method: 'POST'
  },
  //LeverAdmin Api
  leverAdminData: {
    name: 'leverAdminData',
    endPointUrl: `${serverHost}get-gbd-admin-configurations`,
    method: 'GET'
  },
  leverLoggingData: {
    name: 'leverLoggingData',
    endPointUrl: `${serverHost}get-gbl-comments-logs/`,
    method: 'GET'
  },
  updateLeverAdminData: {
    name: 'leverUpdateAdminData',
    endPointUrl: `${serverHost}save-admin-configurations`,
    method: 'POST'
  },
  //LRCP Api
  lrcpData: {
    name: 'lrcpData',
    endPointUrl: `${serverHost}get-lrcp-list?factoryType=${userFactory.factoryType}`,
    method: 'GET'
  },
  updateLrcpData: {
    name: 'updateLrcpData',
    endPointUrl: `${serverHost}save-lrcp-admin-configurations`,
    method: 'POST'
  },
  getUserDetails: {
    name: 'getUserDetails',
    endPointUrl: `${serverHost}get-user-affiliations`
  },
  holidayApiData: {
    name: 'holidayData',
    endPointUrl: `${serverHost}/gsm/factory-holidays/v1/factory`,
    method: 'GET'
  },
  usrFactGrpSearch: {
    name: 'usrFactGrpSearch',
    endPointUrl: `${serverHost}get-capacity-types`
  },
  usrFactGrpSearch1: {
    name: 'usrFactGrpSearch',
    endPointUrl: `${serverHost}get-factory-association`
  },
  appConfigData: {
    name: 'appConfigData',
    endPointUrl: `${serverHost}get-app-configurations`,
    method: 'GET'
  },
  getCapacityChangeNotification: {
    name: 'dashboardMassData',
    endPointUrl: `${serverHostNotifications}user-notifications`,
    method: 'GET'
  },
  getUserNotify: {
    name: 'getUserNotify',
    endPointUrl: `${serverHostNotifications}user-notifications/stats`
  },
  updateUserUpdatePending: {
    name: 'updateUserUpdatePending',
    endPointUrl: `${serverHostNotifications}user-notifications/clear-pending`
  },
  updateUserUpdateRead: {
    name: 'updateUserUpdateRead',
    endPointUrl: `${serverHostNotifications}update-notification-read-status`,
    method: 'POST'
  },
  getUserAvailableFactories: {
    name: 'adminData',
    endPointUrl: `${serverHostNotifications}existing-capacities`,
    method: 'GET'
  },
  getUserPrefferedFactories: {
    name: 'adminData',
    endPointUrl: `${serverHostNotifications}user-capacities`,
    method: 'GET'
  },
  updateUserPrefferedFactories: {
    name: 'updateAdminData',
    endPointUrl: `${serverHostNotifications}user-capacities`,
    method: 'POST'
  },
  getCommentByID: {
    name: 'getCommentByID',
    endPointUrl: `${serverHost}get-capacity-details`,
    method: 'GET'
  },
  getCommentByBatch: {
    name: 'getCommentByBatch',
    endPointUrl: `${serverHost}capacity-details/get-batch`,
    method: 'POST'
  },
  dashboardSelectedWeeks: {
    name: 'dashboardSelectedWeeks',
    endPointUrl: `${serverHostNotifications}selected-capacities`,
    method: 'GET'
  },
  growthDimensionData: {
    name: 'growthDimensionPmdData',
    endPointUrl: `${serverHost}get-gbd-weekly-records/horizon/`,
    method: 'GET'
  },
  growthDimensionGridColumns: {
    name: 'growthDimensionGridColumns',
    endPointUrl: `${serverHost}get-gbd-base-ui/`,
    method: 'GET'
  },

  //GrowthLever API's
  growthLeverData: {
    name: 'growthLeverPmdData',
    endPointUrl: `${serverHost}get-gbl-quarterly-records/horizon/`,
    method: 'GET'
  },
  growthLeverQuarterData: {
    name: 'growthLeverQuarterPmdData',
    endPointUrl: `${serverHost}get-gbl-single-quarterly-record/horizon/`,
    method: 'GET'
  },
  growthLeverListData: {
    name: 'growthLeverListDataPmdData',
    endPointUrl: `${serverHost}get-gbl-base-ui/dashboard/`,
    method: 'GET'
  },
  growthLeverGridColumns: {
    name: 'growthLeverGridColumns',
    endPointUrl: `${serverHost}get-gbd-base-ui/`,
    method: 'GET'
  },
  updateGrowthDimensionData: {
    name: 'updateGrowthDimensionData',
    endPointUrl: `${serverHost}update-factory-capacity/growthDimension/`,
    method: 'POST'
  },
  capvisApiGetDeveloper: {
    name: 'capvisApiGetDeveloper',
    endPointUrl: `${serverHost}get-developer`
  },
  capvisWorkersGetDeveloper: {
    name: 'capvisWorkersGetDeveloper',
    endPointUrl: `${serverHost}get-developer`
  },
  notificationApiGetDeveloper: {
    name: 'notificationApiGetDeveloper',
    endPointUrl: `${serverHostNotifications}get-developer`
  },
  notificationWorkersGetDeveloper: {
    name: 'notificationWorkersGetDeveloper',
    endPointUrl: `${serverHost}get-developer`
  },
  gbdHeaderView: {
    name: 'gbdHeaderView',
    endPointUrl: `${serverHost}get-gbd-base-ui/dashboard/`
  },
  getLrcpList: {
    name: 'getLrcpList',
    endPointUrl: `${serverHost}get-lrcp-list`,
    method: 'GET'
  }
};
