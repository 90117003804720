import React from 'react';
import PropTypes from 'prop-types';

const CommentItem = ({ content, user, type, field, date, isQuarterly }) => (
  <div className="comment-box">
    <div className="comment-head">
      <span className="comment-name by-author">
        <i className="fas fa-user" /> {`${user}`}
      </span>
      <span className="time-comment">
        <i
          className={
            isQuarterly ? 'fas fa-calendar-alt' : 'fas fa-calendar-week'
          }
        />
        {Number(date) ? (
          <span> {new Date(1 * date).toLocaleString()}</span>
        ) : (
          <span> {new Date(date).toLocaleString()}</span>
        )}
        <i className="far fa-clock"></i>
      </span>
    </div>
    <div className="comment-content">
      {type === 'time' ? (
        <div className="ncss-container">
          <div className="ncss-row">
            <div className="ncss-col-sm-6"> {field && field}</div>
            <div className="ncss-col-sm-6">
              {content && typeof content === 'string' && content}
              {content && Array.isArray(content) && content[0].comment
                ? content[0].comment
                : ''}
            </div>
          </div>
        </div>
      ) : (
        <div className="ncss-col-sm-12 comment-input pt">
          {content &&
            (typeof content === 'string' || typeof content === 'number') &&
            content}
          {content && Array.isArray(content) && content[0].comment
            ? content[0].comment
            : ''}
        </div>
      )}
    </div>
  </div>
);

CommentItem.propTypes = {
  content: PropTypes.string,
  user: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.string,
  date: PropTypes.string
};

export default CommentItem;
