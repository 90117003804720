import { toast } from 'react-toastify';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';
import { msgConstants } from '../constants/msgConstants.js';

export function* listener(serviceType, action) {
  try {
    const data = JSON.parse(JSON.stringify(action.data));
    delete data.dashboard;
    yield put(createServiceAction(serviceType, 'success')(null));
    yield put(createServiceAction(serviceType, 'loading')());
    const putDashDataUrl = `${apiConfig.updateDashboardComment.endPointUrl}${action.data.dashboard}`;
    const res = yield call(putRequest, putDashDataUrl, data, true);
    // const response = yield res.json();

    if (res.status === 201) {
      yield put(createServiceAction(serviceType, 'success')(true));
      const message = msgConstants.cmntAddSuccess;
      toast.success(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      yield put(createServiceAction(serviceType, 'success')(false));
      const message = msgConstants.cmntAddFailed;
      toast.error(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'success')(false));
  }
}

function* updateDashboardCommentSagas() {
  const serviceType = 'updateDashboardComment';
  yield takeEvery(
    createServiceAction(serviceType, 'submited').type,
    listener,
    serviceType
  );
}

export default updateDashboardCommentSagas;
