import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Grid from '../grid/Grid.js';
import { H5, IconButton } from '../index.js';
import gridConstants from '../../constants/gridConstants.js';

const gridDefault = {
  resizable: true,
  editable: false,
  sortable: true,
  filter: true
};
const gridHeader = [
  {
    headerName: 'Excel Row',
    field: 'row',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 90,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Factory Code ',
    field: 'factoryCode',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 120,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Capacity Type Code ',
    field: 'capacityTypeCode',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 120,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Week Start Date',
    field: 'weekStartDate',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 120,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Column Name',
    field: 'fieldname',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 175,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Status',
    field: 'status',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 110,
    valueGetter: params =>
      `${params.data && params.data.status ? 'SUCCESS' : 'FAILED'}`,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'FAILURE TYPE',
    field: 'type',
    hide: true,
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 110,
    valueFormatter: params =>
      `${params.data && params.data.type ? params.data.type : ''}`,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Value',
    field: 'value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 250,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Comment',
    field: 'message',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    width: 250,
    headerClass: 'litegrey',
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'ID',
    field: 'id',
    filter: false,
    hide: true,
    width: 250,
    suppressMenu: true,
    headerClass: 'litegrey',
    cellClass: 'whiteText'
  }
];

const pQGridDefault = {
  gridDefault,
  wrapText: true,
  autoHeight: true
};

const pQGridHeader = [
  {
    headerName: 'Excel Row',
    field: 'row',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 90,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Factory Code ',
    field: 'factoryCode',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 120,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Capacity Type Code ',
    field: 'capacityTypeCode',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 120,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Production Quarter',
    field: 'productionQuarter',
    filter: true,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 120,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`,
    valueGetter: params => {
      let pQ = '';
      if (params && params.data && params.data.productionQuarter) {
        let pdQ = String(params.data.productionQuarter);
        if (pdQ.includes('-Q')) {
          pQ = pdQ;
        } else {
          pQ = `${pdQ.substring(0, 4)}-Q${pdQ.substring(4, 5)}`;
        }
      }
      return pQ;
    }
  },
  {
    headerName: 'Column Name',
    field: 'fieldname',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 175,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Status',
    field: 'status',
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 110,
    valueGetter: params =>
      `${params.data && params.data.status ? 'SUCCESS' : 'FAILED'}`,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'FAILURE TYPE',
    field: 'type',
    hide: true,
    filter: 'agSetColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 110,
    valueFormatter: params =>
      `${params.data && params.data.type ? params.data.type : ''}`,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Value',
    field: 'value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 250,
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  },
  {
    headerName: 'Comment',
    field: 'message',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    width: 450,
    headerClass: 'litegrey',
    cellClass: params =>
      `${params.data.status ? 'editableColumn' : 'editableRedColumn'}`
  }
];

export default class UploadUpdateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paramsWeeklyExcel: {},
      ParamsQuarterExcel: {},
      showTab: 0,
      getRowHeight(params) {
        let defaultHeight = 50;

        if (params.data && params.data.value) {
          const h = Math.ceil(params.data.value.length / 40);
          if (h > 1) {
            defaultHeight = 40 * h;
          }
        }
        return defaultHeight;
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    // On page load, loading max available dashboard as default
    const { paramsWeeklyExcel } = this.state;
    const {
      updateCycleComplete,
      validatedUploadPQResult,
      isQuarterlyUpdate
    } = this.props;
    const showTab =
      (validatedUploadPQResult && validatedUploadPQResult.length > 0) ||
      isQuarterlyUpdate
        ? 1
        : 0;
    this.setState({ showTab });
    if (updateCycleComplete !== nextProps.updateCycleComplete) {
      if (
        nextProps.updateCycleComplete &&
        Object.keys(paramsWeeklyExcel).length !== 0
      ) {
        var params = { force: true };
        paramsWeeklyExcel.api.refreshCells(params);
      }
    }
  }
  setParams = params => {
    // Function called post GRID initial load to reassure column structure and fields

    if (params) {
      this.setState({ paramsWeeklyExcel: params }); // update AG Grid Properties to state

      if (params.columnApi.getAllColumns()) {
        try {
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();
          // params.api.sizeColumnsToFit();
          const defaultSortAfterLoad = [
            {
              colId: 'row',
              sort: 'asc'
            }
          ];
          params.api.setSortModel(defaultSortAfterLoad);
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  setPQParams = params => {
    // Function called post GRID initial load to reassure column structure and fields

    if (params) {
      this.setState({ paramsQuarterExcel: params }); // update AG Grid Properties to state

      if (params.columnApi.getAllColumns()) {
        try {
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  downloadData = () => {
    // Function to download the current grid data (upload status report) to excel sheet

    // to apply custom footer which will have to drop down values for short horizon and status
    const { paramsWeeklyExcel, paramsQuarterExcel } = this.state;
    let { showTab } = this.state;
    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() +
      1}-${today.getDate()}:${today.getHours()}-${today.getMinutes()}`;

    const params = {
      fileName: `ExcelUpload-${
        showTab === 1 ? 'Quarterly' : 'Weekly'
      }-${date}-Report`,
      sheetName: 'Report',
      processCellCallback(paramsl) {
        return paramsl.value;
      }
    };
    showTab === 0
      ? paramsWeeklyExcel.api.exportDataAsExcel(params)
      : paramsQuarterExcel.api.exportDataAsExcel(params);
  };
  toogleTab = n => {
    this.setState({ showTab: n });
  };
  render() {
    const { getRowHeight, showTab } = this.state;
    const {
      UploadUpdateModel,
      UploadStatusData,
      toogleUploadUpdateModel,
      UploadUpdateModelType,
      updateCycleComplete,
      validatedUploadPQResult
    } = this.props;
    gridHeader[0].headerName =
      UploadUpdateModelType === 'Grid Row' ? '' : 'Excel Row ';
    let currentTab = Number(showTab) || 0;
    if (validatedUploadPQResult && validatedUploadPQResult.length > 0) {
      currentTab = 1;
    }
    return (
      <Modal
        isOpen={UploadUpdateModel}
        className="modal-container modal-container-big"
        overlayClassName="modal-overlay"
      >
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() => toogleUploadUpdateModel()}
        />
        <div className="row">
          <div className="ncss-col-sm-6 ">
            <H5 className="u-bold  fl-sm-l p2-top-sm p2-bottom-sm text-color-success">
              {UploadUpdateModelType === 'Grid' ? '' : 'Upload '} Validation
              &amp; DB update Report
            </H5>
          </div>
          <div className="ncss-col-sm-6 ta-sm-r pr8-sm">
            <button
              type="button"
              onClick={() => this.downloadData()}
              className="fl-sm-r  ncss-btn-secondary-lite"
            >
              <i className="fas fa fa-download" /> DOWNLOAD
              {showTab === 1 ? ' QUARTERLY' : ' WEEKLY'} REPORT
            </button>
          </div>
        </div>

        <div className="ncss-col-sm-12 ">
          {!updateCycleComplete &&
            UploadStatusData &&
            UploadStatusData.length > 0 && (
              <div className="bg-success-box fl-sm-c ta-sm-c p3-sm">
                <p className=" ">Updating database...</p>
              </div>
            )}
          <Tabs
            defaultIndex={currentTab}
            onSelect={index => this.toogleTab(index)}
          >
            <TabList>
              <Tab
                className={
                  !UploadStatusData || UploadStatusData.length === 0
                    ? 'd-sm-h'
                    : 'react-tabs__tab'
                }
              >
                Weekly Reports
              </Tab>
              <Tab
                className={
                  !validatedUploadPQResult ||
                  validatedUploadPQResult.length === 0
                    ? 'd-sm-h'
                    : 'react-tabs__tab'
                }
              >
                Quarterly Reports
              </Tab>
            </TabList>

            <TabPanel>
              {UploadStatusData ? (
                <div className="border numberList">
                  <div
                    id="myGrid"
                    style={{ height: 'calc( 55vh )', width: '100%' }}
                    className="ag-theme-balham report-grid"
                  >
                    <Grid
                      defaultColDef={gridDefault}
                      columnDefs={gridHeader}
                      rowData={UploadStatusData}
                      setParams={this.setParams}
                      dbGridFunctions={false}
                      setheight="55vh"
                      headerHeight={60}
                      getRowHeight={getRowHeight}
                      enableCellChangeFlash={true}
                      excelStyles={gridConstants.excelStyles}
                    />
                  </div>
                </div>
              ) : (
                <div className="border" />
              )}
            </TabPanel>
            <TabPanel>
              {validatedUploadPQResult && validatedUploadPQResult.length > 0 ? (
                <div className="border numberList">
                  <div
                    id="myGrid1"
                    style={{ height: '250px', width: '100%' }}
                    className="ag-theme-balham report-grid"
                  >
                    <Grid
                      defaultColDef={pQGridDefault}
                      columnDefs={pQGridHeader}
                      rowData={validatedUploadPQResult}
                      setParams={this.setPQParams}
                      dbGridFunctions={false}
                      setheight="55vh"
                      enableCellChangeFlash={true}
                      excelStyles={gridConstants.excelStyles}
                    />
                  </div>
                </div>
              ) : (
                <div className="border" />
              )}
            </TabPanel>
          </Tabs>

          <div className="bottom-section p4-top-sm popup-bottom" />
        </div>
      </Modal>
    );
  }
}

UploadUpdateModal.propTypes = {
  UploadUpdateModel: PropTypes.bool,
  toogleUploadUpdateModel: PropTypes.func,
  UploadStatusData: PropTypes.any,
  UploadUpdateModelType: PropTypes.any,
  updateCycleReport: PropTypes.any,
  updateCycleComplete: PropTypes.any,
  validatedUploadPQResult: PropTypes.any
};
