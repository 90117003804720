import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import SortableTree, {
  changeNodeAtPath,
  addNodeUnderParent,
  toggleExpandedForAll,
  removeNodeAtPath
} from 'react-sortable-tree';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-sortable-tree/style.css';
import { Button, Spinner } from '../../components/index.js';
import NotFound from '../../components/errors/NotFound.js';
import {
  getLeverAdminData,
  getLeverAdminError,
  getLeverAdminLoading,
  getUpdatingLeverAdminLoading,
  getUpdatingLeverAdminSuccess
} from '../../reducers/leverAdminReducer';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import history from '../../util/history.js';
import { msgConstants } from '../../constants/msgConstants.js';
import moment from 'moment';
import Grid from '../../components/grid/Grid.js';
import gridConstants from '../../constants/gridConstants.js';

export const getDate = paramval => {
  const date = paramval
    ? paramval.length > 0
      ? new Date(paramval)
      : new Date()
    : new Date();
  return `${date.getMonth() +
    1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};

const returnListData = array => {
  const listItems = [];
  array.forEach((cat, c) => {
    const category = {
      title: cat.categoryName,
      category: cat.categoryName,
      expanded: false,
      new: false,
      isChild: true,
      visible: cat.categoryStatus
    };
    if (cat.dimensionList && cat.dimensionList.length > 0) {
      category.children = [];
      cat.dimensionList.forEach((dim, d) => {
        const dimension = {
          title: dim.dimensionName,
          category: cat.categoryName,
          dimension: dim.dimensionName,
          expanded: false,
          new: false,
          isChild: true,
          visible: dim.dimensionStatus
        };
        if (dim.leverList && dim.leverList.length > 0) {
          dimension.children = [];
          dim.leverList.forEach((lev, l) => {
            dimension.children.push({
              title: lev.leverName,
              new: false,
              lastChild: true,
              visible: lev.leverVisible,
              code: lev.leverCode
            });
          });
        }
        category.children.push(dimension);
      });
    }

    listItems.push(category);
  });

  return listItems;
};

const gridDefault = {
  resizable: true,
  editable: false,
  sortable: false,
  filter: false
};

const historyHeader = [
  {
    headerName: 'Saved User',
    field: 'userId',
    hide: true
  },
  {
    headerName: 'Saved By',
    field: 'timestamp',
    width: 250,
    filter: false,
    suppressMenu: true,
    autoHeight: true,
    headerClass: 'litegrey',
    valueFormatter: params => {
      if (params && params.data) {
        const { userId } = params.node.data;
        return userId + ' \n ' + moment(params.value).format('MM/DD/YYYY LT');
      }
      return '';
    },
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { userId } = params.data;
        return userId + '<br/>' + moment(params.value).format('MM/DD/YYYY LT');
      }
    }
  },
  {
    headerName: 'Lever Changes',
    field: 'list',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 600,
    cellClass: 'nonEditableColumn',
    wrapText: true,
    autoHeight: true,
    cellRenderer: params => {
      if (params && params.value && params.value.length > 0) {
        const listItems = params.value.map(
          cat => `<li>${cat.categoryName}  ${
            cat.activeChange || cat.isNew
              ? cat.categoryStatus
                ? '- <small class="text-color-success">ACTIVE</small>'
                : '- <small class="text-color-error">INACTIVE</small>'
              : ''
          } ${
            cat.isNew
              ? '- <small class="cellStatusBox di bg-success-lite">NEW</small>'
              : ''
          }
          ${
            cat.dimensionList && cat.dimensionList.length > 0
              ? `<ul class="gridlistItems"> ${cat.dimensionList
                  .map(
                    dim => `<li>${dim.dimensionName}  ${
                      dim.activeChange || dim.isNew
                        ? dim.dimensionStatus
                          ? '- <small class="text-color-success">ACTIVE</small>'
                          : '- <small class="text-color-error">INACTIVE</small>'
                        : ''
                    } ${
                      dim.isNew
                        ? '- <small class="cellStatusBox di bg-success-lite">NEW</small>'
                        : ''
                    }
            ${
              dim.leverList && dim.leverList.length > 0
                ? `<ul class="gridlistItems"> ${dim.leverList
                    .map(
                      lev =>
                        `<li>${lev.leverName}   ${
                          lev.leverVisible
                            ? '- <small class="text-color-success">ACTIVE</small>'
                            : '- <small class="text-color-error">INACTIVE</small>'
                        }   ${
                          lev.isNew
                            ? '- <small class="cellStatusBox di bg-success-lite">NEW</small>'
                            : ''
                        }</li>`
                    )
                    .join(' ')} </ul>`
                : ``
            }
            </li>`
                  )
                  .join(' ')} </ul>`
              : ``
          }
          </li>`
        );
        return '<ol class="gridlistItems">' + listItems.join(' ') + ` </ol>`;
      }
    }
  }
];

const compareHistory1 = (newArr, oldArr) => {
  const catDiff = _.differenceWith(newArr, oldArr, _.isEqual);
  //const catArr2 = _.differenceWith(oldArr, newArr, _.isEqual);
  // const catDiff = _.unionBy(catArr1, catArr2, 'categoryName');

  const hList = [];
  if (catDiff && catDiff.length > 0) {
    catDiff.forEach((cItem, cindex) => {
      const isExistOldCat = oldArr
        ? oldArr.find(o => o.categoryName === cItem.categoryName)
        : {};
      const newCItem = { ...cItem };
      if (isExistOldCat && Object.keys(isExistOldCat).length > 0) {
        newCItem.isNew = false;
        newCItem.activeChange =
          isExistOldCat.categoryStatus !== newCItem.categoryStatus;
        if (newCItem.dimensionList && newCItem.dimensionList.length > 0) {
          const dimDiff = _.differenceWith(
            newCItem.dimensionList,
            isExistOldCat.dimensionList,
            _.isEqual
          );
          /*const dimArr2 = _.differenceWith(
            isExistOldCat.dimensionList,
            newCItem.dimensionList,
            _.isEqual
          );
          const dimDiff = _.unionBy(dimArr1, dimArr2, 'dimensionName');
          */
          const dimensionList = [];
          dimDiff.forEach((dItem, dindex) => {
            const newdItem = { ...dItem };
            const isExistOldDim =
              isExistOldCat && isExistOldCat.dimensionList
                ? isExistOldCat.dimensionList.find(
                    o => o.dimensionName === dItem.dimensionName
                  )
                : {};
            if (isExistOldDim && Object.keys(isExistOldDim).length > 0) {
              newdItem.isNew = false;

              newdItem.activeChange =
                isExistOldDim.dimensionStatus !== newdItem.dimensionStatus;
              if (newdItem.leverList && newdItem.leverList.length > 0) {
                const levDiff = _.differenceWith(
                  dItem.leverList,
                  isExistOldDim.leverList,
                  _.isEqual
                );
                const leverList = [];

                levDiff.forEach((lItem, dindex) => {
                  const newlItem = { ...lItem };
                  const isExistOldLev =
                    isExistOldDim && isExistOldDim.leverList
                      ? isExistOldDim.leverList.find(
                          o => o.leverCode === newlItem.leverCode
                        )
                      : {};
                  newlItem.isNew =
                    isExistOldLev && Object.keys(isExistOldLev).length > 0
                      ? false
                      : true;
                  leverList.push(newlItem);
                });
                newdItem.leverList = leverList;
              }
              //
            } else {
              newdItem.isNew = true;
              if (newdItem.leverList && newdItem.leverList.length > 0) {
                newdItem.leverList.forEach((lItem, dindex) => {
                  lItem.isNew = true;
                });
              }
            }
            dimensionList.push(newdItem);
          });

          newCItem.dimensionList = dimensionList;
        }
      } else {
        newCItem.isNew = true;
        if (newCItem.dimensionList && newCItem.dimensionList.length > 0) {
          newCItem.dimensionList.forEach((dItem, dindex) => {
            dItem.isNew = true;
            if (dItem.leverList && dItem.leverList.length > 0) {
              dItem.leverList.forEach((lItem, dindex) => {
                lItem.isNew = true;
              });
            }
          });
        }
      }

      hList.push(newCItem);
    });
  }
  return hList;
};

// eslint-disable-next-line max-statements
const compareHistory = (configuration, history) => {
  const result = { created: [], modified: [], changeList: [] };
  if (configuration !== undefined && history !== undefined) {
    for (let i = 0; i < configuration.length; i++) {
      let configIndex = history.findIndex(
        config => config.categoryName === configuration[i].categoryName
      );
      let categoryName = configuration[i].categoryName;
      let status =
        configuration[i].categoryStatus === true ? 'Active' : 'Inactive';
      if (configuration[i].dimensionList !== undefined) {
        if (configIndex > -1) {
          if (
            configuration[i].categoryStatus !==
            history[configIndex].categoryStatus
          ) {
            let obj = `${categoryName} : ${status}`;
            result.modified.push(obj);
          }
          for (let j = 0; j < configuration[i].dimensionList.length; j++) {
            let configDimensionName =
              configuration[i].dimensionList[j].dimensionName;
            let dimeStatus =
              configuration[i].dimensionList[j].dimensionStatus === true
                ? 'Active'
                : 'Inactive';
            let dimensionObj = `${categoryName} → ${configDimensionName} : ${dimeStatus}`;
            let historyDimension = history[configIndex].dimensionList
              ? history[configIndex].dimensionList
              : [];
            let dimensionIndex =
              historyDimension &&
              historyDimension.findIndex(
                history => configDimensionName === history.dimensionName
              );
            if (configuration[i].dimensionList[j].leverList !== undefined) {
              if (dimensionIndex > -1) {
                if (
                  configuration[i].dimensionList[j].dimensionStatus !==
                  history[configIndex].dimensionList[dimensionIndex]
                    .dimensionStatus
                ) {
                  result.modified.push(dimensionObj);
                }
                let configLeverList =
                  configuration[i].dimensionList[j].leverList;
                for (let k = 0; k < configLeverList.length; k++) {
                  let configLeverListName = configLeverList[k].leverName;
                  let leverStatus =
                    configLeverList[k].leverVisible === true
                      ? 'Active'
                      : 'Inactive';
                  let leverObj = `${categoryName} → ${configDimensionName} → ${configLeverListName} : ${leverStatus}`;
                  let historyLeverI = history[configIndex].dimensionList[
                    dimensionIndex
                  ].leverList
                    ? history[configIndex].dimensionList[dimensionIndex]
                        .leverList
                    : [];
                  let leverIndex =
                    historyLeverI &&
                    historyLeverI.findIndex(
                      lever => lever.leverName === configLeverListName
                    );
                  let historyLever =
                    history[configIndex].dimensionList[dimensionIndex]
                      .leverList[leverIndex];
                  if (leverIndex > -1) {
                    if (
                      historyLever.leverVisible !==
                      configLeverList[k].leverVisible
                    ) {
                      result.modified.push(leverObj);
                    }
                  } else {
                    result.created.push(leverObj);
                  }
                }
              } else {
                result.created.push(dimensionObj);
                for (
                  let k = 0;
                  k < configuration[i].dimensionList[j].leverList;
                  k++
                ) {
                  let leverName =
                    configuration[i].dimensionList[j].leverList[k].leverName;
                  let leverStatus =
                    leverName.leverVisible === true ? 'Active' : 'Inactive';
                  let leverlist = `${categoryName} → ${configDimensionName} → ${leverName} : ${leverStatus}`;
                  result.created.push(leverlist);
                }
              }
            }
          }
        } else {
          let obj = `${categoryName} : ${status}`;
          result.created.push(obj);
          for (let j = 0; j < configuration[i].dimensionList.length; j++) {
            let dimensionName = configuration[i].dimensionList[j].dimensionName;
            let dimensionStatus =
              configuration[i].dimensionList[j].dimensionName
                .dimensionStatus === true
                ? 'Active'
                : 'Inactive';
            let dimensionList = `${categoryName} → ${dimensionName} : ${dimensionStatus}`;
            result.created.push(dimensionList);
            for (
              let k = 0;
              k < configuration[i].dimensionList[j].leverList.length;
              k++
            ) {
              let leverName =
                configuration[i].dimensionList[j].leverList[k].leverName;
              let leverStatus =
                configuration[i].dimensionList[j].leverList[k].leverVisible ===
                true
                  ? 'Active'
                  : 'Inactive';
              let leverLists = `${categoryName} → ${dimensionName} → ${leverName} : ${leverStatus}`;
              result.created.push(leverLists);
            }
          }
        }
      }
    }
  }
  return result;
};

const getNodeKey = ({ treeIndex }) => treeIndex;
export class LeverAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: '',
      listItems: [],
      listItemsBackup: [],
      leverHistory: [],
      isTreeExpanded: false,
      userProfile: {},
      context: { componentParent: this },
      pageTitles: [
        { name: 'Apparel', type: 'AP' },
        { name: 'Footwear', type: 'FW' },
        { name: 'Equipment', type: 'EQ' }
      ],
      pageTitle: '',
      pageData: [],
      pageBackData: {},
      errorStatus: false,
      errorMsg: '',
      treeInputTouched: false,
      excelStyles: gridConstants.excelStyles,
      saveLoading: false
    };
    this.addNewListCat = this.addNewListCat.bind(this);
    this.addNewListItem = this.addNewListItem.bind(this);
    this.setListItems = this.setListItems.bind(this);
    this.saveDataChanges = this.saveDataChanges.bind(this);
    this.toastUpdate = this.toastUpdate.bind(this);
    this.downloadHistory = this.downloadHistory.bind(this);
  }

  findDuplicate = value => {
    let seen = new Set();
    const isDup = value.some(currentObject => {
      return seen.size === seen.add(currentObject.title.toUpperCase()).size;
    });
    return isDup;
  };

  toastUpdate = toastms => {
    const { toastConfig } = this.state;
    if (toastms.status) {
      toast.success(toastms.msg, toastConfig);
    } else {
      toast.error(toastms.msg, toastConfig);
    }
  };

  convertLeverAdminData = array => {
    const configurations = [];
    const valiData = { status: true, msg: '' };
    const { listItemsBackup } = this.state;
    if (JSON.stringify(listItemsBackup) === JSON.stringify(array)) {
      valiData.status = false;
      valiData.msg = 'No changes made';
    } else {
      array.forEach((cat, c) => {
        const dimension = [];
        if (cat.children && cat.children.length > 0) {
          cat.children.forEach((dim, d) => {
            const lever = [];
            if (dim.children && dim.children.length > 0) {
              dim.children.forEach((lev, l) => {
                const isDuplicate = this.findDuplicate(dim.children);
                if (
                  lev.title === undefined ||
                  lev.title === ' ' ||
                  lev.title.toString().trim() === ''
                ) {
                  valiData.status = false;
                  valiData.msg = 'Lever Value Cannot be empty';
                } else if (isDuplicate) {
                  valiData.status = false;
                  valiData.msg = 'Lever Already Exist';
                } else {
                  const leverList = {
                    leverName: lev.title,
                    leverVisible: lev.visible,
                    levercode: lev.code
                  };
                  lever.push(leverList);
                }
              });
            }
            const isDuplicate = this.findDuplicate(cat.children);
            if (
              dim.title === undefined ||
              dim.title === ' ' ||
              dim.title.toString().trim() === ''
            ) {
              valiData.status = false;
              valiData.msg = 'Dimension Value Cannot be empty';
            } else if (isDuplicate) {
              valiData.status = false;
              valiData.msg = 'Dimension Already Exist';
            } else {
              dimension.push({
                dimensionStatus: dim.visible,
                leverList: lever,
                dimensionName: dim.title
              });
            }
          });
        }
        const isDuplicate = this.findDuplicate(array);
        if (
          cat.title === undefined ||
          cat.title === ' ' ||
          cat.title.toString().trim() === ''
        ) {
          valiData.status = false;
          valiData.msg = 'Category Value Cannot be empty';
        }
        if (isDuplicate) {
          valiData.status = false;
          valiData.msg = 'Category Already Exist';
        } else {
          configurations.push({
            categoryName: cat.title,
            dimensionList: dimension,
            categoryStatus: cat.visible
          });
        }
      });
    }
    if (valiData.status) {
      return configurations;
    } else {
      this.toastUpdate(valiData);
    }
  };

  componentDidMount() {
    const { requestLeverAdminData, requestUserProfileData } = this.props;
    requestUserProfileData();
    let { pageTitle, pageTitles } = this.state;
    const currentPathName = window.location.pathname;
    let pageType = currentPathName.includes('/admin/manageLevers/')
      ? currentPathName.replace('/admin/manageLevers/', '')
      : null;
    //listItems = returnListData(leverAdminConstants.sampleData.categories);
    if (pageType) {
      const pageTitleObj = pageTitles.find(o => o.type === pageType);
      if (pageTitleObj && Object.keys(pageTitleObj).length !== 0) {
        pageTitle = pageTitleObj;
      }
    }
    this.setState({
      pageType,
      pageTitle
      //listItems
    });
    localStorage.setItem('isLeverAdminDataChanged', 'false');
    setTimeout(() => {
      requestLeverAdminData(pageTitle);
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    const {
      leverAdminData,
      userProfileData,
      updateLeverAdminLoading,
      requestLeverAdminData,
      location
    } = this.props;
    let { listItems, listItemsBackup, pageTitle } = this.state;
    const leverHistory = [];
    if (!_.isEqual(leverAdminData, nextProps.leverAdminData)) {
      this.setState({
        leverHistory: [],
        listItems: []
      });
      if (
        nextProps.leverAdminData &&
        Object.keys(nextProps.leverAdminData).length !== 0
      ) {
        const returnList = returnListData(
          nextProps.leverAdminData.configurations
        );
        listItems = JSON.parse(JSON.stringify(returnList));
        listItemsBackup = JSON.parse(JSON.stringify(returnList));
        const { history } = nextProps.leverAdminData;

        const sortedHistory = history.sort(function(a, b) {
          return new Date(b.lastModified) - new Date(a.lastModified);
        });
        const sortedArray = sortedHistory;
        sortedArray.forEach((sArray, index) => {
          let nxtHistory = sortedArray[index + 1]
            ? sortedArray[index + 1].categories
            : [];

          let rowResult = null;
          let rowResult1 = compareHistory1(sArray.categories, nxtHistory);
          rowResult = compareHistory(sArray.categories, nxtHistory);

          let rowObj = {
            timestamp: sArray.lastModified || sArray.timestamp,
            userId: sArray.userId,
            leverCreate: rowResult ? rowResult.created : [],
            leverModified: rowResult ? rowResult.modified : [],
            list: rowResult1
          };
          //
          if (rowResult1 && rowResult1.length > 0) {
            leverHistory.push(rowObj);
          }
        });
        this.setState({
          pageData: { ...nextProps.leverAdminData },
          pageBackData: JSON.parse(JSON.stringify(nextProps.leverAdminData)),
          listItems,
          leverHistory,
          listItemsBackup
        });
        this.setState({ isTreeExpanded: false });
      }
    }
    if (userProfileData !== nextProps.userProfileData) {
      if (nextProps.userProfileData.factoryType) {
        this.setState({
          userProfile: nextProps.userProfileData
        });
      }
    }
    if (updateLeverAdminLoading !== nextProps.updateLeverAdminLoading) {
      if (!nextProps.updateLeverAdminLoading) {
        requestLeverAdminData(pageTitle);
      }
    }

    if (location.pathname !== nextProps.location.pathname) {
      this.getLeverAdminData();
    }
  }

  componentDidUpdate(prevProps) {
    const { updateLeverAdminSuccess } = this.props;
    if (
      prevProps.updateLeverAdminSuccess !== updateLeverAdminSuccess &&
      updateLeverAdminSuccess
    ) {
      this.setTreeInputTouched(false);
    }
  }

  setListItems = listItems => {
    this.setState({ listItems }, () => {
      this.setTreeInputTouched();
    });
  };

  addNewListCat = () => {
    this.setState(state => ({
      listItems: state.listItems.concat({
        title: ``,
        new: true,
        isChild: true
      })
    }));
  };

  addNewListItem = node => {
    const { listItems } = this.state;
    let dimIndex = null;
    listItems.forEach((cat, c) => {
      let dimChildIndex = cat.children.findIndex(
        o => o.dimension === node.dimension
      );
      if (dimChildIndex >= 0) {
        dimIndex = dimChildIndex;
      }
    });
    if (dimIndex >= 0) {
      //
    }
    this.setState({ listItems });
  };

  toggleCollapse = () => {
    const { listItems, isTreeExpanded } = this.state;
    let isTreeExpandedNew = !isTreeExpanded;
    this.setState({
      isTreeExpanded: isTreeExpandedNew,
      listItems: toggleExpandedForAll({
        expanded: isTreeExpandedNew,
        treeData: listItems
      })
    });
  };

  toggleNodeVisible = newNode => {
    const node = { ...newNode };
    node.visible = !node.visible;

    if (node.children) {
      node.children.forEach(dim => {
        dim.visible = node.visible;

        if (dim.children) {
          dim.children.forEach(lev => {
            lev.visible = node.visible;
          });
        }
      });
    }
    return node;
  };

  renderTitle = (node, setTreeData, treeData, path) => (
    <div className="d-sm-b">
      <div className="d-sm-ib">
        {node.new ? (
          <input
            className="gblAdminListInput"
            value={node.title}
            onChange={event => {
              const title = event.target.value;
              this.setListItems(
                changeNodeAtPath({
                  treeData,
                  path,
                  getNodeKey,
                  newNode: { ...node, title }
                })
              );
            }}
          />
        ) : (
          <span className="gblAdminListText"> {node.title}</span>
        )}
      </div>
      <div className="d-sm-ib switchContainer">
        <label
          className="switch sm blue"
          htmlFor="maxCapacityEditable"
          onClick={() => {
            treeData.forEach(item => {
              if (item.title === node.title) {
                this.setListItems(
                  changeNodeAtPath({
                    treeData,
                    path,
                    getNodeKey,
                    newNode: this.toggleNodeVisible(node)
                  })
                );
              } else {
                if (item.children) {
                  item.children.forEach(dim => {
                    if (dim === node && item.visible) {
                      this.setListItems(
                        changeNodeAtPath({
                          treeData,
                          path,
                          getNodeKey,
                          newNode: this.toggleNodeVisible(node)
                        })
                      );
                    } else {
                      if (dim.children) {
                        dim.children.forEach(lev => {
                          if (lev === node && dim.visible) {
                            this.setListItems(
                              changeNodeAtPath({
                                treeData,
                                path,
                                getNodeKey,
                                newNode: this.toggleNodeVisible(node)
                              })
                            );
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
          }}
        >
          <input
            type="checkbox"
            name="maxCapacityEditable"
            checked={!!node.visible} // mapping only boolean value
            readOnly
          />

          <div className="slider round"></div>
        </label>
      </div>
      <div className="d-sm-ib switchContainer adminToggleText">
        {node.visible ? 'ACTIVE' : 'INACTIVE'}
      </div>
    </div>
  );

  renderNodeButtons = (node, setTreeData, treeData, path) => {
    const addDimensionButton = (
      <button
        className="withText"
        onClick={() => {
          this.setState(state => ({
            listItems: addNodeUnderParent({
              treeData: state.listItems,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey,
              newNode: {
                title: ``,
                new: true,
                category: 'cat.categoryName',
                dimension: 'dim.dimensionName',
                expanded: true,
                isChild: true
              },
              addAsFirstChild: state.addAsFirstChild
            }).treeData
          }));
          this.setTreeInputTouched();
        }}
      >
        <i className="fas fa-plus" /> Dimension
      </button>
    );

    const addLeverButton = (
      <button
        className="withText"
        onClick={() => {
          this.setState(state => ({
            listItems: addNodeUnderParent({
              treeData: state.listItems,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey,
              newNode: {
                title: ``,
                new: true,
                lastChild: true
              },
              addAsFirstChild: state.addAsFirstChild
            }).treeData
          }));
          this.setTreeInputTouched();
        }}
      >
        <i className="fas fa-plus" /> Lever
      </button>
    );

    const deleteNodeButton = (
      <button
        onClick={() => {
          this.setState(state => ({
            listItems: removeNodeAtPath({
              treeData: state.listItems,
              path,
              getNodeKey
            })
          }));
          this.setTreeInputTouched(false);
        }}
      >
        <i className="fas fa-minus-circle text-color-accent"></i>
      </button>
    );

    let ButtonArray = [];
    if (node.isChild) {
      ButtonArray = node.dimension ? [addLeverButton] : [addDimensionButton];
    }
    if (node.new) {
      ButtonArray.push(deleteNodeButton);
    }
    return ButtonArray;
  };

  canDrop = ({ node, prevParent, nextParent, prevPath, nextPath }) => {
    let previousTitle = (prevParent && prevParent.title) || '';
    let nextTitle = (nextParent && nextParent.title) || '';
    if (previousTitle !== nextTitle) {
      return false;
    }

    return true;
  };

  saveDataChanges = () => {
    const { listItemsBackup } = this.state;
    this.setState({ saveLoading: true });
    const time = listItemsBackup.length * 200;
    setTimeout(() => {
      const { listItems } = this.state;
      const updatedLeverAdminData = this.convertLeverAdminData(listItems);
      if (updatedLeverAdminData !== undefined) {
        const { updateLeverAdminData } = this.props;
        const adminCategories = {
          categories: updatedLeverAdminData
        };
        this.setState({ saveLoading: false });
        updateLeverAdminData(adminCategories);
        localStorage.setItem('isLeverAdminDataChanged', 'false');
      } else {
        this.setState({ saveLoading: false });
        localStorage.setItem('isLeverAdminDataChanged', 'false');
      }
    }, time);
  };

  prepareTreeCompareList = (list = []) =>
    list.map(i => {
      const item = {
        title: i.title,
        visible: i.visible
      };
      if (i.children) {
        item.children = this.prepareTreeCompareList(i.children);
      }

      return item;
    });

  setIsLeverAdminDataChanged = () => {
    const { listItemsBackup, listItems } = this.state;
    if (
      JSON.stringify(this.prepareTreeCompareList(listItemsBackup)) ===
      JSON.stringify(this.prepareTreeCompareList(listItems))
    ) {
      localStorage.setItem('isLeverAdminDataChanged', 'false');
      this.setState({ treeInputTouched: false });
    } else {
      localStorage.setItem('isLeverAdminDataChanged', 'true');
      this.setState({ treeInputTouched: true });
    }
  };

  setTreeInputTouched = (value = true) => {
    if (value) {
      this.setIsLeverAdminDataChanged();
    } else {
      localStorage.setItem('isLeverAdminDataChanged', 'false');
      this.setState({ treeInputTouched: false });
    }
  };

  setParams = params => {
    //const rowStyle = { background: 'white' };
    if (params) {
      this.setState({ historyParams: params });
      if (params.columnApi.getAllColumns()) {
        try {
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();
          params.api.sizeColumnsToFit();
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  downloadHistory = () => {
    const { historyParams } = this.state;
    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() +
      1}-${today.getDate()}_${today.getHours()}-${today.getMinutes()}`;

    const params = {
      fileName: `LEVERS-ADMIN-HISTORY-${date}`,
      sheetName: 'HISTORY',
      allColumns: true,
      processHeaderCallback(hedpar) {
        if (hedpar && hedpar.column && hedpar.column.colId === 'timestamp') {
          return 'Saved On';
        }
        return hedpar.column.colDef.headerName;
      },
      processCellCallback(params) {
        if (params.value === 0 || params.value) {
          let fieldName = params.column.colDef.field;
          if (fieldName === 'userId') {
            const value = params.value.length > 0 ? params.value : '';
            return value;
          } else if (fieldName === 'timestamp') {
            return moment(params.value).format('MM/DD/YYYY LT');
          } else if (
            fieldName === 'leverCreate' ||
            fieldName === 'leverModified'
          ) {
            return params.value.length > 0
              ? params.value
                  .map((i, index) => `${index + 1} : ${i} `)
                  .join('\n')
              : '';
          } else if (fieldName === 'list') {
            return params.value.length > 0
              ? params.value
                  .map((cat, cati) => {
                    return `${cati + 1} : ${cat.categoryName} ${
                      cat.activeChange
                        ? cat.categoryStatus || cat.isNew
                          ? ' : ACTIVE'
                          : ' : INACTIVE'
                        : ''
                    } ${cat.isNew ? '→ NEW' : ''}
                       ${
                         cat.dimensionList && cat.dimensionList.length > 0
                           ? cat.dimensionList
                               .map((dim, dimi) => {
                                 return `${dimi + 1} : ${dim.dimensionName} ${
                                   dim.activeChange || dim.isNew
                                     ? dim.dimensionStatus
                                       ? ' : ACTIVE'
                                       : ' : INACTIVE'
                                     : ''
                                 } ${dim.isNew ? '→ NEW' : ''}
                                 
                                 ${
                                   dim.leverList && dim.leverList.length > 0
                                     ? dim.leverList
                                         .map((lev, levi) => {
                                           return `${levi + 1} : ${
                                             lev.leverName
                                           }${
                                             lev.leverVisible
                                               ? ' : ACTIVE'
                                               : ' : INACTIVE'
                                           } ${lev.isNew ? '→ NEW' : ''}`;
                                         })
                                         .join('\n')
                                     : ''
                                 }`;
                               })
                               .join('\n')
                           : ''
                       }
                    `;
                  })
                  .join('\n')
              : '';
          }
        }
      }
    };
    historyParams.api.exportDataAsExcel(params);
  };

  render() {
    const {
      leverAdminData,
      leverAdminError,
      userProfileData,
      updateLeverAdminLoading
    } = this.props;
    const {
      pageType,
      listItems,
      setListItems,
      pageData,
      isTreeExpanded,
      treeInputTouched,
      leverHistory,
      excelStyles,
      saveLoading
    } = this.state;
    let showLeverAdmin = false;
    let pageLoading = true;
    if (
      userProfileData &&
      userProfileData.factoryType &&
      ((pageData &&
        pageData.configurations &&
        pageData.configurations.length > 0) ||
        (leverAdminError && leverAdminError.status))
    ) {
      pageLoading = false;
    }
    let leverPageType = '';
    if (userProfileData && Object.keys(userProfileData).length !== 0) {
      if ('factoryType' in userProfileData) {
        const currentPathName = window.location.pathname;
        leverPageType = currentPathName.includes('/admin/manageLevers/')
          ? currentPathName.replace('/admin/manageLevers/', '')
          : null;
        if (leverPageType === userProfileData.factoryType) {
          showLeverAdmin = true;
        }
      }
    }
    return (
      <div className="page-align-items">
        <div className="">
          {pageLoading && <Spinner large />}
          {saveLoading && <Spinner large position="Fixed" />}
          {updateLeverAdminLoading && <Spinner large position="Fixed" />}
          {!pageLoading &&
            (Object.keys(leverAdminError).length !== 0 || !showLeverAdmin) && (
              <NotFound status={leverAdminError.status || 403} />
            )}

          {!pageLoading &&
            Object.keys(leverAdminError).length === 0 &&
            leverAdminData &&
            showLeverAdmin && (
              <div className="ncss-container  u-clearfix ">
                <div className="ncss-row page-header pb0-sm pb2-md">
                  <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                    <h3 className="pt3-sm main-heading  c-f-500">
                      {userProfileData && userProfileData.factoryType && (
                        <span>
                          {pageType === 'AP' ? 'Apparel ' : 'Footwear '} Levers
                          Management
                        </span>
                      )}
                    </h3>
                  </div>
                  <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                    <Button
                      onClick={() => {
                        this.saveDataChanges();
                      }}
                      extraClassName={`ncss-btn-black u-uppercase  dashboard-button`}
                      disabled={!treeInputTouched}
                    >
                      Save
                    </Button>
                  </div>
                </div>

                <div className="ncss-row page-menu-header">
                  <NavLink
                    className="menuOpt1Item  "
                    to={`/admin/manageView/${pageType}`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        localStorage.getItem('isLeverAdminDataChanged') ===
                        'true'
                      ) {
                        confirmAlert({
                          message: msgConstants.pageChangeAlert,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                localStorage.setItem(
                                  'isLeverAdminDataChanged',
                                  'false'
                                );
                                history.push(`/admin/manageView/${pageType}`);
                              }
                            },
                            {
                              label: 'No',
                              onClick: () => {}
                            }
                          ]
                        });
                      } else {
                        history.push(`/admin/manageView/${pageType}`);
                      }
                    }}
                  >
                    <span className="text">Capacity</span>
                  </NavLink>

                  <NavLink
                    className="menuOpt1Item active"
                    to={`/admin/manageLevers/${pageType}`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        localStorage.getItem('isLeverAdminDataChanged') ===
                        'true'
                      ) {
                        confirmAlert({
                          message: msgConstants.pageChangeAlert,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                localStorage.setItem(
                                  'isLeverAdminDataChanged',
                                  'false'
                                );
                                history.push(`/admin/manageLevers/${pageType}`);
                              }
                            },
                            {
                              label: 'No',
                              onClick: () => {}
                            }
                          ]
                        });
                      } else {
                        history.push(`/admin/manageLevers/${pageType}`);
                      }
                    }}
                  >
                    <span className="text">Levers</span>
                  </NavLink>
                  <NavLink
                    className="menuOpt1Item"
                    to={`/admin/manageLrcp/${pageType}`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        localStorage.getItem('isLeverAdminDataChanged') ===
                        'true'
                      ) {
                        confirmAlert({
                          message: msgConstants.pageChangeAlert,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                localStorage.setItem(
                                  'isLeverAdminDataChanged',
                                  'false'
                                );
                                history.push(`/admin/manageLrcp/${pageType}`);
                              }
                            },
                            {
                              label: 'No',
                              onClick: () => {}
                            }
                          ]
                        });
                      } else {
                        history.push(`/admin/manageLrcp/${pageType}`);
                      }
                    }}
                  >
                    <span className="text">LRCP</span>
                  </NavLink>
                </div>
                <div className=" bg-white c-f-border-5 p3-sm mb5-sm">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12 ncss-col-md-12 ncss-col-lg-12 va-sm-t">
                      <div className="adminPanel c-f-all">
                        <div className="ncss-row">
                          <div className="ncss-col-sm-4 va-sm-t  open-sans-c u-bold">
                            <h3 className="validation-header c-f">
                              Lever List
                            </h3>
                            <br />
                          </div>
                          <div className="ncss-col-sm-8">
                            <button
                              onClick={() => this.toggleCollapse()}
                              className="fl-sm-r  c-f ncss-btn-primary-dark ml2-sm"
                            >
                              <i
                                className={`fas  ${
                                  isTreeExpanded ? 'fa-minus' : 'fa-plus'
                                }`}
                              />
                              {isTreeExpanded ? '  Collapse' : ' Expand'}
                            </button>
                            <button
                              onClick={() => this.addNewListCat()}
                              className="fl-sm-r  c-f ncss-btn-primary-dark"
                            >
                              <i className="fas fa-plus" /> New Category
                            </button>
                          </div>
                        </div>

                        <div
                          style={{
                            height: 'calc( 60vh )',
                            width: '100%'
                          }}
                        >
                          <SortableTree
                            treeData={listItems}
                            rowHeight={50}
                            canNodeHaveChildren={node => node.isChild}
                            canDrop={this.canDrop}
                            onChange={treeData => {
                              this.setState({ listItems: treeData });
                            }}
                            onMoveNode={() => this.setTreeInputTouched()}
                            generateNodeProps={({ node, path }) => ({
                              title: this.renderTitle(
                                node,
                                setListItems,
                                listItems,
                                path
                              ),
                              buttons: this.renderNodeButtons(
                                node,
                                setListItems,
                                listItems,
                                path
                              )
                            })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ncss-col-sm-12 ">
                      <div className="ncss-container comment-container">
                        <div className="ncss-row">
                          <div className="ncss-col-sm-12">
                            <div>
                              <Button
                                onClick={this.downloadHistory}
                                extraClassName={`fl-sm-r ncss-btn-primary-dark ncss-btn-sm`}
                              >
                                <i className="fas fa fa-download" />
                                <span className="d-sm-h d-md-ib">Download</span>
                              </Button>
                              <br />
                              <br />
                            </div>
                            {leverHistory && leverHistory.length > 0 && (
                              <div
                                id="myGrid"
                                style={{
                                  height: 'calc( 100vh - 40px )',
                                  width: '100%'
                                }}
                                className="ag-theme-balham"
                              >
                                <Grid
                                  defaultColDef={gridDefault}
                                  columnDefs={historyHeader}
                                  rowData={leverHistory}
                                  dbGridFunctions={false}
                                  setheight="100vh"
                                  setParams={this.setParams}
                                  headerHeight={60}
                                  animateRows={true}
                                  excelStyles={excelStyles}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

LeverAdmin.propTypes = {
  history: PropTypes.object,
  requestAdminData: PropTypes.func,
  updateLeverAdminData: PropTypes.func,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  updatingLeverAdminLoading: PropTypes.any,
  updatingLeverAdminSuccess: PropTypes.any,
  leverAdminData: PropTypes.object,
  requestLeverAdminData: PropTypes.func
};

const mapStateToProps = state => ({
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  leverAdminData: getLeverAdminData(state),
  leverAdminLoading: getLeverAdminLoading(state),
  leverAdminError: getLeverAdminError(state),
  updateLeverAdminLoading: getUpdatingLeverAdminLoading(state),
  updateLeverAdminSuccess: getUpdatingLeverAdminSuccess(state)
});

const mapDispatchToProps = dispatch => ({
  requestLeverAdminData: status => {
    dispatch(createServiceAction('updateLeverAdminData', 'loading')(false));
    dispatch(createServiceAction('updateLeverAdminData', 'success')(false));
    dispatch(createServiceAction('leverAdminData', 'requested')(status));
  },
  requestUserProfileData: () => {
    dispatch(createServiceAction('getUserProfile', 'requested')());
  },
  updateLeverAdminData: (data, history) => {
    dispatch(
      createServiceAction(
        'updateLeverAdminData',
        'submited'
      )({
        ...data,
        history
      })
    );
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeverAdmin)
);
