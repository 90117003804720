const commonCellBorder = {
  color: '#9a9a9a',
  lineStyle: 'Continuous',
  weight: 1
};
const commonWhiteBorder = {
  color: '#ffffff',
  lineStyle: 'Continuous',
  weight: 1
};
const gridConstants = {
  defaultColDef: {
    resizable: true,
    width: 150,
    editable: true,
    filter: false,
    sort: true,
    enableRowGroup: false,
    enablePivot: false,
    enableBrowserTooltips: true,
    suppressClipboardPaste: true,
    enableRangeSelection: true,
    enableCellTextSelection: true
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel'
      }
    ],
    defaultToolPanel: 'columns'
  },
  excelStyles: [
    {
      id: 'greenBackground',
      interior: {
        color: '#b5e6b5',
        pattern: 'Solid'
      }
    },

    {
      id: 'header',
      interior: {
        color: '#000000',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff'
      }
    },
    {
      id: 'editableColumn',
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'nonEditableColumn',

      alignment: {
        wrapText: true
      },
      protection: {
        protected: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'footerContent',

      alignment: {
        wrapText: true
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'whiteText',

      font: {
        color: '#969696',
        size: 10
      },
      interior: {
        color: '#d4d0d0',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableGreyColumn',
      interior: {
        color: '#d4d0d0',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableYellowColumn',
      interior: {
        color: '#FFFFCC',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableRedColumn',
      interior: {
        color: '#f9baba',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'titleHeader',
      interior: {
        color: '#808080',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 12
      },
      protection: {
        protected: true,
        hideFormula: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'headGreyCloud',
      interior: {
        color: '#aeaaaa',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headLapisBlue',
      interior: {
        color: '#203763',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headBlueEyes',
      interior: {
        color: '#0372c3',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },

    {
      id: 'headViolet',
      interior: {
        color: '#4a71b7',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headSapphireBlue',
      interior: {
        color: '#0372c3',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headSilkBlue',
      interior: {
        color: '#8faad9',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'editableColHeader',
      interior: {
        color: '#62a57e',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'GreyCloud',
      interior: {
        color: '#efefef',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'LapisBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'BlueEyes',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'Violet',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'SapphireBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'SilkBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'editableCol',
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'peachOrange',
      interior: {
        color: '#ffcc99',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    }
  ]
};

export default gridConstants;
