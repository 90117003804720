/* eslint-disable max-statements */
/* eslint-disable complexity */
import * as moment from 'moment';
import _ from 'lodash';
import { msgConstants } from '../constants/msgConstants.js';
import { columnConstants } from '../constants/columnConstants.js';
import { holidayCalculator } from '../util/dashboardGridConfigure.js';
import { isEmpty } from '../util/general.js';
import * as XLSX from 'xlsx/xlsx.mjs';

// for search form validation
export const searchValidator = (searchGroups, searchParams) => {
  const searchStatus = { status: true, message: '' };
  let GroupAndCode = 0;
  searchGroups.forEach(gridItem => {
    if (gridItem.selected.length === 0) {
      GroupAndCode += 1;
    }
    if (GroupAndCode > 1) {
      searchStatus.status = false;
      searchStatus.message += msgConstants.invalidSearchInput;
    }
  });

  searchParams.forEach(gridItem => {
    if (gridItem.required && gridItem.value.length === 0) {
      searchStatus.status = false;
      searchStatus.message += ` ${gridItem.name} is mandatory. `;
    }
  });
  const startDate =
    searchParams.find(o => o.field === 'startWeekDate').value || '';
  const endDate = searchParams.find(o => o.field === 'endWeekDate').value || '';
  // factory code mandatory !moment(startDate, 'MM/DD/YYYY',true).isValid()

  // start date should be lesser than end date
  if (startDate.length > 0 && endDate.length > 0 && startDate > endDate) {
    searchStatus.status = false;
    searchStatus.message +=
      ' Start Date is greater that End date. Please choose valid date range !';
  }

  return searchStatus;
};

// for ag grid custom footer download
export const fetchAllQueryValue = (gridItem, ifAll) => {
  let allSelVal = '';
  let ifAllSel = false;
  if (gridItem.selected && gridItem.selected.length > 0) {
    gridItem.selected.forEach(v => {
      allSelVal += `${allSelVal ? ',' : ''}${v.value}`;
      if (v.name === 'ALL' && gridItem.field === 'factoryCode') {
        ifAllSel = true;
      }
    });
  } else if (gridItem.selected && gridItem.selected.key) {
    allSelVal += `${allSelVal ? ',' : ''}${gridItem.selected.value}`;
  } else {
    ifAllSel = false;
  }
  if (
    allSelVal.length === 0 &&
    (ifAllSel || ifAll) &&
    gridItem &&
    gridItem.options &&
    gridItem.options.length
  ) {
    allSelVal = '';
    gridItem.options.forEach(v => {
      allSelVal += `${allSelVal ? ',' : ''}${v.value}`;
    });
  }
  return allSelVal;
};
// for search post validation frame query
export const searchQueryFrame = (
  num,
  searchGroups,
  searchParams,
  gridPagination,
  recCount,
  revisedWeeks
) => {
  const count = recCount || 104;
  let allString = '';
  const factCode = searchGroups.find(o => o.field === 'factoryCode');
  const returFactoryCodeValue = fetchAllQueryValue(factCode, true) || '';

  if (num === 1) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCodes=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    } else {
      const factGrp = searchGroups.find(o => o.field === 'factoryGroup');
      const returFactoryGrpValue = fetchAllQueryValue(factGrp, true) || '';
      const updateVal = `factoryGroup=${returFactoryGrpValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
  } else if (num === 2) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCode=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
    if (searchParams && searchParams.length > 0) {
      searchParams.forEach(gridItem => {
        let returValue = '';
        switch (gridItem.field) {
          case 'capacityTypeCode':
            returValue = fetchAllQueryValue(gridItem, false);
            break;
          case 'startWeekDate':
          case 'endWeekDate':
            returValue = gridItem.value
              ? `${gridItem.field}=${moment(new Date(gridItem.value)).format(
                  'MM/DD/YYYY'
                )}`
              : '';
            break;
          default:
            returValue = '';
        }
        if (returValue) {
          const updateVal = `${gridItem.field}=${returValue}`;
          allString += allString ? `&${updateVal}` : updateVal;
        }
      });
    }

    if (Object.keys(gridPagination).length > 0) {
      const queryString = Object.keys(gridPagination)
        .map(key => `${key}=${gridPagination[key]}`)
        .join('&');
      allString += `${allString ? '&' : '?'}${queryString}`;
    } else {
      allString += `${allString ? '&' : '?'}count=${count}&anchor=1`;
    }
    if (revisedWeeks >= 0) {
      allString += `${allString ? '&' : '?'}boundaryWeek=${revisedWeeks}`;
    }
  }

  return allString;
};

// for ag grid custom footer download
export const agGridDownloadFileName = (
  defaultStatus,
  userType,
  dashboardType,
  userName,
  isToggleOn
) => {
  const date = moment().format('MM-DD-YYYY-HH-mm-ss');
  const viewIs = defaultStatus === 'MaxAvailable' ? 'STRATEGIC' : 'REVISED';
  const typeIs = dashboardType === 'view' ? 'UPLOAD' : 'VIEW';
  const gridView = !isToggleOn ? 'WEEKLY' : 'QUARTERLY';
  const fileInfo = {
    fileName: `CAPVIZ-${viewIs}-${typeIs}-${gridView}-${userType}-${date}`,
    sheetName: `${viewIs} CAPACITY ${typeIs}`,
    fileID: `CAPVIZ-${viewIs}-${typeIs}-${gridView}-${userName}-${userType}-${date}`
  };
  return fileInfo;
};
// for ag grid custom footer download
export const agGridCustomFooter = getAllColumns => {
  let customfooter = [];
  let reasonCodeOptions = [];
  getAllColumns.forEach(column => {
    if (
      column.colDef.field === 'maxAvailableReasonCode' ||
      column.colDef.field === 'shortHorizonReasonCode'
    ) {
      const onlySelectValues = column.colDef.cellEditorParams();
      // reasonCodeOptions = column.colDef.filterParams.values;
      reasonCodeOptions = onlySelectValues.values;
      if (reasonCodeOptions) {
        customfooter.push([]);
        customfooter.push([]);
        customfooter.push([
          {
            styleId: 'header',
            // mergeAcross: 2,
            data: {
              type: 'String',
              value: 'Reason Codes'
            }
          }
        ]);
        reasonCodeOptions.forEach(value => {
          let footerContent = [
            {
              styleId: 'footerContent',
              //  mergeAcross: 2,
              data: {
                type: 'String',
                value: value || ''
              }
            }
          ];

          customfooter.push(footerContent);
        });
      }
    }
  });
  return customfooter;
};

//Creating new excel Renderer
export const CustomExcelRenderer = (file, callback) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    var rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      var bstr = e.target.result;
      var wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        cellDates: true
      });

      /* Get first worksheet */
      var wsname = wb.SheetNames[0];
      var ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      var json = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        dateNF: 'mm/dd/yyyy'
      });
      var cols = make_cols(ws['!ref']);
      json.forEach(col => {
        const columnIndex = col.findIndex(c => c instanceof Date && !isNaN(c));
        if (columnIndex) {
          col[columnIndex] = moment(col[columnIndex]).format('MM/DD/YYYY');
        }
      });

      var data = { rows: json, cols: cols };

      resolve(data);
      return callback(null, data);
    };
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

const make_cols = refstr => {
  var o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (let i = 0; i < C; ++i) {
    o[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return o;
};

// for ag grid excel data pre formatter
export const agGridDownloadDataFormat = paramsl => {
  if (paramsl.value) {
    if (paramsl.column.colDef.filter === 'agDateColumnFilter') {
      const replaceDate = new Date(paramsl.value);
      return moment(replaceDate).format('MM/DD/YYYY');
    }
    if (paramsl.column.colDef.field === 'productionQuarter') {
      const yearIs = String(paramsl.value) || '';
      if (paramsl.value) {
        return `${yearIs.substring(0, 4)}-Q${yearIs.substring(4, 5)}`;
      }
    }
    if (
      paramsl.column.colDef.field === 'maxAvailableDelta' ||
      paramsl.column.colDef.field === 'shortHorizonDelta'
    ) {
      return `${paramsl.value}${paramsl.value ? '%' : ''}`;
    }

    if (paramsl.column.colDef.headerName === 'Notes') {
      return '';
    }
  }
  if (paramsl.column.colDef.field === 'numberOfHolidays' && !paramsl.value) {
    return `0`;
  }
  return paramsl.value;
};

// eslint-disable-next-line max-statements
export const updateColumnFieldsPostChange = (
  columnModified,
  currentCellValue,
  userType,
  userName,
  dBoard
) => {
  const result = {};
  const statusChange = {};
  const newVal = currentCellValue;
  let col2Check = '';
  const status =
    dBoard === 'MaxAvailable' ? 'maxAvailableStatus' : 'shortHorizonStatus';
  let modified = '';
  if (userType === 'FACTORY' || userType === 'gsm') {
    col2Check =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableCapacity'
        : 'shortHorizonCapacity';
    if (columnModified === col2Check) {
      result[status] = newVal.length !== 0 ? 'In Review' : '';
    }
  } else if (userType === 'NLO') {
    col2Check =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableUnitsConfirmed'
        : 'shortHorizonUnitsConfirmed';
    modified =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableConfirmedBy'
        : 'shortHorizonConfirmedBy';
    if (columnModified === col2Check) {
      result[modified] = newVal.length !== 0 ? userName : '';
      result[status] = newVal.length !== 0 ? 'Confirmed' : 'In Review';
      statusChange.nloConfirmed = newVal.length !== 0 ? true : false;
    }

    col2Check =
      dBoard === 'MaxAvailable'
        ? 'approvedMaxCapacity'
        : 'approvedRevisedCapacity';
    modified =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableApprovedBy'
        : 'shortHorizonApprovedBy';
    if (columnModified === col2Check) {
      result[status] = newVal.length !== 0 ? 'Approved' : 'Confirmed';
      result[modified] = newVal.length !== 0 ? userName : '';
      statusChange.nloApproved = newVal.length !== 0 ? true : false;
    }
  } else if (userType === 'WHQ') {
    col2Check =
      dBoard === 'MaxAvailable'
        ? 'approvedMaxCapacity'
        : 'approvedRevisedCapacity';
    modified =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableApprovedBy'
        : 'shortHorizonApprovedBy';
    if (columnModified === col2Check) {
      result[status] = newVal.length !== 0 ? 'Approved' : 'Confirmed';
      result[modified] = newVal.length !== 0 ? userName : '';
    }
  }

  return { toChangeCondition: result, statusChange };
};

// for ag grid custom footer download
export const removeAllFromOptions = arr => {
  arr.forEach(item => {
    if (item.options) {
      const foundIndex = item.options.findIndex(i => i.name === 'ALL');
      if (foundIndex >= 0) {
        item.options.splice(foundIndex, 1);
      }
    }
    if (item.backOptions) {
      const foundIndex = item.backOptions.findIndex(i => i.name === 'ALL');
      if (foundIndex >= 0) {
        item.options.splice(foundIndex, 1);
      }
    }
  });
  return arr;
};

// for ag grid custom footer download
export const updateErrorReport = (report, UploadStatusData) => {
  let uploadReport = [...UploadStatusData];
  if (uploadReport) {
    //report.outputData &&
    //let APIErrorReport = [...report.outputData.errors]|| [];
    let APIFailedReport = [];
    if (report && report.outputData && report.outputData.failed) {
      APIFailedReport = [...report.outputData.failed];
    }

    uploadReport.forEach(item => {
      /*
      if (APIErrorReport && APIErrorReport.length > 0) {
        let errorID = APIErrorReport.findIndex(i => i.id === item.id);
        if (errorID >= 0) {
          item.message = 'API VALIDATION ERROR';
          item.status = false;
          item.type = 'API ERROR';
        }
      } */
      if (APIFailedReport && APIFailedReport.length > 0) {
        let errorID = APIFailedReport.findIndex(i => i.id === item.id);

        if (errorID >= 0) {
          item.message = 'DATABASE UPDATING FAILED';
          item.status = false;
          item.type = 'API FAILURE';
        }
      }
    });
  }
  return uploadReport;
};

export const updateFailedReport = UploadStatusData => {
  let uploadReport = [...UploadStatusData];
  if (uploadReport) {
    uploadReport.forEach(item => {
      if (item.status) {
        item.message = 'DATABASE UPDATING FAILED';
        item.status = false;
        item.type = 'API FAILURE';
      }
    });
  }
  return uploadReport;
};

//used in both dashboardType for SearchCount
export const updatePageSearchCountFn = (
  usrFactGrpSearchData,
  searchParams,
  num
) => {
  if (usrFactGrpSearchData) {
    if (num === 1) {
      searchParams.find(
        o => o.field === 'factoryCode'
      ).options = usrFactGrpSearchData;
    } else if (num === 2) {
      const faCodSeltd =
        searchParams.find(o => o.field === 'factoryCode').value || '';
      const faCodSeltdOptions =
        searchParams.find(o => o.value === faCodSeltd).options || [];
      let faCodSeltdCCd = [];
      const faCodSeltdList = faCodSeltdOptions.find(
        o => o.value === faCodSeltd
      );
      if (faCodSeltdList) {
        faCodSeltdCCd = faCodSeltdList.capacitycodes;
      }

      searchParams.find(
        o => o.field === 'capacityTypeCode'
      ).options = faCodSeltdCCd;
      searchParams.find(o => o.field === 'capacityTypeCode').disabled = false;
      if (faCodSeltdCCd.length === 0) {
        searchParams.find(o => o.field === 'capacityTypeCode').value = '';
      }
    }
  }
  return searchParams;
};

// eslint-disable-next-line max-statements
export const updateNewCommentEntry = (
  value,
  currentValue,
  currentuseremail,
  userType,
  isToggleOn
) => {
  const currentArray = currentValue || [];
  const newComment = {
    comment: value,
    userRole: userType,
    userId: currentuseremail.toLowerCase(),
    timestamp: Date.parse(new Date())
  };
  if (isToggleOn) {
    newComment.isQuarterly = true;
  }

  currentArray.push(newComment);
  return currentArray;
};

const getNewValue = newValue1 => {
  if (newValue1 === 0 || (newValue1 && newValue1.toString().length > 0)) {
    return newValue1;
  }
  return '';
};

export const validateDataByRowOnUpload = (
  currentField,
  backUpColVal,
  newValue1,
  actGrdRow,
  NewGridItem,
  reasonCodes,
  reasonCodesAll,
  userType,
  defaultStatus
) => {
  let newValue = getNewValue(newValue1);
  const returnData = { value: '', message: null, status: false };
  // validation for capacity range
  if (columnConstants.numberEditableCols.includes(currentField)) {
    // for number columns
    if (newValue && newValue.toString().length > 9) {
      returnData.message = msgConstants.noChangeUpdate;
    } else if (
      !isNaN(newValue) &&
      newValue.toString().length > 0 &&
      Number(newValue) >= 0 &&
      Number(newValue) !== backUpColVal
    ) {
      // validation for reason code
      if (!!(newValue % 1)) {
        returnData.message = 'Number Can be integer only';
      } else {
        if (userType === 'FACTORY') {
          let reasonCode =
            NewGridItem.maxAvailableReasonCode ||
            NewGridItem.shortHorizonReasonCode ||
            '';
          let reasonCodeValid = false;
          if (backUpColVal && backUpColVal.toString().length > 0) {
            if (
              reasonCodes.includes(reasonCode) ||
              reasonCodesAll.includes(reasonCode)
            ) {
              reasonCodeValid = true;
            }
          } else {
            if (reasonCodes.includes(reasonCode)) {
              reasonCodeValid = true;
            }
          }
          if (currentField === 'FctyMaxCapacity') {
            let maxCapacityReasonCode = NewGridItem.maxCapacityReasonCode
              ? NewGridItem.maxCapacityReasonCode
              : '';
            if (
              reasonCodes.includes(maxCapacityReasonCode) ||
              reasonCodesAll.includes(maxCapacityReasonCode)
            ) {
              reasonCodeValid = true;
            } else {
              reasonCodeValid = false;
            }
          }
          if (reasonCodeValid) {
            returnData.value = newValue;
            returnData.status = true;
          } else {
            returnData.message = 'Invalid Reason Code';
          }
        } else {
          returnData.value = newValue;
          returnData.status = true;
        }
      }
    } else if (!isNaN(newValue) && Number(newValue) < 0) {
      returnData.message = 'Negative numbers not accepted';
    } else if (!newValue && Number(backUpColVal)) {
      returnData.message = 'Number cant replaced as Empty';
    } else if (isNaN(newValue)) {
      returnData.message = 'Numbers only accepted';
    }
  }
  // validation for reason code
  else if (columnConstants.reasonCodeEditableCols.includes(currentField)) {
    let CapacityNew = '';
    let oldReasonCode = '';
    if (currentField === 'maxAvailableReasonCode') {
      CapacityNew = NewGridItem.maxAvailableCapacity || '';
      oldReasonCode = actGrdRow.maxAvailableReasonCode || '';
    } else if (currentField === 'shortHorizonReasonCode') {
      CapacityNew = NewGridItem.shortHorizonCapacity || '';
      oldReasonCode = actGrdRow.shortHorizonReasonCode || '';
    } else if (currentField === 'maxCapacityReasonCode') {
      let cpNewVal = NewGridItem.FctyMaxCapacity;
      if (cpNewVal === 0 || (cpNewVal && cpNewVal.toString().length > 0)) {
        CapacityNew = NewGridItem.FctyMaxCapacity;
      }
      oldReasonCode = actGrdRow.maxCapacityReasonCode || '';
    }
    if (userType === 'FACTORY') {
      if (newValue.toString() !== oldReasonCode.toString()) {
        if (Number(CapacityNew) >= 0) {
          if (reasonCodes.includes(newValue)) {
            let FactoryField =
              defaultStatus === 'MaxAvailable'
                ? NewGridItem.maxAvailableCapacity
                : NewGridItem.shortHorizonCapacity;
            let FactoryFieldStr = '';
            if (
              FactoryField === 0 ||
              (FactoryField && FactoryField.toString().length > 0)
            ) {
              FactoryFieldStr = FactoryField.toString();
            }
            if (currentField === 'maxCapacityReasonCode') {
              if (
                NewGridItem.FctyMaxCapacity === 0 ||
                (NewGridItem.FctyMaxCapacity &&
                  NewGridItem.FctyMaxCapacity.toString().length > 0)
              ) {
                FactoryFieldStr = NewGridItem.FctyMaxCapacity.toString();
              }
            }
            if (FactoryFieldStr.length > 0) {
              returnData.value = newValue;
              returnData.status = true;
            } else {
              returnData.message = 'Invalid Capacity Value';
            }
          } else {
            returnData.message = 'Invalid Reason Code';
          }
        } else {
          returnData.message = 'Invalid Capacity Value';
        }
      }
    }
  }
  // for update notes
  else if (columnConstants.notesEditableCols.includes(currentField)) {
    if (newValue && newValue.length > 0) {
      returnData.value = newValue;
      returnData.status = true;
    }
  }
  return returnData;
};

export const validateNumberChange = (
  newValue,
  oldValue,
  field,
  IsExcel,
  isProdView
) => {
  const maxLimit = isProdView ? 10 : 9;
  const result = {
    value: newValue,
    message: 'Invalid Capacity Value',
    status: false,
    field
  };
  const numbersOnly = /^[0-9]+$/;

  if (newValue === 0 || (newValue && newValue.toString().length > 0)) {
    const newValStr = newValue.toString();
    if (newValue && newValue.toString().length > maxLimit) {
      result.message = msgConstants.noChangeUpdate;
    } else if (
      Number(newValue) >= 0 &&
      Number(newValue) % 1 === 0 &&
      newValStr.match(numbersOnly)
    ) {
      if (IsExcel && Number(newValue) !== oldValue) {
        result.value = newValue;
        result.status = true;
        result.message = 'Accepted';
      } else {
        result.value = newValue;
        result.status = true;
        result.message = 'Accepted';
      }
    } else if (Number(newValue) < 0) {
      result.message = 'Negative numbers not accepted';
    } else if (isNaN(newValue)) {
      result.message = 'Numbers only accepted';
    } else if (Number(newValue) % 1 !== 0) {
      result.message = 'Decimal Values not Accepted';
    }
  } else if (!newValue && Number(oldValue)) {
    result.message = 'Number cant replaced as Empty';
  } else if (isNaN(newValue)) {
    result.message = 'Invalid Capacity Value';
  }
  return result;
};

export const validateRCChange = (
  newValue,
  oldValue,
  reasonCodes,
  reasonCodesAll,
  field
) => {
  const result = {
    value: newValue,
    message: 'Invalid ReasonCode',
    status: false,
    field
  };

  if (oldValue && oldValue.toString().length > 0) {
    if (reasonCodesAll.includes(newValue)) {
      result.value = newValue;
      result.status = true;
      result.message = 'Accepted';
    }
  } else if (reasonCodes.includes(newValue)) {
    result.value = newValue;
    result.status = true;
    result.message = 'Accepted';
  }

  return result;
};

export const validateDataByRowOnlyOnUpload = (
  newDataRow,
  actGrdRow,
  backUpGridRow,
  userType,
  reasonCodes,
  reasonCodesAll,
  defaultStatus,
  currentuseremail,
  IsExcel,
  maxColumnEditable,
  isProdView,
  userFactoryType
) => {
  const result = [];
  const colModified = [];
  actGrdRow.ChangeByUpload = 0;
  actGrdRow.colModified = [];
  // Validation based on user type
  if (userType === 'NLO') {
    const CPCol =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableUnitsConfirmed'
        : 'shortHorizonUnitsConfirmed';
    const CPValue = newDataRow[CPCol];
    const CPValidate = validateNumberChange(
      CPValue,
      backUpGridRow[CPCol],
      CPCol,
      IsExcel,
      isProdView
    );
    if (CPValidate.status) {
      actGrdRow[CPCol] = CPValue;
      actGrdRow.ChangeByUpload = 1;
    }
    if (
      (CPValue === 0 || (CPValue && CPValue.toString().length > 0)) &&
      CPValue !== backUpGridRow[CPCol]
    ) {
      colModified.push(CPCol);
      result.push(CPValidate);
    }
    if (defaultStatus !== 'MaxAvailable') {
      const ApRevCPValue = newDataRow.approvedRevisedCapacity;
      const ApRevCPValidate = validateNumberChange(
        ApRevCPValue,
        backUpGridRow.approvedRevisedCapacity,
        'approvedRevisedCapacity',
        IsExcel,
        isProdView
      );
      if (ApRevCPValidate.status) {
        actGrdRow.approvedRevisedCapacity = ApRevCPValue;
        actGrdRow.ChangeByUpload = 1;
      }
      if (
        (ApRevCPValue === 0 ||
          (ApRevCPValue && ApRevCPValue.toString().length > 0)) &&
        ApRevCPValue !== backUpGridRow.approvedRevisedCapacity
      ) {
        colModified.push('approvedRevisedCapacity');
        result.push(ApRevCPValidate);
      }
    }
  } else if (userType === 'WHQ') {
    const CPCol =
      defaultStatus === 'MaxAvailable'
        ? 'approvedMaxCapacity'
        : 'approvedRevisedCapacity';

    const CPValue = newDataRow[CPCol];
    const CPValidate = validateNumberChange(
      CPValue,
      backUpGridRow[CPCol],
      CPCol,
      IsExcel,
      isProdView
    );
    if (CPValidate.status) {
      actGrdRow[CPCol] = CPValue;
      actGrdRow.ChangeByUpload = 1;
    }
    if (
      (CPValue === 0 || (CPValue && CPValue.toString().length > 0)) &&
      CPValue !== backUpGridRow[CPCol]
    ) {
      colModified.push(CPCol);
      result.push(CPValidate);
    }

    if (isProdView) {
      let targetCaptyColumnName =
        userFactoryType === 'FW' ? 'targetCpty' : 'targetHldCpty';
      const tCpValue = newDataRow[targetCaptyColumnName];
      const tCpValidate = validateNumberChange(
        tCpValue,
        backUpGridRow[targetCaptyColumnName],
        targetCaptyColumnName,
        IsExcel,
        isProdView
      );
      if (
        (tCpValue === 0 || (tCpValue && tCpValue.toString().length > 0)) &&
        tCpValue !== backUpGridRow[targetCaptyColumnName]
      ) {
        if (tCpValidate.status) {
          actGrdRow[targetCaptyColumnName] = tCpValue;
          actGrdRow[
            userFactoryType === 'FW' ? 'targetHldCpty' : 'targetCpty'
          ] = holidayCalculator(
            userFactoryType === 'FW' ? 'targetHldCpty' : 'targetCpty',
            newDataRow
          );
          actGrdRow.ChangeByUpload = 1;
        }
        colModified.push(targetCaptyColumnName);
        result.push(tCpValidate);
      }
    }
  } else if (userType === 'FACTORY') {
    const CPCol =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableCapacity'
        : 'shortHorizonCapacity';
    const RCCol =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableReasonCode'
        : 'shortHorizonReasonCode';

    const RCValue = newDataRow[RCCol] || '';
    const CPValue = newDataRow[CPCol];
    const CPValidate = validateNumberChange(
      CPValue,
      backUpGridRow[CPCol],
      CPCol,
      IsExcel,
      isProdView
    );
    const BacUpRC = backUpGridRow[RCCol] || '';
    const RCValidate = validateRCChange(
      RCValue,
      BacUpRC,
      reasonCodes,
      reasonCodesAll,
      RCCol
    );

    if (CPValidate.status && RCValidate.status) {
      actGrdRow[RCCol] = RCValue;
      actGrdRow[CPCol] = CPValue;
    } else {
      actGrdRow[RCCol] = BacUpRC;
      if (
        backUpGridRow[CPCol] === 0 ||
        (backUpGridRow[CPCol] && backUpGridRow[CPCol].toString().length > 0)
      ) {
        actGrdRow[CPCol] = backUpGridRow[CPCol];
      } else {
        actGrdRow[CPCol] = '';
      }

      if (CPValidate.status && !RCValidate.status) {
        CPValidate.message = 'Invalid ReasonCode';
        CPValidate.status = RCValidate.status;
      } else if (!CPValidate.status && RCValidate.status) {
        RCValidate.message = 'Invalid Capacity Value';
        RCValidate.status = CPValidate.status;
      }
    }
    if (
      (CPValue === 0 || (CPValue && CPValue.toString().length > 0)) &&
      CPValue !== backUpGridRow[CPCol]
    ) {
      result.push(CPValidate);
      CPValidate.status && colModified.push(CPCol);
    }
    if (String(RCValue) !== String(BacUpRC)) {
      result.push(RCValidate);
      RCValidate.status && colModified.push(RCCol);
    }

    //ChangeByUpload
    let maxColEdit = true;
    if (defaultStatus !== 'MaxAvailable' && !maxColumnEditable) {
      maxColEdit = false;
    }

    if (maxColEdit) {
      const CPMaxValue = newDataRow.FctyMaxCapacity;
      const RCMaxValue = newDataRow.maxCapacityReasonCode || '';

      const CPMaxValidate = validateNumberChange(
        CPMaxValue,
        backUpGridRow.FctyMaxCapacity,
        'FctyMaxCapacity',
        IsExcel,
        isProdView
      );
      const BackUpMaxRC = backUpGridRow.maxCapacityReasonCode || '';
      const RCMaxValidate = validateRCChange(
        RCMaxValue,
        BackUpMaxRC,
        reasonCodes,
        reasonCodesAll,
        'maxCapacityReasonCode'
      );
      if (CPMaxValidate.status && RCMaxValidate.status) {
        actGrdRow.maxCapacityReasonCode = RCMaxValidate.value;
        actGrdRow.FctyMaxCapacity = CPMaxValidate.value;
      } else {
        actGrdRow.maxCapacityReasonCode = BackUpMaxRC;
        if (
          backUpGridRow[CPCol] === 0 ||
          (backUpGridRow.FctyMaxCapacity &&
            backUpGridRow.FctyMaxCapacity.toString().length > 0)
        ) {
          actGrdRow.FctyMaxCapacity = backUpGridRow.FctyMaxCapacity;
        } else {
          actGrdRow.FctyMaxCapacity = '';
        }

        if (CPMaxValidate.status && !RCMaxValidate.status) {
          CPMaxValidate.message = 'Invalid ReasonCode';
          CPMaxValidate.status = RCMaxValidate.status;
        } else if (!CPMaxValidate.status && RCMaxValidate.status) {
          RCMaxValidate.message = 'Invalid Capacity Value';
          RCMaxValidate.status = CPMaxValidate.status;
        }
      }

      if (
        (CPMaxValue === 0 ||
          (CPMaxValue && CPMaxValue.toString().length > 0)) &&
        CPMaxValue !== backUpGridRow.FctyMaxCapacity
      ) {
        result.push(CPMaxValidate);
        CPMaxValidate.status && colModified.push('FctyMaxCapacity');
      }

      if (String(RCMaxValue) !== String(BackUpMaxRC)) {
        result.push(RCMaxValidate);
        RCMaxValidate.status && colModified.push('maxCapacityReasonCode');
      }
    }
    const isChanged = result.findIndex(o => o.status === true);
    actGrdRow.ChangeByUpload = isChanged >= 0 ? 1 : 0;
  }
  actGrdRow.colModified = colModified;

  // Validation common for all
  //1.update notes
  const NoteCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotes'
      : 'shortHorizonNotes';
  const NoteCountCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotesCount'
      : 'shortHorizonNotesCount';
  const QuarterNoteCountCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotesQuarterCount'
      : 'shortHorizonNotesQuarterCount';
  const NoteValue = newDataRow[NoteCol];
  if (
    IsExcel &&
    (NoteValue || NoteValue === 0) &&
    (typeof NoteValue === 'string' ||
      NoteValue instanceof String ||
      typeof NoteValue === 'number') &&
    NoteValue.toString().length > 0
  ) {
    const newComment = updateNewCommentEntry(
      NoteValue,
      backUpGridRow[NoteCol],
      currentuseremail,
      userType,
      isProdView
    );
    actGrdRow[NoteCol] = newComment;
    actGrdRow[NoteCountCol] = actGrdRow[NoteCountCol]
      ? actGrdRow[NoteCountCol] + 1
      : 1;

    if (isProdView) {
      actGrdRow[QuarterNoteCountCol] = actGrdRow[QuarterNoteCountCol]
        ? actGrdRow[QuarterNoteCountCol] + 1
        : 1;
    }
    actGrdRow.colModified.push(NoteCol);
    actGrdRow.ChangeByUpload = 1;
    result.push({
      value: NoteValue,
      message: 'New Comments Added',
      status: true,
      field: NoteCol
    });
  }
  //2. change last modified by applicable only for status changing capacity fields

  if (actGrdRow.colModified && actGrdRow.colModified.length > 0) {
    let EditByValid = 0;
    actGrdRow.colModified.forEach(col => {
      if (columnConstants.statusChangingCapCols.includes(col)) {
        EditByValid += 1;
      }
    });
    if (EditByValid > 0) {
      const ChangeBy =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableEditedBy'
          : 'shortHorizonEditedBy';
      const newAPC = isEmpty(newDataRow.approvedRevisedCapacity)
        ? ''
        : newDataRow.approvedRevisedCapacity;
      const oldAPC = isEmpty(backUpGridRow.approvedRevisedCapacity)
        ? ''
        : backUpGridRow.approvedRevisedCapacity;

      if (defaultStatus === 'MaxAvailable') {
        actGrdRow[ChangeBy] = userType;
      } else {
        actGrdRow[ChangeBy] = newAPC && newAPC !== oldAPC ? 'WHQ' : userType;
      }
    }
  }

  const newActRow = actGrdRow;
  return { newActRow, result };
};
//upload Validation
export const validateAllDataOnUpload = (
  data,
  activeGrid,
  reasonCodes,
  reasonCodesAll,
  userType,
  defaultStatus,
  gridColumns,
  currentuseremail,
  maxColumnEditable,
  isToggleOn,
  userFactoryType
) => {
  // upload validation
  let ErrorCounts = 0;
  const validationReport = [];
  const backUpGrid = JSON.parse(JSON.stringify(activeGrid));
  const AllColNames = [];
  gridColumns.forEach(keyCol => {
    const col = { name: keyCol.colDef.headerName, field: keyCol.colDef.field };
    AllColNames.push(col);
  });
  if (data.length > 0) {
    // for each current grid row

    activeGrid.forEach(actGrdRow => {
      const activeIndex = data.findIndex(i => i.id === actGrdRow.id);
      const backUpIndex = backUpGrid.findIndex(i => i.id === actGrdRow.id);
      if (activeIndex >= 0) {
        if (Object.keys(data[activeIndex]).length > 0) {
          actGrdRow.ChangeByUpload = 0;
          actGrdRow.colModified = [];
          const { newActRow, result } = validateDataByRowOnlyOnUpload(
            data[activeIndex],
            actGrdRow,
            backUpGrid[backUpIndex],
            userType,
            reasonCodes,
            reasonCodesAll,
            defaultStatus,
            currentuseremail,
            true,
            maxColumnEditable,
            isToggleOn,
            userFactoryType
          );

          if (
            newActRow.ChangeByUpload > 0 &&
            newActRow.colModified.length > 0
          ) {
            newActRow.colModified.forEach(col => {
              actGrdRow[col] = newActRow[col];
            });
            actGrdRow.ChangeByUpload = newActRow.ChangeByUpload;
            actGrdRow.colModified = newActRow.colModified;
          } else {
            actGrdRow.ChangeByUpload = 0;
          }

          if (result.length > 0) {
            const report = JSON.parse(JSON.stringify(result));
            report.forEach(log => {
              log.row = activeIndex + 2;
              log.fieldname = AllColNames.find(i => i.field === log.field).name;
              log.factoryCode = data[activeIndex].factoryCode;
              log.capacityTypeCode = data[activeIndex].capacityTypeCode;
              log.weekStartDate = data[activeIndex].weekStartDate;
              log.id = data[activeIndex].id;
              log.productionQuarter = data[activeIndex].productionQuarter;
              validationReport.push(log);
            });
          }
        } else {
          ErrorCounts += 1;
          validationReport.push({
            status: false,
            message: msgConstants.inValFormatDataCort
          });
        }
      }
    });
  } else {
    // updates if rows missing in upload, throws error and stops updating the uploaded data
    ErrorCounts += 1;
    validationReport.push({
      status: false,
      message: msgConstants.inValFormatDataCort
    });
  }
  if (ErrorCounts === 0) {
    return { activeGrid, validationReport };
  }
  return { activeGrid: [], validationReport };
};
//upload Validation
export const validateAllDataOnUploadOld = (
  data,
  activeGrid,
  reasonCodes,
  reasonCodesAll,
  userType,
  defaultStatus,
  gridColumns,
  currentuseremail
) => {
  // upload validation
  let ErrorCounts = 0;
  const validationReport = [];
  const backUpGrid = JSON.parse(JSON.stringify(activeGrid));

  if (data.length > 0) {
    // for each current grid row
    activeGrid.forEach(actGrdRow => {
      const activeIndex = data.findIndex(i => i.id === actGrdRow.id);
      const backUpIndex = backUpGrid.findIndex(i => i.id === actGrdRow.id);
      if (activeIndex >= 0) {
        if (Object.keys(data[activeIndex]).length > 0) {
          gridColumns.forEach(keyCol => {
            const headerName = keyCol.colDef.headerName;
            let backUpColVal = '';
            const validColVal = backUpGrid[backUpIndex][keyCol.colDef.field];
            if (
              validColVal === 0 ||
              validColVal === '0' ||
              (validColVal && validColVal.toString().length > 0)
            ) {
              backUpColVal = validColVal;
            }

            const userInput = data[activeIndex][keyCol.colDef.field];
            const ifNoteField = columnConstants.notesEditableCols.includes(
              keyCol.colDef.field
            );
            if (keyCol.colDef.editable || ifNoteField) {
              if (String(backUpColVal) !== String(userInput)) {
                // for what values vadidation

                const updateData = validateDataByRowOnUpload(
                  keyCol.colDef.field,
                  backUpColVal,
                  userInput,
                  actGrdRow,
                  data[activeIndex],
                  reasonCodes,
                  reasonCodesAll,
                  userType,
                  defaultStatus
                ); //currentField, backUpColVal, userInput, actGrdRow, NewGridItem
                const reportLog = { status: false, message: '' };
                reportLog.row = activeIndex + 2;
                reportLog.value = updateData.value || userInput;
                reportLog.fieldname = headerName;
                reportLog.factoryCode = data[activeIndex].factoryCode;
                reportLog.capacityTypeCode = data[activeIndex].capacityTypeCode;
                reportLog.weekStartDate = data[activeIndex].weekStartDate;
                reportLog.id = data[activeIndex].id;
                if (
                  updateData.value.toString().length > 0 &&
                  updateData.value !== actGrdRow[keyCol.colDef.field]
                ) {
                  if (updateData.value === 'NULLRETURN') {
                    actGrdRow[keyCol.colDef.field] = '';
                  } else {
                    /// on notes update as object else as vale
                    if (ifNoteField) {
                      const newComment = updateNewCommentEntry(
                        userInput,
                        backUpColVal,
                        currentuseremail,
                        userType,
                        false
                      );
                      actGrdRow[keyCol.colDef.field] = newComment;
                    } else {
                      actGrdRow[keyCol.colDef.field] = userInput;
                    }
                    ///
                  }
                  if (
                    columnConstants.numberEditableCols.includes(
                      keyCol.colDef.field
                    ) &&
                    keyCol.colDef.field !== 'FctyMaxCapacity'
                  ) {
                    if (defaultStatus === 'MaxAvailable') {
                      actGrdRow.maxAvailableEditedBy = userType;
                    }
                    if (defaultStatus === 'shortHorizon') {
                      actGrdRow.shortHorizonEditedBy =
                        keyCol.colDef.field === 'approvedRevisedCapacity'
                          ? 'WHQ'
                          : userType;
                    }
                  }

                  reportLog.status = true;
                  reportLog.message = 'Accepted';
                } else if (updateData.message) {
                  reportLog.message = updateData.message;
                }
                if (reportLog.message) {
                  validationReport.push(reportLog);
                }
              }
            } else {
            }
          });
        } else {
          ErrorCounts += 1;
          validationReport.push({
            status: false,
            message: msgConstants.inValFormatDataCort
          });
        }
      }
    });
  } else {
    // updates if rows missing in upload, throws error and stops updating the uploaded data
    ErrorCounts += 1;
    validationReport.push({
      status: false,
      message: msgConstants.inValFormatDataCort
    });
  }
  if (ErrorCounts === 0) {
    return { activeGrid, validationReport };
  }
  return { activeGrid: [], validationReport };
};

export const calculationAfterUpload = (
  oldData,
  userType,
  userName,
  dBoard,
  isProdView
) => {
  const data = oldData;
  data.forEach((item, i) => {
    let statusCol =
      dBoard === 'MaxAvailable' ? 'maxAvailableStatus' : 'shortHorizonStatus';
    let userCol = '';
    if (userType === 'FACTORY') {
      const col2Check =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableCapacity'
          : 'shortHorizonCapacity';
      const rCodeField =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableReasonCode'
          : 'shortHorizonReasonCode';
      const ChangeBy =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableEditedBy'
          : 'shortHorizonEditedBy';

      if (item.colModified && item.colModified.includes(col2Check)) {
        item[statusCol] = 'In Review';
      }
      if (
        isProdView &&
        item.colModified &&
        item.colModified.length > 0 &&
        item.colModified.includes(rCodeField) &&
        (col2Check === 0 ||
          col2Check === '0' ||
          (col2Check && col2Check.toString().length > 0))
      ) {
        item[statusCol] = 'In Review';
        item[ChangeBy] = userType;
      }
    } else if (userType === 'NLO') {
      const col2Check =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableUnitsConfirmed'
          : 'shortHorizonUnitsConfirmed';

      if (item.colModified && item.colModified.includes(col2Check)) {
        const userCol =
          dBoard === 'MaxAvailable'
            ? 'maxAvailableConfirmedBy'
            : 'shortHorizonConfirmedBy';
        item[statusCol] = 'Confirmed';
        item[userCol] = userName;
      }
      if (
        item.colModified &&
        item.colModified.includes('approvedRevisedCapacity')
      ) {
        statusCol = 'shortHorizonStatus';
        userCol = 'shortHorizonApprovedBy';
        item[statusCol] = 'Approved';
        item[userCol] = userName;
      }
    } else if (userType === 'WHQ') {
      const col2Check =
        dBoard === 'MaxAvailable'
          ? 'approvedMaxCapacity'
          : 'approvedRevisedCapacity';
      if (item.colModified && item.colModified.includes(col2Check)) {
        userCol =
          dBoard === 'MaxAvailable'
            ? 'maxAvailableApprovedBy'
            : 'shortHorizonApprovedBy';
        item[statusCol] = 'Approved';
        item[userCol] = userName;
      }
    }
  });
  return data;
};

export const frameDataOnUpload = (
  data,
  activeGridSetLength,
  gridColumns,
  headerLength
) => {
  const columns = [];
  const jsonData = [];

  gridColumns.forEach(i => {
    columns.push({ name: i.colDef.headerName, code: i.colId });
  });
  // setting up the index keys first
  const excelOtherHeaderLenEnd = headerLength + activeGridSetLength;
  const headerRow = data[headerLength];
  const columnOrder = [];
  headerRow.forEach(item => {
    let thisIndex =
      columns.findIndex(i => i.name === item) ||
      columns.findIndex(i => i.name.includes(item));

    if (thisIndex >= 0) {
      columnOrder.push(columns[thisIndex].code);
    }
  });

  data.forEach((row, index) => {
    if (index > 0 && index <= excelOtherHeaderLenEnd) {
      const newItems = {};
      row.forEach((item, key) => {
        const field = columnOrder[key];
        newItems[field] = item;
      });
      jsonData.push(newItems);
    }
  });

  return jsonData;
};

// for ag grid custom footer download
export const updateUploadDataFormat = (
  data,
  activeGrid,
  defaultStatus,
  isToggleOn
) => {
  let formattedData = JSON.parse(JSON.stringify(data)); //let formattedData = [...data];
  let formattedNewData = [];

  formattedData.forEach(dataItem => {
    const foundIndex = activeGrid.findIndex(i => i.id === dataItem.id);
    const newDataItem = activeGrid[foundIndex];
    if (dataItem.weekStartDate) {
      dataItem.weekStartDate = moment(dataItem.weekStartDate).format(
        'MM/DD/YYYY'
      );
      newDataItem.weekStartDate = moment(newDataItem.weekStartDate).format(
        'MM/DD/YYYY'
      );
    }
    dataItem.isQuarterly = isToggleOn;
    if (defaultStatus === 'MaxAvailable') {
      //dataItem.shortHorizonModifiedAtPQView = isToggleOn;
      columnConstants.maxHolidayCols.forEach(columnName => {
        dataItem[columnName] = holidayCalculator(columnName, dataItem);
      });
    } else {
      columnConstants.revHolidayCols.forEach(columnName => {
        dataItem[columnName] = holidayCalculator(columnName, dataItem);
      });
    }

    try {
      // removing the unnecessary cloumns added oonly
      delete dataItem.nloApproved;
      delete dataItem.nloConfirmed;
      delete dataItem.isStatusBreak;
      delete dataItem.isThresholdExceed;
      delete dataItem.inThresholdExceedCols;
      delete dataItem.inThreshold;
      delete dataItem.isColumnSkipped;
      delete dataItem.inColumnSkipped;
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }
    if (JSON.stringify(newDataItem) !== JSON.stringify(dataItem)) {
      formattedNewData.push(dataItem);
    }
  });
  return formattedNewData;
};

// setParamsUpdate
export const setParamsUpdate = (params, defaultStatus) => {
  const result = {
    reasonCodes: [],
    reasonCodesAll: [],
    maxColumnEditable: true,
    prodQuarterColumns: []
  };
  let reasonCodeField = '';
  let maxColumnEditableCount = 0;
  if (defaultStatus === 'MaxAvailable') {
    reasonCodeField = 'maxAvailableReasonCode';
  } else {
    reasonCodeField = 'shortHorizonReasonCode';
  }
  result.prodQuarterColumns = [
    {
      headerName: 'Weeks Merged',
      field: 'totalWeeks',
      width: 70,
      editable: false,
      filter: false,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      cellClass: 'GreyCloud',
      headerClass: `headGreyCloud`,
      valueFormatter: params => {
        return params.value ? params.value : 1;
      }
    }
  ];
  if (params) {
    if (params.columnApi.getAllColumns()) {
      params.columnApi.getAllColumns().forEach(column => {
        if (column.colDef.field === reasonCodeField) {
          let RCActive = [];
          let RCAll = [];
          const onlySelectValues = column.colDef.cellEditorParams(); // only active
          const onlyFilterValues = column.colDef.filterParams; // all filter values
          RCActive = onlySelectValues.values;
          RCAll = onlyFilterValues.values;
          result.reasonCodes = RCActive.filter(function(el) {
            return el != null && el.length > 0;
          });
          result.reasonCodesAll = RCAll.filter(function(el) {
            return el != null && el.length > 0;
          });
        }
        if (
          columnConstants.maxCapacityCols.includes(column.colDef.field) &&
          column.colDef.editable
        ) {
          maxColumnEditableCount += 1;
        }
        if (
          columnConstants.prodQuarterColumnHeader.includes(column.colDef.field)
        ) {
          result.prodQuarterColumns.push({ ...column.userProvidedColDef });
        }
      });
      result.prodQuarterColumns.find(
        o => o.field === 'productionQuarter'
      ).pinned = 'left';
    }
  }
  if (maxColumnEditableCount === 0) {
    result.maxColumnEditable = false;
  }
  return result;
};

// setParamsUpdateByProps
export const setParamsUpdateByProps = (
  props,
  defaultStatus,
  userType,
  userFactoryType
) => {
  const result = {
    reasonCodes: [],
    reasonCodesAll: [],
    maxColumnEditable: true,
    prodQuarterColumns: []
  };
  let rsf = '';
  rsf =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableReasonCode'
      : 'shortHorizonReasonCode';
  let maxColumnEditableCount = 0;

  result.prodQuarterColumns = [
    {
      headerName: 'Weeks Merged',
      field: 'totalWeeks',
      width: 90,
      editable: false,
      filter: false,
      suppressMenu: true,
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      cellClass: 'GreyCloud',
      headerClass: `headGreyCloud`,
      valueFormatter: params => {
        return params.value ? params.value : 1;
      }
    }
  ];

  if (props) {
    if (props.columns) {
      props.columns.forEach(column => {
        if (
          columnConstants.maxCapacityCols.includes(column.field) &&
          column.editable
        ) {
          maxColumnEditableCount += 1;
        }

        if (columnConstants.prodQuarterColumnHeader.includes(column.field)) {
          let newCol = { ...column };
          let targetCaptyCol =
            userFactoryType === 'FW' ? 'targetHldCpty' : 'targetCpty';
          if (
            columnConstants.maxHolidayCols.includes(column.field) ||
            columnConstants.revHolidayCols.includes(column.field) ||
            targetCaptyCol === column.field
          ) {
            // delete newCol.valueFormatter;

            newCol.valueGetter = params => {
              let paramVl = '';
              if (params && params.data) {
                paramVl = params.data[column.field];
              }
              if (
                paramVl === 0 ||
                paramVl === '0' ||
                (paramVl && paramVl.toString().length > 0)
              ) {
                return paramVl;
              } else {
                try {
                  return holidayCalculator(column.field, params.data);
                } catch (e) {
                  return paramVl;
                }
              }
            };
            // if (targetCaptyCol === column.field && newCol) {
            //   newCol.valueFormatter = params => {
            //     if (!isString(params.value)) {
            //       return params.value.toFixed(1);
            //     } else {
            //       return params.value
            //         ? parseFloat(params.value, 10).toFixed(1)
            //         : '';
            //     }
            //   };
            // }
            //delete newCol.valueGetter;
          }
          if (columnConstants.prodQuarterFixedCols.includes(column.field)) {
            newCol.pinned = 'left';
            newCol.hide = false;
          }
          result.prodQuarterColumns.push(newCol);
        }
      });
      if (props.role === 'WHQ') {
        const targetFieldIndex =
          userFactoryType === 'FW'
            ? result.prodQuarterColumns.findIndex(o => o.field === 'targetCpty')
            : result.prodQuarterColumns.findIndex(
                o => o.field === 'targetHldCpty'
              );

        if (targetFieldIndex >= 0) {
          result.prodQuarterColumns[targetFieldIndex].editable = true;
          result.prodQuarterColumns[targetFieldIndex].cellClass = 'editableCol';
        }
      }
    }
    if (props.columnOptions) {
      props.columnOptions.forEach(column => {
        if (column.header === rsf) {
          result.reasonCodes = [];
          result.reasonCodesAll = [];
          column.options.forEach(item => {
            if (item.status === 'ACTIVE') {
              result.reasonCodes.push(item.value);
            }
            result.reasonCodesAll.push(item.value);
          });
        }
      });
    }
  }
  if (maxColumnEditableCount === 0) {
    result.maxColumnEditable = false;
  }
  return result;
};

export const onCellValueChangFormatValidate = (
  cellFormat,
  cellValue,
  editedColumn,
  reasonCodes,
  isProdView
) => {
  let formatAccepted = false;
  const maxLimit = isProdView ? 10 : 9;
  if (cellFormat === 'agNumberColumnFilter') {
    const numbersOnly = /^[0-9]+$/;
    if (
      isNaN(cellValue) ||
      Number(cellValue) % 1 !== 0 ||
      Number(cellValue) < 0 ||
      cellValue.toString().length > maxLimit ||
      !String(cellValue).match(numbersOnly)
    ) {
      formatAccepted = false;
    } else {
      formatAccepted = true;
    }
  }
  if (
    columnConstants.reasonCodeEditableCols.includes(editedColumn) &&
    (reasonCodes.includes(cellValue) || cellValue.length === 0)
  ) {
    formatAccepted = true;
  }

  return formatAccepted;
};

export const onCellValueChangeUpdateFields = (
  rowNodeData,
  changeAcceptance,
  editedColumn,
  eventNewValue,
  oldRowValue,
  defaultStatus,
  userType,
  userName,
  thresholds
) => {
  const { toChangeCondition, statusChange } = updateColumnFieldsPostChange(
    editedColumn,
    eventNewValue,
    userType,
    userName,
    defaultStatus
  );
  if (columnConstants.statusChangingCapCols.includes(editedColumn)) {
    const CPCol =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableEditedBy'
        : 'shortHorizonEditedBy';
    if (changeAcceptance) {
      rowNodeData[CPCol] =
        editedColumn === 'approvedRevisedCapacity' ? 'WHQ' : userType;

      if (
        CPCol === 'shortHorizonEditedBy' &&
        editedColumn === 'shortHorizonUnitsConfirmed' &&
        rowNodeData.nloApproved
      ) {
        rowNodeData[CPCol] = 'WHQ';
      }
    } else {
      rowNodeData[CPCol] = oldRowValue[CPCol];
    }
  }

  //for production quarter
  Object.keys(columnConstants.holidayColUpdate).forEach(col => {
    if (col === editedColumn) {
      const holCol = columnConstants.holidayColUpdate[editedColumn];
      rowNodeData[holCol] = holidayCalculator(holCol, rowNodeData);
    }
  });
  if (Object.keys(statusChange).length !== 0) {
    Object.keys(statusChange).forEach(e => {
      rowNodeData[e] = statusChange[e];
    });
  }
  if (Object.keys(toChangeCondition).length !== 0) {
    Object.keys(toChangeCondition).forEach(e => {
      const val = changeAcceptance ? toChangeCondition[e] : oldRowValue[e];

      if (e === 'shortHorizonStatus') {
        if (rowNodeData.nloApproved) {
          rowNodeData[e] = 'Approved';
        } else if (rowNodeData.nloConfirmed) {
          rowNodeData[e] = 'Confirmed';
        } else {
          rowNodeData[e] = val;
        }
      } else {
        rowNodeData[e] = val;
      }
    });
  }

  rowNodeData.isEdited = true;
  // validating if exceeding thresholds and pushing it into array
  const fromComp = rowNodeData[editedColumn] || 0;
  let toComp = 0;
  let ToCheckColSkip = '';
  let ToCheckFactorySkip = false;
  let isConfirmedOrApproved = false;

  switch (editedColumn) {
    case 'maxAvailableUnitsConfirmed':
      toComp = rowNodeData.maxAvailableCapacity || null;
      ToCheckColSkip = rowNodeData.maxAvailableCapacity || null;
      isConfirmedOrApproved = true;
      break;
    case 'shortHorizonUnitsConfirmed':
      toComp = rowNodeData.shortHorizonCapacity || null;
      ToCheckColSkip = rowNodeData.shortHorizonCapacity || null;
      isConfirmedOrApproved = true;
      break;
    case 'approvedMaxCapacity':
      toComp =
        rowNodeData.maxAvailableUnitsConfirmed ||
        rowNodeData.maxAvailableCapacity ||
        null;
      ToCheckColSkip = rowNodeData.maxAvailableUnitsConfirmed || null;
      ToCheckFactorySkip = rowNodeData.maxAvailableCapacity ? false : true;
      isConfirmedOrApproved = true;
      break;
    case 'approvedRevisedCapacity':
      toComp =
        rowNodeData.shortHorizonUnitsConfirmed ||
        rowNodeData.shortHorizonCapacity ||
        null;
      ToCheckColSkip = rowNodeData.shortHorizonUnitsConfirmed || null;
      ToCheckFactorySkip = rowNodeData.shortHorizonCapacity ? false : true;
      isConfirmedOrApproved = true;
      break;
    default:
      toComp = -1;
      ToCheckColSkip = -1;
  }
  if (isConfirmedOrApproved) {
    if (ToCheckColSkip === null || ToCheckColSkip === undefined) {
      rowNodeData.isColumnSkipped = true;
      const isExist = rowNodeData.inColumnSkipped.includes(editedColumn);
      if (!isExist) {
        rowNodeData.inColumnSkipped.push(editedColumn);
        rowNodeData.isStatusBreak = true;
      }
    } else {
      rowNodeData.inColumnSkipped = [];
      rowNodeData.isStatusBreak = false;
      rowNodeData.isColumnSkipped = false;
    }
    if (
      (editedColumn === 'maxAvailableUnitsConfirmed' ||
        editedColumn === 'shortHorizonUnitsConfirmed') &&
      rowNodeData.isColumnSkipped
    ) {
      //nothing
    } else if (
      (editedColumn === 'approvedMaxCapacity' ||
        editedColumn === 'approvedRevisedCapacity') &&
      ToCheckFactorySkip &&
      rowNodeData.isColumnSkipped
    ) {
      //nothing
    } else {
      // const diff = 100 * Math.abs((fromComp - toComp) / ((fromComp + toComp) / 2));
      let toCompNum =
        toComp === 0 ||
        toComp === '0' ||
        (toComp && toComp.toString().length > 0)
          ? Number(toComp)
          : '';
      let diff = ((Number(fromComp) - toCompNum) / toCompNum) * 100;
      if (diff === Infinity) {
        diff = 100;
      }
      // const diffValue = Math.round((diff + Number.EPSILON) * 100) / 100;
      const diffValue = diff;
      rowNodeData.inThreshold = null;
      const thresholdMax =
        defaultStatus === 'MaxAvailable'
          ? thresholds.strategicThresholdIncrease
          : thresholds.revisedThresholdIncrease;
      const thresholdMin =
        defaultStatus === 'MaxAvailable'
          ? thresholds.strategicThresholdDecrease
          : thresholds.revisedThresholdDecrease;
      if (
        (diffValue < 0 && Math.abs(diffValue) > thresholdMin) ||
        (diffValue > 0 && Math.abs(diffValue) > thresholdMax)
      ) {
        const isExist = rowNodeData.inThresholdExceedCols.includes(
          editedColumn
        );
        if (!isExist) {
          rowNodeData.inThresholdExceedCols.push(editedColumn);
        }
        rowNodeData.isThresholdExceed = true;
        rowNodeData.inThreshold = diffValue;
      } else {
        rowNodeData.isThresholdExceed = false;
        rowNodeData.inThreshold = 0;
        rowNodeData.inThresholdExceedCols = [];
      }
    }
  }

  switch (editedColumn) {
    case 'targetCpty':
      rowNodeData.targetHldCpty = holidayCalculator(
        'targetHldCpty',
        rowNodeData
      );
      break;

    default:
      break;
  }

  return rowNodeData;
};

export const onConsoleArr = arr => {
  arr.forEach((ai, i) => {
    const editableColumns = JSON.parse(
      JSON.stringify(columnConstants.editableFields4AllUsers)
    );
    editableColumns.push('maxAvailableEditedBy');
    editableColumns.push('shortHorizonEditedBy');
    editableColumns.push('maxAvailableStatus');
    editableColumns.push('shortHorizonStatus');
  });
};

//quarterly grouped Data
export const quarterlyGroupedData = (data, defaultStatus) => {
  let formattedData = JSON.parse(JSON.stringify(data));
  let formattedNewData = [];
  let uniqueIdCount = 10001;
  const HolidayCols =
    defaultStatus === 'MaxAvailable'
      ? [...columnConstants.maxHolidayCols]
      : [...columnConstants.revHolidayCols];
  const gridStatusCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableStatus'
      : 'shortHorizonStatus';
  const noteCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotes'
      : 'shortHorizonNotes';
  const authorColumns =
    defaultStatus === 'MaxAvailable'
      ? columnConstants.maxAvailableAuthorCols
      : columnConstants.shortHorizonAuthorCols;
  const cols2Add =
    defaultStatus === 'MaxAvailable'
      ? columnConstants.prodQuarterStrategicCols
      : columnConstants.prodQuarterRevisedCols;

  formattedData.forEach(dataItem => {
    let isFound = 0;
    if (formattedNewData.length > 0) {
      formattedNewData.forEach((fnItem, i) => {
        if (
          String(fnItem.productionQuarter) ===
            String(dataItem.productionQuarter) &&
          String(fnItem.capacityTypeCode) ===
            String(dataItem.capacityTypeCode) &&
          String(fnItem.factoryCode) === String(dataItem.factoryCode)
        ) {
          isFound = i + 1;
          if (!('totalWeeks' in fnItem)) {
            fnItem.totalWeeks = 2;
          } else {
            fnItem.totalWeeks += 1;
          }
          if (!(gridStatusCol in dataItem)) {
            fnItem[gridStatusCol] = '';
          }
          cols2Add.forEach(field => {
            //Object.keys(dataItem).forEach(field => {
            if (
              (columnConstants.numberEditableCols.includes(field) ||
                [
                  'workingDays',
                  'numberOfHolidays',
                  'weeklyCapacityQuantity',
                  'adjustedCapacityQuantity'
                ].includes(field)) &&
              !isNaN(parseInt(dataItem[field]))
            ) {
              //fnItem[field] += Number(dataItem[field]);
              if (fnItem[field]) {
                fnItem[field] = Number(fnItem[field]) + Number(dataItem[field]);
              } else {
                fnItem[field] = Number(dataItem[field]);
              }
            }

            if (HolidayCols.includes(field)) {
              const hC = holidayCalculator(field, dataItem);

              if (hC === 0 || hC === '0' || (hC && hC.toString().length > 0)) {
                if (!isNaN(fnItem[field])) {
                  const curVal = Number(fnItem[field]) + Number(hC);
                  fnItem[field] = curVal % 1 === 0 ? curVal : curVal.toFixed(2);
                } else {
                  fnItem[field] =
                    Number(hC) % 1 === 0 ? hC : Number(hC).toFixed(2);
                }
              }
            }

            //for status
            if (columnConstants.prodQStatusCols.includes(field)) {
              if (fnItem[field] !== dataItem[field]) {
                fnItem[field] = '';
              }
            }

            if (columnConstants.reasonCodeEditableCols.includes(field)) {
              let newD = dataItem[field] ? dataItem[field] : '';
              if (fnItem[field] !== newD) {
                fnItem[field] = 'MULTIPLE';
              }
            }

            //for logs
            if (field === 'logs' && dataItem.logs && dataItem.logs.length > 0) {
              const logArr = [];
              dataItem.logs.forEach((log, i) => {
                const newLog = { ...log };
                newLog.weekStartDate = dataItem.weekStartDate;

                logArr.push(newLog);
              });
              if (fnItem.logs && fnItem.logs.length > 0) {
                fnItem.logs = [...fnItem.logs, ...logArr];
              } else {
                fnItem.logs = logArr;
              }
            }
            if (
              field === 'logCount' &&
              dataItem.logCount &&
              dataItem.logCount !== 0
            ) {
              fnItem.logCount =
                Number(fnItem.logCount) + Number(dataItem.logCount);
            }

            if (
              field === 'maxAvailableNotesCount' &&
              dataItem.maxAvailableNotesCount &&
              dataItem.maxAvailableNotesCount !== 0
            ) {
              fnItem.maxAvailableNotesCount =
                Number(fnItem.maxAvailableNotesCount) +
                Number(dataItem.maxAvailableNotesCount);
            }

            if (
              field === 'shortHorizonNotesCount' &&
              dataItem.shortHorizonNotesCount &&
              dataItem.shortHorizonNotesCount !== 0
            ) {
              fnItem.shortHorizonNotesCount =
                Number(fnItem.shortHorizonNotesCount) +
                Number(dataItem.shortHorizonNotesCount);
            }

            if (
              field === 'maxAvailableNotesQuarterCount' &&
              dataItem.maxAvailableNotesQuarterCount
            ) {
              fnItem.maxAvailableNotesQuarterCount = Number(
                dataItem.maxAvailableNotesQuarterCount
              );
            }

            if (
              field === 'shortHorizonNotesQuarterCount' &&
              dataItem.shortHorizonNotesQuarterCount
            ) {
              fnItem.shortHorizonNotesQuarterCount = Number(
                dataItem.shortHorizonNotesQuarterCount
              );
            }

            if (field === 'ids' && dataItem.id !== undefined) {
              fnItem.ids.push(dataItem.id);
            }
            //for notes
            if (
              field === noteCol &&
              dataItem[noteCol] &&
              dataItem[noteCol].length > 0
            ) {
              dataItem[noteCol].forEach((note, i) => {
                if (
                  note.isQuarterly &&
                  !fnItem[noteCol].some(r => r.timestamp === note.timestamp)
                ) {
                  fnItem[noteCol].push(note);
                }
              });
            }
            //for authorColumns
            if (
              authorColumns.includes(field) &&
              fnItem[field] !== dataItem[field]
            ) {
              fnItem[field] = '';
            }
          });
        }
      });
    }

    if (formattedNewData.length === 0 || isFound === 0) {
      const nItem = {
        productionQuarter: dataItem.productionQuarter,
        factoryCode: dataItem.factoryCode,
        factoryDescription: dataItem.factoryDescription,
        capacityTypeDescription: dataItem.capacityTypeDescription,
        capacityTypeClass: dataItem.capacityTypeClass,
        weeklyCapacityQuantity: dataItem.weeklyCapacityQuantity,
        adjustedCapacityQuantity: dataItem.adjustedCapacityQuantity,
        capacityTypeCode: dataItem.capacityTypeCode,
        weekStartDate: dataItem.weekStartDate,
        weekEndDate: dataItem.weekEndDate,
        logs: [],
        workingDays: dataItem.workingDays,
        numberOfHolidays: dataItem.numberOfHolidays,
        uniQId: uniqueIdCount,
        totalWeeks: 1,
        id: `${dataItem.productionQuarter}-${dataItem.factoryCode}-${dataItem.capacityTypeCode}`,
        ids: [],
        maxAvailableNotesCount: 0,
        shortHorizonNotesCount: 0,
        maxAvailableNotesQuarterCount: Number(
          dataItem.maxAvailableNotesQuarterCount
        ),
        shortHorizonNotesQuarterCount: Number(
          dataItem.shortHorizonNotesQuarterCount
        ),
        logCount: 0,
        isStatusBreak: false,
        isThresholdExceed: false,
        inThresholdExceedCols: [],
        inThreshold: null,
        isColumnSkipped: false,
        inColumnSkipped: [],
        WHQTargetCapacity: dataItem.WHQTargetCapacity
      };

      cols2Add.forEach(i => {
        nItem[i] = '';
      });
      nItem[noteCol] = [];
      nItem['ids'] = [];
      Object.keys(dataItem).forEach(field => {
        //for editable capacities  numberEditableCols  and revised holidays
        if (
          columnConstants.numberEditableCols.includes(field) ||
          [
            'workingDays',
            'numberOfHolidays',
            'weeklyCapacityQuantity',
            'adjustedCapacityQuantity'
          ].includes(field)
        ) {
          const val = dataItem[field];
          if (val === 0 || val === '0' || (val && val.toString().length > 0)) {
            nItem[field] = Number(val);
          }
        }
        if (HolidayCols.includes(field)) {
          const hC = holidayCalculator(field, dataItem);
          if (hC === 0 || hC === '0' || (hC && hC.toString().length > 0)) {
            nItem[field] = Number(hC) % 1 === 0 ? hC : Number(hC).toFixed(2);
          }
        }

        //for notes notesEditableCols
        if (columnConstants.notesEditableCols.includes(field)) {
          nItem[field] = [];
        }
        //for reasonCode  reasonCodeEditableCols
        if (
          columnConstants.reasonCodeEditableCols.includes(field) ||
          columnConstants.prodQStatusCols.includes(field)
        ) {
          nItem[field] = dataItem[field];
        }

        //for logs
        if (field === 'logs' && dataItem.logs && dataItem.logs.length > 0) {
          const logArr = [];
          dataItem.logs.forEach((log, i) => {
            const newLog = { ...log };
            newLog.weekStartDate = dataItem.weekStartDate;
            logArr.push(newLog);
          });
          nItem.logs = logArr;
        }
        if (
          field === 'logCount' &&
          dataItem.logCount &&
          dataItem.logCount !== 0
        ) {
          nItem.logCount = nItem.logCount + dataItem.logCount;
        }

        if (
          field === 'maxAvailableNotesCount' &&
          dataItem.maxAvailableNotesCount &&
          dataItem.maxAvailableNotesCount !== 0
        ) {
          nItem.maxAvailableNotesCount =
            nItem.maxAvailableNotesCount + dataItem.maxAvailableNotesCount;
        }

        if (
          field === 'shortHorizonNotesCount' &&
          dataItem.shortHorizonNotesCount &&
          dataItem.shortHorizonNotesCount !== 0
        ) {
          nItem.shortHorizonNotesCount =
            nItem.shortHorizonNotesCount + dataItem.shortHorizonNotesCount;
        }

        if (
          field === 'maxAvailableNotesQuarterCount' &&
          dataItem.maxAvailableNotesQuarterCount
        ) {
          nItem.maxAvailableNotesQuarterCount =
            dataItem.maxAvailableNotesQuarterCount;
        }

        if (
          field === 'shortHorizonNotesQuarterCount' &&
          dataItem.shortHorizonNotesQuarterCount
        ) {
          nItem.shortHorizonNotesQuarterCount = dataItem.shortHorizonNotesCount;
        }

        if (field === 'id' && dataItem.id !== undefined) {
          nItem.ids.push(dataItem.id);
        }

        //for notes
        if (
          field === noteCol &&
          dataItem[noteCol] &&
          dataItem[noteCol].length > 0
        ) {
          dataItem[noteCol].forEach((note, i) => {
            if (note.isQuarterly) {
              nItem[noteCol].push(note);
            }
          });
        }

        //for authorColumns
        if (authorColumns.includes(field)) {
          nItem[field] = dataItem[field];
        }

        //for holidays and working days
      });

      formattedNewData.push(nItem);
      uniqueIdCount += 1;
    }
  });
  return formattedNewData;
};

export const prodQuarterCol2Update = (obj1, obj2) => {
  const arr = [];
  Object.keys(obj1).forEach(f => {
    if (
      columnConstants.prodQuarterColumn2Validate.includes(f) &&
      obj1[f] !== obj2[f]
    ) {
      arr.push(f);
    }
  });

  if (
    arr.includes('maxCapacityReasonCode') &&
    !arr.includes('FctyMaxCapacity')
  ) {
    arr.push('FctyMaxCapacity');
  }

  if (
    arr.includes('maxAvailableReasonCode') &&
    !arr.includes('maxAvailableCapacity')
  ) {
    arr.push('maxAvailableCapacity');
  }

  if (
    arr.includes('shortHorizonReasonCode') &&
    !arr.includes('shortHorizonCapacity')
  ) {
    arr.push('shortHorizonCapacity');
  }
  return arr;
};

export const prodQuarterCapacityUpdate = (
  totalCapacity,
  weekCount,
  totalWeek
) => {
  const arr = arrayGenerator(0, totalCapacity, totalWeek);
  return arr[weekCount];
};

export const splitInteger = (num, parts) => {
  let val;
  let mod = num % parts;
  let retData = [];
  if (mod === 0) {
    val = num / parts;
    retData = Array(parts).fill(val);
  } else {
    val = (num - mod) / parts;
    retData = Array(parts).fill(val);
    for (let i = 0; i < mod; i++) {
      retData[i] = retData[i] + 1;
    }
    retData.reverse();
  }

  return retData;
};

export const arrayGenerator = (type, num, parts) => {
  let arr = [];
  switch (type) {
    case 1:
      //equally divided in integer and reminders included only in last
      arr = Array.from({ length: parts }, (_, i) =>
        i === parts - 1
          ? Math.floor(num / parts) + Number(num % parts)
          : Math.floor(num / parts)
      );
      break;
    case 2:
      //equally divided in integer and reminders splitted between last few obj
      arr = Array.from(
        { length: parts },
        (_, i) => 0 | (i < num % parts ? num / parts + 1 : num / parts)
      );

      break;
    case 3:
      //equally divided in integer and reminders splitted between first few obj

      arr = Array.from(
        { length: parts },
        (_, i) => 0 | (i < num % parts ? num / parts + 1 : num / parts)
      ).reverse();

      break;
    case 4:
      //equally divided in integer and reminders splitted between first few obj

      arr = Array.from({ length: parts }, (_, i) =>
        (num / parts) % 1 === 0 ? num / parts : (num / parts).toFixed(2)
      );

      break;
    default:
      //equally divides with decimal if available
      arr = Array.from({ length: parts }, (_, i) => Math.round(num / parts));
  }

  return arr;
};

export const generateProdQuarterWeeks = (
  updatedQuarters,
  activeQuarterGrid,
  paramsForExcel,
  isExcel,
  defaultStatus,
  userFactoryType
) => {
  const prodQuarterWeeks = [];
  const NoteCountCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotesCount'
      : 'shortHorizonNotesCount';
  const QuarterNoteCountCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotesQuarterCount'
      : 'shortHorizonNotesQuarterCount';
  if (updatedQuarters.length > 0) {
    updatedQuarters.forEach(prodQuarter => {
      const oldRow =
        activeQuarterGrid.find(old => old.uniQId === prodQuarter.uniQId) || {};
      let weekCount = 0;
      const col2Update = prodQuarterCol2Update(prodQuarter, oldRow);
      paramsForExcel.api.forEachNode(rowNode => {
        if (
          prodQuarter.productionQuarter === rowNode.data.productionQuarter &&
          prodQuarter.capacityTypeCode === rowNode.data.capacityTypeCode &&
          prodQuarter.factoryCode === rowNode.data.factoryCode
        ) {
          col2Update.forEach(field => {
            if (
              prodQuarter[field] === 0 ||
              prodQuarter[field] === '0' ||
              (prodQuarter[field] && prodQuarter[field].toString().length > 0)
            ) {
              const numEditCols = columnConstants.numberEditableCols;
              if (numEditCols.includes(field)) {
                rowNode.data[field] = prodQuarterCapacityUpdate(
                  prodQuarter[field],
                  weekCount,
                  prodQuarter.totalWeeks
                );
              } else if (columnConstants.notesEditableCols.includes(field)) {
                // handle note uploads

                const oldNotes = oldRow[field] || [];
                const newNotes = prodQuarter[field] || [];

                if (
                  newNotes.length > 0 &&
                  newNotes.length !== oldNotes.length
                ) {
                  const newNote =
                    newNotes.length > 0
                      ? newNotes[newNotes.length - 1]
                      : undefined;
                  if (newNote) {
                    if (
                      rowNode.data[QuarterNoteCountCol] !==
                      prodQuarter[QuarterNoteCountCol]
                    ) {
                      let quarterCountDifference =
                        prodQuarter[QuarterNoteCountCol] -
                        rowNode.data[QuarterNoteCountCol];
                      rowNode.data[QuarterNoteCountCol] =
                        prodQuarter[QuarterNoteCountCol];

                      if (isExcel) {
                        rowNode.data[NoteCountCol] = rowNode.data[NoteCountCol]
                          ? rowNode.data[NoteCountCol] + quarterCountDifference
                          : quarterCountDifference;
                      }
                    }
                    if (rowNode.data[field] && rowNode.data[field].length > 0) {
                      rowNode.data[field].push(newNote);
                    } else {
                      rowNode.data[field] = [newNote];
                    }
                  }
                }
              } else {
                rowNode.data[field] = prodQuarter[field];
              }
            }
          });
          rowNode.data.targetHldCpty = holidayCalculator(
            'targetHldCpty',
            rowNode.data
          );
          weekCount += 1;
          prodQuarterWeeks.push({ ...rowNode.data });
        }
      });
    });
  }
  return prodQuarterWeeks;
};

export const generateWeeklyReport = (
  modifiedWeeklyRows,
  activeWeeklyRows,
  backUpWeeklyRows,
  userType,
  allWeeklyFields,
  reasonCodes,
  reasonCodesAll,
  defaultStatus,
  currentuseremail,
  IsExcel,
  maxColumnEditable,
  isProdView,
  userFactoryType
) => {
  const report = [];
  modifiedWeeklyRows.forEach(weekRow => {
    const foundIndex = activeWeeklyRows.findIndex(i => i.id === weekRow.id);
    const foundIndBack = backUpWeeklyRows.findIndex(i => i.id === weekRow.id);
    const { result } = validateDataByRowOnlyOnUpload(
      weekRow,
      activeWeeklyRows[foundIndex],
      backUpWeeklyRows[foundIndBack],
      userType,
      reasonCodes,
      reasonCodesAll,
      defaultStatus,
      currentuseremail,
      IsExcel,
      maxColumnEditable,
      isProdView,
      userFactoryType
    );
    result.forEach(log => {
      const logVal = log.value;
      if (
        columnConstants.notesEditableCols.includes(log.field) &&
        Array.isArray(logVal)
      ) {
        const newv = logVal.length > 0 ? logVal[logVal.length - 1] : undefined;
        if (newv && newv.comment) {
          log.value = newv.comment || undefined;
        }
      }

      log.row = foundIndex + 1;
      log.fieldname = allWeeklyFields.find(i => i.field === log.field).name;
      log.factoryCode = weekRow.factoryCode;
      log.capacityTypeCode = weekRow.capacityTypeCode;
      log.weekStartDate = weekRow.weekStartDate
        ? moment(weekRow.weekStartDate).format('MM/DD/YYYY')
        : '';
      log.id = weekRow.id;
      report.push(log);
    });
  });
  return _.sortBy(report, 'row');
};

export const userUpdateSearchValidator = (searchGroups, searchParams) => {
  const searchStatus = { status: true, message: '' };
  let GroupAndCode = 0;
  searchGroups.forEach(gridItem => {
    if (gridItem.selected.length === 0) {
      GroupAndCode += 1;
    }
    if (GroupAndCode > 1) {
      searchStatus.status = false;
      searchStatus.message += msgConstants.invalidSearchInput;
    }
  });

  searchParams.forEach(gridItem => {
    if (gridItem.required && gridItem.value.length === 0) {
      searchStatus.status = false;
      searchStatus.message += ` ${gridItem.name} is mandatory. `;
    }
  });

  return searchStatus;
};

export const getDateRangeByQuarter = productionQuarter => {
  const pq = String(productionQuarter);
  const year = pq.substring(0, 4);
  let pQStartMonth = Number(pq.substring(4, 5)) * 3 - 3;
  let pQEndMonth = Number(pq.substring(4, 5)) * 3 - 1;
  let sdate = moment()
    .set('year', year)
    .set('month', pQStartMonth)
    .set('date', 1)
    .isoWeekday(8);
  if (sdate.date() > 7) {
    sdate = sdate.isoWeekday(-6);
  }
  let edate = moment()
    .set('year', year)
    .set('month', pQEndMonth)
    .set('date', 1)
    .endOf('month')
    .startOf('isoweek')
    .format('MM/DD/YYYY');
  return [sdate.format('MM/DD/YYYY'), edate];
};

export const getReviewOrConfirmedSkipped = productionQuarter => {
  //
  return true;
};

// setParamsUpdateByProps
export const getGbdAllLevers = (props, defaultStatus, userType) => {
  const gbdAllLevers = [];

  if (props) {
    if (props.columns) {
      props.columns.forEach(column => {
        if (
          column.headerName.startsWith('GBD - ') ||
          column.headerName.startsWith('Strat QOQ -') ||
          column.headerName.startsWith('Rvsd QOQ -') ||
          column.headerName.startsWith('Max QOQ -')
        ) {
          if (column.children && column.children.length > 0) {
            //
            column.children.forEach(dimension => {
              if (dimension.children && dimension.children.length > 0) {
                dimension.children.forEach(lever => {
                  gbdAllLevers.push(lever.field);
                });
              }
            });
            //
          } else if (
            column.headerName.includes('Validity Date') ||
            column.headerName.includes('Comments')
          ) {
            gbdAllLevers.push(column.field);
          }
        }
      });
    }
  }
  return gbdAllLevers;
};

export const gbdVarianceGen = (field, data) => {
  const {
    workingDays,
    numberOfHolidays,
    RevQMaxCapVariance,
    GbdMaxCapVariance,
    StrQMaxCapVariance,
    MaxQMaxCapVariance,
    CapQMaxCapVariance,
    targetCpty
  } = data;
  let retVal = '';
  const holidays = numberOfHolidays || 0;
  const availableWorkingDays =
    workingDays - holidays > 0 ? workingDays - holidays : 0;
  let field2calculate = '';

  if (workingDays !== 0) {
    switch (field) {
      case 'GbdMaxCapAdjVariance':
        field2calculate = GbdMaxCapVariance;
        break;
      case 'StrQMaxCapAdjVariance':
        field2calculate = StrQMaxCapVariance;
        break;
      case 'MaxQMaxCapAdjVariance':
        field2calculate = MaxQMaxCapVariance;
        break;
      case 'RevQMaxCapAdjVariance':
        field2calculate = RevQMaxCapVariance;
        break;
      case 'targetHldCpty':
        field2calculate = targetCpty;
        break;
      case 'CapQMaxCapAdjVariance':
        field2calculate = CapQMaxCapVariance;
        break;
      default:
        field2calculate = '';
    }
    if (
      field2calculate === 0 ||
      field2calculate === '0' ||
      (field2calculate && Number(field2calculate) > 0)
    ) {
      retVal = (Number(field2calculate) / workingDays) * availableWorkingDays;
      if (retVal % 1 !== 0) {
        retVal = (Math.round(retVal * 10) / 10).toFixed(1);
      }
    }
  }

  return retVal;
};
//quarterly grouped Data
export const quarterlyGroupedGBDData = (data, defaultStatus, gbdAllLevers) => {
  let formattedData = JSON.parse(JSON.stringify(data));
  let quarterRec = [];
  let uniqueIdCount = 10001;

  const PmdColList = [
    'weeklyCapacityQuantity',
    'adjustedCapacityQuantity',
    'workingDays',
    'numberOfHolidays',
    'FctyStratHldCpty',
    'FctyMaxHldCpty',
    'FctyRvsdHldCpty',
    'targetCpty',
    'FctyMaxCapacity',
    'maxAvailableCapacity',
    'FctyStartHldCpty'
  ];

  formattedData.forEach(dataItem => {
    let isFound = 0;
    if (quarterRec.length > 0) {
      quarterRec.forEach((fnItem, i) => {
        if (
          String(fnItem.productionQuarter) ===
            String(dataItem.productionQuarter) &&
          String(fnItem.capacityTypeCode) ===
            String(dataItem.capacityTypeCode) &&
          String(fnItem.factoryCode) === String(dataItem.factoryCode)
        ) {
          isFound = i + 1;
          if (!('totalWeeks' in fnItem)) {
            fnItem.totalWeeks = 2;
          } else {
            fnItem.totalWeeks += 1;
          }
          PmdColList.forEach(i => {
            fnItem[i] = dataItem[i]
              ? Number(dataItem[i]) + Number(fnItem[i])
              : fnItem[i];
          });
          gbdAllLevers.forEach(i => {
            if (dataItem[i]) {
              fnItem[i] = !isNaN(fnItem[i])
                ? Number(dataItem[i]) + Number(fnItem[i])
                : Number(dataItem[i]);
            }
          });

          fnItem.ids.push(dataItem.id);
        }
      });
    }

    if (quarterRec.length === 0 || isFound === 0) {
      const nItem = {
        productionQuarter: dataItem.productionQuarter,
        factoryCode: dataItem.factoryCode,
        capacityTypeCode: dataItem.capacityTypeCode,
        factoryDescription: dataItem.factoryDescription,
        capacityTypeDescription: dataItem.capacityTypeDescription,
        capacityTypeClass: dataItem.capacityTypeClass,
        weekStartDate: dataItem.weekStartDate,
        weekEndDate: dataItem.weekEndDate,

        uniQId: uniqueIdCount,
        totalWeeks: 1,
        id: `${dataItem.productionQuarter}-${dataItem.factoryCode}-${dataItem.capacityTypeCode}`,
        ids: []
      };
      PmdColList.forEach(i => {
        nItem[i] = dataItem[i] ? Number(dataItem[i]) : '';
      });
      gbdAllLevers.forEach(i => {
        if (dataItem[i]) {
          nItem[i] = dataItem[i];
        }
      });
      nItem['ids'] = [];
      nItem.ids.push(dataItem.id);
      Object.keys(dataItem).forEach(field => {
        if (
          columnConstants.numberEditableCols.includes(field) ||
          [
            'workingDays',
            'numberOfHolidays',
            'weeklyCapacityQuantity',
            'adjustedCapacityQuantity'
          ].includes(field)
        ) {
          const val = dataItem[field];
          if (val === 0 || val === '0' || (val && val.toString().length > 0)) {
            nItem[field] = Number(val);
          }
        }
      });

      quarterRec.push(nItem);
      uniqueIdCount += 1;
    }
  });

  quarterRec.forEach((data, index) => {
    // create variance Part
    const {
      FctyMaxCapacity,
      maxAvailableCapacity,
      shortHorizonCapacity
    } = data;
    data.GbdMaxCapVariance =
      FctyMaxCapacity && maxAvailableCapacity
        ? Number(FctyMaxCapacity) - Number(maxAvailableCapacity)
        : '';
    const prevRec = quarterRec[index - 1] || {};
    if (Object.keys(prevRec).length > 0) {
      if (prevRec.maxAvailableCapacity && maxAvailableCapacity) {
        data.StrQMaxCapVariance =
          maxAvailableCapacity - prevRec.maxAvailableCapacity;
      }
      if (prevRec.shortHorizonCapacity && shortHorizonCapacity) {
        data.RevQMaxCapAdjVariance =
          shortHorizonCapacity - prevRec.shortHorizonCapacity;
      }
      if (prevRec.FctyMaxCapacity && maxAvailableCapacity) {
        data.MaxQMaxCapVariance = FctyMaxCapacity - prevRec.FctyMaxCapacity;
      }
    }
    // ADJ
    if (data.GbdMaxCapVariance) {
      data.GbdMaxCapAdjVariance = gbdVarianceGen('GbdMaxCapAdjVariance', data);
    }
    if (data.StrQMaxCapVariance) {
      data.StrQMaxCapAdjVariance = gbdVarianceGen(
        'StrQMaxCapAdjVariance',
        data
      );
    }
    if (data.RevQMaxCapVariance) {
      data.RevQMaxCapAdjVariance = gbdVarianceGen(
        'RevQMaxCapAdjVariance',
        data
      );
    }
    if (data.MaxQMaxCapVariance) {
      data.MaxQMaxCapAdjVariance = gbdVarianceGen(
        'MaxQMaxCapAdjVariance',
        data
      );
    }
    if (data.CapQMaxCapVariance) {
      data.CapQMaxCapAdjVariance = gbdVarianceGen(
        'CapQMaxCapAdjVariance',
        data
      );
    }
    if (data.targetCpty) {
      data.targetHldCpty = gbdVarianceGen('targetHldCpty', data);
    }
  });
  return quarterRec;
};

export const generateGbDWeekRecordsFromQuarter = (
  updatedQuarters,
  gbdWeeklyData,
  gbdAllLevers
) => {
  const gbdWeeklyList = [];
  if (updatedQuarters.length > 0) {
    updatedQuarters.forEach(prodQuarter => {
      let weekCount = 0;
      gbdWeeklyData.forEach(weekItem => {
        const newItem = { ...weekItem };
        if (
          prodQuarter.productionQuarter === weekItem.productionQuarter &&
          prodQuarter.capacityTypeCode === weekItem.capacityTypeCode &&
          prodQuarter.factoryCode === weekItem.factoryCode
        ) {
          weekCount += 1;
          gbdAllLevers.forEach(i => {
            if (prodQuarter[i]) {
              newItem[i] = isNaN(prodQuarter[i])
                ? prodQuarter[i]
                : Number(prodQuarter[i]);
            }
          });

          if (prodQuarter.targetCpty) {
            newItem.targetCpty = isNaN(prodQuarter.targetCpty)
              ? prodQuarter.targetCpty
              : Number(prodQuarter.targetCpty);
            newItem.targetHldCpty = gbdVarianceGen('targetHldCpty', newItem);
          }

          if (weekCount === prodQuarter.totalWeeks) {
            newItem.weekStartDate = newItem.weekStartDate
              ? moment(newItem.weekStartDate).format('MM/DD/YYYY')
              : '';
            gbdWeeklyList.push(newItem);
          }
        }
      });
    });
  }
  return gbdWeeklyList;
};
