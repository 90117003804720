import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

export default class LeversCustomCell extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod(entryCol) {
    const { context, node } = this.props;
    context &&
      context.componentParent.displayLogEntryDetails(node.rowIndex, entryCol);
  }

  render() {
    const { colDef, data } = this.props;
    const { productionQuarter, partialQuarter, partialQuarterPrev } = data;
    let prodQuart;
    let partQuarText = partialQuarter ? 'Current' : '';
    partQuarText += partialQuarterPrev
      ? partQuarText
        ? '& Previous'
        : 'Previous'
      : '';
    if (productionQuarter) {
      const pqStr = String(productionQuarter);
      prodQuart = `${pqStr.substring(0, 4)}-Q${pqStr.substring(4, 5)}`;
    }
    return (
      <span className="d-block">
        {colDef.field === 'productionQuarter' && (
          <span className="d-block productionQuarter">
            {prodQuart}

            {(partialQuarter || partialQuarterPrev) && (
              <span className="d-sm-ib fl-sm-r">
                <Tooltip
                  placement="right"
                  overlay={<span className="c-f"> {partQuarText}</span>}
                  arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                  <span
                    className={`lever-icon lever-icon-qc text-color-grey1 opacity-50`}
                  ></span>
                </Tooltip>
              </span>
            )}
          </span>
        )}
      </span>
    );
  }
}
LeversCustomCell.propTypes = {
  context: PropTypes.any,
  node: PropTypes.any,
  colDef: PropTypes.any,
  value: PropTypes.any,
  data: PropTypes.any
};
