import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SimpleCellRender extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod(entryCol) {
    const { context, node } = this.props;
    context &&
      context.componentParent.cellRenderAction(node.rowIndex, entryCol);
  }

  render() {
    const { colDef, value } = this.props;
    return (
      <span>
        {colDef.field === 'reasonCodes' && (
          <button
            onClick={() => this.invokeParentMethod(colDef.field)}
            className="gridBtn1"
          >
            VIEW LIST
          </button>
        )}
        {colDef.field === 'newRow' && value && (
          <button
            onClick={() => this.invokeParentMethod(colDef.field)}
            className="gridDelBtn"
          >
            DELETE
          </button>
        )}
        {colDef.field === 'status' && (
          <span className="gridDropDown">
            {value}
            <i className="fas fa-chevron-down"></i>
          </span>
        )}
        {(colDef.field === 'newrc' || colDef.field === 'modifiedrc') && (
          <span className="newrcmodifiedrc">
            {value.length > 0 &&
              value.map(v => (
                <span className={`reasonCode${v.status} `}>{v.value}; </span>
              ))}
          </span>
        )}
      </span>
    );
  }
}
SimpleCellRender.propTypes = {
  context: PropTypes.any,
  node: PropTypes.any,
  colDef: PropTypes.any,
  value: PropTypes.any,
  data: PropTypes.any
};
