import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch DASHBOARD_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'dashboardData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));
    // Trigger Call to API endpoint
    // comment this for real data // ${action.data.user}/role/${action.data.role}
    let options = {};
    let getDashDataUrl = `${apiConfig.getCapacityChangeNotification.endPointUrl}`;

    if (action.data.searchfields.length > 0) {
      getDashDataUrl = `${getDashDataUrl}?${action.data.searchfields}`;
    }
    const res = yield call(getRequest, getDashDataUrl, options, true);
    // const holidayRes = yield call(getRequest, getHolidayApiUrl, options, true);

    if (Number(res.status) === 400 || Number(res.status) === 504) {
      const responsemessage = yield res.text() || res.json();
      const errorResponse = {
        status: Number(res.status),
        message: responsemessage
      };

      yield put(createServiceAction(serviceType, 'error')(errorResponse));
    } else {
      const response = yield res.json();
      // const holidayResponse = yield holidayRes.json();
      yield put(
        createServiceAction(serviceType, 'success')({
          dashboardData: response,
          holidayData: {}
        })
      );
    }
    // Dispatch DASHBOARD_DATA_LOADING action with response object
    yield put(createServiceAction(serviceType, 'loading')(false));
    // yield put(createServiceAction(serviceType, 'success')(response));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    // Dispatch DASHBOARD_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* userUpdatesDataSagas() {
  const serviceType = 'userUpdatesData';
  // Listen to DASHBOARD_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default userUpdatesDataSagas;
