/* eslint-disable max-statements */

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch GBD_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'growthLeverListData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));

    let options = {};
    //get the url endpoint
    let getDashDataUrl = `${apiConfig.growthLeverListData.endPointUrl}${
      //check if strategic or revised
      action.data.dashboard === 'MaxAvailable' ? 'strategic' : 'revised'
    }`;

    const res = yield call(getRequest, getDashDataUrl, options, true);

    if (res.status === 200) {
      // Parse response object
      const response = yield res.json();
      // Dispatch LEVER_ADMIN_DATA_LOADING action with response object
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'success')(response));
    } else {
      const errorMsg = { status: res.status, msg: res.statusText };
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'error')(errorMsg));
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* growthLeverListSagas() {
  const serviceType = 'growthLeverListData';
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default growthLeverListSagas;
