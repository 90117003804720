import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { SingleInput } from '../index.js';

const buttonClass =
  'd-sm-ib ncss-brand u-uppercase pt1-sm pr3-sm pb1-sm pl3-sm';

export default class GBDPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToPageno: 1
    };
    this.go2PageByNumber = this.go2PageByNumber.bind(this);
    this.go2Page = this.go2Page.bind(this);
  }

  componentDidMount() {
    const { currentPage } = this.props;

    this.setState({
      goToPageno: Number(currentPage)
    });
  }

  onPageNumberChange = event => {
    if (event.target) {
      this.setState({ goToPageno: event.target.value });
    }
  };

  go2PageByNumber = num => {
    const { totalPage, goToPage } = this.props;
    const { goToPageno } = this.state;
    if (num !== goToPageno) {
      if (goToPageno >= 1 && goToPageno <= totalPage) {
        goToPage(goToPageno);
      } else {
        toast.error(`Please Enter number between 1  and  ${totalPage}`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    }
  };

  go2Page = action => {
    const { goToPage } = this.props;
    goToPage(action);
  };

  render() {
    const { goToPageno } = this.state;
    const { totalPage, currentPage } = this.props;
    const lastPage = Number(currentPage) !== totalPage ? totalPage : null;
    const nextPage =
      Number(currentPage) <= totalPage - 1 ? Number(currentPage) + 1 : null;
    const firstPage = Number(currentPage) !== 1 ? 1 : null;
    const previousPage =
      Number(currentPage) > firstPage ? Number(currentPage) - 1 : null;
    // On page load, loading max available dashboard as default

    return (
      <div className="bg-white pt2-sm">
        <div className="ncss-container">
          <div className="ncss-row">
            <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-3 fl-sm-l">
              {totalPage && (
                <div>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      this.go2PageByNumber(currentPage);
                    }}
                  >
                    <div className="ta-sm-c pt2-sm fl-sm-l">Goto </div>
                    <div
                      className="ta-sm-c    fl-sm-l"
                      style={{ width: '60px' }}
                    >
                      <SingleInput
                        inputType="number"
                        name="gotoPagebyNumber"
                        title=""
                        controlFunc={e => this.onPageNumberChange(e)}
                        className="description u-bold trf-data-text border-black fl-sm-l p2-sm  ml1-sm m1-sm u-full-width"
                        content={Number(goToPageno)}
                        disabled={totalPage <= 1}
                      />
                    </div>
                    <div className="ta-sm-c   pt1-sm pl2-sm fl-sm-l">
                      <button
                        type="submit"
                        className={`${buttonClass}  ncss-btn-black`}
                      >
                        <i className="fas fa-angle-right" />
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>

            <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-3 fl-sm-r">
              <button
                type="button"
                className={` mr1-sm ${buttonClass}  fl-sm-r ${
                  lastPage ? ' ncss-btn-black' : ''
                }`}
                disabled={!lastPage}
                onClick={() => this.go2Page(lastPage)}
              >
                <i className="fas fa-angle-double-right" />
              </button>
              <button
                type="button"
                className={` mr1-sm ${buttonClass}  fl-sm-r ${
                  nextPage ? ' ncss-btn-black' : ''
                }`}
                disabled={!nextPage}
                onClick={() => this.go2Page(nextPage)}
              >
                <i className="fas fa-angle-right" />
              </button>
              <input
                type="text"
                className=" mr1-sm ta-sm-c  ncss-brand u-uppercase pt2-sm pr1-sm pb2-sm pl1-sm fl-sm-r"
                style={{ width: '30%' }}
                disabled
                value={` ${currentPage} / ${totalPage}`}
              />
              <button
                type="button"
                className={` mr1-sm fl-sm-r ${buttonClass}   ${
                  previousPage ? ' ncss-btn-black' : ''
                }`}
                onClick={() => this.go2Page(previousPage)}
                disabled={!previousPage}
              >
                <i className="fas fa-angle-left" />
              </button>
              <button
                type="button"
                className={` mr1-sm fl-sm-r ${buttonClass}   ${
                  firstPage ? ' ncss-btn-black' : ''
                }`}
                onClick={() => this.go2Page(firstPage)}
                disabled={!firstPage}
              >
                <i className="fas fa-angle-double-left" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
GBDPagination.propTypes = {
  pagination: PropTypes.any,
  goToPage: PropTypes.func,
  dataLength: PropTypes.any,
  view: PropTypes.any
};
