import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Select extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      isFocused: false
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = event => {
    const dropdownElement = this.node;
    const { isFocused } = this.state;
    if (
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      isFocused
    ) {
      this.setState({ isFocused: false });
    }
  };

  openMenu = e => {
    e.preventDefault();
    const { isFocused } = this.state;
    if (!isFocused) {
      this.setState({ isFocused: true });
    }
  };

  handleChange = newValue => {
    const { onChange } = this.props;
    onChange(newValue);
    this.setState({ isFocused: false });
  };

  render() {
    let { value, placeholder, clearable } = this.props;
    const { onChange, label, options } = this.props;
    const { isFocused } = this.state;
    const optionValues = options.slice(0);
    placeholder = placeholder || 'Select an option';

    if (clearable === undefined) {
      clearable = false;
    }

    if (clearable || value === null || value === undefined) {
      optionValues.unshift({ label: placeholder, value: '' });
      value = value || '';
    }

    const selectedOptionLabel = (
      optionValues.find(o => o.value === value) || {}
    ).label;

    return (
      <div
        className={`typeahead-dropdown-container ${isFocused ? 'focus' : ''}`}
        ref={node => (this.node = node)}
      >
        {label ? (
          <label className="ncss-label pr3-sm" htmlFor="select" id="select">
            {label}
          </label>
        ) : null}

        <div className="d-sm-b d-md-h">
          <select value={value} onChange={e => onChange(e.target.value)}>
            {optionValues.map(v => (
              <option key={v.value} value={v.value}>
                {v.label}
              </option>
            ))}
          </select>
        </div>

        <div className="typeahead-input z2 d-sm-h d-md-b">
          <div
            className="ncss-flyout-btn bg-white u-full-width"
            role="none"
            onClick={this.openMenu}
          >
            {selectedOptionLabel}
          </div>
          <div className="typeahead-options ncss-flyout-options">
            {optionValues.map(o => (
              <span
                className={`ncss-option pt1-sm pr5-sm pb1-sm pl5-sm ${
                  o.value === value ? 'active' : ''
                }`}
                onClick={() => this.handleChange(o.value)}
                role="none"
                tabIndex={o.value}
                key={o.value}
              >
                {o.label}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  options: PropTypes.arrayOf
};
