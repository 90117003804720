/* eslint-disable complexity */
/* eslint-disable max-statements */
import * as moment from 'moment';
import _ from 'lodash';
import { msgConstants } from '../constants/msgConstants.js';
import { columnConstants } from '../constants/columnConstants.js';
import { divisionCodes } from '../constants/appConstants';
import {
  holidayCalculator,
  numberFormatter
} from '../util/growthDimensionGridConfigure.js';

// for search form validation
export const searchValidator = (searchGroups, searchParams) => {
  const searchStatus = { status: true, message: '' };
  let GroupAndCode = 0;
  searchGroups.forEach(gridItem => {
    if (gridItem.selected.length === 0) {
      GroupAndCode += 1;
    }
    if (GroupAndCode > 1) {
      searchStatus.status = false;
      searchStatus.message += msgConstants.invalidSearchInput;
    }
  });

  searchParams.forEach(gridItem => {
    if (gridItem.required && gridItem.value.length === 0) {
      searchStatus.status = false;
      searchStatus.message += ` ${gridItem.name} is mandatory. `;
    }
  });
  const startDate =
    searchParams.find(o => o.field === 'startWeekDate').value || '';
  const endDate = searchParams.find(o => o.field === 'endWeekDate').value || '';
  // factory code mandatory !moment(startDate, 'MM/DD/YYYY',true).isValid()
  // start date should be lesser than end date
  if (startDate && endDate && moment(startDate).isAfter(endDate)) {
    searchStatus.status = false;
    searchStatus.message +=
      ' Start Quarter is greater that End Quarter. Please choose valid date range !';
  }

  return searchStatus;
};

// for ag grid custom footer download
export const fetchAllQueryValue = (gridItem, ifAll) => {
  let allSelVal = '';
  let ifAllSel = false;
  if (gridItem.selected && gridItem.selected.length > 0) {
    gridItem.selected.forEach(v => {
      allSelVal += `${allSelVal ? ',' : ''}${v.value}`;
      if (v.name === 'ALL' && gridItem.field === 'factoryCode') {
        ifAllSel = true;
      }
    });
  } else if (gridItem.selected && gridItem.selected.key) {
    allSelVal += `${allSelVal ? ',' : ''}${gridItem.selected.value}`;
  } else {
    ifAllSel = false;
  }
  if (
    allSelVal.length === 0 &&
    (ifAllSel || ifAll) &&
    gridItem &&
    gridItem.options &&
    gridItem.options.length
  ) {
    allSelVal = '';
    gridItem.options.forEach(v => {
      allSelVal += `${allSelVal ? ',' : ''}${v.value}`;
    });
  }
  return allSelVal;
};
// for search post validation frame query
export const searchQueryFrame = (
  num,
  searchGroups,
  searchParams,
  gridPagination,
  recCount,
  revisedWeeks,
  defaultStatus
) => {
  const count = recCount || 104;
  let allString = '';
  const factCode = searchGroups.find(o => o.field === 'factoryCode');
  const returFactoryCodeValue = fetchAllQueryValue(factCode, true) || '';

  if (num === 1) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCodes=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    } else {
      const factGrp = searchGroups.find(o => o.field === 'factoryGroup');
      const returFactoryGrpValue = fetchAllQueryValue(factGrp, true) || '';
      const updateVal = `factoryGroup=${returFactoryGrpValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
  } else if (num === 2) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCode=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
    let actualStartWeekDate = null;
    let qStart = null;
    let qEnd = null;
    if (searchParams && searchParams.length > 0) {
      searchParams.forEach(gridItem => {
        let returValue = '';
        switch (gridItem.field) {
          case 'capacityTypeCode':
            returValue = fetchAllQueryValue(gridItem, false);
            break;
          case 'startWeekDate':
            if (gridItem.value) {
              actualStartWeekDate = moment(gridItem.value, 'MM/DD/YYYY')
                .subtract(3, 'months')
                .utc()
                .startOf('month')
                .startOf('isoweek')
                .add(1, 'w');
              if (actualStartWeekDate.date() > 7) {
                //
                // actualStartWeekDate = actualStartWeekDate.isoWeekday(-6);
              }
            }
            if (actualStartWeekDate) {
              qStart = moment(actualStartWeekDate);
            } else {
              qStart = moment(gridItem.fromMonth);
            }
            const startDateSel = moment(gridItem.value).diff(
              moment(gridItem.fromMonth),
              'week'
            );
            if (startDateSel > 0) {
              returValue = gridItem.value
                ? `${gridItem.field}=${moment(actualStartWeekDate).format(
                    'YYYYQ'
                  )}&actualStartWeekDate=${moment(
                    new Date(gridItem.value)
                  ).format('YYYYQ')}`
                : '';
            }
            break;
          case 'endWeekDate':
            if (gridItem.value) {
              qEnd = moment(gridItem.value)
                .endOf('quarter')
                .startOf('isoweek');

              const endDay = qEnd.weekday();
              if (endDay < 1) {
                qEnd = qEnd.weekday(1);
              }
              if (qEnd > moment(gridItem.toMonth)) {
                qEnd = moment(gridItem.toMonth);
              }
            } else {
              qEnd = moment(gridItem.toMonth);
            }
            const endDateSel = moment(gridItem.value).diff(
              moment(gridItem.toMonth),
              'week'
            );
            if (endDateSel < 0) {
              returValue = gridItem.value
                ? `${gridItem.field}=${qEnd.format('YYYYQ')}`
                : '';
            }
            break;
          default:
            returValue = '';
        }
        if (returValue) {
          const updateVal = returValue.includes(`${gridItem.field}`)
            ? `${returValue}`
            : `${gridItem.field}=${returValue}`;
          allString += allString ? `&${updateVal}` : updateVal;
        }
      });
    }

    if (Object.keys(gridPagination).length > 0) {
      const queryString = Object.keys(gridPagination)
        .map(key => `${key}=${gridPagination[key]}`)
        .join('&');
      allString += `${allString ? '&' : '?'}${queryString}`;
    } else {
      let toChek = qEnd.diff(qStart, 'week') + 1;
      const range = toChek ? Math.ceil(count / toChek) * toChek : count;
      allString += `${allString ? '&' : '?'}count=${range}&anchor=1`;
    }
    if (revisedWeeks >= 0) {
      allString += `${allString ? '&' : '?'}boundaryWeek=${revisedWeeks}`;
    }
  }
  return allString;
};

export const getCTCList = gridItem => {
  let allSelVal = [];
  /**checking value  for ALL option  */
  const checkAlloption =
    gridItem.selected.length > 0 &&
    !gridItem.selected.find(o => o.value === '');
  if (gridItem.selected && checkAlloption) {
    allSelVal = gridItem.selected;
  } else {
    allSelVal = gridItem.options;
  }
  const newList = allSelVal
    .filter(({ value }) => value && value.toString().length > 0)
    .sort((a, b) => a.fc.localeCompare(b.fc));
  return newList.length > 0 ? newList : allSelVal;
};
export const searchQueryCTFrame = (
  searchParams,
  revisedHorizonWeeks,
  defaultStatus,
  maxQuarterLimit
) => {
  //  const count = maxQuarterLimit || 104;
  let actualStartWeekDate = null;
  let qStart = null;
  let qEnd = null;

  const returnRes = {};
  if (searchParams && searchParams.length > 0) {
    searchParams.forEach(gridItem => {
      switch (gridItem.field) {
        case 'capacityTypeCode':
          returnRes.capacityTypeCode = getCTCList(gridItem);
          break;
        case 'startWeekDate':
          const qFrom = moment(gridItem.fromMonth);
          if (gridItem.value) {
            qStart = moment(gridItem.value);
          } else {
            qStart = qFrom;
          }
          const startDateSel = qStart.diff(qFrom, 'months');
          if (startDateSel >= 0) {
            actualStartWeekDate = moment(gridItem.value)
              .subtract(3, 'months')
              .startOf('month');

            const prevDiff = actualStartWeekDate.diff(qFrom, 'months');
            if (prevDiff > 1) {
              returnRes.startWeekDate = actualStartWeekDate.format('YYYYQ');
            } else if (prevDiff === 1) {
              returnRes.startWeekDate = qFrom.format('YYYYQ');
            }
          }
          returnRes.actualStartWeekDate = moment(
            new Date(gridItem.value)
          ).format('YYYYQ');
          break;
        case 'endWeekDate':
          if (gridItem.value) {
            qEnd = moment(gridItem.value)
              .endOf('quarter')
              .startOf('isoweek');

            const endDay = qEnd.weekday();
            if (endDay < 1) {
              qEnd = qEnd.weekday(1);
            }
            if (qEnd > moment(gridItem.toMonth)) {
              qEnd = moment(gridItem.toMonth);
            }
          } else {
            qEnd = moment(gridItem.toMonth);
          }
          const endDateSel = moment(gridItem.value).diff(
            moment(gridItem.toMonth),
            'week'
          );
          if (endDateSel < 0) {
            returnRes.endWeekDate = qEnd.format('YYYYQ');
          }

          break;
        default:
          returnRes.returValue = '';
      }
    });
  }
  let capTypePageLen = Math.ceil(qEnd.diff(qStart, 'months') / 3);
  let pages = 1;
  let count = 1;
  if (returnRes.capacityTypeCode.length > 1) {
    const ccl = returnRes.capacityTypeCode.length;
    const nearBy = 60;
    const perPage = Math.ceil(nearBy / capTypePageLen) * capTypePageLen;
    const pagesIs = Math.ceil((ccl * capTypePageLen) / perPage);
    pages = pagesIs > 1 ? pagesIs : 1;
    count = Math.ceil(ccl / pages);
  }
  returnRes.count = count;
  returnRes.pages = pages;
  return returnRes;
};
// for ag grid custom footer download
export const agGridDownloadFileName = (
  defaultStatus,
  userType,
  dashboardType,
  userName,
  isToggleOn
) => {
  const date = moment().format('MM-DD-YYYY-HH-mm-ss');
  const viewIs = defaultStatus === 'MaxAvailable' ? 'STRATEGIC' : 'REVISED';
  const typeIs = dashboardType === 'view' ? 'UPLOAD' : 'VIEW';
  const gridView = !isToggleOn ? 'WEEKLY' : 'QUARTERLY';
  const fileInfo = {
    fileName: `CAPVIZ-CAPACITY-LEVERS-${viewIs}-${typeIs}-${gridView}-${userType}-${date}`,
    sheetName: `${viewIs} CAPACITY LEVERS ${typeIs}`,
    fileID: `CAPVIZ-CAPACITY-LEVERS-${viewIs}-${typeIs}-${gridView}-${userName}-${userType}-${date}`
  };
  return fileInfo;
};
// for ag grid custom footer download
export const agGridCustomFooter = getAllColumns => {
  let customfooter = [];
  let reasonCodeOptions = [];
  getAllColumns.forEach(column => {
    if (
      column.colDef.field === 'maxAvailableReasonCode' ||
      column.colDef.field === 'shortHorizonReasonCode'
    ) {
      const onlySelectValues = column.colDef.cellEditorParams();
      // reasonCodeOptions = column.colDef.filterParams.values;
      reasonCodeOptions = onlySelectValues.values;
      if (reasonCodeOptions) {
        customfooter.push([]);
        customfooter.push([]);
        customfooter.push([
          {
            styleId: 'header',
            // mergeAcross: 2,
            data: {
              type: 'String',
              value: 'Reason Codes'
            }
          }
        ]);
        reasonCodeOptions.forEach(value => {
          let footerContent = [
            {
              styleId: 'footerContent',
              //  mergeAcross: 2,
              data: {
                type: 'String',
                value: value || ''
              }
            }
          ];

          customfooter.push(footerContent);
        });
      }
    }
  });
  return customfooter;
};

// for ag grid excel data pre formatter
export const agGridDownloadDataFormat = paramsl => {
  if (paramsl.value) {
    if (paramsl.column.colDef.filter === 'agDateColumnFilter') {
      const replaceDate = new Date(paramsl.value);
      return moment(replaceDate).format('MM/DD/YYYY');
    }
    if (paramsl.column.colDef.field === 'productionQuarter') {
      const yearIs = String(paramsl.value) || '';
      if (paramsl.value) {
        return `${yearIs.substring(0, 4)}-Q${yearIs.substring(4, 5)}`;
      }
    }

    if (paramsl.column.colDef.headerName === 'Notes') {
      return '';
    }
  }
  if (paramsl.column.colDef.field === 'numberOfHolidays' && !paramsl.value) {
    return `0`;
  }
  return paramsl.value;
};

// eslint-disable-next-line max-statements
export const updateColumnFieldsPostChange = (
  columnModified,
  currentCellValue,
  userType,
  userName,
  dBoard
) => {
  const result = {};
  const statusChange = {};
  const newVal = currentCellValue;
  let col2Check = '';
  const status =
    dBoard === 'MaxAvailable' ? 'maxAvailableStatus' : 'shortHorizonStatus';
  let modified = '';
  if (userType === 'FACTORY' || userType === 'gsm') {
    col2Check =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableCapacity'
        : 'shortHorizonCapacity';
    if (columnModified === col2Check) {
      result[status] = newVal.length !== 0 ? 'In Review' : '';
    }
  } else if (userType === 'NLO') {
    col2Check =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableUnitsConfirmed'
        : 'shortHorizonUnitsConfirmed';
    modified =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableConfirmedBy'
        : 'shortHorizonConfirmedBy';
    if (columnModified === col2Check) {
      result[modified] = newVal.length !== 0 ? userName : '';
      result[status] = newVal.length !== 0 ? 'Confirmed' : 'In Review';
      statusChange.nloConfirmed = newVal.length !== 0 ? true : false;
    }

    col2Check =
      dBoard === 'MaxAvailable'
        ? 'approvedMaxCapacity'
        : 'approvedRevisedCapacity';
    modified =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableApprovedBy'
        : 'shortHorizonApprovedBy';
    if (columnModified === col2Check) {
      result[status] = newVal.length !== 0 ? 'Approved' : 'Confirmed';
      result[modified] = newVal.length !== 0 ? userName : '';
      statusChange.nloApproved = newVal.length !== 0 ? true : false;
    }
  } else if (userType === 'WHQ') {
    col2Check =
      dBoard === 'MaxAvailable'
        ? 'approvedMaxCapacity'
        : 'approvedRevisedCapacity';
    modified =
      dBoard === 'MaxAvailable'
        ? 'maxAvailableApprovedBy'
        : 'shortHorizonApprovedBy';
    if (columnModified === col2Check) {
      result[status] = newVal.length !== 0 ? 'Approved' : 'Confirmed';
      result[modified] = newVal.length !== 0 ? userName : '';
    }
  }

  return { toChangeCondition: result, statusChange };
};

// for ag grid custom footer download
export const removeAllFromOptions = arr => {
  arr.forEach(item => {
    if (item.options) {
      const foundIndex = item.options.findIndex(i => i.name === 'ALL');
      if (foundIndex >= 0) {
        item.options.splice(foundIndex, 1);
      }
    }
    if (item.backOptions) {
      const foundIndex = item.backOptions.findIndex(i => i.name === 'ALL');
      if (foundIndex >= 0) {
        item.options.splice(foundIndex, 1);
      }
    }
  });
  return arr;
};

// for ag grid custom footer download
export const updateErrorReport = (report, UploadStatusData) => {
  let uploadReport = [...UploadStatusData];
  if (uploadReport) {
    //report.outputData &&
    //let APIErrorReport = [...report.outputData.errors]|| [];
    let APIFailedReport = [];
    if (report && report.outputData && report.outputData.failed) {
      APIFailedReport = [...report.outputData.failed];
    }

    uploadReport.forEach(item => {
      /*
      if (APIErrorReport && APIErrorReport.length > 0) {
        let errorID = APIErrorReport.findIndex(i => i.id === item.id);
        if (errorID >= 0) {
          item.message = 'API VALIDATION ERROR';
          item.status = false;
          item.type = 'API ERROR';
        }
      } */
      if (APIFailedReport && APIFailedReport.length > 0) {
        let errorID = APIFailedReport.findIndex(i => i.id === item.id);

        if (errorID >= 0) {
          item.message = 'DATABASE UPDATING FAILED';
          item.status = false;
          item.type = 'API FAILURE';
        }
      }
    });
  }
  return uploadReport;
};

export const updateFailedReport = UploadStatusData => {
  let uploadReport = [...UploadStatusData];
  if (uploadReport) {
    uploadReport.forEach(item => {
      if (item.status) {
        item.message = 'DATABASE UPDATING FAILED';
        item.status = false;
        item.type = 'API FAILURE';
      }
    });
  }
  return uploadReport;
};

//used in both dashboardType for SearchCount
export const updatePageSearchCountFn = (
  usrFactGrpSearchData,
  searchParams,
  num
) => {
  if (usrFactGrpSearchData) {
    if (num === 1) {
      searchParams.find(
        o => o.field === 'factoryCode'
      ).options = usrFactGrpSearchData;
    } else if (num === 2) {
      const faCodSeltd =
        searchParams.find(o => o.field === 'factoryCode').value || '';
      const faCodSeltdOptions =
        searchParams.find(o => o.value === faCodSeltd).options || [];
      let faCodSeltdCCd = [];
      const faCodSeltdList = faCodSeltdOptions.find(
        o => o.value === faCodSeltd
      );
      if (faCodSeltdList) {
        faCodSeltdCCd = faCodSeltdList.capacitycodes;
      }

      searchParams.find(
        o => o.field === 'capacityTypeCode'
      ).options = faCodSeltdCCd;
      searchParams.find(o => o.field === 'capacityTypeCode').disabled = false;
      if (faCodSeltdCCd.length === 0) {
        searchParams.find(o => o.field === 'capacityTypeCode').value = '';
      }
    }
  }
  return searchParams;
};

// eslint-disable-next-line max-statements
export const updateNewCommentEntry = (
  value,
  currentValue,
  currentuseremail,
  userType,
  isToggleOn
) => {
  const currentArray = currentValue || [];
  const newComment = {
    comment: value,
    userRole: userType,
    userId: currentuseremail.toLowerCase(),
    timestamp: Date.parse(new Date())
  };
  if (isToggleOn) {
    newComment.isQuarterly = true;
  }

  currentArray.push(newComment);
  return currentArray;
};

export const validateDataByRowOnUpload = (
  currentField,
  backUpColVal,
  newValue1,
  actGrdRow,
  NewGridItem,
  reasonCodes,
  reasonCodesAll,
  userType,
  defaultStatus
) => {
  let newValue = '';
  if (newValue1 === 0 || (newValue1 && newValue1.toString().length > 0)) {
    newValue = newValue1;
  }
  const returnData = { value: '', message: null, status: false };

  // validation for capacity range
  if (columnConstants.numberEditableCols.includes(currentField)) {
    // for number columns

    if (newValue && newValue.toString().length > 9) {
      returnData.message = msgConstants.noChangeUpdate;
    } else if (
      !isNaN(newValue) &&
      newValue.toString().length > 0 &&
      Number(newValue) >= 0 &&
      Number(newValue) !== backUpColVal
    ) {
      // validation for reason code
      if (!!(newValue % 1)) {
        returnData.message = 'Number Can be integer only';
      } else {
        if (userType === 'FACTORY') {
          let reasonCode =
            NewGridItem.maxAvailableReasonCode ||
            NewGridItem.shortHorizonReasonCode ||
            '';
          let reasonCodeValid = false;
          if (backUpColVal && backUpColVal.toString().length > 0) {
            if (
              reasonCodes.includes(reasonCode) ||
              reasonCodesAll.includes(reasonCode)
            ) {
              reasonCodeValid = true;
            }
          } else {
            if (reasonCodes.includes(reasonCode)) {
              reasonCodeValid = true;
            }
          }
          if (currentField === 'FctyMaxCapacity') {
            let maxCapacityReasonCode = NewGridItem.maxCapacityReasonCode
              ? NewGridItem.maxCapacityReasonCode
              : '';
            if (
              reasonCodes.includes(maxCapacityReasonCode) ||
              reasonCodesAll.includes(maxCapacityReasonCode)
            ) {
              reasonCodeValid = true;
            } else {
              reasonCodeValid = false;
            }
          }
          if (reasonCodeValid) {
            returnData.value = newValue;
            returnData.status = true;
          } else {
            returnData.message = 'Invalid Reason Code';
          }
        } else {
          returnData.value = newValue;
          returnData.status = true;
        }
      }
    } else if (!isNaN(newValue) && Number(newValue) < 0) {
      returnData.message = 'Negative numbers not accepted';
    } else if (!newValue && Number(backUpColVal)) {
      returnData.message = 'Number cant replaced as Empty';
    } else if (isNaN(newValue)) {
      returnData.message = 'Numbers only accepted';
    }
  }
  // validation for reason code
  else if (columnConstants.reasonCodeEditableCols.includes(currentField)) {
    // let CapacityChange = '';
    let CapacityNew = '';
    let oldReasonCode = '';
    if (currentField === 'maxAvailableReasonCode') {
      CapacityNew = NewGridItem.maxAvailableCapacity || '';
      oldReasonCode = actGrdRow.maxAvailableReasonCode || '';
    } else if (currentField === 'shortHorizonReasonCode') {
      CapacityNew = NewGridItem.shortHorizonCapacity || '';
      oldReasonCode = actGrdRow.shortHorizonReasonCode || '';
    } else if (currentField === 'maxCapacityReasonCode') {
      let cpNewVal = NewGridItem.FctyMaxCapacity;
      if (cpNewVal === 0 || (cpNewVal && cpNewVal.toString().length > 0)) {
        CapacityNew = NewGridItem.FctyMaxCapacity;
      }
      oldReasonCode = actGrdRow.maxCapacityReasonCode || '';
    }
    if (userType === 'FACTORY') {
      if (newValue.toString() !== oldReasonCode.toString()) {
        if (Number(CapacityNew) >= 0) {
          // && (Number(CapacityNew) !== Number(CapacityChange))

          if (reasonCodes.includes(newValue)) {
            let FactoryField =
              defaultStatus === 'MaxAvailable'
                ? NewGridItem.maxAvailableCapacity
                : NewGridItem.shortHorizonCapacity;
            let FactoryFieldStr = '';
            if (
              FactoryField === 0 ||
              (FactoryField && FactoryField.toString().length > 0)
            ) {
              FactoryFieldStr = FactoryField.toString();
            }
            if (currentField === 'maxCapacityReasonCode') {
              if (
                NewGridItem.FctyMaxCapacity === 0 ||
                (NewGridItem.FctyMaxCapacity &&
                  NewGridItem.FctyMaxCapacity.toString().length > 0)
              ) {
                FactoryFieldStr = NewGridItem.FctyMaxCapacity.toString();
              }
            }
            if (FactoryFieldStr.length > 0) {
              returnData.value = newValue;
              returnData.status = true;
            } else {
              returnData.message = 'Invalid Capacity Value';
            }
          } else {
            returnData.message = 'Invalid Reason Code';
          }
        } else {
          returnData.message = 'Invalid Capacity Value';
        }
      }
    }
  }
  // for update notes
  else if (columnConstants.notesEditableCols.includes(currentField)) {
    if (newValue && newValue.length > 0) {
      returnData.value = newValue;
      returnData.status = true;
    }
  }
  return returnData;
};

export const validateNumberChange = (
  newValue,
  oldValue,
  field,
  IsExcel,
  isProdView,
  fieldname
) => {
  const maxLimit = isProdView ? 10 : 9;
  const result = {
    value: newValue,
    message: 'Invalid Capacity Value',
    status: false,
    field,
    fieldname
  };
  const numbersOnly = /^[0-9]+$/;

  if (newValue === 0 || (newValue && newValue.toString().length > 0)) {
    const newValStr = newValue.toString();
    if (newValue && newValue.toString().length > maxLimit) {
      result.message = msgConstants.noChangeUpdate;
    } else if (
      Number(newValue) >= 0 &&
      Number(newValue) % 1 === 0 &&
      newValStr.match(numbersOnly)
    ) {
      if (IsExcel && Number(newValue) !== oldValue) {
        result.value = newValue;
        result.status = true;
        result.message = 'Accepted';
      } else {
        result.value = newValue;
        result.status = true;
        result.message = 'Accepted';
      }
    } else if (Number(newValue) < 0) {
      result.message = 'Negative numbers not accepted';
    } else if (isNaN(newValue)) {
      result.message = 'Numbers only accepted';
    } else if (Number(newValue) % 1 !== 0) {
      result.message = 'Decimal Values not Accepted';
    }
  } else if (!newValue && Number(oldValue)) {
    result.message = 'Number cant replaced as Empty';
  } else if (isNaN(newValue)) {
    result.message = 'Invalid Capacity Value';
  }
  return result;
};

export const validateRCChange = (
  newValue,
  oldValue,
  reasonCodes,
  reasonCodesAll,
  field,
  fieldname
) => {
  const result = {
    value: newValue,
    message: 'Invalid ReasonCode',
    status: false,
    field,
    fieldname
  };

  if (oldValue && oldValue.toString().length > 0) {
    if (reasonCodesAll.includes(newValue)) {
      result.value = newValue;
      result.status = true;
      result.message = 'Accepted';
    }
  } else if (reasonCodes.includes(newValue)) {
    result.value = newValue;
    result.status = true;
    result.message = 'Accepted';
  }

  return result;
};

const isValidGBDComment = (newValue, field, fieldname) => {
  const result = {
    value: newValue,
    message: 'Invalid comments format.',
    status: false,
    field,
    fieldname
  };
  // const regex = /^[a-zA-Z ]*$/;
  // if (regex.test(newValue.toString()) && newValue.toString().length <= 256) {
  if (newValue && newValue.toString().length > 0) {
    result.value = newValue;
    result.status = true;
    result.message = 'Accepted';
  }
  return result;
};

const isValidGBDDateFormat = (newValue, field, fieldname) => {
  const result = {
    value: newValue,
    message: 'Invalid date format.',
    status: false,
    field,
    fieldname
  };
  var today = moment().format('MM/DD/YYYY');
  var formattedNewValue = moment(newValue.toString(), 'MM/DD/YYYY', true);
  if (formattedNewValue.isValid() && formattedNewValue.diff(today) >= 0) {
    result.value = newValue;
    result.status = true;
    result.message = 'Accepted';
  }
  return result;
};

export const isValidGBDCapacity = (newValue, oldValue, field, fieldname) => {
  const maxLimit = 10;
  const result = {
    value: newValue,
    message: 'Invalid Capacity Value',
    status: false,
    field,
    fieldname
  };
  const numbersOnly = /^-?[0-9]+$/;

  if (newValue === 0 || (newValue && newValue.toString().length > 0)) {
    const newValStr = newValue.toString();
    if (newValue && newValue.toString().length > maxLimit) {
      result.message = msgConstants.noChangeUpdate;
    } else if (Number(newValue) % 1 === 0 && newValStr.match(numbersOnly)) {
      if (Number(newValue) !== oldValue) {
        result.value = newValue;
        result.status = true;
        result.message = 'Accepted';
      }
    } else if (isNaN(newValue)) {
      result.message = 'Numbers only accepted';
    } else if (Number(newValue) % 1 !== 0) {
      result.message = 'Decimal Values not Accepted';
    }
  } else if (!newValue && Number(oldValue)) {
    result.message = 'Number cant replaced as Empty';
  } else if (isNaN(newValue)) {
    result.message = 'Invalid Capacity Value';
  }
  return result;
};

export const isValidGBDCost = (newValue, oldValue, field, fieldname) => {
  const maxLimit = 10;
  const result = {
    value: newValue,
    message: 'Invalid Cost Value',
    status: false,
    field,
    fieldname
  };
  const numbersOnly = /^-?[0-9]+$/;

  if (newValue === 0 || (newValue && newValue.toString().length > 0)) {
    const newValStr = newValue.toString();
    if (newValue && newValue.toString().length > maxLimit) {
      result.message = msgConstants.noChangeUpdate;
    } else if (Number(newValue) % 1 === 0 && newValStr.match(numbersOnly)) {
      if (Number(newValue) !== oldValue) {
        result.value = newValue;
        result.status = true;
        result.message = 'Accepted';
      }
    } else if (isNaN(newValue)) {
      result.message = 'Numbers only accepted';
    } else if (Number(newValue) % 1 !== 0) {
      result.message = 'Decimal Values not Accepted';
    }
  } else if (!newValue && Number(oldValue)) {
    result.message = 'Number cant replaced as Empty';
  } else if (isNaN(newValue)) {
    result.message = 'Invalid Capacity Value';
  }
  return result;
};

const getColumnHeaderName = (gblColumnData, capacity) => {
  let colFieldName;
  gblColumnData.forEach(col => {
    if (col.children) {
      col.children.forEach(firstChild => {
        if (firstChild.children) {
          const capacityColName = firstChild.children.find(
            o => o.field === capacity
          );
          if (capacityColName !== undefined) {
            colFieldName =
              capacityColName.headerName +
              ' :: ' +
              firstChild.headerName +
              ' :: ' +
              col.headerName;
          }
        } else {
          if (firstChild.field === capacity) {
            colFieldName = firstChild.headerName + ' :: ' + col.headerName;
          }
        }
      });
    } else {
      if (col.field === capacity) {
        colFieldName = col.headerName;
      }
    }
  });
  return colFieldName;
};

export const validateDataByRowOnlyOnUpload = (
  newDataRow,
  actGrdRow,
  backUpGridRow,
  userType,
  defaultStatus,
  gbdGroupedColumns,
  gblColumnData
) => {
  const result = [];
  actGrdRow.ChangeByUpload = 0;
  actGrdRow.colModified = [];
  const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
  const userFactory = JSON.parse(userFacList);
  const divisionCode = divisionCodes[userFactory.factoryType];

  // GBD validations
  if (userType === 'FACTORY') {
    gbdGroupedColumns.forEach(column => {
      //Variance Validation
      let capacityVariance = '';
      if (column.header === 'GBL') {
        capacityVariance =
          newDataRow['GbdMaxCapVariance'] ||
          newDataRow['GbdMaxCapVariance'] === 0
            ? parseInt(newDataRow['GbdMaxCapVariance'], 10)
            : '';
      } else if (column.header === 'Strat QOQ') {
        capacityVariance =
          newDataRow['StrQMaxCapVariance'] ||
          newDataRow['StrQMaxCapVariance'] === 0
            ? parseInt(newDataRow['StrQMaxCapVariance'], 10)
            : '';
      } else if (column.header === 'Rvsd QOQ') {
        capacityVariance =
          newDataRow['RvsdQMaxCapVariance'] ||
          newDataRow['RvsdQMaxCapVariance'] === 0
            ? parseInt(newDataRow['RvsdQMaxCapVariance'], 10)
            : '';
      } else if (column.header === 'Max QOQ') {
        capacityVariance =
          newDataRow['MaxQMaxCapVariance'] ||
          newDataRow['MaxQMaxCapVariance'] === 0
            ? parseInt(newDataRow['MaxQMaxCapVariance'], 10)
            : '';
      } else if (column.header === 'Cap QOQ') {
        capacityVariance =
          newDataRow['CapQMaxCapVariance'] ||
          newDataRow['CapQMaxCapVariance'] === 0
            ? parseInt(newDataRow['CapQMaxCapVariance'], 10)
            : '';
      }
      let sum = 0;
      column.Cap &&
        column.Cap.forEach(capacity => {
          if (newDataRow[capacity] || newDataRow[capacity] === 0) {
            sum += parseInt(newDataRow[capacity], 10);
          }
        });
      //**If variance exist */
      if (capacityVariance || capacityVariance === 0) {
        if (capacityVariance === sum) {
          column.Cap &&
            column.Cap.forEach(capacity => {
              let capValNew =
                (newDataRow[capacity] &&
                  newDataRow[capacity].toString().length > 0) ||
                newDataRow[capacity] === 0
                  ? Number(newDataRow[capacity])
                  : '';
              let capValOld =
                (backUpGridRow[capacity] &&
                  backUpGridRow[capacity].toString().length > 0) ||
                backUpGridRow[capacity] === 0
                  ? Number(backUpGridRow[capacity])
                  : '';
              const colFieldName = getColumnHeaderName(gblColumnData, capacity);
              if (
                (newDataRow[capacity] || newDataRow[capacity] === 0) &&
                capValNew !== capValOld
              ) {
                actGrdRow[capacity] = parseInt(newDataRow[capacity], 10);
                const successObj = {
                  value: newDataRow[capacity],
                  message: 'Accepted',
                  status: true,
                  field: capacity,
                  fieldname: colFieldName
                };
                actGrdRow.colModified.push(capacity);
                result.push(successObj);
              }
            });
        } else {
          column.Cap &&
            column.Cap.forEach(capacity => {
              let capValNew =
                (newDataRow[capacity] &&
                  newDataRow[capacity].toString().length > 0) ||
                newDataRow[capacity] === 0
                  ? Number(newDataRow[capacity])
                  : '';
              let capValOld =
                (backUpGridRow[capacity] &&
                  backUpGridRow[capacity].toString().length > 0) ||
                backUpGridRow[capacity] === 0
                  ? Number(backUpGridRow[capacity])
                  : '';
              const colFieldName = getColumnHeaderName(gblColumnData, capacity);
              if (backUpGridRow[capacity] === undefined) {
                actGrdRow[capacity] = '';
              }
              if (backUpGridRow[capacity]) {
                actGrdRow[capacity] = parseInt(backUpGridRow[capacity], 10);
              }

              if (
                (newDataRow[capacity] || newDataRow[capacity] === 0) &&
                newDataRow[capacity].toString().length > 0 &&
                capValNew !== capValOld
              ) {
                const colHeader = gblColumnData.find(
                  col => col.field === column.varField
                );
                if (colHeader !== undefined) {
                  const errObj = {
                    value: newDataRow[capacity],
                    message: `${colHeader.headerName} is not equal to sum of ${column.header} Lever values.`,
                    status: false,
                    field: capacity,
                    fieldname: colFieldName
                  };
                  result.push(errObj);
                }
              }
            });
        }

        // Cost Validation
        column.Cost.forEach(costCol => {
          let gbdNewCost =
            newDataRow[costCol] === 0 ||
            (newDataRow[costCol] && newDataRow[costCol].toString().length > 0)
              ? Number(newDataRow[costCol])
              : '';
          let gbdOldCost =
            backUpGridRow[costCol] === 0 ||
            (backUpGridRow[costCol] &&
              backUpGridRow[costCol].toString().length > 0)
              ? Number(backUpGridRow[costCol])
              : '';

          const colFieldName = getColumnHeaderName(gblColumnData, costCol);
          let capacityColumn = costCol;
          capacityColumn = capacityColumn.replace(/(Cost$)/, 'Cap');
          const capacityValue = newDataRow[capacityColumn];
          if (
            (capacityValue || capacityValue === 0) &&
            (gbdNewCost || gbdNewCost === 0) &&
            capacityVariance === sum
          ) {
            if (gbdNewCost >= 0) {
              const gbdCommentsValidate = isValidGBDCost(
                gbdNewCost,
                gbdOldCost,
                costCol,
                colFieldName
              );
              if (gbdCommentsValidate.status) {
                actGrdRow[costCol] = gbdNewCost;
              } else {
                actGrdRow[costCol] = backUpGridRow[costCol];
              }
              if (gbdNewCost !== gbdOldCost) {
                result.push(gbdCommentsValidate);

                if (gbdCommentsValidate.status) {
                  actGrdRow.ChangeByUpload = 1;
                  actGrdRow.colModified.push(costCol);
                }
              }
            } else {
              const errorObj = {
                value: gbdNewCost,
                message: msgConstants.negativeValueMsg,
                status: false,
                field: costCol,
                fieldname: colFieldName
              };
              result.push(errorObj);
            }
          } else {
            if (backUpGridRow[costCol] === undefined) {
              actGrdRow[costCol] = '';
            }
            if (backUpGridRow[costCol]) {
              actGrdRow[costCol] = parseInt(backUpGridRow[costCol], 10);
            }
            let errorObj = {
              value: gbdNewCost,
              message: ``,
              status: false,
              field: costCol,
              fieldname: colFieldName
            };
            if (
              (gbdNewCost || gbdNewCost === 0) &&
              gbdNewCost !== gbdOldCost &&
              (sum === 0 || !capacityValue)
            ) {
              errorObj.message = 'Lever capacity is empty';
              result.push(errorObj);
            } else if (
              (gbdNewCost || gbdNewCost === 0) &&
              gbdNewCost !== gbdOldCost &&
              sum !== 0
            ) {
              errorObj.message = 'Failed sum validation of lever capacity';
              result.push(errorObj);
            }
          }
        });

        // Comments Validation
        column.Comments.forEach(commentsColumn => {
          const colFieldName = getColumnHeaderName(
            gblColumnData,
            commentsColumn
          );
          let gbdNewComment =
            newDataRow[commentsColumn] &&
            newDataRow[commentsColumn].toString().length > 0
              ? newDataRow[commentsColumn].toString()
              : '';
          let gbdOldComment =
            backUpGridRow[commentsColumn] &&
            backUpGridRow[commentsColumn].toString().length > 0
              ? backUpGridRow[commentsColumn].toString()
              : '';
          let capacityColumn = commentsColumn;
          capacityColumn = capacityColumn.replace(/(Comments$)/, 'Cap');
          const capacityValue = newDataRow[capacityColumn];
          if (
            (capacityValue || capacityValue === 0) &&
            (gbdNewComment || gbdNewComment === '') &&
            capacityVariance === sum
          ) {
            const gbdCommentsValidate = isValidGBDComment(
              gbdNewComment,
              commentsColumn,
              colFieldName
            );
            if (gbdCommentsValidate.status) {
              actGrdRow[commentsColumn] = gbdNewComment;
            } else {
              actGrdRow[commentsColumn] = backUpGridRow[commentsColumn];
            }

            if (
              (gbdNewComment || gbdNewComment === '') &&
              gbdNewComment !== gbdOldComment
            ) {
              result.push(gbdCommentsValidate);
              if (gbdCommentsValidate.status) {
                actGrdRow.ChangeByUpload = 1;
                actGrdRow.colModified.push(commentsColumn);
              }
            }
          } else {
            if (backUpGridRow[commentsColumn] === undefined) {
              actGrdRow[commentsColumn] = '';
            }
            if (backUpGridRow[commentsColumn]) {
              actGrdRow[commentsColumn] = backUpGridRow[commentsColumn];
            }
            let errorObj = {
              value: gbdNewComment,
              message: ``,
              status: false,
              field: commentsColumn,
              fieldname: colFieldName
            };
            if (
              (gbdNewComment || gbdNewComment === '') &&
              gbdNewComment !== gbdOldComment &&
              (sum === 0 || !capacityValue)
            ) {
              errorObj.message = 'Lever capacity is empty';
              result.push(errorObj);
            } else if (
              (gbdNewComment || gbdNewComment === '') &&
              gbdNewComment !== gbdOldComment &&
              sum !== 0
            ) {
              errorObj.message = 'Failed sum validation of lever capacity';
              result.push(errorObj);
            }
          }
        });

        //ValidityDate Validation
        column.ValidityDate.forEach(validityDateColumn => {
          const gbdValidityDate = newDataRow[validityDateColumn];
          const colFieldName = getColumnHeaderName(
            gblColumnData,
            validityDateColumn
          );
          let gbdValidityDateNewtoStr =
            gbdValidityDate && gbdValidityDate.toString().length > 0
              ? gbdValidityDate.toString()
              : '';
          let gbdValidityDateOldtoStr =
            backUpGridRow[validityDateColumn] &&
            backUpGridRow[validityDateColumn].toString().length > 0
              ? backUpGridRow[validityDateColumn].toString()
              : '';
          let capacityColumn = validityDateColumn;
          capacityColumn = capacityColumn.replace(/(ValidityDate$)/, 'Cap');
          const capacityValue = newDataRow[capacityColumn];
          if (
            (capacityValue || capacityValue === 0) &&
            gbdValidityDate &&
            capacityVariance === sum
          ) {
            const gbdValidityDateValidate = isValidGBDDateFormat(
              gbdValidityDate,
              validityDateColumn,
              colFieldName
            );

            if (gbdValidityDateValidate.status) {
              actGrdRow[validityDateColumn] = gbdValidityDate;
            } else {
              actGrdRow[validityDateColumn] = backUpGridRow[validityDateColumn];
            }
            if (gbdValidityDateNewtoStr !== gbdValidityDateOldtoStr) {
              result.push(gbdValidityDateValidate);
              gbdValidityDateValidate.status &&
                actGrdRow.colModified.push(validityDateColumn);
            }
          } else {
            if (backUpGridRow[validityDateColumn] === undefined) {
              actGrdRow[validityDateColumn] = '';
            }
            if (backUpGridRow[validityDateColumn]) {
              actGrdRow[validityDateColumn] = backUpGridRow[validityDateColumn];
            }
            let errorObj = {
              value: gbdValidityDate,
              message: ``,
              status: false,
              field: validityDateColumn,
              fieldname: colFieldName
            };
            if (
              gbdValidityDate &&
              gbdValidityDateNewtoStr !== gbdValidityDateOldtoStr &&
              (sum === 0 || !capacityValue)
            ) {
              errorObj.message = 'Lever capacity is empty';
              result.push(errorObj);
            } else if (
              gbdValidityDate &&
              gbdValidityDateNewtoStr !== gbdValidityDateOldtoStr &&
              sum !== 0
            ) {
              errorObj.message = 'Failed sum validation of lever capacity';
              result.push(errorObj);
            }
          }
        });
      } else {
        //**If variance does not exist */
        const pushErrorFun = (colField, header) => {
          const colFieldName = getColumnHeaderName(gblColumnData, colField);
          let newColField =
            newDataRow[colField] && newDataRow[colField].toString().length > 0
              ? newDataRow[colField]
              : '';
          let oldColField =
            backUpGridRow[colField] &&
            backUpGridRow[colField].toString().length > 0
              ? backUpGridRow[colField]
              : '';

          if (backUpGridRow[colField] === undefined) {
            actGrdRow[colField] = '';
          }
          if (backUpGridRow[colField]) {
            actGrdRow[colField] = parseInt(backUpGridRow[colField], 10);
          }
          if (
            (newDataRow[colField] && newColField !== oldColField) ||
            (newDataRow[colField] === 0 &&
              newDataRow[colField].toString().length > 0)
          ) {
            const errObj = {
              value: newDataRow[colField],
              message: `${header} Variance does not exist.`,
              status: false,
              field: colField,
              fieldname: colFieldName
            };
            result.push(errObj);
          }
        };
        //**check all column for error msg */
        column.Cap &&
          column.Cap.forEach(capacity => pushErrorFun(capacity, column.header));
        column.Cost &&
          column.Cost.forEach(costCol => pushErrorFun(costCol, column.header));
        column.ValidityDate &&
          column.ValidityDate.forEach(valiCol =>
            pushErrorFun(valiCol, column.header)
          );
        column.Comments &&
          column.Comments.forEach(comCol =>
            pushErrorFun(comCol, column.header)
          );
      }
    });
  }
  const isChanged = result.findIndex(o => o.status === true);
  actGrdRow.ChangeByUpload = isChanged >= 0 ? 1 : 0;

  if (userType === 'WHQ') {
    let newVal = '';
    let oldVal = '';
    let colFieldName = '';
    if (divisionCode === 10) {
      //For AP User
      newVal =
        (typeof newDataRow.targetHldCpty !== 'undefined' &&
          newDataRow.targetHldCpty.toString().length > 0) ||
        newDataRow.targetHldCpty === 0
          ? newDataRow.targetHldCpty
          : '';
      oldVal =
        (typeof backUpGridRow.targetHldCpty !== 'undefined' &&
          backUpGridRow.targetHldCpty.toString().length > 0) ||
        backUpGridRow.targetHldCpty === 0
          ? backUpGridRow.targetHldCpty
          : '';
      colFieldName = getColumnHeaderName(gblColumnData, 'targetHldCpty');
    } else if (divisionCode === 20) {
      //For FW User
      newVal =
        (typeof newDataRow.targetCpty !== 'undefined' &&
          newDataRow.targetCpty.toString().length > 0) ||
        newDataRow.targetCpty === 0
          ? newDataRow.targetCpty
          : '';
      oldVal =
        (typeof backUpGridRow.targetCpty !== 'undefined' &&
          backUpGridRow.targetCpty.toString().length > 0) ||
        backUpGridRow.targetCpty === 0
          ? backUpGridRow.targetCpty
          : '';
      colFieldName = getColumnHeaderName(gblColumnData, 'targetCpty');
    }
    if (oldVal !== newVal) {
      if (!(newVal < 0)) {
        const targetCptyVald = isValidGBDCapacity(
          newVal,
          oldVal,
          divisionCode === 20 ? 'targetCpty' : 'targetHldCpty',
          colFieldName
        );

        if (targetCptyVald.status) {
          actGrdRow.ChangeByUpload = 1;
          if (
            (newDataRow.targetCpty === 0 || newDataRow.targetCpty) &&
            divisionCode === 20
          ) {
            actGrdRow.targetCpty = newDataRow.targetCpty;
            actGrdRow.colModified.push('targetCpty');
            actGrdRow.targetCpty = parseInt(newDataRow.targetCpty, 10);
            const successObj = {
              value: newDataRow.targetCpty,
              message: 'Accepted',
              status: true,
              field: 'targetCpty',
              fieldname: colFieldName
            };
            result.push(successObj);
          } else if (
            (newDataRow.targetHldCpty === 0 || newDataRow.targetHldCpty) &&
            divisionCode === 10
          ) {
            actGrdRow.targetHldCpty = newDataRow.targetHldCpty;
            actGrdRow.colModified.push('targetHldCpty');
            actGrdRow.targetHldCpty = parseInt(newDataRow.targetHldCpty, 10);
            const successObj = {
              value: newDataRow.targetHldCpty,
              message: 'Accepted',
              status: true,
              field: 'targetHldCpty',
              fieldname: colFieldName
            };
            result.push(successObj);
          } else {
            result.push(targetCptyVald);
          }
        } else {
          const fieldName =
            divisionCode === 20 ? 'targetCpty' : 'targetHldCpty';
          actGrdRow[fieldName] = backUpGridRow[fieldName];
          const errorObj = {
            value:
              divisionCode === 20
                ? newDataRow.targetCpty
                : newDataRow.targetHldCpty,
            message: targetCptyVald.message,
            status: false,
            field: fieldName,
            fieldname: colFieldName
          };
          result.push(errorObj);
        }
      } else {
        const fieldName = divisionCode === 20 ? 'targetCpty' : 'targetHldCpty';
        actGrdRow[fieldName] = backUpGridRow[fieldName];
        const errorObj = {
          value:
            divisionCode === 20
              ? newDataRow.targetCpty
              : newDataRow.targetHldCpty,
          message: msgConstants.negativeValueMsg,
          status: false,
          field: fieldName,
          fieldname: colFieldName
        };
        result.push(errorObj);
      }
    }
  }
  const newActRow = actGrdRow;
  return { newActRow, result };
};

export const GBDVarianceValidationResult = (
  newDataRow,
  gbdGroupedColumns,
  defaultStatus
) => {
  const result = [];
  // GBD validations
  gbdGroupedColumns.forEach(column => {
    //Variance Validation
    let capacityVariance = 0;
    if (column.header === 'GBL') {
      capacityVariance = newDataRow['GbdMaxCapVariance']
        ? parseInt(newDataRow['GbdMaxCapVariance'], 10)
        : 0;
    } else if (
      column.header === 'Strat QOQ' &&
      defaultStatus === 'MaxAvailable'
    ) {
      capacityVariance = newDataRow['StrQMaxCapVariance']
        ? parseInt(newDataRow['StrQMaxCapVariance'], 10)
        : 0;
    } else if (
      column.header === 'Rvsd QOQ' &&
      defaultStatus === 'shortHorizon'
    ) {
      capacityVariance = newDataRow['RvsdQMaxCapVariance']
        ? parseInt(newDataRow['RvsdQMaxCapVariance'], 10)
        : 0;
    } else if (column.header === 'Max QOQ') {
      capacityVariance = newDataRow['MaxQMaxCapVariance']
        ? parseInt(newDataRow['MaxQMaxCapVariance'], 10)
        : 0;
    } else if (column.header === 'Cap QOQ') {
      capacityVariance = newDataRow['CapQMaxCapVariance']
        ? parseInt(newDataRow['CapQMaxCapVariance'], 10)
        : 0;
    }
    let sum = 0;
    let unchangedCapacityCount = 0;
    column.Cap &&
      column.Cap.forEach(capacity => {
        if (newDataRow[capacity] !== undefined) {
          sum += parseInt(newDataRow[capacity], 10);
        } else {
          unchangedCapacityCount = unchangedCapacityCount + 1;
        }
      });
    if (
      capacityVariance !== sum &&
      column.Cap &&
      unchangedCapacityCount !== column.Cap.length
    ) {
      const errObj = {
        id: newDataRow.uniQId,
        productionQuarter: newDataRow.productionQuarter,
        factoryCode: newDataRow.factoryCode,
        capacityTypeCode: newDataRow.capacityTypeCode,
        type: column.header,
        weekStartDate: newDataRow.weekStartDate,
        expectedValue: capacityVariance,
        actualValue: sum,
        difference: parseInt(capacityVariance) - parseInt(sum)
      };
      result.push(errObj);
    }
  });
  return { result };
};
//upload Validation
export const validateAllDataOnUpload = (
  jsonDataMapped,
  activeGrid,
  userType,
  defaultStatus,
  gridColumns,
  gbdGroupedColumns,
  gblColumnData
) => {
  // upload validation
  let ErrorCounts = 0;
  const validationReport = [];
  const backUpGrid = JSON.parse(JSON.stringify(activeGrid));
  const AllColNames = [];
  gridColumns.forEach(keyCol => {
    const col = { name: keyCol.colDef.headerName, field: keyCol.colDef.field };
    AllColNames.push(col);
  });
  if (jsonDataMapped.length > 0) {
    // for each current grid row
    activeGrid.forEach(actGrdRow => {
      const activeIndex = jsonDataMapped.findIndex(i => i.id === actGrdRow.id);
      const backUpIndex = backUpGrid.findIndex(i => i.id === actGrdRow.id);
      if (activeIndex >= 0) {
        if (Object.keys(jsonDataMapped[activeIndex]).length > 0) {
          actGrdRow.ChangeByUpload = 0;
          actGrdRow.colModified = [];
          const { newActRow, result } = validateDataByRowOnlyOnUpload(
            jsonDataMapped[activeIndex],
            actGrdRow,
            backUpGrid[backUpIndex],
            userType,
            defaultStatus,
            gbdGroupedColumns,
            gblColumnData
          );

          if (newActRow.ChangeByUpload > 0) {
            newActRow.colModified.forEach(col => {
              actGrdRow[col] = newActRow[col];
            });
            actGrdRow.ChangeByUpload = newActRow.ChangeByUpload;
            actGrdRow.colModified = newActRow.colModified;
          } else {
            actGrdRow.ChangeByUpload = 0;
          }

          if (result.length > 0) {
            const report = JSON.parse(JSON.stringify(result));
            report.forEach(log => {
              log.row = jsonDataMapped[activeIndex].excelRow;
              log.factoryCode = jsonDataMapped[activeIndex].factoryCode;
              log.capacityTypeCode =
                jsonDataMapped[activeIndex].capacityTypeCode;
              log.weekStartDate = jsonDataMapped[activeIndex].weekStartDate;
              log.id = jsonDataMapped[activeIndex].id;
              log.productionQuarter =
                jsonDataMapped[activeIndex].productionQuarter;
              validationReport.push(log);
            });
          }
        } else {
          ErrorCounts += 1;
          validationReport.push({
            status: false,
            message: msgConstants.inValFormatDataCort
          });
        }
      }
    });
  } else {
    // updates if rows missing in upload, throws error and stops updating the uploaded data
    ErrorCounts += 1;
    validationReport.push({
      status: false,
      message: msgConstants.inValFormatDataCort
    });
  }
  if (ErrorCounts === 0) {
    return { activeGrid, validationReport };
  }
  return { activeGrid: [], validationReport };
};
//upload Validation
export const validateAllDataOnUploadOld = (
  data,
  activeGrid,
  reasonCodes,
  reasonCodesAll,
  userType,
  defaultStatus,
  gridColumns,
  currentuseremail
) => {
  // upload validation
  let ErrorCounts = 0;
  const validationReport = [];
  const backUpGrid = JSON.parse(JSON.stringify(activeGrid));

  if (data.length > 0) {
    // for each current grid row
    activeGrid.forEach(actGrdRow => {
      const activeIndex = data.findIndex(i => i.id === actGrdRow.id);
      const backUpIndex = backUpGrid.findIndex(i => i.id === actGrdRow.id);
      if (activeIndex >= 0) {
        if (Object.keys(data[activeIndex]).length > 0) {
          // for each current grid row col//
          //';

          gridColumns.forEach(keyCol => {
            const headerName = keyCol.colDef.headerName;
            let backUpColVal = '';
            const validColVal = backUpGrid[backUpIndex][keyCol.colDef.field];
            if (
              validColVal === 0 ||
              validColVal === '0' ||
              (validColVal && validColVal.toString().length > 0)
            ) {
              backUpColVal = validColVal;
            }

            const userInput = data[activeIndex][keyCol.colDef.field];
            const ifNoteField = columnConstants.notesEditableCols.includes(
              keyCol.colDef.field
            );
            if (keyCol.colDef.editable || ifNoteField) {
              if (String(backUpColVal) !== String(userInput)) {
                // for what values vadidation

                const updateData = validateDataByRowOnUpload(
                  keyCol.colDef.field,
                  backUpColVal,
                  userInput,
                  actGrdRow,
                  data[activeIndex],
                  reasonCodes,
                  reasonCodesAll,
                  userType,
                  defaultStatus
                ); //currentField, backUpColVal, userInput, actGrdRow, NewGridItem
                const reportLog = { status: false, message: '' };
                reportLog.row = data[activeIndex].excelRow;
                reportLog.value = updateData.value || userInput;
                reportLog.fieldname = headerName;
                reportLog.factoryCode = data[activeIndex].factoryCode;
                reportLog.capacityTypeCode = data[activeIndex].capacityTypeCode;
                reportLog.weekStartDate = data[activeIndex].weekStartDate;
                reportLog.id = data[activeIndex].id;
                if (
                  updateData.value.toString().length > 0 &&
                  updateData.value !== actGrdRow[keyCol.colDef.field]
                ) {
                  if (updateData.value === 'NULLRETURN') {
                    actGrdRow[keyCol.colDef.field] = '';
                  } else {
                    /// on notes update as object else as vale
                    if (ifNoteField) {
                      const newComment = updateNewCommentEntry(
                        userInput,
                        backUpColVal,
                        currentuseremail,
                        userType,
                        false
                      );
                      actGrdRow[keyCol.colDef.field] = newComment;
                    } else {
                      actGrdRow[keyCol.colDef.field] = userInput;
                    }
                    ///
                  }
                  if (
                    columnConstants.numberEditableCols.includes(
                      keyCol.colDef.field
                    ) &&
                    keyCol.colDef.field !== 'FctyMaxCapacity'
                  ) {
                    if (defaultStatus === 'MaxAvailable') {
                      actGrdRow.maxAvailableEditedBy = userType;
                    }
                    if (defaultStatus === 'shortHorizon') {
                      actGrdRow.shortHorizonEditedBy =
                        keyCol.colDef.field === 'approvedRevisedCapacity'
                          ? 'WHQ'
                          : userType;
                    }
                  }

                  reportLog.status = true;
                  reportLog.message = 'Accepted';
                } else if (updateData.message) {
                  reportLog.message = updateData.message;
                }
                if (reportLog.message) {
                  validationReport.push(reportLog);
                }
              }
            } else {
            }
          });
        } else {
          ErrorCounts += 1;
          validationReport.push({
            status: false,
            message: msgConstants.inValFormatDataCort
          });
        }
      }
    });
  } else {
    // updates if rows missing in upload, throws error and stops updating the uploaded data
    ErrorCounts += 1;
    validationReport.push({
      status: false,
      message: msgConstants.inValFormatDataCort
    });
  }
  if (ErrorCounts === 0) {
    return { activeGrid, validationReport };
  }
  return { activeGrid: [], validationReport };
};

export const calculationAfterUpload = (
  oldData,
  userType,
  userName,
  dBoard,
  isProdView
) => {
  const data = oldData;
  data.forEach((item, i) => {
    let statusCol =
      dBoard === 'MaxAvailable' ? 'maxAvailableStatus' : 'shortHorizonStatus';
    let userCol = '';
    if (userType === 'FACTORY') {
      const col2Check =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableCapacity'
          : 'shortHorizonCapacity';
      const rCodeField =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableReasonCode'
          : 'shortHorizonReasonCode';
      const ChangeBy =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableEditedBy'
          : 'shortHorizonEditedBy';

      if (item.colModified && item.colModified.includes(col2Check)) {
        item[statusCol] = 'In Review';
      }
      if (
        isProdView &&
        item.colModified &&
        item.colModified.length > 0 &&
        item.colModified.includes(rCodeField) &&
        (col2Check === 0 ||
          col2Check === '0' ||
          (col2Check && col2Check.toString().length > 0))
      ) {
        item[statusCol] = 'In Review';
        item[ChangeBy] = userType;
      }
    } else if (userType === 'NLO') {
      const col2Check =
        dBoard === 'MaxAvailable'
          ? 'maxAvailableUnitsConfirmed'
          : 'shortHorizonUnitsConfirmed';

      if (item.colModified && item.colModified.includes(col2Check)) {
        const userCol =
          dBoard === 'MaxAvailable'
            ? 'maxAvailableConfirmedBy'
            : 'shortHorizonConfirmedBy';
        item[statusCol] = 'Confirmed';
        item[userCol] = userName;
      }
      if (
        item.colModified &&
        item.colModified.includes('approvedRevisedCapacity')
      ) {
        statusCol = 'shortHorizonStatus';
        userCol = 'shortHorizonApprovedBy';
        item[statusCol] = 'Approved';
        item[userCol] = userName;
      }
    } else if (userType === 'WHQ') {
      const col2Check =
        dBoard === 'MaxAvailable'
          ? 'approvedMaxCapacity'
          : 'approvedRevisedCapacity';
      if (item.colModified && item.colModified.includes(col2Check)) {
        userCol =
          dBoard === 'MaxAvailable'
            ? 'maxAvailableApprovedBy'
            : 'shortHorizonApprovedBy';
        item[statusCol] = 'Approved';
        item[userCol] = userName;
      }
    }
  });
  return data;
};

export const frameDataOnUpload = (
  data,
  activeGridSetLength,
  gridColumns,
  headerLength
) => {
  const columns = [];
  const jsonData = [];

  gridColumns.forEach(i => {
    const obj = { name: i.colDef.headerName, code: i.colId };
    if (
      i &&
      i.userProvidedColDef &&
      i.userProvidedColDef.category &&
      i.userProvidedColDef.dimension
    ) {
      obj.fname =
        i.colDef.headerName +
        ' :: ' +
        i.userProvidedColDef.dimension +
        ' :: ' +
        i.userProvidedColDef.category;
    } else {
      obj.fname = i.colDef.headerName;
    }
    columns.push(obj);
  });

  // setting up the index keys first
  const excelOtherHeaderLenEnd = headerLength + activeGridSetLength;
  const headerRow = data[headerLength];
  const columnOrder = [];

  headerRow.forEach(item => {
    let thisIndex = columns.findIndex(i => i.fname === item);
    if (thisIndex >= 0) {
      columnOrder.push(columns[thisIndex].code);
    }
  });

  data.forEach((row, index) => {
    if (index > headerLength && index <= excelOtherHeaderLenEnd) {
      const newItems = {};
      row.forEach((item, key) => {
        if (item.toString() && item.toString().length > 0) {
          const field = columnOrder[key];
          if (field.endsWith('ValidityDate') && item) {
            newItems[field] = isNaN(item)
              ? item
              : moment(new Date(Date.UTC(0, 0, item, -16))).format(
                  'MM/DD/YYYY'
                );
          } else if (field.endsWith('Cost')) {
            newItems[field] = item;
          } else if (field.endsWith('Comments')) {
            newItems[field] = item;
          } else {
            newItems[field] = item;
          }
        }
      });
      jsonData.push(newItems);
    }
  });

  return jsonData;
};

// for ag grid custom footer download
export const updateUploadDataFormat = (
  data,
  activeGrid,
  defaultStatus,
  isToggleOn
) => {
  let formattedData = JSON.parse(JSON.stringify(data));
  let formattedNewData = [];

  formattedData.forEach(dataItem => {
    if (dataItem.weekStartDate) {
      dataItem.weekStartDate = moment(dataItem.weekStartDate).format(
        'MM/DD/YYYY'
      );
    }
    dataItem.isQuarterly = isToggleOn;
    if (dataItem.divisionCode === 20 && dataItem.targetCpty !== '') {
      dataItem.targetHldCpty = gbdVarianceGen('targetHldCpty', dataItem);
    }

    if (
      (dataItem.divisionCode === 10 || dataItem.divisionCode === 30) &&
      dataItem.targetHldCpty !== ''
    ) {
      dataItem.targetCpty = gbdVarianceGen('targetCpty', dataItem);
    }

    try {
      // Applying last week
      dataItem.id = dataItem.ids[dataItem.ids.length - 1];
      // removing the unnecessary cloumns added oonly

      delete dataItem.isColumnSkipped;
      delete dataItem.ids;
      delete dataItem.totalWeeks;
      delete dataItem.colModified;
      delete dataItem.ChangeByUpload;
      delete dataItem.uniQId;
      Object.keys(dataItem).forEach(function(key) {
        if (
          dataItem[key] === undefined ||
          dataItem[key] === null ||
          dataItem[key] === ''
        ) {
          delete dataItem[key];
        }
      });
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }
    formattedNewData.push(dataItem);
  });
  return formattedNewData;
};

// setParamsUpdate
export const setParamsUpdate = (params, defaultStatus) => {
  const result = {
    reasonCodes: [],
    reasonCodesAll: [],
    maxColumnEditable: true,
    prodQuarterColumns: []
  };
  let reasonCodeField = '';
  let maxColumnEditableCount = 0;
  if (defaultStatus === 'MaxAvailable') {
    reasonCodeField = 'maxAvailableReasonCode';
  } else {
    reasonCodeField = 'shortHorizonReasonCode';
  }
  result.prodQuarterColumns = [
    {
      headerName: 'Weeks Merged',
      field: 'totalWeeks',
      width: 70,
      editable: false,
      filter: false,
      suppressMenu: true,
      icons: {
        sortNone: '<i class="fas fa-sort"></i>',
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      cellClass: 'GreyCloud',
      headerClass: `headGreyCloud`,
      valueFormatter: params => {
        return params.value ? params.value : 1;
      }
    }
  ];
  if (params) {
    if (params.columnApi.getAllColumns()) {
      params.columnApi.getAllColumns().forEach(column => {
        if (column.colDef.field === reasonCodeField) {
          let RCActive = [];
          let RCAll = [];
          const onlySelectValues = column.colDef.cellEditorParams(); // only active
          const onlyFilterValues = column.colDef.filterParams; // all filter values
          RCActive = onlySelectValues.values;
          RCAll = onlyFilterValues.values;
          result.reasonCodes = RCActive.filter(function(el) {
            return el != null && el.length > 0;
          });
          result.reasonCodesAll = RCAll.filter(function(el) {
            return el != null && el.length > 0;
          });
        }
        if (
          columnConstants.maxCapacityCols.includes(column.colDef.field) &&
          column.colDef.editable
        ) {
          maxColumnEditableCount += 1;
        }
        if (
          columnConstants.prodQuarterColumnHeader.includes(column.colDef.field)
        ) {
          result.prodQuarterColumns.push({ ...column.userProvidedColDef });
        }
      });
      result.prodQuarterColumns.find(
        o => o.field === 'productionQuarter'
      ).pinned = 'left';
    }
  }
  if (maxColumnEditableCount === 0) {
    result.maxColumnEditable = false;
  }
  return result;
};

// setParamsUpdateByProps
export const setParamsUpdateByProps = (props, defaultStatus, userType) => {
  const result = {
    reasonCodes: [],
    reasonCodesAll: [],
    maxColumnEditable: true,
    prodQuarterColumns: []
  };
  let rsf = '';
  rsf =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableReasonCode'
      : 'shortHorizonReasonCode';
  let maxColumnEditableCount = 0;

  result.prodQuarterColumns = [
    {
      headerName: 'Weeks Merged',
      field: 'totalWeeks',
      width: 90,
      editable: false,
      filter: false,
      suppressMenu: true,
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      cellClass: 'GreyCloud',
      headerClass: `headGreyCloud`,
      valueFormatter: params => {
        return params.value ? params.value : 1;
      }
    }
  ];

  if (props) {
    if (props.columns) {
      props.columns.forEach(column => {
        if (
          columnConstants.maxCapacityCols.includes(column.field) &&
          column.editable
        ) {
          maxColumnEditableCount += 1;
        }

        if (columnConstants.prodQuarterColumnHeader.includes(column.field)) {
          let newCol = { ...column };
          if (
            columnConstants.maxHolidayCols.includes(column.field) ||
            columnConstants.revHolidayCols.includes(column.field)
          ) {
            // delete newCol.valueFormatter;

            newCol.valueGetter = params => {
              let paramVl = '';
              if (params && params.data) {
                paramVl = params.data[column.field];
              }
              if (
                paramVl === 0 ||
                paramVl === '0' ||
                (paramVl && paramVl.toString().length > 0)
              ) {
                return paramVl;
              } else {
                try {
                  return holidayCalculator(column.field, params.data);
                } catch (e) {
                  return paramVl;
                }
              }
            };
            //delete newCol.valueGetter;
          }
          if (columnConstants.prodQuarterFixedCols.includes(column.field)) {
            newCol.pinned = 'left';
            newCol.hide = false;
          }
          result.prodQuarterColumns.push(newCol);
        }
      });
    }
    if (props.columnOptions) {
      props.columnOptions.forEach(column => {
        if (column.header === rsf) {
          result.reasonCodes = [];
          result.reasonCodesAll = [];
          column.options.forEach(item => {
            if (item.status === 'ACTIVE') {
              result.reasonCodes.push(item.value);
            }
            result.reasonCodesAll.push(item.value);
          });
        }
      });
    }
  }
  let targetCapacity = {
    headerName: 'Target Capacity',
    field: 'targetCpty',
    width: 120,
    editable:
      defaultStatus === 'MaxAvailable' && userType === 'WHQ' ? true : false,
    filter: 'agNumberColumnFilter',
    cellEditor: 'numericEditor',
    suppressMenu: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellClass: defaultStatus === 'MaxAvailable' ? 'editableCol' : '',
    headerClass: `headSilkBlue`,
    valueFormatter: params => {
      if (params.value && !isNaN(params.value)) {
        return numberFormatter(params.value);
      }

      return params.value;
    }
  };
  if (result.prodQuarterColumns.length !== 0) {
    result.prodQuarterColumns.splice(11, 0, targetCapacity);
  }
  if (maxColumnEditableCount === 0) {
    result.maxColumnEditable = false;
  }
  return result;
};

export const onCellValueChangFormatValidate = (
  cellFormat,
  cellValue,
  editedColumn,
  reasonCodes,
  isProdView
) => {
  let formatAccepted = false;
  const maxLimit = isProdView ? 10 : 9;
  if (cellFormat === 'agNumberColumnFilter') {
    const numbersOnly = /^[0-9]+$/;
    if (
      isNaN(cellValue) ||
      Number(cellValue) % 1 !== 0 ||
      Number(cellValue) < 0 ||
      cellValue.toString().length > maxLimit ||
      !cellValue.match(numbersOnly)
    ) {
      formatAccepted = false;
    } else {
      formatAccepted = true;
    }
  }
  if (
    columnConstants.reasonCodeEditableCols.includes(editedColumn) &&
    (reasonCodes.includes(cellValue) || cellValue.length === 0)
  ) {
    formatAccepted = true;
  }

  return formatAccepted;
};

export const onCellValueChangeUpdateFields = (
  rowNodeData,
  changeAcceptance,
  editedColumn,
  eventNewValue,
  oldRowValue,
  defaultStatus,
  userType,
  userName,
  thresholds
) => {
  const { toChangeCondition, statusChange } = updateColumnFieldsPostChange(
    editedColumn,
    eventNewValue,
    userType,
    userName,
    defaultStatus
  );
  if (columnConstants.statusChangingCapCols.includes(editedColumn)) {
    const CPCol =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableEditedBy'
        : 'shortHorizonEditedBy';
    if (changeAcceptance) {
      rowNodeData[CPCol] =
        editedColumn === 'approvedRevisedCapacity' ? 'WHQ' : userType;

      if (
        CPCol === 'shortHorizonEditedBy' &&
        editedColumn === 'shortHorizonUnitsConfirmed' &&
        rowNodeData.nloApproved
      ) {
        rowNodeData[CPCol] = 'WHQ';
      }
    } else {
      rowNodeData[CPCol] = oldRowValue[CPCol];
    }
  }

  //for production quarter
  Object.keys(columnConstants.holidayColUpdate).forEach(col => {
    if (col === editedColumn) {
      const holCol = columnConstants.holidayColUpdate[editedColumn];
      rowNodeData[holCol] = holidayCalculator(holCol, rowNodeData);
    }
  });
  if (Object.keys(statusChange).length !== 0) {
    Object.keys(statusChange).forEach(e => {
      rowNodeData[e] = statusChange[e];
    });
  }
  if (Object.keys(toChangeCondition).length !== 0) {
    Object.keys(toChangeCondition).forEach(e => {
      const val = changeAcceptance ? toChangeCondition[e] : oldRowValue[e];
      if (e === 'shortHorizonStatus') {
        if (rowNodeData.nloApproved) {
          rowNodeData[e] = 'Approved';
        } else if (rowNodeData.nloConfirmed) {
          rowNodeData[e] = 'Confirmed';
        } else {
          rowNodeData[e] = val;
        }
      } else {
        rowNodeData[e] = val;
      }
    });
  }

  rowNodeData.isEdited = true;
  // validating if exceeding thresholds and pushing it into array
  const fromComp = rowNodeData[editedColumn] || 0;
  let toComp = 0;
  let ToCheckColSkip = '';
  let ToCheckFactorySkip = false;
  let isConfirmedOrApproved = false;

  switch (editedColumn) {
    case 'maxAvailableUnitsConfirmed':
      toComp = rowNodeData.maxAvailableCapacity || null;
      ToCheckColSkip = rowNodeData.maxAvailableCapacity || null;
      isConfirmedOrApproved = true;
      break;
    case 'shortHorizonUnitsConfirmed':
      toComp = rowNodeData.shortHorizonCapacity || null;
      ToCheckColSkip = rowNodeData.shortHorizonCapacity || null;
      isConfirmedOrApproved = true;
      break;
    case 'approvedMaxCapacity':
      toComp =
        rowNodeData.maxAvailableUnitsConfirmed ||
        (rowNodeData.maxAvailableCapacity || null);
      ToCheckColSkip = rowNodeData.maxAvailableUnitsConfirmed || null;
      ToCheckFactorySkip = rowNodeData.maxAvailableCapacity ? false : true;
      isConfirmedOrApproved = true;
      break;
    case 'approvedRevisedCapacity':
      toComp =
        rowNodeData.shortHorizonUnitsConfirmed ||
        (rowNodeData.shortHorizonCapacity || null);
      ToCheckColSkip = rowNodeData.shortHorizonUnitsConfirmed || null;
      ToCheckFactorySkip = rowNodeData.shortHorizonCapacity ? false : true;
      isConfirmedOrApproved = true;
      break;
    default:
      toComp = -1;
      ToCheckColSkip = -1;
  }
  if (isConfirmedOrApproved) {
    if (ToCheckColSkip === null || ToCheckColSkip === undefined) {
      rowNodeData.isColumnSkipped = true;
      const isExist = rowNodeData.inColumnSkipped.includes(editedColumn);
      if (!isExist) {
        rowNodeData.inColumnSkipped.push(editedColumn);
        rowNodeData.isStatusBreak = true;
      }
    } else {
      rowNodeData.inColumnSkipped = [];
      rowNodeData.isStatusBreak = false;
      rowNodeData.isColumnSkipped = false;
    }
    if (
      (editedColumn === 'maxAvailableUnitsConfirmed' ||
        editedColumn === 'shortHorizonUnitsConfirmed') &&
      rowNodeData.isColumnSkipped
    ) {
      //nothing
    } else if (
      (editedColumn === 'approvedMaxCapacity' ||
        editedColumn === 'approvedRevisedCapacity') &&
      ToCheckFactorySkip &&
      rowNodeData.isColumnSkipped
    ) {
      //nothing
    } else {
      // const diff = 100 * Math.abs((fromComp - toComp) / ((fromComp + toComp) / 2));
      let toCompNum =
        toComp === 0 ||
        toComp === '0' ||
        (toComp && toComp.toString().length > 0)
          ? Number(toComp)
          : '';
      let diff = ((Number(fromComp) - toCompNum) / toCompNum) * 100;
      if (diff === Infinity) {
        diff = 100;
      }
      // const diffValue = Math.round((diff + Number.EPSILON) * 100) / 100;
      const diffValue = diff;
      rowNodeData.inThreshold = null;
      const thresholdMax =
        defaultStatus === 'MaxAvailable'
          ? thresholds.strategicThresholdIncrease
          : thresholds.revisedThresholdIncrease;
      const thresholdMin =
        defaultStatus === 'MaxAvailable'
          ? thresholds.strategicThresholdDecrease
          : thresholds.revisedThresholdDecrease;
      if (
        (diffValue < 0 && Math.abs(diffValue) > thresholdMin) ||
        (diffValue > 0 && Math.abs(diffValue) > thresholdMax)
      ) {
        const isExist = rowNodeData.inThresholdExceedCols.includes(
          editedColumn
        );
        if (!isExist) {
          rowNodeData.inThresholdExceedCols.push(editedColumn);
        }
        rowNodeData.isThresholdExceed = true;
        rowNodeData.inThreshold = diffValue;
      } else {
        rowNodeData.isThresholdExceed = false;
        rowNodeData.inThreshold = 0;
        rowNodeData.inThresholdExceedCols = [];
      }
    }
  }
  return rowNodeData;
};

export const onConsoleArr = arr => {
  arr.forEach((ai, i) => {
    const editableColumns = JSON.parse(
      JSON.stringify(columnConstants.editableFields4AllUsers)
    );
    editableColumns.push('maxAvailableEditedBy');
    editableColumns.push('shortHorizonEditedBy');
    editableColumns.push('maxAvailableStatus');
    editableColumns.push('shortHorizonStatus');
  });
};

//quarterly grouped Data
export const quarterlyGroupedData = (data, defaultStatus) => {
  let formattedData = JSON.parse(JSON.stringify(data));
  let formattedNewData = [];
  let uniqueIdCount = 10001;
  const HolidayCols =
    defaultStatus === 'MaxAvailable'
      ? [...columnConstants.maxHolidayCols]
      : [...columnConstants.revHolidayCols];
  const gridStatusCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableStatus'
      : 'shortHorizonStatus';
  const noteCol =
    defaultStatus === 'MaxAvailable'
      ? 'maxAvailableNotes'
      : 'shortHorizonNotes';
  const authorColumns =
    defaultStatus === 'MaxAvailable'
      ? columnConstants.maxAvailableAuthorCols
      : columnConstants.shortHorizonAuthorCols;
  const cols2Add =
    defaultStatus === 'MaxAvailable'
      ? columnConstants.prodQuarterStrategicCols
      : columnConstants.prodQuarterRevisedCols;

  formattedData.forEach(dataItem => {
    let isFound = 0;
    if (formattedNewData.length > 0) {
      formattedNewData.forEach((fnItem, i) => {
        if (
          String(fnItem.productionQuarter) ===
            String(dataItem.productionQuarter) &&
          String(fnItem.capacityTypeCode) ===
            String(dataItem.capacityTypeCode) &&
          String(fnItem.factoryCode) === String(dataItem.factoryCode)
        ) {
          isFound = i + 1;
          if (!('totalWeeks' in fnItem)) {
            fnItem.totalWeeks = 2;
          } else {
            fnItem.totalWeeks += 1;
          }
          if (!(gridStatusCol in dataItem)) {
            fnItem[gridStatusCol] = '';
          }
          cols2Add.forEach(field => {
            //Object.keys(dataItem).forEach(field => {
            if (
              (columnConstants.numberEditableCols.includes(field) ||
                [
                  'workingDays',
                  'numberOfHolidays',
                  'weeklyCapacityQuantity',
                  'adjustedCapacityQuantity'
                ].includes(field)) &&
              !isNaN(parseInt(dataItem[field]))
            ) {
              //fnItem[field] += Number(dataItem[field]);
              if (fnItem[field]) {
                fnItem[field] = Number(fnItem[field]) + Number(dataItem[field]);
              } else {
                fnItem[field] = Number(dataItem[field]);
              }
            }

            if (HolidayCols.includes(field)) {
              const hC = holidayCalculator(field, dataItem);

              if (hC === 0 || hC === '0' || (hC && hC.toString().length > 0)) {
                if (!isNaN(fnItem[field])) {
                  const curVal = Number(fnItem[field]) + Number(hC);
                  fnItem[field] = curVal % 1 === 0 ? curVal : curVal.toFixed(2);
                } else {
                  fnItem[field] =
                    Number(hC) % 1 === 0 ? hC : Number(hC).toFixed(2);
                }
              }
            }

            //for status
            if (columnConstants.prodQStatusCols.includes(field)) {
              if (fnItem[field] !== dataItem[field]) {
                fnItem[field] = '';
              }
            }

            if (columnConstants.reasonCodeEditableCols.includes(field)) {
              let newD = dataItem[field] ? dataItem[field] : '';
              if (fnItem[field] !== newD) {
                fnItem[field] = 'MULTIPLE';
              }
            }

            //for logs
            if (field === 'logs' && dataItem.logs && dataItem.logs.length > 0) {
              const logArr = [];
              dataItem.logs.forEach((log, i) => {
                const newLog = { ...log };
                newLog.weekStartDate = dataItem.weekStartDate;

                logArr.push(newLog);
              });
              if (fnItem.logs && fnItem.logs.length > 0) {
                fnItem.logs = [...fnItem.logs, ...logArr];
              } else {
                fnItem.logs = logArr;
              }
            }
            if (
              field === 'logCount' &&
              dataItem.logCount &&
              dataItem.logCount !== 0
            ) {
              fnItem.logCount =
                Number(fnItem.logCount) + Number(dataItem.logCount);
            }

            if (
              field === 'maxAvailableNotesCount' &&
              dataItem.maxAvailableNotesCount &&
              dataItem.maxAvailableNotesCount !== 0
            ) {
              fnItem.maxAvailableNotesCount =
                Number(fnItem.maxAvailableNotesCount) +
                Number(dataItem.maxAvailableNotesCount);
            }

            if (
              field === 'shortHorizonNotesCount' &&
              dataItem.shortHorizonNotesCount &&
              dataItem.shortHorizonNotesCount !== 0
            ) {
              fnItem.shortHorizonNotesCount =
                Number(fnItem.shortHorizonNotesCount) +
                Number(dataItem.shortHorizonNotesCount);
            }

            if (
              field === 'maxAvailableNotesQuarterCount' &&
              dataItem.maxAvailableNotesQuarterCount
            ) {
              fnItem.maxAvailableNotesQuarterCount = Number(
                dataItem.maxAvailableNotesQuarterCount
              );
            }

            if (
              field === 'shortHorizonNotesQuarterCount' &&
              dataItem.shortHorizonNotesQuarterCount
            ) {
              fnItem.shortHorizonNotesQuarterCount = Number(
                dataItem.shortHorizonNotesQuarterCount
              );
            }

            if (field === 'ids' && dataItem.id !== undefined) {
              fnItem.ids.push(dataItem.id);
            }
            //for notes
            if (
              field === noteCol &&
              dataItem[noteCol] &&
              dataItem[noteCol].length > 0
            ) {
              dataItem[noteCol].forEach((note, i) => {
                if (
                  note.isQuarterly &&
                  !fnItem[noteCol].some(r => r.timestamp === note.timestamp)
                ) {
                  fnItem[noteCol].push(note);
                }
              });
            }
            //for authorColumns
            if (
              authorColumns.includes(field) &&
              fnItem[field] !== dataItem[field]
            ) {
              fnItem[field] = '';
            }
          });
        }
      });
    }

    if (formattedNewData.length === 0 || isFound === 0) {
      const nItem = {
        productionQuarter: dataItem.productionQuarter,
        factoryCode: dataItem.factoryCode,
        factoryDescription: dataItem.factoryDescription,
        capacityTypeDescription: dataItem.capacityTypeDescription,
        capacityTypeClass: dataItem.capacityTypeClass,
        weeklyCapacityQuantity: dataItem.weeklyCapacityQuantity,
        adjustedCapacityQuantity: dataItem.adjustedCapacityQuantity,
        capacityTypeCode: dataItem.capacityTypeCode,
        weekStartDate: dataItem.weekStartDate,
        weekEndDate: dataItem.weekEndDate,
        logs: [],
        workingDays: dataItem.workingDays,
        numberOfHolidays: dataItem.numberOfHolidays,
        uniQId: uniqueIdCount,
        totalWeeks: 1,
        id: `${dataItem.productionQuarter}-${dataItem.factoryCode}-${dataItem.capacityTypeCode}`,
        ids: [],
        maxAvailableNotesCount: 0,
        shortHorizonNotesCount: 0,
        maxAvailableNotesQuarterCount: Number(
          dataItem.maxAvailableNotesQuarterCount
        ),
        shortHorizonNotesQuarterCount: Number(
          dataItem.shortHorizonNotesQuarterCount
        ),
        logCount: 0,
        isStatusBreak: false,
        isThresholdExceed: false,
        inThresholdExceedCols: [],
        inThreshold: null,
        isColumnSkipped: false,
        inColumnSkipped: [],
        targetCpty: dataItem.targetCpty
      };

      cols2Add.forEach(i => {
        nItem[i] = '';
      });
      nItem[noteCol] = [];
      nItem['ids'] = [];
      Object.keys(dataItem).forEach(field => {
        //for editable capacities  numberEditableCols  and revised holidays
        if (
          columnConstants.numberEditableCols.includes(field) ||
          [
            'workingDays',
            'numberOfHolidays',
            'weeklyCapacityQuantity',
            'adjustedCapacityQuantity'
          ].includes(field)
        ) {
          const val = dataItem[field];
          if (val === 0 || val === '0' || (val && val.toString().length > 0)) {
            nItem[field] = Number(val);
          }
        }
        if (HolidayCols.includes(field)) {
          const hC = holidayCalculator(field, dataItem);
          if (hC === 0 || hC === '0' || (hC && hC.toString().length > 0)) {
            nItem[field] = Number(hC) % 1 === 0 ? hC : Number(hC).toFixed(2);
          }
        }

        //for notes notesEditableCols
        if (columnConstants.notesEditableCols.includes(field)) {
          nItem[field] = [];
        }
        //for reasonCode  reasonCodeEditableCols
        if (
          columnConstants.reasonCodeEditableCols.includes(field) ||
          columnConstants.prodQStatusCols.includes(field)
        ) {
          nItem[field] = dataItem[field];
        }

        //for logs
        if (field === 'logs' && dataItem.logs && dataItem.logs.length > 0) {
          const logArr = [];
          dataItem.logs.forEach((log, i) => {
            const newLog = { ...log };
            newLog.weekStartDate = dataItem.weekStartDate;
            logArr.push(newLog);
          });
          nItem.logs = logArr;
        }
        if (
          field === 'logCount' &&
          dataItem.logCount &&
          dataItem.logCount !== 0
        ) {
          nItem.logCount = nItem.logCount + dataItem.logCount;
        }

        if (
          field === 'maxAvailableNotesCount' &&
          dataItem.maxAvailableNotesCount &&
          dataItem.maxAvailableNotesCount !== 0
        ) {
          nItem.maxAvailableNotesCount =
            nItem.maxAvailableNotesCount + dataItem.maxAvailableNotesCount;
        }

        if (
          field === 'shortHorizonNotesCount' &&
          dataItem.shortHorizonNotesCount &&
          dataItem.shortHorizonNotesCount !== 0
        ) {
          nItem.shortHorizonNotesCount =
            nItem.shortHorizonNotesCount + dataItem.shortHorizonNotesCount;
        }

        if (
          field === 'maxAvailableNotesQuarterCount' &&
          dataItem.maxAvailableNotesQuarterCount
        ) {
          nItem.maxAvailableNotesQuarterCount =
            dataItem.maxAvailableNotesQuarterCount;
        }

        if (
          field === 'shortHorizonNotesQuarterCount' &&
          dataItem.shortHorizonNotesQuarterCount
        ) {
          nItem.shortHorizonNotesQuarterCount = dataItem.shortHorizonNotesCount;
        }

        if (field === 'id' && dataItem.id !== undefined) {
          nItem.ids.push(dataItem.id);
        }

        //for notes
        if (
          field === noteCol &&
          dataItem[noteCol] &&
          dataItem[noteCol].length > 0
        ) {
          dataItem[noteCol].forEach((note, i) => {
            if (note.isQuarterly) {
              nItem[noteCol].push(note);
            }
          });
        }

        //for authorColumns
        if (authorColumns.includes(field)) {
          nItem[field] = dataItem[field];
        }

        //for holidays and working days
      });

      formattedNewData.push(nItem);
      uniqueIdCount += 1;
    }
  });
  return formattedNewData;
};

export const prodQuarterCol2Update = (obj1, obj2) => {
  const arr = [];
  Object.keys(obj1).forEach(f => {
    if (
      columnConstants.prodQuarterColumn2Validate.includes(f) &&
      obj1[f] !== obj2[f]
    ) {
      arr.push(f);
    }
  });

  if (
    arr.includes('maxCapacityReasonCode') &&
    !arr.includes('FctyMaxCapacity')
  ) {
    arr.push('FctyMaxCapacity');
  }

  if (
    arr.includes('maxAvailableReasonCode') &&
    !arr.includes('maxAvailableCapacity')
  ) {
    arr.push('maxAvailableCapacity');
  }

  if (
    arr.includes('shortHorizonReasonCode') &&
    !arr.includes('shortHorizonCapacity')
  ) {
    arr.push('shortHorizonCapacity');
  }
  return arr;
};

export const prodQuarterCapacityUpdate = (
  totalCapacity,
  weekCount,
  totalWeek
) => {
  const arr = arrayGenerator(0, totalCapacity, totalWeek);
  return arr[weekCount];
};

export const splitInteger = (num, parts) => {
  let val;
  let mod = num % parts;
  let retData = [];
  if (mod === 0) {
    val = num / parts;
    retData = Array(parts).fill(val);
  } else {
    val = (num - mod) / parts;
    retData = Array(parts).fill(val);
    for (let i = 0; i < mod; i++) {
      retData[i] = retData[i] + 1;
    }
    retData.reverse();
  }

  return retData;
};

export const arrayGenerator = (type, num, parts) => {
  let arr = [];
  switch (type) {
    case 1:
      //equally divided in integer and reminders included only in last
      arr = Array.from({ length: parts }, (_, i) =>
        i === parts - 1
          ? Math.floor(num / parts) + Number(num % parts)
          : Math.floor(num / parts)
      );
      break;
    case 2:
      //equally divided in integer and reminders splitted between last few obj
      arr = Array.from(
        { length: parts },
        (_, i) => 0 | (i < num % parts ? num / parts + 1 : num / parts)
      );

      break;
    case 3:
      //equally divided in integer and reminders splitted between first few obj

      arr = Array.from(
        { length: parts },
        (_, i) => 0 | (i < num % parts ? num / parts + 1 : num / parts)
      ).reverse();

      break;
    case 4:
      //equally divided in integer and reminders splitted between first few obj

      arr = Array.from({ length: parts }, (_, i) =>
        (num / parts) % 1 === 0 ? num / parts : (num / parts).toFixed(2)
      );

      break;
    default:
      //equally divides with decimal if available
      arr = Array.from({ length: parts }, (_, i) => Math.round(num / parts));
  }

  return arr;
};

export const generateProdQuarterWeeks = (
  updatedQuarters,
  activeQuarterGrid,
  paramsForExcel,
  isExcel,
  defaultStatus
) => {
  const prodQuarterWeeks = [];

  if (updatedQuarters.length > 0) {
    updatedQuarters.forEach(prodQuarter => {
      const oldRow =
        activeQuarterGrid.find(old => old.uniQId === prodQuarter.uniQId) || {};
      let weekCount = 0;
      const col2Update = prodQuarterCol2Update(prodQuarter, oldRow);
      paramsForExcel.api.forEachNode(rowNode => {
        if (
          prodQuarter.productionQuarter === rowNode.data.productionQuarter &&
          prodQuarter.capacityTypeCode === rowNode.data.capacityTypeCode &&
          prodQuarter.factoryCode === rowNode.data.factoryCode
        ) {
          col2Update.forEach(field => {
            if (
              prodQuarter[field] === 0 ||
              prodQuarter[field] === '0' ||
              (prodQuarter[field] && prodQuarter[field].toString().length > 0)
            ) {
              if (columnConstants.numberEditableCols.includes(field)) {
                rowNode.data[field] = prodQuarterCapacityUpdate(
                  prodQuarter[field],
                  weekCount,
                  prodQuarter.totalWeeks
                );
              } else if (columnConstants.notesEditableCols.includes(field)) {
                // handle note uploads
              } else {
                rowNode.data[field] = prodQuarter[field];
              }
            }
          });
          weekCount += 1;
          prodQuarterWeeks.push({ ...rowNode.data });
        }
      });
    });
  }
  return prodQuarterWeeks;
};

export const generateWeeklyReport = (
  modifiedWeeklyRows,
  activeWeeklyRows,
  backUpWeeklyRows,
  userType,
  allWeeklyFields,
  reasonCodes,
  reasonCodesAll,
  defaultStatus,
  currentuseremail,
  IsExcel,
  maxColumnEditable,
  isProdView
) => {
  const report = [];
  modifiedWeeklyRows.forEach(weekRow => {
    const foundIndex = activeWeeklyRows.findIndex(i => i.id === weekRow.id);
    const foundIndBack = backUpWeeklyRows.findIndex(i => i.id === weekRow.id);
    const { result } = validateDataByRowOnlyOnUpload(
      weekRow,
      activeWeeklyRows[foundIndex],
      backUpWeeklyRows[foundIndBack],
      userType,
      reasonCodes,
      reasonCodesAll,
      defaultStatus,
      currentuseremail,
      IsExcel,
      maxColumnEditable,
      isProdView
    );
    result.forEach(log => {
      const logVal = log.value;
      if (
        columnConstants.notesEditableCols.includes(log.field) &&
        Array.isArray(logVal)
      ) {
        const newv = logVal.length > 0 ? logVal[logVal.length - 1] : undefined;
        if (newv && newv.comment) {
          log.value = newv.comment || undefined;
        }
      }

      log.row = weekRow.excelRow;
      log.fieldname = allWeeklyFields.find(i => i.field === log.field).name;
      log.factoryCode = weekRow.factoryCode;
      log.capacityTypeCode = weekRow.capacityTypeCode;
      log.weekStartDate = weekRow.weekStartDate
        ? moment(weekRow.weekStartDate).format('MM/DD/YYYY')
        : '';
      log.id = weekRow.id;
      report.push(log);
    });
  });
  return _.sortBy(report, 'row');
};

export const userUpdateSearchValidator = (searchGroups, searchParams) => {
  const searchStatus = { status: true, message: '' };
  let GroupAndCode = 0;
  searchGroups.forEach(gridItem => {
    if (gridItem.selected.length === 0) {
      GroupAndCode += 1;
    }
    if (GroupAndCode > 1) {
      searchStatus.status = false;
      searchStatus.message += msgConstants.invalidSearchInput;
    }
  });

  searchParams.forEach(gridItem => {
    if (gridItem.required && gridItem.value.length === 0) {
      searchStatus.status = false;
      searchStatus.message += ` ${gridItem.name} is mandatory. `;
    }
  });

  return searchStatus;
};

export const getDateRangeByQuarter = productionQuarter => {
  const pq = String(productionQuarter);
  const year = pq.substring(0, 4);
  let pQStartMonth = Number(pq.substring(4, 5)) * 3 - 3;
  let pQEndMonth = Number(pq.substring(4, 5)) * 3 - 1;
  let sdate = moment()
    .set('year', year)
    .set('month', pQStartMonth)
    .set('date', 1)
    .isoWeekday(8);
  if (sdate.date() > 7) {
    sdate = sdate.isoWeekday(-6);
  }
  let edate = moment()
    .set('year', year)
    .set('month', pQEndMonth)
    .set('date', 1)
    .endOf('month')
    .startOf('isoweek')
    .format('MM/DD/YYYY');
  return [sdate.format('MM/DD/YYYY'), edate];
};

export const getReviewOrConfirmedSkipped = productionQuarter => {
  //
  return true;
};

// setParamsUpdateByProps
export const getGbdAllLevers = (props, defaultStatus, userType) => {
  const gbdAllLevers = [];

  if (props) {
    if (props.columns) {
      props.columns.forEach(column => {
        if (
          column.headerName.startsWith('GBL - ') ||
          column.headerName.startsWith('Strat QOQ -') ||
          column.headerName.startsWith('Rvsd QOQ -') ||
          column.headerName.startsWith('Max QOQ -') ||
          column.headerName.startsWith('Cap QOQ -') ||
          column.headerName.startsWith('Target ')
        ) {
          if (column.children && column.children.length > 0) {
            //
            column.children.forEach(dimension => {
              if (dimension.children && dimension.children.length > 0) {
                dimension.children.forEach(lever => {
                  gbdAllLevers.push(lever.field);
                });
              }
            });
            //
          }
        }
      });
    }
  }
  return gbdAllLevers;
};

// setParamsUpdateByProps
export const getGbdGroupedColumns = (props, defaultStatus, userType) => {
  let GBDArray = [
    {
      header: 'GBL',
      Cap: [],
      Cost: [],
      ValidityDate: [],
      Comments: [],
      fieldStarts: 'GbdMax',
      varField: 'GbdMaxCapVariance',
      varSum: 0,
      varVal: 0,
      ArrOld: [],
      ArrNew: []
    },
    {
      header: 'Strat QOQ',
      Cap: [],
      Cost: [],
      ValidityDate: [],
      Comments: [],
      fieldStarts: 'StrQMax',
      varField: 'StrQMaxCapVariance',
      varSum: 0,
      varVal: 0,
      ArrOld: [],
      ArrNew: []
    },
    {
      header: 'Rvsd QOQ',
      Cap: [],
      Cost: [],
      ValidityDate: [],
      Comments: [],
      fieldStarts: 'RvsdQMax',
      varField: 'RvsdQMaxCapVariance',
      varSum: 0,
      varVal: 0,
      ArrOld: [],
      ArrNew: []
    },
    {
      header: 'Max QOQ',
      Cap: [],
      Cost: [],
      ValidityDate: [],
      Comments: [],
      fieldStarts: 'MaxQMax',
      varField: 'MaxQMaxCapVariance',
      varSum: 0,
      varVal: 0,
      ArrOld: [],
      ArrNew: []
    },
    {
      header: 'Cap QOQ',
      Cap: [],
      Cost: [],
      ValidityDate: [],
      Comments: [],
      fieldStarts: 'CapQMax',
      varField: 'CapQMaxCapVariance',
      varSum: 0,
      varVal: 0,
      ArrOld: [],
      ArrNew: []
    }
  ];
  if (props) {
    if (props.columns) {
      props.columns.forEach(column => {
        GBDArray.forEach(data => {
          //if () {
          if (
            column.headerName.startsWith(data.header) &&
            // column.field.startsWith(data.fieldStarts) &&
            column.children &&
            column.children.length > 0
          ) {
            column.children.forEach(dimension => {
              if (dimension.children && dimension.children.length > 0) {
                dimension.children.forEach(lever => {
                  if (lever.field.endsWith('Cap')) {
                    data.Cap.push(lever.field);
                  } else if (lever.field.endsWith('Cost')) {
                    data.Cost.push(lever.field);
                  } else if (lever.field.endsWith('ValidityDate')) {
                    data.ValidityDate.push(lever.field);
                  }
                  if (lever.field.endsWith('Comments')) {
                    data.Comments.push(lever.field);
                  }
                });
              }
            });
          }
        });
      });
    }
  }
  return GBDArray;
};

export const gbdVarianceGen = (field, data) => {
  const {
    workingDays,
    numberOfHolidays,
    RvsdQMaxCapVariance,
    GbdMaxCapVariance,
    StrQMaxCapVariance,
    MaxQMaxCapVariance,
    targetCpty,
    targetHldCpty,
    CapQMaxCapVariance
  } = data;
  let retVal = '';
  const workingDayCount = workingDays ? Number(workingDays) : 0;
  const holidays = numberOfHolidays ? Number(numberOfHolidays) : 0;
  const availableWorkingDays = workingDayCount - holidays;
  let field2calculate = '';
  if (workingDays !== 0) {
    switch (field) {
      case 'GbdMaxCapAdjVariance':
        field2calculate = GbdMaxCapVariance;
        break;
      case 'StrQMaxCapAdjVariance':
        field2calculate = StrQMaxCapVariance;
        break;
      case 'MaxQMaxCapAdjVariance':
        field2calculate = MaxQMaxCapVariance;
        break;
      case 'RvsdQMaxCapAdjVariance':
        field2calculate = RvsdQMaxCapVariance;
        break;
      case 'CapQMaxCapAdjVariance':
        field2calculate = CapQMaxCapVariance;
        break;
      case 'targetHldCpty':
        field2calculate = targetCpty;
        break;
      case 'targetCpty':
        field2calculate = targetHldCpty;
        break;
      default:
        field2calculate = '';
    }
    if (
      field2calculate === 0 ||
      field2calculate === '0' ||
      (field2calculate && Number(field2calculate) !== 0)
    ) {
      if (field === 'targetCpty') {
        retVal = (field2calculate / availableWorkingDays) * workingDays;
        return Math.round(retVal);
      } else {
        retVal = (field2calculate / workingDays) * availableWorkingDays;
      }
      if (retVal % 1 !== 0) {
        retVal = Number((Math.round(retVal * 100) / 100).toFixed(2)); // retVal.toFixed(1);
      }
    }
  }
  return retVal;
};
//quarterly grouped Data
export const quarterlyGroupedGBDData = (
  data,
  defaultStatus,
  getGbdGroupedColumns,
  actualStartWeekDate
) => {
  let formattedData = JSON.parse(JSON.stringify(data)); //let formattedData = [...data];
  let quarterRec = [];
  let uniqueIdCount = 10001;
  // const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
  // const userFactory = JSON.parse(userFacList);
  // const divisionCode = divisionCodes[userFactory.factoryType];

  const PmdColList = [
    'weeklyCapacityQuantity',
    'adjustedCapacityQuantity',
    'weeklyRevisedCapacityQuantity',
    'workingDays',
    'numberOfHolidays',
    'FctyStratHldCpty',
    'FctyMaxHldCpty',
    'FctyRvsdHldCpty',
    'targetCpty',
    'targetHldCpty',
    'FctyMaxCapacity',
    'maxAvailableCapacity',
    'FctyStartHldCpty',
    'shortHorizonCapacity'
  ];

  const AdjPMDList = ['FctyRvsdHldCpty', 'FctyStratHldCpty', 'FctyMaxHldCpty'];

  const FCPArray = [
    'factoryCode',
    'capacityTypeCode',
    'productionQuarter'
  ]; /**Convert String number to number */

  formattedData.forEach(dataItem => {
    FCPArray.forEach(FCPColumn => {
      dataItem[FCPColumn] = isNaN(dataItem[FCPColumn])
        ? dataItem[FCPColumn]
        : Number(dataItem[FCPColumn]);
    });
    AdjPMDList.forEach(columnName => {
      dataItem[columnName] = holidayCalculator(columnName, dataItem);
    });

    let isFound = 0;
    if (quarterRec.length > 0) {
      quarterRec.forEach((fnItem, i) => {
        if (
          String(fnItem.productionQuarter) ===
            String(dataItem.productionQuarter) &&
          String(fnItem.capacityTypeCode) ===
            String(dataItem.capacityTypeCode) &&
          String(fnItem.factoryCode) === String(dataItem.factoryCode)
        ) {
          isFound = i + 1;
          if (!('totalWeeks' in fnItem)) {
            fnItem.totalWeeks = 2;
          } else {
            fnItem.totalWeeks += 1;
          }
          PmdColList.forEach(i => {
            fnItem[i] =
              dataItem[i] || dataItem[i] === 0
                ? Number(dataItem[i]) + Number(fnItem[i])
                : fnItem[i];
          });
          getGbdGroupedColumns.forEach(record => {
            Object.keys(record).forEach(types => {
              if (['Cap', 'Cost', 'ValidityDate', 'Comments'].includes(types)) {
                record[types].forEach(i => {
                  if (types === 'Cap' || types === 'Cost') {
                    fnItem[i] = dataItem[i]
                      ? Number(dataItem[i]) + Number(fnItem[i])
                      : fnItem[i];
                    if (
                      dataItem[i] === 0 ||
                      (dataItem[i] &&
                        dataItem[i].toString().length > 0 &&
                        !isNaN(dataItem[i]))
                    ) {
                      fnItem[i] = Number(dataItem[i]);
                    }
                  } else if (
                    (types === 'ValidityDate' || types === 'Comments') &&
                    dataItem[i]
                  ) {
                    fnItem[i] = dataItem[i];
                  }
                });
              }
            });
          });
          fnItem.wid = dataItem.id;
          fnItem.ids.push(dataItem.id);
          // Partial Quarter and Last Revised Quarter Logic
          if (dataItem.revisedLastQuarter) {
            fnItem.revisedLastQuarter = dataItem.revisedLastQuarter;
          }
          if (dataItem.partialQuarter) {
            fnItem.partialQuarter = dataItem.partialQuarter;
          }
        }
      });
    }

    if (quarterRec.length === 0 || isFound === 0) {
      const nItem = {
        productionQuarter: dataItem.productionQuarter,
        factoryCode: dataItem.factoryCode,
        capacityTypeCode: dataItem.capacityTypeCode,
        factoryDescription: dataItem.factoryDescription,
        capacityTypeDescription: dataItem.capacityTypeDescription,
        capacityTypeClass: dataItem.capacityTypeClass,
        weekStartDate: dataItem.weekStartDate,
        weekEndDate: dataItem.weekEndDate,
        divisionCode: dataItem.divisionCode,
        uniQId: uniqueIdCount,
        totalWeeks: 1,
        // id: dataItem.id,
        id: `${dataItem.productionQuarter}-${dataItem.factoryCode}-${dataItem.capacityTypeCode}`,
        ids: [dataItem.id],
        revisedLastQuarter: dataItem.revisedLastQuarter
      };

      const tempPmd = [
        'weeklyCapacityQuantity',
        'adjustedCapacityQuantity',
        'workingDays',
        'numberOfHolidays'
      ]; /** TO do them 0 insted of empty string */

      PmdColList.forEach(i => {
        nItem[i] = dataItem[i]
          ? Number(dataItem[i])
          : tempPmd.includes(i)
          ? 0
          : '';
      });
      getGbdGroupedColumns.forEach(record => {
        Object.keys(record).forEach(types => {
          if (['Cap', 'Cost', 'ValidityDate', 'Comments'].includes(types)) {
            record[types].forEach(i => {
              if ((types === 'Cap' || types === 'Cost') && dataItem[i]) {
                if (
                  dataItem[i] === 0 ||
                  (dataItem[i] &&
                    dataItem[i].toString().length > 0 &&
                    !isNaN(dataItem[i]))
                ) {
                  nItem[i] = Number(dataItem[i]);
                }
              } else if (
                (types === 'ValidityDate' || types === 'Comments') &&
                dataItem[i]
              ) {
                nItem[i] = dataItem[i];
              }
            });
          }
        });
      });
      nItem['ids'] = [];
      nItem.ids.push(dataItem.id);

      quarterRec.push(nItem);
      uniqueIdCount += 1;
      // Partial Quarter and Last Revised Quarter Logic
      if (dataItem.revisedLastQuarter) {
        nItem.revisedLastQuarter = dataItem.revisedLastQuarter;
      }
      if (dataItem.partialQuarter) {
        nItem.partialQuarter = dataItem.partialQuarter;
      }
    }
  });

  const newADJ = [...PmdColList, ...AdjPMDList];
  const newQuartters = [...quarterRec];
  newQuartters.forEach((data, index) => {
    try {
      // create variance Part
      const {
        FctyMaxCapacity,
        weeklyRevisedCapacityQuantity,
        weeklyCapacityQuantity,
        partialQuarter
      } = data;

      newADJ.forEach(field => {
        const df = data[field];
        if (df && typeof df === 'number') {
          data[field] = df % 1 === 0 ? df : df.toFixed(2);
        }
      });
      // if Current Quarter is Not Partial
      if (!partialQuarter) {
        //MaxCapVar
        let maxHorVarCap = weeklyCapacityQuantity;
        if (
          defaultStatus !== 'MaxAvailable' &&
          (weeklyRevisedCapacityQuantity || weeklyRevisedCapacityQuantity === 0)
        ) {
          maxHorVarCap = weeklyRevisedCapacityQuantity;
        }
        if (
          (FctyMaxCapacity || FctyMaxCapacity === 0) &&
          (maxHorVarCap || maxHorVarCap === 0)
        ) {
          data.GbdMaxCapVariance =
            Number(FctyMaxCapacity) - Number(maxHorVarCap);
        }
      }

      const prevRec = quarterRec[index - 1] || {};
      //For QOQ Comparing Different Quarter with Different Factory code and Capacity Type code
      if (
        data.capacityTypeCode === prevRec.capacityTypeCode &&
        data.factoryCode === prevRec.factoryCode
      ) {
        data.partialQuarterPrev = prevRec.partialQuarter;
        // Do QoQ Variance Calculation only if both current and previous quarter is not partial
        if (
          prevRec.partialQuarter !== 'readOnly' &&
          partialQuarter !== 'readOnly'
        ) {
          // for CapQoQ
          if (defaultStatus === 'MaxAvailable') {
            // in Strategic use maxAvailableCapacity only but there is chance prev rec is from reviesed quarter
            data.CapQMaxCapVariance =
              weeklyCapacityQuantity - prevRec.weeklyCapacityQuantity;
          } else {
            //in revised view use weeklyRevisedCapacityQuantity if available else use weeklyCapacityQuantity
            let rvsdCurrCap;
            if (
              weeklyRevisedCapacityQuantity ||
              weeklyRevisedCapacityQuantity === 0
            ) {
              rvsdCurrCap = weeklyRevisedCapacityQuantity;
            } else {
              rvsdCurrCap = weeklyCapacityQuantity;
            }
            let rvsdPrevCap;
            if (
              prevRec.weeklyRevisedCapacityQuantity ||
              prevRec.weeklyRevisedCapacityQuantity === 0
            ) {
              rvsdPrevCap = prevRec.weeklyRevisedCapacityQuantity;
            } else {
              rvsdPrevCap = prevRec.weeklyCapacityQuantity;
            }
            if (
              (rvsdPrevCap || rvsdPrevCap === 0) &&
              (rvsdCurrCap || rvsdCurrCap === 0)
            ) {
              data.CapQMaxCapVariance = rvsdCurrCap - rvsdPrevCap;
            }
          }

          // for StrQ Outdated and not used and displayed, just keeping for reference and should get removed somepoint
          if (Object.keys(prevRec).length > 0) {
            if (
              (prevRec.weeklyCapacityQuantity ||
                prevRec.weeklyCapacityQuantity === 0) &&
              (weeklyCapacityQuantity || weeklyCapacityQuantity === 0)
            ) {
              data.StrQMaxCapVariance =
                weeklyCapacityQuantity - prevRec.weeklyCapacityQuantity;
            }
            // for Rvsd  Outdated and not used and displayed, just keeping for reference and should get removed somepoint
            if (
              (prevRec.weeklyRevisedCapacityQuantity ||
                prevRec.weeklyRevisedCapacityQuantity === 0) &&
              (weeklyRevisedCapacityQuantity ||
                weeklyRevisedCapacityQuantity === 0)
            ) {
              data.RvsdQMaxCapVariance =
                weeklyRevisedCapacityQuantity -
                prevRec.weeklyRevisedCapacityQuantity;
            }

            // for MaxQ used
            if (
              (prevRec.FctyMaxCapacity || prevRec.FctyMaxCapacity === 0) &&
              (FctyMaxCapacity || FctyMaxCapacity === 0)
            ) {
              data.MaxQMaxCapVariance =
                FctyMaxCapacity - prevRec.FctyMaxCapacity;
            }
          }
        }
      }

      // ADJ
      const AdjGBDList = [
        { adjSrc: 'GbdMaxCapVariance', adjDest: 'GbdMaxCapAdjVariance' },
        { adjSrc: 'StrQMaxCapVariance', adjDest: 'StrQMaxCapAdjVariance' },
        { adjSrc: 'RvsdQMaxCapVariance', adjDest: 'RvsdQMaxCapAdjVariance' },
        { adjSrc: 'MaxQMaxCapVariance', adjDest: 'MaxQMaxCapAdjVariance' },
        { adjSrc: 'CapQMaxCapVariance', adjDest: 'CapQMaxCapAdjVariance' }
      ];

      AdjGBDList.forEach(adjField => {
        const { adjSrc, adjDest } = adjField;
        if (data[adjSrc]) {
          data[adjDest] = gbdVarianceGen(adjDest, data);
        } else if (data[adjSrc] === 0) {
          data[adjDest] = 0;
        }
      });
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }
  });
  let filterdQuarters = [];
  let filterdQuarters1 = [];
  if (actualStartWeekDate && !isNaN(actualStartWeekDate)) {
    filterdQuarters1 = newQuartters.filter(
      q => Number(q.productionQuarter) >= Number(actualStartWeekDate)
    );
  } else {
    filterdQuarters1 = newQuartters;
  }
  if (defaultStatus === 'MaxAvailable') {
    filterdQuarters = filterdQuarters1.filter(value => {
      return !value.revisedLastQuarter || value.revisedLastQuarter === '';
    });
  } else {
    filterdQuarters = filterdQuarters1;
  }
  return filterdQuarters;
};

export const generateGbDWeekRecordsFromQuarter = (
  updatedQuarters,
  gbdWeeklyData,
  gbdAllLevers
) => {
  const gbdWeeklyList = [];
  if (updatedQuarters.length > 0) {
    updatedQuarters.forEach(prodQuarter => {
      let weekCount = 0;
      gbdWeeklyData.forEach(weekItem => {
        const newItem = { ...weekItem };
        if (
          prodQuarter.productionQuarter === weekItem.productionQuarter &&
          prodQuarter.capacityTypeCode === weekItem.capacityTypeCode &&
          prodQuarter.factoryCode === weekItem.factoryCode
        ) {
          weekCount += 1;
          gbdAllLevers.forEach(i => {
            if (prodQuarter[i]) {
              newItem[i] = prodQuarter[i] || '';
            }
          });
          if (weekCount === prodQuarter.totalWeeks) {
            newItem.weekStartDate = newItem.weekStartDate
              ? moment(newItem.weekStartDate).format('MM/DD/YYYY')
              : '';
            gbdWeeklyList.push(newItem);
          }
        }
      });
    });
  }
  return gbdWeeklyList;
};

export const gbdExcelUploadRowCompare = (
  excelRow,
  inGridRow,
  colToValidate
) => {
  const newExcelrow = { ...excelRow };
  const newGridrow = { ...inGridRow };
  const excelRowFiltered = _.pick(newExcelrow, colToValidate);
  const inGridRowFiltered = _.pick(newGridrow, colToValidate);

  const cleanUpFun = v => v !== undefined && v !== '' && v !== null;
  const ExcelRowCleaned = _.pickBy(excelRowFiltered, cleanUpFun);
  const inGridRowCleaned = _.pickBy(inGridRowFiltered, cleanUpFun);

  return _.isEqual(ExcelRowCleaned, inGridRowCleaned) ? {} : excelRow;
};
