/* eslint-disable max-statements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import _ from 'lodash';
// import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Beforeunload } from 'react-beforeunload';
import { CustomExcelRenderer } from '../../services/dashboardService.js';
import { H1, PageError, Spinner, Button } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import UploadModal from '../../components/dashboard/uploadModal.js';
import UploadUpdateModal from '../../components/dashboard/UploadUpdateModal.js';
import {
  getDashboardDataLoading,
  getDashboardData,
  getDashboardError,
  getDashboardGridLoading,
  getDashboardGridColumns,
  getDashboardGridError,
  getusrFactGrpSearchData,
  getusrFactGrpSearchLoading,
  getusrFactGrpSearchError,
  getDashboardDataUpdate
} from '../../reducers/dashboardReducer.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { AppCipher, AppDeCipher } from '../../util/cipher.js';
import {
  agGridCustomFooter,
  agGridDownloadDataFormat,
  updateErrorReport,
  agGridDownloadFileName,
  validateAllDataOnUpload,
  calculationAfterUpload,
  frameDataOnUpload,
  updateUploadDataFormat,
  setParamsUpdateByProps,
  onConsoleArr,
  quarterlyGroupedData,
  generateProdQuarterWeeks,
  generateWeeklyReport
} from '../../services/dashboardService.js';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';
import { selectStatusButton } from '../../actions/dashboardActions';
import gridConstants from '../../constants/gridConstants.js';
import { msgConstants } from '../../constants/msgConstants.js';
import pageConstants from './Dashboard.constants.js';
import {
  getAppConfigData,
  getAppConfigLoading,
  getAppConfigError
} from '../../reducers/appConfigReducer.js';
const searchfirst = true;
let noSearchInitiated = true;
const maxDownloadLimit = 4000;
export class uploadView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsForExcel: {},
      userType: '',
      UploadModel: false,
      UploadUpdateModel: false,
      UploadUpdateModelType: 'Grid',
      UploadStatusData: [],
      UploadingData: false,
      context: { componentParent: this },
      defaultStatus: 'MaxAvailable',

      defaultColDef: gridConstants.defaultColDef,
      sideBar: gridConstants.sideBar,
      excelStyles: gridConstants.excelStyles,

      // eslint-disable-next-line react/no-unused-state
      uploadErrorState: [],
      gridPagination: {},
      changedRowsData: [],
      saveViewToogle: 0,
      gridCurrentPageData: [],
      gridColumnConfig: [],
      defaultSortAfterLoad: [
        {
          colId: 'factoryCode',
          sort: 'asc'
        },
        {
          colId: 'capacityTypeCode',
          sort: 'asc'
        }
      ],
      defaultPQSortAfterLoad: [
        {
          colId: 'factoryCode',
          sort: 'asc'
        },
        {
          colId: 'capacityTypeCode',
          sort: 'asc'
        },
        {
          colId: 'productionQuarter',
          sort: 'asc'
        }
      ],
      pageSearch: 0,
      reasonCodesAll: [],
      reasonCodes: [],
      maxColumnEditable: true,
      recentDownloadedGridFile: '',
      recentDownloadedGridFileId: '',
      updateCycleCompleteModel: false,
      updateCycleCompleteMsg: false,
      updateCycleReport: {},
      revisedHorizonWeeks: 0,
      displayView: 0,
      prodQuarterColumns: [],
      prodQuartersData: [],
      prodQuartersDataBackUp: [],
      agGridQuarterParams: {},
      isToggleOn: false,
      quarterRowDataChanged: [],
      quarterScoreCardCount: pageConstants.quarterScoreCardCount,
      quarterGridCurrentFilter: {}
    };

    this.getDashboardType = this.getDashboardType.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.saveDataChanges = this.saveDataChanges.bind(this);
    this.uploadProcess = this.uploadProcess.bind(this);
    this.uploadStatusUpdate = this.uploadStatusUpdate.bind(this);
    this.toggleUploadModel = this.toggleUploadModel.bind(this);
    this.toogleUploadUpdateModel = this.toogleUploadUpdateModel.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
  }

  componentDidMount() {
    // On page load, loading max available dashboard as default
    let defaultStatus = 'MaxAvailable';
    const { location, userProfileData } = this.props;
    const { pathname, search } = location;
    // checking url is to load max available or short horizon dashboard
    if (pathname && pathname.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    const queryString = window.location.search;
    if (
      queryString &&
      queryString.toString().length > 0 &&
      queryString.includes('?')
    ) {
      const queryObj = location.query;

      if (
        queryObj &&
        (queryObj.userNotify === true || queryObj.userNotify === 'true')
      ) {
        if (queryObj.productionQuarter) {
        }
        noSearchInitiated = false;
        this.setState({ userNotify: true, saveViewToogle: 0 });
      }
    }
    if (userProfileData) {
      this.setState({
        userType: userProfileData.userRole,
        userFactoryType: userProfileData.factoryType
      });
    }
    // Function to call API for Grid Data
    this.getDashboardType(defaultStatus);
    localStorage.setItem('isRowDataChanged', 'false');
    localStorage.setItem('isQuarterRowDataChanged', 'false');
    localStorage.setItem('isGBDQuarterRowDataChanged', 'false');

    if (
      (search && search.userNotify === true) ||
      search.userNotify === 'true'
    ) {
    } else {
      this.setState({ searchQuery: location.query }, () => {
        setTimeout(() => {
          this.onSearchByGlobalFilters();
        }, 100);
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    // On page load, loading max available dashboard as default
    let defaultStatus = 'MaxAvailable';
    const { UploadStatusData, userType, userFactoryType } = this.state;
    const {
      location,
      dashboardData,

      userProfileData,

      dashboardDataUpdate,
      clearDashboardData,
      clearUpdateDashboardData,
      appConfigData,
      dashboardGridColumns
    } = this.props;
    const { pathname, search } = location;
    const newPathName = nextProps.location.pathname;
    const oldPathName = pathname;

    if (newPathName && newPathName.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }

    if (search !== nextProps.location.search) {
      const nextPropSearch = nextProps.location.search;
      if (
        nextPropSearch.userNotify === true ||
        nextPropSearch.userNotify === 'true'
      ) {
      } else {
        this.setState({ searchQuery: nextProps.location.query }, () => {
          setTimeout(() => {
            this.onSearchByGlobalFilters();
          }, 100);
        });
      }
    }
    if (userProfileData !== nextProps.userProfileData) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        newUsrProData.factoryGroups = _.sortBy(
          newUsrProData.factoryGroups,
          'name'
        );

        this.setState({
          userFactoryType: newUsrProData.factoryType
        });
      } else {
      }
    }

    if (dashboardDataUpdate !== nextProps.dashboardDataUpdate) {
      const updateData = nextProps.dashboardDataUpdate;
      if (
        updateData &&
        (updateData.status === 201 ||
          (Array.isArray(updateData.status) && updateData.status.includes(201)))
      ) {
        let UploadStatusDataNew = [];
        if (updateData.outputData) {
          UploadStatusDataNew = updateErrorReport(
            updateData.outputData,
            UploadStatusData
          );
        }

        clearDashboardData({});
        clearUpdateDashboardData();
        this.setState({
          updateCycleCompleteModel: true,
          updateCycleCompleteMsg: true,
          UploadStatusData: UploadStatusDataNew
        });
      }
    }

    let rvsdHorzLocal = localStorage.getItem('revisedHorizonWeeks');
    let revisedHorizonWeeks = rvsdHorzLocal ? Number(rvsdHorzLocal) : 0;
    if (appConfigData && appConfigData.revisedHorizonWeeks) {
      revisedHorizonWeeks = appConfigData.revisedHorizonWeeks;
      this.setState({
        revisedHorizonWeeks: revisedHorizonWeeks
      });
    }
    if (appConfigData !== nextProps.appConfigData) {
      if (
        nextProps.appConfigData &&
        Object.keys(nextProps.appConfigData).length !== 0
      ) {
        if (nextProps.appConfigData.revisedHorizonWeeks) {
          revisedHorizonWeeks = nextProps.appConfigData.revisedHorizonWeeks;
          this.setState({
            revisedHorizonWeeks: revisedHorizonWeeks
          });
        }
      }
    }
    if (userProfileData !== nextProps.userProfileData) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        this.setState({
          userFactoryType: newUsrProData.factoryType
        });
      }
    }
    if (dashboardGridColumns !== nextProps.dashboardGridColumns) {
      if (
        nextProps.dashboardGridColumns &&
        nextProps.dashboardGridColumns.role
      ) {
        // PQ COL
        const {
          reasonCodes,
          reasonCodesAll,
          maxColumnEditable,
          prodQuarterColumns
        } = setParamsUpdateByProps(
          nextProps.dashboardGridColumns,
          defaultStatus,
          userType,
          userFactoryType
        );
        this.setState({
          reasonCodes,
          reasonCodesAll,
          maxColumnEditable,
          prodQuarterColumns: prodQuarterColumns,
          userType: nextProps.dashboardGridColumns.role
        });
      }
    }

    if (
      !_.isEqual(nextProps.dashboardData, dashboardData) &&
      nextProps.dashboardData.data
    ) {
      const prodQuartersData = quarterlyGroupedData(
        nextProps.dashboardData.data,
        defaultStatus
      );
      const prodQuartersDataBackUp = JSON.parse(
        JSON.stringify(prodQuartersData)
      );
      this.setState({
        prodQuartersData,
        prodQuartersDataBackUp,
        updateCycleCompleteMsg: false
      });
    }

    if (newPathName !== oldPathName) {
      noSearchInitiated = true;
      this.getDashboardType(defaultStatus);
    }
  }

  onSearchFilterParams(query) {
    const { revisedHorizonWeeks, gridPagination } = this.state;
    let qst = '';
    if (Object.keys(query).length > 0) {
      qst = Object.keys(query)
        .map(
          key =>
            `${key}=${
              ['startWeekDate', 'endWeekDate'].includes(key)
                ? query[key].replace('-', '/')
                : query[key]
            }`
        )
        .join('&');
    }
    if (qst && revisedHorizonWeeks) {
      qst += `${qst ? '&' : '?'}boundaryWeek=${revisedHorizonWeeks}`;
    }
    if (qst && maxDownloadLimit) {
      qst += `${qst ? '&' : '?'}count=${maxDownloadLimit}&anchor=1`;
    }
    if (Object.keys(gridPagination).length > 0) {
      const queryString = Object.keys(gridPagination)
        .map(key => `${key}=${gridPagination[key]}`)
        .join('&');
      qst += `${qst ? '&' : '?'}${queryString}`;
    }
    return qst;
  }

  onSearchByGlobalFilters() {
    // after selecting all required search params, retriving data
    this.setState({
      gridCurrentPageData: [],
      updateCycleComplete: false,
      isToggleOn: false
    });

    const {
      defaultStatus,
      userType,
      userFactoryType,
      searchQuery
    } = this.state;
    noSearchInitiated = false;

    const {
      requestDashboardData,
      requestDashboardGridColumns,
      currentusername,
      userProfileData
    } = this.props;

    if (userProfileData) {
      let newQuery = typeof searchQuery === 'object' ? { ...searchQuery } : {};
      if (newQuery && newQuery.factoryCode) {
        //good
      } else {
        let factoryCodes = [];
        if (searchQuery && searchQuery.factoryGroup) {
          const factoryGroups = userProfileData.association.find(
            i => i.factoryGroup === searchQuery.factoryGroup
          );
          const factoryies = [...factoryGroups.factories];
          const index = factoryies.indexOf('ALL');
          if (index > -1) {
            factoryies.splice(index, 1);
          }
          newQuery.factoryCode = factoryies.sort().join();
        } else {
          factoryCodes = [...userProfileData.factoryCodesArr] || [];
          const index = factoryCodes.indexOf('ALL');
          if (index > -1) {
            factoryCodes.splice(index, 1);
          }
          newQuery.factoryCode = factoryCodes.join();
        }
      }
      try {
        // to avoid the errors can be caused sometimes

        delete newQuery.factoryGroup;
        delete newQuery.qp;
      } catch (e) {
        // this will run only if the code in the try block errors-out
      }

      const queryString = this.onSearchFilterParams(newQuery);
      const getDashboardData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: queryString,
        type: 'bulk',
        factoryCode: newQuery.factoryCode,
        userFactoryType: userFactoryType
      };
      this.setState({
        loadGrid: true,
        paramsForExcel: {},
        changedRowsData: [],
        quarterRowDataChanged: []
      });
      localStorage.setItem('isRowDataChanged', 'false');
      localStorage.setItem('isQuarterRowDataChanged', 'false');

      requestDashboardGridColumns(getDashboardData);
      requestDashboardData(getDashboardData); // API  Request function for Grid Data
    }
  }

  getDashboardType(defaultStatus) {
    // Function call on page load and reload, to initate API request for Grid Header and Data

    const {
      requestDashboardData,
      requestDashboardGridColumns,
      currentusername,
      currentusergroups,
      clearDashboardData
    } = this.props;
    clearDashboardData({});
    noSearchInitiated = true;
    this.setState({ gridColumnConfig: [], pageSearch: 0 });
    // eslint-disable-next-line prefer-const
    let { userType } = this.state;
    const sessionUserType = window.sessionStorage.getItem('currentUserGroup');
    if (sessionUserType) {
      userType = sessionUserType;
    } else {
      userType = currentusergroups[0].value;
    }
    // setting the factory codes for the default dropdown in serch box

    // end of update
    this.setState({ defaultStatus, userType }); // set new dashboard type
    // condition check to load the entire grid data at first itself or to load only after seach with user inputs
    if (!searchfirst) {
      const { searchBoxFields } = this.state;
      const getDashboardData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchBoxFields,
        type: 'bulk'
      };
      requestDashboardGridColumns(getDashboardData);
      requestDashboardData(getDashboardData); // API  Request function for Grid Data
      localStorage.setItem('isRowDataChanged', 'false');
    }
    /* */
  }

  setParams = params => {
    // Function called post GRID initial load to reassure column structure and fields
    const { gridCurrentPageData, defaultSortAfterLoad } = this.state;

    if (params) {
      this.setState({ paramsForExcel: params, loadGrid: false }); // update AG Grid Properties to state
      if (params.columnApi.getAllColumns()) {
        if (gridCurrentPageData.length === 0) {
          const AllGridData = []; // current grid

          params.api.forEachNode(node => AllGridData.push(node.data));
          this.setState({
            gridCurrentPageData: JSON.parse(JSON.stringify(AllGridData))
          });
        }

        try {
          // to avoid the errors can be caused sometimes
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel(); // AG Grid closed the side panel which will be open by default
          params.api.setSortModel(defaultSortAfterLoad);
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  saveDataChanges = () => {
    // Function to call Data Save API to update changes made in AG grid data, either by editing or by uploading
    // this method is currently disabled as initially on user completes all the changes user has to click save button and to call API to update
    // now on each row updated, immediately API call will be initiated
    const { updateDashboardData } = this.props;
    const {
      defaultStatus,
      changedRowsData,
      paramsForExcel,
      gridCurrentPageData,
      isToggleOn
    } = this.state;

    // if (!changedRowsData && changedRowsData.length === 0) return false;
    const modifiedUploadData = updateUploadDataFormat(
      changedRowsData,
      gridCurrentPageData,
      defaultStatus,
      isToggleOn
    );
    let validatedUploadData = [];
    if (modifiedUploadData.length === 0) {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.noChangeUpdate
      };
      this.uploadStatusUpdate(commentsUpdateStatus);
      this.setState({ changedRowsData: [] });
    } else {
      const stat = [];
      const CurrentGridData1 = [];
      paramsForExcel.api.forEachNodeAfterFilterAndSort(node =>
        CurrentGridData1.push(node.data)
      );
      const AllColNames = [];
      const getAllColumns = paramsForExcel.columnApi.getAllColumns();
      getAllColumns.forEach(item => {
        const itemis = {
          name: item.colDef.headerName,
          code: item.colId,
          field: item.colDef.field
        };
        AllColNames.push(itemis);
      });

      modifiedUploadData.forEach(scItem => {
        validatedUploadData.push(scItem);
      });
      if (stat.length > 0) {
        this.setState({
          UploadStatusData: _.sortBy(stat, 'row'),
          UploadUpdateModel: true,
          changedRowsData: [],
          UploadUpdateModelType: 'Grid'
        });
      }
      //const updateData = maxAvailableCapacity
      if (validatedUploadData.length > 0) {
        const AllGridData = {
          dashboard: defaultStatus,
          data: validatedUploadData,
          type: 'row Update'
        }; // current grid
        updateDashboardData(AllGridData);
        const AllGridDataCopy = [];
        paramsForExcel.api.forEachNode(node => AllGridDataCopy.push(node.data));
        this.setState({
          changedRowsData: [],
          gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
        });

        localStorage.setItem('isRowDataChanged', 'false');
      } else {
        this.setState({
          updateCycleCompleteModel: true
        });
        const uploadStatus = {
          status: false,
          message: msgConstants.noChangeUpdate
        };
        this.uploadStatusUpdate(uploadStatus);
      }
    }
  };

  downloadData = () => {
    // Function to download the current grid data to excel sheet

    // to apply custom footer which will have to drop down values for short horizon and status
    const {
      defaultStatus,
      userType,
      paramsForExcel,
      isToggleOn,
      agGridQuarterParams
    } = this.state;
    const { currentusername } = this.props;

    const fileInfo = agGridDownloadFileName(
      defaultStatus,
      userType,
      'bulk',
      currentusername,
      isToggleOn
    );

    this.setState({
      recentDownloadedGridFile: fileInfo.fileName,
      recentDownloadedGridFileId: fileInfo.fileID
    });

    toast.success(msgConstants.excelDownloadSuccess, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });

    const params = {
      fileName: fileInfo.fileName,
      sheetName: fileInfo.sheetName,
      processCellCallback(paramsl) {
        // data format change for excel
        return agGridDownloadDataFormat(paramsl);
      }
    };
    // for custom header
    const getAllColumns = !isToggleOn
      ? paramsForExcel.columnApi.getAllColumns()
      : agGridQuarterParams.columnApi.getAllColumns();
    let customHeader = JSON.parse(JSON.stringify(pageConstants.excelHeader));
    customHeader[0][1].data.value = AppCipher(fileInfo.fileID);
    params.customHeader = customHeader;

    if (userType === 'FACTORY') {
      params.customFooter = agGridCustomFooter(getAllColumns);
    }
    !isToggleOn
      ? paramsForExcel.api.exportDataAsExcel(params)
      : agGridQuarterParams.api.exportDataAsExcel(params);
  };

  toggleUploadModel = () => {
    // toggles the upload dialog
    const { UploadModel } = this.state;
    this.setState({ UploadModel: !UploadModel });
  };
  toogleUploadUpdateModel = () => {
    // toggles the upload dialog
    let { UploadStatusData, validatedUploadPQResult } = this.state;
    const { UploadUpdateModel, paramsForExcel } = this.state;
    if (UploadUpdateModel) {
      const AllGridDataCopy = [];
      paramsForExcel.api.forEachNode(node => AllGridDataCopy.push(node.data));
      this.setState({
        gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
      });
      UploadStatusData = [];
      validatedUploadPQResult = [];
    }
    this.setState({
      UploadUpdateModel: !UploadUpdateModel,
      UploadStatusData,
      validatedUploadPQResult
    });
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      this.setState({
        editGrid: true,
        UploadingData: false,
        UploadModel: false
      });
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  uploadProcess = data => {
    let jsonDataRaw = data;
    let jsonDataMapped = [];
    let jsonDataCalculated = [];
    const {
      paramsForExcel,
      agGridQuarterParams,
      defaultStatus,
      userType,
      reasonCodes,
      reasonCodesAll,
      gridCurrentPageData,
      maxColumnEditable,
      isToggleOn,
      prodQuartersDataBackUp,
      userFactoryType
    } = this.state;
    const {
      updateDashboardData,
      currentusername,
      currentuseremail
    } = this.props;

    const uploadStatus = {
      status: false,
      message: msgConstants.uploadFailNoData
    };
    const activeGridSet = [];
    (isToggleOn ? agGridQuarterParams : paramsForExcel).api.forEachNode(node =>
      activeGridSet.push(node.data)
    );

    const gridColumns = (isToggleOn
      ? agGridQuarterParams
      : paramsForExcel
    ).columnApi.getAllColumns();

    // validating the ray array and updating the columns structure
    if (jsonDataRaw.length > 0) {
      jsonDataMapped = frameDataOnUpload(
        jsonDataRaw,
        activeGridSet.length,
        gridColumns,
        pageConstants.excelHeader.length
      );
      jsonDataRaw = [];
      // validating the mapped columns and updating only the editable columns
      if (jsonDataMapped.length > 0) {
        const { activeGrid, validationReport } = validateAllDataOnUpload(
          jsonDataMapped,
          activeGridSet,
          reasonCodes,
          reasonCodesAll,
          userType,
          defaultStatus,
          gridColumns,
          currentuseremail,
          maxColumnEditable,
          isToggleOn,
          userFactoryType
        );
        jsonDataMapped = [];
        // assigning the new data to the grid in dashboard
        if (activeGrid.length > 0) {
          jsonDataCalculated = calculationAfterUpload(
            activeGrid,
            userType,
            currentusername,
            defaultStatus,
            isToggleOn
          );

          if (jsonDataCalculated.length > 0) {
            (isToggleOn ? agGridQuarterParams : paramsForExcel).api.setRowData(
              jsonDataCalculated
            );
            // send data only for api
            let dataToUploadApi = [];

            if (isToggleOn) {
              // distribute quarterly aggregated values into corresponding weeks.
              const updatedQuarters = jsonDataCalculated.filter(
                quarter => quarter.ChangeByUpload > 0
              );
              dataToUploadApi = generateProdQuarterWeeks(
                updatedQuarters,
                prodQuartersDataBackUp,
                paramsForExcel,
                false,
                defaultStatus,
                userFactoryType
              );

              const activeWeeklyRows = [];
              paramsForExcel.api.forEachNode(node =>
                activeWeeklyRows.push(node.data)
              );
              const allWeeklyColumns = paramsForExcel.columnApi.getAllColumns();
              const allWeeklyFields = allWeeklyColumns.map(item => {
                return {
                  name: item.colDef.headerName,
                  code: item.colId,
                  field: item.colDef.field
                };
              });

              const weeklyReport = generateWeeklyReport(
                dataToUploadApi,
                activeWeeklyRows,
                gridCurrentPageData,
                userType,
                allWeeklyFields,
                reasonCodes,
                reasonCodesAll,
                defaultStatus,
                currentuseremail,
                true,
                maxColumnEditable,
                isToggleOn,
                userFactoryType
              );
              if (validationReport.length > 0) {
                this.setState({
                  UploadStatusData: weeklyReport,
                  validatedUploadPQResult: validationReport,
                  UploadUpdateModel: true,
                  UploadUpdateModelType: 'Excel'
                });
              }
            } else {
              dataToUploadApi = jsonDataCalculated.filter(
                quarter => quarter.ChangeByUpload > 0
              );
              if (validationReport.length > 0) {
                this.setState({
                  UploadStatusData: validationReport,
                  UploadUpdateModel: true,
                  UploadUpdateModelType: 'Excel'
                });
              }
            }

            /* uploadStatus.status = true;
           uploadStatus.message = validationReport.toString();
           uploadStatus.autoClose = 10000; */

            // this.uploadStatusUpdate(uploadStatus);
            if (dataToUploadApi.length > 0) {
              onConsoleArr(dataToUploadApi);
              const modifiedUploadData = updateUploadDataFormat(
                dataToUploadApi,
                gridCurrentPageData,
                defaultStatus,
                isToggleOn
              );

              let AllGridData = {
                dashboard: defaultStatus,
                data: modifiedUploadData,
                type: 'file Update'
              };
              updateDashboardData(AllGridData);
            } else {
              this.setState({
                updateCycleCompleteModel: true
              });
              if (validationReport.length) {
                uploadStatus.message = msgConstants.uploadFailNoMatchData;
              }
              this.uploadStatusUpdate(uploadStatus);
            }
          } else {
            this.uploadStatusUpdate(uploadStatus);
          }
        } else {
          this.uploadStatusUpdate(uploadStatus);
        }
      } else {
        uploadStatus.message = msgConstants.uploadFailNoMatchData;
        this.uploadStatusUpdate(uploadStatus);
      }
    } else {
      uploadStatus.message = msgConstants.uploadFailFetchErr;
      this.uploadStatusUpdate(uploadStatus);
    }
    this.setState({ UploadingData: false, UploadModel: false });
  };

  fileHandler = event => {
    if (event.target.files.length) {
      this.setState({ UploadingData: true });
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.fileFormatError
      };

      const fileObj = event.target.files[0];
      const fileName = fileObj.name;
      const { recentDownloadedGridFile } = this.state;
      // check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
        if (fileObj.size < 1000) {
          // default file size of
          commentsUpdateStatus.message = msgConstants.uploadFailNoContent;
          this.uploadStatusUpdate(commentsUpdateStatus);
        } else {
          // for conflict name validation
          if (recentDownloadedGridFile.length === 0) {
            commentsUpdateStatus.message =
              msgConstants.uploadFailInvalidSession;
            this.uploadStatusUpdate(commentsUpdateStatus);
          } else if (fileName.includes(recentDownloadedGridFile)) {
            //ExcelRenderer to start here
            this.excelRendererFn(fileObj, commentsUpdateStatus);
          } else {
            confirmAlert({
              message: msgConstants.uploadWarnNoMatchFileName,
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    this.excelRendererFn(fileObj, commentsUpdateStatus);
                  }
                },
                {
                  label: 'No',
                  onClick: () => {
                    this.setState({ UploadingData: false, UploadModel: false });
                  }
                }
              ]
            });
          }
        }
      } else {
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    } else {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.uploadFailNoFile
      };

      this.setState({ UploadingData: false, UploadModel: false });
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };

  excelRendererFn(fileObj, commentsUpdateStatus) {
    const { recentDownloadedGridFileId, isToggleOn } = this.state;

    CustomExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        this.uploadStatusUpdate(commentsUpdateStatus);
      } else {
        const fileID = AppDeCipher(resp.rows[0][1]);
        const aggregationAttribute = isToggleOn ? 'QUARTERLY' : 'WEEKLY';
        const uploadedFileAttributes = fileID.split('-');

        const errorCase = { status: true, message: '' };
        if (!recentDownloadedGridFileId.includes(fileID)) {
          errorCase.status = false;
          errorCase.message = msgConstants.uploadFailNoMatchId;
          this.uploadStatusUpdate(errorCase);
        } else if (uploadedFileAttributes[3] !== aggregationAttribute) {
          errorCase.status = false;
          errorCase.message = isToggleOn
            ? msgConstants.uploadFailNotQuarterly
            : msgConstants.uploadFailNotWeekly;
          this.uploadStatusUpdate(errorCase);
        } else {
          this.uploadProcess(resp.rows);
        }
      }
    });

    setTimeout(() => {
      const { UploadModel } = this.state;
      if (UploadModel) {
        this.setState({
          editGrid: true,
          UploadingData: false,
          UploadModel: false
        });
        commentsUpdateStatus.message = msgConstants.noChangeUpdate;
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    }, 45000);
  }

  setQuarterParams = params => {
    const { defaultPQSortAfterLoad } = this.state;
    if (params) {
      this.setState({ agGridQuarterParams: params });
      setTimeout(() => {
        try {
          //params.api.sizeColumnsToFit();
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();
          params.api.setSortModel(defaultPQSortAfterLoad);
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      });
    }
  };
  onToggleClick = () => {
    const { isToggleOn } = this.state;
    let toggleOn = !isToggleOn;
    this.setState({
      isToggleOn: toggleOn
    });
  };
  // eslint-disable-next-line complexity
  render() {
    // rendering the dashboard content
    const {
      dashboardDataLoading,
      dashboardData,
      dashboardError,
      dashboardGridLoading,
      dashboardGridColumns,
      dashboardGridError,
      userProfileData,
      userProfileError,
      usrFactGrpSearchData,
      usrFactGrpSearchLoading,
      location
    } = this.props; // receive from API response
    const {
      defaultStatus,
      pageSearch,
      defaultColDef,
      excelStyles,
      UploadModel,
      UploadUpdateModel,
      UploadStatusData,
      UploadUpdateModelType,
      UploadingData,
      changedRowsData,
      updateCycleCompleteMsg,
      updateCycleCompleteModel,
      updateCycleReport,
      recentDownloadedGridFile,
      prodQuartersData,
      prodQuarterColumns,
      isToggleOn,
      validatedUploadPQResult
    } = this.state;
    let capacityLink = location && location.search ? location.search : '';
    return (
      <div className="page-align-items">
        <Beforeunload
          onBeforeunload={event => {
            if (changedRowsData.length === 0) {
              return true;
            }
            event.preventDefault();
          }}
        >
          <div className="dashboard">
            <div className="ncss-container">
              {!userProfileError &&
                userProfileData &&
                userProfileData.factoryGroups && (
                  <div className="ncss-row page-header pb0-sm pb2-md ">
                    <div className="ncss-col-lg-6  ncss-col-sm-12 va-sm-m ">
                      <h3 className="pt3-sm main-heading  c-f-500 text-color-accent">
                        <span>
                          {defaultStatus === 'MaxAvailable'
                            ? 'Strategic '
                            : 'Revised '}
                          Capacity Bulk Edits
                        </span>
                      </h3>
                    </div>
                    <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                      <div className="ncss-row">
                        <div className="ncss-col-sm-12  pl0-sm pr0-sm pt2-sm">
                          <NavLink
                            to={`/dashboard/${
                              defaultStatus === 'MaxAvailable'
                                ? 'strategicCapacity'
                                : 'revisedCapacity'
                            }${capacityLink}`}
                            className="fl-sm-r ncss-btn dashboard-button ncss-btn-accent"
                          >
                            <i className="fas fa fa-table" />{' '}
                            <span className="d-sm-h d-md-ib">
                              {defaultStatus === 'MaxAvailable'
                                ? 'Strategic'
                                : 'Revised'}
                              {' View'}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {/* Show Search bar on conditions */}

              {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}
              {(dashboardDataLoading ||
                dashboardGridLoading ||
                usrFactGrpSearchLoading) && (
                <div>
                  <Spinner large />
                </div>
              )}

              {/* Show Page Loading Error on failure of API */}
              {!noSearchInitiated &&
                (dashboardError || dashboardGridError) &&
                (!dashboardDataLoading || !dashboardGridLoading) && (
                  <PageError response={dashboardError} />
                )}

              {/* Show Counts and Grid */}
              <div className="vertical-align-baseline ">
                <div className="container p0-top-sm m0-sm">
                  {dashboardData &&
                    !dashboardDataLoading &&
                    (dashboardGridColumns && !dashboardGridLoading) && (
                      <div className="border-solid-light-grey">
                        <div>
                          {dashboardData.data &&
                          dashboardGridColumns.columns ? (
                            <div
                              className={` r-${dashboardData.data.length}  c-${dashboardGridColumns.columns.length}`}
                            >
                              <div className="myGridUploadOnly">
                                <Grid
                                  columnDefs={dashboardGridColumns.columns}
                                  defaultColDef={defaultColDef}
                                  rowData={dashboardData.data}
                                  setParams={this.setParams}
                                  excelStyles={excelStyles}
                                />
                                <Grid
                                  columnDefs={prodQuarterColumns}
                                  defaultColDef={defaultColDef}
                                  rowData={prodQuartersData}
                                  setParams={this.setQuarterParams}
                                  excelStyles={excelStyles}
                                />
                              </div>
                              <div className="capvis-page-container sm c-f-all u-clearfix">
                                <div className="ncss-container">
                                  <div className="ncss-row">
                                    <div className="ncss-col-sm-12 ncss-col-md-12 ncss-col-lg-12 ta-sm-l">
                                      <div className="ncss-row">
                                        <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-3 ta-sm-l va-sm-t open-sans-c u-bold ">
                                          <div>
                                            Choose View {isToggleOn}
                                            <br />
                                            <select
                                              name="toggleView"
                                              value={isToggleOn}
                                              key={`isToggleOn`}
                                              onChange={() =>
                                                this.onToggleClick()
                                              }
                                              className={`ncss-col-sm-12 p1-sm border-black bg-white no-border-custom react-select__control css-yk16xz-control`}
                                            >
                                              <option key="tw" value={false}>
                                                Weekly
                                              </option>
                                              <option key="tp" value={true}>
                                                Quarterly
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {dashboardData.pagination &&
                                dashboardData.pagination.totalResources ? (
                                  <div
                                    className={
                                      dashboardData.pagination.totalResources <=
                                      maxDownloadLimit
                                        ? 'bg-success-box'
                                        : 'bg-error-box'
                                    }
                                  >
                                    <H1>
                                      {dashboardData.pagination
                                        .totalResources <= maxDownloadLimit
                                        ? 'Success: '
                                        : 'Warning: '}
                                      <i
                                        className={
                                          isToggleOn
                                            ? 'fas fa-calendar-alt'
                                            : 'fas fa-calendar-week'
                                        }
                                      />
                                      {!isToggleOn
                                        ? ` ${dashboardData.pagination.totalResources} Weekly `
                                        : ` ${prodQuartersData &&
                                            prodQuartersData.length} Quarterly`}{' '}
                                      Records found!
                                    </H1>
                                    {dashboardData.pagination.totalResources <=
                                    maxDownloadLimit ? (
                                      <div>
                                        <p className=" p3-sm">
                                          Please proceed to download the records
                                        </p>
                                        <div className="fl-sm-c ">
                                          <Button
                                            onClick={() => this.downloadData()}
                                            extraClassName="ncss-btn-accent ncss-brand u-uppercase  dashboard-button"
                                          >
                                            <i className="fas fa fa-download" />
                                            <span className="d-sm-h d-md-ib">
                                              Download
                                            </span>
                                          </Button>
                                          &nbsp;
                                          <Button
                                            onClick={() =>
                                              this.toggleUploadModel()
                                            }
                                            extraClassName="ncss-btn-accent ncss-brand u-uppercase  dashboard-button"
                                          >
                                            <i className="fas fa fa-upload" />
                                            <span className="d-sm-h d-md-ib">
                                              Upload
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                    ) : (
                                      <p className="errorContent p3-sm errorContentMax">
                                        {' '}
                                        The current selection is looking for
                                        more than {maxDownloadLimit} weekly
                                        records. Please try a different search
                                        criterion by limiting the search to five
                                        factory codes or five capacity type
                                        codes.
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}

                          {updateCycleCompleteMsg && (
                            <div className="bg-success-box fl-sm-c">
                              <H1> Upload Successful !</H1>
                              <p className=" p3-sm">
                                Click to redirect to dashboard view or continue
                                with a new search.
                              </p>
                              <NavLink
                                to={`/dashboard/${
                                  defaultStatus === 'MaxAvailable'
                                    ? 'strategicCapacity'
                                    : 'revisedCapacity'
                                }${capacityLink}`}
                                className=" ncss-btn dashboard-button ncss-btn-black ncss-brand   "
                              >
                                <i className="fas fa fa-table" />{' '}
                                <span className="d-sm-h d-md-ib">
                                  {defaultStatus === 'MaxAvailable'
                                    ? 'STRATEGIC '
                                    : 'REVISED '}
                                  {'VIEW '}
                                </span>
                              </NavLink>

                              <div className="d-sm-h">
                                {' '}
                                {''} <br /> {''}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  {usrFactGrpSearchData &&
                    usrFactGrpSearchData.length === 0 &&
                    pageSearch === 1 && (
                      <div className="bg-white border-light-grey p3-sm">
                        <H1>
                          {' '}
                          No Factory Codes under selected Factory Group is
                          Associated for You !
                        </H1>
                      </div>
                    )}
                  {usrFactGrpSearchData &&
                    usrFactGrpSearchData.length === 0 &&
                    pageSearch === 1 && (
                      <div className="bg-white border-light-grey p3-sm">
                        <H1>
                          {' '}
                          No Factory Codes under selected Factory Group is
                          Associated for You !
                        </H1>
                      </div>
                    )}
                </div>
                <UploadModal
                  UploadModel={UploadModel}
                  UploadingData={UploadingData}
                  fileHandler={this.fileHandler}
                  toggleUploadModel={this.toggleUploadModel}
                  recentDownloadedGridFile={recentDownloadedGridFile}
                />
                <UploadUpdateModal
                  UploadUpdateModel={UploadUpdateModel}
                  UploadStatusData={UploadStatusData}
                  validatedUploadPQResult={validatedUploadPQResult}
                  toogleUploadUpdateModel={this.toogleUploadUpdateModel}
                  UploadUpdateModelType={UploadUpdateModelType}
                  updateCycleComplete={updateCycleCompleteModel}
                  updateCycleReport={updateCycleReport}
                />
              </div>
            </div>
          </div>
        </Beforeunload>
      </div>
    );
  }
}

uploadView.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  dashboardDataLoading: PropTypes.bool,
  dashboardData: PropTypes.object,
  dashboardError: PropTypes.any,
  dashboardGridLoading: PropTypes.bool,
  dashboardGridColumns: PropTypes.object,
  dashboardGridError: PropTypes.any,
  requestDashboardData: PropTypes.func,
  clearDashboardData: PropTypes.func,
  requestDashboardGridColumns: PropTypes.func,
  updateDashboardGridColumns: PropTypes.func,
  updateDashboardData: PropTypes.func,
  clearUpdateDashboardData: PropTypes.func,
  updateDashboardComment: PropTypes.func,
  savePreferenceData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  getUsrFactGrpSearchData: PropTypes.object,
  dashboardDataUpdate: PropTypes.any
};

const mapStateToProps = state => ({
  dashboardDataLoading: getDashboardDataLoading(state),
  dashboardData: getDashboardData(state),
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  dashboardError: getDashboardError(state),
  dashboardGridLoading: getDashboardGridLoading(state),
  dashboardGridColumns: getDashboardGridColumns(state),
  dashboardGridError: getDashboardGridError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  usrFactGrpSearchData: getusrFactGrpSearchData(state),
  usrFactGrpSearchLoading: getusrFactGrpSearchLoading(state),
  usrFactGrpSearchError: getusrFactGrpSearchError(state),
  dashboardDataUpdate: getDashboardDataUpdate(state),
  appConfigData: getAppConfigData(state),
  appConfigLoading: getAppConfigLoading(state),
  appConfigError: getAppConfigError(state)
});

const mapDispatchToProps = dispatch => ({
  requestDashboardData: status => {
    // Action for Making the status button as selected (Action required, In progress or completed)
    dispatch(selectStatusButton(status));
    // Get data to show on grid
    dispatch(createServiceAction('dashboardData', 'requested')(status));
  },

  getusrFactGrpSearchData: query => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'requested')(query));
  },

  clearDashboardData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('dashboardData', 'clear')(status));
    dispatch(createServiceAction('dashboardError', 'clear')({}));
  },
  clearDashboardGridColumns: columndata => {
    // Get data to show on graph and count
    dispatch(createServiceAction('dashboardGridColumns', 'clear')(columndata));
    dispatch(createServiceAction('dashboardGridError', 'clear')({}));
  },
  updateDashboardGridColumns: columndata => {
    dispatch(createServiceAction('dashboardGridColumns', 'update')(columndata));
  },
  requestDashboardGridColumns: requestHeader => {
    // Get data to show on graph and count
    dispatch(
      createServiceAction('dashboardGridColumns', 'requested')(requestHeader)
    );
  },
  savePreferenceData: (data, history) => {
    dispatch(
      createServiceAction('savePreferenceData', 'submited')({
        ...data,
        history
      })
    );
  },
  updateDashboardComment: (data, history) => {
    dispatch(
      createServiceAction('updateDashboardComment', 'submited')({
        ...data,
        history
      })
    );
  },
  updateDashboardData: data => {
    dispatch(
      createServiceAction('dashboardDataUpdate', 'submited')({ ...data })
    );
  },
  clearUpdateDashboardData: data => {
    dispatch(createServiceAction('dashboardDataUpdate', 'clear')({}));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(uploadView)
);
