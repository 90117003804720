import { toast } from 'react-toastify';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { postRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  const toastConfig = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };
  try {
    const data = action.data;
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'success')(false));
    const res = yield call(
      postRequest,
      apiConfig.updateLeverAdminData.endPointUrl,
      data,
      true
    );
    if (res.status === 201) {
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'success')(true));
      yield put(createServiceAction(serviceType, 'error')(false));
      toast.success(`Changes Updated successfully.`, toastConfig);
    } else if (res.status === 400) {
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'error')(true));
      yield put(createServiceAction(serviceType, 'success')(false));
      toast.error(`Failed to Update the changes. Try Again!`, toastConfig);
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(true));
    toast.error(`Failed to Update the changes. Try Again!`, toastConfig);
  }
}

function* updateLeverAdminDataSagas() {
  const serviceType = 'updateLeverAdminData';
  yield takeEvery(
    createServiceAction(serviceType, 'submited').type,
    listener,
    serviceType
  );
}

export default updateLeverAdminDataSagas;
