import { fromJS } from 'immutable';
import _ from 'lodash';
import { createServiceAction } from '../util/actionHelper.js';
import { growthLeverGridConfigure } from '../util/growthLeverGridConfigure.js';

const defaultState = fromJS({
  growthLeverDataLoading: false,
  growthLeverGridLoading: false,
  growthLeverDataUpdate: {}
});

const modifyGrowthLeverHeader = headerResponse => {
  const returnArray = headerResponse;
  returnArray.columns = growthLeverGridConfigure(headerResponse);
  return returnArray;
};
const modifyGrowthLeverData = growthLeverDataResponse => {
  const dataResponse = growthLeverDataResponse.growthLeverData;

  const { data, pagination } = dataResponse;
  if (data) {
    data.forEach(dataItem => {
      Object.keys(dataItem).forEach(key => {
        if (key.endsWith('Cap') || key.endsWith('Cost')) {
          if (
            dataItem[key] === 0 ||
            (dataItem[key] && dataItem[key].toString().length > 0)
          ) {
            dataItem[key] = Number(dataItem[key]);
          }
        }
        dataItem.ChangeByUpload = 0;
        dataItem.colModified = [];
      });
    });
  }
  if (pagination) {
    if (
      pagination.back &&
      Object.keys(pagination.back).length > 0 &&
      (pagination.back.count === null || pagination.back.count === 'null')
    ) {
      pagination.back = {};
    }
    if (
      pagination.next &&
      Object.keys(pagination.next).length > 0 &&
      (pagination.back.next === null || pagination.next.count === 'null')
    ) {
      pagination.back = {};
    }
  }

  dataResponse.data = _.orderBy(
    data,
    [
      function(item) {
        return item.factoryCode.a;
      },
      function(item) {
        return item.capacityTypeCode.b;
      }
    ],
    ['asc', 'asc']
  );
  dataResponse.pagination = pagination;
  return dataResponse;
};

const modifyFactGrpSearchData = resp => {
  let retArr = [];
  let setArray = [];
  if (resp) {
    if (Array.isArray(resp)) {
      if (resp.length > 0) {
        resp.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    } else {
      let cArr = resp.capacities;
      if (cArr.length > 0) {
        cArr.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    }
  }
  if (setArray.length > 0) {
    setArray.forEach((icc, i) => {
      let newCode = {
        name: icc.capacityTypeCode,
        value: icc.capacityTypeCode,
        key: icc.capacityTypeCode,
        label: icc.capacityTypeCode,
        fc: icc.factoryCode || '',
        id: `${i + 1000}`
      };
      retArr.push(newCode);
    });
  }

  const retUnQ = retArr;
  let retUnAll = [];
  if (retUnQ.length > 1) {
    const firstItem = {
      name: 'ALL',
      value: '',
      key: 'ALL',
      label: 'ALL',
      id: 10001
    };
    if (resp.length === 1) {
      firstItem.key = `All${resp[0].factoryCode}`;
      firstItem.name = `All ${resp[0].factoryCode}`;
      firstItem.label = `All ${resp[0].factoryCode}`;
    }
    retUnAll = [firstItem, ...retUnQ];
  } else {
    retUnAll = retUnQ;
  }
  return retUnAll;
};

export default function growthLever(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('growthLeverData', 'loading').type:
      return state.set('growthLeverDataLoading', action.data);

    case createServiceAction('growthLeverData', 'success').type:
      return state.set('growthLeverData', modifyGrowthLeverData(action.data));

    case createServiceAction('growthLeverData', 'clear').type:
      return state.set('growthLeverData', action.data);

    case createServiceAction('growthLeverData', 'error').type:
      return state.set('growthLeverError', action.data);

    case createServiceAction('growthLeverGridColumns', 'loading').type:
      return state.set('growthLeverGridLoading', action.data);

    case createServiceAction('growthLeverGridColumns', 'success').type:
      return state.set(
        'growthLeverGridColumns',
        modifyGrowthLeverHeader(action.data)
      );

    case createServiceAction('growthLeverGridColumns', 'clear').type:
      return state.set('growthLeverGridColumns', action.data);

    case createServiceAction('growthLeverGridColumns', 'error').type:
      return state.set('growthLeverGridError', action.data);

    case createServiceAction('growthLeverDataUpdate', 'success').type:
      return state.set('growthLeverDataUpdate', action.data);

    case createServiceAction('growthLeverDataUpdate', 'error').type:
      return state.set('growthLeverDataUpdate', action.data);

    case createServiceAction('growthLeverDataUpdate', 'clear').type:
      return state.set('growthLeverDataUpdate', action.data);

    case createServiceAction('testDetails', 'success').type:
      return state.set('testDetailData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'loading').type:
      return state.set('usrFactGrpSearchLoading', action.data);

    case createServiceAction('usrFactGrpSearchData', 'success').type:
      return state.set(
        'usrFactGrpSearchData',
        modifyFactGrpSearchData(action.data)
      );

    case createServiceAction('usrFactGrpSearchData', 'clear').type:
      return state.set('usrFactGrpSearchData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'error').type:
      return state.set('usrFactGrpSearchError', action.data);

    default:
      return state;
  }
}

export const getGrowthLeverDataLoading = state =>
  state.growthLever.get('growthLeverDataLoading');
export const getGrowthLeverData = state =>
  state.growthLever.get('growthLeverData');
export const getGrowthLeverError = state =>
  state.growthLever.get('growthLeverError');

export const getGrowthLeverGridLoading = state =>
  state.growthLever.get('growthLeverGridLoading');
export const getGrowthLeverGridError = state =>
  state.growthLever.get('growthLeverGridError');
export const getGrowthLeverGridColumns = state =>
  state.growthLever.get('growthLeverGridColumns');

export const getUsrFactGrpSearchLoading = state =>
  state.growthLever.get('usrFactGrpSearchLoading');
export const getUsrFactGrpSearchError = state =>
  state.growthLever.get('usrFactGrpSearchError');
export const getUsrFactGrpSearchData = state =>
  state.growthLever.get('usrFactGrpSearchData');

export const updateGrowthLeverData = state =>
  state.growthLever.get('growthLeverDataUpdate');

export const getGrowthLeverDataUpdate = state =>
  state.growthLever.get('growthLeverDataUpdate');

export const getTestDetailData = state =>
  state.growthLever.get('testDetailData');
