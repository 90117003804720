/* eslint-disable max-statements */
/* eslint-disable no-restricted-globals */

export const holidayCalculator = (field, paramsdata) => {
  const {
    workingDays,
    numberOfHolidays,
    maxAvailableCapacity,
    maxAvailableUnitsConfirmed,
    approvedMaxCapacity,
    shortHorizonCapacity,
    shortHorizonUnitsConfirmed,
    approvedRevisedCapacity,
    FctyMaxCapacity
  } = paramsdata;
  let retVal = '';
  const holidays = numberOfHolidays || 0;
  const availableWorkingDays =
    workingDays - holidays > 0 ? workingDays - holidays : 0;
  let field2calculate = '';

  if (workingDays !== 0) {
    switch (field) {
      case 'FctyStratHldCpty':
        field2calculate = maxAvailableCapacity;
        break;
      case 'ConfrndStratHldCpty':
        field2calculate = maxAvailableUnitsConfirmed;
        break;
      case 'AprvdStratHldCpty':
        field2calculate = approvedMaxCapacity;
        break;
      case 'FctyRvsdHldCpty':
        field2calculate = shortHorizonCapacity;
        break;
      case 'ConfrndRvsdHldCpty':
        field2calculate = shortHorizonUnitsConfirmed;
        break;
      case 'AprvdRvsdHldCpty':
        field2calculate = approvedRevisedCapacity;
        break;
      case 'FctyMaxHldCpty':
        field2calculate = FctyMaxCapacity;
        break;

      default:
        field2calculate = '';
    }
    if (
      field2calculate === 0 ||
      field2calculate === '0' ||
      (field2calculate && Number(field2calculate) > 0)
    ) {
      retVal = (field2calculate / workingDays) * availableWorkingDays;
      if (retVal % 1 !== 0) {
        retVal = Number((Math.round(retVal * 100) / 100).toFixed(2));
      }
    }
  }
  return retVal;
};

export const growthLeverGridConfigure = data => {
  // Function to create AG grid based header based on Colum API values
  const { reportColumns } = data;
  const gridConfig = [];

  if (reportColumns) {
    reportColumns.push({
      displayUser: true,
      src: 'GRID',
      editable: false,
      headerName: 'PQID',
      field: 'id'
    });
    reportColumns.push({
      displayUser: true,
      src: 'GRID',
      editable: false,
      headerName: 'WeekID',
      field: 'wid'
    });
  }
  return gridConfig;
};
