import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  commentsLogsDataLoading: false,
  commentsLogsDataUpdate: {}
});

export default function commentLogs(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('commentsLogsData', 'loading').type:
      return state.set('commentsLogsDataLoading', action.data);

    case createServiceAction('commentsLogsData', 'success').type:
      return state.set('commentsLogsData', action.data);

    case createServiceAction('commentsLogsData', 'clear').type:
      return state.set('commentsLogsData', action.data);

    case createServiceAction('commentsLogsData', 'error').type:
      return state.set('commentsLogsDataError', action.data);

    default:
      return state;
  }
}

export const getCommentsLogsDataLoading = state =>
  state.commentLogs.get('commentsLogsDataLoading');
export const getCommentsLogsData = state => state.commentLogs.get('commentsLogsData');
export const getCommentsLogsDataError = state => state.commentLogs.get('commentsLogsDataError');
