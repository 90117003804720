import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Beforeunload } from 'react-beforeunload';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Spinner } from '../../components/index.js';
import NotFound from '../../components/errors/NotFound.js';
import SelectFilterList from '../../components/userProfile/SelectFilterList.js';
import {
  getUserProfileSettingsData,
  getUserProfileSettingsLoading,
  getUserProfileSettingsError,
  getUpdatingUserProfileSettingsLoading,
  getUpdatingUserProfileSettingsSuccess,
  getUpdatingUserProfileSettingsFailed
} from '../../reducers/userProfileSettingsReducer.js';
import {
  getUserProfileData,
  getUserProfileDataLoading,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import pageConstants from './userProfileSettings.constants.js';
import { landingOptions } from '../../constants/appConstants.js';
import {
  selectLegacyUserName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';

export class UserProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: { componentParent: this },
      filteredReasonCodes: [],
      userProfile: {},
      pageData: [],
      pageBackData: [],
      pageDataChanged: false,
      enableSave: false,
      historyRCListEntry: {},
      displayHistoryModel: false,
      displayHistoryRCModel: false,
      reasonCodeparams: {},
      landingOptions: [...landingOptions],
      userProfileSettingsInfo: pageConstants.sampleData,
      toastConfig: {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      },

      pageTitle: '',
      horizonWeeksRange: { min: 0, max: 103 },
      reload: false
    };

    this.onSaveCancel = this.onSaveCancel.bind(this);
    this.saveDataChanges = this.saveDataChanges.bind(this);
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.onCheckAllChange = this.onCheckAllChange.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onFilterInputChange = this.onFilterInputChange.bind(this);

    this.toastUpdate = this.toastUpdate.bind(this);
    this.getUserProfileSettingsData = this.getUserProfileSettingsData.bind(
      this
    );
    this.reloadData = this.reloadData.bind(this);
  }

  componentDidMount() {
    this.getUserProfileSettingsData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      userProfileSettingsData,
      requestUserProfileSettingsData,
      userProfileData,
      updatingUserProfileSettingsSuccess,
      updatingUserProfileSettingsFailed,
      location
    } = this.props;

    if (userProfileSettingsData !== nextProps.userProfileSettingsData) {
      if (
        nextProps.userProfileSettingsData &&
        Object.keys(nextProps.userProfileSettingsData).length !== 0 &&
        nextProps.userProfileSettingsData.list
      ) {
        //var uniqueUsers = _.uniqWith(users, _.isEqual);//removed complete duplicates

        this.setState({
          pageData: JSON.parse(
            JSON.stringify(nextProps.userProfileSettingsData.list)
          ),
          pageBackData: JSON.parse(
            JSON.stringify(nextProps.userProfileSettingsData.list)
          )
        });
      }
    }
    if (userProfileData !== nextProps.userProfileData) {
      if (nextProps.userProfileData.factoryType) {
        this.setState({
          userProfile: nextProps.userProfileData
        });
        requestUserProfileSettingsData('');
      }
    }
    if (
      !nextProps.updateUserProfileSettingsLoading &&
      (updatingUserProfileSettingsSuccess !==
        nextProps.updatingUserProfileSettingsSuccess ||
        updatingUserProfileSettingsFailed !==
          nextProps.updatingUserProfileSettingsFailed)
    ) {
      this.reloadData();
    }

    if (location.pathname !== nextProps.location.pathname) {
      this.getUserProfileSettingsData();
    }
  }

  toastUpdate = toastms => {
    const { toastConfig } = this.state;
    if (toastms.status) {
      toast.success(toastms.msg, toastConfig);
    } else {
      toast.error(toastms.msg, toastConfig);
    }
  };

  getUserProfileSettingsData = () => {
    const {
      requestUserProfileSettingsData,
      requestUserProfileData,
      userProfileData
    } = this.props;
    if (userProfileData) {
      requestUserProfileSettingsData('');
    } else {
      requestUserProfileData();
    }
  };

  onSearchFieldChange = event => {
    let { pageData } = this.state;
    if (event.target.name) {
      pageData.configurations[event.target.name] = event.target.value;
      this.setState({ pageData });
    }
  };

  onCheckSelectionChange = (p, child, name, e) => {
    let { pageData, pageBackData } = this.state;
    pageData[p][child].list.forEach(i => {
      if (i.parent === name) {
        i.checked = e.target.checked;
      }
    });
    const ifFalse = pageData[p][child].list.findIndex(o => o.checked === false);
    pageData[p][child].checkedAll = ifFalse >= 0 ? false : true;
    pageData[p][child].indeterminate = ifFalse >= 0 ? true : false;

    let pageDataChanged = false;
    if (JSON.stringify(pageData) !== JSON.stringify(pageBackData)) {
      pageDataChanged = true;
    }
    this.setState({ pageData, pageDataChanged });
  };

  onFilterInputChange = (p, child, e) => {
    let { pageData } = this.state;
    pageData[p][child].searchText = e.target.value;
    this.setState({ pageData });
  };

  onCheckAllChange = (p, child) => {
    let { pageData, pageBackData } = this.state;
    pageData[p][child].checkedAll = !pageData[p][child].checkedAll;
    pageData[p][child].list.forEach(i => {
      if (
        pageData[p][child].searchText &&
        pageData[p][child].searchText.length > 0
      ) {
        if (
          i.name
            .toLowerCase()
            .includes(pageData[p][child].searchText.toLowerCase())
        ) {
          i.checked = pageData[p][child].checkedAll;
        }
      } else {
        i.checked = pageData[p][child].checkedAll;
      }
    });

    let pageDataChanged = false;
    if (JSON.stringify(pageData) !== JSON.stringify(pageBackData)) {
      pageDataChanged = true;
    }
    this.setState({ pageData, pageDataChanged });
  };

  onCheckChange = (p, child, name, parent) => {
    let { pageData, pageBackData } = this.state;

    const ix = _.findIndex(pageData[p][child].list, {
      name: name,
      parent: parent
    });
    //const ix = pageData[p][child].list.findIndex(o => o.name === name);
    pageData[p][child].list[ix].checked = !pageData[p][child].list[ix].checked;

    const selArLen = pageData[p][child].list.filter(i => i.checked).length || 0;
    const totArLen = pageData[p][child].list.length;

    pageData[p][child].checkedAll = selArLen === totArLen ? true : false;
    pageData[p][child].indeterminate =
      selArLen > 0 && selArLen < totArLen ? true : false;

    let pageDataChanged = false;
    if (JSON.stringify(pageData) !== JSON.stringify(pageBackData)) {
      pageDataChanged = true;
    }
    this.setState({ pageData, pageDataChanged });
  };

  onToggleClick = name => {
    let { pageData, pageBackData } = this.state;
    const toChange = pageData[name].checked;
    pageData[name].checked = !toChange;

    let pageDataChanged = false;
    if (pageData[name].checked !== pageBackData[name].checked) {
      pageDataChanged = true;
    }
    this.setState({ pageData, pageDataChanged });
  };

  onSaveCancel = () => {
    const { pageDataChanged, pageBackData } = this.state;
    if (pageDataChanged) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="c-f-all bg-offwhite p2r-sm">
              <h1 className="text-color-black  page-title-has-back fs-24px pt0-sm">
                Unsaved Changes
              </h1>
              <p className="fs-14px">
                You have unsaved changes on this page. Do you wish to continue?
              </p>
              <div className="pt4-sm ta-sm-r ">
                <button
                  onClick={onClose}
                  className="  ncss-btn-secondary mr-2-sm"
                >
                  Back
                </button>
                <button
                  className="  ncss-btn-primary-dark"
                  onClick={() => {
                    this.setState({
                      pageData: JSON.parse(JSON.stringify(pageBackData)),
                      pageDataChanged: false
                    });
                    onClose();
                  }}
                >
                  Don't Save
                </button>
              </div>
            </div>
          );
        }
      });
    } else {
      return false;
    }
  };

  saveDataChanges = () => {
    const { pageData } = this.state;
    const { updateUserProfileSettingsData } = this.props;
    const toggledDashboards = [];

    if (pageData) {
      const returnData = {};
      pageData.forEach(i => {
        if (i.checked) {
          toggledDashboards.push(i.name.toUpperCase());
        }
        returnData[i.name] = {};
        i.fGroup.list.forEach(j => {
          if (j.checked) {
            returnData[i.name][j.name] = {};
          }
        });
        i.fCodes.list.forEach(j => {
          if (returnData[i.name][j.parent]) {
            returnData[i.name][j.parent][j.name] = i.fTypes.list
              .filter(k => k.checked && j.checked && k.parent === j.name)
              .map(function(l) {
                return {
                  capacityTypeCode: l.code,
                  capacityTypeDescription: l.desc
                };
              });
          }
        });
      });

      this.setState({
        pageData: [],
        pageBackData: [],
        reload: true,
        pageDataChanged: false
      });
      returnData.toggledDashboards = toggledDashboards;

      updateUserProfileSettingsData(returnData);
    }
  };

  getlistByFiltered = (p, c) => {
    const { pageData } = this.state;
    let parentFilteredList = [];

    let searchFilteredList = [];
    if (
      pageData &&
      pageData.length > 0 &&
      pageData[p][c] &&
      pageData[p][c].list &&
      pageData[p][c].list.length > 0
    ) {
      if (c === 'fGroup') {
        parentFilteredList = pageData[p].fGroup.list;
      } else if (c === 'fCodes') {
        const gList = pageData[p].fGroup.list
          .filter(j => j.checked)
          .map(function(i) {
            return i.name;
          });
        parentFilteredList = pageData[p][c].list.filter(o =>
          gList.includes(o.parent)
        );
      } else if (c === 'fTypes') {
        const gList = pageData[p].fGroup.list
          .filter(j => j.checked)
          .map(function(i) {
            return i.name;
          });
        const cList = pageData[p].fCodes.list
          .filter(j => j.checked)
          .map(function(i) {
            return i.name;
          });
        parentFilteredList = pageData[p][c].list.filter(
          o => cList.includes(o.parent) && gList.includes(o.parent2)
        );
      }
      if (pageData[p][c].searchText && pageData[p][c].searchText.length > 0) {
        searchFilteredList = parentFilteredList.filter(item => {
          return (
            item.name
              .toLowerCase()
              .search(pageData[p][c].searchText.toLowerCase()) !== -1
          );
        });
      } else {
        searchFilteredList = parentFilteredList;
      }
    }
    return searchFilteredList;
  };

  reloadData = () => {
    const { requestUserProfileSettingsData } = this.props;
    const { reload } = this.state;
    if (reload) {
      requestUserProfileSettingsData('');
      this.setState({ reload: false });
    }
  };

  render() {
    const {
      userProfileSettingsError,
      userProfileSettingsLoading,
      userProfileData,
      updatingUserProfileSettingsLoading,
      currentuseremail
    } = this.props;
    const { pageData, pageDataChanged } = this.state;
    let pageLoading = true;
    if (
      userProfileData &&
      userProfileData.factoryType &&
      (pageData ||
        (userProfileSettingsError && userProfileSettingsError.status))
    ) {
      pageLoading = false;
    }
    localStorage.setItem('isProfileSettingsChanged', pageDataChanged);
    let pageError = false;
    if (
      !userProfileSettingsLoading &&
      userProfileSettingsError &&
      userProfileSettingsError.status
    ) {
      pageError = true;
    }

    return (
      <div className="page-align-items  c-f-all">
        <Beforeunload
          onBeforeunload={event => {
            if (pageDataChanged) {
              event.preventDefault();
            } else {
              localStorage.setItem('isProfileSettingsChanged', false);
            }
          }}
        >
          <div className="">
            {userProfileSettingsLoading && <Spinner large />}
            {updatingUserProfileSettingsLoading && (
              <Spinner large position="Fixed" />
            )}
            {!pageLoading && pageError && <NotFound status={500} />}

            {!pageLoading && !pageError && pageData && (
              <div className="ncss-container prl0-sm mt6-sm pt6-sm">
                <div className=" bg-white c-f-border-5 p6-sm mb5-sm">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12  ncss-col-md-6 ncss-col-lg-2 row-align-top">
                      <h3 className="text-color-grey1  page-title-has-back fs-24px">
                        Profile Settings
                      </h3>
                      <p className="fs-12px c-f-500 text-color-black fs-wb">
                        {currentuseremail}
                      </p>
                    </div>
                    <div className="ncss-col-sm-12  ncss-col-md-6 ncss-col-lg-2 row-align-top fl-md-r ta-sm-r">
                      <button
                        type="button"
                        className="  ncss-btn-secondary ncss-btn-large"
                        onClick={() => this.onSaveCancel()}
                      >
                        Cancel
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        disabled={!pageDataChanged}
                        onClick={() => this.saveDataChanges()}
                        className={`  ncss-btn-primary-dark ncss-btn-large`}
                      >
                        Save
                      </button>
                    </div>
                    <div
                      className="ncss-col-sm-12  ncss-col-md-12 ncss-col-lg-8"
                      style={{ minHeight: window.innerHeight - 215 }}
                    >
                      {pageData.length > 0 &&
                        pageData.map((v, i) => (
                          <div
                            className="ncss-container pt0-sm pb4-sm"
                            key={`listOf${i}`}
                          >
                            <div className="ncss-row pt2-sm">
                              <div className="ncss-col-sm-12 row-align-top pb1-sm">
                                <div className="fl-sm-l pt0-sm pr2-sm">
                                  <label
                                    className="switch sm blue"
                                    htmlFor="maxCapacityEditable"
                                    onClick={e => this.onToggleClick(i)}
                                  >
                                    <input
                                      type="checkbox"
                                      name="maxCapacityEditable"
                                      checked={v.checked}
                                      readOnly
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </div>
                                <h4
                                  className={`fl-sm-l pl1-sm bold fs-18px c-f-500 u-capitalize
                                ${v.checked ? '' : 'text-color-grey1'}
                                `}
                                >
                                  {v.name && v.name.toLowerCase()} Horizon
                                  Notifications ({v.checked ? 'On' : 'Off'})
                                </h4>
                              </div>
                            </div>
                            <div className="ncss-row">
                              <div
                                className={`ncss-col-sm-12   ncss-col-md-4 row-align-top
                              ${v.checked ? '' : 'text-color-grey1'}`}
                              >
                                <p className="fs-12px c-f-500">
                                  Factory Group (All)
                                </p>
                                {v.fGroup && (
                                  <SelectFilterList
                                    disabled={!v.fGroup.checked}
                                    checkedAll={v.fGroup.checkedAll}
                                    indeterminate={v.fGroup.indeterminate}
                                    searchText={v.fGroup.searchText}
                                    listGroupBy={v.fGroup.groupBy}
                                    disabledAll={!v.checked}
                                    list={this.getlistByFiltered(i, 'fGroup')}
                                    name="fGroup"
                                    onCheckChange={this.onCheckChange}
                                    onCheckSelectionChange={
                                      this.onCheckSelectionChange
                                    }
                                    parent={i}
                                    selectAllOption={false}
                                    onFilterInputChange={
                                      this.onFilterInputChange
                                    }
                                    onCheckAllChange={this.onCheckAllChange}
                                  />
                                )}
                              </div>

                              <div
                                className={`ncss-col-sm-12  ncss-col-md-4 row-align-top
                              ${v.checked ? '' : 'text-color-grey1'}`}
                              >
                                <p className="fs-12px c-f-500">
                                  Factory Code (All)
                                </p>
                                {v.fCodes && (
                                  <SelectFilterList
                                    disabled={!v.fCodes.checked}
                                    checkedAll={v.fCodes.checkedAll}
                                    indeterminate={v.fCodes.indeterminate}
                                    searchText={v.fCodes.searchText}
                                    listGroupBy={v.fCodes.groupBy}
                                    disabledAll={!v.checked}
                                    list={this.getlistByFiltered(i, 'fCodes')}
                                    name="fCodes"
                                    onCheckChange={this.onCheckChange}
                                    onCheckSelectionChange={
                                      this.onCheckSelectionChange
                                    }
                                    parent={i}
                                    selectAllOption={false}
                                    onFilterInputChange={
                                      this.onFilterInputChange
                                    }
                                    onCheckAllChange={this.onCheckAllChange}
                                  />
                                )}
                              </div>
                              <div
                                className={`ncss-col-sm-12  ncss-col-md-4 row-align-top
                              ${v.checked ? '' : 'text-color-grey1'}`}
                              >
                                <p className="fs-12px c-f-500">
                                  Factory Capacity Description (All)
                                </p>
                                {v.fTypes && (
                                  <SelectFilterList
                                    disabled={!v.fTypes.checked}
                                    checkedAll={v.fTypes.checkedAll}
                                    indeterminate={v.fTypes.indeterminate}
                                    searchText={v.fTypes.searchText}
                                    listGroupBy={v.fTypes.groupBy}
                                    disabledAll={!v.checked}
                                    list={this.getlistByFiltered(i, 'fTypes')}
                                    name="fTypes"
                                    onCheckChange={this.onCheckChange}
                                    onCheckSelectionChange={
                                      this.onCheckSelectionChange
                                    }
                                    parent={i}
                                    selectAllOption={false}
                                    onFilterInputChange={
                                      this.onFilterInputChange
                                    }
                                    onCheckAllChange={this.onCheckAllChange}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Beforeunload>
      </div>
    );
  }
}

UserProfileSettings.propTypes = {
  history: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  userProfileSettingsData: PropTypes.object,
  userProfileSettingsLoading: PropTypes.any,
  userProfileSettingsError: PropTypes.object,
  requestUserProfileSettingsData: PropTypes.func,
  updateUserProfileSettingsData: PropTypes.func,
  userProfileLoading: PropTypes.any,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  updatingUserProfileSettingsLoading: PropTypes.any,
  updatingUserProfileSettingsSuccess: PropTypes.any,
  updatingUserProfileSettingsFailed: PropTypes.any
};

const mapStateToProps = state => ({
  userProfileLoading: getUserProfileDataLoading(state),
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  userProfileSettingsData: getUserProfileSettingsData(state),
  userProfileSettingsLoading: getUserProfileSettingsLoading(state),
  userProfileSettingsError: getUserProfileSettingsError(state),

  updatingUserProfileSettingsLoading: getUpdatingUserProfileSettingsLoading(
    state
  ),
  updatingUserProfileSettingsSuccess: getUpdatingUserProfileSettingsSuccess(
    state
  ),
  updatingUserProfileSettingsFailed: getUpdatingUserProfileSettingsFailed(
    state
  ),

  name: selectLegacyUserName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state)
});

const mapDispatchToProps = dispatch => ({
  requestUserProfileSettingsData: status => {
    dispatch(
      createServiceAction('updateUserProfileSettingsData', 'clear')(false)
    );
    // Get data to show on grid
    dispatch(
      createServiceAction('updateUserProfileSettingsData', 'loading')(false)
    );
    dispatch(
      createServiceAction('userProfileSettingsData', 'requested')(status)
    );
  },
  requestUserProfileData: () => {
    dispatch(createServiceAction('getUserProfile', 'requested')());
  },
  updateUserProfileSettingsData: (data, history) => {
    dispatch(
      createServiceAction(
        'updateUserProfileSettingsData',
        'submited'
      )({
        ...data,
        history
      })
    );
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfileSettings)
);
