import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from 'react-select';
import { SingleInput } from '../index.js';
import DatePickerYearMonth from '../../components/dashboard/datePickerYearMonth.js';
import Tooltip from 'rc-tooltip';
import NumberFormat from 'react-number-format';
import Indicator from './Indicator.js';
import moment from 'moment';
import { checkNumberDigits } from '../../util/helper.js';
import { numberWithCommas } from '../../util/general.js';

const modifiers = {
  disabled: { daysOfWeek: [6] },
  birthday: new Date(2018, 8, 19),
  monday: { daysOfWeek: [1] }
};
let dayPickerInputRef = null;

const today = new Date();
const startDate = moment(today);
const fromMonth = new Date(startDate);

export default class LeverFields extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.typeAheadOptions = this.typeAheadOptions.bind(this);
    this.typeAheadSelected = this.typeAheadSelected.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchTypeAheadChange = this.onSearchTypeAheadChange.bind(this);
    this.onSelectFieldChange = this.onSelectFieldChange.bind(this);
  }

  //completed
  onSearchFieldChange = (index, i, field, value) => {
    const { updateVarianceLever } = this.props;
    updateVarianceLever(index, i, field, value);
  };
  onSelectFieldChange = (index, i, field, value) => {
    const { updateVarianceLever } = this.props;
    updateVarianceLever(index, i, field, value.value);
  };
  onSearchDateChange(index, i, field, date) {
    const { updateVarianceLever } = this.props;
    updateVarianceLever(index, i, field, new Date(moment(date)));
  }
  handleYearMonthChange(month, field) {
    this.setState({ [field]: month });
  }

  typeAheadOptions = field => {
    let allOptions = [];
    let filteredOptions = [];
    const { leverColumns } = this.props;
    const thisIndex = leverColumns.findIndex(item => item.field === field);
    if (thisIndex >= 0) {
      allOptions = leverColumns[thisIndex].options;
    }

    filteredOptions = allOptions;

    return filteredOptions;
  };
  typeAheadSelected = (field, value) => {
    let arr = [];
    if (value === '') {
      return [];
    }
    const { leverColumns } = this.props;
    const thisIndex = leverColumns.findIndex(item => item.field === field);
    if (thisIndex >= 0) {
      arr = leverColumns[thisIndex].options;
    }

    let selectedValue = arr.filter(item => item.name === value);
    return selectedValue.length > 0
      ? selectedValue
      : [{ name: value, label: value }];
  };

  typeAheadDisabled = (field, value) => {
    let arr = [];
    const { leverColumns } = this.props;
    const thisIndex = leverColumns.findIndex(item => item.field === field);
    if (thisIndex >= 0) {
      arr = leverColumns[thisIndex].options;
    }
    return arr.filter(item => item.name === value).length === 0;
  };

  onSearchTypeAheadChange(index, i, field, value) {
    const valueIs = (value && value.length > 0 && value[0].name) || '';
    const { updateVarianceLever } = this.props;

    updateVarianceLever(index, i, field, valueIs);
  }

  getSectionClass = (field, validationObj) => {
    const { row } = this.props;
    let cssClass = [];
    if (validationObj && !validationObj[field]) {
      cssClass.push('capacityError');
    } else if (
      (row.capacity || row.capacity === 0) &&
      (row.leverQty || row.leverQty === 0) &&
      (Number(Math.sign(row.leverQty)) !== Number(Math.sign(row.capacity)) ||
        Number(Math.abs(row.leverQty)) > Number(Math.abs(row.capacity))) &&
      (field === 'capacity' || field === 'leverQty')
    ) {
      cssClass.push('capacityError');
    }
    return cssClass.join(' ');
  };

  getSelectSelected = (arr, val) => {
    const selected = arr.find(o => o.value === val);
    return selected;
  };

  // eslint-disable-next-line complexity
  getSection = levItem => {
    const { field, placeholder, editable = false } = levItem;

    const {
      pageType,
      row,
      index,
      i,
      userRole,
      partialQuarter,
      validationResult
    } = this.props;
    let disableField = false;
    if (row['lever']) {
      if (
        row['lever'].length === 0 &&
        (field === 'capacity' ||
          field === 'cost' ||
          field === 'validityDate' ||
          field === 'comment')
      ) {
        disableField = true;
      }

      if (
        row['lever'].length !== 0 &&
        row['capacity'].length === 0 &&
        (field === 'cost' || field === 'validityDate' || field === 'comment')
      ) {
        disableField = true;
      }
    }
    if (field === 'leverQty' && row.srcDecision !== 'Yes') {
      disableField = true;
    }
    if (
      row.srcDecision === 'Expired' &&
      userRole === 'WHQ' &&
      field !== 'comment'
    ) {
      disableField = true;
    }
    if (field === 'capacity' || field === 'cost' || field === 'leverQty') {
      row[field] = row[field] && Math.round(row[field]);
    }
    if (!editable || disableField || partialQuarter) {
      return (
        <div className="position-relative">
          {field === 'cost' && <span className="costPrefix">$</span>}
          <SingleInput
            inputType={'text'}
            name={field}
            key={`tab-${index}-row-${i}-${field}`}
            controlFunc={e =>
              this.onSearchFieldChange(index, i, field, e.target.value)
            }
            content={
              levItem.inputType === 'number' && row[field] && !isNaN(row[field])
                ? numberWithCommas(row[field])
                : row[field]
            }
            placeholder={placeholder || ''}
            className={`leverInput ${levItem.cssClass}`}
            required={levItem.required || false}
            disabled={true}
          />
        </div>
      );
    }
    switch (levItem.inputType) {
      case 'options':
        return (
          <div className="position-relative">
            {levItem.cssClass === 'float' && levItem.selected.length > 0 ? (
              <span className="floatHead">{levItem.name}</span>
            ) : null}

            <select
              name={levItem.field}
              value={levItem.value}
              key={`${levItem.id}-${levItem.field}`}
              onChange={e => this.onSearchFieldChange(e)}
              className={` input-text  ${levItem.cssClass}`}
              disabled={levItem.disabled}
            >
              <option />
              {levItem.options &&
                levItem.options.map(v => (
                  <option key={v.key} value={v.value}>
                    {v.label}
                  </option>
                ))}
            </select>
          </div>
        );
      case 'select':
        return (
          <div className="position-relative">
            <Select
              value={this.getSelectSelected(levItem.options, row[field])}
              id={levItem.name}
              ref={`optionsSelect${levItem.field}`}
              options={levItem.options}
              placeholder={levItem.name}
              isMulti={false}
              onChange={e => this.onSelectFieldChange(index, i, field, e)}
              classNamePrefix="react-select-sour-dec"
              // menuIsOpen={true}
            />
          </div>
        );
      case 'typeahead':
        return (
          <div className="position-relative">
            {levItem.cssClass === 'float' && levItem.selected.length > 0 ? (
              <span className="floatHead">{levItem.name}</span>
            ) : null}
            {row.category && row.dimension && field === 'lever' && (
              <div className=" leverToolTipInfo ">
                <Tooltip
                  key={`indicator-tool-tip-${Math.random() * (100000 - 10000) +
                    10000}`}
                  placement="top"
                  overlay={
                    <p className="c-f">
                      Category : {row.category}
                      <br />
                      Dimension : {row.dimension}
                    </p>
                  }
                  arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                  <i className="leverToolTipInfoContent"> </i>
                </Tooltip>
              </div>
            )}
            <Typeahead
              value={levItem.value}
              id={`${levItem.name}-${index}-${i}`}
              ref={`typeAhead${levItem.field}`}
              onChange={value =>
                this.onSearchTypeAheadChange(index, i, field, value)
              }
              maxSelected={1}
              // onBlur={() => this.onSearchTypeAheadOnBlur(levItem.field)}
              options={this.typeAheadOptions(levItem.field)}
              placeholder={placeholder || ''}
              disabled={levItem.disabled}
              multiple={false}
              dropup={true}
              selected={this.typeAheadSelected(field, row[field])}
              className={`Typeahead ${this.getSectionClass(
                levItem.field,
                validationResult
              )}`}
              renderMenuItemChildren={option => (
                <div>
                  {option.name}
                  {levItem.field === 'levers' && (
                    <div className="text-color-grey">
                      <small>
                        <span className="g72-chevron-right"></span>
                        {option.category || ''}
                        <span className="g72-chevron-right"></span>
                        {option.dimension || ''}
                      </small>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        );

      case 'date':
        // This will be slightly different for now depending on whether it's Upload or Search View. When Upload View changes to
        //  single row selectors, all of the DayPicker code can be the same
        return (
          <div name="dayPickerParent">
            {pageType !== 'searchgrid' ? levItem.name : null}
            <div
              className={`DayPicker-Border ${
                levItem.cssClass
              } ${this.getSectionClass(levItem.field, validationResult)}`}
            >
              <DayPickerInput
                value={row[field] ? new Date(row[field]) : ''}
                keepFocus={false}
                onDayChange={day =>
                  this.onSearchDateChange(index, i, field, day)
                }
                modifiers={modifiers}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder="MM/DD/YYYY"
                className={`DayPickerInput`}
                inputProps={{
                  readOnly: true,
                  style: {
                    borderRadius: 4,
                    borderColor: '#ccc',
                    minHeight: 29,
                    fontSize: 12
                  }
                }}
                dayPickerProps={{
                  // eslint-disable-next-line react/destructuring-assignment
                  month: this.state[levItem.field],
                  fromMonth: fromMonth,
                  disabledDays: [{ before: fromMonth }],

                  modifiers,
                  captionElement: ({ date, localeUtils }) => (
                    <DatePickerYearMonth
                      date={date}
                      localeUtils={localeUtils}
                      field={levItem.field}
                      onChange={this.handleYearMonthChange}
                    />
                  ),
                  onBlur: () => {
                    setTimeout(() => {
                      const elClicked = document.activeElement,
                        container = document.getElementsByName(
                          `dayPickerParent`
                        );
                      if (
                        container &&
                        !container[0].contains(elClicked) &&
                        dayPickerInputRef
                      ) {
                        dayPickerInputRef.hideDayPicker();
                      }
                    }, 1);
                  }
                }}
              />
            </div>
          </div>
        );
      case 'number':
        return (
          <div className="position-relative">
            <NumberFormat
              key={`tab-${index}-row-${i}-${field}`}
              thousandsGroupStyle="thousand"
              value={row[field]}
              prefix={field === 'cost' ? '$' : ''}
              decimalSeparator={false}
              displayType="input"
              type="text"
              decimalScale={field === 'cost' ? 0 : 2}
              thousandSeparator={true}
              isAllowed={({ value }) => {
                let digitShouldAccept = field === 'cost' ? 100 : 12;
                let decimalShouldAccept = field === 'cost' ? 0 : 2;
                return checkNumberDigits(
                  value,
                  digitShouldAccept,
                  decimalShouldAccept
                );
              }}
              allowNegative={true}
              onValueChange={e =>
                this.onSearchFieldChange(index, i, field, e.value)
              }
              className={`leverInput ${this.getSectionClass(
                levItem.field,
                validationResult
              )}`}
            />
          </div>
        );
      default:
        return (
          <div className="position-relative">
            {field === 'cost' && <span className="costPrefix">$</span>}
            <SingleInput
              inputType={
                levItem.inputType === 'number'
                  ? 'text'
                  : levItem.inputType || 'text'
              }
              name={field}
              key={`tab-${index}-row-${i}-${field}`}
              controlFunc={e =>
                this.onSearchFieldChange(index, i, field, e.target.value)
              }
              content={row[field]}
              placeholder={placeholder || ''}
              className={`leverInput ${this.getSectionClass(
                levItem.field,
                validationResult
              )}`}
              required={levItem.required || false}
              disabled={levItem.disabled}
            />
          </div>
        );
    }
  };

  render() {
    const {
      leverColumns,
      varianceCode,
      deleteVarianceLever,
      index,
      i,
      row
    } = this.props;
    const editableFields = ['typeahead', 'date', 'select', 'text', 'number'];
    return (
      <tr>
        {leverColumns.map((column, ci) => {
          return (
            <td
              key={`tb-${index}-${ci}-${varianceCode}-${column.field}`}
              className={`${varianceCode}-${column.field}`}
            >
              <div
                className={`lever-table-cell lever-table-cell-${varianceCode} lever-table-cell-${column.field}`}
              >
                {column.field === 'indicator' && (
                  <span className="d-block">
                    {row && (
                      <div className="d-sm-b">
                        <Indicator item={row} />
                      </div>
                    )}
                  </span>
                )}
                {editableFields.includes(column.inputType) &&
                  this.getSection(column, 1)}
                {column.field === 'action' && column.editable && (
                  <i
                    className="delete cursor-pointer"
                    onClick={() => deleteVarianceLever(index, i)}
                  ></i>
                )}
              </div>
            </td>
          );
        })}
      </tr>
    );
  }
}
LeverFields.propTypes = {
  onSearchEnter: PropTypes.func
};
