import { createAction } from '../util/actionHelper.js';

export const addNotification = createAction('NOTIFICATIONS_ADD');
export const notificationRemove = createAction('NOTIFICATIONS_REMOVE');
export const clearNotifications = createAction('NOTIFICATIONS_CLEAR_ALL');
export const requestNotification = createAction('NOTIFICATION_REQUESTED', requestObject => requestObject);
export const requestErrorNotification = createAction(
  'NOTIFICATION_ERROR_REQUESTED',
  requestObject => requestObject
);
