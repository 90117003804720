import { store } from '../index.js';
import { selectUserDetails } from '../reducers/authReducer.js';

export function getParameterByName(url, name) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const globalHeader = url => {
  const { accessToken } = selectUserDetails(store.getState());
  if (
    url.includes('get-user-affiliations') ||
    url.includes('get-factory-association') ||
    url.includes('update-gbl-data')
  ) {
    return {
      Authorization: `Bearer ${accessToken}`
    };
  }
  const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
  const userFactory = JSON.parse(userFacList);
  const userFactoryCodes = userFacList.factoryCode || [];
  const factorycodes =
    getParameterByName(url, 'factoryCode') || userFactoryCodes.join(',');

  return {
    'factory-type': userFactory.factoryType || '',
    'user-factories': factorycodes,
    Authorization: `Bearer ${accessToken}`
  };
};

export default globalHeader;
