import { fromJS } from 'immutable';
import * as moment from 'moment';
import _ from 'lodash';
import { createServiceAction } from '../util/actionHelper.js';
import { dashboardGridConfigure } from '../util/dashboardGridConfigure.js';

const defaultState = fromJS({
  dashboardDataLoading: false,
  dashboardGridLoading: false,
  dashboardDataUpdate: {}
});

const modifyDashboardHeader = headerResponse => {
  const returnArray = headerResponse;
  returnArray.columns = dashboardGridConfigure(headerResponse);
  return returnArray;
};
const modifyDashboardData = dashBoardDataResponse => {
  const dataResponse = dashBoardDataResponse.dashboardData;

  const { data, pagination } = dataResponse;

  if (data) {
    const numberEditableCols = [
      'maxAvailableCapacity',
      'shortHorizonCapacity',
      'shortHorizonCapacityChange',
      'approvedRevisedCapacity',
      'maxAvailableUnitsConfirmed',
      'shortHorizonUnitsConfirmed',
      'approvedRevisedCapacity',
      'approvedMaxCapacity',
      'FctyMaxCapacity'
    ];
    const reasonCodeEditableCols = [
      'maxAvailableReasonCode',
      'shortHorizonReasonCode',
      'maxCapacityReasonCode'
    ];
    data.forEach(dataItem => {
      //
      Object.keys(dataItem).forEach(key => {
        if (numberEditableCols.includes(key)) {
          if (
            dataItem[key] === 0 ||
            (dataItem[key] && dataItem[key].toString().length > 0)
          ) {
            dataItem[key] = Number(dataItem[key]);
          }
        } else if (reasonCodeEditableCols.includes(key)) {
          if (dataItem[key] && dataItem[key].toString().length > 0) {
            dataItem[key] = dataItem[key].toString().trim();
          }
        }

        dataItem.ChangeByUpload = 0;
        dataItem.colModified = [];
      });
      //
      const momentdate =
        moment(dataItem.weekStartDate).format('MM/DD/YYYY') || '';

      dataItem.weekStartDate = Date.parse(momentdate);
      let maxAvailableNotes = dataItem.maxAvailableNotes || [];
      let shortHorizonNotes = dataItem.shortHorizonNotes || [];
      if (maxAvailableNotes.length > 0) {
        dataItem.maxAvailableNotes = _.uniqBy(maxAvailableNotes, 'timestamp');
      }
      if (shortHorizonNotes.length > 0) {
        dataItem.shortHorizonNotes = _.uniqBy(shortHorizonNotes, 'timestamp');
      }
      //
      dataItem.isStatusBreak = false;
      dataItem.isThresholdExceed = false;
      dataItem.inThresholdExceedCols = [];
      dataItem.inThreshold = null;
      dataItem.isColumnSkipped = false;
      dataItem.inColumnSkipped = [];
      if (dataItem.capacityTypeCode) {
        dataItem.capacityTypeCode = dataItem.capacityTypeCode.toString();
      }
    });
  }
  if (pagination) {
    if (
      pagination.back &&
      Object.keys(pagination.back).length > 0 &&
      (pagination.back.count === null || pagination.back.count === 'null')
    ) {
      pagination.back = {};
    }
    if (
      pagination.next &&
      Object.keys(pagination.next).length > 0 &&
      (pagination.back.next === null || pagination.next.count === 'null')
    ) {
      pagination.back = {};
    }
  }
  // const sortedData = sortByCapacityTypeCode(data);
  dataResponse.data = data;
  dataResponse.pagination = pagination;
  dataResponse.time = new Date().getTime();
  dataResponse.query = dashBoardDataResponse.query || '';
  return data.length === 0 ? {} : dataResponse;
};

const modifyFactGrpSearchData = resp => {
  let retArr = [];
  let setArray = [];
  if (resp) {
    if (Array.isArray(resp)) {
      //only possibility for multiple in new global implementation
      if (resp.length > 0) {
        resp.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            // setArray = [...setArray, ...icc.capacityTypeCodes];

            icc.capacityTypeCodes.forEach((icct, i) => {
              let newCode = {
                name: icct.capacityTypeCode,
                value: icct.capacityTypeCode,
                key: icct.capacityTypeCode,
                label: icct.capacityTypeCode,
                factoryCode: icc.factoryCode,
                id: `${i + 1000}`
              };
              retArr.push(newCode);
            });
          }
        });
      }
    } else {
      let cArr = resp.capacities;
      if (cArr.length > 0) {
        cArr.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    }
  }
  // let retUnQ = _.uniqBy(retArr, 'key');
  let retUnQ = _.sortBy(retArr, 'key');

  return retUnQ;
};

export default function dashboard(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('dashboardData', 'loading').type:
      return state.set('dashboardDataLoading', action.data);

    case createServiceAction('dashboardData', 'success').type:
      return state.set('dashboardData', modifyDashboardData(action.data));

    case createServiceAction('dashboardData', 'clear').type:
      return state.set('dashboardData', action.data);

    case createServiceAction('dashboardData', 'error').type:
      return state.set('dashboardError', action.data);

    case createServiceAction('dashboardGridColumns', 'loading').type:
      return state.set('dashboardGridLoading', action.data);

    case createServiceAction('dashboardGridColumns', 'success').type:
      return state.set(
        'dashboardGridColumns',
        modifyDashboardHeader(action.data)
      );

    case createServiceAction('dashboardGridColumns', 'clear').type:
      return state.set('dashboardGridColumns', action.data);

    case createServiceAction('dashboardGridColumns', 'error').type:
      return state.set('dashboardGridError', action.data);

    case createServiceAction('dashboardDataUpdate', 'success').type:
      return state.set('dashboardDataUpdate', action.data);

    case createServiceAction('dashboardDataUpdate', 'error').type:
      return state.set('dashboardDataUpdate', action.data);

    case createServiceAction('dashboardDataUpdate', 'clear').type:
      return state.set('dashboardDataUpdate', action.data);

    case createServiceAction('updateDashboardComment', 'success').type:
      return state.set('onUpdateDashboardComment', action.data);

    case createServiceAction('testDetails', 'success').type:
      return state.set('testDetailData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'loading').type:
      return state.set('usrFactGrpSearchLoading', action.data);

    case createServiceAction('usrFactGrpSearchData', 'success').type:
      return state.set(
        'usrFactGrpSearchData',
        modifyFactGrpSearchData(action.data)
      );

    case createServiceAction('usrFactGrpSearchData', 'clear').type:
      return state.set('usrFactGrpSearchData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'error').type:
      return state.set('usrFactGrpSearchError', action.data);

    default:
      return state;
  }
}

export const getDashboardDataLoading = state =>
  state.dashboard.get('dashboardDataLoading');
export const getDashboardData = state => state.dashboard.get('dashboardData');
export const getDashboardError = state => state.dashboard.get('dashboardError');

export const getDashboardGridLoading = state =>
  state.dashboard.get('dashboardGridLoading');
export const getDashboardGridError = state =>
  state.dashboard.get('dashboardGridError');
export const getDashboardGridColumns = state =>
  state.dashboard.get('dashboardGridColumns');

export const getusrFactGrpSearchLoading = state =>
  state.dashboard.get('usrFactGrpSearchLoading');
export const getusrFactGrpSearchError = state =>
  state.dashboard.get('usrFactGrpSearchError');
export const getusrFactGrpSearchData = state =>
  state.dashboard.get('usrFactGrpSearchData');

export const updateDashboardData = state =>
  state.dashboard.get('dashboardDataUpdate');

export const getDashboardDataUpdate = state =>
  state.dashboard.get('dashboardDataUpdate');

export const updateDashboardComment = state =>
  state.dashboard.get('updateDashboardComment');

export const getUpdateDashboardCommentStatus = state =>
  state.dashboard.get('onUpdateDashboardComment');

export const getTestDetailData = state => state.dashboard.get('testDetailData');
