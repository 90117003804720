import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  lrcpLoading: false,
  lrcpData: {},
  lrcpError: {},
  updateLrcpLoading: false,
  updateLrcpSuccess: false,
  updateLrcpFailed: false,
  lrcpListData: {}
});

const modifyLrcpData = data => {
  const returnData = data;
  // statements to modify data if required
  return returnData;
};

export default function lrcp(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('lrcpData', 'success').type:
      return state.set('lrcpData', modifyLrcpData(action.data));

    case createServiceAction('lrcpData', 'loading').type:
      return state.set('lrcpLoading', action.data);

    case createServiceAction('lrcpData', 'clear').type:
      return state.set('lrcpData', action.data);

    case createServiceAction('lrcpData', 'error').type:
      return state.set('lrcpError', action.data);

    case createServiceAction('updateLrcpData', 'loading').type:
      return state.set('updateLrcpLoading', action.data);

    case createServiceAction('updateLrcpData', 'success').type:
      return state.set('updateLrcpSuccess', action.data);

    case createServiceAction('updateLrcpData', 'error').type:
      return state.set('updateLrcpFailed', action.data);

    case createServiceAction('updateLrcpData', 'clear').type:
      return state.set('updateLrcpData', action.data);

    default:
      return state;
  }
}

export const getLrcpData = state => state.lrcp.get('lrcpData');
export const getLrcpLoading = state => state.lrcp.get('lrcpLoading');
export const getLrcpError = state => state.lrcp.get('lrcpError');
export const updateLrcpData = state => state.lrcp.get('updateLrcpData');
export const getUpdatingLrcpLoading = state =>
  state.lrcp.get('updateLrcpLoading');
export const getUpdatingLrcpSuccess = state =>
  state.lrcp.get('updateLrcpSuccess');
export const getUpdatingLrcpFailed = state =>
  state.lrcp.get('updateLrcpFailed');
