/* eslint-disable max-statements */
import { toast } from 'react-toastify';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { putRequest, postRequest } from '../util/httpRequest.js';

import { put, takeEvery, call, all } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  const data = { ...action.data };
  const sourceData = JSON.parse(JSON.stringify(data));
  const act = data.action;
  let sourceNotifications = [];
  let apiUrl = '';
  if (data.action === 'pending') {
    apiUrl = apiConfig.updateUserUpdatePending.endPointUrl;
    sourceNotifications =
      data && data.uniqueNotificationIds
        ? JSON.parse(JSON.stringify(data.uniqueNotificationIds))
        : [];
  } else {
    apiUrl = apiConfig.updateUserUpdateRead.endPointUrl;
    sourceNotifications =
      data && data.notifications
        ? JSON.parse(JSON.stringify(data.notifications))
        : [];
  }

  const toastSettings = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  };
  try {
    yield put(createServiceAction(serviceType, 'loading')(true));
    delete data.action;
    const batchPromise = [];
    let size = 500;

    for (let i = 0; i < sourceNotifications.length; i += size) {
      const newData = JSON.parse(JSON.stringify(sourceData));
      if (act === 'pending') {
        newData.uniqueNotificationIds = sourceNotifications.slice(i, i + size);
        batchPromise.push(call(putRequest, apiUrl, newData, true));
      } else {
        newData.notifications = sourceNotifications.slice(i, i + size);
        batchPromise.push(call(postRequest, apiUrl, newData, true));
      }
    }

    yield put(createServiceAction(serviceType, 'loading')(false));
    const resPromise = yield all([...batchPromise]);

    const resdata = yield all(resPromise.map(obj => obj.json()));

    let result = {};
    let responseLength = 0;
    resdata.forEach(obj => {
      responseLength += Object.keys(obj).length;
      result = { ...result, ...obj };
    });
    if (responseLength === sourceNotifications.length) {
      yield put(createServiceAction(serviceType, 'success')({ result, act }));
      const message =
        act === 'pending'
          ? `Notification marked as Not Pending`
          : 'Capacity Record Marked as Read';
      toast.success(message, toastSettings);
    } else if (
      responseLength > 0 &&
      responseLength < sourceNotifications.length
    ) {
      yield put(createServiceAction(serviceType, 'success')({ result, act }));
      const message =
        act === 'pending'
          ? `Notification Partially marked as Not Pending`
          : 'Capacity Record Partially  Marked as Read';
      toast.warning(message, toastSettings);
    } else {
      yield put(createServiceAction(serviceType, 'error')({ result, act }));

      const message =
        act === 'pending'
          ? `Failed to  clear Notification Pending`
          : 'Failed to  mark Notification  as Read';
      toast.error(message, toastSettings);
    }
  } catch (e) {
    yield put(
      createServiceAction(serviceType, 'error')({ result: false, act })
    );
    const message =
      act === 'pending'
        ? `Failed to  clear Notification Pending`
        : 'Failed to  mark Notification  as Read';
    toast.error(message, toastSettings);
  }
}

function* updateUserUpdateDataSagas() {
  const serviceType = 'updateUserUpdateData';

  yield takeEvery(
    createServiceAction(serviceType, 'submited').type,
    listener,
    serviceType
  );
}

export default updateUserUpdateDataSagas;
