import _ from 'lodash';

// ** check how many digits are after Decimals*/
const countDecimals = value => {
  if (Math.floor(value) === value) return 0;
  if (value.toString().split('.')[1]) {
    return value.toString().split('.')[1].length || 0;
  }
  return 0;
};

//** validate Number Digits and Decimals digits and negative value */
export const checkNumberDigits = (
  number, // value
  digitWants, // how many digits should allow
  decimalDigitWants = 2 // how many decimal digits should allow by default it is 2
) => {
  let result; // will return true if validation pass either false
  if (!_.isNaN(Number(number.indexOf(' ') >= 0 ? '-' : number))) {
    if (number < 0 || number % 1 !== 0) {
      if (countDecimals(number) <= decimalDigitWants) {
        result =
          (number + '').replace('.', '').replace('-', '').length <= digitWants;
      } else {
        result = false;
      }
    } else if (Math.abs(number) === 0) {
      if (number.split('0').length <= 2) {
        result = String(number).length <= digitWants;
      } else {
        result = number;
      }
    } else {
      result = String(Math.abs(number)).length <= digitWants;
    }

    return result;
  } else {
    if (number === '-') {
      //** if first sign in negative sign*/
      return true;
    }
    //**if it is not number or valid sign */
    return false;
  }
};
