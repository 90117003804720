import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { postRequest } from '../util/httpRequest.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch DASHBOARD_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'commentsLogsData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));
    // Trigger Call to API endpoint
    // let options = {};
    const { idSet, ids } = action.data;
    // const data = {
    //   ids
    // }
    const payload = ids && ids.length !== 0 ? { ids } : { idSet };
    let res;
    let url = `${apiConfig.getCommentByBatch.endPointUrl}/`;

    res = yield call(postRequest, url, payload, true);

    if (Number(res.status) === 400 || Number(res.status) === 504) {
      const responsemessage = yield res.text() || res.json();
      const errorResponse = {
        status: Number(res.status),
        message: responsemessage
      };

      yield put(createServiceAction(serviceType, 'error')(errorResponse));
    } else {
      const response = yield res.json();
      yield put(
        createServiceAction(serviceType, 'success')({
          commentsLogsData: response
        })
      );
    }
    // Dispatch DASHBOARD_DATA_LOADING action with response object
    yield put(createServiceAction(serviceType, 'loading')(false));
    // yield put(createServiceAction(serviceType, 'success')(response));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    // Dispatch DASHBOARD_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* commentsLogsDataSagas() {
  const serviceType = 'commentsLogsData';
  // Listen to DASHBOARD_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default commentsLogsDataSagas;
