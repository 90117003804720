import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Link, H4 } from '../../components/index.js';
import {
  navMenuToggle,
  navMenuclose
} from '../../actions/navigationActions.js';
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import {
  selectFullName,
  selectUserAccessToken
} from '../../reducers/authReducer.js';
const navStyle = 'nav  u-no-ws';

const returnURLQuery = (location, currentPathName, to) => {
  const { query } = location;
  let filtered = {};
  if (query && Object.keys(query).length > 0) {
    let allowed = ['factoryGroup', 'factoryCode', 'capacityTypeCode'];
    filtered = Object.keys(query)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = query[key];
        return obj;
      }, {});
  }
  return `?${qs.stringify(filtered)}`;
};

const getActiveClass = (to, currentPathName) => {
  const toLCase = to.toLowerCase();
  const cpLCase = currentPathName.toLowerCase();
  return toLCase.includes(cpLCase) ? 'text-color-info' : '';
};

const NavLinkItem = ({
  to,
  label,
  icon,
  heading,
  url,
  name,
  currentPathName
}) =>
  !url ? (
    <li className="text-color-grey">
      {heading && (
        <H4 className="u-bold text-color-grey pb4-sm seperation-header">
          {heading === 'MYSTUFF' ? (
            <span>{name && name.toUpperCase()}</span>
          ) : (
            <span>{heading}</span>
          )}
        </H4>
      )}

      {label && label.toString().length && to && (
        <NavLink to={to} className={getActiveClass(to, currentPathName)}>
          <span className="menu-item">
            {icon}
            {label}
          </span>
        </NavLink>
      )}
    </li>
  ) : (
    <li className="text-color-grey extlink">
      <a
        href={url}
        target={to !== '/help' ? '_blank' : null}
        rel="noopener noreferrer"
      >
        {heading && (
          <H4 className="u-bold text-color-grey pb4-sm seperation-header">
            {heading === 'MYSTUFF' ? (
              <span>{name.toUpperCase()}</span>
            ) : (
              <span>{heading}</span>
            )}
          </H4>
        )}
        <span className="menu-item">
          {icon}
          {label}
        </span>
      </a>
    </li>
  );

const SlideMenuContainer = ({
  isOpen,
  navMenuclose,
  children,
  navLinks,
  name,
  currentPathName,
  location
}) => (
  <div className={`menu-container g-b-d-menu ${isOpen ? 'show-menu' : ''}`}>
    <div className="menu-aside" role="none" onClick={navMenuclose}>
      <Link
        to="/"
        className="pt1-sm prl6-sm pb1-sm d-sm-b border-bottom-light-grey menu-header sidebar"
      >
        <H4 className="inline-display header-brand ">
          <i className="g72-swoosh   fs22-sm menu-logo" />
          <span className="  u-bold">Capacity Visibility</span>
        </H4>
      </Link>
      <nav className={navStyle}>
        <ul>
          {navLinks.map(
            ({ to, label, icon, heading, id, url, displayCapSearch }, i) => (
              <NavLinkItem
                key={`menu-${i}-${id}`}
                to={`${to}${
                  displayCapSearch
                    ? returnURLQuery(location, currentPathName, to)
                    : ''
                }`}
                url={url}
                heading={heading}
                name={name}
                icon={icon}
                label={
                  <span className="fs18-sm u-bold label-pos">{label}</span>
                }
                currentPathName={currentPathName}
              />
            )
          )}
        </ul>
      </nav>
    </div>
    <div className="viewport">
      {}
      {children}
    </div>
    <div className="menu-lightbox" role="none" onClick={navMenuclose} />
  </div>
);
SlideMenuContainer.propTypes = {
  isOpen: PropTypes.bool,
  navMenuclose: PropTypes.func,
  navLinks: PropTypes.array,
  name: PropTypes.string,
  currentPathName: PropTypes.string
};
NavLinkItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.object,
  icon: PropTypes.object,
  heading: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  currentPathName: PropTypes.string
};
export default withRouter(
  connect(
    (state, ownProps) => ({
      isOpen: selectIsMenuOpen(state),
      name: selectFullName(state),
      userToken: selectUserAccessToken(state),
      navLinks: ownProps.navLinks || []
    }),
    { navMenuToggle, navMenuclose }
  )(SlideMenuContainer)
);
