import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Spinner } from '../../components/index.js';
import NotFound from '../../components/errors/NotFound.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';

import {
  getAppConfigData,
  getAppConfigLoading,
  getAppConfigError
} from '../../reducers/appConfigReducer.js';

import { landingOptions } from '../../constants/appConstants.js';

export class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGrid: false,
      landingOptions: [...landingOptions]
    };
  }

  componentDidMount() {
    const { requestUserProfileData } = this.props;
    requestUserProfileData();
  }

  componentWillReceiveProps(nextProps) {
    const { userProfileData, appConfigData, requestAppconfigData } = this.props;
    if (userProfileData !== nextProps.userProfileData) {
      if (
        nextProps.userProfileData &&
        Object.keys(nextProps.userProfileData).length !== 0
      ) {
        requestAppconfigData();
      }
    }
    if (appConfigData !== nextProps.appConfigData) {
    }
  }
  render() {
    const {
      userProfileData,
      userProfileError,
      appConfigData,
      appConfigError
    } = this.props; // receive from API response
    const { landingOptions } = this.state; // receive from API response
    let defaultRedirect = null;
    if (appConfigData && Object.keys(appConfigData).length !== 0) {
      if ('landingPage' in appConfigData) {
        defaultRedirect =
          landingOptions.find(o => o.value === appConfigData.landingPage).url ||
          landingOptions[0].url;
      }
    }
    let showError = false;
    if (userProfileError && appConfigError) {
      showError =
        Object.keys(userProfileError).length > 0 ||
        Object.keys(appConfigError).length > 0
          ? true
          : false;
    }

    return (
      <div className="page-align-items">
        <div className="dashboard">
          <div className="ncss-container">
            {showError && <NotFound status="401" />}
            {!userProfileError && userProfileData && (
              <div>
                {defaultRedirect && <Redirect to={defaultRedirect} push />}
              </div>
            )}
            {!userProfileError && !userProfileData && <Spinner large />}
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  appConfigData: PropTypes.object,
  appConfigLoading: PropTypes.any,
  appConfigError: PropTypes.any,
  requestAppconfigData: PropTypes.func
};

const mapStateToProps = state => ({
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  appConfigData: getAppConfigData(state),
  appConfigLoading: getAppConfigLoading(state),
  appConfigError: getAppConfigError(state)
});

const mapDispatchToProps = dispatch => ({
  requestUserProfileData: () => {
    dispatch(createServiceAction('getUserProfile', 'requested')());
  },
  requestAppconfigData: () => {
    dispatch(createServiceAction('appConfigData', 'requested')());
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LandingPage)
);
