import React from 'react';
import PropTypes from 'prop-types';

const getButtonClass = props => {
  const style = [`ncss-btn-${props.color || 'primary-dark'} `];

  //if (!props.matchCase) style.push('');
  if (props.small) style.push('pt1-sm pr3-sm pb1-sm pl3-sm');
  else if (props.thin) style.push('');
  // Not yet determined
  else if (props.large) style.push('');
  // Not yet determined
  else style.push('pt1-sm pr3-sm pb1-sm pl3-sm');

  if (props.extraClassName) style.push(props.extraClassName);

  return style.join(' ');
};

// This is a tactic for keeping extra props from getting to the wrapped element
// See https://facebook.github.io/react/warnings/unknown-prop.html for details
const Button = ({
  extraClassName,
  small,
  thin,
  large,
  submit,
  className,
  children,
  ...props
}) => (
  <button
    {...{
      type: submit ? 'submit' : 'button',
      ...props,
      className:
        className ||
        getButtonClass({ ...props, small, thin, large, extraClassName })
    }}
  >
    {children}
  </button>
);
Button.propTypes = {
  extraClassName: PropTypes.string,
  small: PropTypes.string,
  thin: PropTypes.string,
  large: PropTypes.string,
  className: PropTypes.string,
  submit: PropTypes.string
};
export default Button;

export const IconButton = ({ icon, color, extraClassName, ...props }) => (
  <button
    type="button"
    className={`btn icon-solo ${extraClassName || ''}`}
    {...props}
  >
    <i className={`fas fa-${icon} fa-fw text-color-${color || 'black'}`} />
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  extraClassName: PropTypes.string
};
