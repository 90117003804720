import React from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import PropTypes from 'prop-types';

export default ({ hover, click, focus, trigger, placement = 'right', content, children }) => {
  if ((hover || click || focus) && trigger)
    throw new Error(
      'trigger cannot be used with click, hover, and focus. trigger is the long-form of those options'
    );
  if (!trigger) {
    trigger = [];
    if (click) trigger.push('click');
    if (hover) trigger.push('hover');
    if (focus) trigger.push('focus');
    if (!trigger.length) trigger.push('hover');
  }
  return (
    (
      <Tooltip placement={placement} trigger={trigger} overlay={content}>
        {children}
      </Tooltip>
    ),
    {
      propTypes: {
        hover: PropTypes.bool,
        click: PropTypes.bool,
        focus: PropTypes.bool,
        trigger: PropTypes.arrayOf,
        placement: PropTypes.string,
        content: PropTypes.func,
        children: PropTypes.func
      }
    }
  );
};
