const searchConstants = {
  groups: [
    {
      name: 'Factory Group',
      id: 'dsc112',
      field: 'factoryGroup',
      value: '',
      type: 'select',
      format: 'text',
      placeHolder: '',
      cssClass: 'float',
      required: false,
      warnings: '',
      show: true,
      options: [],
      selected: {},
      maxLimit: 1,
      multiSelect: false
    },
    {
      name: 'Factory Code',
      id: 'dsc111',
      field: 'factoryCode',
      value: '',
      type: 'select',
      format: 'text',
      placeHolder: '',
      cssClass: 'float',
      required: true,
      warnings: '',
      show: true,
      options: [],
      selected: {},
      maxLimit: 5,
      multiSelect: false
    },
    {
      name: 'Capacity Type Code',
      id: 'dsc3',
      field: 'capacityTypeCode',
      value: '',
      type: 'select',
      format: 'text',
      placeHolder: '',
      cssClass: 'float',
      required: false,
      warnings: '',
      show: true,
      disabled: true,
      selected: {},
      maxLimit: 5,
      multiSelect: false
    },
    {
      name: 'Bus Wk Start (Mon)',
      id: 'dsc4',
      field: 'startWeekDate',
      value: '',
      type: 'date',
      placeHolder: '',
      format: 'MM/dd/y',
      cssClass: 'litegrey',
      required: false,
      warnings: '',
      show: true,
      fromMonth: new Date(),
      toMonth: new Date()
    },
    {
      name: 'Bus Wk End (Mon)',
      id: 'dsc5',
      field: 'endWeekDate',
      value: '',
      type: 'date',
      placeHolder: '',
      format: 'MM/dd/y',
      cssClass: 'litegrey',
      required: false,
      warnings: '',
      show: true,
      fromMonth: new Date(),
      toMonth: new Date()
    }
  ]
};
export default searchConstants;
