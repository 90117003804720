import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../sagas/index.js';
import rootReducer from '../reducers/index.js';

export const HYDRATE_STATE = 'HYDRATE_STATE';

// If we ever do server-side rendering, the initial state needs to check
// for pre-hydrated data, probably in window.__initialState
const initialState = {};

const sagaMiddleware = createSagaMiddleware();

function makeHydratable(reducer, hydrateActionType) {
  return (state, action) => {
    switch (action.type) {
      case hydrateActionType:
        return reducer(action.state, action);
      default:
        return reducer(state, action);
    }
  };
}

const configureStore = () => {
  const store = createStore(
    makeHydratable(rootReducer, HYDRATE_STATE),
    initialState,
    compose(
      applyMiddleware(sagaMiddleware),
      typeof window === 'object' &&
        typeof window.devToolsExtension !== 'undefined'
        ? window.devToolsExtension()
        : f => f
    )
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers/index', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
