/* eslint-disable max-statements */
import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';
import { all, put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch DASHBOARD_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'dashboardData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));
    // Trigger Call to API endpoint
    // comment this for real data // ${action.data.user}/role/${action.data.role}
    let options = {};
    let getDashDataUrl = `${apiConfig.dashboardPmdData.endPointUrl}dashboard/${action.data.dashboard}`;
    let notesDataUrl = `${apiConfig.dashboardPmdData.endPointUrl}dashboard/${action.data.dashboard}`;
    let res;
    let notes;
    // Removing seperate holiday API
    // let getHolidayApiUrl = `${apiConfig.holidayApiData.endPointUrl}/${action.data.factoryCode}`;
    if (action.data.type === 'bulk') {
      getDashDataUrl = `${apiConfig.dashboardMassData.endPointUrl}${
        action.data.dashboard === 'MaxAvailable' ? 'strategic' : 'revised'
      }`;
      notesDataUrl = `${apiConfig.dashboardMassData.endPointUrl}${
        action.data.dashboard === 'MaxAvailable' ? 'strategic' : 'revised'
      }`;
    }
    if (action.data.type === 'notifications') {
      getDashDataUrl = `${apiConfig.dashboardSelectedWeeks.endPointUrl}`;
    }
    if (action.data.searchfields.length > 0) {
      getDashDataUrl = `${getDashDataUrl}?${action.data.searchfields}`;
    }
    const urlparams = new URLSearchParams(action.data.searchfields);
    const capacityTypeCode = urlparams.get('capacityTypeCode');
    if (action.data.type === 'notifications' || capacityTypeCode) {
      res = yield call(getRequest, getDashDataUrl, options, true);
    } else {
      const batchPromise = [];
      let anchor = urlparams.get('anchor');
      const factoryCode = urlparams.get('factoryCode');
      const boundaryWeek = urlparams.get('boundaryWeek');
      const startWeekDate = urlparams.get('startWeekDate');
      const endWeekDate = urlparams.get('endWeekDate');
      const capacityTypeCode = urlparams.get('capacityTypeCode');
      const counter = 8;
      const count = 500;
      let searchfields = '';
      const noteData = [];
      for (var i = 1; i <= counter; i++) {
        let noteurl = '';

        if (i === 1) {
          anchor = Number(anchor);
        } else {
          anchor = Number(anchor) + count;
        }

        searchfields = `factoryCode=${factoryCode}&boundaryWeek=${boundaryWeek}&anchor=${anchor}&count=${count}`;
        if (startWeekDate) searchfields += `startWeekDate=${startWeekDate}`;
        if (endWeekDate) searchfields += `endWeekDate=${endWeekDate}`;
        if (capacityTypeCode)
          searchfields += `capacityTypeCode=${capacityTypeCode}`;
        noteurl = `${notesDataUrl}?${searchfields}&fieldFilter=notes`;
        batchPromise.push(call(getRequest, noteurl, options, true));
      }
      const [
        resPromise,
        obj1,
        obj2,
        obj3,
        obj4,
        obj5,
        obj6,
        obj7,
        obj8
      ] = yield all([
        call(getRequest, getDashDataUrl, options, true),
        ...batchPromise
      ]);
      res = resPromise;
      const notesPromise = yield all([
        obj1.json(),
        obj2.json(),
        obj3.json(),
        obj4.json(),
        obj5.json(),
        obj6.json(),
        obj7.json(),
        obj8.json()
      ]);
      notesPromise.forEach(note => {
        if (note && note.data && note.data.length !== 0) {
          noteData.push(...note.data);
        }
      });
      if (noteData.length !== 0) notes = noteData;
    }

    // const holidayRes = yield call(getRequest, getHolidayApiUrl, options, true);

    if (Number(res.status) === 400 || Number(res.status) === 504) {
      const responsemessage = yield res.text() || res.json();
      const errorResponse = {
        status: Number(res.status),
        message: responsemessage
      };

      yield put(createServiceAction(serviceType, 'error')(errorResponse));
    } else {
      let response = yield res.json();
      let finalResult;
      if (notes && notes.length > 0 && response && response.data.length > 0) {
        const data = response.data;
        finalResult = data.map(dataItem => {
          return {
            ...dataItem,
            ...notes.find(noteItem => noteItem.id === dataItem.id)
          };
        });
        response.data = finalResult;
      }

      yield put(
        createServiceAction(serviceType, 'success')({
          dashboardData: response,
          holidayData: {},
          query: getDashDataUrl
        })
      );
    }
    // Dispatch DASHBOARD_DATA_LOADING action with response object
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'error')(null));
    // yield put(createServiceAction(serviceType, 'success')(response));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    // Dispatch DASHBOARD_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* dashboardDataSagas() {
  const serviceType = 'dashboardData';
  // Listen to DASHBOARD_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default dashboardDataSagas;
