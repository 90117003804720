import React from 'react';
import PropTypes from 'prop-types';
import { Container, H1, NotFound } from '../index.js';

const is404 = error =>
  error &&
  ((error.status && error.status === 404) ||
    (error.response && is404(error.response)));

const isusernotfound = "Cannot read property 'map' of undefined";
const Generic = ({ error }) => {
  if (is404(error)) return <NotFound />;
  if (isusernotfound)
    return (
      <Container className="usernotfound errorCenter">
        <H1> Access Restricted. </H1>
        <h4>
          The resource you requested was not assigned to you. Please contact the
          Administrator or Support team to get access.
        </h4>
      </Container>
    );
  return (
    <Container section>
      <H1>An error occured, unable to load page.</H1>
      {error ? <p className="errorContent">{error.message || error}</p> : null}
    </Container>
  );
};

Generic.propTypes = {
  error: PropTypes.func
};
export default Generic;
