import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { notifyOffline } from '../../actions/offlineActions.js';
import { isOffline } from '../../reducers/offlineReducer.js';

const OfflineBanner = ({ offline }) =>
  offline ? (
    <div className="ncss-container bg-accent mb2-sm offline-section text-color-white u-align-center">
      <span className="fs20-sm u-sm-ib pr4-sm pl4-sm">
        <i className="g72-alert" />
      </span>
      <span className="ncss-brand">OFFLINE MODE</span>
      <span className="pl2-sm">functionality may be limited</span>
    </div>
  ) : null;

OfflineBanner.propTypes = {
  offline: PropTypes.bool
};
export default connect(
  state => ({
    offline: isOffline(state)
  }),
  { notifyOffline }
)(OfflineBanner);
