import React from 'react';
import PropTypes from 'prop-types';

export class DropDown extends React.Component {
  render() {
    const {
      className,
      options,
      value,
      onChange,
      onOptionSelect,
      placeholder,
      dependentField,
      change,
      defaultValue
    } = this.props;
    return (
      <select
        className={`Select-input select-input-font ncss-input pt2-sm pr4-sm pb2-sm pl4-sm ${className}`}
        defaultValue={defaultValue}
        value={value === '' ? defaultValue : value}
        onChange={e => {
          onChange(e.target.value);
          // eslint-disable-next-line no-unused-expressions
          onOptionSelect && onOptionSelect(e.target.value);
          if (dependentField) {
            change(dependentField, null);
          }
        }}
        selected={defaultValue}
      >
        <option value="" hidden selected={value === ''}>
          {placeholder}
        </option>
        {options.map(option =>
          option.sub_type ? (
            <optgroup label={option.label}>
              {option.sub_types.map(groupOption => (
                <option value={groupOption.value} key={groupOption.id}>
                  {groupOption.label}
                </option>
              ))}
            </optgroup>
          ) : (
            <option
              value={option.value}
              key={option.id}
              selected={option.label === defaultValue}
            >
              {option.label}
            </option>
          )
        )}
      </select>
    );
  }
}
const CustomDropDown = ({
  input,
  options,
  className,
  name,
  placeholder,
  dependentField,
  change,
  onOptionSelect
}) => (
  <DropDown
    {...input}
    options={options}
    name={name}
    placeholder={placeholder}
    className={className}
    value={input.value}
    onChange={input.onChange}
    {...(onOptionSelect && { onOptionSelect })}
    dependentField={dependentField}
    change={change}
  />
);
CustomDropDown.propTypes = {
  input: PropTypes.string,
  name: PropTypes.string,
  onOptionSelect: PropTypes.any,
  options: PropTypes.array,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  dependentField: PropTypes.string,
  change: PropTypes.func,
  defaultValue: PropTypes.number
};
DropDown.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  dependentField: PropTypes.string,
  change: PropTypes.func,
  defaultValue: PropTypes.number,
  onOptionSelect: PropTypes.any
};
export default CustomDropDown;
