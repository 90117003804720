/* eslint-disable max-statements */
import React, { Component } from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Beforeunload } from 'react-beforeunload';
import qs from 'qs';
import { msgConstants } from '../../constants/msgConstants.js';
import {
  getGrowthLeverQuarterDataLoading,
  getGrowthLeverQuarterData,
  getGrowthLeverQuarterError,
  getusrFactGrpSearchLoading,
  getusrFactGrpSearchError,
  getGrowthLeverQuarterDataUpdate
} from '../../reducers/growthLeverQuarterReducer.js';
import NotFound from '../../components/errors/NotFound.js';

import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { getUserNotifyData } from '../../reducers/userNotifyReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
// import { AppCipher, AppDeCipher } from '../../util/cipher.js';

import {
  updateErrorReport,
  updateFailedReport
} from '../../services/growthLeverService.js';

import {
  getEditabLeversList,
  leverDataModify,
  isLeverEdited
} from '../../services/growthLeverQuarterService.js';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';
import growthLeverQuarterSearchConstants from './growthLeverSearch.constants.js';
// import { isEquivalent } from '../../util/general.js';
import {
  getAppConfigData,
  getAppConfigLoading,
  getAppConfigError
} from '../../reducers/appConfigReducer.js';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LeversTabSection from '../../components/Lever/LeversTabSection.js';
import Summary from '../../components/Lever/summary';
import {
  getGrowthLeverListData,
  getGrowthLeverListDataLoading,
  getGrowthLeverListError,
  getGrowthLeverListDataUpdate
} from '../../reducers/growthLeverListReducer.js';

import { Spinner } from '../../components/index.js';
export class growthLeverQuarter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGrid: false,
      userType: '',
      varianceValidationModal: false,
      saveDisabled: true,
      UrlqueryParamObj: {},
      UploadStatusData: [],
      context: { componentParent: this },
      defaultStatus: 'MaxAvailable',
      statusCode: '',
      gridPagination: {},
      searchGroups: growthLeverQuarterSearchConstants.groups,
      searchParams: growthLeverQuarterSearchConstants.fields,
      changedRowsData: [],
      validationResult: [],
      pageSearch: 0,
      revisedHorizonWeeks: 0,
      leverQuarter: {},
      leverQuarterBackup: {},
      varianceLeverList: {},
      leverRow: {
        newItem: true,
        lever: '',
        dimension: '',
        category: '',
        leverCode: '',
        capacity: '',
        cost: '',
        validityDate: '',
        srcDecision: 'TBD',
        leverQty: '',
        comment: ''
      },
      leverColumns: [],
      partialQuarter: false,
      LeverQuartersList: { default: {}, options: [] },
      defaultTabIndex: 0,
      isHistoryAvailable: false
    };

    this.getGrowthLeverQuarterType = this.getGrowthLeverQuarterType.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.addVarianceLever = this.addVarianceLever.bind(this);
    this.deleteVarianceLever = this.deleteVarianceLever.bind(this);
    this.updateVarianceLever = this.updateVarianceLever.bind(this);
    this.quarterSelectHandler = this.quarterSelectHandler.bind(this);
    this.uploadStatusUpdate = this.uploadStatusUpdate.bind(this);
  }

  componentDidMount() {
    // On page load, loading max available growthLeverQuarter as default
    let defaultStatus = 'MaxAvailable';
    const { location, appConfigData } = this.props;

    const { pathname } = location;
    if (pathname && pathname.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    //**Getting factory code ,factory group(fg) , capacity ,prod quarter(pq) from URl */
    const UrlqueryParamObj = {
      factoryCode: null,
      capacityTypeCode: null,
      factoryGroup: null,
      productionQuarter: null,
      ...location.query
    };
    let rvsdHorzLocal = localStorage.getItem('revisedHorizonWeeks');
    let revisedHorizonWeeks = rvsdHorzLocal ? Number(rvsdHorzLocal) : 0;
    if (appConfigData && appConfigData.revisedHorizonWeeks) {
      revisedHorizonWeeks = appConfigData.revisedHorizonWeeks;
    }
    this.setState({
      ...this.state,
      revisedHorizonWeeks,
      UrlqueryParamObj: UrlqueryParamObj,
      defaultStatus: defaultStatus
    });
    // Function to call API for Grid Data
    setTimeout(() => {
      this.getGrowthLeverQuarterType(defaultStatus);
    }, 1000);
    localStorage.setItem('isLeverQuarterDataChanged', 'false');
  }

  // eslint-disable-next-line complexity
  componentWillReceiveProps(nextProps) {
    // On page load, loading max available growthLeverQuarter as default
    const {
      searchGroups,
      UploadStatusData,
      factoryType,
      UrlqueryParamObj,
      defaultStatus
    } = this.state;
    const {
      location,
      growthLeverQuarterData,
      userProfileData,
      growthLeverQuarterDataUpdate,
      growthLeverListData,
      appConfigData,
      clearGrowthLeverQuarterData
    } = this.props;
    const { pathname, search } = location;
    const newPathName = nextProps.location.pathname;
    const newSearch = nextProps.location.search;
    const oldPathName = pathname;

    if (
      search !== nextProps.location.search &&
      nextProps.location.search === ''
    ) {
      // pending
      this.setState({
        updateCycleComplete: false,
        loadGrid: true,
        changedRowsData: []
      });

      clearGrowthLeverQuarterData({});
    }

    if (userProfileData !== nextProps.userProfileData) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        const allFG = [
          {
            name: 'All Factory Groups',
            value: '',
            key: 'AllFactoryGroups',
            label: 'All Factory Groups'
          }
        ];
        newUsrProData.factoryGroups = _.sortBy(
          newUsrProData.factoryGroups,
          'name'
        );
        searchGroups.find(o => o.field === 'factoryGroup').options =
          [...allFG, ...newUsrProData.factoryGroups] || [];
        const CodeIndx = searchGroups.findIndex(o => o.field === 'factoryCode');
        if (CodeIndx >= 0) {
          const AllFactoryCodes = newUsrProData.factoryCodes;

          if (AllFactoryCodes[0].name === 'ALL') {
            AllFactoryCodes.shift();
          }
          searchGroups[CodeIndx].options = AllFactoryCodes;
          searchGroups[CodeIndx].backOptions = AllFactoryCodes;
        }
        this.setState({
          searchGroups,
          factoryType: nextProps.userProfileData.factoryType || ''
        });
      }
    }
    if (newPathName !== oldPathName || search !== newSearch) {
      setTimeout(() => {
        this.getGrowthLeverQuarterType(defaultStatus);
      }, 1000);
    }
    if (growthLeverQuarterData !== nextProps.growthLeverQuarterData) {
      const leverQuarter = leverDataModify(nextProps.growthLeverQuarterData);
      const partialQuarter = leverQuarter && leverQuarter.partialQuarter;
      //isHistoryAvailable
      const isHistoryAvailable =
        leverQuarter && leverQuarter.version ? true : false;
      this.setState({
        isHistoryAvailable: isHistoryAvailable,
        leverQuarter: leverQuarter,
        partialQuarter: partialQuarter,
        leverQuarterBackup: JSON.parse(JSON.stringify(leverQuarter))
      });
    }
    if (growthLeverListData !== nextProps.growthLeverListData) {
      // PQ COL
      const varianceLeverList = getEditabLeversList(
        nextProps.growthLeverListData.varianceList,
        factoryType
      );
      this.setState({
        varianceLeverList,
        userRole: nextProps.growthLeverListData.role
      });
    }

    let rvsdHorzLocal = localStorage.getItem('revisedHorizonWeeks');
    let revisedHorizonWeeks = rvsdHorzLocal ? Number(rvsdHorzLocal) : 0;
    if (appConfigData || appConfigData !== nextProps.appConfigData) {
      let newAppConfig = nextProps.appConfigData || appConfigData;
      if (newAppConfig && newAppConfig.revisedHorizonWeeks) {
        revisedHorizonWeeks = newAppConfig.revisedHorizonWeeks;
        this.setState({ revisedHorizonWeeks });
      }
    }

    if (appConfigData && UrlqueryParamObj.productionQuarter) {
      let defaultSelected = '';
      let FormatdLeverQuartersList = [];

      if (defaultStatus === 'MaxAvailable' && appConfigData.stratProdQtrList) {
        defaultSelected = appConfigData.stratProdQtrList.find(
          item => item.value === UrlqueryParamObj.productionQuarter.toString()
        );

        FormatdLeverQuartersList = {
          default: defaultSelected,
          options: appConfigData.stratProdQtrList
        };
      }
      if (defaultStatus === 'shortHorizon' && appConfigData.rvsdProdQtrList) {
        defaultSelected = appConfigData.rvsdProdQtrList.find(
          item => item.value === UrlqueryParamObj.productionQuarter.toString()
        );

        FormatdLeverQuartersList = {
          default: defaultSelected,
          options: appConfigData.rvsdProdQtrList
        };
      }

      this.setState({
        LeverQuartersList: FormatdLeverQuartersList
      });
    }

    this.setState({
      revisedHorizonWeeks: revisedHorizonWeeks
    });
    /// for save action
    if (
      growthLeverQuarterDataUpdate !== nextProps.growthLeverQuarterDataUpdate
    ) {
      const updateData = nextProps.growthLeverQuarterDataUpdate;
      let UploadStatusDataNew = [];
      if (
        updateData &&
        (updateData.status === 201 ||
          (Array.isArray(updateData.status) && updateData.status.includes(201)))
      ) {
        if (updateData.outputData) {
          UploadStatusDataNew = updateErrorReport(
            updateData.outputData,
            UploadStatusData
          );
        }
      } else {
        UploadStatusDataNew = updateFailedReport(UploadStatusData);
      }
      this.setState({
        updateCycleComplete: true,
        UploadStatusData: UploadStatusDataNew
      });
    }
  }

  quarterSelectHandler = dropDownSelected => {
    const {
      UrlqueryParamObj,
      LeverQuartersList,
      saveDisabled,
      defaultStatus
    } = this.state;
    const { history } = this.props;

    let leverLink =
      defaultStatus === 'MaxAvailable'
        ? `/lever-quarter/strategicCapacity?`
        : `/lever-quarter/revisedCapacity?`;
    if (!saveDisabled) {
      confirmAlert({
        message: msgConstants.pageChangeAlert,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              UrlqueryParamObj.productionQuarter = dropDownSelected.value;
              LeverQuartersList.default = dropDownSelected;
              const pqcStr = qs.stringify(UrlqueryParamObj);

              localStorage.setItem('isLeverQuarterDataChanged', 'false');
              this.setState({ leverQuarter: {} });
              this.setState({ LeverQuartersList, saveDisabled: true });
              history.push(`${leverLink}${pqcStr}`);
            }
          },
          {
            label: 'No'
          }
        ]
      });
    } else {
      UrlqueryParamObj.productionQuarter = dropDownSelected.value;
      LeverQuartersList.default = dropDownSelected;
      const pqcStr = qs.stringify(UrlqueryParamObj);

      localStorage.setItem('isLeverQuarterDataChanged', 'false');
      this.setState({ leverQuarter: {} });
      this.setState({ LeverQuartersList });
      history.push(`${leverLink}${pqcStr}`);
    }
  };

  getGrowthLeverQuarterType(defaultStatus) {
    // Function call on page load and reload, to initate API request for Grid Header and Data
    // let defaultStatus = 'MaxAvailable';
    const { searchParams, searchGroups, revisedHorizonWeeks } = this.state;
    const {
      requestGrowthLeverQuarterData,
      currentusername,
      currentusergroups,
      clearGrowthLeverQuarterData,
      requestGrowthLeverListData
    } = this.props;

    clearGrowthLeverQuarterData({});

    const queryString = window.location.search;
    if (
      queryString &&
      queryString.toString().length > 0 &&
      queryString.includes('?')
    ) {
      const queryObj = qs.parse(queryString.replace('?', ''));
      if (queryObj.defaultStatus) {
        defaultStatus = queryObj.defaultStatus;
      }

      if (
        queryObj &&
        queryObj.factoryCode &&
        queryObj.capacityTypeCode &&
        queryObj.productionQuarter
      ) {
        searchGroups.forEach(i => {
          if (queryObj.hasOwnProperty(i.field)) {
            i.value = queryObj[i.field];
          }
        });
        searchParams.forEach(i => {
          if (queryObj.hasOwnProperty(i.field)) {
            i.value = queryObj[i.field];
          }
        });
        this.setState({ searchGroups, searchParams, userNotify: true });
      }

      let { userType } = this.state;
      const sessionUserType = window.sessionStorage.getItem('currentUserGroup');
      if (sessionUserType) {
        userType = sessionUserType;
      } else {
        userType = currentusergroups[0].value;
      }
      this.setState({ defaultStatus, userType }); // set new dashboard type
      const searchfieldsObj = {
        factoryCode: queryObj.factoryCode,
        capacityTypeCode: queryObj.capacityTypeCode,
        quarter: queryObj.productionQuarter,
        boundaryWeek: revisedHorizonWeeks,
        revisedHorizonWeeks: revisedHorizonWeeks
      };
      const searchfieldStr = qs.stringify(searchfieldsObj);
      const getGrowthLeverQuarterData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchfieldStr,
        type: 'api'
      };
      requestGrowthLeverListData(getGrowthLeverQuarterData);
      requestGrowthLeverQuarterData(getGrowthLeverQuarterData);
    }
    /* */
  }

  saveHandler = () => {
    const {
      leverQuarter,
      defaultStatus,
      thresholdCounts,
      validationResult,
      userRole
    } = this.state;
    const { userProfileData } = this.props;
    const { updateGrowthLeverQuarterData } = this.props;
    let isValid = true;
    let tabArray = [];
    const factoryType =
      (userProfileData && userProfileData.factoryType) || 'FW';
    leverQuarter.leverInfo.variances.forEach((variance, index) => {
      let varianceObj = {};
      if (factoryType === 'FW') {
        varianceObj.headerName = variance.headerName;
      } else {
        if (variance.varianceCode === 'Gbd') {
          varianceObj.headerName = 'Adjusted Max Capacity Variance';
          variance.headerName = 'Adjusted Max Capacity Variance';
        } else if (variance.varianceCode === 'MaxQ') {
          varianceObj.headerName = 'Adjusted Max Capacity QoQ Variance';
          variance.headerName = 'Adjusted Max Capacity QoQ Variance';
        } else if (variance.varianceCode === 'CapQ') {
          varianceObj.headerName = 'Adjusted Factory Capacity QoQ Variance';
          variance.headerName = 'Adjusted Factory Capacity QoQ Variance';
        }
      }
      varianceObj.varianceCode = variance.varianceCode;
      varianceObj.leverlist = [];
      variance.leverlist.forEach((lever, index) => {
        let leverObj = {};

        if (userRole === 'WHQ') {
          Object.keys(lever).forEach(key => {
            if (
              key === 'leverQty' &&
              lever.srcDecision === 'Yes' &&
              !lever.leverQty &&
              lever.leverQty !== 0
            ) {
              leverObj[key] = false;
              if (!tabArray.includes(varianceObj.headerName)) {
                tabArray.push(varianceObj.headerName);
              }
              isValid = false;
            } else {
              leverObj[key] = true;
            }
          });
          varianceObj.leverlist.splice(index, 0, leverObj);
        } else {
          Object.keys(lever).forEach(key => {
            if (
              key === 'lever' ||
              key === 'capacity' ||
              key === 'validityDate' ||
              key === 'leverQty' ||
              key === 'cost'
            ) {
              if (
                key === 'lever' ||
                key === 'capacity' ||
                key === 'validityDate'
              ) {
                leverObj[key] = lever[key].length !== 0 ? true : false;
              } else {
                leverObj[key] = true;
              }

              if (
                (key === 'capacity' || key === 'cost' || key === 'leverQty') &&
                Number.isNaN(+lever[key])
              ) {
                leverObj[key] = false;
              }
              if (!leverObj[key]) {
                if (!tabArray.includes(variance.headerName)) {
                  tabArray.push(variance.headerName);
                }
                isValid = false;
              }
            } else {
              leverObj[key] = true;
            }
          });

          const leverCodeStr =
            lever.category + ' ' + lever.dimension + ' ' + lever.lever;
          let leverCode = this.getUniq(leverCodeStr);
          lever.leverCode = leverCode;
          lever.capacity =
            lever.capacity &&
            (Number.isNaN(+lever.capacity) ? '' : Number(lever.capacity));
          lever.cost =
            lever.cost && (Number.isNaN(+lever.cost) ? '' : Number(lever.cost));
          lever.leverQty =
            lever.leverQty &&
            (Number.isNaN(+lever.leverQty) ? '' : Number(lever.leverQty));
          varianceObj.leverlist.splice(index, 0, leverObj);
        }
      });
      validationResult.splice(index, 0, varianceObj);
    });
    this.setState({
      validationResult,
      saveDisabled: true,
      isHistoryAvailable: true
    });
    if (isValid) {
      const AllGridData = {
        dashboard: defaultStatus,
        data: leverQuarter,
        type: 'row Update',
        thresholdCounts
      };
      localStorage.setItem('isLeverQuarterDataChanged', 'false');
      updateGrowthLeverQuarterData(AllGridData);
    } else {
      const status = {
        status: false,
        message: tabArray.join(',') + ' - Invalid lever data'
      };
      this.uploadStatusUpdate(status);
    }
  };

  getUniq = str => {
    var words = str.replace(/[^a-zA-Z ]+/gi, '').split(' ');
    // eslint-disable-next-line array-callback-return
    var newArr = words.map(function(item) {
      if (item.length > 0) {
        return (
          item.substr(0, 1).toUpperCase() + item.substr(1, 7).toLowerCase()
        );
      }
    });
    return newArr.join('');
  };

  addVarianceLever = index => {
    const { leverQuarter, leverRow } = this.state;
    let item = JSON.parse(JSON.stringify(leverRow));
    // item.leverCode = Math.floor(Math.random() * 1000000);

    if (
      leverQuarter &&
      leverQuarter.leverInfo &&
      leverQuarter.leverInfo.variances
    ) {
      let levListLen = leverQuarter.leverInfo.variances[index].leverlist
        ? leverQuarter.leverInfo.variances[index].leverlist.length
        : 0;
      item.devIndex = levListLen;
      leverQuarter.leverInfo.variances[index].leverlist.push(item);
    }
    this.setState({ leverQuarter, validationResult: [] });
  };

  deleteVarianceLever = (index, i) => {
    const { leverQuarter, leverQuarterBackup } = this.state;
    leverQuarter.leverInfo.variances[index].leverlist.splice(i, 1);
    let saveDisabled =
      JSON.stringify(leverQuarter) === JSON.stringify(leverQuarterBackup);
    this.setState({ leverQuarter, saveDisabled });
    //
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      this.setState({
        editGrid: true,
        UploadingData: false,
        UploadModel: false
      });
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  updateVarianceLever = (index, i, field, value) => {
    const {
      leverQuarter,
      leverQuarterBackup,
      varianceLeverList,
      userRole
    } = this.state;
    if (
      leverQuarter &&
      leverQuarter.leverInfo &&
      leverQuarter.leverInfo.variances &&
      leverQuarter.leverInfo.variances[index]
    ) {
      let setVal = '';
      switch (field) {
        case 'validityDate':
          setVal = moment(value).format('MM/DD/YYYY');
          break;
        default:
          setVal = value;
      }
      leverQuarter.leverInfo.variances[index].leverlist[i][field] = setVal;
      if (field === 'lever') {
        leverQuarter.leverInfo.variances[index].leverlist[i][field] = setVal;
        const leverList = varianceLeverList[index].columns.find(
          o => o.field === field
        );
        if (leverList && leverList.options) {
          const leverItemDetail = leverList.options.find(o => o.name === value);
          if (leverItemDetail) {
            leverQuarter.leverInfo.variances[index].leverlist[i].dimension =
              leverItemDetail.dimension;
            leverQuarter.leverInfo.variances[index].leverlist[i].category =
              leverItemDetail.category;
          }
        }
      }
      ///
      let LeverChange = false;
      if (!leverQuarter.leverInfo.variances[index].leverlist[i].newItem) {
        //
        if (leverQuarterBackup.leverInfo.variances[index].leverlist[i]) {
          const devIndex =
            leverQuarter.leverInfo.variances[index].leverlist[i].devIndex;
          const di = leverQuarterBackup.leverInfo.variances[
            index
          ].leverlist.findIndex(o => o.devIndex === devIndex);
          LeverChange = isLeverEdited(
            leverQuarter.leverInfo.variances[index].leverlist[i],
            leverQuarterBackup.leverInfo.variances[index].leverlist[di],
            userRole
          );
        }
        if (LeverChange) {
          leverQuarter.leverInfo.variances[index].leverlist[i].srcDecision =
            'TBD';
        }
      }

      ///
      localStorage.setItem('isLeverQuarterDataChanged', 'true');
      this.setState({
        leverQuarter,
        saveDisabled: false,
        validationResult: []
      });
    } else {
      const status = {
        status: false,
        message: 'unable to update field'
      };
      this.uploadStatusUpdate(status);
    }
    //
  };

  getLeverLink = () => {
    const { UrlqueryParamObj, defaultStatus } = this.state;
    let leverLink =
      defaultStatus === 'MaxAvailable'
        ? `/levers/strategicCapacity?`
        : `/levers/revisedCapacity?`;

    if (UrlqueryParamObj.factoryGroup) {
      leverLink += `&factoryGroup=${UrlqueryParamObj.factoryGroup}`;
    }
    if (UrlqueryParamObj.factoryCode) {
      leverLink += `&factoryCode=${UrlqueryParamObj.factoryCode}`;
    }
    if (UrlqueryParamObj.capacityTypeCode && UrlqueryParamObj.isCTC) {
      leverLink += `&capacityTypeCode=${UrlqueryParamObj.capacityTypeCode}`;
    }
    if (UrlqueryParamObj.startWeekDate) {
      leverLink += `&startWeekDate=${UrlqueryParamObj.startWeekDate}`;
    }
    if (UrlqueryParamObj.endWeekDate) {
      leverLink += `&endWeekDate=${UrlqueryParamObj.endWeekDate}`;
    }
    return leverLink;
  };

  backLeverSearch = () => {
    const { saveDisabled } = this.state;
    const { history } = this.props;
    if (!saveDisabled) {
      confirmAlert({
        message: msgConstants.pageChangeAlert,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              localStorage.setItem('isLeverQuarterDataChanged', 'false');
              this.setState({ leverQuarter: {} });
              history.push(this.getLeverLink());
            }
          },
          {
            label: 'No'
          }
        ]
      });
    } else {
      this.setState({ leverQuarter: {} });
      history.push(this.getLeverLink());
    }
  };

  // eslint-disable-next-line complexity
  render() {
    // rendering the dashboard content
    const {
      userProfileData,
      userProfileError,
      appConfigData,
      growthLeverQuarterDataLoading,
      growthLeverQuarterData,
      growthLeverQuarterError,

      growthLeverListData,
      growthLeverListDataLoading,
      growthLeverListError,
      usrFactGrpSearchLoading
    } = this.props; // receive from API response

    const {
      leverQuarter,
      varianceLeverList,
      leverColumns,
      LeverQuartersList,
      userRole,
      saveDisabled,
      validationResult,
      defaultStatus,
      defaultTabIndex,
      partialQuarter,
      isHistoryAvailable
    } = this.state;
    const factoryType =
      (userProfileData && userProfileData.factoryType) || 'FW';
    const { featureConfig } = appConfigData || {};

    // const partialQuarter = false; // API Logic is not yet ready once that is ready have to integrate
    return (
      <div className={`page-align-items ${userRole}`}>
        {featureConfig && !featureConfig.lever && <NotFound status="400" />}
        {featureConfig && featureConfig.lever && (
          <Beforeunload
            onBeforeunload={event => {
              if (saveDisabled) {
                return true;
              }
              event.preventDefault();
            }}
          >
            {/* if user not available  or no factory associated*/}
            {userProfileError ||
              (userProfileData && !userProfileData.factoryGroups && (
                <div className="capvis-page-container c-f-all u-clearfix">
                  <NotFound status="401" />
                </div>
              ))}

            {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}
            {(growthLeverQuarterDataLoading ||
              growthLeverListDataLoading ||
              usrFactGrpSearchLoading) && (
              <div>
                <Spinner large />
              </div>
            )}
            {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}

            <div className="ncss-container quarterPageContainer1">
              <div className="ncss-row page-header ">
                <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m pt3-sm pb3-sm ">
                  <button onClick={() => this.backLeverSearch()}>
                    <span className="mr2-sm g72-chevron-left"> </span>
                    Levers Summary
                  </button>
                  <h3 className="pt3-sm main-heading  p0 c-f-500"> Levers</h3>
                  <div className="position-relative QuarterSelectFiled">
                    {/* quarter select drop down */}
                    <Select
                      className="quarterInput_"
                      filedType="select"
                      defaultValue={LeverQuartersList.default}
                      onChange={e => this.quarterSelectHandler(e)}
                      options={LeverQuartersList.options}
                      value={LeverQuartersList.default}
                      classNamePrefix="quarterInput_"
                    />
                    <h6>
                      {leverQuarter &&
                        leverQuarter.leverInfo &&
                        leverQuarter.leverInfo.capacityInfo &&
                        leverQuarter.leverInfo.capacityInfo.totalWeeks &&
                        ` ${leverQuarter.leverInfo.capacityInfo.totalWeeks} weeks`}
                    </h6>
                  </div>
                </div>
                <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12  pl0-sm pr0-sm pt2-sm">
                      <div className="fl-sm-r ">
                        <button
                          className="ncss-btn-black u-uppercase  dashboard-button"
                          onClick={() => this.saveHandler()}
                          disabled={saveDisabled}
                        >
                          <span className="d-sm-h d-md-ib"> Save </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!growthLeverQuarterDataLoading &&
              !growthLeverListDataLoading &&
              leverQuarter &&
              leverQuarter.leverInfo &&
              varianceLeverList &&
              varianceLeverList.length > 0 && (
                <div className="ncss-container quarterPageContainer1">
                  {leverQuarter.leverInfo.capacityInfo &&
                    leverQuarter.leverInfo.variances && (
                      <Summary
                        capacityInfo={leverQuarter.leverInfo.capacityInfo}
                        factoryType={factoryType}
                        capacityTypeDescription={
                          leverQuarter.capacityTypeDescription
                        }
                        partialQuarter={partialQuarter}
                        defaultTabIndex={defaultTabIndex}
                        varianceInfo={leverQuarter.leverInfo.variances}
                        defaultStatus={defaultStatus}
                      />
                    )}
                  {!partialQuarter &&
                  leverQuarter.leverInfo.variances &&
                  leverQuarter.leverInfo.variances.length > 0 ? (
                    <div className={` ncss-row mt8-sm `}>
                      <div className="ncss-col-sm-12">
                        {/* lever , QOQ tab start */}
                        <Tabs
                          defaultIndex={defaultTabIndex}
                          onSelect={index =>
                            this.setState({
                              defaultTabIndex: index
                            })
                          }
                        >
                          <TabList>
                            {leverQuarter.leverInfo.variances.map(
                              (variance, index) => (
                                <>
                                  {variance.display && (
                                    <Tab
                                      className="menuOpt1Item"
                                      key={`tab-header-${variance.field}-${index}`}
                                      disabled={
                                        variance.varianceCode !== 'Gbd' &&
                                        leverQuarter.previousPartialQuarter ===
                                          'readOnly'
                                          ? true
                                          : false
                                      }
                                    >
                                      <span className="text">
                                        {factoryType === 'AP'
                                          ? (variance.headerName =
                                              'Adjusted Max Capacity Variance')
                                          : variance.headerName ||
                                            variance.name}
                                      </span>
                                    </Tab>
                                  )}
                                </>
                              )
                            )}
                          </TabList>

                          {leverQuarter.leverInfo.variances.map(
                            (variance, index) => (
                              <>
                                {variance.display && (
                                  <TabPanel
                                    className="quarter-tab-content"
                                    key={`tab-content-${variance.field}-${index}`}
                                  >
                                    <LeversTabSection
                                      growthLeverQuarterId={leverQuarter.id}
                                      variance={variance}
                                      factoryType={factoryType}
                                      index={index}
                                      addVarianceLever={this.addVarianceLever}
                                      deleteVarianceLever={
                                        this.deleteVarianceLever
                                      }
                                      updateVarianceLever={
                                        this.updateVarianceLever
                                      }
                                      leverColumns={leverColumns}
                                      userRole={userRole}
                                      varianceLever={
                                        (varianceLeverList &&
                                          varianceLeverList[index]) ||
                                        []
                                      }
                                      partialQuarter={partialQuarter}
                                      validationResult={validationResult[index]}
                                      isHistoryEnabled={isHistoryAvailable}
                                    ></LeversTabSection>
                                  </TabPanel>
                                )}
                              </>
                            )
                          )}
                        </Tabs>
                      </div>
                    </div>
                  ) : (
                    <div className="c-f-all u-full-width  u-clearfix  d-sm-flx-new  flx-jc-sm-c ">
                      <div className="flex-item p4-sm ta-sm-l flx-as-sm-c  ">
                        <h4 className="  pb2-sm u-bold d-sm-flx-new  flx-jc-sm-c">
                          No data Available
                        </h4>
                        <p className="d-sm-flx-new  flx-jc-sm-c">
                          {partialQuarter
                            ? 'Please select other quarter.'
                            : 'Please refresh or select other quarter.'}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {/* Error on loading Data */}
            {!growthLeverQuarterDataLoading &&
              !growthLeverListDataLoading &&
              (growthLeverListError ||
                growthLeverQuarterError ||
                (growthLeverQuarterData &&
                  Object.keys(growthLeverQuarterData).length === 0) ||
                (growthLeverListData &&
                  Object.keys(growthLeverListData).length === 0)) && (
                <div className="c-f-all u-full-width  u-clearfix  d-sm-flx-new  flx-jc-sm-c ">
                  <div className="flex-item p4-sm ta-sm-l flx-as-sm-c  ">
                    <h4 className="  pb2-sm u-bold "> Something Went Wrong</h4>
                    <p> Please refresh or select valid Factory and Quarter.</p>
                  </div>
                </div>
              )}
          </Beforeunload>
        )}
      </div>
    );
  }
}

growthLeverQuarter.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  growthLeverQuarterDataLoading: PropTypes.bool,
  growthLeverQuarterData: PropTypes.object,
  growthLeverQuarterError: PropTypes.any,
  growthLeverQuarterGridLoading: PropTypes.bool,
  growthLeverQuarterGridColumns: PropTypes.object,
  growthLeverQuarterGridError: PropTypes.any,
  requestGrowthLeverQuarterData: PropTypes.func,
  clearGrowthLeverQuarterData: PropTypes.func,
  requestGrowthDimensionGridColumns: PropTypes.func,
  updateGrowthDimensionGridColumns: PropTypes.func,
  updateGrowthLeverQuarterData: PropTypes.func,
  clearUpdateGrowthLeverQuarterData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  getUsrFactGrpSearchData: PropTypes.object,
  growthLeverQuarterDataUpdate: PropTypes.any,
  appConfigData: PropTypes.object,
  appConfigLoading: PropTypes.any,
  appConfigError: PropTypes.object,
  growthLeverListData: PropTypes.any,
  growthLeverListDataLoading: PropTypes.bool,
  growthLeverListError: PropTypes.any
};

const mapStateToProps = state => ({
  growthLeverQuarterDataLoading: getGrowthLeverQuarterDataLoading(state),
  growthLeverQuarterData: getGrowthLeverQuarterData(state),
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  growthLeverQuarterError: getGrowthLeverQuarterError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  usrFactGrpSearchLoading: getusrFactGrpSearchLoading(state),
  usrFactGrpSearchError: getusrFactGrpSearchError(state),
  growthLeverQuarterDataUpdate: getGrowthLeverQuarterDataUpdate(state),
  appConfigData: getAppConfigData(state),
  appConfigLoading: getAppConfigLoading(state),
  appConfigError: getAppConfigError(state),
  userNotifyData: getUserNotifyData(state),
  growthLeverListData: getGrowthLeverListData(state),
  growthLeverListDataLoading: getGrowthLeverListDataLoading(state),
  growthLeverListError: getGrowthLeverListError(state),
  growthLeverListDataUpdate: getGrowthLeverListDataUpdate(state)
});

const mapDispatchToProps = dispatch => ({
  requestGrowthLeverQuarterData: status => {
    // Get data to show on grid
    dispatch(
      createServiceAction('growthLeverQuarterData', 'requested')(status)
    );
  },

  clearGrowthLeverQuarterData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('growthLeverQuarterData', 'clear')(status));
    dispatch(createServiceAction('growthLeverQuarterError', 'clear')({}));
  },
  clearGrowthDimensionGridColumns: columndata => {
    // Get data to show on graph and count
    dispatch(
      createServiceAction('growthLeverQuarterGridColumns', 'clear')(columndata)
    );
    dispatch(createServiceAction('growthLeverQuarterGridError', 'clear')({}));
  },
  updateGrowthDimensionGridColumns: columndata => {
    dispatch(
      createServiceAction('growthLeverQuarterGridColumns', 'update')(columndata)
    );
  },
  requestGrowthDimensionGridColumns: requestHeader => {
    // Get data to show on graph and count
    dispatch(
      createServiceAction('growthLeverQuarterGridColumns', 'requested')(
        requestHeader
      )
    );
  },

  updateGrowthLeverQuarterData: data => {
    dispatch(
      createServiceAction('growthLeverQuarterDataUpdate', 'submited')({
        ...data
      })
    );
  },
  clearUpdateGrowthLeverQuarterData: data => {
    dispatch(createServiceAction('growthLeverQuarterDataUpdate', 'clear')({}));
  },

  requestGrowthLeverListData: data => {
    dispatch(createServiceAction('growthLeverListData', 'requested')(data));
  },
  clearGrowthLeverListData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('growthLeverListData', 'clear')(status));
    dispatch(createServiceAction('growthLeverListError', 'clear')({}));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(growthLeverQuarter)
);
