/* eslint-disable max-statements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Beforeunload } from 'react-beforeunload';
import * as moment from 'moment';
import _ from 'lodash';
import Tooltip from 'react-simple-tooltip';
import Grid from '../../components/grid/Grid.js';
import { H1, Spinner } from '../../components/index.js';
import SearchNew from '../../components/dashboard/searchNew.js';
import TabHeader from '../../components/dashboard/tabHeader';
import cellLinkRenderer from '../../components/grid/cellLinkRenderer.js';
import dayPickerFilter from '../../components/grid/dayPickerFilter.js';
import NotFound from '../../components/errors/NotFound.js';
import {
  getUserUpdatesDataLoading,
  getUserUpdatesData,
  getUserUpdatesError,
  getUpdatedUserUpdatesData
} from '../../reducers/userUpdatesReducer.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { getUserNotifyData } from '../../reducers/userNotifyReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';
import { selectStatusButton } from '../../actions/dashboardActions';
import gridConstants from '../../constants/gridConstants.js';
import pageConstants from './UserUpdates.constants.js';
import updatesSearchConstants from './UserUpdatesSearch.constants.js';
import { verifyTrial } from '../../util/general.js';
import { msgConstants } from '../../constants/msgConstants.js';
import { getAppConfigData } from '../../reducers/appConfigReducer.js';

const CapChangeNotificationShow = verifyTrial('ccn');
const searchfirst = true;
const maxDownloadLimit = 1000;
export class UserUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsForExcel: {},
      userType: '',
      UploadStatusData: [],
      frameworkComponents: {
        cellLinkRenderer,
        agDateInput: dayPickerFilter
      },
      scoreCardCount: pageConstants.scoreCardCount,
      context: { componentParent: this },
      defaultStatus: 'MaxAvailable',
      defaultColDef: gridConstants.defaultColDef,
      excelStyles: gridConstants.excelStyles,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      statusCode: '',
      statusCodeBack: '',
      gridPageCount: 1,
      gridPagination: {},
      searchGroups: updatesSearchConstants.groups,

      getRowHeight(params) {
        return 56;
      },
      maxColumnEditable: true,

      isPendingSelected: false,
      isReadSelected: false,
      selectedRows: [],
      updateGridData: [],
      totalResources: 0,
      pageNext: {},
      paginationInitiated: false,
      paginationLoadInfo: false,
      externalFilter: null,
      gridHeight: 400,
      gridWidth: 'calc(100%)',
      updateCycleComplete: false,
      updateCycleData: [],
      allSearchFields: '',
      displayCount: 0,
      updateRecordActive: false,
      searchQuery: {}
    };
    this.GridParentRel = React.createRef();
    this.getUserUpdatesType = this.getUserUpdatesType.bind(this);
    this.uploadStatusUpdate = this.uploadStatusUpdate.bind(this);
    this.callExternalFilter = this.callExternalFilter.bind(this);
    this.onSearchEnterApi = this.onSearchEnterApi.bind(this);
    this.onSearchGroupUpdate = this.onSearchGroupUpdate.bind(this);
    this.onSearchIconClick = this.onSearchIconClick.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.toogleUserUpdatesModel = this.toogleUserUpdatesModel.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
  }

  componentDidMount() {
    // On page load, loading max available dashboard as default
    let defaultStatus = 'MaxAvailable';
    const { location } = this.props;
    const { pathname, query } = location;
    const { scoreCardCount } = this.state;
    scoreCardCount.forEach((scItem, x) => {
      scItem.unreadCount = 0;
      scItem.pendingCount = 0;
    });
    this.setState(scoreCardCount);
    // checking url is to load max available or short horizon dashboard
    if (pathname && pathname.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    window.addEventListener('resize', this.updateDimensions);
    this.getUserUpdatesType(defaultStatus, query);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  componentWillReceiveProps(nextProps) {
    // On page load, loading max available dashboard as default
    let defaultStatus = 'MaxAvailable';
    const { searchGroups, updateGridData, scoreCardCount } = this.state;
    const {
      location,
      userUpdatesData,
      userProfileData,
      userUpdatesDataUpdate
    } = this.props;
    const { pathname, search } = location;
    const newPathName = nextProps.location.pathname;
    const oldPathName = pathname;

    if (newPathName && newPathName.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    if (
      userProfileData !== nextProps.userProfileData &&
      nextProps.userProfileData &&
      Object.keys(nextProps.userProfileData).length > 0
    ) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        searchGroups.forEach(i => {
          if (i.field === 'factoryGroup') {
            if (
              newUsrProData.factoryGroups &&
              newUsrProData.factoryGroups.length > 0
            ) {
              const allFG = [
                {
                  name: 'All Factory Groups',
                  value: '',
                  key: 'AllFactoryGroups',
                  label: 'All Factory Groups'
                }
              ];
              newUsrProData.factoryGroups = _.sortBy(
                newUsrProData.factoryGroups,
                'name'
              );
              i.options = [...allFG, ...newUsrProData.factoryGroups];
            } else {
              i.disabled = true;
            }
          } else if (i.field === 'factoryCode') {
            let allopt = [];
            if (
              newUsrProData.factoryCodes &&
              newUsrProData.factoryCodes.length > 1
            ) {
              const allFC = [
                {
                  name: 'All Factory Codes',
                  value: '',
                  key: 'AllFactoryCodes',
                  label: 'All Factory Codes'
                }
              ];
              let factoryCodes = newUsrProData.factoryCodes;
              let filteredArr = factoryCodes.filter(x => x.name !== 'ALL');
              let sortedArr = _.sortBy(filteredArr, 'name');
              allopt = [...allFC, ...sortedArr];
            }
            let allsopt = [];
            if (
              newUsrProData.factoryCodesArr &&
              newUsrProData.factoryCodesArr.length > 1
            ) {
              allsopt = newUsrProData.factoryCodes.slice(1);
            }
            i.options = allopt;
            i.backOptions = allopt;
            i.sOptions = allsopt;
            i.sBackOptions = allsopt;
          }

          if (i.field === 'readStatus') {
            i.selected = i.options[1];
          } else {
            const fromUserUpdates = window.sessionStorage.getItem(
              'fromUserUpdates'
            );
            const fromDashboard = window.sessionStorage.getItem(
              'fromDashboard'
            );
            if (
              JSON.parse(fromUserUpdates) === true &&
              JSON.parse(fromDashboard) === true
            ) {
              const searchG = JSON.parse(
                window.sessionStorage.getItem('searchGroups')
              );
              const searchObj = searchG.filter(obj => obj.field === i.field);
              if (searchObj.length !== 0) {
                i.selected = searchObj[0].selected;
              } else {
                i.selected = i.options[0];
              }
            } else {
              i.selected = i.options[0];
            }
          }
        });

        this.setState({ searchGroups });
      }
    }

    // to make any changs post user
    if (userUpdatesDataUpdate !== nextProps.userUpdatesDataUpdate) {
      const updateData = nextProps.userUpdatesDataUpdate;
      if (updateData) {
        this.reloadGridContentAfterSave(nextProps.userUpdatesDataUpdate);
      }
    }

    if (userUpdatesData !== nextProps.userUpdatesData) {
      if (nextProps.userUpdatesData && nextProps.userUpdatesData.data) {
        // PQ COL
        let updateGridDataNew = [];
        if (
          nextProps.userUpdatesData.pagination &&
          nextProps.userUpdatesData.pagination.back
        ) {
          if (
            Object.keys(nextProps.userUpdatesData.pagination.back).length === 0
          ) {
            updateGridDataNew = nextProps.userUpdatesData.data;
          } else {
            const AppendArray = [
              ...updateGridData,
              ...nextProps.userUpdatesData.data
            ];
            updateGridDataNew = _.uniqBy(AppendArray, v =>
              [v.changeNotificationId, v.changeTimestamp].join()
            );
          }

          if (
            nextProps.userUpdatesData.counts &&
            nextProps.userUpdatesData.counts.length > 0
          ) {
            scoreCardCount.forEach((scItem, x) => {
              scItem.unreadCount =
                nextProps.userUpdatesData.counts &&
                nextProps.userUpdatesData.counts[x].read
                  ? nextProps.userUpdatesData.counts[x].read
                  : 0;
              scItem.pendingCount =
                nextProps.userUpdatesData.counts &&
                nextProps.userUpdatesData.counts[x].pend
                  ? nextProps.userUpdatesData.counts[x].pend
                  : 0;
            });
          }

          this.setState({
            displayCount: updateGridDataNew.length || 0,
            scoreCardCount: scoreCardCount,
            updateGridData: updateGridDataNew,
            totalResources: nextProps.userUpdatesData.pagination.totalResources,
            pageNext: nextProps.userUpdatesData.pagination.next,
            paginationInitiated: false,
            paginationLoadInfo: false
          });
          this.updateGridRows();
        }
      }
    }

    if (newPathName !== oldPathName) {
      this.getUserUpdatesType(defaultStatus);
    }

    if (search !== nextProps.location.search) {
      this.setState({
        gridCurrentPageData: [],
        updateCycleComplete: false,
        loadGrid: true,
        changedRowsData: [],
        quarterRowDataChanged: []
      });

      this.getUserUpdatesType(defaultStatus, nextProps.location.query);
    }
  }

  updateDimensions = () => {
    this.setState({ gridHeight: window.innerHeight - 315 });
  };

  onSearchAllClear() {
    const { searchGroups } = this.state;
    searchGroups.forEach(gridItem => {
      gridItem.value = '';
      let thisrel = this.refs[`typeAhead${gridItem.field}`];
      if (thisrel) {
        thisrel.getInstance().clear();
      }
      gridItem.selected = {};
      if (gridItem.field === 'factoryCode') {
        gridItem.options = gridItem.backOptions;
      }
    });

    this.setState({ searchGroups });
  }
  onSearchGroupUpdate(searchGroups) {
    this.setState({ searchGroups, gridPageCount: 1 });
    setTimeout(() => {
      this.onSearchEnterApi();
    }, 500);
  }

  onSearchEnterApi(statusCodeParam) {
    // after selecting all required search params, retrieving data
    this.setState({
      updateCycleComplete: false,
      isReadSelected: false,
      isPendingSelected: false
    });

    const {
      defaultStatus,
      userType,
      searchGroups,
      allSearchFields,
      scoreCardCount,
      statusCode,
      statusCodeBack,
      paramsForExcel,
      pageNext,
      updateRecordActive,
      searchQuery,
      paginationInitiated
    } = this.state;

    const {
      requestDashboardData,
      currentusername,
      userProfileData
    } = this.props;

    let currentStatus = scoreCardCount.find(o => o.isActive === true)
      .statusCode;

    let searchObj = {
      view: defaultStatus === 'MaxAvailable' ? 'strategic' : 'revised'
    };
    let overRideFilter = '';

    if (statusCodeParam) {
      const status =
        statusCodeParam.indexOf('-') !== -1
          ? statusCodeParam.split('-')
          : [statusCodeParam, ''];
      overRideFilter = status[1];
    }

    searchGroups.forEach(gridItem => {
      if (gridItem.field === 'readStatus') {
        if (gridItem.selected.value) {
          if (Object.keys(gridItem.selected).length > 0) {
            if ([1, 2].includes(gridItem.selected.value)) {
              let c = Number(gridItem.selected.value);
              const newDay = moment()
                .subtract(c, 'days')
                .startOf('day')
                .format('x');
              searchObj.createdFrom = newDay;
            } else if (['Pending'].includes(gridItem.selected.value)) {
              searchObj.pendingStatus = true;
            } else {
              searchObj[
                gridItem.field
              ] = gridItem.selected.value.toLocaleLowerCase();
            }
          }
        }
      }
    });

    if (searchQuery && searchQuery.factoryCode) {
      searchObj.factoryCode = searchQuery.factoryCode;
    } else {
      if (searchQuery && searchQuery.factoryGroup) {
        const factoryGroups = userProfileData.association.find(
          i => i.factoryGroup === searchQuery.factoryGroup
        );
        const factoryies = [...factoryGroups.factories];
        const index = factoryies.indexOf('ALL');
        if (index > -1) {
          factoryies.splice(index, 1);
        }
        searchObj.factoryCode = factoryies.sort().join();
      } else {
        const factoryCodes =
          userProfileData && userProfileData.factoryCodesArr
            ? [...userProfileData.factoryCodesArr]
            : [];
        const index = factoryCodes.indexOf('ALL');
        if (index > -1) {
          factoryCodes.splice(index, 1);
        }
        searchObj.factoryCode = factoryCodes.join();
      }
    }
    if (allSearchFields !== JSON.stringify(searchObj)) {
      scoreCardCount.forEach((scItem, x) => {
        scItem.isActive = x === 0 ? true : false;
      });
      this.setState({
        allSearchFields: JSON.stringify(searchObj),
        scoreCardCount,
        statusCode: ''
      });
      searchObj.anchor = 1;
    } else {
      if (updateRecordActive) {
        searchObj.anchor = 1;
        this.setState({
          updateRecordActive: false
        });
      } else {
        searchObj.anchor = (paginationInitiated && pageNext.anchor) || 1;
      }
      if (statusCodeBack !== statusCode) {
        searchObj.anchor = 1;
        this.setState({
          statusCodeBack: statusCode
        });
      }
      if (currentStatus.length > 0) {
        searchObj.status = currentStatus;
      }
      if (overRideFilter.includes('read')) {
        searchObj.tabReadStatus = 'unread';
      } else if (overRideFilter.includes('pending')) {
        searchObj.tabPendingStatus = true;
      }
    }
    searchObj.count = maxDownloadLimit;

    const queryString = Object.keys(searchObj)
      .map(key => `${key}=${searchObj[key]}`)
      .join('&');

    const getUserUpdatesData = {
      user: currentusername,
      role: userType,
      dashboard: defaultStatus,
      searchfields: queryString,
      type: 'bulk',
      factoryCode:
        searchQuery && searchQuery.factoryCode ? searchQuery.factoryCode : ''
    };

    if (Object.keys(paramsForExcel).length > 0) {
      paramsForExcel.api.setFilterModel(null);
    }

    requestDashboardData(getUserUpdatesData);
  }

  getContextMenuItems = params => ['copy'];

  getUserUpdatesType(defaultStatus, query) {
    // Function call on page load and reload, to initate API request for Grid Header and Data

    const { currentusergroups, clearDashboardData } = this.props;
    clearDashboardData({});
    let { userType } = this.state;
    const sessionUserType = window.sessionStorage.getItem('currentUserGroup');
    if (sessionUserType) {
      userType = sessionUserType;
    } else {
      userType = currentusergroups[0].value;
    }
    this.setState({ defaultStatus, userType, searchQuery: query }, () => {
      setTimeout(() => {
        this.onSearchEnterApi();
      }, 100);
    });
  }

  setParams = params => {
    // Function called post GRID initial load to reassure column structure and fields

    if (params) {
      this.setState({ paramsForExcel: params }); // update AG Grid Properties to state
      if (params.columnApi.getAllColumns()) {
        try {
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel(); // AG Grid closed the side panel which will be open by default
          // params.api.setSortModel(defaultSortAfterLoad);
          const availableWidth = this.GridParentRel.current.offsetWidth;
          const displayedColumns = params.columnApi.getAllDisplayedColumns();
          const usedWidth = _.sumBy(displayedColumns, 'actualWidth');
          if (availableWidth > usedWidth) {
            params.api.sizeColumnsToFit();
          }
          this.setState({ gridWidth: '100%' }); // update AG Grid Properties to state
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  onFirstDataRendered = params => {
    const defaultSortAfterLoad = [
      {
        colId: 'lastModified',
        sort: 'desc'
      }
    ];
    params.api.setSortModel(defaultSortAfterLoad);
  };

  callExternalFilter = statusCode => {
    // based on the status wise count, on click on the numbers, paticular data will be filtered in grid
    this.setState({ paginationLoadInfo: false });
    const { scoreCardCount } = this.state;
    scoreCardCount.forEach(scItem => {
      scItem.isActive = false;
    });
    if (statusCode.includes('-')) {
      const status = statusCode.split('-');
      scoreCardCount.find(o => o.statusCode === status[0]).isActive = true;
    } else {
      scoreCardCount.find(o => o.statusCode === statusCode).isActive = true;
    }

    this.setState({ statusCode });
    this.onSearchEnterApi(statusCode);
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  toogleUserUpdatesModel = () => {
    // toggles the upload dialog
    const { paramsForExcel } = this.state;
    let { updateCycleData, updateCycleComplete } = this.state;
    if (updateCycleComplete) {
      updateCycleData = [];
      paramsForExcel.api.deselectAll();
    }
    this.setState({
      updateCycleComplete: !updateCycleComplete,
      updateCycleData
    });
  };

  reloadGridContentAfterSave = onSaveResp => {
    // to make some action based on flag or checkbox change in api integration
    const {
      paramsForExcel,
      scoreCardCount,
      searchGroups,
      updateGridData,
      totalResources
    } = this.state;
    const { requestUserNotificationData } = this.props;
    let totalResourcesCnt = totalResources;
    if (
      onSaveResp &&
      onSaveResp.result &&
      Object.keys(onSaveResp.result).length >= 0
    ) {
      requestUserNotificationData();
      let lastIndex = 0;
      Object.keys(onSaveResp.result).forEach(col => {
        const ip = updateGridData.findIndex(
          o =>
            col.includes(o.changeNotificationId) &&
            col.includes(o.changeTimestamp)
        );
        lastIndex = ip;
        const msg = onSaveResp.result[col].message || 'Pending Cleared';
        if (ip >= 0) {
          let Indx = -1;
          if (updateGridData[ip].changeType) {
            Indx = scoreCardCount.findIndex(
              o => o.statusCode === updateGridData[ip].changeType
            );
          }

          if (msg.includes('Read')) {
            updateGridData[ip].readStatus = 'read';
            if (Indx >= 0 && scoreCardCount[Indx].unreadCount > 0) {
              scoreCardCount[Indx].unreadCount -= 1;
            }
          } else if (msg.includes('Pending')) {
            updateGridData[ip].pending = false;
            if (Indx >= 0 && scoreCardCount[Indx].pendingCount > 0) {
              scoreCardCount[Indx].pendingCount -= 1;
            }
          }

          const rsi = searchGroups.findIndex(o => o.field === 'readStatus');
          if (
            (searchGroups[rsi].selected.key === 'Unread' &&
              msg.includes('Read')) ||
            (searchGroups[rsi].selected.key === 'Pending' &&
              msg.includes('Pending'))
          ) {
            updateGridData.splice(ip, 1);
            totalResourcesCnt = totalResourcesCnt - 1;
          }
        }
      });
      paramsForExcel.api.deselectAll();
      paramsForExcel.api.setRowData(updateGridData);
      if (lastIndex > updateGridData.length) {
        lastIndex = updateGridData.length;
        paramsForExcel.api.ensureIndexVisible(lastIndex, 'bottom');
      } else {
        paramsForExcel.api.ensureIndexVisible(lastIndex, 'middle');
      }
    }

    this.setState({
      updateCycleComplete: true,
      scoreCardCount,
      displayCount: updateGridData.length || 0,
      totalResources: totalResourcesCnt
    });
  };

  onSelectionChanged() {
    // on click of checkbox in grid
    const { paramsForExcel } = this.state;
    const selectedRows = paramsForExcel.api.getSelectedRows();

    this.setState({
      isPendingSelected:
        selectedRows.filter(i => i.pending === true).length > 0 ? true : false,
      isReadSelected:
        selectedRows.filter(i => i.readStatus === 'unread').length > 0
          ? true
          : false,
      selectedRows
    });
  }

  onSearchIconClick(a) {
    // updates the seach box entries, frames query and calls API
    const { isPendingSelected, isReadSelected, selectedRows } = this.state;
    const { updateUserUpdateData, getUserNotification } = this.props;
    if (isPendingSelected || isReadSelected) {
      if (a === 'bell') {
        const toUpdate = {
          action: 'read',
          dashboard: null,
          notifications: []
        };
        selectedRows.forEach(i => {
          if (!toUpdate.dashboard) {
            toUpdate.dashboard = i.dashboard;
          }
          toUpdate.notifications.push({
            uniqueNotificationId: `${i.changeNotificationId}-${i.changeTimestamp}`,
            readStatus: 'read'
          });
        });
        updateUserUpdateData(toUpdate);
      } else {
        const toUpdate = {
          action: 'pending',
          uniqueNotificationIds: []
        };
        selectedRows.forEach(i => {
          toUpdate.uniqueNotificationIds.push(
            `${i.changeNotificationId}-${i.changeTimestamp}`
          );
        });
        updateUserUpdateData(toUpdate);

        setTimeout(() => {
          getUserNotification();
        }, 1000);
      }
      this.setState({
        updateRecordActive: true
      });
    }
  }

  // When a User Clicks on a Production Quarter Link, Navigate and Mark as Read
  onProductionQuarterLinkClick = (data, productionQuarterLink) => {
    const { updateUserUpdateData, history } = this.props;
    const { searchGroups } = this.state;
    window.sessionStorage.setItem('searchGroups', JSON.stringify(searchGroups));
    if (data.readStatus === 'unread') {
      const toUpdate = {
        action: 'read',
        dashboard: data.dashboard,
        notifications: [
          {
            uniqueNotificationId: `${data.changeNotificationId}-${data.changeTimestamp}`,
            readStatus: 'read'
          }
        ]
      };
      updateUserUpdateData(toUpdate);
    }
    history.push(productionQuarterLink);
  };

  bodyScroll(a) {
    const { paramsForExcel, updateGridData, paginationLoadInfo } = this.state;
    let lastRow = 0;
    if (Object.keys(paramsForExcel).length > 0) {
      lastRow = paramsForExcel.api.getLastDisplayedRow();
      const last5Row = updateGridData.length - 5;
      if (last5Row > 0 && last5Row < lastRow) {
        this.setState({ paginationLoadInfo: true });
      } else {
        if (paginationLoadInfo) {
          this.setState({ paginationLoadInfo: false });
        }
      }
    }
  }

  loadMoreOnClick() {
    const { pageNext, gridPageCount } = this.state;
    this.setState({
      gridPagination: pageNext,
      paginationInitiated: true,
      gridPageCount: gridPageCount + 1
    });
    setTimeout(() => {
      this.onSearchEnterApi();
    }, 100);
  }

  updateGridRows() {
    // on click of checkbox in grid
    const fromUserUpdates = window.sessionStorage.getItem('fromUserUpdates');
    const fromDashboard = window.sessionStorage.getItem('fromDashboard');
    const { paramsForExcel, updateGridData } = this.state;
    if (updateGridData.length > 0 && Object.keys(paramsForExcel).length > 0) {
      paramsForExcel.api.setRowData(updateGridData);
      if (
        JSON.parse(fromUserUpdates) === true &&
        JSON.parse(fromDashboard) === true
      ) {
        window.sessionStorage.setItem('fromUserUpdates', false);
        window.sessionStorage.setItem('fromDashboard', false);
        window.sessionStorage.setItem('searchGroups', '[]');
      }
      if (maxDownloadLimit < updateGridData.length) {
        const lr = updateGridData.length - maxDownloadLimit;
        paramsForExcel.api.ensureIndexVisible(lr, 'bottom');
      }
    }
  }
  // eslint-disable-next-line complexity
  render() {
    const {
      userUpdatesDataLoading,
      userUpdatesError,
      userProfileData,
      userProfileError,
      usrFactGrpSearchData,
      usrFactGrpSearchLoading,
      userNotifyData,
      userUpdatesData,
      appConfigData
    } = this.props;

    const {
      defaultStatus,
      scoreCardCount,
      searchGroups,
      defaultColDef,
      overlayLoadingTemplate,
      frameworkComponents,
      excelStyles,
      context,
      getRowHeight,
      isPendingSelected,
      isReadSelected,
      updateGridData,
      paginationInitiated,
      paginationLoadInfo,
      statusCode,
      selectedRows,
      displayCount,
      totalResources,
      gridWidth
    } = this.state;

    const messageContent = {
      title: 'No Data Available',
      content: ''
    };
    let notificationCount = '';
    let reachedLast = false;
    if (
      (displayCount || Number(displayCount) === 0) &&
      (totalResources || Number(totalResources) === 0)
    ) {
      notificationCount = `${displayCount} of ${totalResources} Notifications`;
    }
    if (userUpdatesData && userUpdatesData.pagination && updateGridData) {
      if (
        updateGridData.length ===
        Number(userUpdatesData.pagination.totalResources)
      ) {
        reachedLast = true;
      }
    }
    const sel = searchGroups.find(o => o.field === 'readStatus');
    if (sel && sel.selected && sel.selected.name) {
      messageContent.title = `No Data Available`;
    }
    if (userUpdatesError) {
      if (userUpdatesError.status === 400) {
        messageContent.title = 'Something Went Wrong !';
      } else {
        messageContent.title = msgConstants.invalidSearch;
      }
      messageContent.content = userUpdatesError.message || '';
    }
    const overlayNoRow = `<span class="text-color-grey1 fs-18px"> ${messageContent.title} <br/><span class=""${messageContent.content}</span></span>`;

    const { featureConfig } = appConfigData || {};
    return (
      <div className="page-align-items c-f-all ">
        {!CapChangeNotificationShow ? <NotFound status="401" /> : null}
        <Beforeunload
          onBeforeunload={event => {
            if (selectedRows && selectedRows.length > 0) {
              event.preventDefault();
            }
          }}
        >
          <div
            className={`dashboard ${CapChangeNotificationShow ? '' : 'd-sm-h'}`}
          >
            {/* Show Search bar on conditions */}

            <div className="ncss-container">
              {/* Show Search count bar on conditions */}
              {userProfileError ||
                (userProfileData && !userProfileData.factoryGroups && (
                  <NotFound status="401" />
                ))}
              <div className="ncss-row page-header pb0-sm pb2-md">
                <div className="ncss-col-lg-6  ncss-col-sm-12 va-sm-m ">
                  <h3 className="pt3-sm main-heading  c-f-500">
                    {defaultStatus === 'MaxAvailable'
                      ? 'Strategic '
                      : 'Revised '}{' '}
                    Capacity View
                  </h3>
                </div>
                <div className="ncss-col-sm-12 ncss-col-md-6 ta-sm-r fl-sm-r  text-color-grey1 fs-14px pt4-sm u-normal">
                  {notificationCount}
                </div>
              </div>
              <div>
                {!userProfileError &&
                  userProfileData &&
                  userProfileData.factoryGroups &&
                  userProfileData.factoryGroups.length > 0 &&
                  searchfirst && (
                    <div>
                      <div className="ncss-container c-f search-header-new">
                        <div className="ncss-row page-menu-header">
                          <TabHeader
                            defaultStatus={defaultStatus}
                            userNotifyData={userNotifyData}
                            active="userupdate"
                            featureConfig={featureConfig}
                          />

                          <div className="ncss-col-sm-12 ncss-col-md-8  ta-sm-r fl-sm-r pb2-sm">
                            <div className="d-sm-flx flx-jc-sm-fe">
                              {scoreCardCount &&
                                scoreCardCount.map(
                                  ({
                                    name,
                                    id,
                                    unreadCount,
                                    pendingCount,
                                    statusCode,
                                    isActive,
                                    showUnread,
                                    showPending
                                  }) => (
                                    <div
                                      className="flex-child menuOpt2Item"
                                      key={id}
                                    >
                                      <div className="d-sm-ib ta-sm-l">
                                        <span
                                          onClick={() => {
                                            this.callExternalFilter(statusCode);
                                          }}
                                          className={`text c-f-500 ${
                                            isActive ? 'active ' : ' '
                                          }`}
                                        >
                                          {name}{' '}
                                        </span>
                                        <div className="alert d-sm-b text-color-error">
                                          {' '}
                                          {unreadCount >= 0 && showUnread && (
                                            <div className="d-sm-b fl-sm-l p-r">
                                              <Tooltip
                                                arrow={10}
                                                background="#fff"
                                                border="#ccc"
                                                color="#777"
                                                fontSize="13px"
                                                padding={5}
                                                placement="top"
                                                radius={5}
                                                customCss={`white-space: nowrap; font-weight:normal;`}
                                                content="Unread"
                                              >
                                                <span
                                                  className={`notification-count-side ${
                                                    unreadCount === 0
                                                      ? 'bg-grey'
                                                      : 'bg-primary  u-cursor-pointer'
                                                  }`}
                                                  onClick={() => {
                                                    if (unreadCount > 0) {
                                                      this.callExternalFilter(
                                                        `${statusCode}-read`
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {unreadCount}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          )}
                                          {pendingCount >= 0 && showPending && (
                                            <div className="d-sm-b fl-sm-l p-r ">
                                              <Tooltip
                                                arrow={10}
                                                background="#fff"
                                                border="#ccc"
                                                color="#777"
                                                fontSize="13px"
                                                padding={5}
                                                placement="top"
                                                radius={5}
                                                customCss={`white-space: nowrap; font-weight:normal;`}
                                                content="Pending"
                                              >
                                                <span
                                                  className={`notification-count-side ${
                                                    pendingCount === 0
                                                      ? 'bg-grey'
                                                      : 'bg-amber u-cursor-pointer'
                                                  }`}
                                                  onClick={() => {
                                                    if (pendingCount > 0) {
                                                      this.callExternalFilter(
                                                        `${statusCode}-pending`
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {pendingCount}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              <div className="d-sm-ib ta-sm-l">
                                <div className="flex-child menuOpt2Item  box  ">
                                  <div className="d-sm-b">
                                    <span className="d-sm-h d-md-ib summary-toggle">
                                      Change Status
                                    </span>
                                    {!userProfileError &&
                                      userProfileData &&
                                      userProfileData.factoryGroups &&
                                      userProfileData.factoryGroups.length >
                                        0 &&
                                      searchfirst && (
                                        <SearchNew
                                          searchGroups={searchGroups.filter(
                                            x => x.field === 'readStatus'
                                          )}
                                          searchOptions={userProfileData}
                                          onSearchFieldUpdate={
                                            this.onSearchFieldUpdate
                                          }
                                          usrFactGrpSearchData={
                                            usrFactGrpSearchData
                                          }
                                          pageType="changesgrid"
                                          key="changesgrid"
                                          isReadSelected={isReadSelected}
                                          isPendingSelected={isPendingSelected}
                                          onSearchIconClick={
                                            this.onSearchIconClick
                                          }
                                          notificationCount={notificationCount}
                                          defaultStatus={defaultStatus}
                                          onSearchGroupUpdate={
                                            this.onSearchGroupUpdate
                                          }
                                        />
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {/* Show Counts and Grid */}
                <div className="capvis-page-container vertical-align-baseline u-clearfix ">
                  <div className="container  m0-sm">
                    <div className=" d-sm-b u-clearfix">
                      <div
                        className={`u-clearfix border-light-grey d-sm-b  position-relative`}
                      >
                        <div className="tooltipPlacer c-f-all">
                          <div className="d-sm-b fl-sm-l plr4-sm p-r">
                            <Tooltip
                              arrow={10}
                              background="#fff"
                              border="#ccc"
                              color="#777"
                              fontSize="13px"
                              padding={5}
                              placement="top"
                              radius={5}
                              content="Mark Read"
                              customCss={`
                      white-space: nowrap;
                      font-weight:normal;
                    `}
                            >
                              <div
                                className={`create-icon-svg  ${
                                  isReadSelected
                                    ? 'u-cursor-pointer text-color-blue1'
                                    : 'text-color-grey1 opacity-50'
                                } `}
                                onClick={() => this.onSearchIconClick('bell')}
                              >
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  preserveAspectRatio="xMidYMid meet"
                                  fill="currentColor"
                                >
                                  <path d="M12.136842,17.9154 C12.1381782,17.9434619 12.1395145,17.9688513 12.1395145,17.9969132 C12.1395145,19.1033547 11.1613561,20 9.95602964,20 C8.74936694,20 7.77120847,19.1033547 7.77120847,17.9969132 C7.77120847,17.9688513 7.77254475,17.9434619 7.77388103,17.9154 L7.77388103,17.9154 Z M9.95576238,0 C10.4849301,0 10.9178855,0.318035131 10.9379297,1.00755668 C13.7788654,1.48728194 15.9449786,4.00884619 15.9449786,7.04888788 C15.9449786,7.27471955 16.1921908,10.9575129 16.1921908,10.9575129 C16.1921908,10.9575129 16.3632349,12.9138299 17.0273671,13.6862009 C17.5097649,14.2487756 18.0309149,14.5173683 18.2794633,14.6215983 C18.6402595,14.7044478 18.9101885,15.0198104 18.9101885,15.3939694 L18.9101885,15.3939694 L18.9101885,15.4086685 C18.9101885,15.8456327 18.5427109,16.20242 18.0937201,16.20242 L18.0937201,16.20242 L1.81646834,16.20242 C1.36747757,16.20242 1,15.8456327 1,15.4086685 L1,15.4086685 L1,15.3939694 C1,15.0799431 1.19108833,14.8073415 1.46636244,14.6777222 L1.46636244,14.6777222 L1.4249377,14.6777222 C1.4249377,14.6777222 2.16256539,14.4572356 2.82669758,13.6862009 C3.48949348,12.9138299 3.6592013,10.9575129 3.6592013,10.9575129 C3.6592013,10.9575129 3.96654618,7.27471955 3.96654618,7.04888788 C3.96654618,4.00884619 6.1326594,1.48728194 8.97225878,1.00755668 C8.9936393,0.318035131 9.4252584,0 9.95576238,0 Z"></path>
                                </svg>
                              </div>
                            </Tooltip>
                          </div>

                          <div className="d-sm-b fl-sm-l plr4-sm p-r">
                            <Tooltip
                              arrow={10}
                              background="#fff"
                              border="#ccc"
                              color="#777"
                              fontSize="13px"
                              padding={5}
                              placement="top"
                              radius={5}
                              customCss={`
                      white-space: nowrap;
                      font-weight:normal;
                    `}
                              content="Clear Pending"
                            >
                              <div
                                className={`create-icon-svg  ${
                                  isPendingSelected
                                    ? 'u-cursor-pointer text-color-accent'
                                    : 'text-color-grey1 opacity-50'
                                } `}
                                onClick={() => this.onSearchIconClick('flag')}
                              >
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  preserveAspectRatio="xMidYMid meet"
                                  fill="currentColor"
                                >
                                  <polygon points="17.7555556 0 17.7555556 10.5333333 4.29855724 10.5333333 4.29855724 20 2.2 20 2.2 0"></polygon>
                                </svg>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          id="myGrid"
                          ref={this.GridParentRel}
                          style={{ width: gridWidth }}
                        >
                          <Grid
                            columnDefs={pageConstants.columns}
                            defaultColDef={defaultColDef}
                            rowData={updateGridData}
                            setParams={this.setParams}
                            enableSorting
                            floatingFilter
                            overlayLoadingTemplate={overlayLoadingTemplate}
                            overlayNoRowsTemplate={overlayNoRow}
                            showFilter
                            frameworkComponents={frameworkComponents}
                            onCellValueChanged={this.onCellValueChanged}
                            onDisplayedColumnsChanged={
                              this.onDisplayedColumnsChanged
                            }
                            onFirstDataRendered={this.onFirstDataRendered}
                            excelStyles={excelStyles}
                            context={context}
                            statusCode={statusCode}
                            defaultStatus={defaultStatus}
                            getRowHeight={getRowHeight}
                            dbGridFunctions
                            internalFilterChangedCallBack={() => {}}
                            getContextMenuItems={this.getContextMenuItems}
                            headerHeight={55}
                            setheight={window.innerHeight - 315}
                            rowSelection="multiple"
                            onSelectionChanged={this.onSelectionChanged}
                            gridParent="changenotification"
                            suppressRowClickSelection={true}
                            onBodyScroll={e => {
                              this.bodyScroll(e);
                            }}
                            enableRangeSelection={false}
                          />
                        </div>
                      </div>
                      <div className="pageNotifyContainer">
                        {updateGridData && updateGridData.length > 0 && (
                          <div
                            className={`paginateNotify ${
                              paginationLoadInfo && !reachedLast ? 'in' : ''
                            }`}
                          >
                            <button
                              type="button"
                              onClick={() => this.loadMoreOnClick()}
                              className={`paginateNotifyBtn ${
                                paginationInitiated ? 'lite' : ''
                              }`}
                            >
                              {paginationInitiated ? (
                                <i className="text-color-light far fas fa-circle-notch fa-spin  fa-2x fa-fw"></i>
                              ) : (
                                'Load More'
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {usrFactGrpSearchData && usrFactGrpSearchData.length === 0 && (
                      <div className="bg-white border-light-grey p3-sm ta-sm-l">
                        <H1>
                          {' '}
                          No Factory Codes under selected Factory Group is
                          Associated for You !
                        </H1>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}
              {(userUpdatesDataLoading || usrFactGrpSearchLoading) && (
                <div>
                  <Spinner large overlay />
                </div>
              )}
            </div>
          </div>
        </Beforeunload>
      </div>
    );
  }
}

UserUpdates.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  requestAppconfigData: PropTypes.func,
  clearUserProfileData: PropTypes.func,
  userUpdatesDataLoading: PropTypes.bool,
  userUpdatesData: PropTypes.object,
  userUpdatesError: PropTypes.any,
  requestDashboardData: PropTypes.func,
  clearDashboardData: PropTypes.func,
  updateUserUpdateData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  userUpdatesDataUpdate: PropTypes.any,
  requestUserNotificationData: PropTypes.func,
  getUserNotification: PropTypes.func,
  appConfigData: PropTypes.object
};

const mapStateToProps = state => ({
  userUpdatesDataLoading: getUserUpdatesDataLoading(state),
  userUpdatesData: getUserUpdatesData(state),
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  userUpdatesError: getUserUpdatesError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  userUpdatesDataUpdate: getUpdatedUserUpdatesData(state),
  userNotifyData: getUserNotifyData(state),
  appConfigData: getAppConfigData(state)
});

const mapDispatchToProps = dispatch => ({
  requestDashboardData: status => {
    // Action for Making the status button as selected (Action required, In progress or completed)
    dispatch(selectStatusButton(status));
    // Get data to show on grid
    dispatch(createServiceAction('userUpdatesData', 'requested')(status));
  },
  requestUserNotificationData: () => {
    dispatch(createServiceAction('userNotifyData', 'requested')());
  },
  requestUserProfileData: () => {
    dispatch(createServiceAction('getUserProfile', 'requested')());
  },
  requestAppconfigData: () => {
    dispatch(createServiceAction('appConfigData', 'requested')());
  },
  clearUserProfileData: () => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('getUserProfile', 'clear')(null));
  },

  clearDashboardData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('userUpdatesData', 'clear')(status));
    dispatch(createServiceAction('userUpdatesError', 'clear')({}));
  },

  updateUserUpdateData: data => {
    dispatch(
      createServiceAction('updateUserUpdateData', 'submited')({ ...data })
    );
  },
  getUserNotification: data => {
    dispatch(createServiceAction('userNotifyData', 'requested')({}));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserUpdates)
);
