import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')({}));
    yield put(createServiceAction(serviceType, 'leverLoggingData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));

    const options = {
      leverTab: action.data.varianceCode
    };

    let leverLoggingEndpoint = `${apiConfig.leverLoggingData.endPointUrl}${action.data.growthLeverQuarterId}`;

    const res = yield call(getRequest, leverLoggingEndpoint, options, true);

    if (res.status === 200) {
      const response = yield res.json();
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'success')(response));
    } else {
      const errorMsg = { status: res.status, msg: res.statusText };
      yield put(createServiceAction(serviceType, 'loading')(false));
      yield put(createServiceAction(serviceType, 'error')(errorMsg));
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* leverLoggingDataSagas() {
  const serviceType = 'leverLoggingData';
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default leverLoggingDataSagas;
