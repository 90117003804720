import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER
} from './constants';

import { addMonthsToYear, addMonthsToYearAsDate } from './utils';

const moment = extendMoment(Moment);

export const now = moment();

export const buildQuarterCells = (START_YEAR, NUM_OF_YEARS) => {
  const v = [];
  for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const quarter = (i % 4) + 1;
    const startMonth = i * MONTHS_PER_QUARTER;
    const s = addMonthsToYear(START_YEAR, startMonth);
    const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER);
    v.push({
      id: `${s.year}-q${quarter}`,
      title: `Q${quarter} ${s.year}`,
      start: new Date(`${s.year}-${s.month}-01`),
      end: new Date(`${e.year}-${e.month}-01`)
    });
  }
  return v;
};

export const buildMonthCells = (START_YEAR, NUM_OF_YEARS) => {
  const v = [];
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i;
    const start = addMonthsToYearAsDate(START_YEAR, startMonth);
    const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1);
    v.push({
      id: `m${startMonth}`,
      title: MONTH_NAMES[i % 12],
      start,
      end
    });
  }
  return v;
};

export const buildTimebar = (START_YEAR, NUM_OF_YEARS) => [
  {
    id: 'quarters',
    title: 'Quarters',
    cells: buildQuarterCells(START_YEAR, NUM_OF_YEARS),
    style: {}
  },
  {
    id: 'months',
    title: 'Months',
    cells: buildMonthCells(START_YEAR, NUM_OF_YEARS),
    useAsGrid: true,
    style: {}
  }
];

export const buildElementBGColor = (start, end) => {
  const range = moment.range(start, end);
  const result = {
    isFuture: false,
    isPresent: false,
    isPast: false,
    bgColor: ''
  };

  if (range.contains(now)) {
    result.isPresent = true;
    result.bgColor = 'rgba(102, 187, 106, 1)';
  } else if (now.diff(end, 'days') > 0) {
    result.isPast = true;
    result.bgColor = 'rgba(153, 153, 153, 0.5)';
  } else {
    result.isFuture = true;
    result.bgColor = 'rgba(102, 187, 106, 0.5)';
  }

  return result;
};

export const getIsFutureEvent = (start, end) => {
  const range = moment.range(start, end);

  return !range.contains(now) && !(now.diff(end, 'days') > 0);
};

export const getStartEndYear = events => {
  const years = events.map(e => moment(e.effectiveDate).year());

  const START_YEAR = Math.min(...years);
  const END_YEAR = Math.max(...years) + 6;
  const NUM_OF_YEARS = END_YEAR - START_YEAR;

  return { START_YEAR, END_YEAR, NUM_OF_YEARS };
};
