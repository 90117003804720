import { toast } from 'react-toastify';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { postRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const data = action.data;
    yield put(createServiceAction(serviceType, 'loading')(true));
    const res = yield call(
      postRequest,
      apiConfig.updateAdminData.endPointUrl,
      data,
      true
    );
    yield put(createServiceAction(serviceType, 'loading')(false));
    if (res.status === 201) {
      yield put(createServiceAction(serviceType, 'success')(true));
      const message = `Changes Updated successfully.`;
      toast.success(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else if (res.status === 400) {
      yield put(createServiceAction(serviceType, 'error')(true));
      const message = `Failed to Update the changes. Try Again!`;
      toast.error(message, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(true));
    const message = `Failed to Update the changes. Try Again!`;
    toast.error(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }
}

function* updateAdminDataSagas() {
  const serviceType = 'updateAdminData';
  yield takeEvery(
    createServiceAction(serviceType, 'submited').type,
    listener,
    serviceType
  );
}

export default updateAdminDataSagas;
