/* eslint-disable max-statements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Beforeunload } from 'react-beforeunload';
import { H1, PageError, Spinner } from '../../components/index.js';
import { isMobile, isTablet } from 'react-device-detect';
import NumericEditor from '../../components/grid/NumericEditor.js';
import GBDPagination from '../../components/dashboard/GBDPagination.js';
import TabHeader from '../../components/dashboard/tabHeader';
import LeversMinimalCustomCell from '../../components/grid/LeversMinimalCustomCell.js';
import dayPickerFilter from '../../components/grid/dayPickerFilter.js';
import CustomHeader from '../../components/grid/CustomHeader.js';
import NotFound from '../../components/errors/NotFound.js';
import gridConstants from '../../constants/gridConstants.js';
import UploadUpdateModal from '../../components/dashboard/UploadUpdateModal.js';
import Grid from '../../components/grid/Grid.js';
import pageConstants from '../dashboard/Dashboard.constants.js';
import { AppCipher, AppDeCipher } from '../../util/cipher.js';

import {
  getGrowthLeverDataLoading,
  getGrowthLeverData,
  getGrowthLeverError,
  getUsrFactGrpSearchData,
  getUsrFactGrpSearchLoading,
  getUsrFactGrpSearchError,
  getGrowthLeverDataUpdate
} from '../../reducers/growthLeverReducer.js';

import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { getUserNotifyData } from '../../reducers/userNotifyReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import {
  searchQueryFrame,
  searchQueryCTFrame,
  updateErrorReport,
  updateFailedReport,
  gblExcelUploadRowCompare,
  validateGBLQuarterlyData,
  updateUploadDataFormat,
  growthLeverGridConfig,
  agGridDownloadFileName,
  agGridDownloadDataFormat,
  CustomExcelRenderer,
  gbdExcelUploadRowCompare,
  frameDataOnUpload,
  reasonCodeNameList,
  onCellValueChangFormatValidate,
  leverQuarterGridData,
  agGridCustomFooter
} from '../../services/growthLeverService';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';
import { msgConstants } from '../../constants/msgConstants.js';
import growthLeverSearchConstants from './growthLeverSearch.constants.js';
// import { isEquivalent } from '../../util/general.js';
import growthLeverConstants from './growthLeverConstants.js';
import {
  getAppConfigData,
  getAppConfigLoading,
  getAppConfigError
} from '../../reducers/appConfigReducer.js';
import UploadModal from '../../components/dashboard/uploadModal.js';
const searchFirst = true;
let noSearchInitiated = true;
const maxDownloadLimit = 4000;

export class GrowthLever extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGrid: false,
      defaultColDef: gridConstants.defaultColDef,
      excelStyles: gridConstants.excelStyles,
      userType: '',
      UploadUpdateModel: false,
      varianceValidationModal: false,
      UploadUpdateModelType: 'Grid',
      UploadStatusData: [],
      UploadingData: false,
      recentDownloadedGridFile: '',
      recentDownloadedGridFileId: '',
      frameworkComponents: {
        NumericCellEditor: NumericEditor,
        LeversMinimalCustomCell: LeversMinimalCustomCell,
        agDateInput: dayPickerFilter,
        agColumnHeader: CustomHeader
      },
      context: { componentParent: this },
      defaultStatus: 'MaxAvailable',
      editGrid: false,
      gridOptions: growthLeverConstants.gridOptions,
      sideBar: growthLeverConstants.sideBar,
      gridColumns: [],
      reasonCodes: [],
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      statusCode: '',
      commentTextRowId: '',
      commentTextRowDataId: [],
      currentRowComments: [],
      currentRowLogs: [],
      totalLength: 0,
      // eslint-disable-next-line react/no-unused-state
      uploadErrorState: [],
      gridPagination: {},
      searchGroups: growthLeverSearchConstants.groups,
      newSearchgroups: [],
      getRowHeight(params) {
        let defaultheight = 55;
        return defaultheight;
      },
      changedRowsData: [],
      gridCurrentPageData: [],
      gridColumnConfig: [],
      defaultSortAfterLoad: [],

      pageSearch: 0,
      // eslint-disable-next-line no-dupe-keys
      recentDownloadedGridFile: '',
      // eslint-disable-next-line no-dupe-keys
      recentDownloadedGridFileId: '',
      updateCycleCompleteModel: false,
      updateCycleCompleteMsg: false,
      updateCycleReport: {},
      revisedHorizonWeeks: 0,
      displayView: 0,
      prodQuarterColumns: [],
      leverQuarterData: [],
      leverQuarterDataBackUp: [],
      varianceValidationResult: [],
      agGridQuarterParams: {},
      quarterRowDataChanged: [],
      quarterGridCurrentFilter: {},
      commentSession: {},
      thresholdCounts: { passed: 0, all: 0 },
      actualStartWeekDate: '',
      gblColumnData: [],
      currentPage: 1,
      totalPage: 1,
      gridWidth: '100%',
      userFactoryType: ''
    };

    this.GridParentRel = React.createRef();
    this.getGrowthLeverType = this.getGrowthLeverType.bind(this);
    this.onSearchEnter = this.onSearchEnter.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.onSearchEnterApi = this.onSearchEnterApi.bind(this);
    this.onSearchFactoryGrpApi = this.onSearchFactoryGrpApi.bind(this);
    this.onSearchCall = this.onSearchCall.bind(this);
    this.onSearchGroupUpdate = this.onSearchGroupUpdate.bind(this);
    this.onQuarterCellValueChanged = this.onQuarterCellValueChanged.bind(this);
    this.setParams = this.setParams.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
  }

  componentDidMount() {
    // On page load, loading max available growthLever as default
    let defaultStatus = 'MaxAvailable';
    const {
      location,
      usrFactGrpSearchData,
      userProfileData,
      appConfigData
    } = this.props;
    const { pathname, query } = location;
    const { searchGroups } = this.state;
    // checking url is to load max available or short horizon growthLever
    if (pathname && pathname.indexOf('revised') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    let userType = '';
    let userFactoryType = '';
    if (userProfileData) {
      userType = userProfileData.userRole;
      userFactoryType = userProfileData.factoryType;
      this.setState({ userType, userFactoryType });
    }
    // Function to call API for Grid Data
    if (usrFactGrpSearchData) {
      const ci = searchGroups.findIndex(o => o.field === 'capacityTypeCode');
      if (usrFactGrpSearchData && usrFactGrpSearchData.length >= 0) {
        const codeLists = _.sortBy(usrFactGrpSearchData, 'value');
        searchGroups[ci].options = codeLists || [];
      }
      this.setState({
        searchGroups,
        userFactGrpSearchData: usrFactGrpSearchData
      });
    }
    this.setState({ searchQuery: query, currentPage: 1 }, () => {
      setTimeout(() => {
        this.getGrowthLeverType(defaultStatus, query);
      }, 100);
    });
    localStorage.setItem('isQuarterRowDataChanged', 'false');
    localStorage.setItem('isGBDQuarterRowDataChanged', 'false');

    let rvsdHorzLocal = localStorage.getItem('revisedHorizonWeeks');
    let revisedHorizonWeeks = rvsdHorzLocal ? Number(rvsdHorzLocal) : 0;
    if (appConfigData) {
      let newAppConfig = appConfigData;
      if (newAppConfig && Object.keys(newAppConfig) !== 0) {
        let updatedGridCols = growthLeverGridConfig(
          newAppConfig.reasonCodes || [],
          [...growthLeverConstants.columnDefs],
          userType,
          userFactoryType,
          defaultStatus,
          newAppConfig.revisedViewConfig || {}
        );
        revisedHorizonWeeks = newAppConfig.revisedHorizonWeeks
          ? newAppConfig.revisedHorizonWeeks
          : revisedHorizonWeeks;

        this.setState({
          revisedHorizonWeeks: revisedHorizonWeeks,
          gridColumns: updatedGridCols,
          reasonCodes: newAppConfig.reasonCodes
        });
      }
    }
  }

  getTableHeight = () => {
    let height = window.innerHeight - 285;
    if (isMobile || isTablet) {
      height = window.innerHeight - 90; // datalength * 28 + 150;
    }
    if (height < 390) {
      height = 390;
    } else {
      return null;
    }

    return height;
  };
  // eslint-disable-next-line complexity
  componentWillReceiveProps(nextProps) {
    // On page load, loading max available growthLever as default
    let defaultStatus = 'MaxAvailable';
    const {
      searchGroups,
      UploadStatusData,
      actualStartWeekDate,
      userType,
      userFactoryType
    } = this.state;
    const {
      location,
      growthLeverData,
      usrFactGrpSearchData,
      userProfileData,
      growthLeverDataUpdate,
      appConfigData,
      userNotifyData
    } = this.props;
    const { search, query } = location;
    const newPathName = nextProps.location.pathname;
    if (
      JSON.stringify(usrFactGrpSearchData) !==
      JSON.stringify(nextProps.usrFactGrpSearchData)
    ) {
      const CodeIndx = searchGroups.findIndex(
        o => o.field === 'capacityTypeCode'
      );
      if (
        nextProps.usrFactGrpSearchData &&
        nextProps.usrFactGrpSearchData.length >= 0
      ) {
        const codeLists = _.sortBy(nextProps.usrFactGrpSearchData, 'value');
        searchGroups[CodeIndx].options = codeLists || [];
      }

      this.setState(
        {
          searchGroups,
          userFactGrpSearchData: nextProps.usrFactGrpSearchData,
          searchQuery: query,
          currentPage: 1
        },
        () => {
          setTimeout(() => {
            this.getGrowthLeverType(defaultStatus, location.query);
          }, 100);
        }
      );
    }

    if (search !== nextProps.location.search) {
      this.setState(
        {
          searchQuery: nextProps.location.query,
          currentPage: 1
        },
        () => {
          setTimeout(() => {
            this.getGrowthLeverType(defaultStatus, nextProps.location.query);
          }, 100);
        }
      );
    }

    if (
      newPathName &&
      (newPathName.indexOf('revised') !== -1 ||
        newPathName.indexOf('shortHorizon') !== -1)
    ) {
      defaultStatus = 'shortHorizon';
    }
    if (userProfileData !== nextProps.userProfileData) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        this.setState({
          userType: newUsrProData.userRole,
          userFactoryType: newUsrProData.factoryType
        });
      }
    }

    if (growthLeverDataUpdate !== nextProps.growthLeverDataUpdate) {
      const updateData = nextProps.growthLeverDataUpdate;
      let UploadStatusDataNew = [];
      if (
        updateData &&
        (updateData.status === 201 ||
          (Array.isArray(updateData.status) && updateData.status.includes(201)))
      ) {
        if (updateData.outputData) {
          UploadStatusDataNew = updateErrorReport(
            updateData.outputData,
            UploadStatusData
          );
        }
      } else {
        UploadStatusDataNew = updateFailedReport(UploadStatusData);
      }
      this.setState({
        updateCycleComplete: true,
        UploadStatusData: UploadStatusDataNew
      });
      this.reloadGridContentAfterSave(nextProps.growthLeverDataUpdate);
    }

    let rvsdHorzLocal = localStorage.getItem('revisedHorizonWeeks');
    let revisedHorizonWeeks = rvsdHorzLocal ? Number(rvsdHorzLocal) : 0;
    if (appConfigData) {
      revisedHorizonWeeks = appConfigData.revisedHorizonWeeks
        ? appConfigData.revisedHorizonWeeks
        : 0;
    }
    if (appConfigData !== nextProps.appConfigData) {
      let newAppConfig = nextProps.appConfigData;
      if (newAppConfig && Object.keys(newAppConfig) !== 0) {
        let updatedGridCols = growthLeverGridConfig(
          newAppConfig.reasonCodes || [],
          [...growthLeverConstants.columnDefs],
          userType,
          userFactoryType,
          defaultStatus,
          newAppConfig.revisedViewConfig || {}
        );
        revisedHorizonWeeks = newAppConfig.revisedHorizonWeeks
          ? newAppConfig.revisedHorizonWeeks
          : revisedHorizonWeeks;

        this.setState({
          revisedHorizonWeeks: revisedHorizonWeeks,
          gridColumns: updatedGridCols,
          reasonCodes: newAppConfig.reasonCodes
        });
      }
    }

    if (
      !_.isEqual(nextProps.growthLeverData, growthLeverData) &&
      nextProps.growthLeverData.data
    ) {
      const leverQuarterData = leverQuarterGridData(
        nextProps.growthLeverData.data,
        defaultStatus,
        actualStartWeekDate
      );
      const leverQuarterDataBackUp = JSON.parse(
        JSON.stringify(leverQuarterData)
      );
      leverQuarterData.forEach(data => {
        data['CapQMaxCapAdjVarPresent'] =
          data['CapQMaxCapAdjVarPresent'] !== '' &&
          Math.round(data['CapQMaxCapAdjVarPresent']);
        data['CapQMaxCapAdjVarPrevious'] =
          data['CapQMaxCapAdjVarPrevious'] !== '' &&
          Math.round(data['CapQMaxCapAdjVarPrevious']);
        data['MaxQMaxCapAdjVarPresent'] =
          data['MaxQMaxCapAdjVarPresent'] !== '' &&
          Math.round(data['MaxQMaxCapAdjVarPresent']);
        data['MaxQMaxCapAdjVarPrevious'] =
          data['MaxQMaxCapAdjVarPrevious'] !== '' &&
          Math.round(data['MaxQMaxCapAdjVarPrevious']);
      });
      let varianceValidationResult = [];
      this.setState({
        leverQuarterData,
        leverQuarterDataBackUp,
        varianceValidationResult,
        varianceValidationModal:
          varianceValidationResult.length > 0 ? true : false
      });
    }

    try {
      if (
        nextProps.growthLeverData.role !== growthLeverData.role ||
        nextProps.growthLeverData.growthLever !== growthLeverData.dashboard
      ) {
        this.setState({
          loadGrid: false
        });
      }
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }

    this.setState({ userNotifyData });
  }

  onSearchGroupUpdate(searchGroups) {
    const { clearUsrFactGrpSearchData } = this.props;
    // update the state of search group fields from the component
    clearUsrFactGrpSearchData();
    this.setState({ searchGroups, pageSearch: 0 });
  }

  onProductionQuarterLinkClick = (data, productionQuarterLink) => {
    const { history } = this.props;

    history.push(productionQuarterLink);
  };

  goToPage(action) {
    this.setState({ currentPage: action });
    setTimeout(() => {
      this.onSearchEnterApi();
    }, 100);
  }

  onSearchEnterApi() {
    // after selecting all required search params, retriving data
    this.setState({
      gridCurrentPageData: [],
      updateCycleComplete: false
    });
    //const { userProfileData } = this.props;
    const {
      defaultStatus,
      userType,
      searchGroups,
      revisedHorizonWeeks,
      currentPage,
      searchQuery
    } = this.state;

    const {
      requestGrowthLeverData,
      currentusername,
      clearGrowthLeverData
    } = this.props;
    clearGrowthLeverData({});
    this.setState({
      leverQuarterData: [],
      leverQuarterDataBackUp: []
    });
    if (searchQuery && searchQuery.factoryCode) {
      ///
      noSearchInitiated = false;
      const searchfields = searchQueryCTFrame(
        searchGroups,
        searchQuery,
        defaultStatus,
        revisedHorizonWeeks
      );
      const pageStart =
        currentPage === 1 ? 1 : (currentPage - 1) * searchfields.count + 1;
      const pageEnd =
        currentPage === 1
          ? searchfields.count
          : currentPage * searchfields.count;
      const getGrowthLeverData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchfields,
        revisedHorizonWeeks: revisedHorizonWeeks,
        pageStart: pageStart,
        pageEnd: pageEnd,
        type: 'bulk',
        factoryCode: searchQuery.factoryCode,
        maxDownloadLimit: maxDownloadLimit
      };
      const actualStartWeekDate = searchfields.actualStartWeekDate;
      this.setState({
        loadGrid: true,
        changedRowsData: [],
        quarterRowDataChanged: [],
        actualStartWeekDate: actualStartWeekDate,
        totalPage: searchfields.pages
      });
      localStorage.setItem('isGBDQuarterRowDataChanged', 'false');

      requestGrowthLeverData(getGrowthLeverData); // API  Request function for Grid Data
    } else {
      const onSearchValidStatus = {
        status: false,
        message: 'Please Select one Factory Code'
      };

      this.uploadStatusUpdate(onSearchValidStatus);
      noSearchInitiated = true;
    }
  }

  onSearchFactoryGrpApi() {
    // this.setState({ gridCurrentPageData: [] });
    const {
      searchGroups,
      gridPagination,
      revisedHorizonWeeks,
      defaultStatus
    } = this.state;
    noSearchInitiated = true;
    const { getUsrFactGrpSearchData } = this.props;
    const allString = searchQueryFrame(
      1,
      searchGroups,
      searchGroups,
      gridPagination,
      maxDownloadLimit,
      revisedHorizonWeeks,
      defaultStatus
    );
    if (allString) {
      getUsrFactGrpSearchData(allString);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSearchEnter() {
    const { searchGroups } = this.state;
    this.setState({
      newSearchgroups: JSON.parse(JSON.stringify(searchGroups))
    });
    // updates the seach box entries, frames query and calls API
    if (localStorage.getItem('isGBDQuarterRowDataChanged') === 'false') {
      this.setState({ quarterRowDataChanged: [] });
    }
    this.setState({
      gridCurrentPageData: [],
      statusCode: '',
      currentPage: 1
    });
    const { quarterRowDataChanged } = this.state;
    if (quarterRowDataChanged.length === 0) {
      setTimeout(() => {
        this.onSearchEnterApi();
      }, 300);
      return false;
    } else {
      confirmAlert({
        message: msgConstants.pageChangeAlert,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.setState({ quarterRowDataChanged: [] });
              this.onSearchEnterApi();
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
  }

  onSearchCall() {
    this.setState({ gridPagination: {} });

    setTimeout(() => {
      this.onSearchEnter();
    }, 100);
  }

  getGrowthLeverType(defaultStatus, query) {
    const { currentusergroups, clearGrowthLeverData } = this.props;

    noSearchInitiated = true;
    // eslint-disable-next-line prefer-const
    let { userType } = this.state;
    const sessionUserType = window.sessionStorage.getItem('currentUserGroup');
    if (sessionUserType) {
      userType = sessionUserType;
    } else {
      userType = currentusergroups[0].value;
    }
    // setting the factory codes for the default dropdown in serch box

    // end of update

    this.setState(
      {
        gridCurrentPageData: [],
        updateCycleComplete: false,
        loadGrid: true,
        changedRowsData: [],
        quarterRowDataChanged: [],
        gridColumnConfig: [],
        pageSearch: 0,
        defaultStatus,
        userType,
        query
      },
      () => {
        setTimeout(() => {
          clearGrowthLeverData({});
          this.onSearchEnterApi();
        }, 500);
      }
    );

    /* */
  }

  setParams = params => {
    // Function called post GRID initial load to reassure column structure and fields

    if (params) {
      this.setState({ agGridQuarterParams: params });
      if (params.columnApi.getAllColumns()) {
        try {
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();

          const sortModel = [{ colId: 'capacityTypeCode', sort: 'asc' }];
          params.api.setSortModel(sortModel);

          const availableWidth = this.GridParentRel.current.offsetWidth;
          const displayedColumns = params.columnApi.getAllDisplayedColumns();
          const usedWidth = _.sumBy(displayedColumns, 'actualWidth');
          /*const usedWidth = displayedColumns.map(item => item.actualWidth).reduce((prev, next) => prev + next);*/

          if (availableWidth > usedWidth) {
            params.api.sizeColumnsToFit();
          }

          setTimeout(() => {
            // this.setState({ gridWidth: '100%' }); // update AG Grid Properties to state
          }, 500);
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  reloadGridContentAfterSave = onSaveResp => {
    const { agGridQuarterParams } = this.state;
    if (
      onSaveResp &&
      onSaveResp.outputData &&
      onSaveResp.outputData.updateStatus
    ) {
      const AllGridDataCopy = [];

      agGridQuarterParams.api.forEachNode(node => {
        AllGridDataCopy.push(node.data);
      });

      agGridQuarterParams.api.redrawRows();
      this.setState({
        leverQuarterDataBackUp: JSON.parse(JSON.stringify(AllGridDataCopy))
      });
    } else {
      const commentsUpdateStatus = {
        status: false,
        message: 'SAVE FAILED!  RETRY'
      };
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
    this.onSearchEnterApi();
  };

  onQuarterCellValueChanged = event => {
    const {
      quarterRowDataChanged,
      agGridQuarterParams,
      reasonCodes,
      leverQuarterDataBackUp
    } = this.state;
    const commentsUpdateStatus = {
      status: false,
      message: msgConstants.inValFormatData
    };
    const editedColumn = event.colDef.field;
    let rowNode = agGridQuarterParams.api.getDisplayedRowAtIndex(
      event.rowIndex
    );
    const oldRowValue = leverQuarterDataBackUp.find(
      o => o.id === rowNode.data.id
    );
    const index = quarterRowDataChanged.findIndex(
      i => i.id === rowNode.data.id
    );

    const changeAccepted = onCellValueChangFormatValidate(
      event.colDef.filter,
      event.newValue,
      editedColumn,
      reasonCodes
    );
    if (!changeAccepted) {
      rowNode.data[event.colDef.field] = oldRowValue[editedColumn];
      agGridQuarterParams.api.redrawRows(event.rowIndex);
      this.uploadStatusUpdate(commentsUpdateStatus);
    }

    const toCheck = ['FctyMaxCapacity', 'maxCapacityReasonCode'];
    const cleanedRow = gblExcelUploadRowCompare(
      rowNode.data,
      oldRowValue,
      toCheck
    );
    if (
      oldRowValue[editedColumn] &&
      (!isNaN(event.newValue) && event.newValue < 0) &&
      editedColumn === 'FctyMaxCapacity'
    ) {
      rowNode.data[editedColumn] = oldRowValue[editedColumn];
      commentsUpdateStatus.message = msgConstants.repEmptyCapVal;
      this.uploadStatusUpdate(commentsUpdateStatus);
      agGridQuarterParams.api.redrawRows(event.rowIndex);
    } else {
      /// updating for number values
      if (['FctyMaxCapacity'].includes(editedColumn)) {
        rowNode.data[editedColumn] =
          rowNode.data[editedColumn] && Number(rowNode.data[editedColumn]);
      }
      const sameOld =
        cleanedRow && Object.keys(cleanedRow).length > 0 ? false : true;
      if (sameOld) {
        quarterRowDataChanged.splice(index, 1);
      } else {
        const newRowData = JSON.parse(JSON.stringify(rowNode.data));
        if (index >= 0) {
          quarterRowDataChanged[index] = newRowData;
        } else {
          quarterRowDataChanged.push(newRowData);
        }
      }
    }
    if (quarterRowDataChanged.length > 0) {
      localStorage.setItem('isGBDQuarterRowDataChanged', 'true');
    } else {
      localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
    }
    this.setState({ quarterRowDataChanged });
  };
  toggleUploadModel = () => {
    const { UploadModel } = this.state;
    if (
      localStorage.getItem('isRowDataChanged') === 'false' &&
      localStorage.getItem('isQuarterRowDataChanged') === 'false'
    ) {
      this.setState({
        changedRowsData: [],
        quarterRowDataChanged: [],
        UploadModel: !UploadModel
      });
    } else {
      const { changedRowsData, quarterRowDataChanged } = this.state;
      if (changedRowsData.length > 0 || quarterRowDataChanged.length > 0) {
        confirmAlert({
          message: msgConstants.pageChangeAlert,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  changedRowsData: [],
                  quarterRowDataChanged: []
                });
                localStorage.setItem('isRowDataChanged', 'false');
                localStorage.setItem('isQuarterRowDataChanged', 'false');
                // toggles the upload dialog
                this.setState({ UploadModel: !UploadModel });
              }
            },
            {
              label: 'No'
            }
          ]
        });
      }
    }
  };
  toogleUploadUpdateModel = () => {
    // toggles the upload dialog
    let { UploadStatusData, validatedUploadPQResult } = this.state;
    const { UploadUpdateModel, agGridQuarterParams } = this.state;
    if (UploadUpdateModel) {
      const AllGridDataCopy = [];
      agGridQuarterParams.api.forEachNode(node =>
        AllGridDataCopy.push(node.data)
      );
      this.setState({
        gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
      });
      UploadStatusData = [];
      validatedUploadPQResult = [];
    }
    this.setState({
      UploadUpdateModel: !UploadUpdateModel,
      UploadStatusData,
      validatedUploadPQResult
    });
  };
  fileHandler = event => {
    if (event.target.files.length) {
      this.setState({ UploadingData: true });
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.fileFormatError
      };

      const fileObj = event.target.files[0];
      const fileName = fileObj.name;
      const { recentDownloadedGridFile } = this.state;
      // check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
        if (fileObj.size < 1000) {
          // default file size of
          commentsUpdateStatus.message = msgConstants.uploadFailNoContent;
          this.uploadStatusUpdate(commentsUpdateStatus);
        } else {
          // for conflict name valdiation
          if (recentDownloadedGridFile.length === 0) {
            commentsUpdateStatus.message =
              msgConstants.uploadFailInvalidSession;
            this.uploadStatusUpdate(commentsUpdateStatus);
          } else if (fileName.includes(recentDownloadedGridFile)) {
            //ExcelRenderer to start here
            this.excelRendererFn(fileObj, commentsUpdateStatus);
          } else {
            confirmAlert({
              message: msgConstants.uploadWarnNoMatchFileName,
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    this.excelRendererFn(fileObj, commentsUpdateStatus);
                  }
                },
                {
                  label: 'No',
                  onClick: () => {
                    this.setState({ UploadingData: false, UploadModel: false });
                  }
                }
              ]
            });
          }
        }
      } else {
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    } else {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.uploadFailNoFile
      };

      this.setState({ UploadingData: false, UploadModel: false });
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };
  excelRendererFn(fileObj, commentsUpdateStatus) {
    const { recentDownloadedGridFileId, isToggleOn } = this.state;

    CustomExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        this.uploadStatusUpdate(commentsUpdateStatus);
      } else {
        const fileID = AppDeCipher(resp.rows[0][1]);
        const aggregationAttribute = 'QUARTERLY';
        const uploadedFileAttributes = fileID.split('-');
        const errorCase = { status: true, message: '' };
        if (!recentDownloadedGridFileId.includes(fileID)) {
          errorCase.status = false;
          errorCase.message = msgConstants.uploadFailNoMatchId;
          this.uploadStatusUpdate(errorCase);
        } else if (uploadedFileAttributes[5] !== aggregationAttribute) {
          errorCase.status = false;
          errorCase.message = isToggleOn
            ? msgConstants.uploadFailNotQuarterly
            : msgConstants.uploadFailNotWeekly;
          this.uploadStatusUpdate(errorCase);
        } else {
          this.uploadProcess(resp.rows);
        }
      }
    });

    setTimeout(() => {
      const { UploadModel } = this.state;
      if (UploadModel) {
        this.setState({
          editGrid: true,
          UploadingData: false,
          UploadModel: false
        });
        commentsUpdateStatus.message = msgConstants.noChangeUpdate;
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    }, 45000);
  }
  //**To upload excel data to ag grid and validation */
  uploadProcess = data => {
    let jsonDataRaw = data;
    let jsonDataMapped = [];
    const { agGridQuarterParams, defaultStatus, thresholdCounts } = this.state;
    const { updateGrowthLeverData, userProfileData } = this.props;

    const validatedUploadPQData = [];

    //**Array to hold validation result data return by validation function */
    const validatedUploadPQResult = [];

    //**uploadStatus status to show error */
    const uploadStatus = {
      status: false,
      message: msgConstants.uploadFailNoData
    };

    //**array to hold ag grid row quarter data */
    const activeGridSet = [];
    agGridQuarterParams.api.forEachNode(node => activeGridSet.push(node.data));

    //**ag grid columns list */
    const gridColumns = agGridQuarterParams.columnApi.getAllColumns();

    let maxReasonCodes = reasonCodeNameList(gridColumns);

    //**frame the data uploaded by excel  */
    jsonDataMapped = frameDataOnUpload(
      jsonDataRaw,
      activeGridSet.length,
      gridColumns,
      pageConstants.excelHeader.length
    );

    //**getting columns that are editable for user */
    const columnDefs = [...growthLeverConstants.columnDefs];
    // eslint-disable-next-line no-unused-vars
    let editableColumns = [];
    if (userProfileData.factoryType === 'FW') {
      columnDefs.map(x =>
        x.isFW && x.editable ? editableColumns.push(x.field) : false
      );
    } else {
      columnDefs.map(x =>
        x.isAP && x.editable ? editableColumns.push(x.field) : false
      );
    }

    //**push 'id' column name in columns name list because id is not editable */
    editableColumns.push('id');

    if (jsonDataMapped.length > 0) {
      //**Array that will hold objects that row whose data is changed */
      let gbdExcelEditedRows = [];

      jsonDataMapped.forEach((item, index) => {
        if (index >= pageConstants.excelHeader.length) {
          const agRowDataIndex = activeGridSet.findIndex(i => i.id === item.id);

          //**function call to compare ag grid and excel data object to check changes */
          const cleanedRow = gbdExcelUploadRowCompare(
            item,
            activeGridSet[agRowDataIndex],
            editableColumns
          );
          if (cleanedRow) {
            gbdExcelEditedRows.push(cleanedRow);
          }
        }
      });

      if (gbdExcelEditedRows.length > 0) {
        gbdExcelEditedRows.forEach(qrItem => {
          //**find Ag grid data row where id is same with changed data row in excel */
          const foundIndex = activeGridSet.findIndex(i => i.id === qrItem.id);
          const backupRow = activeGridSet[foundIndex];
          //**calling validation function to validate data */
          const { newActRow, result } = validateGBLQuarterlyData(
            qrItem,
            backupRow,
            maxReasonCodes
          );
          // if (result.length > 0) {
          if (result.length > 0) {
            //**adding data in result obj to it on validation report */
            result.forEach(log => {
              validatedUploadPQResult.push({
                ...log,
                ...backupRow,
                row: foundIndex + 1
              });
            });
          }
          const isChanged = result.findIndex(o => o.status === true);
          const ChangeByUpload = isChanged >= 0 ? 1 : 0;
          if (ChangeByUpload > 0) {
            //**making new object with old and new updated values */
            validatedUploadPQData.push({
              ...backupRow,
              ...qrItem,
              ...newActRow
            });
          }
        });
        if (validatedUploadPQData.length > 0) {
          //**changing ag grid row data with updated values */
          validatedUploadPQData.forEach(changedRowsData => {
            const agRowDataIndex = activeGridSet.findIndex(
              i => i.id === changedRowsData.id
            );
            activeGridSet[agRowDataIndex] = {
              ...activeGridSet[agRowDataIndex],
              ...changedRowsData
            };
          });
          const modFormattedUploadData = updateUploadDataFormat(
            validatedUploadPQData
          );
          //**updating ag grid UI table with new values  */
          agGridQuarterParams.api.setRowData(activeGridSet);

          if (modFormattedUploadData.length > 0) {
            const AllGridData = {
              dashboard: defaultStatus,
              data: modFormattedUploadData,
              type: 'file Update',
              thresholdCounts
            }; // current grid

            //**saving the data */
            updateGrowthLeverData(AllGridData);
          } else {
            this.setState({
              updateCycleComplete: true
            });
            if (validatedUploadPQData.length) {
              uploadStatus.message = msgConstants.uploadFailNoMatchData;
            }
            this.uploadStatusUpdate(uploadStatus);
          }
        }
        let showOnlyPQReport = false;
        if (validatedUploadPQResult.length > 0) {
          showOnlyPQReport = true;
        }
        this.setState({
          quarterRowDataChanged: [],
          validatedUploadPQResult,
          UploadStatusData: [],
          UploadUpdateModelType: 'file',
          UploadUpdateModel: showOnlyPQReport
        });
        agGridQuarterParams.api.redrawRows();
      } else {
        //**no data is changed in excel error */
        this.uploadStatusUpdate(uploadStatus);
      }
    } else {
      //**No data is available  in excel error*/
      this.uploadStatusUpdate(uploadStatus);
    }
    this.setState({
      editGrid: true,
      UploadingData: false,
      UploadModel: false,
      isCommentAddedInQuarterView: true
    });
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      this.setState({
        editGrid: true,
        UploadingData: false,
        UploadModel: false
      });
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  onSaveClick() {
    this.saveProdQuarterData();
  }
  saveProdQuarterData = () => {
    const { updateGrowthLeverData } = this.props;
    const {
      quarterRowDataChanged,
      leverQuarterDataBackUp,
      agGridQuarterParams,
      defaultStatus,
      thresholdCounts
    } = this.state;
    const validatedUploadPQData = [];
    const validatedUploadPQResult = [];
    const gridColumns = agGridQuarterParams.columnApi.getAllColumns();

    let maxReasonCodes = reasonCodeNameList(gridColumns);

    if (quarterRowDataChanged.length > 0) {
      const quarterCurrentGrid = [];
      agGridQuarterParams.api.forEachNodeAfterFilterAndSort(node =>
        quarterCurrentGrid.push(node.data)
      );
      quarterRowDataChanged.forEach(qrItem => {
        const foundIndex = quarterCurrentGrid.findIndex(
          i => i.id === qrItem.id
        );
        const backupRow = leverQuarterDataBackUp.find(i => i.id === qrItem.id);
        const { newActRow, result } = validateGBLQuarterlyData(
          qrItem,
          backupRow,
          maxReasonCodes
        );
        if (result.length > 0) {
          result.forEach(log => {
            const logNew = { ...log };
            logNew.id = qrItem.id;
            logNew.row = foundIndex + 1;
            logNew.productionQuarter = qrItem.productionQuarter;
            logNew.factoryCode = qrItem.factoryCode;
            logNew.capacityTypeCode = qrItem.capacityTypeCode;
            logNew.weekStartDate = qrItem.weekStartDate;
            validatedUploadPQResult.push(logNew);
          });
        }
        const isChanged = result.findIndex(o => o.status === true);
        const ChangeByUpload = isChanged >= 0 ? 1 : 0;
        if (ChangeByUpload > 0) {
          validatedUploadPQData.push(newActRow);
        }
        let rowNode = agGridQuarterParams.api.getDisplayedRowAtIndex(
          foundIndex
        );
        rowNode.data = newActRow;
        agGridQuarterParams.api.redrawRows(foundIndex);
      });
      if (validatedUploadPQData.length > 0) {
        const modFormattedUploadData = updateUploadDataFormat(
          validatedUploadPQData
        );
        const AllGridData = {
          dashboard: defaultStatus,
          data: modFormattedUploadData,
          type: 'row Update',
          thresholdCounts
        }; // current grid
        updateGrowthLeverData(AllGridData);
      }
    }
    let showOnlyPQReport = false;
    if (validatedUploadPQResult.length > 0) {
      showOnlyPQReport = true;
    }
    this.setState({
      quarterRowDataChanged: [],
      validatedUploadPQResult,
      UploadStatusData: [],
      UploadUpdateModelType: 'Grid',
      UploadUpdateModel: showOnlyPQReport
    });
    localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
    agGridQuarterParams.api.redrawRows();
    this.setState({
      editGrid: true,
      UploadingData: false,
      UploadModel: false,
      isCommentAddedInQuarterView: true
    });
  };

  downloadData = () => {
    const { defaultStatus, userType, agGridQuarterParams } = this.state;
    const { currentusername } = this.props;
    const fileInfo = agGridDownloadFileName(
      defaultStatus,
      userType,
      'bulk',
      currentusername,
      true
    );
    this.setState({
      recentDownloadedGridFile: fileInfo.fileName,
      recentDownloadedGridFileId: fileInfo.fileID
    });
    const params = {
      fileName: fileInfo.fileName,
      sheetName: fileInfo.sheetName,
      skipColumnGroupHeaders: false,
      processHeaderCallback(hedpar) {
        return hedpar.column.colDef.headerName;
      },
      processCellCallback(paramsl) {
        // data format change for excel
        return agGridDownloadDataFormat(paramsl);
      }
    };

    // for custom header
    const getAllColumns = agGridQuarterParams.columnApi.getAllColumns();
    let customHeader = JSON.parse(JSON.stringify(pageConstants.excelHeader));
    customHeader[0][1].data.value = AppCipher(fileInfo.fileID);
    params.customHeader = customHeader;
    params.columnWidth = 10;
    params.skipColumnGroupHeaders = false;
    //** add reason code in excel sheet */
    params.customFooter = agGridCustomFooter(getAllColumns);
    //**Only selected column will export */
    params.columnKeys = getAllColumns.reduce(
      (previousValue, currentValue) =>
        currentValue.colId !== 'indicator'
          ? previousValue.concat(currentValue.colId)
          : previousValue,
      []
    );

    agGridQuarterParams.api.exportDataAsExcel(params);

    toast.success(msgConstants.excelDownloadSuccess, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  getContextMenuItems(params) {
    var result = ['copy'];
    return result;
  }
  // eslint-disable-next-line complexity
  render() {
    // rendering the dashboard content
    const {
      growthLeverData,
      growthLeverDataLoading,
      growthLeverError,
      userProfileData,
      userProfileError,
      usrFactGrpSearchData,
      usrFactGrpSearchLoading,
      userNotifyData,
      location,
      appConfigData
    } = this.props; // receive from API response
    const {
      defaultStatus,

      pageSearch,
      defaultColDef,

      frameworkComponents,
      excelStyles,
      context,
      statusCode,
      getRowHeight,
      UploadModel,
      UploadUpdateModel,
      UploadStatusData,
      UploadUpdateModelType,
      UploadingData,
      updateCycleComplete,
      updateCycleReport,
      recentDownloadedGridFile,
      quarterRowDataChanged,
      validatedUploadPQResult,
      userType,
      gridColumns,
      totalPage,
      currentPage,
      gridWidth,
      leverQuarterData
    } = this.state;
    // const { columnDefs } = growthLeverConstants;
    let gridHeight =
      window.innerHeight - 335 < 300 ? 300 : window.innerHeight - 335;
    const { featureConfig } = appConfigData || {};
    return (
      <div className={`page-align-items  `}>
        {featureConfig && !featureConfig.lever && <NotFound status="400" />}
        {featureConfig && featureConfig.lever && (
          <Beforeunload
            onBeforeunload={event => {
              if (quarterRowDataChanged.length === 0) {
                return true;
              }
              event.preventDefault();
            }}
          >
            <div className="ncss-container">
              <div className="ncss-row page-header pb0-sm pb2-md">
                <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                  <h3 className="pt3-sm main-heading  c-f-500">
                    {defaultStatus === 'MaxAvailable'
                      ? 'Strategic '
                      : 'Revised '}
                    Capacity View
                  </h3>
                </div>

                <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12  pl0-sm pr0-sm pt2-sm">
                      {growthLeverData &&
                        growthLeverData.data &&
                        growthLeverData.data.length !== 0 && (
                          <div className="fl-sm-r ">
                            <button
                              onClick={() => this.onSaveClick()}
                              disabled={
                                quarterRowDataChanged.length === 0 ||
                                userType !== 'FACTORY'
                              }
                              className="ncss-btn-black  dashboard-button"
                            >
                              <span className="d-sm-h d-md-ib"> Save </span>
                            </button>
                            <button
                              className="ncss-btn-black  dashboard-button"
                              onClick={() => this.toggleUploadModel()}
                              disabled={userType !== 'FACTORY'}
                            >
                              <span className="d-sm-h d-md-ib">Upload</span>
                            </button>
                            <button
                              className="ncss-btn-black  dashboard-button"
                              onClick={() => this.downloadData()}
                            >
                              <span className="d-sm-h d-md-ib">Download</span>
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {!userProfileError &&
                userProfileData &&
                userProfileData.factoryGroups &&
                userProfileData.factoryGroups.length > 0 &&
                searchFirst && (
                  <div className={` `}>
                    <div className="ncss-row page-menu-header">
                      <TabHeader
                        defaultStatus={defaultStatus}
                        userNotifyData={userNotifyData}
                        active="dimension"
                        location={location}
                        featureConfig={featureConfig}
                      />
                    </div>
                  </div>
                )}

              {/* Show Search bar on conditions */}

              <div className={` ncss-row `}>
                <div className="ncss-col-sm-12">
                  <div className="capvis-page-container c-f-all u-clearfix">
                    {/* Show Search bar on conditions */}
                    {userProfileError ||
                      (userProfileData && !userProfileData.factoryGroups && (
                        <NotFound status="401" />
                      ))}
                    {/* Show Page Loading Error on failure of API */}

                    {!growthLeverError &&
                      (!growthLeverData ||
                        !growthLeverData.data ||
                        growthLeverData.data.length === 0) && (
                        <div className="pt2-sm ncss-row text-normal">
                          <div
                            className="flex-parent d-sm-flx-new  flx-jc-sm-c"
                            style={{ minHeight: gridHeight }}
                          >
                            <div className="flex-item p4-sm ta-sm-c flx-as-sm-c fs-18px text-color-grey1">
                              {noSearchInitiated && (
                                <div className="d-sm-flx-new flx-as-sm-c ">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.92 12.8L20 17.9L17.9 20L12.82 14.92C11.4067 15.88 9.86001 16.36 8.18 16.36C5.92666 16.36 4.00001 15.56 2.4 13.96C0.799992 12.36 0 10.4333 0 8.18C0 5.92666 0.799992 4.00001 2.4 2.4C4.00001 0.799992 5.92666 0 8.18 0C10.4333 0 12.36 0.799992 13.96 2.4C15.56 4.00001 16.36 5.92666 16.36 8.18C16.36 9.87334 15.88 11.4133 14.92 12.8ZM13.62 8.18C13.62 6.67333 13.0933 5.38667 12.04 4.32C10.9867 3.25333 9.70001 2.72 8.18 2.72C6.65999 2.72 5.37334 3.25333 4.32 4.32C3.26666 5.38667 2.73333 6.67333 2.72 8.18C2.70667 9.68667 3.23999 10.9667 4.32 12.02C5.40001 13.0733 6.68666 13.6067 8.18 13.62C9.67334 13.6333 10.96 13.1 12.04 12.02C13.12 10.94 13.6467 9.66001 13.62 8.18Z"
                                      fill="#757575"
                                    />
                                  </svg>
                                  &nbsp; Search for a Factory Code to begin
                                </div>
                              )}
                              {!noSearchInitiated && !growthLeverDataLoading && (
                                <div className="flx-as-sm-c">
                                  <strong> No data Available </strong>
                                  <br />
                                  The current account might not be mapped with
                                  any factories or roles
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}
                    {(growthLeverDataLoading || usrFactGrpSearchLoading) && (
                      <div>
                        <Spinner large />
                      </div>
                    )}

                    {/* Show Page Loading Error on failure of API */}
                    {!noSearchInitiated &&
                      growthLeverError &&
                      !growthLeverDataLoading && (
                        <PageError response={growthLeverError} />
                      )}

                    {/* Show Counts and Grid */}
                    <div className="vertical-align-baseline bg-white c-f-border-5">
                      <div className="container p0-top-sm m0-sm">
                        {growthLeverData && !growthLeverDataLoading && (
                          <div className={`u-clearfix `}>
                            <div>
                              {leverQuarterData &&
                              leverQuarterData.length > 0 ? (
                                <div>
                                  <div className="ncss-row    p0-sm   ml0-sm mr0-sm">
                                    {''}
                                  </div>
                                  <div
                                    className={`r-${leverQuarterData.length}  `}
                                  >
                                    {growthLeverData &&
                                      leverQuarterData.length > 0 && (
                                        <div
                                          id="myQuarterGrid"
                                          ref={this.GridParentRel}
                                          className="ag-theme-balham leverSummaryGrid "
                                          style={{ width: gridWidth }}
                                        >
                                          <Grid
                                            columnDefs={gridColumns}
                                            defaultColDef={{
                                              resizable: true,
                                              enableBrowserTooltips: false,
                                              ...defaultColDef
                                            }}
                                            getContextMenuItems={
                                              this.getContextMenuItems
                                            }
                                            rowData={leverQuarterData}
                                            setParams={this.setParams}
                                            floatingFilter
                                            overlayLoadingTemplate
                                            showFilter
                                            frameworkComponents={
                                              frameworkComponents
                                            }
                                            excelStyles={excelStyles}
                                            context={context}
                                            statusCode={statusCode}
                                            defaultStatus={defaultStatus}
                                            getRowHeight={getRowHeight}
                                            headerHeight={55}
                                            setheight={gridHeight}
                                            onCellValueChanged={
                                              this.onQuarterCellValueChanged
                                            }
                                            enableCellChangeFlash={true}
                                            enableRangeSelection={true}
                                          />
                                          {/* Pagination       */}
                                          {leverQuarterData && (
                                            <GBDPagination
                                              totalPage={totalPage}
                                              currentPage={currentPage}
                                              goToPage={this.goToPage}
                                            />
                                          )}
                                        </div>
                                      )}
                                  </div>
                                  <div className="u-clearfix" />
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        )}

                        {usrFactGrpSearchData &&
                          usrFactGrpSearchData.length === 0 &&
                          pageSearch === 1 && (
                            <div className="bg-white border-light-grey p3-sm ta-sm-l">
                              <H1>
                                No Factory Codes under selected Factory Group is
                                Associated for You !
                              </H1>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <UploadModal
                    UploadModel={UploadModel}
                    UploadingData={UploadingData}
                    fileHandler={this.fileHandler}
                    toggleUploadModel={this.toggleUploadModel}
                    recentDownloadedGridFile={recentDownloadedGridFile}
                  />
                  <UploadUpdateModal
                    UploadUpdateModel={UploadUpdateModel}
                    UploadStatusData={UploadStatusData}
                    validatedUploadPQResult={validatedUploadPQResult}
                    toogleUploadUpdateModel={this.toogleUploadUpdateModel}
                    UploadUpdateModelType={UploadUpdateModelType}
                    updateCycleComplete={updateCycleComplete}
                    updateCycleReport={updateCycleReport}
                    isQuarterlyUpdate={true}
                  />
                </div>
              </div>
            </div>
          </Beforeunload>
        )}
      </div>
    );
  }
}

GrowthLever.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  growthLeverDataLoading: PropTypes.bool,
  growthLeverData: PropTypes.object,
  growthLeverError: PropTypes.any,
  growthLeverGridLoading: PropTypes.bool,
  growthLeverGridError: PropTypes.any,
  requestGrowthLeverData: PropTypes.func,
  clearGrowthLeverData: PropTypes.func,
  updateGrowthLeverData: PropTypes.func,
  clearUpdateGrowthLeverData: PropTypes.func,
  updateGrowthLeverComment: PropTypes.func,
  savePreferenceData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  getUsrFactGrpSearchData: PropTypes.object,
  growthLeverDataUpdate: PropTypes.any,
  appConfigData: PropTypes.object,
  appConfigLoading: PropTypes.any,
  appConfigError: PropTypes.object
};

const mapStateToProps = state => ({
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  appConfigData: getAppConfigData(state),
  appConfigLoading: getAppConfigLoading(state),
  appConfigError: getAppConfigError(state),
  userNotifyData: getUserNotifyData(state),
  growthLeverDataLoading: getGrowthLeverDataLoading(state),
  growthLeverData: getGrowthLeverData(state),
  growthLeverError: getGrowthLeverError(state),
  growthLeverDataUpdate: getGrowthLeverDataUpdate(state),
  usrFactGrpSearchData: getUsrFactGrpSearchData(state),
  usrFactGrpSearchLoading: getUsrFactGrpSearchLoading(state),
  usrFactGrpSearchError: getUsrFactGrpSearchError(state)
});

const mapDispatchToProps = dispatch => ({
  requestGrowthLeverData: status => {
    dispatch(createServiceAction('growthLeverData', 'requested')(status));
  },
  clearGrowthLeverData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('growthLeverData', 'clear')({}));
    dispatch(createServiceAction('growthLeverError', 'clear')({}));
  },

  updateGrowthLeverData: data => {
    dispatch(
      createServiceAction('growthLeverDataUpdate', 'submited')({ ...data })
    );
  },
  clearUpdateGrowthLeverData: data => {
    dispatch(createServiceAction('growthLeverDataUpdate', 'clear')({}));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GrowthLever)
);
