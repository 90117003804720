import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-day-picker/lib/style.css';
import Select from 'react-select';
import { SingleInput } from '../index.js';
import moment from 'moment';
import _, { subtract } from 'lodash';
import qs from 'qs';

import { msgConstants } from '../../constants/msgConstants.js';
import { confirmAlert } from 'react-confirm-alert';
import searchConstants from './Search.constants';

import {
  getFactoryGroup,
  getFactoryCodes,
  getDatesRange,
  getDate
} from '../../services/searchService.js';
import {
  getusrFactGrpSearchData,
  getusrFactGrpSearchLoading,
  getusrFactGrpSearchError,
  getDashboardDataUpdate
} from '../../reducers/dashboardReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import datePickerMonthsYears from './datePickerMonthsYears.js';

const currentYear = new Date().getFullYear();
let fromMonth = new Date(currentYear, 0);
let toMonth = new Date(currentYear + 10, 11);

export class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startWeekDate: fromMonth,
      endWeekDate: toMonth,
      searchGroups: searchConstants.groups,
      userFactoryType: '',
      linkQuery: '',
      capacityTypeCodes: []
    };
    this.updateSearchFilterOptions = this.updateSearchFilterOptions.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.getDisabledVal = this.getDisabledVal.bind(this);
    this.onSearchEnterFn = this.onSearchEnterFn.bind(this);
    this.filterCapacityCodes = this.filterCapacityCodes.bind(this);
  }

  componentDidMount() {
    const {
      revisedHorizonWeeks,
      userProfileData,
      location,
      getusrFactGrpSearchData
    } = this.props;

    const { pathname, query } = location;
    this.updateSearchFilterOptions(
      revisedHorizonWeeks,
      userProfileData,
      pathname,
      query
    );

    if (userProfileData && userProfileData.factoryCodesArr) {
      const allString = `factoryCodes=${userProfileData.factoryCodesArr.toString()}`;
      getusrFactGrpSearchData(allString);
    }
  }
  // eslint-disable-next-line max-statements
  componentWillReceiveProps(nextProps) {
    const {
      revisedHorizonWeeks,
      usrFactGrpSearchData,
      userProfileData,
      location
    } = this.props;

    const { pathname } = location;
    const newPathName = nextProps.location.pathname;
    const oldPathName = pathname;
    const { searchGroups } = this.state;
    if (
      location.search !== nextProps.location.search &&
      nextProps.location.search === ''
    ) {
      searchGroups.forEach(i => {
        if (i.type === 'select') {
          if (i.field === 'capacityTypeCode') {
            i.selected = {
              name: 'ALL',
              value: '',
              key: 'ALL',
              label: 'ALL'
            };
          } else {
            if (i.options && i.options.length > 0) {
              i.selected = i.options[0];
            }
          }
          i.value = '';
        } else if (i.type === 'date' || i.type === 'datequarter') {
          i.value = '';
        }
      });
    }

    if (
      userProfileData !== nextProps.userProfileData ||
      revisedHorizonWeeks !== nextProps.revisedHorizonWeeks ||
      newPathName !== oldPathName
    ) {
      const userData = nextProps.userProfileData || userProfileData;
      const weeks = nextProps.revisedHorizonWeeks || revisedHorizonWeeks;
      const newPath = newPathName || oldPathName;
      const newQuery = nextProps.location.query;
      this.updateSearchFilterOptions(weeks, userData, newPath, newQuery);
    }

    if (usrFactGrpSearchData !== nextProps.usrFactGrpSearchData) {
      if (
        nextProps.usrFactGrpSearchData &&
        nextProps.usrFactGrpSearchData.length >= 0
      ) {
        const searchQuery = location.query;
        let capacityTypeCodes = nextProps.usrFactGrpSearchData;
        const cI = searchGroups.findIndex(o => o.field === 'capacityTypeCode');
        const newList =
          nextProps.usrFactGrpSearchData &&
          nextProps.usrFactGrpSearchData.length > 0
            ? nextProps.usrFactGrpSearchData
            : [];

        const newFCAllFirst = {
          name: 'ALL',
          value: '',
          key: 'ALL',
          label: 'ALL',
          id: 10001
        };
        const newCTCAllFirst = {
          name: 'ALL',
          value: '',
          key: 'ALL',
          label: 'ALL',
          id: 10001
        };
        if (searchQuery) {
          if (searchQuery.factoryCode) {
            const filteredList = newList.filter(
              x => x.factoryCode === searchQuery.factoryCode
            );
            newFCAllFirst.key = `${searchQuery.factoryCode}`;
            newFCAllFirst.name = `All ${searchQuery.factoryCode}`;
            newFCAllFirst.label = `All ${searchQuery.factoryCode}`;
            let retUnQ = _.uniqBy(filteredList, 'key');
            let retUnQ1 = _.sortBy(retUnQ, 'name');
            searchGroups[cI].options = [newFCAllFirst, ...retUnQ1];
          } else if (searchQuery.factoryGroup) {
            const fCodeIndx = searchGroups.findIndex(
              o => o.field === 'factoryCode'
            );
            const fCodeOptions = searchGroups[fCodeIndx].backOptions.filter(
              x => x.group === searchQuery.factoryGroup
            );
            newFCAllFirst.key = `${searchQuery.factoryGroup}`;
            newFCAllFirst.name = `All ${searchQuery.factoryGroup}`;
            newFCAllFirst.label = `All ${searchQuery.factoryGroup}`;
            searchGroups[fCodeIndx].options = [newFCAllFirst, ...fCodeOptions];
            searchGroups[fCodeIndx].selected =
              searchGroups[fCodeIndx].options[0];
            let retUnQAll = this.filterCapacityCodes('group', fCodeOptions);
            let retUnQ = _.uniqBy(retUnQAll, 'key');
            let retUnQ1 = _.sortBy(retUnQ, 'name');
            searchGroups[cI].options = [newCTCAllFirst, ...retUnQ1];
          } else {
            let retUnQ = _.uniqBy(newList, 'key');
            let retUnQ1 = _.sortBy(retUnQ, 'name');
            searchGroups[cI].options = [newCTCAllFirst, ...retUnQ1];
          }
        } else {
          let retUnQ = _.uniqBy(newList, 'key');
          let retUnQ1 = _.sortBy(retUnQ, 'name');
          searchGroups[cI].options = [newCTCAllFirst, ...retUnQ1];
        }

        searchGroups[cI].disabled =
          newList.length > 0 &&
          !newPathName.includes('user-updates') &&
          !newPathName.includes('lever-quarter')
            ? false
            : true;

        ///
        let itemCTCSelected = searchGroups[cI].selected
          ? searchGroups[cI].selected
          : {};
        let urlCTCSelected =
          searchQuery &&
          searchQuery.capacityTypeCode &&
          searchGroups[cI].options
            ? searchGroups[cI].options.find(
                x => x.name === searchQuery.capacityTypeCode
              )
            : {};
        if (urlCTCSelected && Object.keys(urlCTCSelected).length > 0) {
          searchGroups[cI].selected = urlCTCSelected;
        } else if (itemCTCSelected && Object.keys(itemCTCSelected).length > 0) {
          searchGroups[cI].selected = itemCTCSelected;
        } else if (
          searchGroups &&
          searchGroups[cI] &&
          searchGroups[cI].options &&
          searchGroups[cI].options.length > 0
        ) {
          searchGroups[cI].selected = searchGroups[cI].options[0] || {};
        }
        this.setState({ searchGroups, capacityTypeCodes }, () => {
          setTimeout(() => {
            // this.onSearchEnterFn();
          }, 100);
        });
      }
    }
  }

  // eslint-disable-next-line max-statements
  updateSearchFilterOptions(
    revisedHorizonWeeks,
    userProfileData,
    newPath,
    newQuery
  ) {
    const { searchGroups } = this.state;
    const { rvsdProdQtrList, stratProdQtrList } = this.props;
    const range = getDatesRange(
      newPath,
      revisedHorizonWeeks,
      rvsdProdQtrList,
      stratProdQtrList
    );

    const { fromMonth, toMonth } = range;
    if (userProfileData) {
      userProfileData.factoryGroups = _.sortBy(
        userProfileData.factoryGroups,
        'name'
      );
      const newRoute = newPath.substring(0, newPath.lastIndexOf('/') + 1);
      searchGroups.forEach(item => {
        let selected =
          newQuery && newQuery[item.field] ? newQuery[item.field] : null;
        let selectedFG =
          newQuery && newQuery.factoryGroup ? newQuery.factoryGroup : null;
        switch (item.field) {
          case 'factoryGroup':
            item = getFactoryGroup(item, newRoute, userProfileData, selected);

            break;
          case 'factoryCode':
            item = getFactoryCodes(
              item,
              newRoute,
              userProfileData,
              selected,
              selectedFG
            );
            break;
          case 'capacityTypeCode':
            item.disabled =
              newPath.includes('user-updates') ||
              newPath.includes('lever-quarter') ||
              !item.options ||
              item.options.length === 0;
            break;
          case 'startWeekDate':
          case 'endWeekDate':
            item = getDate(item, newRoute, range, selected);
            break;
          default:
            item.show = true;
        }
      });
      this.setState({
        searchGroups,
        userFactoryType: userProfileData.factoryType,
        startWeekDate: fromMonth,
        endWeekDate: toMonth
      });
    }
  }

  onSearchFieldChange = event => {
    const { searchGroups } = this.state;

    const thisIndex = searchGroups.findIndex(
      item => item.field === event.target.name
    );
    if (thisIndex >= 0) {
      searchGroups[thisIndex].value = event.target.value || '';
      this.setState({ searchGroups }, () => {
        setTimeout(() => {
          this.onSearchEnterFn();
        }, 100);
      });
    }
  };

  onSearchDateChange(date, field) {
    const { searchGroups } = this.state;
    const thisIndex = searchGroups.findIndex(item => item.field === field);
    if (thisIndex > 0) {
      searchGroups[thisIndex].value = date || '';
    }
    if (field === 'startWeekDate') {
      const i = searchGroups.findIndex(item => item.field === 'endWeekDate');
      searchGroups[i].fromMonth = new Date(moment(date));
    } else {
      const i = searchGroups.findIndex(item => item.field === 'startWeekDate');
      searchGroups[i].toMonth = new Date(moment(date));
    }
    this.setState({ searchGroups }, () => {
      setTimeout(() => {
        this.onSearchEnterFn();
      }, 100);
    });
  }

  onSearchQuarterChange(date, field) {
    const { searchGroups } = this.state;
    const thisIndex = searchGroups.findIndex(item => item.field === field);
    if (thisIndex > 0) {
      searchGroups[thisIndex].value = date || '';
    }
    if (field === 'startWeekDate') {
      const i = searchGroups.findIndex(item => item.field === 'startWeekDate');
      let monday = moment(date)
        .startOf('month')
        .day('Monday');
      if (monday.date() > 7) monday.add(7, 'd');
      searchGroups[i].value = date ? new Date(monday) : '';
    } else {
      const i = searchGroups.findIndex(item => item.field === 'endWeekDate');
      const d = moment(date)
        .endOf('quarter')
        .day('Monday');
      if (d.date() < 7) d.subtract(7, 'd');
      searchGroups[i].value = date ? new Date(d) : '';
    }
    this.setState({ searchGroups }, () => {
      setTimeout(() => {
        this.onSearchEnterFn();
      }, 100);
    });
  }

  getInstanceValue(object, instance) {
    if (
      instance &&
      instance.getInstance().getInput() &&
      instance.getInstance().getInput().value
    ) {
      return (
        instance
          .getInstance()
          .getInput()
          .value.toUpperCase() || ''
      );
    }
    return '';
  }

  onSearchFieldClear(n) {
    const { searchGroups } = this.state;
    searchGroups.forEach(gridItem => {
      gridItem.value = '';
      gridItem.disabled = false;
      gridItem.selected = [];
      let thisrel = this.refs[`typeAhead${gridItem.field}`];
      if (thisrel) {
        thisrel.getInstance().clear();
      }
      if (gridItem.field === 'capacityTypeCode') {
        gridItem.options = [];
        gridItem.disabled = true;
        gridItem.value = '';
      }
    });
  }

  sortFactoryCodes = (factoryCodes, selectedOption) => {
    if (factoryCodes && factoryCodes.length > 0) {
      let sortedArr = factoryCodes.sort((a, b) => (a.name > b.name ? 1 : -1));
      const newFCAllFirst = [
        {
          name: `All ${selectedOption}`,
          key: selectedOption,
          label: `All ${selectedOption}`,
          id: `All${selectedOption}`,
          group: selectedOption,
          value: ''
        }
      ];
      let allFactoryCodes = [...newFCAllFirst, ...sortedArr];
      return allFactoryCodes;
    }
    return [];
  };
  filterCapacityCodes = (filterBy, sortedFCs) => {
    const { capacityTypeCodes } = this.state;
    let filteredCTC = [];
    if (filterBy === 'group') {
      if (sortedFCs && sortedFCs.length > 0) {
        sortedFCs.forEach(fc => {
          let filteredCC = capacityTypeCodes.filter(
            x => x.factoryCode === fc.value
          );
          if (filteredCC && filteredCC.length > 0) {
            filteredCC.forEach(cci => {
              filteredCTC.push(cci);
            });
          }
        });
      }
    }
    return filteredCTC;
  };

  // eslint-disable-next-line max-statements
  onSelectFieldChange = (field, selectedOption) => {
    const { location } = this.props;
    const { searchGroups, capacityTypeCodes } = this.state;
    const selIndx = searchGroups.findIndex(item => item.field === field);
    const fGrpIndx = searchGroups.findIndex(o => o.field === 'factoryGroup');
    const fCodeIndx = searchGroups.findIndex(o => o.field === 'factoryCode');
    const capCIndx = searchGroups.findIndex(
      o => o.field === 'capacityTypeCode'
    );
    if (selIndx >= 0) {
      searchGroups[selIndx].value = selectedOption || '';
      searchGroups[selIndx].selected = selectedOption || '';
      if (field === 'factoryGroup') {
        // on group selection reset fcode and Capcode

        if (selectedOption.value) {
          const factGrpOpts = searchGroups[selIndx].options;
          let newFaCodes =
            factGrpOpts.find(o => o.value === selectedOption.value)
              .factoryCodes || [];
          const sortedFCs = this.sortFactoryCodes(
            newFaCodes,
            selectedOption.value
          );

          searchGroups[fCodeIndx].options = sortedFCs;
          searchGroups[fCodeIndx].value = '';

          searchGroups[capCIndx].value = '';
          searchGroups[fCodeIndx].selected = {};
          searchGroups[capCIndx].selected = {};
        } else {
          if (location.pathname.includes('levers-summary')) {
            //this if never applies
            searchGroups[fCodeIndx].options =
              searchGroups[fCodeIndx].backOptions;
          } else {
            const newFCAllFirst = [
              {
                name: 'All Factory Codes',
                value: '',
                key: 'AllFactoryCodes',
                label: 'All Factory Codes'
              }
            ];
            searchGroups[fCodeIndx].options = [
              ...newFCAllFirst,
              ...searchGroups[fCodeIndx].backOptions
            ];
          }
        }
        searchGroups[fCodeIndx].value = '';
        if (searchGroups[fCodeIndx].options) {
          searchGroups[fCodeIndx].selected = searchGroups[fCodeIndx].options[0];
        } else {
          searchGroups[fCodeIndx].selected = {};
        }
      } else if (searchGroups[selIndx].field === 'factoryCode') {
        const getFGroup = searchGroups[fGrpIndx].options.findIndex(
          item => item.key === selectedOption.group
        );
        //update fG selection
        searchGroups[fGrpIndx].selected =
          getFGroup >= 0
            ? searchGroups[fGrpIndx].options[getFGroup]
            : searchGroups[fGrpIndx].options[0];
        searchGroups[fGrpIndx].value = '';
      } else if (searchGroups[selIndx].field === 'capacityTypeCode') {
        if (selectedOption && selectedOption.value) {
          searchGroups[selIndx].value = '';
          searchGroups[selIndx].selected = selectedOption;
        }
      }
      const newFCAllFirst = {
        name: 'ALL',
        value: '',
        key: 'ALL',
        label: 'ALL',
        id: 10001
      };

      if (field === 'factoryGroup' || field === 'factoryCode') {
        if (field === 'factoryCode' && selectedOption.value) {
          const filteredList = capacityTypeCodes.filter(
            x => x.factoryCode === selectedOption.value
          );
          newFCAllFirst.key = `${selectedOption.value}`;
          newFCAllFirst.name = `All ${selectedOption.value}`;
          newFCAllFirst.label = `All ${selectedOption.value}`;
          let retUnQ = _.uniqBy(filteredList, 'key');
          let retUnQ1 = _.sortBy(retUnQ, 'name');
          searchGroups[capCIndx].options = [newFCAllFirst, ...retUnQ1];
        } else {
          let retUnQAll = this.filterCapacityCodes(
            'group',
            searchGroups[fCodeIndx].options
          );
          let retUnQ = _.uniqBy(retUnQAll, 'key');
          let retUnQ1 = _.sortBy(retUnQ, 'name');
          searchGroups[capCIndx].options = [newFCAllFirst, ...retUnQ1];
        }
        searchGroups[capCIndx].selected =
          searchGroups[capCIndx].options &&
          searchGroups[capCIndx].options.length > 0
            ? searchGroups[capCIndx].options[0]
            : {};
      }
      this.setState({ searchGroups }, () => {
        setTimeout(() => {
          this.onSearchEnterFn();
        }, 100);
      });
    }
  };

  onSearchEnterFn = () => {
    const { location, history } = this.props;
    const { searchGroups, linkQuery } = this.state;
    let linkObj = {};
    searchGroups.forEach(gridItem => {
      if (
        ['factoryGroup', 'factoryCode', 'capacityTypeCode'].includes(
          gridItem.field
        ) &&
        gridItem.selected &&
        gridItem.selected.value
      ) {
        linkObj[gridItem.field] = gridItem.selected.value;
      } else if (
        ['startWeekDate', 'endWeekDate'].includes(gridItem.field) &&
        gridItem.value
      ) {
        linkObj[gridItem.field] = moment(new Date(gridItem.value)).format(
          'MM-DD-YYYY'
        );
      }
    });
    const newLinkQuery = qs.stringify(linkObj);
    if (linkQuery !== newLinkQuery || newLinkQuery === '') {
      let linkValue = location.pathname + '?' + qs.stringify(linkObj);

      if (
        localStorage.getItem('isRowDataChanged') === 'false' &&
        localStorage.getItem('isQuarterRowDataChanged') === 'false' &&
        localStorage.getItem('isGBDQuarterRowDataChanged') === 'false'
      ) {
        this.setState({ linkQuery: newLinkQuery }, () => {
          setTimeout(() => {
            history.push(linkValue);
          }, 100);
        });
      } else {
        confirmAlert({
          message: msgConstants.pageChangeAlert,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                localStorage.setItem('isRowDataChanged', 'false');
                localStorage.setItem('isQuarterRowDataChanged', 'false');
                localStorage.setItem('isGBDQuarterRowDataChanged', 'false');

                this.setState({ linkQuery: newLinkQuery }, () => {
                  setTimeout(() => {
                    history.push(linkValue);
                  }, 100);
                });
              }
            },
            {
              label: 'No'
            }
          ]
        });
      }
    }
  };

  handleYearMonthChange(month, field) {
    this.setState({ [field]: month });
  }

  getDisabledVal(item) {
    const { location } = this.props;
    let retval = '';
    const dateFormat = location.pathname.includes('lever-quarter')
      ? 'YYYY,[Q]Q'
      : 'MM/DD/YYYY';
    let capacityTypeDisabled = item && item.selected ? item.selected.label : '';
    if (location.pathname.includes('user-updates')) {
      capacityTypeDisabled =
        item.options && item.options.length > 0 ? item.options[0].label : 'ALL';
    }
    switch (item.field) {
      case 'factoryGroup':
      case 'factoryCode':
        retval = item && item.selected ? item.selected.label : '';
        break;
      case 'capacityTypeCode':
        retval = capacityTypeDisabled;
        break;
      case 'startWeekDate':
      case 'endWeekDate':
        retval = item.value ? moment(item.value).format(dateFormat) : '';
        break;
      default:
        item.show = true;
    }
    return retval;
  }

  getSection = (fieldItem, searchType) => {
    const { pageType, userNotify } = this.props;

    const multipleSelectTypeAhead = pageType === 'upload' ? true : false;

    switch (fieldItem.type) {
      case 'date':
        if (
          userNotify ||
          fieldItem.type === 'input' ||
          fieldItem.disabled ||
          (fieldItem.options && fieldItem.options.length === 0)
        ) {
          return (
            <div className="position-relative">
              <SingleInput
                inputType={fieldItem.format || 'text'}
                name={fieldItem.field}
                key={`${fieldItem.id}-${fieldItem.field}`}
                content={this.getDisabledVal(fieldItem)}
                placeholder={fieldItem.name}
                className={`input-text ${fieldItem.cssClass}`}
                required={fieldItem.required || false}
                disabled={true}
                style={{
                  height: 29,
                  paddingTop: 0
                }}
              />
            </div>
          );
        } else {
          // This will be slightly different for now depending on whether it's Upload or Search View. When Upload View changes to
          //  single row selectors, all of the DayPicker code can be the same
          return (
            <div name="dayPickerParent">
              <div className={`DayPicker-Border ${fieldItem.cssClass}`}>
                <DatePicker
                  selected={fieldItem.value}
                  dateFormat="MM/dd/yyyy"
                  onChange={date => {
                    this.onSearchDateChange(date, fieldItem.field);
                  }}
                  disabled={fieldItem.disabled}
                  onChangeRaw={e => e.preventDefault()} // to make it uneditable
                  isClearable // to clear the date
                  placeholderText={fieldItem.name}
                  renderCustomHeader={datePickerMonthsYears} // months and year dropDown
                  minDate={subtract(new Date(fieldItem.fromMonth), 0)}
                  maxDate={subtract(new Date(fieldItem.toMonth), 0)}
                  filterDate={date => new Date(date).getDay() === 1} // only Monday is selectable
                />
              </div>
            </div>
          );
        }

      case 'datequarter':
        return (
          <div className="position-relative">
            {fieldItem.cssClass === 'float' && fieldItem.value.length > 0 ? (
              <span className="floatHead">{fieldItem.name}</span>
            ) : null}

            <DatePicker
              placeholderText={fieldItem.name}
              selected={fieldItem.value || ''}
              onChange={date =>
                this.onSearchQuarterChange(date, fieldItem.field)
              }
              dateFormat="yyyy, QQQ"
              showQuarterYearPicker
              minDate={fieldItem.fromQuarter}
              maxDate={fieldItem.toQuarter}
            />
          </div>
        );
      case 'select':
        if (
          userNotify ||
          fieldItem.type === 'input' ||
          fieldItem.disabled ||
          (fieldItem.options && fieldItem.options.length === 0)
        ) {
          return (
            <div className="position-relative">
              {fieldItem.cssClass === 'float' &&
              fieldItem.selected &&
              Object.keys(fieldItem.selected).length !== 0 ? (
                <span className="floatHead-Disable">
                  {fieldItem.name}
                  {fieldItem.required && (
                    <span className="text-color-error"> *</span>
                  )}
                </span>
              ) : null}
              <SingleInput
                inputType={fieldItem.format || 'text'}
                name={fieldItem.field}
                key={`${fieldItem.id}-${fieldItem.field}`}
                content={this.getDisabledVal(fieldItem)}
                placeholder={fieldItem.name}
                className={`input-text ${fieldItem.cssClass}`}
                required={fieldItem.required || false}
                disabled={true}
                style={{
                  height: 29,
                  color: '#545454'
                }}
              />
            </div>
          );
        } else {
          return (
            <div className="position-relative">
              {fieldItem.cssClass === 'float' &&
              fieldItem.selected &&
              Object.keys(fieldItem.selected).length !== 0 ? (
                <span className="floatHead">
                  {fieldItem.name}
                  {fieldItem.required && (
                    <span className="text-color-error"> *</span>
                  )}
                </span>
              ) : null}
              <Select
                value={fieldItem.selected}
                id={fieldItem.name}
                ref={`optionsSelect${fieldItem.field}`}
                options={fieldItem.options}
                placeholder={fieldItem.name}
                disabled={true}
                isMulti={multipleSelectTypeAhead}
                className={`select-drop-down  ${fieldItem.cssClass} ${
                  fieldItem.cssClass &&
                  fieldItem.selected &&
                  Object.keys(fieldItem.selected).length !== 0
                    ? 'selected'
                    : ''
                }`}
                onChange={a => this.onSelectFieldChange(fieldItem.field, a)}
                classNamePrefix="react-select"
              />
            </div>
          );
        }

      default:
    }
  };
  removeAllFromOptions = arr => {
    const arrNew = [...arr];
    arrNew.forEach(item => {
      if (item.options) {
        const foundIndex = item.options.findIndex(i => i.name === 'ALL');
        if (foundIndex >= 0) {
          item.options.splice(foundIndex, 1);
        }
      }
      if (item.backOptions) {
        const foundIndex = item.backOptions.findIndex(i => i.name === 'ALL');
        if (foundIndex >= 0) {
          item.options.splice(foundIndex, 1);
        }
      }
    });
    return arrNew;
  };

  render() {
    const { searchGroups } = this.state;

    return (
      <div className="u-clearfix search-new-container topHeader">
        <form
          onSubmit={e => {
            e.preventDefault();
            this.onSearchEnterFn();
          }}
        >
          <div className="pt0-sm">
            <div className="newSearchCol emptyNotch" />
            {searchGroups &&
              searchGroups.map((fieldItem, index) => (
                <div
                  className={`searchNew newSearchCol ${fieldItem.field}  ${
                    fieldItem.show ? '' : ' d-sm-h d-md-h d-lg-h'
                  } `}
                  key={fieldItem.field}
                >
                  {fieldItem.show && this.getSection(fieldItem, 1)}
                </div>
              ))}
          </div>
        </form>
      </div>
    );
  }
}
Search.propTypes = {
  onSearchEnter: PropTypes.func,
  searchGroups: PropTypes.array,
  userProfileData: PropTypes.any,
  pageType: PropTypes.any,
  usrFactGrpSearchData: PropTypes.any,
  userNotify: PropTypes.any,
  notificationCount: PropTypes.any,
  location: PropTypes.any,
  revisedHorizonWeeks: PropTypes.any,
  getUsrFactGrpSearchData: PropTypes.object,
  rvsdProdQtrList: PropTypes.any,
  stratProdQtrList: PropTypes.any
};

const mapStateToProps = state => ({
  usrFactGrpSearchData: getusrFactGrpSearchData(state),
  usrFactGrpSearchLoading: getusrFactGrpSearchLoading(state),
  usrFactGrpSearchError: getusrFactGrpSearchError(state),
  dashboardDataUpdate: getDashboardDataUpdate(state)
});

const mapDispatchToProps = dispatch => ({
  getusrFactGrpSearchData: query => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'requested')(query));
  },
  clearUsrFactGrpSearchData: () => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'clear')(null));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
