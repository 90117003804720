import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'error')(''));
    let options = {};
    const getDashDataUrl = `${apiConfig.getUserNotify.endPointUrl}`;
    const res = yield call(getRequest, getDashDataUrl, options, true);
    if (Number(res.status) === 200) {
      const response = yield res.json();
      yield put(createServiceAction(serviceType, 'success')(response));
    } else {
      const responsemessage = yield res.text() || res.json();
      const errorResponse = {
        status: Number(res.status),
        message: responsemessage
      };
      yield put(createServiceAction(serviceType, 'error')(errorResponse));
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getUserNotifySagas() {
  const serviceType = 'userNotifyData';
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default getUserNotifySagas;
