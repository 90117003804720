import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { msgConstants } from '../../constants/msgConstants.js';
import { confirmAlert } from 'react-confirm-alert';

const TabHeader = withRouter(
  ({
    defaultStatus,
    userNotifyData,
    active,
    history,
    location,
    featureConfig
  }) => {
    const alertHandler = alinkPath => event => {
      if (
        localStorage.getItem('isRowDataChanged') === 'false' &&
        localStorage.getItem('isQuarterRowDataChanged') === 'false' &&
        localStorage.getItem('isGBDQuarterRowDataChanged') === 'false'
      ) {
        history.push(alinkPath);
      } else {
        if (location.pathname !== alinkPath) {
          confirmAlert({
            message: msgConstants.pageChangeAlert,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  localStorage.setItem('isRowDataChanged', 'false');
                  localStorage.setItem('isQuarterRowDataChanged', 'false');
                  localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
                  history.push(alinkPath);
                }
              },
              {
                label: 'No'
              }
            ]
          });
        }
      }
    };
    let capacityLink = location && location.search ? location.search : '';
    const tabQuery = location && location.query ? location.query : '';
    const allowed = ['factoryGroup', 'factoryCode', 'capacityTypeCode'];
    const finalQuery = Object.fromEntries(
      Object.entries(tabQuery).filter(([key, val]) => allowed.includes(key))
    );
    capacityLink = finalQuery ? `?${qs.stringify(finalQuery)}` : '';
    return (
      <div className="ncss-col-sm-12 ncss-col-md-4 ta-sm-l headline-4  fl-sm-l">
        <div
          className={`menuOpt1Item ${active === 'dashboard' ? 'active' : ''}`}
        >
          <span
            className="text"
            onClick={alertHandler(
              `/dashboard/${
                defaultStatus === 'MaxAvailable'
                  ? 'strategicCapacity'
                  : 'revisedCapacity'
              }${capacityLink}`
            )}
          >
            Capacity
          </span>
        </div>
        {featureConfig && featureConfig.lever && (
          <div
            className={`menuOpt1Item  pl4-sm ${
              active === 'dimension' ? 'active' : ''
            }`}
          >
            <span
              className="text"
              onClick={alertHandler(
                `/levers/${
                  defaultStatus === 'MaxAvailable' ? 'strategic' : 'revised'
                }Capacity${capacityLink}`
              )}
            >
              Levers
            </span>
          </div>
        )}
        <div
          className={`menuOpt1Item ${active === 'userupdate' ? 'active' : ''}`}
        >
          <span className="notification-dot-space">
            {defaultStatus === 'MaxAvailable' ? (
              userNotifyData &&
              userNotifyData.strategic &&
              userNotifyData.strategic.unread > 0 ? (
                <span className="notification-no-count-side bg-primary mt2-sm ml2-sm"></span>
              ) : null
            ) : userNotifyData &&
              userNotifyData.revised &&
              userNotifyData.revised.unread > 0 ? (
              <span className="notification-no-count-side bg-primary mt2-sm ml2-sm"></span>
            ) : null}
            {defaultStatus === 'MaxAvailable' ? (
              userNotifyData &&
              userNotifyData.strategic &&
              userNotifyData.strategic.pending > 0 ? (
                <span className="notification-no-count-side bg-amber ml-2-sm mr1-sm mt2-sm"></span>
              ) : null
            ) : userNotifyData &&
              userNotifyData.revised &&
              userNotifyData.revised.pending > 0 ? (
              <span className="notification-no-count-side bg-amber ml-2-sm mr1-sm mt2-sm"></span>
            ) : null}
          </span>
          <span
            className="text"
            onClick={alertHandler(
              `/user-updates/${
                defaultStatus === 'MaxAvailable'
                  ? 'strategicCapacity'
                  : 'revisedCapacity'
              }${capacityLink}`
            )}
          >
            Notifications
          </span>
        </div>
        {'   '}
      </div>
    );
  }
);

TabHeader.propTypes = {
  content: PropTypes.string,
  user: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.string,
  date: PropTypes.string
};

export default TabHeader;
