import { fromJS } from 'immutable';
import _ from 'lodash';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  growthLeverQuarterData: {},
  growthLeverQuarterDataLoading: false,
  growthLeverQuarterDataUpdate: {}
});

const modifygrowthLeverQuarterData = resp => {
  if (resp && resp.leverInfo && resp.leverInfo.variances) {
    const sortingArr = [
      {
        varianceCode: 'Gbd',
        headerName: 'Max Capacity Variance'
      },
      {
        varianceCode: 'CapQ',
        headerName: 'Factory Capacity QoQ Variance'
      },
      {
        varianceCode: 'MaxQ',
        headerName: 'Max Capacity QoQ Variance'
      }
    ];

    const newlevers = sortingArr.map(itm => {
      var item = resp.leverInfo.variances.find(
        x => x.varianceCode === itm.varianceCode
      );
      item.headerName = itm.headerName;
      return item;
    });
    resp.leverInfo.variances = newlevers;

    resp.leverInfo.variances.forEach(variance => {
      if (variance && variance.leverlist && variance.leverlist.length > 0) {
        variance.leverlist.forEach((leverItem, i) => {
          leverItem.newItem = false;
          leverItem.devIndex = i;
        });
      }
      if (resp.divisionCode === 20) {
        variance.display = true;
      } else {
        if (variance.varianceCode === 'Gbd') {
          variance.display = true;
        } else {
          variance.display = false; // change later
        }
      }
    });
  }
  return resp;
};

const modifyFactGrpSearchData = resp => {
  let retArr = [];
  let setArray = [];

  if (resp) {
    if (Array.isArray(resp)) {
      if (resp.length > 0) {
        resp.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    } else {
      let cArr = resp.capacities;
      if (cArr.length > 0) {
        cArr.forEach((icc, i) => {
          if (icc.capacityTypeCodes && icc.capacityTypeCodes.length > 0) {
            setArray = [...setArray, ...icc.capacityTypeCodes];
          }
        });
      }
    }
  }
  if (setArray.length > 0) {
    setArray.forEach((icc, i) => {
      let newCode = {
        name: icc.capacityTypeCode,
        value: icc.capacityTypeCode,
        key: icc.capacityTypeCode,
        label: icc.capacityTypeCode, //+ ' (' + icc.factoryCode + ')',
        fc: icc.factoryCode || '',
        id: `${i + 1000}`
      };
      retArr.push(newCode);
      //  retArr = [...retArr, ...newCode];
    });
  }

  const retUnQ = _.uniqBy(retArr, 'key'); //retArr; //
  let retUnAll = [];
  if (retUnQ.length > 1) {
    const firstItem = {
      name: 'ALL',
      value: '',
      key: 'ALL',
      label: 'ALL',
      id: 10001
    };
    if (resp.length === 1) {
      firstItem.key = `All${resp[0].factoryCode}`;
      firstItem.name = `All ${resp[0].factoryCode}`;
      firstItem.label = `All ${resp[0].factoryCode}`;
    }
    retUnAll = [firstItem, ...retUnQ];
  } else {
    retUnAll = retUnQ;
  }
  return retUnAll;
};

export default function growthLeverQuarter(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('growthLeverQuarterData', 'loading').type:
      return state.set('growthLeverQuarterDataLoading', action.data);

    case createServiceAction('growthLeverQuarterData', 'success').type:
      return state.set(
        'growthLeverQuarterData',
        modifygrowthLeverQuarterData(action.data)
      );

    case createServiceAction('growthLeverQuarterData', 'clear').type:
      return state.set('growthLeverQuarterData', action.data);

    case createServiceAction('growthLeverQuarterData', 'error').type:
      return state.set('growthLeverQuarterError', action.data);

    case createServiceAction('growthLeverQuarterDataUpdate', 'success').type:
      return state.set('growthLeverQuarterDataUpdate', action.data);

    case createServiceAction('growthLeverQuarterDataUpdate', 'error').type:
      return state.set('growthLeverQuarterDataUpdate', action.data);

    case createServiceAction('growthLeverQuarterDataUpdate', 'clear').type:
      return state.set('growthLeverQuarterDataUpdate', action.data);

    case createServiceAction('usrFactGrpSearchData', 'loading').type:
      return state.set('usrFactGrpSearchLoading', action.data);

    case createServiceAction('usrFactGrpSearchData', 'success').type:
      return state.set(
        'usrFactGrpSearchData',
        modifyFactGrpSearchData(action.data)
      );

    case createServiceAction('usrFactGrpSearchData', 'clear').type:
      return state.set('usrFactGrpSearchData', action.data);

    case createServiceAction('usrFactGrpSearchData', 'error').type:
      return state.set('usrFactGrpSearchError', action.data);

    default:
      return state;
  }
}
export const getGrowthLeverQuarterDataLoading = state =>
  state.growthLeverQuarter.get('growthLeverQuarterDataLoading');
export const getGrowthLeverQuarterData = state =>
  state.growthLeverQuarter.get('growthLeverQuarterData');
export const getGrowthLeverQuarterError = state =>
  state.growthLeverQuarter.get('growthLeverQuarterError');

export const getusrFactGrpSearchLoading = state =>
  state.growthLeverQuarter.get('usrFactGrpSearchLoading');
export const getusrFactGrpSearchError = state =>
  state.growthLeverQuarter.get('usrFactGrpSearchError');
export const getusrFactGrpSearchData = state =>
  state.growthLeverQuarter.get('usrFactGrpSearchData');

export const updategrowthLeverQuarterData = state =>
  state.growthLeverQuarter.get('growthLeverQuarterDataUpdate');

export const getGrowthLeverQuarterDataUpdate = state =>
  state.growthLeverQuarter.get('growthLeverQuarterDataUpdate');
