import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';
import { numberWithCommas } from '../../util/general.js';

export default class LeversMinimalCustomCell extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod(entryCol) {
    const { context, node } = this.props;
    context &&
      context.componentParent.displayLogEntryDetails(node.rowIndex, entryCol);
  }

  linkGen(factoryCode, capacityTypeCode, productionQuarter) {
    // search form validation

    const { context } = this.props;
    let factoryGroup = '';
    let startWeekDate = '';
    let endWeekDate = '';
    let defaultStatus = 'MaxAvailable';
    if (
      window.location &&
      window.location.href &&
      window.location.href.indexOf('revisedCapacity') !== -1
    ) {
      defaultStatus = 'shortHorizon';
    }
    let leverLink = `/lever-quarter/${
      defaultStatus === 'MaxAvailable' ? 'strategicCapacity' : 'revisedCapacity'
    }?factoryCode=${factoryCode}`;
    if (capacityTypeCode) {
      leverLink += `&capacityTypeCode=${capacityTypeCode}`;
    }
    if (productionQuarter) {
      leverLink += `&productionQuarter=${productionQuarter}`;
    }
    if (
      context &&
      context.componentParent &&
      context.componentParent.props &&
      context.componentParent.props.location &&
      context.componentParent.props.location.query
    ) {
      factoryGroup = context.componentParent.props.location.query.factoryGroup;
      startWeekDate =
        context.componentParent.props.location.query.startWeekDate;
      endWeekDate = context.componentParent.props.location.query.endWeekDate;
      if (context.componentParent.props.location.query.capacityTypeCode) {
        leverLink += `&isCTC=true`;
      }
    }
    if (factoryGroup) {
      leverLink += `&factoryGroup=${factoryGroup}`;
    }
    if (startWeekDate) {
      leverLink += `&startWeekDate=${startWeekDate}`;
    }
    if (endWeekDate) {
      leverLink += `&endWeekDate=${endWeekDate}`;
    }

    return leverLink;
  }

  indicatorClass(val) {
    switch (val) {
      case 'notAtAllaccounted':
        return { css: 'bgBlue', text: 'no variance accounted for' };
      case 'notAllAccounted':
        return { css: 'bgYellow', text: 'not all variance accounted for' };
      case 'allAccounted':
        return { css: 'bgGreen', text: 'all variance accounted for' };
      case 'overAccounted':
        return { css: 'bgRed', text: 'variance over' };
      default:
        // no variance editing (disabled) to capacity value missing
        return { css: '', text: 'capacity value missing' };
    }
  }

  // eslint-disable-next-line complexity
  getSection = () => {
    const { colDef, data, value } = this.props;

    const {
      productionQuarter,
      factoryCode,
      capacityTypeCode,
      FctyMaxCapacity,
      FctyMaxHldCpty,
      maxAvailableCapacity,
      FctyRvsdHldCpty,
      FctyStratHldCpty,
      shortHorizonCapacity,
      totalWeeks,
      divisionCode,
      CapQMaxCapVarPresent,
      CapQMaxCapVarPrevious,
      CapQMaxCapAdjVarPresent,
      CapQMaxCapAdjVarPrevious,

      MaxQMaxCapVarPresent,
      MaxQMaxCapVarPrevious,
      MaxQMaxCapAdjVarPresent,
      MaxQMaxCapAdjVarPrevious,

      partialQuarter,
      partialQuarterPrev,

      gbdVarMatchInd,
      capQVarMatchInd,
      maxQVarMatchInd,
      gbdAdjVarMatchInd,
      capQAdjVarMatchInd,
      maxQAdjVarMatchInd
    } = data;

    let prodQuart;
    if (productionQuarter) {
      const pqStr = String(productionQuarter);
      prodQuart = `${pqStr.substring(0, 4)}-Q${pqStr.substring(4, 5)}`;
    }

    let defaultStatus = 'MaxAvailable';
    if (
      window.location &&
      window.location.href &&
      window.location.href.indexOf('revisedCapacity') !== -1
    ) {
      defaultStatus = 'shortHorizon';
    }

    let GbdIconClass = { css: '', text: 'no variance editing (disabled)' };
    let CapQIconClass = { css: '', text: 'no variance editing (disabled)' };
    let MaxQIconClass = { css: '', text: 'no variance editing (disabled)' };
    let PartialQtrIconClass = {
      css: '',
      text: 'Partial Quarter: No variance calculated (Disabled)'
    };

    if (!partialQuarter || partialQuarter !== '') {
      GbdIconClass =
        divisionCode === 20
          ? this.indicatorClass(gbdVarMatchInd)
          : this.indicatorClass(gbdAdjVarMatchInd);
    }

    if (!partialQuarterPrev || partialQuarterPrev !== '') {
      CapQIconClass =
        divisionCode === 20
          ? this.indicatorClass(capQVarMatchInd)
          : this.indicatorClass(capQAdjVarMatchInd);
      MaxQIconClass =
        divisionCode === 20
          ? this.indicatorClass(maxQVarMatchInd)
          : this.indicatorClass(maxQAdjVarMatchInd);
    }

    let GbdMaxCapVarSub = '';
    let GbdMaxCapVarAdjSub = '';
    if (defaultStatus === 'MaxAvailable') {
      GbdMaxCapVarSub = maxAvailableCapacity;
      GbdMaxCapVarAdjSub = FctyStratHldCpty;
    } else {
      GbdMaxCapVarSub =
        shortHorizonCapacity || shortHorizonCapacity === 0
          ? shortHorizonCapacity
          : maxAvailableCapacity;
      GbdMaxCapVarAdjSub =
        FctyRvsdHldCpty || FctyRvsdHldCpty === 0
          ? FctyRvsdHldCpty
          : FctyStratHldCpty;
    }

    switch (colDef.field) {
      case 'indicator':
        if (partialQuarter === 'readOnly') {
          return (
            <div className="d-sm-b">
              <Tooltip
                placement="right"
                overlay={
                  <span className="c-f">
                    <span className="lever-icon-text">
                      <p>
                        <span
                          className={`lever-icon ${PartialQtrIconClass.css}`}
                        ></span>
                        {PartialQtrIconClass.text}
                      </p>
                    </span>
                  </span>
                }
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <span className="lever-icon-space">
                  <span
                    className={`lever-icon ${PartialQtrIconClass.css}`}
                  ></span>
                </span>
              </Tooltip>
            </div>
          );
        }
        return (
          <div className="d-sm-b">
            <Tooltip
              placement="right"
              overlay={
                <span className="c-f">
                  <span className="lever-icon-text">
                    <p>
                      <span className={`lever-icon ${GbdIconClass.css}`}></span>
                      {divisionCode === 20 ? '' : 'Adjd '} Max Cpty Var :
                      {GbdIconClass.text}
                    </p>
                    {divisionCode === 20 && (
                      <>
                        <p>
                          <span
                            className={`lever-icon ${CapQIconClass.css}`}
                          ></span>
                          {divisionCode === 20 ? '' : 'Adjd '}Fcty Cpty QoQ Var
                          : {CapQIconClass.text}
                        </p>
                        <p>
                          <span
                            className={`lever-icon ${MaxQIconClass.css}`}
                          ></span>
                          {divisionCode === 20 ? '' : 'Adjd '}Max Cpty QoQ Var :
                          {MaxQIconClass.text}
                        </p>
                      </>
                    )}
                  </span>
                </span>
              }
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <span className="lever-icon-space">
                <span className={`lever-icon ${GbdIconClass.css}`}></span>
                {divisionCode === 20 && (
                  <>
                    <span className={`lever-icon ${CapQIconClass.css}`}></span>
                    <span className={`lever-icon ${MaxQIconClass.css}`}></span>
                  </>
                )}
              </span>
            </Tooltip>
          </div>
        );
      case 'productionQuarter':
        return (
          <span className="d-sm-b">
            <span className="d-sm-b u-cursor-pointer">
              {partialQuarter ? (
                <span className="u-bold text-color-grey1">{prodQuart}</span>
              ) : (
                <Link
                  to={this.linkGen(
                    factoryCode,
                    capacityTypeCode,
                    productionQuarter
                  )}
                >
                  <span className="text-color-blue1 hover-underline ">
                    <span className="u-bold">{prodQuart}</span>
                  </span>
                </Link>
              )}
            </span>
            {totalWeeks && (
              <span className="small d-sm-b text-color-grey1  pt2-sm">
                {totalWeeks} weeks
              </span>
            )}
          </span>
        );

      case 'GbdMaxCapVariance':
        return (
          <div className="d-sm-b">
            {partialQuarter !== 'readOnly' &&
              (FctyMaxCapacity ||
                FctyMaxCapacity === 0 ||
                (shortHorizonCapacity || shortHorizonCapacity === 0) ||
                (maxAvailableCapacity || maxAvailableCapacity === 0)) && (
                <span className="d-sm-b text-color-grey1 ">
                  {FctyMaxCapacity || FctyMaxCapacity === 0
                    ? numberWithCommas(FctyMaxCapacity)
                    : '—'}
                  -
                  {GbdMaxCapVarSub || GbdMaxCapVarSub === 0
                    ? numberWithCommas(GbdMaxCapVarSub)
                    : '—'}
                </span>
              )}
            {value || value === 0 ? `${numberWithCommas(value)}` : '—'}
          </div>
        );

      case 'GbdMaxCapAdjVariance':
        return (
          <div className="d-sm-b">
            {partialQuarter !== 'readOnly' &&
              (FctyMaxHldCpty ||
                FctyMaxHldCpty === 0 ||
                (FctyRvsdHldCpty || FctyRvsdHldCpty === 0) ||
                (FctyStratHldCpty || FctyStratHldCpty === 0)) && (
                <span className="d-sm-b text-color-grey1 ">
                  {FctyMaxHldCpty || FctyMaxHldCpty === 0
                    ? numberWithCommas(FctyMaxHldCpty)
                    : '—'}
                  -
                  {GbdMaxCapVarAdjSub || GbdMaxCapVarAdjSub === 0
                    ? numberWithCommas(GbdMaxCapVarAdjSub)
                    : '—'}
                </span>
              )}
            {value || value === 0 ? `${numberWithCommas(value)}` : '—'}
          </div>
        );
      case 'CapQMaxCapVariance':
        return (
          <div className="d-sm-b">
            {(CapQMaxCapVarPresent ||
              CapQMaxCapVarPresent === 0 ||
              (CapQMaxCapVarPrevious || CapQMaxCapVarPrevious === 0)) && (
              <span className="d-sm-b text-color-grey1 ">
                {CapQMaxCapVarPresent || CapQMaxCapVarPresent === 0
                  ? numberWithCommas(CapQMaxCapVarPresent)
                  : '—'}
                -
                {CapQMaxCapVarPrevious || CapQMaxCapVarPrevious === 0
                  ? numberWithCommas(CapQMaxCapVarPrevious)
                  : '—'}
              </span>
            )}
            {value || value === 0 ? `${numberWithCommas(value)}` : '—'}
          </div>
        );
      case 'CapQMaxCapAdjVariance':
        return (
          <div className="d-sm-b">
            {(CapQMaxCapAdjVarPresent ||
              CapQMaxCapAdjVarPresent === 0 ||
              (CapQMaxCapAdjVarPrevious || CapQMaxCapAdjVarPrevious === 0)) && (
              <span className="d-sm-b text-color-grey1 ">
                {CapQMaxCapAdjVarPresent || CapQMaxCapAdjVarPresent === 0
                  ? numberWithCommas(CapQMaxCapAdjVarPresent)
                  : '—'}
                -
                {CapQMaxCapAdjVarPrevious || CapQMaxCapAdjVarPrevious === 0
                  ? numberWithCommas(CapQMaxCapAdjVarPrevious)
                  : '—'}
              </span>
            )}
            {value || value === 0 ? `${numberWithCommas(value)}` : '—'}
          </div>
        );
      case 'MaxQMaxCapVariance':
        return (
          <div className="d-sm-b">
            {(MaxQMaxCapVarPresent ||
              MaxQMaxCapVarPresent === 0 ||
              (MaxQMaxCapVarPrevious || MaxQMaxCapVarPrevious === 0)) && (
              <span className="d-sm-b text-color-grey1 ">
                {MaxQMaxCapVarPresent || MaxQMaxCapVarPresent === 0
                  ? numberWithCommas(MaxQMaxCapVarPresent)
                  : '—'}
                -
                {MaxQMaxCapVarPrevious || MaxQMaxCapVarPrevious === 0
                  ? numberWithCommas(MaxQMaxCapVarPrevious)
                  : '—'}
              </span>
            )}
            {value || value === 0 ? `${numberWithCommas(value)}` : '—'}
          </div>
        );
      case 'MaxQMaxCapAdjVariance':
        return (
          <div className="d-sm-b">
            {(MaxQMaxCapAdjVarPresent ||
              MaxQMaxCapAdjVarPresent === 0 ||
              (MaxQMaxCapAdjVarPrevious || MaxQMaxCapAdjVarPrevious === 0)) && (
              <span className="d-sm-b text-color-grey1 ">
                {MaxQMaxCapAdjVarPresent || MaxQMaxCapAdjVarPresent === 0
                  ? numberWithCommas(MaxQMaxCapAdjVarPresent)
                  : '—'}
                -
                {MaxQMaxCapAdjVarPrevious || MaxQMaxCapAdjVarPrevious === 0
                  ? numberWithCommas(MaxQMaxCapAdjVarPrevious)
                  : '—'}
              </span>
            )}
            {value || value === 0 ? `${numberWithCommas(value)}` : '—'}
          </div>
        );
      default:
    }
  };

  render() {
    return <span className="d-block">{this.getSection()}</span>;
  }
}
LeversMinimalCustomCell.propTypes = {
  context: PropTypes.any,
  node: PropTypes.any,
  colDef: PropTypes.any,
  value: PropTypes.any,
  data: PropTypes.any
};
