import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from '../grid/Grid.js';

import { Button } from '../../components/index.js';
import SimpleCellRender from '../grid/simpleCellRender.js';
import gridConstants from '../../constants/gridConstants.js';
const gridDefault = {
  resizable: true,
  editable: false,
  sortable: true,
  filter: true
};

export const isHistoryChanged = params => {
  let changeChappen = false;
  const paramData = params.data || params.node.data;

  const paramField =
    params.colDef && params.colDef.field
      ? params.colDef.field
      : params.column.colDef.field;

  if (
    paramData.changedColms &&
    paramData.changedColms.length &&
    paramData.changedColms.includes(paramField)
  ) {
    changeChappen = true;
  }

  return changeChappen;
};

export const getDate = paramval => {
  const date = paramval.length > 0 ? new Date(paramval) : new Date();
  return `${date.getMonth() +
    1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};

export const getViewConfig = arr => {
  let str = '';
  if (arr && Object.keys(arr).length !== 0) {
    Object.keys(arr).forEach(e => {
      if (e === 'maxCapacityEditable') {
        str += `'Max Capacity Columns' are '${
          arr[e] ? 'Editable' : 'ReadOnly'
        }'`;
      } else {
        str += `${e}" is '${arr[e]}'`;
      }
    });
  }
  return str;
};

export const getResValStat = arr => {
  let newar = '';
  if (arr && arr.length > 0) {
    arr.forEach(function(v) {
      newar += `${newar.length > 0 ? ';' : ''} "${v.value}" is '${v.status}'`;
    });
  }
  return newar;
};

export const getResValPos = arr => {
  let newar = '';
  if (arr && arr.length > 0) {
    arr.forEach(function(v) {
      newar += `${newar.length > 0 ? ';' : ''} '${v.value}': '${v.serial &&
        v.serial} -> ${v.oldSerial && v.oldSerial}'`;
    });
  }
  return newar;
};
export const getResValLen = arr => {
  let valStr = '';
  if (arr && arr.length > 0) {
    arr.forEach(function(v) {
      valStr += `'${v.value}' is '${v.status}'`;
    });
  }

  return valStr.length || 0;
};

const historyHeader = [
  {
    headerName: 'DATE TIME',
    field: 'timestamp',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 180,
    valueFormatter: params => getDate(params.value),
    cellClass: 'nonEditableColumn'
  },
  {
    headerName: 'LANDING PAGE',
    field: 'landingPage',
    filter: false,
    editable: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 210,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params =>
      isHistoryChanged(params)
        ? `${params.value.toUpperCase()} CAPACITY VIEW `
        : ''
  },
  {
    headerName: 'REVISED VIEW WEEKS',
    field: 'revisedHorizonWeeks',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 80,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => `${isHistoryChanged(params) ? params.value : ''}`
  },
  {
    headerName: 'REVISED THRESHOLD INCREASE',
    field: 'revisedThresholdIncrease',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 80,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => `${isHistoryChanged(params) ? params.value : ''}`
  },
  {
    headerName: 'REVISED THRESHOLD DECREASE',
    field: 'revisedThresholdDecrease',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 80,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => `${isHistoryChanged(params) ? params.value : ''}`
  },
  {
    headerName: 'STRATEGIC THRESHOLD INCREASE',
    field: 'strategicThresholdIncrease',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 80,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => `${isHistoryChanged(params) ? params.value : ''}`
  },
  {
    headerName: 'STRATEGIC THRESHOLD DECREASE',
    field: 'strategicThresholdDecrease',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 80,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => `${isHistoryChanged(params) ? params.value : ''}`
  },
  {
    headerName: 'REVISED VIEW CONFIG',
    field: 'revisedViewConfig',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 200,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params =>
      `${isHistoryChanged(params) ? getViewConfig(params.value) : ''}`
  },

  {
    headerName: 'NEW REASON CODES',
    field: 'newrc',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 210,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => getResValStat(params.value),
    cellStyle: { 'white-space': 'normal' }
  },
  {
    headerName: 'REASON CODES STATUS CHANGE',
    field: 'modifiedrc',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 210,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params => getResValStat(params.value),
    cellStyle: { 'white-space': 'normal' }
  },
  {
    headerName: 'REASON CODES POSITION CHANGE',
    field: 'rcSwaps',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 210,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params =>
      params.value.length > 0 ? ' Reason codes order changed' : '',
    cellStyle: { 'white-space': 'normal' }
  },

  {
    headerName: 'FEATURE CHANGE',
    field: 'featureConfigs',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 210,
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`,
    valueFormatter: params =>
      params.value && params.value.length > 0 ? params.value.join('; ') : '',
    cellStyle: { 'white-space': 'normal' }
  },
  {
    headerName: 'MODIFIED BY',
    field: 'userId',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 180,
    valueFormatter(params) {
      if (params.value) {
        return params.value.split('@')[0];
      }
      return params.value;
    },
    cellClass: params =>
      `${isHistoryChanged(params) ? 'editableCol' : 'nonEditableColumn'}`
  }
];

export default class AdminHistorySection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyParams: {},
      context: { componentParent: this },
      currentReasonCodeGrid: [],
      frameworkComponents: {
        SimpleCellRender
      },
      contextMenuItems: ['copy'],
      excelStyles: gridConstants.excelStyles,
      getRowHeight(params) {
        const revisedConfig = getViewConfig(params.data.revisedViewConfig);
        const nrcl = [
          getResValLen(params.data.newrc),
          getResValLen(params.data.modifiedrc),
          revisedConfig.length
        ];

        const maxt = Math.max(...nrcl);

        let newlen = 1;
        if (maxt > 30) {
          newlen = Math.ceil(maxt / 30);
        }

        return 28 * newlen;
      }
    };

    this.setHistoryParams = this.setHistoryParams.bind(this);
    this.cellRenderAction = this.cellRenderAction.bind(this);
    this.downloadHistory = this.downloadHistory.bind(this);
  }
  setHistoryParams = params => {
    if (params) {
      this.setState({ historyParams: params });
      if (params.columnApi.getAllColumns()) {
        try {
          params.api.closeToolPanel();
          // params.api.sizeColumnsToFit();
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };
  cellRenderAction = (rowIndex, column) => {
    const { OpenHistoryRCModel } = this.props;
    OpenHistoryRCModel(rowIndex);
  };

  downloadHistory = () => {
    const { historyParams } = this.state;
    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() +
      1}-${today.getDate()}_${today.getHours()}-${today.getMinutes()}`;

    const params = {
      fileName: `ADMIN-HISTORY-${date}`,
      sheetName: 'HISTORY',
      processCellCallback(params) {
        if (params.value === 0 || params.value) {
          let fieldName = params.column.colDef.field;
          const historychange = isHistoryChanged(params);
          if (fieldName === 'landingPage') {
            return historychange
              ? `${params.value.toUpperCase()} CAPACITY VIEW`
              : '';
          } else if (fieldName === 'newrc' || fieldName === 'modifiedrc') {
            return params.value.length > 0 ? getResValStat(params.value) : '';
          } else if (fieldName === 'revisedViewConfig') {
            return params.value && historychange
              ? getViewConfig(params.value)
              : '';
          } else if (fieldName === 'rcSwaps') {
            return params.value.length > 0 ? ' Reason codes order changed' : '';
          } else if (fieldName === 'userId') {
            return params.value.length > 0 ? params.value.split('@')[0] : '';
          } else if (fieldName === 'timestamp') {
            return getDate(params.value);
          } else if (
            fieldName === 'revisedHorizonWeeks' ||
            fieldName === 'revisedThresholdIncrease' ||
            fieldName === 'revisedThresholdDecrease' ||
            fieldName === 'strategicThresholdIncrease' ||
            fieldName === 'strategicThresholdDecrease'
          ) {
            return historychange ? params.value : '';
          } else if (fieldName === 'featureConfigs') {
            return params.value && params.value.length > 0
              ? params.value.join('; ')
              : '';
          }
        }
      }
    };
    historyParams.api.exportDataAsExcel(params);
  };

  render() {
    const {
      frameworkComponents,
      context,
      getRowHeight,
      contextMenuItems,
      excelStyles
    } = this.state;
    const { historyData } = this.props;
    return (
      <div className="container adminPanel noSide">
        <div className="row prl2-sm">
          <div className="ncss-col-sm-6 ">
            <h3 className="validation-header"> History</h3>
          </div>
          <div className="ncss-col-sm-6">
            <Button
              onClick={() => this.downloadHistory()}
              extraClassName={`fl-sm-r ncss-btn-primary-dark ncss-btn-sm`}
            >
              <i className="fas fa fa-download" />
              <span className="d-sm-h d-md-ib"> Download</span>
            </Button>
          </div>
        </div>

        <div className="ncss-col-sm-12  pl0-sm pr0-sm ">
          {historyData ? (
            <div className="border numberList">
              <div
                id="myGrid"
                className="ag-theme-balham"
                style={{ height: 'calc( 55vh )', width: '100%' }}
              >
                <Grid
                  defaultColDef={gridDefault}
                  context={context}
                  columnDefs={historyHeader}
                  rowData={historyData}
                  setParams={this.setHistoryParams}
                  dbGridFunctions={false}
                  enableCellChangeFlash={true}
                  frameworkComponents={frameworkComponents}
                  getRowHeight={getRowHeight}
                  excelStyles={excelStyles}
                  getContextMenuItems={contextMenuItems}
                  headerHeight={70}
                />
              </div>
            </div>
          ) : (
            <div className="border" />
          )}
        </div>
      </div>
    );
  }
}

AdminHistorySection.propTypes = {
  displayHistoryModel: PropTypes.bool,
  toogleHistoryModel: PropTypes.func,
  OpenHistoryRCModel: PropTypes.func,
  historyData: PropTypes.any,
  pageTitle: PropTypes.any
};
