/* eslint-disable complexity */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import moment from 'moment';
import Grid from '../../components/grid/Grid.js';
import '../../stylus/admin.css';
import history from '../../util/history.js';
import { msgConstants } from '../../constants/msgConstants.js';
import SimpleCellRender from '../../components/grid/simpleCellRender.js';
import AdminHistorySection from '../../components/admin/historySection.js';
import HistoryReasonCodeModal from '../../components/admin/historyReasonCodeModal.js';
import { Button, Spinner, SingleInput } from '../../components/index.js';
import NotFound from '../../components/errors/NotFound.js';
import {
  getAdminData,
  getAdminLoading,
  getAdminError,
  getUpdatingAdminLoading,
  getUpdatingAdminSuccess
} from '../../reducers/adminReducer.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { selectStatusButton } from '../../actions/adminActions.js';
import pageConstants from './CapacityAdmin.constants.js';
import {
  landingOptions,
  adminThreshholdLimits
} from '../../constants/appConstants.js';
import {
  selectLegacyUserName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';

import { isSame } from '../../util/general.js';

export class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: { componentParent: this },
      filteredReasonCodes: [],
      userProfile: {},
      pageData: {
        configurations: {
          legacyUserName: '',
          landingPage: '',
          revisedHorizonWeeks: '',
          userRole: 'WHQ',
          reasonCodes: [],
          revisedThresholdIncrease: '',
          revisedThresholdDecrease: '',
          strategicThresholdIncrease: '',
          strategicThresholdDecrease: '',
          featureConfig: {
            lever: false
          }
        },
        history: []
      },
      newHistory: [],
      pageBackData: {},
      enableSave: false,
      historyRCListEntry: {},
      displayHistoryModel: false,
      displayHistoryRCModel: false,
      reasonCodeparams: {},
      landingOptions: [...landingOptions],
      adminInfo: pageConstants.sampleData,
      toastConfig: {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      },
      frameworkComponents: {
        SimpleCellRender
      },
      contextMenuItems: ['copy'],
      pageTitles: [
        { name: 'Apparel', type: 'AP' },
        { name: 'Footwear', type: 'FW' },
        { name: 'Equipment', type: 'EQ' }
      ],
      pageTitle: '',
      horizonWeeksRange: { min: 0, max: 103 },

      getRowHeight(params) {
        let defaultheight = 45;
        return defaultheight;
      }
    };

    this.saveDataChanges = this.saveDataChanges.bind(this);
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onCheckBoxClick = this.onCheckBoxClick.bind(this);

    this.toastUpdate = this.toastUpdate.bind(this);
    this.toogleHistoryModel = this.toogleHistoryModel.bind(this);
    this.toogleHistoryRCModel = this.toogleHistoryRCModel.bind(this);
    this.OpenHistoryRCModel = this.OpenHistoryRCModel.bind(this);

    this.cellRenderAction = this.cellRenderAction.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.createHistory = this.createHistory.bind(this);
    this.createReasCode = this.createReasCode.bind(this);
    this.getAdminData = this.getAdminData.bind(this);
  }

  componentDidMount() {
    this.getAdminData();
    localStorage.setItem('isCapacityDataChanged', 'false');
  }
  changeTab = num => {
    this.setState({ activeTab: num });
  };

  getAdminData = () => {
    const { requestAdminData, requestUserProfileData } = this.props;
    requestUserProfileData();
    let { pageTitle, pageTitles } = this.state;
    const currentPathName = window.location.pathname;
    let pageType = currentPathName.includes('/admin/manageView/')
      ? currentPathName.replace('/admin/manageView/', '')
      : null;
    if (pageType) {
      const pageTitleObj = pageTitles.find(o => o.type === pageType);
      if (pageTitleObj && Object.keys(pageTitleObj).length !== 0) {
        pageTitle = pageTitleObj;
      }
    }
    this.setState({ pageTitle });

    setTimeout(() => {
      requestAdminData(pageTitle);
    }, 3000);
  };

  toogleHistoryModel = () => {
    const { displayHistoryModel } = this.state;
    this.setState({ displayHistoryModel: !displayHistoryModel });
  };

  toogleHistoryRCModel = () => {
    const { displayHistoryRCModel } = this.state;
    this.setState({ displayHistoryRCModel: !displayHistoryRCModel });
  };

  OpenHistoryRCModel = ix => {
    let { pageData, historyRCListEntry } = this.state;
    historyRCListEntry = pageData.history[ix];
    this.setState({ displayHistoryRCModel: true, historyRCListEntry });
  };

  onSearchFieldChange = event => {
    if (
      event.target.name === 'revisedThresholdIncrease' &&
      event.target.value > adminThreshholdLimits.revisedThresholdIncrease
    ) {
      return true;
    }

    if (
      event.target.name === 'revisedThresholdDecrease' &&
      event.target.value > adminThreshholdLimits.revisedThresholdDecrease
    ) {
      return true;
    }
    if (
      event.target.name === 'strategicThresholdIncrease' &&
      event.target.value > adminThreshholdLimits.strategicThresholdIncrease
    ) {
      return true;
    }
    if (
      event.target.name === 'strategicThresholdDecrease' &&
      event.target.value > adminThreshholdLimits.strategicThresholdDecrease
    ) {
      return true;
    }
    let { pageData } = this.state;
    if (event.target.name.toLowerCase() === 'landingpage') {
      pageData.configurations[event.target.name] = event.target.value.replace(
        /\d/g,
        ''
      );
      this.setState({ pageData });
    } else if (event.target.name) {
      pageData.configurations[event.target.name] = event.target.value.replace(
        /\D/g,
        ''
      );
      this.setState({ pageData });
    }
  };

  onCheckBoxClick = (view, name) => {
    localStorage.setItem('isCapacityDataChanged', 'true');

    let { pageData } = this.state;
    if (view && name) {
      if (view === 'revised') {
        pageData.configurations.revisedViewConfig[name] = !pageData
          .configurations.revisedViewConfig[name];
      } else {
        pageData.configurations.strategicViewConfig[name] = !pageData
          .configurations.strategicViewConfig[name];
      }
    }
    this.setState({ pageData });
  };

  onFeatureCheckBoxClick = (name, value) => {
    localStorage.setItem('isCapacityDataChanged', 'true');
    let { pageData } = this.state;
    confirmAlert({
      message: 'Are you sure to make Feature Changes?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            if (pageData && pageData.configurations) {
              if (pageData.configurations.featureConfig) {
                pageData.configurations.featureConfig[name] = value;
              } else {
                pageData.configurations.featureConfig = {
                  [name]: value
                };
              }
              const featureSessionItem = `featureConfigLocal${name}`;
              localStorage.setItem(featureSessionItem, value);
            }
            this.setState({ pageData });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  componentWillReceiveProps(nextProps) {
    const {
      adminData,
      userProfileData,
      updatingAdminSuccess,
      requestAdminData,
      location
    } = this.props;

    if (adminData !== nextProps.adminData) {
      if (
        nextProps.adminData &&
        Object.keys(nextProps.adminData).length !== 0
      ) {
        //var uniqueUsers = _.uniqWith(users, _.isEqual);//removed complete duplicates
        this.setState({
          pageData: { ...nextProps.adminData },
          pageBackData: JSON.parse(
            JSON.stringify(nextProps.adminData.configurations)
          )
        });
        setTimeout(() => {
          this.createReasCode();
        }, 100);
        this.createHistory(nextProps.adminData.history);
      }
    }
    if (userProfileData !== nextProps.userProfileData) {
      if (nextProps.userProfileData.factoryType) {
        this.setState({
          userProfile: nextProps.userProfileData
        });
        setTimeout(() => {
          this.createReasCode();
        }, 100);
      }
    }
    if (updatingAdminSuccess !== nextProps.updatingAdminSuccess) {
      if (nextProps.updatingAdminSuccess) {
        requestAdminData('admin');
      }
    }

    if (location.pathname !== nextProps.location.pathname) {
      this.getAdminData();
    }
  }

  createReasCode = () => {
    const { pageData, userProfile } = this.state;
    if (
      Object.keys(userProfile).length > 0 &&
      Object.keys(pageData).length > 0
    ) {
      if (pageData.configurations && pageData.configurations.reasonCodes) {
        const filteredReasonCodes = pageData.configurations.reasonCodes;
        filteredReasonCodes.forEach(function(v) {
          delete v.newRow;
        });
        this.setState({ filteredReasonCodes });
      }
    }
  };

  createHistory = history => {
    const newHistory = [...history];
    if (newHistory && newHistory.length > 0) {
      // eslint-disable-next-line max-statements
      newHistory.forEach((item, index) => {
        item.changedColms = [];
        const rcChanges = [];
        const rcSwaps = [];
        const rcNew = [];
        let nextIndex = index + 1;
        if (nextIndex < history.length) {
          if (
            item.revisedHorizonWeeks !== history[nextIndex].revisedHorizonWeeks
          ) {
            item.changedColms.push('revisedHorizonWeeks');
          }
          if (item.landingPage !== history[nextIndex].landingPage) {
            item.changedColms.push('landingPage');
          }
          if (
            item.revisedThresholdIncrease !==
            history[nextIndex].revisedThresholdIncrease
          ) {
            item.changedColms.push('revisedThresholdIncrease');
          }
          if (
            item.revisedThresholdDecrease !==
            history[nextIndex].revisedThresholdDecrease
          ) {
            item.changedColms.push('revisedThresholdDecrease');
          }

          if (
            item.strategicThresholdIncrease !==
            history[nextIndex].strategicThresholdIncrease
          ) {
            item.changedColms.push('strategicThresholdIncrease');
          }
          if (
            item.strategicThresholdDecrease !==
            history[nextIndex].strategicThresholdDecrease
          ) {
            item.changedColms.push('strategicThresholdDecrease');
          }

          //featureChange
          const newFeature = item.featureConfig || {};
          const oldFeature = history[nextIndex].featureConfig || {};
          item.featureChange = [];
          if (JSON.stringify(newFeature) !== JSON.stringify(oldFeature)) {
            const featureChangeFields = _.reduce(
              newFeature,
              function(result, value, key) {
                return _.isEqual(value, oldFeature[key])
                  ? result
                  : result.concat(key);
              },
              []
            );
            item.changedColms.push('featureConfigs');

            item.featureConfigs = featureChangeFields.map(
              i =>
                `${i.toUpperCase()}: ${newFeature[i] ? 'ACTIVE' : 'INACTIVE'}`
            );
          }

          //revisedViewConfig
          let newRevisedViewConfig = item.revisedViewConfig;
          let oldRevisedViewConfig = history[nextIndex].revisedViewConfig;

          if (
            JSON.stringify(newRevisedViewConfig) !==
            JSON.stringify(oldRevisedViewConfig)
          ) {
            item.changedColms.push('revisedViewConfig');
          }

          //revisedViewConfig
          const newRc = item.reasonCodes;
          const OldRc = history[nextIndex].reasonCodes;
          newRc.forEach((rci, rcix) => {
            const ind = OldRc.findIndex(i => i.value === rci.value);

            if (ind >= 0) {
              if (rci.status !== OldRc[ind].status) {
                rcChanges.push(rci);
              }
              if (rci.serial !== OldRc[ind].serial) {
                rci.oldSerial = OldRc[ind].serial;
                rcSwaps.push(rci);
              }
            } else {
              rcNew.push(rci);
            }
          });
          if (rcChanges.length > 0) {
            item.changedColms.push('reasonCodes');
            item.changedColms.push('modifiedrc');
          }
          if (rcSwaps.length > 0) {
            item.changedColms.push('reasonCodes');
            item.changedColms.push('rcSwaps');
          }
          if (rcNew.length > 0) {
            item.changedColms.push('reasonCodes');
            item.changedColms.push('newrc');
          }
        }
        item.modifiedrc = rcChanges;
        item.rcSwaps = rcSwaps;
        item.newrc = rcNew;
      });
    }

    this.setState({ newHistory });
  };

  // remove reason code
  cellRenderAction = (rowIndex, column) => {
    const { reasonCodeparams } = this.state;
    reasonCodeparams.api.forEachNode(function(node) {
      node.setSelected(node.rowIndex === rowIndex);
    });
    const selected = reasonCodeparams.api.getSelectedRows();
    reasonCodeparams.api.updateRowData({ remove: selected });
  };

  addNewReasonCode = () => {
    const { reasonCodeparams } = this.state;
    const CurrentGridData = [];
    reasonCodeparams.api.forEachNode(node => CurrentGridData.push(node.data));
    const newRcode = {
      value: '',
      serial: CurrentGridData.length,
      id: CurrentGridData.length,
      status: 'ACTIVE',
      newRow: true
    };
    reasonCodeparams.api.updateRowData({ add: [newRcode], addIndex: 0 });

    reasonCodeparams.api.setFocusedCell(0, 'value');
    reasonCodeparams.api.startEditingCell({
      rowIndex: 0,
      colKey: 'value'
    });

    localStorage.setItem('isCapacityDataChanged', 'true');
  };

  toastUpdate = toastms => {
    const { toastConfig } = this.state;
    if (toastms.status) {
      toast.success(toastms.msg, toastConfig);
    } else {
      toast.error(toastms.msg, toastConfig);
    }
  };

  // eslint-disable-next-line max-statements
  adminChangesValidation = pageData => {
    const { pageBackData, horizonWeeksRange } = this.state;
    const valiData = { status: true, msg: '' };

    let changecount = 0;

    let newRevWeek = pageData.revisedHorizonWeeks;
    let oldRevWeek = pageBackData.revisedHorizonWeeks;

    let newLandPage = pageData.landingPage;
    let oldLandPage = pageBackData.landingPage;

    let newRevisedThresholdIncrease = pageData.revisedThresholdIncrease;
    let oldRevisedThresholdIncrease = pageBackData.revisedThresholdIncrease;

    let newRevisedThresholdDecrease = pageData.revisedThresholdDecrease;
    let oldRevisedThresholdDecrease = pageBackData.revisedThresholdDecrease;

    let newStrategicThresholdIncrease = pageData.strategicThresholdIncrease;
    let oldStrategicThresholdIncrease = pageBackData.strategicThresholdIncrease;

    let newStrategicThresholdDecrease = pageData.strategicThresholdDecrease;
    let oldStrategicThresholdDecrease = pageBackData.strategicThresholdDecrease;

    let newRevisedViewConfig = pageData.revisedViewConfig;
    let oldRevisedViewConfig = pageBackData.revisedViewConfig;

    let newResCode = pageData.reasonCodes;
    let oldResCode = pageBackData.reasonCodes;

    if (newRevWeek !== oldRevWeek) {
      if (newRevWeek.toString().length === 0) {
        valiData.status = false;
        valiData.msg += 'Revised Horizon Week Cannot be Empty;';
      } else if (isNaN(newRevWeek)) {
        valiData.status = false;
        valiData.msg += 'Revised Horizon Week should be Number;';
      } else if (
        Number(newRevWeek) < horizonWeeksRange.min ||
        Number(newRevWeek) > horizonWeeksRange.max
      ) {
        valiData.status = false;
        valiData.msg += `Revised Horizon Week should be between ${horizonWeeksRange.min} to ${horizonWeeksRange.max};`;
      }

      if (valiData.status) {
        changecount += 1;
        localStorage.setItem('isCapacityDataChanged', 'true');
      }
    }

    if (newLandPage !== oldLandPage) {
      changecount += 1;
      localStorage.setItem('isCapacityDataChanged', 'true');
    }

    if (
      newRevisedThresholdIncrease !== oldRevisedThresholdIncrease ||
      newRevisedThresholdDecrease !== oldRevisedThresholdDecrease
    ) {
      if (
        newRevisedThresholdIncrease !== '' &&
        newRevisedThresholdIncrease !== undefined &&
        (newRevisedThresholdDecrease === '' ||
          newRevisedThresholdDecrease === undefined)
      ) {
        valiData.status = false;
        valiData.msg += 'Revised threshold decrease value cannot be empty;';
      } else if (
        newRevisedThresholdIncrease.toString() ===
          oldRevisedThresholdIncrease.toString() &&
        newRevisedThresholdDecrease.toString() ===
          oldRevisedThresholdDecrease.toString()
      ) {
        valiData.status = false;
        valiData.msg += 'No change in Revised Thresholds; ';
      } else if (
        (newRevisedThresholdIncrease === '' ||
          newRevisedThresholdIncrease === undefined) &&
        newRevisedThresholdDecrease !== '' &&
        newRevisedThresholdDecrease !== undefined
      ) {
        valiData.status = false;
        valiData.msg += 'Revised threshold increase value cannot be empty;';
      } else if (
        newRevisedThresholdIncrease >
        adminThreshholdLimits.revisedThresholdIncrease
      ) {
        valiData.status = false;
        valiData.msg += `Revised threshold increase is greater than ${adminThreshholdLimits.revisedThresholdIncrease};`;
      } else if (
        newRevisedThresholdDecrease >
        adminThreshholdLimits.revisedThresholdDecrease
      ) {
        valiData.status = false;
        valiData.msg += `Revised threshold decrease is greater than ${adminThreshholdLimits.revisedThresholdDecrease};`;
      }
      if (valiData.status) {
        changecount += 1;
        localStorage.setItem('isCapacityDataChanged', 'true');
      }
    }

    if (
      newStrategicThresholdIncrease !== oldStrategicThresholdIncrease ||
      newStrategicThresholdDecrease !== oldStrategicThresholdDecrease
    ) {
      if (
        newStrategicThresholdIncrease !== '' &&
        newStrategicThresholdIncrease !== undefined &&
        (newStrategicThresholdDecrease === '' ||
          newStrategicThresholdDecrease === undefined)
      ) {
        valiData.status = false;
        valiData.msg += 'Strategic threshold decrease value cannot be empty;';
      } else if (
        newStrategicThresholdIncrease.toString() ===
          oldStrategicThresholdIncrease.toString() &&
        newStrategicThresholdDecrease.toString() ===
          oldStrategicThresholdDecrease.toString()
      ) {
        valiData.status = false;
        valiData.msg += 'No change in Strategic Thresholds; ';
      } else if (
        (newStrategicThresholdIncrease === '' ||
          newStrategicThresholdIncrease === undefined) &&
        newStrategicThresholdDecrease !== '' &&
        newStrategicThresholdDecrease !== undefined
      ) {
        valiData.status = false;
        valiData.msg += 'Strategic threshold increase value cannot be empty;';
      } else if (
        newStrategicThresholdIncrease >
        adminThreshholdLimits.strategicThresholdIncrease
      ) {
        valiData.status = false;
        valiData.msg += `Strategic threshold increase is greater than ${adminThreshholdLimits.strategicThresholdIncrease};`;
      } else if (
        newStrategicThresholdDecrease >
        adminThreshholdLimits.strategicThresholdDecrease
      ) {
        valiData.status = false;
        valiData.msg += `Strategic threshold decrease is greater than ${adminThreshholdLimits.strategicThresholdDecrease};`;
      }
      if (valiData.status) {
        changecount += 1;
        localStorage.setItem('isCapacityDataChanged', 'true');
      }
    }

    if (newResCode.length !== oldResCode.length) {
      newResCode.forEach((resCodeItem, i) => {
        if (resCodeItem.value.length === 0) {
          valiData.status = false;
          valiData.msg += `Empty value in  new Reason code(s) `;
        } else if (!/^[a-zA-Z0-9- ,_]*$/.test(resCodeItem.value)) {
        }
      });
    }

    if (
      JSON.stringify(newRevisedViewConfig) !==
      JSON.stringify(oldRevisedViewConfig)
    ) {
      changecount += 1;
      localStorage.setItem('isCapacityDataChanged', 'true');
    }
    const reasCodeRemDup = _.uniqBy(newResCode, obj => obj.value.toLowerCase());
    const dupVals = [];
    if (reasCodeRemDup.length !== newResCode.length) {
      newResCode.forEach((resCodeItem, i) => {
        const ind = reasCodeRemDup.findIndex(
          i => i.serial === resCodeItem.serial
        );
        if (ind < 0) {
          dupVals.push(resCodeItem.value);
        }
      });
    }
    if (dupVals.length > 0) {
      valiData.status = false;
      valiData.msg += `Duplicate reason code Added : ${dupVals.join()}`;
      changecount += 1;
      localStorage.setItem('isCapacityDataChanged', 'true');
    }
    const changeInResCode = isSame(newResCode, oldResCode);
    if (!changeInResCode) {
      changecount += 1;
      localStorage.setItem('isCapacityDataChanged', 'true');
    }

    // FEATURE CONTROL
    let newfeatureConfig = pageData.featureConfig || {};
    let oldfeatureConfig = pageBackData.featureConfig || {};

    if (JSON.stringify(newfeatureConfig) !== JSON.stringify(oldfeatureConfig)) {
      changecount += 1;
      localStorage.setItem('isCapacityDataChanged', 'true');
    }

    if (changecount === 0) {
      localStorage.setItem('isCapacityDataChanged', 'false');
      valiData.status = false;
      if (valiData.msg.length === 0) {
        valiData.msg += `\n No change to Update! `;
      }
    }

    localStorage.setItem('isCapacityDataChanged', 'false');

    return valiData;
  };

  saveDataChanges = () => {
    const { pageData, reasonCodeparams } = this.state;
    let updateData = JSON.parse(JSON.stringify(pageData.configurations));
    const newGridCodes = [];
    reasonCodeparams.api.forEachNode((node, index) => {
      const newNode = JSON.parse(JSON.stringify(node.data));
      const { value } = newNode;
      if (value) {
        newNode.value = value.toString().trim();
      }
      if (newNode.serial !== 999999999) {
        newNode.serial = index + 1;
        newGridCodes.push(newNode);
      }

      const ind = updateData.reasonCodes.findIndex(i => i.id === newNode.id);

      if (ind >= 0) {
        updateData.reasonCodes[ind] = newNode;
      } else {
        updateData.reasonCodes.push(newNode);
      }
    });

    const validation = this.adminChangesValidation(updateData);

    if (validation.status) {
      const { updateAdminData, history } = this.props;
      delete updateData.timestamp;
      delete updateData.lastModified;
      delete updateData.id;
      updateAdminData(updateData, history);
    } else {
      this.toastUpdate(validation);
    }
  };

  setReasonCodeParams = params => {
    if (params) {
      this.setState({ reasonCodeparams: params });
      if (params.columnApi.getAllColumns()) {
        const defaultSortAfterLoad = [
          {
            colId: 'serial',
            sort: 'asc'
          }
        ];
        params.api.closeToolPanel();
        params.api.sizeColumnsToFit();
        params.api.setSortModel(defaultSortAfterLoad);
        params.api.setSuppressRowDrag(false);
      }
    }
  };

  cellValueChanged = () => {
    localStorage.setItem('isCapacityDataChanged', 'true');
  };

  render() {
    const {
      adminData,
      adminError,
      userProfileData,
      updatingAdminLoading
    } = this.props;
    const {
      pageData,
      context,
      landingOptions,
      displayHistoryModel,
      displayHistoryRCModel,
      historyRCListEntry,
      frameworkComponents,
      newHistory,
      filteredReasonCodes,
      contextMenuItems,
      pageTitle,
      horizonWeeksRange,
      getRowHeight
    } = this.state;
    let IsShowAdmin = false;
    let pageLoading = true;
    if (
      userProfileData &&
      userProfileData.factoryType &&
      ((pageData &&
        pageData.configurations &&
        pageData.configurations.reasonCodes &&
        pageData.configurations.reasonCodes.length > 0) ||
        (adminError && adminError.status))
    ) {
      pageLoading = false;
    }
    let pageType = '';
    if (userProfileData && Object.keys(userProfileData).length !== 0) {
      if ('factoryType' in userProfileData) {
        const currentPathName = window.location.pathname;
        pageType = currentPathName.includes('/admin/manageView/')
          ? currentPathName.replace('/admin/manageView/', '')
          : null;
        if (pageType === userProfileData.factoryType) {
          IsShowAdmin = true;
        }
      }
    }
    let lastMondayCount = 1;

    if (
      pageData &&
      pageData.configurations &&
      pageData.configurations.revisedHorizonWeeks
    ) {
      lastMondayCount =
        Number(pageData.configurations.revisedHorizonWeeks) * 7 + 1;
    }
    const lastMonday = moment()
      .day(lastMondayCount)
      .format('MM/DD/YYYY');
    if (
      pageData &&
      pageData.configurations &&
      !pageData.configurations.featureConfig
    ) {
      pageData.configurations.featureConfig = {
        lever: false
      };
    }
    return (
      <div className="page-align-items  c-f-all">
        <div className="">
          {pageLoading && <Spinner large />}
          {updatingAdminLoading && <Spinner large position="Fixed" />}
          {!pageLoading &&
            (Object.keys(adminError).length !== 0 || !IsShowAdmin) && (
              <NotFound status={adminError.status || 403} />
            )}

          {!pageLoading &&
            Object.keys(adminError).length === 0 &&
            adminData &&
            IsShowAdmin && (
              <div className="ncss-container u-clearfix ">
                <div className="ncss-row page-header pb0-sm pb2-md">
                  <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                    <h3 className="pt3-sm main-heading  c-f-500">
                      {userProfileData && userProfileData.factoryType && (
                        <span>
                          {pageTitle && pageTitle.name && pageTitle.name}
                          {' Capacity  '} Management
                        </span>
                      )}
                    </h3>
                  </div>
                  <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                    <Button
                      onClick={() => this.saveDataChanges()}
                      extraClassName={`ncss-btn-black u-uppercase  dashboard-button`}
                    >
                      Save
                    </Button>
                  </div>
                </div>

                <div className="ncss-row page-menu-header">
                  <NavLink
                    className="menuOpt1Item  active"
                    to={`/admin/manageView/${pageType}`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        localStorage.getItem('isCapacityDataChanged') === 'true'
                      ) {
                        confirmAlert({
                          message: msgConstants.pageChangeAlert,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                localStorage.setItem(
                                  'isCapacityDataChanged',
                                  'false'
                                );
                                history.push(`/admin/manageView/${pageType}`);
                              }
                            },
                            {
                              label: 'No',
                              onClick: () => {}
                            }
                          ]
                        });
                      } else {
                        history.push(`/admin/manageView/${pageType}`);
                      }
                    }}
                  >
                    <span className="text">Capacity</span>
                  </NavLink>

                  <NavLink
                    className="menuOpt1Item "
                    to={`/admin/manageLevers/${pageType}`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        localStorage.getItem('isCapacityDataChanged') === 'true'
                      ) {
                        confirmAlert({
                          message: msgConstants.pageChangeAlert,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                localStorage.setItem(
                                  'isCapacityDataChanged',
                                  'false'
                                );
                                history.push(`/admin/manageLevers/${pageType}`);
                              }
                            },
                            {
                              label: 'No',
                              onClick: () => {}
                            }
                          ]
                        });
                      } else {
                        history.push(`/admin/manageLevers/${pageType}`);
                      }
                    }}
                  >
                    <span className="text">Levers</span>
                  </NavLink>

                  <NavLink
                    className="menuOpt1Item "
                    to={`/admin/manageLrcp/${pageType}`}
                    onClick={e => {
                      e.preventDefault();
                      if (
                        localStorage.getItem('isCapacityDataChanged') === 'true'
                      ) {
                        confirmAlert({
                          message: msgConstants.pageChangeAlert,
                          buttons: [
                            {
                              label: 'Yes',
                              onClick: () => {
                                localStorage.setItem(
                                  'isCapacityDataChanged',
                                  'false'
                                );
                                history.push(`/admin/manageLrcp/${pageType}`);
                              }
                            },
                            {
                              label: 'No',
                              onClick: () => {}
                            }
                          ]
                        });
                      } else {
                        history.push(`/admin/manageLrcp/${pageType}`);
                      }
                    }}
                  >
                    <span className="text">LRCP</span>
                  </NavLink>
                </div>
                <div className=" bg-white c-f-border-5 p3-sm mb5-sm">
                  <div className="ncss-row">
                    <div className="ncss-col-sm-12 ncss-col-md-4 va-sm-t">
                      <div className="adminPanel">
                        <h3 className="validation-header">
                          Horizon &amp; Landing Page
                        </h3>

                        <div className="ncss-row">
                          <div className="ncss-col-sm-12 ">
                            <h3 className="validation-label">
                              Revised Horizon Weeks
                              <span className="red">*</span>
                            </h3>
                            <SingleInput
                              inputType="number"
                              title=""
                              name="revisedHorizonWeeks"
                              key="revisedHorizonWeeks"
                              controlFunc={e => this.onSearchFieldChange(e)}
                              content={pageData.configurations.revisedHorizonWeeks.toString()}
                              className={`validation-text-field`}
                            />
                            <div className="ncss-row ">
                              <div className="ncss-col-sm-12 ncss-col-md-6 text-color-succes">
                                Week of {lastMonday}
                              </div>
                              <div className="ncss-col-sm-12 ncss-col-md-6 ta-sm-r text-color-error">
                                <span className="ncss-error-msg">
                                  Select between {horizonWeeksRange.min} -
                                  {horizonWeeksRange.max}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="ncss-col-sm-12">
                            <h3 className="validation-label">Landing Page:</h3>
                            <select
                              name="landingPage"
                              value={pageData.configurations.landingPage}
                              onChange={e => this.onSearchFieldChange(e)}
                              className={`validation-text-field `}
                            >
                              {landingOptions.map(v => (
                                <option key={v.key} value={v.value}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {pageData &&
                        pageData.configurations &&
                        pageData.configurations.revisedViewConfig && (
                          <div className="adminPanel">
                            <h3 className="validation-header"> Revised View</h3>
                            <div className="ncss-row">
                              <div className="ncss-col-sm-12 ">
                                <div className="fl-sm-l ">
                                  <strong> Max Columns Editable</strong>
                                </div>
                                <div className="fl-sm-r ">
                                  <label
                                    className="switch"
                                    htmlFor="maxCapacityEditable"
                                    onClick={e =>
                                      this.onCheckBoxClick(
                                        'revised',
                                        'maxCapacityEditable'
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      name="maxCapacityEditable"
                                      checked={
                                        pageData.configurations
                                          .revisedViewConfig.maxCapacityEditable
                                      }
                                      onChange={e => {}}
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {pageData &&
                        pageData.configurations &&
                        pageData.configurations.featureConfig && (
                          <div className="adminPanel">
                            <h3 className="validation-header">
                              Features Control
                            </h3>
                            <div className="ncss-row">
                              <div className="ncss-col-sm-12 ">
                                <div className="fl-sm-l ">
                                  <strong>Display "Capacity Levers" tab</strong>
                                </div>
                                <div className="fl-sm-r ">
                                  <label
                                    className="switch"
                                    htmlFor="featureLever"
                                    onClick={e =>
                                      this.onFeatureCheckBoxClick(
                                        'lever',
                                        !pageData.configurations.featureConfig
                                          .lever
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      name="featureLever"
                                      checked={
                                        pageData.configurations.featureConfig
                                          .lever
                                      }
                                      onChange={e => {}}
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      <div className="adminPanel c-f-all">
                        <h3 className="validation-header">
                          {pageTitle && pageTitle.type && pageTitle.type}{' '}
                          Capacity Validation Threshold
                        </h3>
                        <p className="validation-header-description">
                          These threshold will alert users when capacity has
                          increased or decreased beyond a percentage threshold
                        </p>
                        <h3 className="validation-label"> Revised Threshold</h3>
                        <div className="d-sm-b">
                          <div className="d-sm-ib thresholdCol">
                            <div className="iconWrapper decrement">
                              <SingleInput
                                inputType="text"
                                name="revisedThresholdDecrease"
                                key="revisedThresholdDecrease"
                                controlFunc={e => this.onSearchFieldChange(e)}
                                content={pageData.configurations.revisedThresholdDecrease.toString()}
                                className="validation-text-field"
                                placeholder="Decrease"
                                max="100"
                              />
                            </div>
                          </div>
                          <div className="d-sm-ib thresholdCol">
                            <div className="iconWrapper">
                              <SingleInput
                                inputType="text"
                                name="revisedThresholdIncrease"
                                key="revisedThresholdIncrease"
                                controlFunc={e => this.onSearchFieldChange(e)}
                                content={pageData.configurations.revisedThresholdIncrease.toString()}
                                className="validation-text-field"
                                placeholder="Increase"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>

                        <h3 className="validation-label validation-margin">
                          Strategic Threshold
                        </h3>
                        <div className="d-sm-b">
                          <div className="d-sm-ib thresholdCol">
                            <div className="iconWrapper decrement">
                              <SingleInput
                                inputType="text"
                                name="strategicThresholdDecrease"
                                key="strategicThresholdDecrease"
                                controlFunc={e => this.onSearchFieldChange(e)}
                                content={pageData.configurations.strategicThresholdDecrease.toString()}
                                className="validation-text-field"
                                placeholder="Decrease"
                                max="100"
                              />
                            </div>
                          </div>
                          <div className="d-sm-ib thresholdCol">
                            <div className="iconWrapper">
                              <SingleInput
                                inputType="text"
                                name="strategicThresholdIncrease"
                                key="strategicThresholdIncrease"
                                controlFunc={e => this.onSearchFieldChange(e)}
                                content={pageData.configurations.strategicThresholdIncrease.toString()}
                                className="validation-text-field"
                                placeholder="Increase"
                                max="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ncss-col-sm-12 ncss-col-md-8  va-sm-t">
                      <div className="adminPanel noSide">
                        <div className="ncss-row prl2-sm">
                          <div className="ncss-col-sm-6 va-sm-t   u-bold">
                            <h3 className="validation-header"> Reason Codes</h3>
                          </div>
                          <div className="ncss-col-sm-6">
                            <Button
                              onClick={() => this.addNewReasonCode()}
                              extraClassName={`fl-sm-r ncss-btn-primary-dark ncss-btn-sm`}
                            >
                              <i className="fas fa-plus"></i> Add New
                            </Button>
                          </div>
                        </div>
                        <div className="ncss-row">
                          <div className="ncss-col-sm-12">
                            <div
                              id="myGrid"
                              className="ag-theme-balham"
                              style={{ height: 'calc( 55vh )', width: '100%' }}
                            >
                              <Grid
                                context={context}
                                defaultColDef={pageConstants.gridDefault}
                                columnDefs={pageConstants.reasonCodeHeader}
                                rowData={filteredReasonCodes}
                                setParams={this.setReasonCodeParams}
                                dbGridFunctions={false}
                                onCellValueChanged={this.cellValueChanged}
                                enableCellChangeFlash={true}
                                rowDragManaged={true}
                                animateRows={true}
                                defaultStatus={userProfileData.factoryType}
                                frameworkComponents={frameworkComponents}
                                getContextMenuItems={contextMenuItems}
                                isGroupOpenByDefault={true}
                                getRowHeight={getRowHeight}
                                headerHeight={60}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {newHistory && newHistory.length > 0 && (
                    <AdminHistorySection
                      displayHistoryModel={displayHistoryModel}
                      historyHeader={pageData.history || []}
                      historyData={newHistory}
                      toogleHistoryModel={this.toogleHistoryModel}
                      OpenHistoryRCModel={this.OpenHistoryRCModel}
                      userType={userProfileData.factoryType}
                      adminType={pageTitle}
                    />
                  )}
                </div>
              </div>
            )}
        </div>

        <HistoryReasonCodeModal
          displayHistoryRCModel={displayHistoryRCModel}
          historyHeader={pageData.history || []}
          historyRCListEntry={historyRCListEntry}
          toogleHistoryRCModel={this.toogleHistoryRCModel}
        />
      </div>
    );
  }
}

Admin.propTypes = {
  history: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  adminData: PropTypes.object,
  adminLoading: PropTypes.any,
  adminError: PropTypes.object,
  requestAdminData: PropTypes.func,
  updateAdminData: PropTypes.func,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  updatingAdminLoading: PropTypes.any,
  updatingAdminSuccess: PropTypes.any
};

const mapStateToProps = state => ({
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  adminData: getAdminData(state),
  adminLoading: getAdminLoading(state),
  adminError: getAdminError(state),

  updatingAdminLoading: getUpdatingAdminLoading(state),
  updatingAdminSuccess: getUpdatingAdminSuccess(state),
  name: selectLegacyUserName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state)
});

const mapDispatchToProps = dispatch => ({
  requestAdminData: status => {
    // Action for Making the status button as selected (Action required, In progress or completed)
    dispatch(selectStatusButton(status));
    // Get data to show on grid
    dispatch(createServiceAction('updateAdminData', 'loading')(false));
    dispatch(createServiceAction('updateAdminData', 'success')(false));
    dispatch(createServiceAction('adminData', 'requested')(status));
  },
  requestUserProfileData: () => {
    dispatch(createServiceAction('getUserProfile', 'requested')());
  },
  updateAdminData: (data, history) => {
    dispatch(
      createServiceAction('updateAdminData', 'submited')({ ...data, history })
    );
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
