import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';

import auth from './authReducer.js';
import user from './userReducer.js';
import userProfile from './userProfileReducer.js';
import navigation from './navigationReducer.js';
import offline from './offlineReducer.js';
import notifications from './notificationsReducer';
import dashboard from './dashboardReducer';
import admin from './adminReducer';
import appConfig from './appConfigReducer';
import userUpdates from './userUpdatesReducer';
import userNotify from './userNotifyReducer.js';
import userProfileSettings from './userProfileSettingsReducer.js';
import commentLogs from './commentsLogsReducer.js';
import growthDimension from './growthDimensionReducer.js';
import growthLever from './growthLeverReducer.js';
import leverAdmin from './leverAdminReducer.js';
import leverLogging from './leverLoggingReducer.js';
import lrcp from './lrcpReducer.js';
import growthLeverQuarter from './growthLeverQuarterReducer.js';
import growthLeverList from './growthLeverListReducer.js';

const rootReducer = combineReducers({
  routing,
  form,
  auth,
  user,
  navigation,
  offline,
  notifications,
  dashboard,
  admin,
  leverAdmin,
  leverLogging,
  lrcp,
  userProfile,
  appConfig,
  userUpdates,
  userNotify,
  userProfileSettings,
  commentLogs,
  growthDimension,
  growthLever,
  growthLeverQuarter,
  growthLeverList
});

export default rootReducer;
