import store from 'localforage';
import urlJoin from 'url-join';
import qs from 'qs';
import OktaAuth from '@okta/okta-auth-js';

import config from '../config.js';
import { oAuthConfig } from '../config/oAuthConfig.js';

const tokenKey = 'token';
const tokenStore = store.createInstance({
  name: 'tokens'
});

const tokenBase = {
  get isExpired() {
    return this.expiresAt * 1000 < Date.now();
  }
};

const makeToken = token => Object.assign(Object.create(tokenBase), token);
const exists = obj => obj !== undefined && obj !== null;

export function login() {
  const href = urlJoin(
    config.oAuth.authorization,
    `?${qs.stringify({
      ...config.oAuth,
      nonce: '1',
      state: '1'
    })}`
  );
  window.location.href = href;
}

export function logout() {
  return tokenStore.removeItem(tokenKey);
}

function parseRedirectToken(value) {
  if (!value || !value.length) return null;
  if (value[0] === '#') value = value.substring(1);

  const token = qs.parse(value);
  if (token.error) {
    const message = `Error logging in: ${token.error_description ||
      'Unknown Error'}`;
    throw new Error(message);
  }
  // Check that this is an oauth redirection, not a normal app route
  return token.access_token ? token : null;
}

function parseJwtPayload(payload) {
  return JSON.parse(window.atob(payload));
}

function unpackEncodedToken(token) {
  if (!token || !token.access_token) return null;

  const accessValues = token.access_token.split('.');
  const openIdValues = token.id_token.split('.');
  const accessClaims = parseJwtPayload(accessValues[1]);
  const openIdClaims = parseJwtPayload(openIdValues[1]);
  // The name of these values will depend on the token issuer
  // Ping and Okta have different values for scope and username
  return {
    accessToken: token.access_token,
    openIdToken: token.id_token,
    expiresIn: token.expires_in,
    expiresAt: accessClaims.exp,
    username: accessClaims.sub,
    email: openIdClaims.email,
    firstName: openIdClaims.given_name,
    lastName: openIdClaims.family_name,
    fullName: openIdClaims.name,
    scope: accessClaims.scope,
    legacyUsername: openIdClaims.legacy_username,
    group: openIdClaims.groups.map(value => {
      const grpInfoArr = value.split('.');
      let returnvalue = '';
      if (grpInfoArr[grpInfoArr.length - 1] === 'NLO') {
        returnvalue = 'NLO';
      } else if (
        grpInfoArr[grpInfoArr.length - 1] === 'Factory-Ext' ||
        grpInfoArr[grpInfoArr.length - 1] === 'Factory' ||
        grpInfoArr[grpInfoArr.length - 1] === 'FACTORY'
      ) {
        returnvalue = 'FACTORY';
      } else if (grpInfoArr[grpInfoArr.length - 1] === 'WHQ') {
        returnvalue = 'WHQ';
      }
      return returnvalue;
    })[0],
    groups: openIdClaims.groups
  };
}

function parseRenewedtoken(token) {
  if (
    !token ||
    !token[0] ||
    !token[1] ||
    !token[0].idToken ||
    !token[1].accessToken
  )
    return null;

  const accessValues = token[1].accessToken.split('.');
  const openIdValues = token[0].idToken.split('.');
  const accessClaims = parseJwtPayload(accessValues[1]);
  const openIdClaims = parseJwtPayload(openIdValues[1]);

  // Make sure the below object has all the keys present in return object of unpackEncodedToken() function
  return makeToken({
    accessToken: token[1].accessToken,
    openIdToken: token[0].idToken,
    expiresIn: '3600',
    expiresAt: token[0].expiresAt,
    username: accessClaims.sub,
    email: openIdClaims.email,
    firstName: openIdClaims.given_name,
    lastName: openIdClaims.family_name,
    fullName: openIdClaims.name,
    legacyUsername: openIdClaims.legacy_username,
    scope: accessClaims.scope,
    group: openIdClaims.groups.map(value => value.split('.')[3])[0],
    groups: openIdClaims.groups
  });
}

export function checkForToken() {
  // Parse the token
  return tokenStore
    .getItem(tokenKey)
    .then(() => {
      // .then(result => {
      //   if (exists(result)) return result;
      let token = parseRedirectToken(window.location.hash);
      if (token) {
        window.location.hash = '';

        token = unpackEncodedToken(token);
        return exists(token) ? tokenStore.setItem(tokenKey, token) : null;
      }
      return null;
    })
    .then(result => {
      if (!result) return;
      const token = makeToken(result);
      return token.isExpired ? logout().then(() => undefined) : token;
    });
}

export function renewToken() {
  const oktaAuth = new OktaAuth({
    issuer: oAuthConfig.issuer,
    clientId: oAuthConfig.clientId,
    redirectUri: config.oAuth.redirectUri
  });
  // Request for a new token
  return oktaAuth.token
    .getWithoutPrompt({
      responseType: ['id_token', 'token'],
      responseMode: 'fragment',
      scopes: ['profile', 'phone', 'email', 'address', 'openid']
    })
    .then(newToken => {
      const parsedToken = parseRenewedtoken(newToken);
      if (exists(parsedToken)) {
        // Set and return new token
        return tokenStore.setItem(tokenKey, parsedToken).then(result => result);
      }
    });
}
