import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { SingleInput } from '../index.js';

const buttonClass =
  'd-sm-ib ncss-brand u-uppercase pt1-sm pr3-sm pb1-sm pl3-sm';

export default class GridPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToPageno: 1
    };
    this.go2PageByNumber = this.go2PageByNumber.bind(this);
    this.go2Page = this.go2Page.bind(this);
  }

  componentDidMount() {
    const { pagination } = this.props;
    let thisCurrentpage = 1;
    // On page load, loading max available dashboard as default
    if (pagination) {
      if (
        Object.keys(pagination.back).length === 0 ||
        Object.keys(pagination.next).length === 0
      ) {
        if (Object.keys(pagination.next).length === 0) {
          thisCurrentpage = pagination.totalPages;
        }
      } else if (Object.keys(pagination.next).length > 0) {
        thisCurrentpage = Math.floor(
          pagination.next.anchor / pagination.next.count
        );
      }
    }
    this.setState({
      goToPageno: thisCurrentpage
    });
  }

  onPageNumberChange = event => {
    if (event.target) {
      this.setState({ goToPageno: event.target.value });
    }
  };

  go2PageByNumber = num => {
    const { goToPage, pagination } = this.props;
    const { goToPageno } = this.state;
    const pagecount = pagination.next.count || pagination.back.count;
    const gotoAction = { count: pagecount, anchor: 1 };
    if (num !== goToPageno) {
      if (goToPageno >= 1 && goToPageno <= pagination.totalPages) {
        gotoAction.anchor =
          gotoAction.count * goToPageno - gotoAction.count + 1;

        goToPage(gotoAction);
      } else {
        toast.error(
          `Please Enter number between 1  and  ${pagination.totalPages}`,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
      }
    }
  };

  go2Page = action => {
    const { goToPage } = this.props;
    goToPage(action);
  };

  render() {
    const { pagination, dataLength, view, gbdQuarter } = this.props;
    const { goToPageno } = this.state;

    const pagecount =
      pagination.next.count ||
      pagination.back.count ||
      pagination.totalResources;
    const lastcount = (pagination.totalPages - 1) * pagecount + 1;
    const firstPage = { count: pagecount, anchor: 1 };
    const lastPage = { count: pagecount, anchor: lastcount };
    let currentPage = 1;
    let recordStart = 1;
    let recordEnd = pagination.totalPages;
    // On page load, loading max available dashboard as default
    if (pagination) {
      if (
        Object.keys(pagination.back).length === 0 &&
        Object.keys(pagination.next).length === 0
      ) {
        recordStart = recordEnd - pagecount + 1;
      } else if (
        Object.keys(pagination.back).length === 0 ||
        Object.keys(pagination.next).length === 0
      ) {
        if (Object.keys(pagination.next).length === 0) {
          currentPage = pagination.totalPages;
        }
      } else if (Object.keys(pagination.next).length > 0) {
        currentPage = Math.floor(
          pagination.next.anchor / pagination.next.count
        );
      }
    }

    recordEnd = pagecount * currentPage;
    recordStart = recordEnd - pagecount + 1;
    if (recordEnd > pagination.totalResources) {
      recordEnd = pagination.totalResources;
    }
    const backLenth = Object.keys(pagination.back).length || 0;
    const nextLenth = Object.keys(pagination.next).length || 0;
    return (
      <div className="bg-white  pt2-sm">
        <div className="ncss-container">
          <div className="ncss-row">
            <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-3 fl-sm-l">
              {pagination.totalResources && dataLength && (
                <div>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      this.go2PageByNumber(currentPage);
                    }}
                  >
                    <div className="ta-sm-c  pt2-sm fl-sm-l">Goto </div>
                    <div
                      className="ta-sm-c    fl-sm-l"
                      style={{ width: '60px' }}
                    >
                      <SingleInput
                        inputType="number"
                        name="gotoPagebyNumber"
                        title=""
                        controlFunc={e => this.onPageNumberChange(e)}
                        className="description u-bold trf-data-text border-black fl-sm-l p2-sm  ml1-sm m1-sm u-full-width"
                        content={Number(goToPageno)}
                        disabled={pagination.totalPages <= 1}
                      />
                    </div>
                    <div className="ta-sm-c   pt1-sm pl2-sm fl-sm-l">
                      <button
                        type="submit"
                        className={`${buttonClass}  ncss-btn-black`}
                      >
                        <i className="fas fa-angle-right" />
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>

            <div className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-3 fl-sm-r">
              <button
                type="button"
                className={` mr1-sm ${buttonClass}  fl-sm-r ${
                  nextLenth > 0 ? ' ncss-btn-black' : ''
                }`}
                disabled={nextLenth === 0}
                onClick={() => this.go2Page(lastPage)}
              >
                <i className="fas fa-angle-double-right" />
              </button>
              <button
                type="button"
                className={` mr1-sm ${buttonClass}  fl-sm-r ${
                  nextLenth > 0 ? ' ncss-btn-black' : ''
                }`}
                disabled={nextLenth === 0}
                onClick={() => this.go2Page(pagination.next)}
              >
                <i className="fas fa-angle-right" />
              </button>
              <input
                type="text"
                className=" mr1-sm ta-sm-c  ncss-brand u-uppercase pt2-sm pr1-sm pb2-sm pl1-sm fl-sm-r"
                style={{ width: '30%' }}
                disabled
                value={` ${currentPage} / ${pagination.totalPages}`}
              />
              <button
                type="button"
                className={` mr1-sm fl-sm-r ${buttonClass}   ${
                  backLenth > 0 ? ' ncss-btn-black' : ''
                }`}
                onClick={() => this.go2Page(pagination.back)}
                disabled={backLenth === 0}
              >
                <i className="fas fa-angle-left" />
              </button>
              <button
                type="button"
                className={` mr1-sm fl-sm-r ${buttonClass}   ${
                  backLenth > 0 ? ' ncss-btn-black' : ''
                }`}
                onClick={() => this.go2Page(firstPage)}
                disabled={backLenth === 0}
              >
                <i className="fas fa-angle-double-left" />
              </button>
            </div>
            <div className="ncss-col-sm-12 ncss-col-md-12  ncss-col-lg-6 ta-sm-c ">
              {pagination.totalResources && dataLength && !gbdQuarter && (
                <div>
                  {view === 'upload' ? 'Downloading ' : 'In page '}
                  {dataLength} records from {recordStart} to {recordEnd} of
                  total {pagination.totalResources}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
GridPagination.propTypes = {
  pagination: PropTypes.any,
  goToPage: PropTypes.func,
  dataLength: PropTypes.any,
  view: PropTypes.any
};
