import { fromJS } from 'immutable';

const defaultState = fromJS({});

export default function compose(state = defaultState, action) {
  state = fromJS(state); // Force immutable in case of rehydration

  switch (action.type) {
    default:
      return state;
  }
}
