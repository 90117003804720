import { toast } from 'react-toastify';

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';
import { all, put, takeEvery, call, delay } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    const chunkSplitter = (list, chunkSize) => {
      const retVal = [...Array(Math.ceil(list.length / chunkSize))].map(_ =>
        list.splice(0, chunkSize)
      );
      return retVal;
    };
    const toastProps = {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    };
    let successMessage = 'Save Successful ';
    let failureMessage = 'Unable to save Changes/ upload, try again ';
    if (action.data.type === 'file Update') {
      successMessage = 'Your file upload was successful';
    }
    let partialSuccessMessage = 'Partially Save Successful :';
    let partialFailureMessage = 'Unable to save Partially : ';
    let partialSave = false;
    yield put(createServiceAction(serviceType, 'loading')());
    const putDashDataUrl = `${apiConfig.updateGrowthLeverData.endPointUrl}${action.data.dashboard}`;
    let res = {
      status: [],
      json: {}
    };
    const data = action.data.data;
    const thresholdCounts = action.data.thresholdCounts || {};
    if (
      thresholdCounts &&
      Object.keys(thresholdCounts).length > 0 &&
      thresholdCounts.passed !== thresholdCounts.all
    ) {
      partialSave = true;
      partialSuccessMessage +=
        thresholdCounts.passed + ' of ' + thresholdCounts.all;
      partialFailureMessage +=
        thresholdCounts.passed + ' of ' + thresholdCounts.all;
    }
    if (action.data.data.length > 1000) {
      const finalPayload = chunkSplitter(data, 500);
      const mutliResponses = yield all(
        finalPayload.map(payload =>
          call(putRequest, putDashDataUrl, payload, true)
        )
      );
      const retObj = {
        failed: [],
        updated: [],
        errors: []
      };
      mutliResponses.forEach(async obj => {
        res.status.push(obj.status);
        const { failed, updated, errors } = await obj.json();
        retObj.failed.push(...failed);
        retObj.updated.push(...updated);
        retObj.errors.push(...errors);
      });
      yield delay(500);
      res.json = retObj;
    } else {
      res = yield call(putRequest, putDashDataUrl, action.data.data, true);
    }

    const fMessage = partialSave ? partialFailureMessage : failureMessage;
    if (res.status === 201 || res.status.includes(201)) {
      partialSave
        ? toast.warning(partialSuccessMessage, toastProps)
        : toast.success(successMessage, toastProps);
    } else if (res.status === 400 && action.data.data.length === 0) {
      toast.error(fMessage, toastProps);
    } else {
      toast.error(fMessage, toastProps);
    }
    if (Array.isArray(res.status)) {
      res.outputData = res.json;
    } else {
      res.outputData = yield res.json();
    }
    yield put(createServiceAction(serviceType, 'success')(res));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateGrowthLeverDataSagas() {
  const serviceType = 'growthLeverDataUpdate';
  yield takeEvery(
    createServiceAction(serviceType, 'submited').type,
    listener,
    serviceType
  );
}

export default updateGrowthLeverDataSagas;
