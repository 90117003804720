/* eslint-disable max-statements */
/* eslint-disable no-restricted-globals */
import * as moment from 'moment';
import { divisionCodes } from '../constants/appConstants';

export const stratDeltaCalculator = (capacity, weeklyCapacityQuantity) => {
  let deltaPercent = '';
  if (
    capacity === 0 ||
    capacity === '0' ||
    (capacity && Number(capacity) > 0)
  ) {
    if (weeklyCapacityQuantity > 0) {
      deltaPercent =
        ((Number(capacity) - Number(weeklyCapacityQuantity)) /
          Number(weeklyCapacityQuantity)) *
        100;
    } else if (weeklyCapacityQuantity === 0) {
      deltaPercent = 100;
    }
    if (!!(deltaPercent % 1)) {
      return deltaPercent.toFixed(1);
    }
  }
  return `${deltaPercent}`;
};

export const holidayCalculator = (field, paramsdata) => {
  const {
    workingDays,
    numberOfHolidays,
    maxAvailableCapacity,
    maxAvailableUnitsConfirmed,
    approvedMaxCapacity,
    shortHorizonCapacity,
    shortHorizonUnitsConfirmed,
    approvedRevisedCapacity,
    FctyMaxCapacity
  } = paramsdata;
  let retVal = '';
  const holidays = numberOfHolidays || 0;
  const availableWorkingDays =
    workingDays - holidays > 0 ? workingDays - holidays : 0;
  let field2calculate = '';

  if (workingDays !== 0) {
    switch (field) {
      case 'FctyStratHldCpty':
        field2calculate = maxAvailableCapacity;
        break;
      case 'ConfrndStratHldCpty':
        field2calculate = maxAvailableUnitsConfirmed;
        break;
      case 'AprvdStratHldCpty':
        field2calculate = approvedMaxCapacity;
        break;
      case 'FctyRvsdHldCpty':
        field2calculate = shortHorizonCapacity;
        break;
      case 'ConfrndRvsdHldCpty':
        field2calculate = shortHorizonUnitsConfirmed;
        break;
      case 'AprvdRvsdHldCpty':
        field2calculate = approvedRevisedCapacity;
        break;
      case 'FctyMaxHldCpty':
        field2calculate = FctyMaxCapacity;
        break;

      default:
        field2calculate = '';
    }
    if (
      field2calculate === 0 ||
      field2calculate === '0' ||
      (field2calculate && Number(field2calculate) > 0)
    ) {
      retVal = (field2calculate / workingDays) * availableWorkingDays;
      if (retVal % 1 !== 0) {
        retVal = Number((Math.round(retVal * 100) / 100).toFixed(2)); // retVal.toFixed(1);
      }
    }
  }
  return retVal;
};

export const numberFormatter = value => {
  if (!!(value % 1)) {
    const na = String(value).split('.');
    const n2 = na[1] ? `.${na[1]}` : '';
    return `${na[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${n2}`;
  }
  return value.toLocaleString('en-US');
};

// eslint-disable-next-line complexity
const generateColumnPreference = (item, role, dashboard, appConfig) => {
  const userFacList = window.sessionStorage.getItem('factorylist') || '{}';
  const userFactory = JSON.parse(userFacList);
  const divisionCode = divisionCodes[userFactory.factoryType];
  let columnPreference = {
    headerName: item.headerName,
    field: item.field,
    hide: !item.displayUser,

    width: 150,
    suppressMenu: true,
    icons: {
      sortNone: '<i class="fas fa-sort"></i>',
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    editable: false
  };

  if (item.src === 'GBD' && role === 'FACTORY') {
    columnPreference.editable = true;
  }
  columnPreference.cellClass = params => {
    const { partialQuarter, partialQuarterPrev } = params.data;
    if (partialQuarter || partialQuarterPrev) {
      return 'GreyCloud';
    }
  };
  if (role === 'WHQ') {
    if (divisionCode === 20 && item.field === 'targetCpty') {
      columnPreference.editable = true;
      columnPreference.cellClass = 'numberEditabeCol';
    }
    if (divisionCode === 10 && item.field === 'targetHldCpty') {
      columnPreference.editable = true;
      // columnPreference.cellClass = 'numberEditabeCol';
      columnPreference.cellClass = 'editableCol';
    }
  }

  if (item.field === 'weekStartDate') {
    columnPreference.hide = true;
  }
  if (item.src === 'PMD') {
    columnPreference.cellClass = 'GreyCloud';
    columnPreference.headerClass = 'gdGridHeadLabelPinned';
  } else if (item.src === 'CapVis') {
    columnPreference.headerClass = 'gdGridHeadLabelPinned';
  } else if (item.src === 'TargetCap') {
    columnPreference.headerClass = 'gdGridHeadLabelCapacity';
  } else if (item.src === 'GBD') {
    columnPreference.headerClass = 'gdGridRcGbEven';
    columnPreference.width = 220;
  }
  if (columnPreference.editable) {
    let tempCellClassObj = {};
    const checkValueExistance = value => (value || value === 0 ? true : false);
    //**editableCol is the css class name */

    let editableColName = '';
    if (item.field.endsWith('Cap') || item.field.endsWith('Cost')) {
      editableColName = 'numberEditabeCol';
    } else if (item.field.endsWith('ValidityDate')) {
      editableColName = 'dateEditabeCol';
    } else {
      editableColName = 'editableCol';
    }
    if (item.field.startsWith('Gbd')) {
      tempCellClassObj[editableColName] = params =>
        checkValueExistance(params.node.data.GbdMaxCapVariance);
      columnPreference.editable = params =>
        checkValueExistance(params.node.data.GbdMaxCapVariance);
    }
    if (item.field.startsWith('Rvsd')) {
      tempCellClassObj[editableColName] = params =>
        checkValueExistance(params.node.data.RvsdQMaxCapVariance);
      columnPreference.editable = params =>
        checkValueExistance(params.node.data.RvsdQMaxCapVariance);
    }
    if (item.field.startsWith('StrQ')) {
      tempCellClassObj[editableColName] = params =>
        checkValueExistance(params.node.data.StrQMaxCapVariance);
      columnPreference.editable = params =>
        checkValueExistance(params.node.data.StrQMaxCapVariance);
    }
    if (item.field.startsWith('MaxQ')) {
      tempCellClassObj[editableColName] = params =>
        checkValueExistance(params.node.data.MaxQMaxCapVariance);
      columnPreference.editable = params =>
        checkValueExistance(params.node.data.MaxQMaxCapVariance);
    }
    if (item.field.startsWith('CapQ')) {
      tempCellClassObj[editableColName] = params =>
        checkValueExistance(params.node.data.CapQMaxCapVariance);
      columnPreference.editable = params =>
        checkValueExistance(params.node.data.CapQMaxCapVariance);
    }

    columnPreference.cellClassRules = tempCellClassObj;
  }

  if (item.field === 'productionQuarter') {
    // ;
    columnPreference.cellRenderer = 'LeversCustomCell';
    /**
   *   columnPreference.valueFormatter = params => {
      const yearIs = String(params.value) || '';
      if (params.value) {
        return `${yearIs.substring(0, 4)}-Q${yearIs.substring(4, 5)}`;
      }
      return params.value;
    };
   */
  }

  if (
    [
      'factoryCode',
      'weekStartDate',
      'capacityTypeCode',
      'productionQuarter'
    ].includes(item.field)
  ) {
    columnPreference.pinned = 'left';
  }

  if (divisionCode === 20 && item.field === 'targetHldCpty') {
    columnPreference.valueGetter = params => {
      const { targetCpty, workingDays, numberOfHolidays } = params.data;
      const holidays = numberOfHolidays || 0;
      const availableWorkingDays =
        workingDays - holidays > 0 ? workingDays - holidays : 0;
      let retVal;
      retVal = (targetCpty / workingDays) * availableWorkingDays;
      if (retVal % 1 !== 0) {
        retVal = Number((Math.round(retVal * 100) / 100).toFixed(2)); // retVal.toFixed(1);
      }
      if (
        workingDays !== 0 &&
        (targetCpty === 0 || (targetCpty && targetCpty.toString().length > 0))
      ) {
        return retVal;
      }
      return '';
    };
  }
  if (divisionCode === 10 && item.field === 'targetCpty') {
    columnPreference.valueGetter = params => {
      const { targetHldCpty, workingDays, numberOfHolidays } = params.data;
      const holidays = numberOfHolidays || 0;
      const availableWorkingDays =
        workingDays - holidays > 0 ? workingDays - holidays : 0;
      let retVal;
      retVal = (targetHldCpty / availableWorkingDays) * workingDays;
      if (retVal % 1 !== 0) {
        // retVal = Number((Math.round(retVal * 100) / 100).toFixed(2)); // retVal.toFixed(1);
        retVal = Math.round(retVal);
      }
      if (
        workingDays !== 0 &&
        (targetHldCpty === 0 ||
          (targetHldCpty && targetHldCpty.toString().length > 0))
      ) {
        return retVal;
      }
      return '';
    };
  }
  /* WILL BE CALCULATED ON DATA GENERATION PART
  if (item.field === 'GbdMaxCapVariance') {
    columnPreference.valueGetter = params => {
      const { FctyMaxCapacity, maxAvailableCapacity } = params.data;
      return FctyMaxCapacity && maxAvailableCapacity
        ? Number(FctyMaxCapacity) - Number(maxAvailableCapacity)
        : '';
    };
  }
  */

  // identify the grid column filter type  and update with ag grid value in filtertype
  // identify the grid column field type  and update with ag grid value in editorType
  if (item.filter === 'TEXT') {
    columnPreference.filter = 'agTextColumnFilter';

    if (item.field.endsWith('Comments')) {
      columnPreference.cellEditor = 'agLargeTextCellEditor';
    }
  } else if (item.filter === 'NUMBER') {
    columnPreference.filter = 'agNumberColumnFilter';
    columnPreference.cellEditor = 'NumericCellEditor';
    if (columnPreference.editable) {
      //  columnPreference.cellClass = 'numberCol';
      // columnPreference.cellClass = 'numberEditabeCol';
    }

    const AdjGBDList = [
      'targetHldCpty',
      'targetCpty',
      'adjustedCapacityQuantity',
      'FctyMaxHldCpty',
      'FctyRvsdHldCpty',
      'FctyStratHldCpty',
      'GbdMaxCapAdjVariance',
      'StrQMaxCapAdjVariance',
      'RvsdQMaxCapAdjVariance',
      'MaxQMaxCapAdjVariance'
    ];
    const hideNumberFormat = [
      'maxAvailableDelta',
      'shortHorizonDelta',
      'productionQuarter'
    ];

    if (hideNumberFormat.indexOf(item.field) === -1) {
      columnPreference.valueFormatter = params => {
        if (params.value && !isNaN(params.value)) {
          AdjGBDList.forEach(adjField => {
            if (params.data[adjField] && params.data[adjField] % 1 !== 0) {
              params.data[adjField] =
                Math.round(
                  (Number(params.data[adjField]) + Number.EPSILON) * 100
                ) / 100;
              // params.data[adjField] = Number(params.data[adjField]).toFixed(2);
            }
          });

          return numberFormatter(params.value);
        }

        return params.value;
      };
    }
  }
  if (
    ['factoryCode', 'capacityTypeCode', 'productionQuarter'].includes(
      item.field
    )
  ) {
    columnPreference.filter = 'agSetColumnFilter';
    columnPreference.filterParams = { selectAllOnMiniFilter: true };
  }
  if (item.filter === 'DATE') {
    columnPreference.filter = 'agDateColumnFilter';
    //columnPreference.cellClass = 'dateCol';
    /**  columnPreference.valueFormatter = params =>
      moment(params.value).format('MM/DD/YYYY'); */
    columnPreference.filterParams = {
      comparator(filterLocalDateAtMidnight, cellValue) {
        const dateAsString = cellValue;
        if (dateAsString == null) return -1;
        const momentdate = moment(cellValue).format('MM/DD/YYYY');
        const cellDate = Date.parse(momentdate);
        const filterdate = Date.parse(filterLocalDateAtMidnight);
        if (filterdate === cellDate) {
          return 0;
        }
        if (cellDate < filterdate) {
          return -1;
        }
        if (cellDate > filterdate) {
          return 1;
        }
      },
      browserDatePicker: true
    };
  }

  if (item.field === 'id' || item.field === 'Id' || item.field === 'wid') {
    columnPreference.width = 1;
    columnPreference.cellStyle = { width: 10, opacity: 0, padding: 0 };
    columnPreference.lockVisible = true;
  }
  return columnPreference;
};

const generateDimensionPreference = (
  dimension,
  role,
  dashboard,
  appConfig,
  categoryName
) => {
  let dimensionPreference = {};
  let levers = [];
  if (dimension.children && dimension.children.length !== 0) {
    dimensionPreference = generateColumnPreference(
      dimension,
      role,
      dashboard,
      appConfig
    );
    dimension.children.forEach((lever, i) => {
      let leverPreference = generateColumnPreference(
        lever,
        role,
        dashboard,
        appConfig
      );
      if (i > 0) {
        leverPreference.columnGroupShow = 'closed';
      }
      leverPreference.dimension = dimension.headerName;
      leverPreference.category = categoryName;
      levers.push(leverPreference);
    });
    dimensionPreference.children = levers;
  } else {
    dimensionPreference = generateColumnPreference(
      dimension,
      role,
      dashboard,
      appConfig
    );
  }
  return dimensionPreference;
};

const generateCategoryPreference = (category, role, dashboard, appConfig) => {
  let categoryPreference = {};
  let dimensions = [];
  if (category.children && category.children.length !== 0) {
    categoryPreference = generateColumnPreference(
      category,
      role,
      dashboard,
      appConfig
    );
    category.children.forEach((dimension, i) => {
      let dimensionPreference = generateDimensionPreference(
        dimension,
        role,
        dashboard,
        appConfig,
        category.headerName
      );
      if (i > 0) {
        dimensionPreference.columnGroupShow = 'closed';
      }
      dimensions.push(dimensionPreference);
    });
    categoryPreference.children = dimensions;
  } else {
    categoryPreference = generateColumnPreference(
      category,
      role,
      dashboard,
      appConfig
    );
  }
  return categoryPreference;
};

export const growthDimensionGridConfigure = data => {
  // Function to create AG grid based header based on Colum API values
  const { reportColumns, role, dashboard, appConfig } = data;

  const gridConfig = [];

  if (reportColumns) {
    reportColumns.push({
      displayUser: true,
      src: 'GRID',
      editable: false,
      headerName: 'PQID',
      field: 'id'
    });
    reportColumns.push({
      displayUser: true,
      src: 'GRID',
      editable: false,
      headerName: 'WeekID',
      field: 'wid'
    });
    reportColumns.forEach(category => {
      // setting providing width based on header character length -
      // update default header column properties for each

      let columnPreference = generateCategoryPreference(
        category,
        role,
        dashboard,
        appConfig
      );
      gridConfig.push(columnPreference);
    });
  }
  return gridConfig;
};
