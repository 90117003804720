import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-float-tooltip';
import HighLightText from '../HighLightText.js';

export const CheckBoxItem = ({
  uid,
  option,
  disabledAll,
  onCheckChange,
  parent,
  name,
  searchText
}) => {
  return (
    <div className="ncss-checkbox-container">
      <input
        type="checkbox"
        className="ncss-checkbox"
        id={`CheckBox-${uid}-${option.ukey}`}
        disabled={disabledAll}
        checked={option.checked}
        onChange={e => onCheckChange(parent, name, option.name, option.parent)}
      />
      <label
        className="ncss-label pl6-sm fs- pushtooltip"
        htmlFor={`CheckBox-${uid}-${option.ukey}`}
      >
        {option.code ? (
          <Tooltip
            tooltipElement={() => (
              <div className="toolTipElm c-f">{option.code}</div>
            )}
          >
            {searchText && searchText.length >= 0 ? (
              <HighLightText text={option.name} highlight={searchText} />
            ) : (
              option.name
            )}
          </Tooltip>
        ) : (
          <span>
            {searchText && searchText.length >= 0 ? (
              <HighLightText text={option.name} highlight={searchText} />
            ) : (
              option.name
            )}
          </span>
        )}
      </label>
    </div>
  );
};

CheckBoxItem.propTypes = {
  response: PropTypes.any
};
export default CheckBoxItem;
