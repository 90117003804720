import { eventChannel, END } from 'redux-saga';
import storage from 'localforage';

import {
  startAuthCheck,
  finishAuthCheck,
  setAuthCheckError,
  setUserLogin
} from '../actions/authActions.js';
import {
  hasAuthCheckStarted,
  selectUserDetails,
  selectUserProfile
} from '../reducers/authReducer.js';
import { setGlobalHeader } from '../util/http';
import history from '../util/history.js';
import * as oAuth from '../services/oAuth.js';
import { isOffline } from '../reducers/offlineReducer.js';
import { oAuthConfig } from '../config/oAuthConfig.js';

import { call, put, takeEvery, cancel, select } from 'redux-saga/effects';

const locationKey = 'location';
const routeStore = storage.createInstance({ name: 'route-store ' });

const activityKey = 'idle';
const activityStore = storage.createInstance({ name: 'activity-store' });

/*
 * Methods inside routeStore should be wrapped inside a function which returns it, as routestore methods
 * are promises and saga call() requires a function which returns a promise object.
 */
const routeStoreObj = {
  getItem: key => routeStore.getItem(key),
  setItem: (key, val) => routeStore.setItem(key, val),
  removeItem: key => routeStore.removeItem(key)
};

const activityStoreObj = {
  getItem: key => activityStore.getItem(key),
  setItem: (key, val) => activityStore.setItem(key, val)
};

/*
 * Function for getting the last activity time of user
 */
function idleChecker() {
  // Store the last user active time
  const setlastIdleTime = () => {
    activityStore.setItem(activityKey, Date.now());
  };

  // Events that user activity needs to be monitored
  window.onload = setlastIdleTime;
  window.onmousemove = setlastIdleTime;
  window.onmousedown = setlastIdleTime;
  window.ontouchstart = setlastIdleTime;
  window.onclick = setlastIdleTime;
  window.onkeypress = setlastIdleTime;
  window.addEventListener('scroll', setlastIdleTime, true);
}

/*
 * Function for running setinterval method in saga using eventchannel
 */
function watchForTokenExpiration(tokenWithallowedIdleTime) {
  const { token } = tokenWithallowedIdleTime;
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter(tokenWithallowedIdleTime);
      // End setinterval execution when token gets expired
      if (token.isExpired) {
        emitter(END);
      }
    }, 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}

function* loginUser() {
  const state = yield select();
  // state.routing.location will contain pathname key having value of the route to be redirected after login
  // And state key containing the state variable that needs to be passed with route.
  // eg:- { pathname:'/test-details', state: { trfId:123 } }
  yield call(routeStoreObj.setItem, locationKey, state.routing.location);
  yield call(oAuth.login);
}

export function* logoutUser(action) {
  try {
    const { skipRedirect } = action.payload;
    yield call(oAuth.logout);
    yield call(routeStoreObj.removeItem, locationKey);
    yield put(setUserLogin(null));
    if (!skipRedirect) {
      history.push('/');
    }
  } catch (err) {
    yield put(setAuthCheckError(err));
  }
}

export function* initializeUserAuth(action) {
  try {
    yield call(idleChecker);
    const state = yield select();
    // NB: if action.renewedToken has value means the initializeUserAuth is called for
    // refreshing the token as part of Silent authentication
    // Dispatch startAuthCheck actions for all scenarious other than token refresh
    if (!action.renewedToken) {
      const hasAuthCheckStartedFlag = yield call(hasAuthCheckStarted, state);
      if (!hasAuthCheckStartedFlag) {
        yield put(startAuthCheck());
      } else {
        yield cancel();
      }
    }
    // Token value can be obtained from action payload for token refresh (silent authentication)
    // or by calling oAuth.checkForToken on normal autherntication flow
    const loginToken = action.renewedToken
      ? action.renewedToken
      : yield call(oAuth.checkForToken);
    if (!loginToken) {
      if (isOffline(state)) {
        yield put(finishAuthCheck());
      } else {
        yield call(loginUser);
      }
    } else {
      yield call(
        setGlobalHeader,
        'Authorization',
        `Bearer ${loginToken.accessToken}`
      );
      const channel = yield call(watchForTokenExpiration, {
        token: loginToken,
        allowedIdleTime: oAuthConfig.allowedIdleTime
      });
      yield takeEvery(channel, function* tokenExpirationWatcher(
        tokenWithallowedIdleTime
      ) {
        const { token, allowedIdleTime } = tokenWithallowedIdleTime;
        if (token.isExpired) {
          const lastActiveTime = yield call(
            activityStoreObj.getItem,
            activityKey
          );
          // if last active time is 30 mins ago (allowedIdleTime), then logout user otherwise refresh session
          if (Date.now() - lastActiveTime <= allowedIdleTime) {
            const renewedToken = yield call(oAuth.renewToken);
            yield put({ type: 'AUTH_CHECK_REQUESTED', renewedToken });
          } else {
            yield call(logoutUser, { payload: { skipRedirect: true } });
          }
        }
      });

      yield put(setUserLogin(loginToken));
      // Redirect user after OKTA authentication
      const userDetails = yield select(selectUserDetails);
      const userProfile = yield select(selectUserProfile);
      let createUserRoute = '';

      if (!userDetails && !userProfile) {
        createUserRoute = '/error';
      }
      // Redirect user after OKTA authentication
      const previousRoute = yield call(routeStoreObj.getItem, locationKey);
      if (previousRoute) {
        // yield call(history.replace, previousRoute);
        yield call(history.replace, createUserRoute || previousRoute);
        yield call(routeStoreObj.removeItem, locationKey);
      } else if (createUserRoute) {
        yield call(history.push, createUserRoute);
      }
      yield put(finishAuthCheck());
    }
  } catch (err) {
    yield put(setAuthCheckError(err));
  }
}

/*
  Starts initializeUserAuth when `AUTH_CHECK_REQUESTED` action is dispatched.
*/
function* authSagas() {
  yield takeEvery('AUTH_CHECK_REQUESTED', initializeUserAuth);
  yield takeEvery('LOGIN_USER_REQUESTED', loginUser);
  yield takeEvery('LOGOUT_USER_REQUESTED', logoutUser);
}

export default authSagas;
