import React from 'react';

const ProgressBar = props => {
  const { bgcolor, completed, total } = props;

  // let percentageFixed = completed ? completed.toFixed(2) : '';
  let barPercentage;
  if (completed < 0 && total < 0) {
    barPercentage = 101;
  } else if (completed < 0 && total > 0) {
    barPercentage = 0;
  } else if (completed > 100) {
    barPercentage = 101;
  } else {
    barPercentage = completed;
  }
  const containerStyles = {
    height: 20,
    backgroundColor: '#e0e0de',
    margin: 0,
    borderRadius: '5px'
  };

  const fillerStyles = {
    height: '100%',
    width: `${barPercentage}%`,
    backgroundColor: bgcolor,
    borderRadius: '5px',
    textAlign: 'right',
    transition: '1s ease',
    transitionDelay: '0.25s'
  };

  // const labelStyles = {
  //   padding: 5,
  //   color: 'white',
  //   fontWeight: 'bold'
  // };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {/* <span style={labelStyles}>{`${percentageFixed}`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;
