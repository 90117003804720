export const AG_GRID_LICENSE_KEY =
  'CompanyName=Insight_on_behalf_of_Nike, Inc.,LicensedApplication=RSL Capacity Visibility,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-012577,ExpiryDate=3_March_2022_[v2]_MTY0NjI2NTYwMDAwMA==9e105cdf20bade7c49643e83ff8d5358';

export const landingOptions = [
  {
    name: 'Strategic Capacity View',
    value: 'strategic',
    key: 'strategic',
    label: 'strategic',
    url: '/dashboard/strategicCapacity',
    id: 10001
  },

  {
    name: 'Revised  Capacity View',
    value: 'revised',
    key: 'revised',
    label: 'revised',
    url: '/dashboard/revisedCapacity',
    id: 10002
  }
];

export const divisionCodes = {
  AP: 10,
  FW: 20
};

export const adminThreshholdLimits = {
  revisedThresholdIncrease: 100,
  revisedThresholdDecrease: 100,
  strategicThresholdIncrease: 100,
  strategicThresholdDecrease: 100
};
