import * as moment from 'moment';
import _ from 'lodash';
import { msgConstants } from '../constants/msgConstants.js';

// for search form validation
export const searchValidator = (searchGroups, searchParams) => {
  const searchStatus = { status: true, message: '' };
  let GroupAndCode = 0;
  searchGroups.forEach(gridItem => {
    if (gridItem.selected.length === 0) {
      GroupAndCode += 1;
    }
    if (GroupAndCode > 1) {
      searchStatus.status = false;
      searchStatus.message += msgConstants.invalidSearchInput;
    }
  });

  searchParams.forEach(gridItem => {
    if (gridItem.required && gridItem.value.length === 0) {
      searchStatus.status = false;
      searchStatus.message += ` ${gridItem.name} is mandatory. `;
    }
  });
  const startDate =
    searchParams.find(o => o.field === 'startWeekDate').value || '';
  const endDate = searchParams.find(o => o.field === 'endWeekDate').value || '';
  // start date should be lesser than end date
  if (startDate.length > 0 && endDate.length > 0 && startDate > endDate) {
    searchStatus.status = false;
    searchStatus.message +=
      ' Start Date is greater that End date. Please choose valid date range !';
  }

  return searchStatus;
};

// for ag grid custom footer download
export const fetchAllQueryValue = (gridItem, ifAll) => {
  let allSelVal = '';
  let ifAllSel = false;
  if (gridItem.selected && gridItem.selected.length > 0) {
    gridItem.selected.forEach(v => {
      allSelVal += `${allSelVal ? ',' : ''}${v.value}`;
      if (v.name === 'ALL' && gridItem.field === 'factoryCode') {
        ifAllSel = true;
      }
    });
  } else if (gridItem.selected && gridItem.selected.key) {
    allSelVal += `${allSelVal ? ',' : ''}${gridItem.selected.value}`;
  } else {
    ifAllSel = false;
  }
  if (
    allSelVal.length === 0 &&
    (ifAllSel || ifAll) &&
    gridItem &&
    gridItem.options &&
    gridItem.options.length
  ) {
    allSelVal = '';
    gridItem.options.forEach(v => {
      allSelVal += `${allSelVal ? ',' : ''}${v.value}`;
    });
  }
  return allSelVal;
};
// for search post validation frame query
export const searchQueryFrame = (
  num,
  searchGroups,
  searchParams,
  gridPagination,
  recCount,
  revisedWeeks
) => {
  const count = recCount || 104;
  let allString = '';
  const factCode = searchGroups.find(o => o.field === 'factoryCode');
  const returFactoryCodeValue = fetchAllQueryValue(factCode, true) || '';

  if (num === 1) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCodes=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    } else {
      const factGrp = searchGroups.find(o => o.field === 'factoryGroup');
      const returFactoryGrpValue = fetchAllQueryValue(factGrp, true) || '';
      const updateVal = `factoryGroup=${returFactoryGrpValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
  } else if (num === 2) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCode=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
    if (searchParams && searchParams.length > 0) {
      searchParams.forEach(gridItem => {
        let returValue = '';
        switch (gridItem.field) {
          case 'capacityTypeCode':
            returValue = fetchAllQueryValue(gridItem, false);
            break;
          case 'startWeekDate':
          case 'endWeekDate':
            returValue = gridItem.value
              ? `${gridItem.field}=${moment(new Date(gridItem.value)).format(
                  'MM/DD/YYYY'
                )}`
              : '';
            break;
          default:
            returValue = '';
        }
        if (returValue) {
          const updateVal = `${gridItem.field}=${returValue}`;
          allString += allString ? `&${updateVal}` : updateVal;
        }
      });
    }

    if (Object.keys(gridPagination).length > 0) {
      const queryString = Object.keys(gridPagination)
        .map(key => `${key}=${gridPagination[key]}`)
        .join('&');
      allString += `${allString ? '&' : '?'}${queryString}`;
    } else {
      allString += `${allString ? '&' : '?'}count=${count}&anchor=1`;
    }
    if (revisedWeeks >= 0) {
      allString += `${allString ? '&' : '?'}boundaryWeek=${revisedWeeks}`;
    }
  }

  return allString;
};

// for ag grid custom footer download
export const removeAllFromOptions = arr => {
  arr.forEach(item => {
    if (item.options) {
      const foundIndex = item.options.findIndex(i => i.name === 'ALL');
      if (foundIndex >= 0) {
        item.options.splice(foundIndex, 1);
      }
    }
    if (item.backOptions) {
      const foundIndex = item.backOptions.findIndex(i => i.name === 'ALL');
      if (foundIndex >= 0) {
        item.options.splice(foundIndex, 1);
      }
    }
  });
  return arr;
};

export const getFactoryGroup = (item, newPath, userProfileData, selected) => {
  const allFG = [
    {
      name: 'All Factory Groups',
      value: '',
      key: 'AllFactoryGroups',
      label: 'All Factory Groups'
    }
  ];
  if (newPath.includes('levers-summary') || newPath.includes('lever-quarter')) {
    //this if never applies
    item.options = [...userProfileData.factoryGroups] || [];
    item.backOptions = [...userProfileData.factoryGroups] || [];
  } else {
    item.options = [...allFG, ...userProfileData.factoryGroups] || [];
    item.backOptions = [...allFG, ...userProfileData.factoryGroups] || [];
  }
  if (newPath.includes('lever-quarter')) {
    item.disabled = true;
  } else {
    item.disabled = false;
  }
  let itemSelected = item.selected ? item.selected : {};
  let urlSelected =
    selected && item.options ? item.options.find(x => x.name === selected) : {};
  if (
    urlSelected &&
    Object.keys(urlSelected).length > 0 &&
    JSON.stringify(urlSelected) !== JSON.stringify(itemSelected)
  ) {
    item.selected = urlSelected;
  }
  if (
    !urlSelected ||
    (Object.keys(urlSelected).length === 0 &&
      Object.keys(itemSelected).length === 0)
  ) {
    item.selected = item.options[0];
  }

  return item;
};

export const getFactoryCodes = (
  item,
  newPath,
  userProfileData,
  selected,
  selectedFG
) => {
  const allFG = [
    {
      name: 'All Factory Codes',
      value: '',
      key: 'AllFactoryCodes',
      label: 'All Factory Codes'
    }
  ];
  let factoryCodes = userProfileData.factoryCodes;
  let filteredArr = factoryCodes.filter(x => x.name !== 'ALL');
  item.backOptions = factoryCodes || [];
  let sortedArr = _.sortBy(filteredArr, 'name');

  if (newPath.includes('levers') || newPath.includes('lever-quarter')) {
    item.required = true;
  } else {
    item.required = false;
  }
  if (!item.options || item.options.length === 0) {
    item.options = [...allFG, ...sortedArr];
  }
  if (newPath.includes('lever-quarter')) {
    item.disabled = true;
  } else {
    item.disabled = false;
  }

  if (selectedFG) {
    let FGoptions = item.backOptions.filter(x => x.group === selectedFG);
    if (FGoptions && FGoptions.length > 0) {
      const selFG = [
        {
          name: `All ${selectedFG}`,
          value: '', //selectedFG
          key: `All${selectedFG}`,
          label: `All ${selectedFG}`,
          group: selectedFG
        }
      ];
      item.options = [...selFG, ...FGoptions];
    }
  }

  let itemSelected = item.selected ? item.selected : {};
  let urlSelected =
    selected && item.options ? item.options.find(x => x.name === selected) : {};
  if (
    urlSelected &&
    Object.keys(urlSelected).length > 0 &&
    JSON.stringify(urlSelected) !== JSON.stringify(itemSelected)
  ) {
    item.selected = urlSelected;
  }
  if (
    !urlSelected ||
    (Object.keys(urlSelected).length === 0 &&
      Object.keys(itemSelected).length === 0)
  ) {
    item.selected = item.options[0];
  }

  return item;
};
export const getDatesRange = (
  newPath,
  revisedHorizonWeeks,
  rvsdProdQtrList,
  stratProdQtrList
) => {
  const range = {
    fromMonth: '',
    toMonth: ''
  };

  const today = new Date();

  const startDate = moment(today)
    .startOf('week')
    .add(1, 'day');
  const endDate = moment(today).add(revisedHorizonWeeks, 'weeks');
  const weekEnd = moment(endDate)
    .endOf('week')
    .startOf('week')
    .add(1, 'day');
  const quaterEnd = moment(endDate)
    .endOf('quarter')
    .startOf('week')
    .add(1, 'day');
  if (!weekEnd.isSame(quaterEnd)) {
    endDate.subtract(1, 'quarter');
  }
  endDate
    .endOf('quarter')
    .startOf('week')
    .add(1, 'day');
  if (newPath && newPath.includes('revisedCapacity')) {
    // revisedCapacity dates
    range.fromMonth = new Date(startDate);
    range.toMonth = new Date(endDate);
  } else {
    // strategicCapacity dates
    endDate.add(
      1,
      'weeks'
    ); /* we are removing the first week in the date picker month, don't know why */
    range.fromMonth = new Date(endDate);
    const weekDiff = endDate.diff(startDate, 'weeks');
    const strategicEndDate = moment(range.fromMonth).add(
      260 - weekDiff - 1,
      'weeks'
    );
    range.toMonth = new Date(strategicEndDate);
  }

  if (
    newPath &&
    newPath.includes('revisedCapacity') &&
    rvsdProdQtrList &&
    rvsdProdQtrList.length > 0
  ) {
    const qRangeFrom = rvsdProdQtrList[0].value;
    const qRangeto = rvsdProdQtrList[rvsdProdQtrList.length - 1].value;
    range.fromQuarter = new Date(moment(qRangeFrom, 'YYYYQ'));
    range.toQuarter = new Date(moment(qRangeto, 'YYYYQ').endOf('quarter'));
  } else if (
    newPath &&
    newPath.includes('strategicCapacity') &&
    stratProdQtrList &&
    stratProdQtrList.length > 0
  ) {
    const qRangeFrom = stratProdQtrList[0].value;
    const qRangeto = stratProdQtrList[stratProdQtrList.length - 1].value;
    range.fromQuarter = new Date(moment(qRangeFrom, 'YYYYQ'));
    range.toQuarter = new Date(moment(qRangeto, 'YYYYQ').endOf('quarter'));
  }
  return range;
};

export const getDate = (item, newPath, range, selected) => {
  if (newPath.includes('levers') || newPath.includes('lever-quarter')) {
    item.type = 'datequarter';
    let quaterStart = moment(range.fromQuarter).startOf('quarter');
    let quaterend = moment(range.toQuarter).endOf('quarter');
    item.fromQuarter = new Date(quaterStart);
    item.toQuarter = new Date(quaterend);
    item.name =
      item.field === 'startWeekDate' ? 'Quarter Start' : 'Quarter End';
  } else {
    item.type = 'date';
    item.fromMonth = range.fromMonth;
    item.toMonth = range.toMonth;
    item.name =
      item.field === 'startWeekDate'
        ? 'Bus Wk Start (Mon)'
        : 'Bus Wk End (Mon)';
  }
  if (newPath.includes('user-updates') || newPath.includes('lever-quarter')) {
    item.disabled = true;
  } else {
    item.disabled = false;
  }
  if (selected) {
    const formatted = moment(selected, 'MM-DD-YYYY').toString();
    item.value = new Date(formatted);
  } else {
    item.value = '';
  }
  return item;
};
