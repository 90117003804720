/* eslint-disable max-statements */

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';

import { all, put, takeEvery, call } from 'redux-saga/effects';
/*
const breakIntoParts = (num, parts) =>
  [...Array(parts)].map((_, i) => 0 | (num / parts + (i < num % parts)));
*/

export function* listener(serviceType, action) {
  try {
    // Dispatch GBD_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'growthDimensionData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));

    let options = {};
    const {
      dashboard,
      pageStart,
      pageEnd,
      revisedHorizonWeeks,
      searchfields
    } = action.data;

    const { capacityTypeCode, startWeekDate, endWeekDate } = searchfields;
    let getDashDataUrl = `${apiConfig.growthDimensionData.endPointUrl}${
      dashboard === 'MaxAvailable' ? 'strategic' : 'revised'
    }`;
    let res;
    const noteData = [];
    const batchPromise = [];

    if (capacityTypeCode.length > 0) {
      if (
        capacityTypeCode.length > 1 &&
        capacityTypeCode[0].value.length === 0
      ) {
        capacityTypeCode.splice(0, 1);
      }
      for (var i = 1; i <= capacityTypeCode.length; i++) {
        if (i >= pageStart && i <= pageEnd) {
          const p = capacityTypeCode.length === 1 ? 0 : i - 1;
          let searchfields = `factoryCode=${capacityTypeCode[p].fc}`;
          searchfields += `&capacityTypeCode=${capacityTypeCode[p].value}`;
          searchfields += `&boundaryWeek=${revisedHorizonWeeks}`;
          searchfields += `&anchor=1&count=265`;

          if (startWeekDate) {
            searchfields += `&startWeekDate=${startWeekDate}`;
          }
          if (endWeekDate) {
            searchfields += `&endWeekDate=${endWeekDate}`;
          }

          let noteurl = `${getDashDataUrl}?${searchfields}`;
          batchPromise.push(call(getRequest, noteurl, options, true));
        }
      }
      const resPromise = yield all([...batchPromise]);
      res = resPromise;
      const resdata = yield all(resPromise.map(obj => obj.json()));
      resdata.forEach(note => {
        if (note && note.data && note.data.length !== 0) {
          noteData.push(...note.data);
        }
      });

      if (
        (noteData.length === 0 && Number(res.status) === 400) ||
        Number(res.status) === 504
      ) {
        const responsemessage = yield res.text() || res.json();
        const errorResponse = {
          status: Number(res.status),
          message: responsemessage
        };

        yield put(createServiceAction(serviceType, 'error')(errorResponse));
      } else {
        let response = {};
        if (noteData && noteData.length > 0) {
          response.data = noteData;
        }

        yield put(
          createServiceAction(serviceType, 'success')({
            growthDimensionData: response
          })
        );
      }
    }
    // Dispatch GBD_DATA_LOADING action with response object
    yield put(createServiceAction(serviceType, 'loading')(false));
    // yield put(createServiceAction(serviceType, 'success')(response));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    // Dispatch GBD_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* growthDimensionDataSagas() {
  const serviceType = 'growthDimensionData';
  // Listen to GBD_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default growthDimensionDataSagas;
