import { oAuthConfig } from './config/oAuthConfig.js';

const config = {
  appName: 'Capacity Visibility',
  appFullName: 'Capacity Visibility',
  oAuth: {
    client_id: 'nike.niketech.capviz',
    response_type: 'id_token token',
    scope: 'openid profile legacy_username email address phone offline_access',
    redirect_uri: window.location.origin,
    authorization: oAuthConfig.authorizationUrl
  }
};

export default config;
