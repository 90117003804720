import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';
import { getAppConfigData } from '../reducers/appConfigReducer.js';
import { put, takeEvery, call, select } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    // Dispatch dashboardGridColumns_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'dashboardGridColumns')({}));

    let options = {};
    let getDashDataUrl = `${apiConfig.dashboardGridColumns.endPointUrl}dashboard/${action.data.dashboard}`;

    if (action.data.type === 'static') {
      getDashDataUrl = apiConfig.dashboardGridColumns.endPointUrl;
      options = { dashboardType: action.data.dashboard };
    }
    // Trigger Call to API endpoint
    const res = yield call(getRequest, getDashDataUrl, options, true);
    // Parse response object
    const response = yield res.json();
    const appConfig = yield select(getAppConfigData);
    if (response && appConfig) {
      response.appConfig = appConfig;
    }
    response.userFactoryType = action.data.userFactoryType || '';
    // Dispatch dashboardGridColumns_LOADING action with response object
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'success')(response));
  } catch (e) {
    // Dispatch dashboardGridColumns_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* dashboardGridColumnsSagas() {
  const serviceType = 'dashboardGridColumns';
  // Listen to dashboardGridColumns_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default dashboardGridColumnsSagas;
