import { createAction } from '../util/actionHelper.js';
import { createServiceAction } from '../util/actionHelper.js';

export const selectStatusButton = createAction(
  'STATUS_BUTTON_SELECT',
  statusButtonVal => statusButtonVal
);

export const getAppConfigAction = createServiceAction(
  'appConfigData',
  'requested'
);

//dispatch(createServiceAction('getUserProfile', 'requested')());
