import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ large, overlay, className, position }) => (
  <div
    className={`spinnerContainer spin${position ? position : ''} spin${
      large ? large : 'Large'
    } spin${overlay ? 'Overlay' : ''}`}
  >
    <div
      className={`spinner ${large ? 'spinner-lg' : ''} ${className || ''}`}
    />
  </div>
);

Spinner.propTypes = {
  large: PropTypes.bool,
  overlay: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string
};
export default Spinner;
