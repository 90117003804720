import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Grid from '../../components/grid/Grid';
import Button from '../../components/ncss/Button';
import SimpleCellRender from '../../components/grid/simpleCellRender.js';
import gridConstants from '../../constants/gridConstants.js';
const gridDefault = {
  resizable: true,
  editable: false,
  sortable: true,
  filter: true
};

export const isHistoryChanged = params => {
  let changeChappen = false;
  const paramData = params.data || params.node.data;

  const paramField =
    params.colDef && params.colDef.field
      ? params.colDef.field
      : params.column.colDef.field;

  if (
    paramData.changedColms &&
    paramData.changedColms.length &&
    paramData.changedColms.includes(paramField)
  ) {
    changeChappen = true;
  }

  return changeChappen;
};

export const getDate = paramval => {
  const date = paramval
    ? paramval.length > 0
      ? new Date(paramval)
      : new Date()
    : new Date();
  return `${date.getMonth() +
    1}/${date.getDate()}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};

export const getViewConfig = arr => {
  let str = '';
  if (arr && Object.keys(arr).length !== 0) {
    Object.keys(arr).forEach(e => {
      if (e === 'maxCapacityEditable') {
        str += `'Max Capacity Columns' are '${
          arr[e] ? 'Editable' : 'ReadOnly'
        }'`;
      } else {
        str += `${e}" is '${arr[e]}'`;
      }
    });
  }
  return str;
};

export const getResValLen = arr => {
  return arr ? arr.length : 0;
};
const autoGroupColumnDef = {
  headerName: 'Saved By',
  minWidth: 120,
  headerClass: 'litegrey',
  filter: false,
  editable: false,
  suppressMenu: true,
  cellRendererParams: {
    suppressCount: true
  }
};
const historyHeader = [
  {
    headerName: 'Saved By',
    field: 'iterate',
    rowGroup: true,
    filter: false,
    editable: false,
    suppressMenu: true,
    width: 250,
    hide: true,
    valueFormatter: params => {
      if (
        params &&
        params.node &&
        params.node.allLeafChildren &&
        params.node.allLeafChildren.length > 0
      ) {
        const { modifiedOn, modifiedBy } = params.node.allLeafChildren[0].data;
        return modifiedBy + ' \n ' + moment(modifiedOn).format('MM/DD/YYYY LT');
      }
      return '';
    }
  },
  {
    headerName: 'Saved By',
    field: 'modifiedBy',
    hide: true
  },
  {
    headerName: 'Action',
    field: 'action',
    filter: false,
    editable: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    cellClass: 'nonEditableColumn',
    cellRenderer: params => {
      if (params.value) {
        let colour = '';
        switch (params.value) {
          case 'Delete':
            colour = 'text-color-error';
            break;
          case 'Modify':
            colour = 'text-color-warn';
            break;
          default:
            colour = 'text-color-success';
        }
        return '<i class="' + colour + '">●</i>&nbsp;' + params.value + ' ';
      }
    }
  },
  {
    headerName: 'Event Name',
    field: 'eventName',
    filter: false,
    editable: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    cellClass: 'nonEditableColumn',
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { action, oldName } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            return (
              ` <span>${params.value} </span> ` +
              `${
                oldName
                  ? '<br/><span class="u-strikethrough">' + oldName + '</span>'
                  : ''
              } `
            );
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  },
  {
    headerName: 'Effective Date',
    field: 'effectiveDate',
    filter: false,
    editable: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    cellClass: 'nonEditableColumn',
    cellRenderer: params => {
      if (params && params.value && params.data) {
        const { action, oldDate } = params.data;
        switch (action) {
          case 'Delete':
            return '<span class="u-strikethrough">' + params.value + '</span>';
          case 'Modify':
            return (
              ` <span>${params.value} </span> ` +
              `${
                oldDate
                  ? '<br/><span class="u-strikethrough">' +
                    moment(oldDate).format('MM/DD/YYYY') +
                    '</span>'
                  : ''
              } `
            );
          default:
            return (
              '<span class="text-color-success">' + params.value + '</span>'
            );
        }
      }
    }
  }
];

export default class LrcpHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      historyParams: {},
      context: { componentParent: this },
      currentReasonCodeGrid: [],
      frameworkComponents: {
        SimpleCellRender
      },
      contextMenuItems: ['copy'],
      excelStyles: gridConstants.excelStyles,
      getRowHeight(params) {
        return 60;
      }
    };

    this.setHistoryParams = this.setHistoryParams.bind(this);
    this.cellRenderAction = this.cellRenderAction.bind(this);
    this.downloadHistory = this.downloadHistory.bind(this);
  }
  setHistoryParams = params => {
    if (params) {
      this.setState({ historyParams: params });
      if (params.columnApi.getAllColumns()) {
        try {
          params.api.closeToolPanel();
          params.api.sizeColumnsToFit();
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };
  cellRenderAction = (rowIndex, column) => {
    const { OpenHistoryRCModel } = this.props;
    OpenHistoryRCModel(rowIndex);
  };

  downloadHistory = () => {
    const { historyParams } = this.state;
    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() +
      1}-${today.getDate()}_${today.getHours()}-${today.getMinutes()}`;

    const params = {
      fileName: `LRCP-ADMIN-HISTORY-${date}`,
      sheetName: 'HISTORY',
      allColumns: true,
      processHeaderCallback(hedpar) {
        if (hedpar && hedpar.column && hedpar.column.colId === 'iterate') {
          return 'Saved On';
        }
        return hedpar.column.colDef.headerName;
      },
      processCellCallback(params) {
        if (params.value) {
          let fieldName = params.column.colDef.field;
          if (fieldName === 'iterate') {
            return moment(params.value).format('MM/DD/YYYY LT');
          }
          return params.value;
        }
      }
    };
    historyParams.api.exportDataAsExcel(params);
  };

  render() {
    const {
      frameworkComponents,
      context,
      getRowHeight,
      contextMenuItems,
      excelStyles
    } = this.state;
    let { historyData } = this.props;
    return (
      <div className="container adminPanel noSide">
        <div className="row prl3-sm">
          <div className="ncss-col-sm-6 fl-sm-l">
            <h3 className="validation-header"> History</h3>
          </div>
          <div className="ncss-col-sm-6">
            <Button
              onClick={() => this.downloadHistory()}
              extraClassName={`fl-sm-r ncss-btn-primary-dark ncss-btn-sm`}
            >
              <i className="fas fa fa-download" />
              <span className="d-sm-h d-md-ib"> Download</span>
            </Button>
          </div>
        </div>

        <div className="row ">
          <div className="ncss-col-sm-12  pl0-sm pr0-sm ">
            {historyData ? (
              <div className="border numberList">
                <div
                  id="lrcpHistoryGrid"
                  className="ag-theme-balham"
                  style={{ height: 'calc( 55vh )', width: '100%' }}
                >
                  <Grid
                    defaultColDef={gridDefault}
                    context={context}
                    columnDefs={historyHeader}
                    rowData={historyData}
                    setParams={this.setHistoryParams}
                    dbGridFunctions={false}
                    enableCellChangeFlash={true}
                    frameworkComponents={frameworkComponents}
                    getRowHeight={getRowHeight}
                    excelStyles={excelStyles}
                    getContextMenuItems={contextMenuItems}
                    headerHeight={70}
                    groupDisplayType={'singleColumn'}
                    autoGroupColumnDef={autoGroupColumnDef}
                  />
                </div>
              </div>
            ) : (
              <div className="border" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

LrcpHistory.propTypes = {
  historyData: PropTypes.any,
  pageTitle: PropTypes.any
};
