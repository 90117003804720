import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

export const Toggle = ({
  name,
  options,
  label,
  line,
  onChange,
  title,
  value
}) => (
  <div className="ncss-form-group">
    {label ? (
      <Label title={title} className="mb2-sm d-sm-b">
        {label}
      </Label>
    ) : null}
    <div
      className={
        line ? 'ncss-toggle-line' : 'ncss-toggle-btn border-light-grey'
      }
    >
      {options.map(option => (
        <div
          key={option.value}
          className={line ? 'ncss-toggle-line-item' : 'ncss-toggle-btn-item'}
        >
          <input
            className="ncss-radio"
            type="radio"
            name={name}
            id={option.value}
            onChange={() => onChange(option.value)}
            checked={option.value === value}
          />
          <label
            className="ncss-label ncss-brand pt2-sm pr5-sm pb2-sm pl5-sm u-uppercase"
            onClick={() => onChange(option.value)}
            role="none"
            htmlFor={option.value}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  </div>
);

Toggle.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf,
  label: PropTypes.string,
  line: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.func
};
