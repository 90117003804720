import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';

export class Checkbox extends Component {
  constructor(...props) {
    super(...props);
    this.id += 1;
  }

  render() {
    const {
      checked,
      label,
      value,
      onChange,
      title,
      icon,
      isIcon,
      type,
      defaultSelectedValue,
      selectedValues,
      className
    } = this.props;
    return (
      <div className={`${className}`}>
        <input
          type={type}
          className="ncss-checkbox"
          id={`ncss-checkbox-${label}`}
          name={label}
          checked={defaultSelectedValue || selectedValues ? true : checked}
          value={value}
          onChange={event => onChange(value, event.target.checked)}
        />
        <Label
          className="pl6-sm"
          htmlFor={`ncss-checkbox-${label}`}
          title={title}
        >
          {isIcon ? <i className={icon} /> : <span>{label}</span>}
        </Label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
  isIcon: PropTypes.bool,
  defaultSelectedValue: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  selectedValues: PropTypes.any
};

Checkbox.defaultProps = {
  defaultSelectedValue: false
};

const renderCheckbox = ({
  fields,
  input,
  label,
  isIcon,
  icon,
  name,
  type,
  checked,
  id,
  defaultSelectedValue,
  selectedValues,
  ...rest
}) => (
  <Checkbox
    {...input}
    label={label}
    type={type}
    isIcon={isIcon}
    checked={input.checked}
    onChange={(id, checked) => input.onChange(checked)}
    value={id}
    defaultSelectedValue={defaultSelectedValue}
    selectedValues={selectedValues}
    icon={icon}
    name={name}
    {...rest}
  />
);
renderCheckbox.propTypes = {
  fields: PropTypes.string,
  input: PropTypes.string,
  label: PropTypes.string,
  isIcon: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  defaultSelectedValue: PropTypes.any,
  selectedValues: PropTypes.any
};

export const normalizeBoolean = id => checked => {
  if (checked) {
    return id;
  }
  return null;
};

export default renderCheckbox;
