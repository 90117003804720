import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  adminLoading: false,
  adminData: {},
  adminError: {},
  updateAdminLoading: false,
  updateAdminSuccess: false,
  updateAdminFailed: false
});

const modifyAdminData = data => {
  const returnData = data;
  if (
    returnData &&
    returnData.configurations &&
    returnData.configurations.reasonCodes
  ) {
    const rawrescode = returnData.configurations.reasonCodes;
    data.configurations.reasonCodes = rawrescode.sort(
      (a, b) => a.serial - b.serial
    );
  }
  if (returnData && returnData.configurations) {
    if (!('revisedViewConfig' in returnData.configurations)) {
      returnData.configurations.revisedViewConfig = {
        maxCapacityEditable: true
      };
    }
  }
  return returnData;
};

export default function admin(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('adminData', 'success').type:
      return state.set('adminData', modifyAdminData(action.data));

    case createServiceAction('adminData', 'loading').type:
      return state.set('adminLoading', action.data);

    case createServiceAction('adminData', 'clear').type:
      return state.set('adminData', action.data);

    case createServiceAction('adminData', 'error').type:
      return state.set('adminError', action.data);

    case createServiceAction('updateAdminData', 'loading').type:
      return state.set('updateAdminLoading', action.data);

    case createServiceAction('updateAdminData', 'success').type:
      return state.set('updateAdminSuccess', action.data);

    case createServiceAction('updateAdminData', 'error').type:
      return state.set('updateAdminFailed', action.data);

    case createServiceAction('updateAdminData', 'clear').type:
      return state.set('updateAdminData', action.data);

    default:
      return state;
  }
}
export const getAdminData = state => state.admin.get('adminData');
export const getAdminLoading = state => state.admin.get('adminLoading');
export const getAdminError = state => state.admin.get('adminError');
export const updateAdminData = state => state.admin.get('updateAdminData');
export const getUpdatingAdminLoading = state =>
  state.admin.get('updateAdminLoading');
export const getUpdatingAdminSuccess = state =>
  state.admin.get('updateAdminSuccess');
export const getUpdatingAdminFailed = state =>
  state.admin.get('updateAdminFailed');
