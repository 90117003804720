import * as moment from 'moment';
import _ from 'lodash';
// for ag grid custom footer download
export const getEditabLeversList = (list, division) => {
  let varianceLeverList = [];
  if (list) {
    if (division === 20) {
      varianceLeverList = list;
    } else {
      varianceLeverList = list;
    }
  }
  return varianceLeverList;
};

export const leverDataModify = data => {
  if (
    data &&
    data.leverInfo &&
    data.leverInfo.variances &&
    data.leverInfo.variances.length > 0
  ) {
    data.leverInfo.variances.forEach((variance, vIndex) => {
      if (variance && variance.leverlist && variance.leverlist.length > 0) {
        variance.leverlist.forEach((lever, lIndex) => {
          if (lever.validityDate) {
            const now = moment(new Date());
            const end = moment(lever.validityDate);
            //let tomorrow = moment().add(1, 'days');
            const duration = now.diff(end, 'days');
            if (duration > 0 && lever.srcDecision === 'TBD') {
              lever.srcDecision = 'Expired';
            }
          }
        });
      }
    });
  }

  return data;
};

export const isLeverEdited = (obj1, obj2, userType) => {
  let editedStatus = false;
  if (obj1.capacity) {
    obj1.capacity = Number(obj1.capacity);
  }
  if (obj1.cost) {
    obj1.cost = Number(obj1.cost);
  }
  if (obj1.leverQty) {
    obj1.leverQty = Number(obj1.leverQty);
  }

  if (obj2.capacity) {
    obj2.capacity = Number(obj2.capacity);
  }
  if (obj2.cost) {
    obj2.cost = Number(obj2.cost);
  }
  if (obj2.leverQty) {
    obj2.leverQty = Number(obj2.leverQty);
  }
  let fields = [];
  if (userType === 'FACTORY') {
    if (obj2.srcDecision === 'Expired') {
      fields = ['validityDate'];
    } else {
      fields = ['lever', 'capacity', 'cost', 'validityDate'];
    }
  }
  const obj1Min = _.pick(obj1, fields);
  const obj2Min = _.pick(obj2, fields);
  if (JSON.stringify(obj1Min) !== JSON.stringify(obj2Min)) {
    editedStatus = true;
  }

  return editedStatus;
};
