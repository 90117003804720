/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isMobile, isTablet } from 'react-device-detect';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipShowDelay: 0,
      tooltipHideDelay: 2000
    };
  }
  onGridReady = params => {
    const { setParams } = this.props;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.closeToolPanel();
    params.api.resetRowHeights();
    if (setParams) {
      setParams(params);
    }
  };

  getHeight = () => {
    const { setheight } = this.props;
    let height = window.innerHeight - 285;
    if (setheight) {
      height = setheight;
    } else {
      if (isMobile || isTablet) {
        height = window.innerHeight - 90;
      }
      if (height < 300) {
        height = 300;
      }
    }

    return height;
  };

  onGridSizeChanged = params => {
    params.api.resetRowHeights();
  };

  isExternalFilterPresent = () => {
    const { dbGridFunctions } = this.props;
    if (dbGridFunctions) {
      setTimeout(() => {
        this.props.internalFilterChangedCallBack();
      }, 100);
      return this.props.statusCode !== '';
    }
  };

  doesExternalFilterPass = node => {
    const { statusCode, defaultStatus, gridParent } = this.props;
    const statusList = ['In Review', 'Factory', 'Confirmed', 'Approved', 'Max'];
    let status =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableStatus'
        : 'shortHorizonStatus';
    if (gridParent === 'changenotification') {
      status = 'changeType';
    }
    if (statusCode.includes('-')) {
      const val = statusCode.split('-');
      const stat = val[0];
      if (statusList.includes(stat)) {
        switch (val[1]) {
          case 'status': //OR CASE
            return node.data[status] === stat;
          case 'read': // AND CASE
            return (
              node.data[status] === stat && node.data.readStatus !== 'read'
            );
          case 'pending': // OR CASE ACROSS DIFFERENT COLUMNS
            return node.data[status] === stat && node.data.pending === true;
          default:
            return true;
        }
      } else if (stat === 'savecheck') {
        return (
          node.data.isThresholdExceed === true ||
          node.data.isStatusBreak === true
        );
      } else {
        return node.data.isMax === true;
      }
    } else {
      if (statusList.includes(statusCode)) {
        return node.data[status] === statusCode;
      } else {
        return node.data.isMax === true;
      }
    }
  };

  render() {
    const {
      columnDefs,
      rowData,
      adjustHeight,
      headerHeight,
      onRowClick,
      sideBar,
      onCellValueChanged,
      frameworkComponents,
      excelStyles,
      getRowHeight,
      defaultColDef,
      pagination,
      onDisplayedColumnsChanged,
      context,
      getContextMenuItems,
      rowDragManaged,
      animateRows,
      onSelectionChanged,
      rowSelection,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      suppressRowClickSelection,
      onBodyScroll,
      enableRangeSelection,
      onFirstDataRendered,
      enableCellChangeFlash,
      groupDisplayType,
      autoGroupColumnDef
    } = this.props;

    return (
      <div
        style={{ height: this.getHeight() }}
        className={`ag-theme-balham ${
          !adjustHeight ? 'custom-height' : 'custom-height-lg'
        }`}
      >
        <AgGridReact
          modules={AllModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          floatingFilter
          enableSorting
          pagination={pagination}
          onGridReady={this.onGridReady}
          onGridSizeChanged={this.onGridSizeChanged}
          onRowClicked={onRowClick}
          headerHeight={headerHeight}
          sideBar={sideBar}
          suppressMenuHide
          onCellValueChanged={onCellValueChanged}
          onDisplayedColumnsChanged={onDisplayedColumnsChanged}
          frameworkComponents={frameworkComponents}
          excelStyles={excelStyles}
          stopEditingWhenGridLosesFocus
          context={context}
          isExternalFilterPresent={this.isExternalFilterPresent}
          doesExternalFilterPass={this.doesExternalFilterPass}
          getRowHeight={getRowHeight}
          enableRangeSelection={
            enableRangeSelection ? enableRangeSelection : false
          }
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          rowDragManaged={rowDragManaged || false}
          animateRows={animateRows || false}
          onSelectionChanged={onSelectionChanged}
          rowSelection={rowSelection || false}
          overlayLoadingTemplate={overlayLoadingTemplate || ''}
          overlayNoRowsTemplate={overlayNoRowsTemplate || ''}
          suppressRowHoverHighlight
          suppressRowClickSelection={suppressRowClickSelection || false}
          onBodyScroll={onBodyScroll}
          tooltipShowDelay={this.state.tooltipShowDelay}
          tooltipHideDelay={this.state.tooltipHideDelay}
          enableCellChangeFlash={enableCellChangeFlash || false}
          autoGroupColumnDef={autoGroupColumnDef || ''}
          groupDisplayType={groupDisplayType || ''}
          suppressColumnVirtualisation={process.env.NODE_ENV === 'development'}
        />
      </div>
    );
  }
}

Grid.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  showFilter: PropTypes.bool,
  edit: PropTypes.bool,
  adjustHeight: PropTypes.bool,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  setParams: PropTypes.func,
  sideBar: PropTypes.object,
  onCellValueChanged: PropTypes.func,
  frameworkComponents: PropTypes.object,
  excelStyles: PropTypes.array,
  context: PropTypes.any,
  statusCode: PropTypes.any,
  internalFilterChangedCallBack: PropTypes.func,
  defaultStatus: PropTypes.any,
  getRowHeight: PropTypes.any,
  pagination: PropTypes.bool,
  rowModelType: PropTypes.any,
  dbGridFunctions: PropTypes.bool,
  defaultColDef: PropTypes.any,
  setheight: PropTypes.any,
  onDisplayedColumnsChanged: PropTypes.func,
  getContextMenuItems: PropTypes.any,
  rowDragManaged: PropTypes.bool,
  animateRows: PropTypes.bool,
  onSelectionChanged: PropTypes.func,
  rowSelection: PropTypes.any,
  overlayLoadingTemplate: PropTypes.any,
  overlayNoRowsTemplate: PropTypes.any,
  gridParent: PropTypes.any,
  suppressRowClickSelection: PropTypes.any,
  onBodyScroll: PropTypes.any,
  enableRangeSelection: PropTypes.bool,
  enableCellChangeFlash: PropTypes.bool,
  groupDisplayType: PropTypes.any,
  autoGroupColumnDef: PropTypes.any
};

Grid.defaultProps = {
  showFilter: true,
  headerHeight: PropTypes.any || 32
};

export default connect()(Grid);
