import React from 'react';
import PropTypes from 'prop-types';

import InfoTooltip from '../InfoTooltip';
import Markdown from '../Markdown.js';

const defaultClass = 'ncss-label';

const Label = ({
  children,
  title,
  className,
  useBareClass,
  htmlFor,
  ...props
}) => (
  <label
    {...props}
    className={`${useBareClass || defaultClass} ${className || ''}`}
    htmlFor={`${htmlFor}`}
    id="label"
    key="label"
  >
    {children}
    {title ? (
      <InfoTooltip key="tooltip" className="ml2-sm ">
        <div className="tooltip-markdown">
          <Markdown source={title} />
        </div>
      </InfoTooltip>
    ) : null}
  </label>
);

Label.propTypes = {
  children: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  useBareClass: PropTypes.string,
  htmlFor: PropTypes.string
};

export default Label;
