import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import CheckBoxItem from './CheckBoxItem';

export default class SelectFilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      uid: Math.floor(Math.random() * 10) + 1,
      // eslint-disable-next-line react/destructuring-assignment
      filterData: this.props.list
    };
  }

  componentWillReceiveProps(nextProps) {
    const { list } = this.props;
    if (
      list &&
      nextProps.list &&
      JSON.stringify(list) !== JSON.stringify(nextProps.list)
    ) {
      this.setState({ filterData: nextProps.list });
    }
  }

  checkChildAll = (p, child, name, type) => {
    let { list } = this.props;
    const iList = list.filter(o => o.parent === name);
    const totArLen = iList.length;
    const selArLen = iList.filter(i => i.checked).length;
    if (type === 'i') {
      return totArLen > selArLen && 0 < selArLen ? true : false;
    }
    return totArLen === selArLen ? true : false;
  };

  render() {
    const {
      searchText,
      listGroupBy,
      onCheckChange,
      parent,
      name,
      onCheckAllChange,
      onCheckSelectionChange,
      disabledAll,
      onFilterInputChange,
      indeterminate,
      checkedAll
    } = this.props;
    const { filterData, uid } = this.state;
    let groupedData = [];
    if (listGroupBy && filterData && !searchText) {
      groupedData = _(filterData)
        .groupBy(x => x.parent)
        .map((value, key) => ({
          name: key,
          list: value,
          checked: this.checkChildAll(parent, name, key, 'c'),
          indeterminate: this.checkChildAll(parent, name, key, 'i')
        }))
        .value();
    } else {
      groupedData = [];
    }

    const InnherHeight =
      window.innerHeight / 2 - 210 < 250 ? 250 : window.innerHeight / 2 - 210;

    return (
      <div>
        <div>
          <div className="searchbox border-bottom-black fs-12px">
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onClick={this.handleChange}
              //onChange={this.filterList}
              onChange={e => onFilterInputChange(parent, name, e)}
              disabled={disabledAll}
            />
            <span className="searchboxIcon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                preserveAspectRatio="xMidYMid meet"
                fill="currentColor"
              >
                <path d="M14.92,12.8 L20,17.9 L17.9,20 L12.82,14.92 C11.4066596,15.8800048 9.8600084,16.36 8.18,16.36 C5.9266554,16.36 4.000008,15.560008 2.4,13.96 C0.799992,12.359992 0,10.4333446 0,8.18 C0,5.9266554 0.799992,4.000008 2.4,2.4 C4.000008,0.799992 5.9266554,-4.73695157e-14 8.18,0 C10.4333446,-4.73695157e-14 12.359992,0.799992 13.96,2.4 C15.560008,4.000008 16.36,5.9266554 16.36,8.18 C16.36,9.8733418 15.8800048,11.4133264 14.92,12.8 Z M13.62,8.18 C13.62,6.6733258 13.0933386,5.386672 12.04,4.32 C10.9866614,3.253328 9.7000076,2.72 8.18,2.72 C6.6599924,2.72 5.3733386,3.253328 4.32,4.32 C3.2666614,5.386672 2.7333334,6.6733258 2.72,8.18 C2.7066666,9.6866742 3.2399946,10.9666614 4.32,12.02 C5.4000054,13.0733386 6.6866592,13.6066666 8.18,13.62 C9.6733408,13.6333334 10.9599946,13.1000054 12.04,12.02 C13.1200054,10.9399946 13.6466668,9.6600074 13.62,8.18 Z"></path>
              </svg>
            </span>
          </div>
          <div className="customScrollBar" style={{ height: InnherHeight }}>
            {filterData && filterData.length > 0 && (
              <ul>
                <li>
                  <div
                    className={`ncss-checkbox-container ${
                      indeterminate ? 'indeterminate' : ''
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="ncss-checkbox"
                      id={`CheckBox-${uid}-${parent}-${name}-SelectAll`}
                      disabled={disabledAll}
                      checked={checkedAll || false}
                      onChange={e => onCheckAllChange(parent, name)}
                    />
                    <label
                      className="ncss-label pl6-sm"
                      htmlFor={`CheckBox-${uid}-${parent}-${name}-SelectAll`}
                    >
                      Select/ Clear All
                      {searchText && searchText.length > 0
                        ? ' search results'
                        : ''}
                    </label>
                  </div>
                </li>

                {listGroupBy && groupedData.length > 0
                  ? groupedData.map((option, i) => (
                      <li key={`${uid}-${option.name}`}>
                        <b
                          className={`fs-12px c-f-500 ${
                            disabledAll ? 'text-color-grey1' : ''
                          }`}
                        >
                          {option.name}
                        </b>

                        <ul>
                          {option.list && option.list.length > 0 && (
                            <li>
                              <div
                                className={`ncss-checkbox-container ${
                                  option.indeterminate === true
                                    ? 'indeterminate'
                                    : ''
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  className="ncss-checkbox"
                                  id={`CheckBox-${uid}-${i}-Select-${option.name}`}
                                  disabled={disabledAll}
                                  checked={option.checked || false}
                                  onChange={e =>
                                    onCheckSelectionChange(
                                      parent,
                                      name,
                                      option.name,
                                      e
                                    )
                                  }
                                />
                                <label
                                  className="ncss-label pl6-sm fs-12px"
                                  htmlFor={`CheckBox-${uid}-${i}-Select-${option.name}`}
                                >
                                  Select/ Clear Selection
                                </label>
                              </div>
                            </li>
                          )}
                          {option.list &&
                            option.list.map((item, i) => (
                              <li key={`${uid}-${option.name}-${item.ukey}`}>
                                <CheckBoxItem
                                  uid={uid}
                                  option={item}
                                  disabledAll={disabledAll}
                                  onCheckChange={onCheckChange}
                                  parent={parent}
                                  name={name}
                                  searchText={searchText}
                                />
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))
                  : filterData.map((option, i) => (
                      <li key={`p-${uid}-${option.parent}-${option.ukey}`}>
                        <CheckBoxItem
                          uid={uid}
                          option={option}
                          disabledAll={disabledAll}
                          onCheckChange={onCheckChange}
                          parent={parent}
                          name={name}
                          searchText={searchText}
                        />
                      </li>
                    ))}
                {}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SelectFilterList.propTypes = {
  list: PropTypes.any,
  name: PropTypes.any,
  selectAllOption: PropTypes.any,
  listGroupBy: PropTypes.any,
  enableAll: PropTypes.any,
  onCheckChange: PropTypes.any,
  parent: PropTypes.any,
  selectAll: PropTypes.any,
  attr: PropTypes.any,
  onFilterInputChange: PropTypes.any,
  onCheckAllChange: PropTypes.any,
  disabledAll: PropTypes.any
};
