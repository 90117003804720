export const msgConstants = {
  pageChangeAlert: 'Are you sure? You will lose all unsaved data ?',
  inValFormatData: 'Invalid Data Format',
  repEmptyCapVal:
    'Capacity value cannot be left blank once a value is input. Reseting to previous value.',
  noChangeUpdate: 'No change to update were found. ',
  inValFormatDataCort:
    'Data is in an invalid format. Please upload a .xsl or .csv file.',
  uploadFailNoFile:
    'Your file upload failed - No file found. Please try again.',
  uploadFailInvalidSession:
    'Upload Failed : The latest version of the file was not uploaded. Please try with the latest file or try re-downloading a newer version and uploading again',
  uploadFailNoData:
    'No new data was found in your file. Please make changes and re-upload.',
  uploadFailNoMatchData:
    'Upload failed: data does not match. Please download your file and upload again to match data.',
  uploadFailNoMatchId:
    'Upload Failed: An unauthorized file id change was made. Please try downloading a new file again and upload without editing the file id',
  uploadFailNotWeekly:
    'Upload Failed: The file was downloaded from a Quarterly view. Please switch back to Quarterly view and try again or download a fresh file to proceed.',
  uploadFailNotQuarterly:
    'Upload Failed: The file was downloaded from a Weekly view. Please switch back to Weekly view and try again or download a fresh file and proceed.',
  uploadWarnNoMatchFileName:
    'File conflicts with another users recent upload. Please download again and make your changes.',
  uploadFailFetchErr:
    'Upload Failed : Unable to read the data from Excel. Please try again.',
  uploadFailReadErrInDashboard:
    'Upload Failed: Unable to read the data from Excel. Please try again or contact support if the issue persists.',
  fileFormatError: 'Incorrect file format: Please upload .xls files.',
  uploadFailNoContent: 'Upload Failed : There is no data to upload',
  cmntAddSuccess: 'Comment Added',
  cmntAddFailed: 'Comment not added, please try again.',
  cmntAddInvalid: ' Please Enter valid comments',
  excelDownloadSuccess: 'Download Success',
  invalidSearch: 'No results were found for these filters. Please try again',
  invalidSearchInput:
    'Please select a valid factory code or group to continue.',
  negativeValueMsg: 'Negative value is not acceptable'
};
