import React from 'react';
import PropTypes from 'prop-types';

const ScoreCard = ({ title, icon, count, loadGrid }) => (
  <div
    className={`flex-child menuOpt2Item ${count > 0 ? 'u-cursor-pointer' : ''}`}
  >
    <div className="d-sm-b">
      <span className="text c-f-500 ">{title}</span>
      <div className="d-sm-b  u-bold ">
        <span className="d-sm-ib  d-lg-ib">
          <span className="d-sm-ib d-md-h">
            <i className="fas fa-calendar-week" />
          </span>
          <i className={`fa fa-fw  ${icon} icon-button-margin `} />
        </span>

        <span className="d-sm-ib  d-lg-ib  "> {loadGrid ? '0' : count}</span>
      </div>
    </div>
  </div>
);

ScoreCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  count: PropTypes.number,
  loadGrid: PropTypes.bool
};

export default ScoreCard;
