import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, PageSpinner, Button } from '../../components/index.js';
import { requestUserLogin } from '../../actions/authActions.js';
import {
  userLoggedIn,
  hasAuthCheckFinished
} from '../../reducers/authReducer.js';

const mapProps = state => ({
  isLoggedIn: userLoggedIn(state),
  loginFinished: hasAuthCheckFinished(state)
});

const LoginRequired = withRouter(
  connect(
    mapProps,
    { requestUserLogin }
  )(({ isLoggedIn, loginFinished, title, requestUserLogin, children }) => {
    if (!loginFinished) return <PageSpinner title={title} noHeader />;
    if (!isLoggedIn) {
      return (
        <Container fluid>
          <div className=" ncss-row">
            <div className="ncss-col-sm-12 ">
              <div className="c-f-all u-full-width  u-clearfix  d-sm-flx-new  flx-jc-sm-c errorCenter">
                <div className="flex-item p4-sm ta-sm-l flx-as-sm-c  ">
                  <h4 className="  pb2-sm u-bold "> Session Expired </h4>
                  <p> You must be logged in to view this page</p>
                  <p className="errorContent pt4-sm ta-sm-l ">
                    <Button
                      extraClassName="m2-sm u-sm-ib u-va-m"
                      onClick={requestUserLogin}
                    >
                      Login
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      );
    }

    return React.Children.only(children);
  })
);

const requireLogin = title => WrappedComponent => props => (
  <LoginRequired title={title}>
    <WrappedComponent {...props} />
  </LoginRequired>
);

export { LoginRequired };
export default requireLogin;
