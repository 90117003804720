import React from 'react';
import PropTypes from 'prop-types';

import { Container, H1 } from './index.js';

const PageHeader = ({ title, back, children }) => {
  const headerStyle = ['ncss-brand u-uppercase flex-fill'];
  if (back) headerStyle.push('page-title-has-back');

  return (
    <Container section extraClassName="flex-container start page-header">
      <H1 className={headerStyle.join(' ')}>
        {title}
        {back || null}
      </H1>
      {React.Children.count(children) ? (
        <div className="flex-static">{children}</div>
      ) : null}
    </Container>
  );
};

PageHeader.propTypes = {
  children: PropTypes.func,
  title: PropTypes.string,
  back: PropTypes.bool
};
export default PageHeader;
