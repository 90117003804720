import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import Grid from '../grid/Grid.js';
import { H5, IconButton } from '../index.js';
const gridDefault = {
  resizable: false,
  editable: true,
  sortable: false,
  filter: false
};
const historyRCHeader = [
  {
    headerName: 'Sl.No',
    field: 'serial',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 60,
    cellClass: 'nonEditableColumn'
  },
  {
    value: 'Reason Code',
    field: 'value',
    filter: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 300,
    cellClass: 'nonEditableColumn'
  },

  {
    headerName: 'Status',
    field: 'status',
    filter: true,
    editable: false,
    suppressMenu: true,
    headerClass: 'litegrey',
    width: 200,

    cellClass: 'nonEditableColumn'
  }
];
export default class HistoryReasonCodeModal extends Component {
  render() {
    const {
      displayHistoryRCModel,
      toogleHistoryRCModel,
      historyRCListEntry
    } = this.props;
    const currentEnv = window.sessionStorage.getItem('currentEnv');

    return (
      <Modal
        isOpen={displayHistoryRCModel}
        className={`modal-right-container  ${currentEnv ? 'showEnv' : ''}`}
        overlayClassName="modal-overlay"
      >
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() => toogleHistoryRCModel()}
        />

        <div className="border">
          <H5 className="u-bold description p2-top-sm p2-bottom-sm">
            Reason Codes Changed
            {historyRCListEntry.timestamp &&
              ` on  ${moment(historyRCListEntry.timestamp).format(
                'MM/DD/YYYY HH:MM'
              )}`}
            {historyRCListEntry.userId && ` by ${historyRCListEntry.userId}`}
          </H5>
          <div className="ncss-col-sm-12 ">
            <div className="ncss-container comment-container">
              <div className="ncss-row">
                <div className="ncss-col-sm-12">
                  <div
                    id="myGrid"
                    style={{
                      height: 'calc( 100vh - 100px)',
                      width: '100%'
                    }}
                    className="ag-theme-balham"
                  >
                    <Grid
                      defaultColDef={gridDefault}
                      columnDefs={historyRCHeader}
                      rowData={historyRCListEntry.reasonCodes}
                      dbGridFunctions={false}
                      setheight="80vh"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

HistoryReasonCodeModal.propTypes = {
  displayHistoryRCModel: PropTypes.bool,
  toogleHistoryRCModel: PropTypes.func,
  historyRCListEntry: PropTypes.any
};
