import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const CloseButton = ({ closeToast }) => {
  return (
    <i onClick={closeToast} style={{ margin: 'auto', fontSize: 'medium' }}>
      <svg
        width="12"
        height="12"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6755 0L9.92736 7.74818L2.17918 0L0 2.32446L7.65133 10.0726L0 17.8208L2.17918 20L9.92736 12.3487L17.6755 20L20 17.8208L12.2518 10.0726L20 2.32446L17.6755 0Z"
          fill="#000"
        />
      </svg>
    </i>
  );
};
class NotificationsMessage extends Component {
  render() {
    return (
      <div className="main c-f-all">
        <ToastContainer
          closeButton={<CloseButton />}
          position="top-center"
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default NotificationsMessage;
