import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import qs from 'qs';
import { Spinner } from '../../components/index.js';
import { featuresConstants } from '../../constants/featuresConstants.js';
import {
  selectLegacyUserName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';

export class UserProfileFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appFeature: JSON.parse(JSON.stringify(featuresConstants)),
      appFeatureBak: JSON.parse(JSON.stringify(featuresConstants)),
      pageLoading: false,
      userProfileSettingsInfo: featuresConstants,
      toastConfig: {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      },
      pageTitle: '',
      showBuild: false
    };

    this.onSaveCancel = this.onSaveCancel.bind(this);
    this.saveDataChanges = this.saveDataChanges.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);

    this.toastUpdate = this.toastUpdate.bind(this);
  }

  componentDidMount() {
    const { appFeature } = this.state;
    const queryString = window.location.search;
    const queryObj = qs.parse(queryString.replace('?', ''));
    const showBuild = 'build' in queryObj ? true : false;
    if (queryObj && Object.keys(queryObj).length !== 0) {
      Object.keys(queryObj).forEach(e => {
        const thisIndex = appFeature.findIndex(item => item.name === e);
        if (thisIndex >= 0) {
          appFeature[thisIndex].value = queryObj[e];
        }
      });
    }
    if (appFeature && appFeature.length > 0) {
      appFeature.forEach(i => {
        const ls = localStorage.getItem(i.name);

        if (ls) {
          i.value = ls.toString();
          i.status = false;
        }
      });
      setTimeout(() => {
        this.setState({
          showBuild,
          appFeature,
          appFeatureBak: JSON.parse(JSON.stringify(appFeature))
        });
      }, 100);
    }
  }

  toastUpdate = toastms => {
    const { toastConfig } = this.state;
    if (toastms.status) {
      toast.success(toastms.msg, toastConfig);
    } else {
      toast.error(toastms.msg, toastConfig);
    }
  };

  onFieldChange = (i, e) => {
    let { appFeature } = this.state;
    appFeature[i].value = e.target.value || '';
    this.setState({ appFeature });
  };

  onToggleClick = name => {
    let { appFeature } = this.state;
    appFeature[name].status = !appFeature[name].status;
    this.setState({ appFeature });
  };

  onSaveCancel = () => {
    const { appFeature, appFeatureBak } = this.state;
    if (JSON.stringify(appFeature) === JSON.stringify(appFeatureBak)) {
      this.toastUpdate({
        status: false,
        msg: 'No Changes to Revert!'
      });
    } else {
      this.setState({ appFeature: JSON.parse(JSON.stringify(appFeatureBak)) });
      this.toastUpdate({
        status: true,
        msg: 'Changes Reverted Successfully!'
      });
    }
  };
  saveDataChanges = () => {
    const { appFeature, appFeatureBak } = this.state;
    let toUpdate = { status: false, msg: '' };
    let errorCount = 0;
    if (JSON.stringify(appFeature) === JSON.stringify(appFeatureBak)) {
      toUpdate.msg = 'No Changes to Save!';
      this.toastUpdate(toUpdate);
    } else {
      appFeature.forEach(j => {
        if (j.trial && j.status) {
          localStorage.removeItem(j.name);
        } else {
          localStorage.setItem(j.name, j.defaultVal);
        }
      });
      if (errorCount > 0) {
        this.toastUpdate(toUpdate);
      } else {
        this.toastUpdate({
          status: true,
          msg: 'Changes Updated Successfully!'
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  };

  render() {
    const { appFeature, pageLoading, appFeatureBak } = this.state;
    const appFeatureCount = appFeature.filter(a => a.status).length;
    const appFeatureBacCount = appFeatureBak.filter(a => a.status).length;

    return (
      <div className="page-align-items   c-f-all">
        <div className="">
          {pageLoading && <Spinner large />}

          {!pageLoading && appFeature && (
            <div className="ncss-container prl0-sm mt6-sm pt6-sm">
              <div className=" bg-white c-f-border-5 p6-sm mb5-sm">
                <div className="ncss-row">
                  <div className="ncss-col-sm-12  ncss-col-md-6 ncss-col-lg-6 row-align-top pl7-sm">
                    <h3 className="text-color-grey1  page-title-has-back fs-24px">
                      New Features Settings
                    </h3>
                  </div>
                  <div className="ncss-col-sm-12  ncss-col-md-6 ncss-col-lg-2 row-align-top fl-md-r ta-sm-r">
                    <button
                      type="button"
                      className="  ncss-btn-secondary-lite fs-16px"
                      onClick={() => this.onSaveCancel()}
                    >
                      Cancel
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      disabled={appFeatureCount === appFeatureBacCount}
                      onClick={() => this.saveDataChanges()}
                      className={`  ncss-btn-primary-dark fs-16px`}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="ncss-row text-color-accent bg-secondary-grey">
                  <div className="ncss-col-sm-12  pl6-sm">
                    <div>
                      Note: To enable feature slide toggle switch to ON, enter
                      pass-code and save. To disable slide toggle to OFF and
                      save
                    </div>
                  </div>
                </div>
                <div className="ncss-row">
                  <div className="ncss-col-sm-12  ncss-col-md-12 ncss-col-lg-8">
                    {appFeature &&
                      appFeature.map((v, i) => (
                        <div
                          className="ncss-container pt4-sm"
                          key={`listOf${i}`}
                        >
                          <div className="ncss-row pt4-sm">
                            <div className="ncss-col-sm-8 row-align-top">
                              <div className="fl-sm-l pt2-sm pr4-sm">
                                <label
                                  className="switch sm blue"
                                  htmlFor="maxCapacityEditable"
                                  onClick={e => this.onToggleClick(i)}
                                >
                                  <input
                                    type="checkbox"
                                    name="maxCapacityEditable"
                                    checked={v.status}
                                    readOnly
                                  />
                                  <div className="slider round"></div>
                                </label>
                              </div>
                              <p className="fl-sm-l pl1-sm c-f-500 fs-18px">
                                {v.label}
                              </p>
                              <input
                                type="text"
                                placeholder="Pass Code"
                                disabled={!v.status}
                                value={v.value}
                                className="fl-sm-l ml1-sm cInput p2-sm"
                                onChange={e => this.onFieldChange(i, e)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

UserProfileFeatures.propTypes = {
  history: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array
};

const mapStateToProps = state => ({
  name: selectLegacyUserName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state)
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserProfileFeatures)
);
