import { fromJS } from 'immutable';
import { openNavMenu, navMenuclose, navMenuToggle } from '../actions/navigationActions.js';

const defaultState = fromJS({
  isMenuOpen: false
});

export default function navigation(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case openNavMenu.type:
      return state.set('isMenuOpen', true);

    case navMenuclose.type:
      return state.set('isMenuOpen', false);

    case navMenuToggle.type:
      return state.set('isMenuOpen', !state.get('isMenuOpen'));

    default:
      return state;
  }
}

export const selectIsMenuOpen = state => state.navigation.get('isMenuOpen');
