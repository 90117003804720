import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  leverAdminLoading: false,
  leverAdminData: {},
  leverAdminError: {},
  updateLeverAdminLoading: false,
  updateLeverAdminSuccess: false,
  updateLeverAdminFailed: false
});

const modifyLeverAdminData = data => {
  const returnData = data;
  if (
    returnData &&
    returnData.configurations &&
    returnData.configurations.reasonCodes
  ) {
    const rawrescode = returnData.configurations.reasonCodes;
    data.configurations.reasonCodes = rawrescode.sort(
      (a, b) => a.serial - b.serial
    );
  }
  if (returnData && returnData.configurations) {
    if (!('revisedViewConfig' in returnData.configurations)) {
      returnData.configurations.revisedViewConfig = {
        maxCapacityEditable: true
      };
    }
  }
  return returnData;
};

export default function leverAdmin(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('leverAdminData', 'success').type:
      return state.set('leverAdminData', modifyLeverAdminData(action.data));

    case createServiceAction('leverAdminData', 'loading').type:
      return state.set('leverAdminLoading', action.data);

    case createServiceAction('leverAdminData', 'clear').type:
      return state.set('leverAdminData', action.data);

    case createServiceAction('leverAdminData', 'error').type:
      return state.set('leverAdminError', action.data);

    case createServiceAction('updateLeverAdminData', 'loading').type:
      return state.set('updateLeverAdminLoading', action.data);

    case createServiceAction('updateLeverAdminData', 'success').type:
      return state.set('updateLeverAdminSuccess', action.data);

    case createServiceAction('updateLeverAdminData', 'error').type:
      return state.set('updateLeverAdminFailed', action.data);

    case createServiceAction('updateLeverAdminData', 'clear').type:
      return state.set('updateLeverAdminData', action.data);

    default:
      return state;
  }
}
export const getLeverAdminData = state =>
  state.leverAdmin.get('leverAdminData');
export const getLeverAdminLoading = state =>
  state.leverAdmin.get('leverAdminLoading');
export const getLeverAdminError = state =>
  state.leverAdmin.get('leverAdminError');
export const updateLeverAdminData = state =>
  state.leverAdmin.get('updateLeverAdminData');
export const getUpdatingLeverAdminLoading = state =>
  state.leverAdmin.get('updateLeverAdminLoading');
export const getUpdatingLeverAdminSuccess = state =>
  state.leverAdmin.get('updateLeverAdminSuccess');
export const getUpdatingLeverAdminFailed = state =>
  state.leverAdmin.get('updateLeverAdminFailed');
