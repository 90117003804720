import { fromJS } from 'immutable';
import _ from 'lodash';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  userProfileDataLoading: false
});

const setStorageUser = userResp => {
  const userfactorylist = JSON.stringify(userResp);
  window.sessionStorage.setItem('factorylist', userfactorylist);
  const factoryGroupsAsso = userResp.association || [];

  if (factoryGroupsAsso.length > 0) {
    let factoryGroups = [];
    let factoryCodesOnly = [];
    factoryGroupsAsso.forEach((fgaItem, indexSub) => {
      let factoryCodes = [];
      if (fgaItem.factories && fgaItem.factories.length > 0) {
        fgaItem.factories.forEach((fgCodItem, indexSub1) => {
          const newArrFcods = {
            name: fgCodItem,
            value: fgCodItem,
            key: fgCodItem,
            label: fgCodItem,
            id: Number(`${indexSub + 1}${indexSub1 + 1}`),
            group: fgaItem.factoryGroup
          };
          factoryCodes.push(newArrFcods);
          factoryCodesOnly.push(newArrFcods);
        });
      }

      factoryCodes = _.uniqBy(factoryCodes, 'key');
      if (factoryCodes.length > 0) {
        /* const newFCAllFirst = {
          name: 'ALL',
          value: '',
          key: 'ALL',
          label: 'ALL',
          id: 10001
        };
*/
        factoryCodes = _.sortBy(factoryCodes, 'name');
        // factoryCodes = [newFCAllFirst, ...factoryCodes];
      }
      const newArrItemOb = {
        name: fgaItem.factoryGroup || '',
        value: fgaItem.factoryGroup || '',
        key: fgaItem.factoryGroup || '',
        label: fgaItem.factoryGroup || '',
        id: indexSub + 1,
        factoryCodes: factoryCodes
      };

      factoryGroups.push(newArrItemOb);
    });
    factoryCodesOnly = _.uniqBy(factoryCodesOnly, 'key');
    if (factoryCodesOnly.length > 0) {
      /* const newFCAllFirst = {
        name: 'ALL',
        value: '',
        key: 'ALL',
        label: 'ALL',
        id: 10001
      };
    */
      factoryCodesOnly = _.sortBy(factoryCodesOnly, 'name');
      // factoryCodesOnly = [newFCAllFirst, ...factoryCodesOnly];
    }
    userResp.factoryCodes = factoryCodesOnly;
    userResp.factoryGroups = factoryGroups;
    userResp.factoryCodesArr = _.map(factoryCodesOnly, 'name');
    userResp.factoryGroupsArr = _.map(factoryGroups, 'name');
  }
  return userResp;
};
export default function userProfile(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('getUserProfile', 'loading').type:
      return state.set('userProfileDataLoading', action.data);

    case createServiceAction('getUserProfile', 'success').type:
      return state.set('userProfileData', setStorageUser(action.data));

    case createServiceAction('getUserProfile', 'clear').type:
      return state.set('userProfileData', action.data);

    case createServiceAction('getUserProfile', 'error').type:
      return state.set('userProfileError', action.data);

    default:
      return state;
  }
}

export const getUserProfileDataLoading = state =>
  state.userProfile.get('userProfileDataLoading');
export const getUserProfileData = state =>
  state.userProfile.get('userProfileData');
export const getUserProfileError = state =>
  state.userProfile.get('userProfileError');
