import React from 'react';
import { Container, H1 } from '../index.js';

const NotAuthorized = () => (
  <Container section>
    <H1>
      <span role="img" aria-label=" Not Authorized">
        🔒
      </span>
      You are not authorized to view this page
    </H1>
  </Container>
);
export default NotAuthorized;
