import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import Grid from '../grid/Grid.js';
import { H5, IconButton } from '../index.js';
import gridConstants from '../../constants/gridConstants.js';

const gridDefault = {
  resizable: true,
  editable: false,
  sortable: true,
  filter: true
};

const pQGridHeader = [
  {
    headerName: 'Factory Code ',
    field: 'factoryCode',
    filter: true,
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 120
  },
  {
    headerName: 'Capacity Type Code ',
    field: 'capacityTypeCode',
    filter: true,
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 120
  },
  {
    headerName: 'Production Quarter',
    field: 'productionQuarter',
    filter: true,
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 120,
    valueGetter: params => {
      let pQ = '';
      if (params && params.data && params.data.productionQuarter) {
        let pdQ = String(params.data.productionQuarter);
        if (pdQ.includes('-Q')) {
          pQ = pdQ;
        } else {
          pQ = `${pdQ.substring(0, 4)}-Q${pdQ.substring(4, 5)}`;
        }
      }
      return pQ;
    }
  },
  {
    headerName: 'Type ',
    field: 'type',
    filter: true,
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 120
  },
  {
    headerName: 'Expected Variance',
    field: 'expectedValue',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 250
  },
  {
    headerName: 'Actual Variance',
    field: 'actualValue',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 250
  },
  {
    headerName: 'Difference',
    field: 'difference',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    headerClass: 'gdGridHeadLabelPinned',
    width: 250
  }
];

export default class GBDVarianceValidationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ParamsQuarterExcel: {},
      getRowHeight(params) {
        let defaultHeight = 28;

        if (params.data && params.data.value) {
          const h = Math.ceil(params.data.value.length / 40);
          if (h > 1) {
            defaultHeight = 28 * h;
          }
        }
        return defaultHeight;
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    // On page load, loading max available dashboard as default
  }

  setPQParams = params => {
    // Function called post GRID initial load to reassure column structure and fields

    if (params) {
      this.setState({ paramsQuarterExcel: params }); // update AG Grid Properties to state

      if (params.columnApi.getAllColumns()) {
        try {
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel();
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  downloadData = () => {
    // Function to download the current grid data (upload status report) to excel sheet

    // to apply custom footer which will have to drop down values for short horizon and status
    const { paramsQuarterExcel } = this.state;

    const today = new Date();
    const date = `${today.getFullYear()}-${today.getMonth() +
      1}-${today.getDate()}:${today.getHours()}-${today.getMinutes()}`;

    const params = {
      fileName: `Capacity-Levers-Variance-${date}-Report`,
      sheetName: 'Validation',
      processCellCallback(paramsl) {
        if (paramsl.column.colDef.field === 'status') {
          return paramsl.value ? 'SUCCESS' : 'FAILED';
        }
        return paramsl.value;
      }
    };
    paramsQuarterExcel.api.exportDataAsExcel(params);
  };

  render() {
    const { getRowHeight } = this.state;
    const {
      varianceValidationModal,
      varianceValidationResult,
      toogleValidationModel
    } = this.props;
    return (
      <Modal
        isOpen={varianceValidationModal}
        className="modal-container modal-container-gbl modal-container-big"
        overlayClassName="modal-overlay"
      >
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() => toogleValidationModel()}
        />
        <div className="ncss-row">
          <div className="ncss-col-sm-6 ">
            <H5 className="u-bold  fl-sm-l p2-top-sm p2-bottom-sm text-color-success">
              Capacity Levers Variance Validation Result
            </H5>
          </div>
          <div className="ncss-col-sm-6 ta-sm-r pr8-sm gbd-validation-modal-margin-bottom">
            <button
              type="button"
              onClick={() => this.downloadData()}
              className="fl-sm-r  ncss-btn-secondary-lite"
            >
              <i className="fas fa fa-download" /> DOWNLOAD VALIDATION REPORT
            </button>
          </div>
        </div>

        <div className="ncss-col-sm-12 ">
          {varianceValidationResult && varianceValidationResult.length > 0 ? (
            <div className="border numberList">
              <div
                id="myGrid1"
                style={{ height: '250px', width: '100%' }}
                className="ag-theme-balham g-b-d"
              >
                <Grid
                  defaultColDef={gridDefault}
                  columnDefs={pQGridHeader}
                  rowData={varianceValidationResult}
                  setParams={this.setPQParams}
                  dbGridFunctions={false}
                  headerHeight={60}
                  setheight="55vh"
                  getRowHeight={getRowHeight}
                  enableCellChangeFlash={true}
                  excelStyles={gridConstants.excelStyles}
                />
              </div>
            </div>
          ) : (
            <div className="border" />
          )}
          <div className="bottom-section p4-top-sm popup-bottom" />
        </div>
      </Modal>
    );
  }
}

GBDVarianceValidationModal.propTypes = {
  varianceValidationModal: PropTypes.bool,
  varianceValidationResult: PropTypes.any,
  toogleValidationModel: PropTypes.func
};
