import React from 'react';
import 'rc-tooltip/assets/bootstrap.css';

import { numberWithCommas } from '../../util/general.js';

export const Summary = ({
  capacityInfo,
  factoryType,
  capacityTypeDescription,
  partialQuarter,
  defaultTabIndex,
  varianceInfo,
  defaultStatus
}) => {
  const {
    workingDays,
    numberOfHolidays,
    FctyMaxCapacity,
    FctyMaxHldCpty,
    maxAvailableCapacity,
    FctyRvsdHldCpty,
    FctyStratHldCpty,
    shortHorizonCapacity,

    CapQMaxCapVarPresent,
    CapQMaxCapVarPrevious,
    CapQMaxCapAdjVarPresent,
    CapQMaxCapAdjVarPrevious,

    MaxQMaxCapVarPresent,
    MaxQMaxCapVarPrevious,
    MaxQMaxCapAdjVarPresent,
    MaxQMaxCapAdjVarPrevious
  } = capacityInfo;

  let field1Value = factoryType === 'FW' ? FctyMaxCapacity : FctyMaxHldCpty;

  let field2Value =
    factoryType === 'FW'
      ? shortHorizonCapacity || shortHorizonCapacity === 0
        ? shortHorizonCapacity
        : maxAvailableCapacity
      : FctyRvsdHldCpty || FctyRvsdHldCpty === 0
      ? FctyRvsdHldCpty
      : FctyStratHldCpty;

  if (defaultStatus === 'MaxAvailable') {
    field2Value =
      factoryType === 'FW' ? maxAvailableCapacity : FctyStratHldCpty;
  } else {
    if (factoryType === 'FW') {
      field2Value =
        shortHorizonCapacity || shortHorizonCapacity === 0
          ? shortHorizonCapacity
          : maxAvailableCapacity;
    } else {
      field2Value =
        FctyRvsdHldCpty || FctyRvsdHldCpty === 0
          ? FctyRvsdHldCpty
          : FctyStratHldCpty;
    }
  }

  let field1Name = '';
  let field2Name = '';
  let varianceName =
    factoryType === 'FW'
      ? varianceInfo[defaultTabIndex].headerName
      : 'Adjusted Max Capacity Variance';
  if (!varianceName.endsWith('Variance')) {
    varianceName += ' Variance';
  }
  const variancevalue =
    factoryType === 'FW'
      ? varianceInfo[defaultTabIndex].value
      : varianceInfo[defaultTabIndex].adjValue;
  switch (defaultTabIndex) {
    case 1:
      field1Name = 'Current Factory Capacity';
      field2Name = 'Previous Factory Capacity';
      field1Value =
        factoryType === 'FW' ? CapQMaxCapVarPresent : CapQMaxCapAdjVarPresent;
      field2Value =
        factoryType === 'FW' ? CapQMaxCapVarPrevious : CapQMaxCapAdjVarPrevious;
      break;
    case 2:
      field1Name = 'Current Factory Max Capacity';
      field2Name = 'Previous Factory Max Capacity';
      field1Value =
        factoryType === 'FW' ? MaxQMaxCapVarPresent : MaxQMaxCapAdjVarPresent;
      field2Value =
        factoryType === 'FW' ? MaxQMaxCapVarPrevious : MaxQMaxCapAdjVarPrevious;
      break;
    default:
      field1Name = 'Factory Max Capacity';
      field2Name = 'Factory Capacity';
  }
  return (
    <div>
      {partialQuarter && (
        <div className="partialQuarterWarning">
          This is partial Quarter and Cannot be Modified
        </div>
      )}
      <div className={` ncss-row lever-page-container m-20`}>
        <div className="ncss-col-sm-6 pr6-sm">
          <table className="leverQuarterSummaryTable">
            <tbody>
              <tr>
                <th>Total Factory Work Days</th>
                <td>{workingDays || '0'}</td>
              </tr>
              <tr>
                <th className="">Total Holidays </th>
                <td>{numberOfHolidays || '0'}</td>
              </tr>
              <tr>
                <th>Capacity Type Desc</th>
                <td>{capacityTypeDescription || ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="ncss-col-sm-6  pl6-sm">
          <table className="leverQuarterSummaryTable">
            <tbody>
              <tr>
                <th>
                  {factoryType === 'FW' ? '' : 'Adjusted'} {field1Name}
                </th>
                <td>
                  {field1Value || field1Value === 0
                    ? numberWithCommas(field1Value)
                    : '—'}
                </td>
              </tr>
              <tr>
                <th className="pb2-sm">
                  {factoryType === 'FW' ? '' : 'Adjusted'} {field2Name}
                </th>
                <td>
                  <span className="text-color-grey symbol-position">-</span>
                  {field2Value || field2Value === 0
                    ? numberWithCommas(field2Value)
                    : '—'}
                </td>
              </tr>
              <tr>
                <th className="border-top-medium-grey pt2-sm">
                  {factoryType === 'FW'
                    ? varianceName
                    : 'Adjusted Max Capacity Variance'}
                </th>
                <td className="border-top-medium-grey pt2-sm">
                  <span className="text-color-grey symbol-position">=</span>
                  {variancevalue || variancevalue === 0
                    ? numberWithCommas(variancevalue)
                    : '—'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Summary;
