const adminConstants = {
  PageTitle: 'ADMIN PANEL',
  nikeUserPageTitle: 'COLUMN MANAGMENT',
  saveInfoButton: 'SAVE',
  gridDefault: {
    resizable: false,
    editable: true,
    sortable: false,
    filter: false,
    singleClickEdit: true
  },

  reasonCodeHeader: [
    {
      headerName: ' ',
      rowDrag: true,
      filter: false,
      editable: false,
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 20,
      sortable: false,
      valueFormatter: params => {
        return ''; //Number(params.node.rowIndex) + 1
      },
      cellClass: 'nonEditableColumn'
    },
    {
      headerName: ' Reason code',
      field: 'value',
      filter: true,
      editable: params => (params.data.newRow ? true : false),
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 300,
      sortable: false,
      cellClass: params =>
        params.data.newRow ? 'editableColumn' : 'nonEditableColumn'
    },
    {
      headerName: 'Status ',
      field: 'status',
      filter: true,
      editable: true,
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 120,
      sortable: false,
      cellClass: 'editableColumn',
      cellRenderer: 'SimpleCellRender',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: () => ({
        values: ['INACTIVE', 'ACTIVE']
      })
    },
    {
      headerName: 'Action',
      field: 'newRow',
      filter: false,
      editable: false,
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 100,
      sortable: false,
      cellRenderer: 'SimpleCellRender',
      cellClass: 'nonEditableColumn'
    }
  ],
  reasonCodeData: [
    {
      category: 'AP',
      value: 'Conventional: Efficiency Gains',
      serial: 14,
      status: 'INACTIVE'
    }
  ],
  customLabels: {
    maxCapacityEditable: 'Max Capacity Editable'
  },
  sampleData: {
    landingPage: 'strategic',
    revisedHorizonWeeks: 28,
    reasonCodes: [
      {
        id: 'd0e64c25-5fbb-4c70-b009-c0fb2ed45bae',
        value: 'Conventional: Efficiency Gains',
        serial: 14,
        category: 'AP',
        status: 'INACTIVE'
      },
      {
        id: 'a2791004-fa87-4765-90bf-e78c13457791',
        value: 'Conventional: New lines in existing building',
        serial: 15,
        category: 'AP',
        status: 'INACTIVE'
      },

      {
        id: 'ec720ae1-cbea-4b5f-b098-f6be69a2dab2',
        value: 'Capacity Release due to Divest/Ramp down',
        serial: 46,
        category: 'AP',
        status: 'ACTIVE'
      }
    ]
  }
};

export default adminConstants;
