import { fromJS } from 'immutable';
import * as moment from 'moment';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  userUpdatesDataLoading: false,
  userUpdatesGridLoading: false,
  userUpdatesDataUpdate: {},

  userOnUpdateData: {},
  userOnUpdateLoading: {},
  userOnUpdateError: {},

  userOnUpdateNotifyReadData: {},
  userOnUpdateNotifyReadLoading: {},
  userOnUpdateNotifyReadError: {}
});

const modifyUserUpdatesData = resp => {
  const dataResponse = resp.dashboardData;

  const { data, pagination, reportColumns } = dataResponse;
  //let { holidayData } = dashBoardDataResponse;
  //holidayData = holidayData ? holidayData : {};
  const sc = [
    {
      read: 0,
      pend: 0,
      statusCode: ''
    },
    {
      read: 0,
      pend: 0,
      statusCode: 'Factory'
    },
    {
      read: 0,
      pend: 0,
      statusCode: 'Confirmed'
    },
    {
      read: 0,
      pend: 0,
      statusCode: 'Approved'
    },
    {
      read: 0,
      pend: 0,
      statusCode: 'Max'
    }
  ];
  if (data) {
    try {
      data.forEach(dataItem => {
        if (reportColumns) reportColumns.forEach(headerItem => {});
        if (dataItem.productionQuarter) {
          const y = String(dataItem.productionQuarter) || '';
          dataItem.productionQuarter = `${y.substring(0, 4)}-${
            y.includes('Q') ? '' : 'Q'
          }${y.substring(4, 6)}`;
        }
        if (dataItem.lastModified) {
          //moment.unix().format()
          dataItem.changeTime = moment(dataItem.lastModified).format(
            'MM/DD/YYYY hh:mm A'
          );
        }
        let curnVal = dataItem.currentValue || 0;
        let prevVal = dataItem.previousValue || 0;
        dataItem.changeValue = curnVal - prevVal;
        let delta = prevVal === 0 ? 100 : ((curnVal - prevVal) / prevVal) * 100;

        dataItem.delta = delta ? Number(delta.toFixed(2)) : delta;
        //for score
        if (dataItem.changeType) {
          sc.forEach((sci, i) => {
            if (i > 0 && sci.statusCode === dataItem.changeType) {
              if (dataItem.readStatus && dataItem.readStatus !== 'read') {
                sci.read += 1;
              }
              if (dataItem.pending) {
                sci.pend += 1;
              }
            }
          });
        }
      });
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }
  }
  if (pagination) {
    if (
      pagination.back &&
      Object.keys(pagination.back).length > 0 &&
      (pagination.back.count === null || pagination.back.count === 'null')
    ) {
      pagination.back = {};
    }
    if (
      pagination.next &&
      Object.keys(pagination.next).length > 0 &&
      (pagination.back.next === null || pagination.next.count === 'null')
    ) {
      pagination.back = {};
    }
  }

  dataResponse.data = data;
  dataResponse.sc = sc;
  dataResponse.pagination = pagination;
  dataResponse.time = new Date().getTime();
  return dataResponse;
};

export default function userUpdates(state = defaultState, action) {
  state = fromJS(state);
  switch (action.type) {
    case createServiceAction('userUpdatesData', 'loading').type:
      return state.set('userUpdatesDataLoading', action.data);

    case createServiceAction('userUpdatesData', 'success').type:
      return state.set('userUpdatesData', modifyUserUpdatesData(action.data));

    case createServiceAction('userUpdatesData', 'clear').type:
      return state.set('userUpdatesData', action.data);

    case createServiceAction('userUpdatesData', 'error').type:
      return state.set('userUpdatesError', action.data);

    case createServiceAction('updateUserUpdateData', 'loading').type:
      return state.set('userOnUpdateLoading', action.data);

    case createServiceAction('updateUserUpdateData', 'success').type:
      return state.set('userOnUpdateData', action.data);

    case createServiceAction('updateUserUpdateData', 'error').type:
      return state.set('userOnUpdateError', action.data);

    case createServiceAction('updateUserUpdateData', 'clear').type:
      return state.set('userOnUpdateData', action.data);

    default:
      return state;
  }
}

export const getUserUpdatesDataLoading = state =>
  state.userUpdates.get('userUpdatesDataLoading');
export const getUserUpdatesData = state =>
  state.userUpdates.get('userUpdatesData');
export const getUserUpdatesError = state =>
  state.userUpdates.get('userUpdatesError');

export const getUpdatedUserUpdatesData = state =>
  state.userUpdates.get('userOnUpdateData');
export const getUpdatedUserUpdatesLoading = state =>
  state.userUpdates.get('userOnUpdateLoading');
export const getUpdatedUserUpdatesError = state =>
  state.userUpdates.get('userOnUpdateError');
