/* eslint-disable react/destructuring-assignment */
import { connect } from 'react-redux';
import React from 'react';
import qs from 'qs';
import history from '../../util/history.js';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Container, H3, Badge } from '../../components/index.js';
import { navMenuToggle } from '../../actions/navigationActions.js';
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import { msgConstants } from '../../constants/msgConstants.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import {
  isFactoryUser,
  isFWSUser,
  isNLOUser,
  selectFullName,
  selectUserAccessToken
} from '../../reducers/authReducer.js';
import { verifyTrial } from '../../util/general.js';
const CapChangeNotificationShow = verifyTrial('ccn');
const returnURLQuery = toView => {
  const currentURL = window.location.href;
  const queryString = window.location.search;
  const fromView = currentURL.includes('strategic')
    ? 'strategicCapacity'
    : 'revisedCapacity';
  const query = qs.parse(queryString.replace('?', ''));
  let filtered = {};
  if (query && Object.keys(query).length > 0) {
    let allowed = ['factoryGroup', 'factoryCode', 'capacityTypeCode'];
    if (fromView === toView) {
      allowed = [
        'factoryGroup',
        'factoryCode',
        'capacityTypeCode',
        'startWeekDate',
        'endWeekDate'
      ];
    }
    filtered = Object.keys(query)
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = query[key];
        return obj;
      }, {});
  }
  if (
    localStorage.getItem('isRowDataChanged') === 'true' ||
    localStorage.getItem('isQuarterRowDataChanged') === 'true' ||
    localStorage.getItem('isGBDQuarterRowDataChanged') === 'true' ||
    localStorage.getItem('isLeverQuarterDataChanged') === 'true'
  ) {
    confirmAlert({
      message: msgConstants.pageChangeAlert,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            localStorage.setItem('isRowDataChanged', 'false');
            localStorage.setItem('isQuarterRowDataChanged', 'false');
            localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
            localStorage.setItem('isLeverQuarterDataChanged', 'false');
            history.push(`/user-updates/${toView}?${qs.stringify(filtered)}`);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  } else {
    history.push(`/user-updates/${toView}?${qs.stringify(filtered)}`);
  }
};
const Header = ({
  isOpen,
  navMenuToggle,
  name,
  userToken,
  isFactoryUser,
  isFWSUser,
  isNLOUser,
  showMenu,
  userProfileData,
  currentEnv,
  notification
}) => (
  <Container
    section
    className={`header-container  ${isOpen ? 'hide-header' : ''} ${
      currentEnv ? 'showEnv' : ''
    }`}
  >
    {currentEnv ? (
      <div className={`ncss-row envHighlight ${currentEnv}`}>
        <div className="nc"> {currentEnv.toUpperCase()} ENVIRONMENT</div>
      </div>
    ) : null}
    <div className="ncss-row headerContent">
      <div className={`ncss-col-sm-8 ${showMenu} ${name}`}>
        {name && showMenu && userToken ? (
          <button
            type="button"
            className="menu-btn inline-display ncss-btn fs16-sm"
            onClick={() => {
              if (
                localStorage.getItem('isRowDataChanged') === 'true' ||
                localStorage.getItem('isQuarterRowDataChanged') === 'true' ||
                localStorage.getItem('isGBDQuarterRowDataChanged') === 'true' ||
                localStorage.getItem('isLeverQuarterDataChanged') === 'true' ||
                localStorage.getItem('isLeverAdminDataChanged') === 'true' ||
                localStorage.getItem('isLRCPDataChanged') === 'true'
              ) {
                confirmAlert({
                  message: msgConstants.pageChangeAlert,
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                        localStorage.setItem('isRowDataChanged', 'false');
                        localStorage.setItem(
                          'isQuarterRowDataChanged',
                          'false'
                        );
                        localStorage.setItem(
                          'isGBDQuarterRowDataChanged',
                          'false'
                        );
                        localStorage.setItem(
                          'isLeverQuarterDataChanged',
                          'false'
                        );
                        localStorage.setItem(
                          'isLeverAdminDataChanged',
                          'false'
                        );
                        localStorage.setItem('isLRCPDataChanged', 'false');
                        navMenuToggle();
                      }
                    },
                    {
                      label: 'No'
                    }
                  ]
                });
              } else if (
                localStorage.getItem('isProfileSettingsChanged') === 'true'
              ) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="c-f-all bg-offwhite p2r-sm">
                        <h1 className="text-color-black  page-title-has-back fs-24px pt0-sm">
                          Unsaved Changes
                        </h1>
                        <p className="fs-14px">
                          You have unsaved changes on this page. Do you wish to
                          continue?
                        </p>
                        <div className="pt4-sm ta-sm-r ">
                          <button
                            onClick={onClose}
                            className="  ncss-btn-secondary mr-2-sm"
                          >
                            Back
                          </button>
                          <button
                            className="  ncss-btn-primary-dark"
                            onClick={() => {
                              localStorage.setItem(
                                'isProfileSettingsChanged',
                                'false'
                              );
                              onClose();
                              navMenuToggle();
                            }}
                          >
                            Don't Save
                          </button>
                        </div>
                      </div>
                    );
                  }
                });
              } else {
                navMenuToggle();
              }
            }}
          >
            <Badge />

            <i className="g72-menu" />
          </button>
        ) : null}
        <H3 className="header-brand  fl-sm-l">
          <i className="g72-swoosh nav-brand fs22-sm menu-logo" />
          <span className="u-bold">Capacity Visibility</span>
        </H3>
      </div>
      <div className="ncss-col-sm-4 u-bold ta-sm-r">
        {name ? (
          <div className="header-user-name  fl-sm-r d-sm-b relative-position ">
            {CapChangeNotificationShow ? (
              <div className="d-sm-ib  fl-sm-l pr4-sm c-f-all cMenu1">
                <Menu
                  menuButton={
                    <div className="relative-position   ta-sm-l pl3-sm ">
                      <div className={`create-icon-svg  `}>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          preserveAspectRatio="xMidYMid meet"
                          fill="currentColor"
                        >
                          <path d="M12.136842,17.9154 C12.1381782,17.9434619 12.1395145,17.9688513 12.1395145,17.9969132 C12.1395145,19.1033547 11.1613561,20 9.95602964,20 C8.74936694,20 7.77120847,19.1033547 7.77120847,17.9969132 C7.77120847,17.9688513 7.77254475,17.9434619 7.77388103,17.9154 L7.77388103,17.9154 Z M9.95576238,0 C10.4849301,0 10.9178855,0.318035131 10.9379297,1.00755668 C13.7788654,1.48728194 15.9449786,4.00884619 15.9449786,7.04888788 C15.9449786,7.27471955 16.1921908,10.9575129 16.1921908,10.9575129 C16.1921908,10.9575129 16.3632349,12.9138299 17.0273671,13.6862009 C17.5097649,14.2487756 18.0309149,14.5173683 18.2794633,14.6215983 C18.6402595,14.7044478 18.9101885,15.0198104 18.9101885,15.3939694 L18.9101885,15.3939694 L18.9101885,15.4086685 C18.9101885,15.8456327 18.5427109,16.20242 18.0937201,16.20242 L18.0937201,16.20242 L1.81646834,16.20242 C1.36747757,16.20242 1,15.8456327 1,15.4086685 L1,15.4086685 L1,15.3939694 C1,15.0799431 1.19108833,14.8073415 1.46636244,14.6777222 L1.46636244,14.6777222 L1.4249377,14.6777222 C1.4249377,14.6777222 2.16256539,14.4572356 2.82669758,13.6862009 C3.48949348,12.9138299 3.6592013,10.9575129 3.6592013,10.9575129 C3.6592013,10.9575129 3.96654618,7.27471955 3.96654618,7.04888788 C3.96654618,4.00884619 6.1326594,1.48728194 8.97225878,1.00755668 C8.9936393,0.318035131 9.4252584,0 9.95576238,0 Z"></path>
                        </svg>
                      </div>
                      <div className="p-a create-icon-svg-note">
                        {notification.unreadTotal > 0 && (
                          <span className="notification-no-count-small bg-primary "></span>
                        )}

                        {notification.pendingTotal > 0 && (
                          <span className="notification-no-count-small bg-amber ml-2-sm "></span>
                        )}
                      </div>
                    </div>
                  }
                >
                  <MenuItem onClick={() => returnURLQuery('strategicCapacity')}>
                    <span className="notification-count-side bg-primary">
                      {notification.strategic.unread}
                    </span>
                    <span className="notification-count-side bg-amber">
                      {notification.strategic.pending}
                    </span>
                    <span className="d-sm-h d-md-ib pl2-sm pt1-sm">
                      Strategic View
                    </span>
                  </MenuItem>
                  <MenuItem onClick={() => returnURLQuery('revisedCapacity')}>
                    <span className="notification-count-side bg-primary">
                      {notification.revised.unread}
                    </span>
                    <span className="notification-count-side bg-amber">
                      {notification.revised.pending}
                    </span>
                    <span className="d-sm-h d-md-ib pl2-sm pt1-sm">
                      {' '}
                      Revised View
                    </span>
                  </MenuItem>
                </Menu>
              </div>
            ) : null}
            <div className="d-sm-ib  fl-sm-l  "> {name}</div>
          </div>
        ) : null}
      </div>
    </div>
  </Container>
);

Header.propTypes = {
  isOpen: PropTypes.bool,
  navMenuToggle: PropTypes.func,
  name: PropTypes.string,
  userToken: PropTypes.string,
  isFactoryUser: PropTypes.bool,
  isFWSUser: PropTypes.bool,
  isNLOUser: PropTypes.bool,
  showMenu: PropTypes.bool,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  currentEnv: PropTypes.any,
  notification: PropTypes.any
};

export default connect(
  state => ({
    isOpen: selectIsMenuOpen(state),
    name: selectFullName(state),
    userToken: selectUserAccessToken(state),
    isFactoryUser: isFactoryUser(state),
    isFWSUser: isFWSUser(state),
    isNLOUser: isNLOUser(state),
    userProfileData: getUserProfileData(state),
    userProfileError: getUserProfileError(state)
  }),
  { navMenuToggle }
)(Header);
