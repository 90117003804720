import * as moment from 'moment';
import _ from 'lodash';
import { holidayCalculator } from '../util/growthLeverGridConfigure.js';
import * as XLSX from 'xlsx/xlsx.mjs';

// for ag grid custom footer download
export const fetchAllQueryValue = (gridItem, searchQuery) => {
  const allSelVal = gridItem.options.find(
    o => o.name === searchQuery.factoryCode
  );
  return allSelVal;
};
// for search post validation frame query
export const searchQueryFrame = (
  num,
  searchGroups,
  searchParams,
  gridPagination,
  recCount,
  revisedWeeks,
  defaultStatus
) => {
  const count = recCount || 104;
  let allString = '';
  const factCode = searchGroups.find(o => o.field === 'factoryCode');
  const returFactoryCodeValue = fetchAllQueryValue(factCode, true) || '';

  if (num === 1) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCodes=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    } else {
      const factGrp = searchGroups.find(o => o.field === 'factoryGroup');
      const returFactoryGrpValue = fetchAllQueryValue(factGrp, true) || '';
      const updateVal = `factoryGroup=${returFactoryGrpValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
  } else if (num === 2) {
    if (returFactoryCodeValue) {
      const updateVal = `factoryCode=${returFactoryCodeValue}`;
      allString += allString ? `&${updateVal}` : updateVal;
    }
    let actualStartWeekDate = null;
    let qStart = null;
    let qEnd = null;
    if (searchParams && searchParams.length > 0) {
      searchParams.forEach(gridItem => {
        let returValue = '';
        switch (gridItem.field) {
          case 'capacityTypeCode':
            returValue = fetchAllQueryValue(gridItem, false);
            break;
          case 'startWeekDate':
            if (gridItem.value) {
              actualStartWeekDate = moment(gridItem.value, 'MM/DD/YYYY')
                .subtract(3, 'months')
                .utc()
                .startOf('month')
                .startOf('isoweek')
                .add(1, 'w');
              if (actualStartWeekDate.date() > 7) {
                //
                actualStartWeekDate = actualStartWeekDate.isoWeekday(-6);
              }
            }
            if (actualStartWeekDate) {
              qStart = moment(actualStartWeekDate);
            } else {
              qStart = moment(gridItem.fromMonth);
            }
            const startDateSel = moment(gridItem.value).diff(
              moment(gridItem.fromMonth),
              'week'
            );
            if (startDateSel > 0) {
              returValue = gridItem.value
                ? `${gridItem.field}=${moment(actualStartWeekDate).format(
                    'YYYYQ'
                  )}&actualStartWeekDate=${moment(
                    new Date(gridItem.value)
                  ).format('YYYYQ')}`
                : '';
            }
            break;
          case 'endWeekDate':
            if (gridItem.value) {
              qEnd = moment(gridItem.value)
                .endOf('quarter')
                .startOf('isoweek');

              const endDay = qEnd.weekday();
              if (endDay < 1) {
                qEnd = qEnd.weekday(1);
              }
              if (qEnd > moment(gridItem.toMonth)) {
                qEnd = moment(gridItem.toMonth);
              }
            } else {
              qEnd = moment(gridItem.toMonth);
            }
            const endDateSel = moment(gridItem.value).diff(
              moment(gridItem.toMonth),
              'week'
            );
            if (endDateSel < 0) {
              returValue = gridItem.value
                ? `${gridItem.field}=${qEnd.format('YYYYQ')}`
                : '';
            }
            break;
          default:
            returValue = '';
        }
        if (returValue) {
          // const updateVal = `${gridItem.field}=${returValue}`;
          const updateVal = returValue.includes(`${gridItem.field}`)
            ? `${returValue}`
            : `${gridItem.field}=${returValue}`;
          allString += allString ? `&${updateVal}` : updateVal;
        }
      });
    }

    if (Object.keys(gridPagination).length > 0) {
      const queryString = Object.keys(gridPagination)
        .map(key => `${key}=${gridPagination[key]}`)
        .join('&');
      allString += `${allString ? '&' : '?'}${queryString}`;
    } else {
      let toChek = qEnd.diff(qStart, 'week') + 1;
      const range = toChek ? Math.ceil(count / toChek) * toChek : count;
      allString += `${allString ? '&' : '?'}count=${range}&anchor=1`;
    }
    if (revisedWeeks >= 0) {
      allString += `${allString ? '&' : '?'}boundaryWeek=${revisedWeeks}`;
    }
  }
  return allString;
};

export const getCTCList = (gridItem, capacityTypeCode, factoryCode) => {
  let allSelVal = [];
  if (capacityTypeCode) {
    allSelVal = [
      {
        fc: factoryCode,
        label: capacityTypeCode,
        name: capacityTypeCode,
        value: capacityTypeCode
      }
    ];
  } else {
    if (gridItem.options && gridItem.options.length > 0) {
      allSelVal = gridItem.options
        .filter(item => item.fc === factoryCode)
        .sort((a, b) => a.fc.localeCompare(b.fc));
    }
  }
  return allSelVal;
};

// for ag grid custom footer download
export const agGridDownloadFileName = (
  defaultStatus,
  userType,
  dashboardType,
  userName,
  isToggleOn
) => {
  const date = moment().format('MM-DD-YYYY-HH-mm-ss');
  const viewIs = defaultStatus === 'MaxAvailable' ? 'STRATEGIC' : 'REVISED';
  const typeIs = dashboardType === 'view' ? 'UPLOAD' : 'VIEW';
  const gridView = !isToggleOn ? 'WEEKLY' : 'QUARTERLY';
  const fileInfo = {
    fileName: `CAPVIZ-CAPACITY-LEVERS-${viewIs}-${typeIs}-${gridView}-${userType}-${date}`,
    sheetName: `${viewIs} CAPACITY LEVERS ${typeIs}`,
    fileID: `CAPVIZ-CAPACITY-LEVERS-${viewIs}-${typeIs}-${gridView}-${userName}-${userType}-${date}`
  };
  return fileInfo;
};

// for ag grid custom footer download
export const agGridCustomFooter = getAllColumns => {
  let customfooter = [];
  let reasonCodeOptions = [];
  getAllColumns.forEach(column => {
    if (column.colDef.field === 'maxCapacityReasonCode') {
      const onlySelectValues = column.colDef.cellEditorParams();
      // reasonCodeOptions = column.colDef.filterParams.values;
      reasonCodeOptions = onlySelectValues.values;
      if (reasonCodeOptions) {
        customfooter.push([]);
        customfooter.push([]);
        customfooter.push([
          {
            styleId: 'header',
            // mergeAcross: 2,
            data: {
              type: 'String',
              value: 'Reason Codes'
            }
          }
        ]);
        reasonCodeOptions.forEach(value => {
          let footerContent = [
            {
              styleId: 'footerContent',
              //  mergeAcross: 2,
              data: {
                type: 'String',
                value: value || ''
              }
            }
          ];

          customfooter.push(footerContent);
        });
      }
    }
  });
  return customfooter;
};

// for ag grid excel data pre formatter
export const agGridDownloadDataFormat = paramsl => {
  if (paramsl.value) {
    if (paramsl.column.colDef.filter === 'agDateColumnFilter') {
      const replaceDate = new Date(paramsl.value);
      return moment(replaceDate).format('MM/DD/YYYY');
    }
    if (paramsl.column.colDef.field === 'productionQuarter') {
      const yearIs = String(paramsl.value) || '';
      if (paramsl.value) {
        return `${yearIs.substring(0, 4)}-Q${yearIs.substring(4, 5)}`;
      }
    }

    if (paramsl.column.colDef.headerName === 'Notes') {
      return '';
    }
  }
  if (paramsl.column.colDef.field === 'numberOfHolidays' && !paramsl.value) {
    return `0`;
  }
  return paramsl.value;
};

export const searchQueryCTFrame = (
  searchParams,
  searchQuery,
  defaultStatus,
  revisedHorizonWeeks
) => {
  //  const count = maxQuarterLimit || 104;
  let actualStartWeekDate = null;

  let qStart = moment()
    .startOf('week')
    .add(1, 'day');

  let qEnd = moment().add(revisedHorizonWeeks, 'weeks');
  const weekEnd = moment(qEnd)
    .endOf('week')
    .startOf('week')
    .add(1, 'day');
  const quaterEnd = moment(qEnd)
    .endOf('quarter')
    .startOf('week')
    .add(1, 'day');
  if (!weekEnd.isSame(quaterEnd)) {
    qEnd.subtract(1, 'quarter');
  }
  qEnd
    .endOf('quarter')
    .startOf('week')
    .add(1, 'day');
  if (defaultStatus !== 'shortHorizon') {
    qStart = qEnd.add(1, 'weeks');
    const weekDiff = qEnd.diff(qStart, 'weeks');
    qEnd = moment().add(260 - weekDiff - 1, 'weeks');
  }
  const returnRes = {};
  if (searchParams && searchParams.length > 0) {
    searchParams.forEach(gridItem => {
      switch (gridItem.field) {
        case 'factoryCode':
          returnRes.factoryCode = searchQuery.factoryCode;
          break;
        case 'capacityTypeCode':
          returnRes.capacityTypeCode = getCTCList(
            gridItem,
            searchQuery.capacityTypeCode,
            searchQuery.factoryCode
          );
          break;
        case 'startWeekDate':
          let qFrom = moment(qStart);
          if (searchQuery.startWeekDate) {
            qFrom = moment(searchQuery.startWeekDate, 'MM-DD-YYYY');
          }
          const startDateSel = qStart.diff(qFrom, 'months');
          if (startDateSel >= 0) {
            actualStartWeekDate = moment(qFrom)
              .subtract(3, 'months')
              .startOf('month');

            const prevDiff = actualStartWeekDate.diff(qFrom, 'months');
            if (prevDiff > 1) {
              returnRes.startWeekDate = actualStartWeekDate.format('YYYYQ');
            } else if (prevDiff === 1) {
              returnRes.startWeekDate = qFrom.format('YYYYQ');
            }
          }
          returnRes.actualStartWeekDate = moment(new Date(qFrom)).format(
            'YYYYQ'
          );
          break;
        case 'endWeekDate':
          let qStop = moment(qEnd);
          if (searchQuery.endWeekDate) {
            qStop = moment(searchQuery.endWeekDate, 'MM-DD-YYYY')
              .endOf('quarter')
              .startOf('isoweek');
            const endDateSel = moment(
              searchQuery.endWeekDate,
              'MM-DD-YYYY'
            ).diff(moment(qEnd), 'week');
            if (endDateSel < 0) {
              returnRes.endWeekDate = qStop.format('YYYYQ');
            }
          }
          break;
        default:
          returnRes.returValue = '';
      }
    });
  }
  // let toChek = qEnd.diff(qStart, 'quarters') + 1;
  let capTypePageLen = Math.ceil(qEnd.diff(qStart, 'months') / 3);
  let pages = 1;
  let count = 1;
  if (returnRes.capacityTypeCode.length > 1) {
    const ccl = returnRes.capacityTypeCode.length;
    const nearBy = 60;
    const perPage = Math.ceil(nearBy / capTypePageLen) * capTypePageLen;
    const pagesIs = Math.ceil((ccl * capTypePageLen) / perPage);
    pages = pagesIs > 1 ? pagesIs : 1;
    count = Math.ceil(ccl / pages);
  }
  returnRes.count = count;
  returnRes.pages = pages;
  return returnRes;
};

// for ag grid custom footer download
export const updateErrorReport = (report, UploadStatusData) => {
  let uploadReport = [...UploadStatusData];
  if (uploadReport) {
    //report.outputData &&
    //let APIErrorReport = [...report.outputData.errors]|| [];
    let APIFailedReport = [];
    if (report && report.outputData && report.outputData.failed) {
      APIFailedReport = [...report.outputData.failed];
    }

    uploadReport.forEach(item => {
      /*
      if (APIErrorReport && APIErrorReport.length > 0) {
        let errorID = APIErrorReport.findIndex(i => i.id === item.id);
        if (errorID >= 0) {
          item.message = 'API VALIDATION ERROR';
          item.status = false;
          item.type = 'API ERROR';
        }
      } */
      if (APIFailedReport && APIFailedReport.length > 0) {
        let errorID = APIFailedReport.findIndex(i => i.id === item.id);

        if (errorID >= 0) {
          item.message = 'DATABASE UPDATING FAILED';
          item.status = false;
          item.type = 'API FAILURE';
        }
      }
    });
  }
  return uploadReport;
};

export const updateFailedReport = UploadStatusData => {
  let uploadReport = [...UploadStatusData];
  if (uploadReport) {
    uploadReport.forEach(item => {
      if (item.status) {
        item.message = 'DATABASE UPDATING FAILED';
        item.status = false;
        item.type = 'API FAILURE';
      }
    });
  }
  return uploadReport;
};

export const gbdVarianceGen = (field, data) => {
  const {
    workingDays,
    numberOfHolidays,
    GbdMaxCapVariance,
    MaxQMaxCapVariance,
    CapQMaxCapVariance
  } = data;
  let retVal = '';
  const workingDayCount = workingDays ? Number(workingDays) : 0;
  const holidays = numberOfHolidays ? Number(numberOfHolidays) : 0;
  const availableWorkingDays = workingDayCount - holidays;
  let field2calculate = '';
  if (workingDays !== 0) {
    switch (field) {
      case 'GbdMaxCapAdjVariance':
        field2calculate = GbdMaxCapVariance;
        break;
      case 'MaxQMaxCapAdjVariance':
        field2calculate = MaxQMaxCapVariance;
        break;
      case 'CapQMaxCapAdjVariance':
        field2calculate = CapQMaxCapVariance;
        break;
      default:
        field2calculate = '';
    }
    if (
      field2calculate === 0 ||
      field2calculate === '0' ||
      (field2calculate && Number(field2calculate) !== 0)
    ) {
      if (retVal % 1 !== 0) {
        retVal = Number((Math.round(retVal * 100) / 100).toFixed(2)); // retVal.toFixed(1);
      } else {
        retVal = (field2calculate / workingDays) * availableWorkingDays;
      }
    }
  }
  return retVal;
};

export const gblExcelUploadRowCompare = (
  excelRow,
  inGridRow,
  colToValidate
) => {
  const newExcelrow = { ...excelRow };
  const newGridrow = { ...inGridRow };
  const excelRowFiltered = _.pick(newExcelrow, colToValidate);
  const inGridRowFiltered = _.pick(newGridrow, colToValidate);

  const cleanUpFun = v => v !== undefined && v !== '' && v !== null;
  const ExcelRowCleaned = _.pickBy(excelRowFiltered, cleanUpFun);
  const inGridRowCleaned = _.pickBy(inGridRowFiltered, cleanUpFun);
  return _.isEqual(ExcelRowCleaned, inGridRowCleaned) ? {} : excelRow;
};

export const validateGBLQuarterlyData = (
  newDataRow,
  backUpGridRow,
  maxReasonCodes = []
) => {
  //** rename of key and assign default null value for excel */
  const {
    FctyMaxCapacity: excelFctyMaxCapacity = null,
    maxCapacityReasonCode: excelMaxCapacityReasonCode = null
  } = newDataRow;

  //** rename of key and assign default null value for grid*/
  const {
    FctyMaxCapacity: agGridFctyMaxCapacity = null,
    maxCapacityReasonCode: agGridMaxCapacityReasonCode = null
  } = backUpGridRow;
  const result = [];

  //**Array to include and exclude keys of fields object   */
  const HeadersFieldsKeyNames = ['FACT_MAX_CAPACITY', 'MAX_REASON_CODE'];

  //**we can change field and field name from here in future */
  const fields = {
    [HeadersFieldsKeyNames[0]]: {
      field: 'FctyMaxCapacity',
      fieldname: 'Fcty Max Cpty'
    },
    [HeadersFieldsKeyNames[1]]: {
      field: 'maxCapacityReasonCode',
      fieldname: 'Max Reason Code'
    }
  };

  const generateSuccessErrorObject = ({ value, status, field, msg }) => {
    const objTemp = {
      value,
      message: status
        ? 'Accepted'
        : msg
        ? msg
        : `Invalid ${fields[field].fieldname}`,
      status: status,
      ...fields[field]
    };
    if (!status) {
      newDataRow[fields[field].field] = backUpGridRow[fields[field].field];
    }
    result.push(objTemp);
  };

  //**check if both values are not available  */
  if (
    !(excelFctyMaxCapacity || excelFctyMaxCapacity === 0) &&
    !excelMaxCapacityReasonCode
  ) {
    generateSuccessErrorObject({
      value: excelFctyMaxCapacity,
      status: false,
      field: HeadersFieldsKeyNames[0]
    });
    generateSuccessErrorObject({
      value: excelMaxCapacityReasonCode,
      status: false,
      field: HeadersFieldsKeyNames[1]
    });
    return { newActRow: newDataRow, result };
  }

  let compareNewAndOldValues = (NewValue, OldValue, field, status) => {
    if (NewValue !== OldValue) {
      generateSuccessErrorObject({
        value: NewValue,
        status,
        field
      });
    }
  };

  //**check number have how many digits */
  const count = n => String(Math.abs(n)).length;

  //**Reason code must be available, string and included in reason code list */
  let conditionOfMaxCapacityReasonCode =
    excelMaxCapacityReasonCode &&
    !_.isNumber(excelMaxCapacityReasonCode) &&
    maxReasonCodes.includes(excelMaxCapacityReasonCode);

  //**Facty Max Capacity must be available, number, positive ,less then 10 digit*/
  let conditionOfFctyMaxCapacity =
    excelFctyMaxCapacity >= 0 &&
    _.isNumber(excelFctyMaxCapacity) &&
    count(excelFctyMaxCapacity) <= 10;

  //**validated Fact Max Capacity and Max reason code */
  //**if condition if both of them valid value */
  //**else condition if one of them is invalid value */
  if (conditionOfFctyMaxCapacity && conditionOfMaxCapacityReasonCode) {
    let factyMaxCapOldAgValue =
      _.isNumber(agGridFctyMaxCapacity) || agGridFctyMaxCapacity === '0'
        ? Number(agGridFctyMaxCapacity)
        : '';
    compareNewAndOldValues(
      Number(excelFctyMaxCapacity),
      factyMaxCapOldAgValue,
      HeadersFieldsKeyNames[0],
      true
    );
    compareNewAndOldValues(
      excelMaxCapacityReasonCode,
      agGridMaxCapacityReasonCode,
      HeadersFieldsKeyNames[1],
      true
    );
  } else {
    //**condition if Facty max capacity has any error */
    if (!conditionOfFctyMaxCapacity) {
      let factyMaxCapOldAgValue =
        _.isNumber(agGridFctyMaxCapacity) || agGridFctyMaxCapacity === '0'
          ? Number(agGridFctyMaxCapacity)
          : '';
      //**if the new wrong facty max capty is not equal to old one  */
      compareNewAndOldValues(
        excelFctyMaxCapacity,
        factyMaxCapOldAgValue,
        HeadersFieldsKeyNames[0],
        false
      );
      //**if fax max capacity has error and reason code change so it would not accept */
      if (
        conditionOfMaxCapacityReasonCode &&
        excelMaxCapacityReasonCode !== agGridMaxCapacityReasonCode
      ) {
        generateSuccessErrorObject({
          value: excelMaxCapacityReasonCode,
          status: false,
          field: HeadersFieldsKeyNames[1],
          msg: `Invalid  ${fields[HeadersFieldsKeyNames[0]].fieldname} `
        });
      }
    }
    //**condition if reason code has any error */
    if (!conditionOfMaxCapacityReasonCode) {
      //**if new wrong reason code is not equal to old one  */
      compareNewAndOldValues(
        excelMaxCapacityReasonCode,
        agGridMaxCapacityReasonCode,
        HeadersFieldsKeyNames[1],
        false
      );
      let factyMaxCapOldAgValue =
        _.isNumber(agGridFctyMaxCapacity) || agGridFctyMaxCapacity === '0'
          ? Number(agGridFctyMaxCapacity)
          : '';
      //**if Max Reason code has error and Max capacity has chnaged then id would not accept */
      if (
        conditionOfFctyMaxCapacity &&
        factyMaxCapOldAgValue !== Number(excelFctyMaxCapacity)
      ) {
        generateSuccessErrorObject({
          value: excelFctyMaxCapacity,
          status: false,
          field: HeadersFieldsKeyNames[0],
          msg: `Invalid ${fields[HeadersFieldsKeyNames[1]].fieldname} `
        });
      }
    }
  }
  return { newActRow: newDataRow, result };
};

export const onCellValueChangFormatValidate = (
  cellFormat,
  cellValue,
  editedColumn,
  reasonCodes
) => {
  let formatAccepted = false;
  const maxLimit = 10;
  if (cellFormat === 'agNumberColumnFilter') {
    const numbersOnly = /^[0-9]+$/;
    if (
      isNaN(cellValue) ||
      Number(cellValue) % 1 !== 0 ||
      Number(cellValue) < 0 ||
      cellValue.toString().length > maxLimit ||
      !String(cellValue).match(numbersOnly)
    ) {
      formatAccepted = false;
    } else {
      formatAccepted = true;
    }
  }
  if (
    cellValue === undefined ||
    reasonCodes.filter(e => e.value === cellValue).length > 0
  ) {
    formatAccepted = true;
  }
  return formatAccepted;
};

export const growthLeverGridConfig = (
  reasonCodes,
  gridColumnsRaw,
  userType,
  userRole,
  defaultStatus,
  revisedViewConfig
) => {
  let gridColumns = [];
  let { maxCapacityEditable } = revisedViewConfig;
  // APPLY FILTER COLUMNS
  if (userRole === 'FW') {
    gridColumns = gridColumnsRaw.filter(x => x.isFW);
  } else {
    gridColumns = gridColumnsRaw.filter(x => x.isAP);
  }

  gridColumns.forEach(colItem => {
    // APPLY CSS CLASSES
    colItem.cellClass = params => {
      const { partialQuarter, partialQuarterPrev } = params.data;
      if (partialQuarter || partialQuarterPrev) {
        return 'GreyCloud';
      }
    };
    if (['id', 'Id'].includes(colItem.field)) {
      colItem.cellClass = 'whiteText';
    }
    if (['FctyMaxCapacity', 'maxCapacityReasonCode'].includes(colItem.field)) {
      if (userType === 'FACTORY') {
        if (
          defaultStatus === 'shortHorizon' &&
          (maxCapacityEditable === 'false' || maxCapacityEditable === false)
        ) {
          colItem.editable = false;
        } else {
          colItem.editable = true;
          colItem.cellClass = 'editableCol';
        }
      }
      colItem.headerClass = `headSapphireBlue`;
    } else {
      colItem.headerClass = `headGreyCloud`;
    }
    if (colItem.field === 'GbdMaxCapVariance') {
      colItem.headerComponentParams.subTitle =
        defaultStatus === 'shortHorizon'
          ? 'Max - Fcty Cpty = Variance'
          : 'Max - Fcty Cpty = Variance';
    } else if (colItem.field === 'GbdMaxCapAdjVariance') {
      colItem.headerComponentParams.subTitle =
        defaultStatus === 'shortHorizon'
          ? 'Adjd Max - Adjd Fcty Cpty = Adjd Variance'
          : 'Adjd Max - Adjd Fcty Cpty = Adjd Variance';
    }

    // APPLY EDITABLE CONTITIONS
    if (colItem.field === 'FctyMaxCapacity') {
      colItem.cellEditor = 'NumericCellEditor';
    } else if (colItem.field === 'maxCapacityReasonCode') {
      const optSelectArr = [''];
      const optFilterArr = [null];
      colItem.options = reasonCodes || [];
      colItem.options.forEach(colmItemOptn => {
        if (colmItemOptn.status === 'ACTIVE') {
          optSelectArr.push(colmItemOptn.value);
        }
        optFilterArr.push(colmItemOptn.value);
      });
      colItem.filter = 'agSetColumnFilter';
      colItem.cellEditor = 'agRichSelectCellEditor';
      colItem.cellStyle = {
        fontSize: '13px',
        lineHeight: '22px'
      };
      colItem.filterParams = {
        cellHeight: 20,
        values: _.union(optFilterArr),
        debounceMs: 1000,
        suppressRemoveEntries: true
      };

      colItem.cellEditorParams = () => ({
        values: _.union(optSelectArr),
        formatValue(value) {
          return value === '' ? '(blank)' : value;
        }
      });
    }
  });
  return gridColumns;
};

// for ag grid custom footer download
export const updateUploadDataFormat = data => {
  let formattedData = JSON.parse(JSON.stringify(data)); //let formattedData = [...data];
  let formattedNewData = [];

  formattedData.forEach(dataItem => {
    if (dataItem.weekStartDate) {
      dataItem.weekStartDate = moment(dataItem.weekStartDate).format(
        'MM/DD/YYYY'
      );
    }
    dataItem.isQuarterly = true;
    if (dataItem.FctyMaxCapacity || dataItem.FctyMaxCapacity === 0) {
      dataItem.FctyMaxHldCpty = holidayCalculator('FctyMaxHldCpty', dataItem);
    }

    try {
      // Applying last week
      dataItem.id = dataItem.ids[dataItem.ids.length - 1];
      // removing the unnecessary cloumns added oonly

      delete dataItem.isColumnSkipped;
      delete dataItem.totalWeeks;
      delete dataItem.colModified;
      delete dataItem.ChangeByUpload;
      delete dataItem.uniQId;
      Object.keys(dataItem).forEach(function(key) {
        if (
          dataItem[key] === undefined ||
          dataItem[key] === null ||
          dataItem[key] === ''
        ) {
          delete dataItem[key];
        }
      });
    } catch (e) {
      // this will run only if the code in the try block errors-out
    }
    formattedNewData.push(dataItem);
  });
  return formattedNewData;
};

//quarterly grouped Data
export const leverQuarterGridData = (
  data,
  defaultStatus,
  actualStartWeekDate
) => {
  let formattedData = JSON.parse(JSON.stringify(data));
  let filterdQuarters =
    actualStartWeekDate > 0
      ? formattedData.filter(
          q => Number(q.productionQuarter) >= Number(actualStartWeekDate)
        )
      : formattedData;
  let maxQuarters =
    defaultStatus === 'MaxAvailable'
      ? filterdQuarters.filter(value => {
          return !value.revisedLastQuarter || value.revisedLastQuarter === '';
        })
      : filterdQuarters;

  return maxQuarters;
};

//Creating new excel Renderer
export const CustomExcelRenderer = (file, callback) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    var rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      var bstr = e.target.result;
      var wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        cellDates: true
      });

      /* Get first worksheet */
      var wsname = wb.SheetNames[0];
      var ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      var json = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        dateNF: 'mm/dd/yyyy'
      });
      var cols = make_cols(ws['!ref']);
      json.forEach(col => {
        const columnIndex = col.findIndex(c => c instanceof Date && !isNaN(c));
        if (columnIndex) {
          col[columnIndex] = moment(col[columnIndex]).format('MM/DD/YYYY');
        }
      });

      var data = { rows: json, cols: cols };

      resolve(data);
      return callback(null, data);
    };
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};
const make_cols = refstr => {
  var o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (let i = 0; i < C; ++i) {
    o[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return o;
};

export const gbdExcelUploadRowCompare = (
  excelRow,
  inGridRow,
  editableColumns
) => {
  const newExcelRow = { ...excelRow };
  const newGridRow = { ...inGridRow };
  const excelRowFiltered = _.pick(newExcelRow, editableColumns);
  const inGridRowFiltered = _.pick(newGridRow, editableColumns);

  const cleanUpFun = v => v !== undefined && v !== '' && v !== null;
  const ExcelRowCleaned = _.pickBy(excelRowFiltered, cleanUpFun);
  const inGridRowCleaned = _.pickBy(inGridRowFiltered, cleanUpFun);

  //**returning object that only have changed values with only changed value */
  return _.isEqual(ExcelRowCleaned, inGridRowCleaned)
    ? false
    : excelRowFiltered;
};

export const frameDataOnUpload = (
  data,
  activeGridSetLength,
  gridColumns,
  headerLength
) => {
  const columns = [];
  const jsonData = [];

  gridColumns.forEach(i => {
    columns.push({ name: i.colDef.headerName, code: i.colId });
  });
  // columns.push({ name: 'uniQId', code: 'uniQId' });
  // setting up the index keys first
  const excelOtherHeaderLenEnd = headerLength + activeGridSetLength;
  const headerRow = data[headerLength];
  const columnOrder = [];
  headerRow.forEach(item => {
    let thisIndex =
      columns.findIndex(i => i.name === item) ||
      columns.findIndex(i => i.name.includes(item));
    if (thisIndex === -1) {
      columnOrder.push(columns[0].code);
    }

    if (thisIndex >= 0) {
      columnOrder.push(columns[thisIndex].code);
    }
  });

  data.forEach((row, index) => {
    if (index > 0 && index <= excelOtherHeaderLenEnd) {
      const newItems = {};
      row.forEach((item, key) => {
        const field = columnOrder[key];
        newItems[field] = item;
      });

      jsonData.push(newItems);
    }
  });

  return jsonData;
};

export const reasonCodeNameList = gridColumns => {
  let maxReasonCodes = [];
  gridColumns.forEach(i =>
    i.colId === 'maxCapacityReasonCode'
      ? (maxReasonCodes = i.userProvidedColDef.options)
      : true
  );
  let reasonCodeNames = [];
  maxReasonCodes.forEach(i =>
    i.status === 'ACTIVE' ? reasonCodeNames.push(i.value) : true
  );
  return reasonCodeNames;
};
