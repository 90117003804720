/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from 'react-select';
import { SingleInput } from '../index.js';
import DatePickerYearMonth from './datePickerYearMonth.js';
import moment from 'moment';
import _ from 'lodash';

const modifiers = {
  disabled: { daysOfWeek: [6] },
  birthday: new Date(2018, 8, 19),
  monday: { daysOfWeek: [1] }
};
const currentYear = new Date().getFullYear();
let fromMonth = new Date(currentYear, 0);
let toMonth = new Date(currentYear + 10, 11);
let dayPickerInputRef = null;

export default class SearchNew extends Component {
  constructor(props) {
    super(props);
    const { revisedHorizonWeeks, pageType } = this.props;
    if (pageType === 'searchgrid') {
      this.validateHorizonForDatePickers(revisedHorizonWeeks);
    }

    this.state = {
      startWeekDate: fromMonth,
      endWeekDate: fromMonth
    };
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.onSearchFieldChange = this.onSearchFieldChange.bind(this);
    this.onSearchTypeAheadChange = this.onSearchTypeAheadChange.bind(this);
    this.onSearchTypeAheadOnBlur = this.onSearchTypeAheadOnBlur.bind(this);
  }

  onSearchFieldChange = event => {
    const {
      searchFields,
      onSearchFieldUpdate,
      onSearchGroupUpdate,
      searchGroups
    } = this.props;

    const thisIndex = searchGroups.findIndex(
      item => item.field === event.target.name
    );
    if (thisIndex >= 0) {
      searchGroups[thisIndex].value = event.target.value || '';

      onSearchGroupUpdate(searchGroups);
    } else {
      const thisIndex = searchFields.findIndex(
        item => item.field === event.target.name
      );
      if (thisIndex >= 0) {
        searchFields[thisIndex].value = event.target.value;
        onSearchFieldUpdate(searchFields);
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    const { revisedHorizonWeeks, pageType } = this.props;
    if (
      revisedHorizonWeeks !== nextProps.revisedHorizonWeeks &&
      pageType === 'searchgrid'
    ) {
      this.validateHorizonForDatePickers(nextProps.revisedHorizonWeeks);
    }
  }

  validateHorizonForDatePickers(revisedHorizonWeeks) {
    const { searchFields, onSearchFieldUpdate } = this.props;
    const { defaultStatus } = this.props;
    const today = new Date();

    const startDate = moment(today)
      .startOf('week')
      .add(1, 'day');

    const endDate = moment(today).add(revisedHorizonWeeks, 'weeks');
    const weekEnd = moment(endDate)
      .endOf('week')
      .startOf('week')
      .add(1, 'day');
    const quaterEnd = moment(endDate)
      .endOf('quarter')
      .startOf('week')
      .add(1, 'day');
    if (!weekEnd.isSame(quaterEnd)) {
      endDate.subtract(1, 'quarter');
    }
    endDate
      .endOf('quarter')
      .startOf('week')
      .add(1, 'day');

    //if (location && location.pathname.includes('revisedCapacity')) {
    if (defaultStatus === 'shortHorizon') {
      // revisedCapacity dates
      fromMonth = new Date(startDate);
      toMonth = new Date(endDate);
    } else {
      // strategicCapacity dates
      endDate.add(1, 'weeks');
      fromMonth = new Date(endDate);
      const weekDiff = endDate.diff(startDate, 'weeks');
      const strategicEndDate = moment(fromMonth).add(
        260 - weekDiff - 1,
        'weeks'
      );
      toMonth = new Date(strategicEndDate);
    }

    searchFields.forEach(item => {
      if (item.type === 'date') {
        item.fromMonth = fromMonth;
        item.toMonth = toMonth;
      } else if (item.type === 'datequarter') {
        item.fromMonth = fromMonth;
        item.toMonth = toMonth;
        let quaterStart = moment(fromMonth).startOf('quarter');
        let quaterend = moment(toMonth).endOf('quarter');
        item.fromQuarter = new Date(quaterStart);
        item.toQuarter = new Date(quaterend);
      }
    });
    onSearchFieldUpdate(searchFields);
    this.setState({
      startWeekDate: fromMonth,
      endWeekDate: fromMonth
    });
  }

  onSearchDateChange(date, field) {
    const { searchFields, onSearchFieldUpdate } = this.props;
    const thisIndex = searchFields.findIndex(item => item.field === field);
    if (thisIndex > 0) {
      searchFields[thisIndex].value = date || '';
    }
    if (field === 'startWeekDate') {
      const i = searchFields.findIndex(item => item.field === 'endWeekDate');
      searchFields[i].fromMonth = new Date(moment(date));
    } else {
      const i = searchFields.findIndex(item => item.field === 'startWeekDate');
      searchFields[i].toMonth = new Date(moment(date));
    }
    onSearchFieldUpdate(searchFields);
  }

  onSearchQuarterChange(date, field) {
    const { searchFields, onSearchFieldUpdate } = this.props;
    const thisIndex = searchFields.findIndex(item => item.field === field);
    if (thisIndex > 0) {
      searchFields[thisIndex].value = date || '';
    }
    if (field === 'startWeekDate') {
      const i = searchFields.findIndex(item => item.field === 'startWeekDate');
      /* const d = new Date(  moment(date) .startOf('month')  .day('Monday') );*/
      let monday = moment(date)
        .startOf('month')
        .day('Monday');
      if (monday.date() > 7) monday.add(7, 'd');
      searchFields[i].value = date ? new Date(monday) : '';
    } else {
      const i = searchFields.findIndex(item => item.field === 'endWeekDate');
      const d = new Date(
        moment(date)
          .endOf('quarter')
          .startOf('week')
      );
      searchFields[i].value = date ? d : '';
    }
    onSearchFieldUpdate(searchFields);
  }
  onSearchTypeAheadChange(gvalue, field) {
    const valueIs = (gvalue && gvalue.length > 0 && gvalue[0].value) || '';
    const {
      searchFields,
      onSearchGroupUpdate,
      searchGroups,
      onSearchFieldUpdate,
      onSearchFactoryGrpApi
    } = this.props;
    const thisIndex = searchGroups.findIndex(item => item.field === field);
    if (thisIndex >= 0) {
      //searchGroups[thisIndex].value = valueIs || '';
      searchGroups[thisIndex].selected = gvalue;
      if (searchGroups[thisIndex].field === 'factoryGroup') {
        const i = searchGroups.findIndex(o => o.field === 'factoryCode');
        if (valueIs) {
          const factGrpOpts = searchGroups[thisIndex].options;
          let newFaCodes =
            factGrpOpts.find(o => o.value === valueIs).factoryCodes || [];
          searchGroups[i].options = newFaCodes;
        } else {
          searchGroups[i].options = searchGroups[i].backOptions;
        }
        searchGroups[i].value = '';
        searchGroups[i].selected = [];
        let thisrel = this.refs[`typeAheadfactoryCode`];
        if (thisrel) {
          thisrel.getInstance().clear();
        }
      }
      searchFields.forEach(gridItem => {
        if (gridItem.field === 'capacityTypeCode') {
          gridItem.value = '';
          gridItem.selected = [];
          let thisrel = this.refs[`typeAhead${gridItem.field}`];
          if (thisrel) {
            thisrel.getInstance().clear();
          }
        }
      });
      onSearchGroupUpdate(searchGroups);
      onSearchFactoryGrpApi();
    } else {
      const thisIndex = searchFields.findIndex(item => item.field === field);
      if (thisIndex >= 0) {
        searchFields[thisIndex].selected = gvalue;
        searchFields[thisIndex].value = valueIs || '';
      }
    }
    onSearchFieldUpdate(searchFields);
  }

  getInstanceValue(object, instance) {
    if (
      instance &&
      instance.getInstance().getInput() &&
      instance.getInstance().getInput().value
    ) {
      return (
        instance
          .getInstance()
          .getInput()
          .value.toUpperCase() || ''
      );
    }
    return '';
  }
  onSearchTypeAheadOnBlur(field) {
    const { searchFields, searchGroups, pageType } = this.props;
    const listenBlur = pageType === 'upload' ? false : true;
    if (listenBlur) {
      let thisrelVal = '';
      let thisrelObj = [];
      let selected = false;
      setTimeout(() => {
        const thisIndex = searchGroups.findIndex(item => item.field === field);
        if (thisIndex >= 0) {
          let thisrel = this.refs[`typeAhead${field}`];
          thisrelVal = this.getInstanceValue(searchGroups[thisIndex], thisrel);
          thisrelObj = searchGroups[thisIndex].options.find(
            o => o.name === thisrelVal
          );
          if (searchGroups[thisIndex].selected.length === 0) {
            selected = true;
          }
        } else {
          const thisIndex = searchFields.findIndex(
            item => item.field === field
          );
          if (thisIndex >= 0) {
            let thisrel = this.refs[`typeAhead${field}`];
            thisrelVal = this.getInstanceValue(
              searchFields[thisIndex],
              thisrel
            );
            thisrelObj = searchFields[thisIndex].options.find(
              o => o.name === thisrelVal
            );
            if (searchFields[thisIndex].selected.length === 0) {
              selected = true;
            }
          }
        }
        if (thisrelObj && selected) {
          const ar = [thisrelObj];
          this.onSearchTypeAheadChange(ar, field);
        }
      }, 100);
    }
  }

  onSearchFieldClear(n) {
    const { searchFields, onSearchFieldUpdate } = this.props;
    searchFields.forEach(gridItem => {
      gridItem.value = '';
      gridItem.disabled = false;
      gridItem.selected = [];
      let thisrel = this.refs[`typeAhead${gridItem.field}`];
      if (thisrel) {
        thisrel.getInstance().clear();
      }
      if (gridItem.field === 'capacityTypeCode') {
        gridItem.options = [];
        gridItem.disabled = true;
        gridItem.value = '';
      }
    });
    onSearchFieldUpdate(searchFields);
  }
  onSearchAllClear(n) {
    const {
      searchGroups,
      onSearchGroupUpdate,
      pageType,
      onToggleClick,
      revisedHorizonWeeks
    } = this.props;
    searchGroups.forEach(gridItem => {
      gridItem.value = '';
      gridItem.selected = [];
      let thisrel = this.refs[`typeAhead${gridItem.field}`];
      if (thisrel) {
        thisrel.getInstance().clear();
      }
      if (gridItem.field === 'factoryCode') {
        gridItem.options = gridItem.backOptions;
      }
    });
    if (pageType === 'upload') {
      onToggleClick(false);
    }
    // Not resetting the weekly- quarterly dropdown to weekly yet :(
    //this.setState({ isToggleOn: false, isToggleAfterSearchOn: false });
    onSearchGroupUpdate(searchGroups);
    this.onSearchFieldClear();
    this.validateHorizonForDatePickers(revisedHorizonWeeks);
  }
  sortFactoryCodes = (factoryCodes, selectedOption) => {
    const { parentPage } = this.props;
    let allFC = [
      factoryCodes.find(x => _.startsWith(x.name.toLowerCase(), 'all'))
    ];
    if (allFC) {
      allFC[0].key = `All${selectedOption}`;
      allFC[0].name = `All ${selectedOption}`;
      allFC[0].label = `All ${selectedOption}`;
    }
    let filteredArr = factoryCodes.filter(
      x => !_.startsWith(x.name.toLowerCase(), 'all')
    );
    let sortedArr = _.sortBy(filteredArr, 'name');
    let allFactoryCodes = [...allFC, ...sortedArr];
    return parentPage === 'Growth' ? sortedArr : allFactoryCodes;
  };

  onSelectFieldChange = (field, selectedOption) => {
    const {
      searchFields,
      onSearchFieldUpdate,
      onSearchGroupUpdate,
      onSearchFactoryGrpApi,
      searchGroups,
      pageType,
      parentPage
    } = this.props;
    const thisIndex = searchGroups.findIndex(item => item.field === field);
    if (thisIndex >= 0) {
      searchGroups[thisIndex].value = selectedOption || '';
      searchGroups[thisIndex].selected = selectedOption || '';
      if (pageType === 'changesgrid' || pageType === 'searchgrid') {
        if (searchGroups[thisIndex].field === 'factoryGroup') {
          const i = searchGroups.findIndex(o => o.field === 'factoryCode');
          if (selectedOption.value) {
            const factGrpOpts = searchGroups[thisIndex].options;
            let newFaCodes =
              factGrpOpts.find(o => o.value === selectedOption.value)
                .factoryCodes || [];
            searchGroups[i].options = this.sortFactoryCodes(
              newFaCodes,
              selectedOption.value
            );
            searchGroups[i].value = '';
            searchGroups[i].selected = {};
          } else {
            searchGroups[i].options = searchGroups[i].backOptions;
          }
          searchGroups[i].value = '';
          searchGroups[i].selected =
            parentPage === 'Growth' ? [] : searchGroups[i].options[0];
        } else if (searchGroups[thisIndex].field === 'factoryCode') {
          const i = searchGroups.findIndex(o => o.field === 'factoryGroup');
          const factGrpOpts =
            searchGroups[i].options.find(
              o =>
                o.factoryCodes &&
                o.factoryCodes.find(item => item.key === selectedOption.key)
            ) || searchGroups[i].options[0];
          if (factGrpOpts) {
            searchGroups[i].value = '';
            searchGroups[i].selected = factGrpOpts;
          }
        }

        if (pageType === 'searchgrid' && searchFields) {
          searchFields.forEach(gridItem => {
            if (gridItem.field === 'capacityTypeCode') {
              const i = searchGroups.findIndex(o => o.field === 'factoryCode');
              const factoryCodeValue = searchGroups[i].selected.value;
              const firstItem = {
                name: 'ALL',
                value: '',
                key: 'ALL',
                label: 'ALL',
                id: 10001
              };
              if (factoryCodeValue) {
                firstItem.key = `All${factoryCodeValue}`;
                firstItem.name = `All ${factoryCodeValue}`;
                firstItem.label = `All ${factoryCodeValue}`;
              }

              gridItem.disabled = false;
              gridItem.value = '';
              gridItem.selected = firstItem;
            }
          });
          onSearchFieldUpdate(searchFields);
          onSearchFactoryGrpApi();
        }
      }

      onSearchGroupUpdate(searchGroups);
    } else {
      const thisIndex = searchFields.findIndex(item => item.field === field);
      if (thisIndex >= 0) {
        searchFields[thisIndex].value = selectedOption;
        searchFields[thisIndex].selected = [];
        searchFields[thisIndex].selected.push(selectedOption);
        onSearchFieldUpdate(searchFields);
      }
    }
  };
  onSearchEnterFn = () => {
    const { onSearchEnter } = this.props;
    onSearchEnter();
  };

  handleYearMonthChange(month, field) {
    this.setState({ [field]: month });
  }

  getSection = (fieldItem, searchType) => {
    const { pageType, userNotify } = this.props;

    const multipleSelectTypeAhead = pageType === 'upload' ? true : false;

    if (
      userNotify ||
      fieldItem.type === 'input' ||
      fieldItem.disabled ||
      (fieldItem.options && fieldItem.options.length === 0)
    ) {
      return (
        <div className="position-relative">
          <SingleInput
            inputType={fieldItem.format || 'text'}
            name={fieldItem.field}
            key={`${fieldItem.id}-${fieldItem.field}`}
            controlFunc={e => this.onSearchFieldChange(e)}
            content={fieldItem.value}
            placeholder={fieldItem.name || ''}
            className={`input-text ${fieldItem.cssClass}`}
            required={fieldItem.required || false}
            disabled={true}
            style={{
              height: 29
            }}
          />
        </div>
      );
    }
    switch (fieldItem.type) {
      case 'options':
        return (
          <div className="position-relative">
            {fieldItem.cssClass === 'float' && fieldItem.selected.length > 0 ? (
              <span className="floatHead">{fieldItem.name}</span>
            ) : null}

            <select
              name={fieldItem.field}
              value={fieldItem.value}
              key={`${fieldItem.id}-${fieldItem.field}`}
              onChange={e => this.onSearchFieldChange(e)}
              className={` input-text  ${fieldItem.cssClass}`}
              disabled={fieldItem.disabled}
            >
              <option />
              {fieldItem.options &&
                fieldItem.options.map(v => (
                  <option key={v.key} value={v.value}>
                    {v.label}
                  </option>
                ))}
            </select>
          </div>
        );

      case 'typeahead':
        return (
          <div className="position-relative">
            {fieldItem.cssClass === 'float' && fieldItem.selected.length > 0 ? (
              <span className="floatHead">{fieldItem.name}</span>
            ) : null}
            <Typeahead
              value={fieldItem.value}
              id={fieldItem.name}
              ref={`typeAhead${fieldItem.field}`}
              onChange={value =>
                this.onSearchTypeAheadChange(value, fieldItem.field)
              }
              maxSelected={1}
              onBlur={() => this.onSearchTypeAheadOnBlur(fieldItem.field)}
              options={
                fieldItem.selected.length >= fieldItem.maxLimit
                  ? []
                  : fieldItem.options
              }
              placeholder={fieldItem.name || ''}
              disabled={fieldItem.disabled}
              multiple={multipleSelectTypeAhead}
              selected={fieldItem.selected}
              className="drop-down"
            />
          </div>
        );

      case 'date':
        // This will be slightly different for now depending on whether it's Upload or Search View. When Upload View changes to
        //  single row selectors, all of the DayPicker code can be the same
        return (
          <div name="dayPickerParent">
            {pageType !== 'searchgrid' ? fieldItem.name : null}
            <div className={`DayPicker-Border ${fieldItem.cssClass}`}>
              <DayPickerInput
                value={fieldItem.value}
                keepFocus={false}
                onDayChange={day =>
                  this.onSearchDateChange(day, fieldItem.field)
                }
                modifiers={modifiers}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder={
                  pageType !== 'searchgrid' ? 'MM/DD/YYYY' : fieldItem.name
                }
                disabledDays={[new Date(2017, 3, 12), { daysOfWeek: [0, 6] }]}
                inputProps={{
                  readOnly: true,
                  style: {
                    borderRadius: 4,
                    borderColor: '#ccc',
                    minHeight: 29,
                    fontSize: 12
                  }
                }}
                disabled={fieldItem.disabled}
                dayPickerProps={{
                  month: this.state[fieldItem.field],
                  fromMonth: fieldItem.fromMonth,
                  toMonth: fieldItem.toMonth,
                  disabledDays: [
                    { daysOfWeek: [0, 2, 3, 4, 5, 6] },
                    { before: fieldItem.fromMonth },
                    { after: fieldItem.toMonth }
                  ],

                  modifiers,
                  captionElement: ({ date, localeUtils }) => (
                    <DatePickerYearMonth
                      date={date}
                      localeUtils={localeUtils}
                      field={fieldItem.field}
                      onChange={this.handleYearMonthChange}
                    />
                  ),
                  onBlur: () => {
                    setTimeout(() => {
                      const elClicked = document.activeElement,
                        container = document.getElementsByName(
                          `dayPickerParent`
                        );
                      if (
                        container &&
                        !container[0].contains(elClicked) &&
                        dayPickerInputRef
                      ) {
                        dayPickerInputRef.hideDayPicker();
                      }
                    }, 1);
                  }
                }}
              />
            </div>
          </div>
        );
      case 'dateold':
        return (
          <div>
            {fieldItem.name}
            <br />

            <DatePicker
              value={fieldItem.value}
              key={`${fieldItem.id}-${fieldItem.field}`}
              format={fieldItem.format}
              className={fieldItem.cssClass}
              onChange={date => this.onSearchDateChange(date, fieldItem.field)}
              style={{ width: 'inherit' }}
              disabled={fieldItem.disabled}
            />
          </div>
        );
      case 'datequarter':
        return (
          <div className="position-relative">
            {fieldItem.cssClass === 'float' && fieldItem.value.length > 0 ? (
              <span className="floatHead">{fieldItem.name}</span>
            ) : null}

            <DatePicker
              placeholderText={fieldItem.name}
              selected={fieldItem.value || ''}
              onChange={date =>
                this.onSearchQuarterChange(date, fieldItem.field)
              }
              dateFormat="yyyy, QQQ"
              showQuarterYearPicker
              minDate={fieldItem.fromQuarter}
              maxDate={fieldItem.toQuarter}
            />
          </div>
        );
      case 'select':
        return (
          <div className="position-relative">
            {fieldItem.cssClass === 'float' &&
            fieldItem.selected &&
            Object.keys(fieldItem.selected).length !== 0 ? (
              <span className="floatHead">{fieldItem.name}</span>
            ) : null}
            <Select
              value={
                Object.keys(fieldItem.selected).length !== 0
                  ? fieldItem.selected
                  : ''
              }
              id={fieldItem.name}
              ref={`optionsSelect${fieldItem.field}`}
              options={
                fieldItem.selected.length >= fieldItem.maxLimit
                  ? []
                  : fieldItem.options
              }
              placeholder={fieldItem.name}
              disabled={fieldItem.disabled}
              isMulti={multipleSelectTypeAhead}
              className={`select-drop-down  ${fieldItem.cssClass} ${
                fieldItem.cssClass &&
                fieldItem.selected &&
                Object.keys(fieldItem.selected).length !== 0
                  ? 'selected'
                  : ''
              }`}
              onChange={a => this.onSelectFieldChange(fieldItem.field, a)}
              classNamePrefix="react-select"
            />
          </div>
        );
      default:
    }
  };
  removeAllFromOptions = arr => {
    const arrNew = [...arr];
    arrNew.forEach(item => {
      if (item.options) {
        const foundIndex = item.options.findIndex(i => i.name === 'ALL');
        if (foundIndex >= 0) {
          item.options.splice(foundIndex, 1);
        }
      }
      if (item.backOptions) {
        const foundIndex = item.backOptions.findIndex(i => i.name === 'ALL');
        if (foundIndex >= 0) {
          item.options.splice(foundIndex, 1);
        }
      }
    });
    return arrNew;
  };

  render() {
    const {
      searchFields,
      searchGroups,
      pageType,
      isToggleOn,
      onToggleClick,
      userNotify,
      parentPage
    } = this.props;
    const searchG =
      pageType === 'upload'
        ? this.removeAllFromOptions(searchGroups)
        : searchGroups;
    const searchF =
      pageType === 'upload'
        ? this.removeAllFromOptions(searchFields)
        : searchFields;
    const capacityTypeCodeList =
      searchFields && searchFields.length > 0
        ? searchFields.find(o => o.field === 'capacityTypeCode').options
        : [];
    const factoryCodeSelected =
      searchFields && searchGroups.length > 0
        ? searchGroups.find(o => o.field === 'factoryCode').selected
        : [];
    const isSearchButtonEnable =
      parentPage === 'Growth'
        ? capacityTypeCodeList.length > 0 &&
          (factoryCodeSelected && factoryCodeSelected.name)
        : searchGroups
            .filter(item => item.required)
            .every(item => item.selected.label);
    return (
      <div className="u-clearfix search-new-container">
        <form
          onSubmit={e => {
            e.preventDefault();
            this.onSearchEnterFn();
          }}
        >
          <div className="pt0-sm">
            {searchG &&
              searchG.map((fieldItem, index) => (
                <div
                  className={`searchNew newSearchCol ${fieldItem.field}  ${
                    fieldItem.show ? '' : ' d-sm-h d-md-h d-lg-h'
                  } `}
                  key={fieldItem.field}
                >
                  {fieldItem.show && this.getSection(fieldItem, 1)}
                </div>
              ))}

            {pageType === 'upload' && (
              <div className="newSearchCol">
                <div>
                  Choose View {isToggleOn}
                  <br />
                  <select
                    name="toggleView"
                    value={isToggleOn}
                    key={`isToggleOn`}
                    onChange={() => onToggleClick()}
                    className={`ncss-col-sm-12 p1-sm border-black bg-white no-border-custom react-select__control css-yk16xz-control`}
                  >
                    <option key="tw" value={false}>
                      Weekly
                    </option>
                    <option key="tp" value={true}>
                      Quarterly
                    </option>
                  </select>
                </div>
              </div>
            )}
            {searchF &&
              searchF.map(fieldItem => (
                <div
                  className={`searchNew newSearchCol ${fieldItem.field}  ${
                    fieldItem.show ? '' : ' d-sm-h d-md-h d-lg-h'
                  }`}
                  key={fieldItem.field}
                >
                  {fieldItem.show && this.getSection(fieldItem, 2)}
                </div>
              ))}
            {pageType === 'searchgrid' && (
              <div className="searchNew newSearchCol">
                <button
                  type="button"
                  className="fl-sm-l c-f  ncss-btn-secondary ncss-btn-sm"
                  onClick={() => this.onSearchAllClear()}
                  disabled={userNotify || !isSearchButtonEnable}
                >
                  <span className=""> Clear</span>
                </button>
              </div>
            )}
            {pageType === 'searchgrid' && (
              <div className="searchNew newSearchCol">
                <button
                  type="submit"
                  className={`fl-sm-l   c-f  ${
                    pageType === 'upload'
                      ? 'ncss-btn-accent-dark '
                      : 'ncss-btn-primary-dark '
                  } ncss-btn-sm`}
                  disabled={userNotify || !isSearchButtonEnable}
                >
                  <span className=""> Search</span>
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}
SearchNew.propTypes = {
  onSearchEnter: PropTypes.func,
  onSearchFactoryGrpApi: PropTypes.func,
  onSearchFieldUpdate: PropTypes.func,
  onSearchGroupUpdate: PropTypes.func,
  searchFields: PropTypes.array,
  searchOptions: PropTypes.any,
  searchGroups: PropTypes.array,
  pageSearch: PropTypes.any,
  pageType: PropTypes.any,
  usrFactGrpSearchData: PropTypes.any,
  userNotify: PropTypes.any,
  notificationCount: PropTypes.any,
  location: PropTypes.any,
  revisedHorizonWeeks: PropTypes.any
};
