export const columnConstants = {
  editableFields4AllUsers: [
    'maxAvailableCapacity',
    'maxAvailableReasonCode',
    'maxAvailableUnitsConfirmed',
    'approvedMaxCapacity',
    'shortHorizonCapacity',
    'shortHorizonReasonCode',
    'shortHorizonUnitsConfirmed',
    'approvedRevisedCapacity',
    'maxCapacityReasonCode',
    'FctyMaxCapacity',
    'targetCpty',
    'targetHldCpty'
  ],
  notesEditableCols: ['maxAvailableNotes', 'shortHorizonNotes'],
  numberEditableCols: [
    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'shortHorizonCapacityChange',
    'approvedMaxCapacity',
    'maxAvailableUnitsConfirmed',
    'shortHorizonUnitsConfirmed',
    'approvedRevisedCapacity',
    'FctyMaxCapacity',
    'targetCpty',
    'targetHldCpty'
  ],
  statusChangingCapCols: [
    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'maxAvailableUnitsConfirmed',
    'shortHorizonUnitsConfirmed',
    'approvedRevisedCapacity',
    'approvedMaxCapacity'
  ],
  reasonCodeEditableCols: [
    'maxAvailableReasonCode',
    'shortHorizonReasonCode',
    'maxCapacityReasonCode'
  ],
  maxHolidayCols: [
    'FctyStratHldCpty',
    'ConfrndStratHldCpty',
    'AprvdStratHldCpty',
    'FctyMaxHldCpty'
  ],
  revHolidayCols: [
    'FctyRvsdHldCpty',
    'ConfrndRvsdHldCpty',
    'AprvdRvsdHldCpty',
    'FctyMaxHldCpty'
  ],
  maxAvailableAuthorCols: [
    'maxAvailableEditedBy',
    'maxAvailableConfirmedBy',
    'maxAvailableApprovedBy'
  ],
  shortHorizonAuthorCols: [
    'shortHorizonEditedBy',
    'shortHorizonConfirmedBy',
    'shortHorizonApprovedBy'
  ],
  maxCapacityCols: ['FctyMaxCapacity', 'maxCapacityReasonCode'],
  prodQuarterColumnHeader: [
    'productionQuarter',
    'factoryCode',
    'capacityTypeCode',
    'factoryDescription',
    'capacityTypeDescription',
    'capacityTypeClass',
    'weeklyCapacityQuantity',
    'adjustedCapacityQuantity',
    'maxAvailableDelta',
    'shortHorizonDelta',
    'FctyMaxCapacity',
    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'shortHorizonCapacityChange',
    'approvedRevisedCapacity',
    'maxAvailableUnitsConfirmed',
    'shortHorizonUnitsConfirmed',
    'approvedMaxCapacity',

    'numberOfHolidays',
    'workingDays',

    'maxAvailableStatus',
    'shortHorizonStatus',

    'FctyStratHldCpty',
    'ConfrndStratHldCpty',
    'AprvdStratHldCpty',
    'FctyMaxHldCpty',

    'ConfrndRvsdHldCpty',
    'AprvdRvsdHldCpty',
    'FctyRvsdHldCpty',

    'maxAvailableReasonCode',
    'shortHorizonReasonCode',
    'maxCapacityReasonCode',

    'maxAvailableApprovedBy',
    'maxAvailableConfirmedBy',
    'shortHorizonConfirmedBy',
    'shortHorizonApprovedBy',

    'maxAvailableNotes',
    'shortHorizonNotes',

    'targetCpty',
    'targetHldCpty',

    'id'
  ],
  prodQuarterColumn2Validate: [
    'FctyMaxCapacity',
    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'shortHorizonCapacityChange',
    'approvedRevisedCapacity',
    'maxAvailableUnitsConfirmed',
    'shortHorizonUnitsConfirmed',
    'approvedMaxCapacity',

    'maxAvailableStatus',
    'shortHorizonStatus',

    'maxAvailableEditedBy',
    'shortHorizonEditedBy',

    'FctyStratHldCpty',
    'ConfrndStratHldCpty',
    'AprvdStratHldCpty',
    'FctyMaxHldCpty',

    'ConfrndRvsdHldCpty',
    'AprvdRvsdHldCpty',
    'FctyRvsdHldCpty',

    'maxAvailableReasonCode',
    'shortHorizonReasonCode',
    'maxCapacityReasonCode',

    'maxAvailableApprovedBy',
    'maxAvailableConfirmedBy',
    'shortHorizonConfirmedBy',
    'shortHorizonApprovedBy',
    'maxAvailableNotes',
    'shortHorizonNotes',

    'targetCpty',
    'targetHldCpty'
  ],
  prodQStatusCols: ['maxAvailableStatus', 'shortHorizonStatus'],
  holidayColUpdate: {
    maxAvailableCapacity: 'FctyStratHldCpty',
    maxAvailableUnitsConfirmed: 'ConfrndStratHldCpty',
    approvedMaxCapacity: 'AprvdStratHldCpty',
    FctyMaxCapacity: 'FctyMaxHldCpty',
    shortHorizonCapacity: 'FctyRvsdHldCpty',
    shortHorizonUnitsConfirmed: 'ConfrndRvsdHldCpty',
    approvedRevisedCapacity: 'AprvdRvsdHldCpty',
    targetHldCpty: 'targetCpty'
  },
  prodQuarterStrategicCols: [
    'FctyMaxCapacity',
    'maxAvailableCapacity',
    'maxAvailableUnitsConfirmed',
    'approvedMaxCapacity',
    'maxAvailableStatus',
    'maxAvailableEditedBy',
    'FctyStratHldCpty',
    'ConfrndStratHldCpty',
    'AprvdStratHldCpty',
    'FctyMaxHldCpty',
    'maxAvailableReasonCode',
    'maxCapacityReasonCode',
    'maxAvailableApprovedBy',
    'maxAvailableConfirmedBy',
    'workingDays',
    'numberOfHolidays',
    'logs',
    'weeklyCapacityQuantity',
    'adjustedCapacityQuantity',
    'maxAvailableDelta',
    'logCount',
    'shortHorizonNotesCount',
    'maxAvailableNotesCount',
    'shortHorizonNotesQuarterCount',
    'maxAvailableNotesQuarterCount',

    'targetCpty',
    'targetHldCpty',
    'ids'
  ],
  prodQuarterRevisedCols: [
    'FctyMaxCapacity',
    'shortHorizonCapacity',
    'shortHorizonCapacityChange',
    'approvedRevisedCapacity',
    'shortHorizonUnitsConfirmed',
    'shortHorizonStatus',
    'shortHorizonEditedBy',
    'FctyMaxHldCpty',
    'ConfrndRvsdHldCpty',
    'AprvdRvsdHldCpty',
    'FctyRvsdHldCpty',
    'shortHorizonReasonCode',
    'maxCapacityReasonCode',
    'shortHorizonConfirmedBy',
    'shortHorizonApprovedBy',
    'workingDays',
    'numberOfHolidays',
    'logs',
    'weeklyCapacityQuantity',
    'adjustedCapacityQuantity',
    'shortHorizonDelta',
    'logCount',
    'shortHorizonNotesCount',
    'maxAvailableNotesCount',
    'shortHorizonNotesQuarterCount',
    'maxAvailableNotesQuarterCount',

    'targetCpty',
    'targetHldCpty',
    'ids'
  ],
  prodQuarterFixedCols: [
    'productionQuarter',
    'factoryCode',
    'capacityTypeCode'
  ],
  userUpdatesColumnHeader: [
    'userUpdatesFlag',
    'userUpdatesCheck',
    'productionQuarter',
    'factoryDescription',
    'factoryCode',
    'capacityTypeCode',
    'capacityTypeDescription',

    'maxAvailableStatus',
    'shortHorizonStatus',

    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'maxAvailableApprovedBy',
    'shortHorizonApprovedBy'
  ],
  userUpdatesColumnCustomCell: [
    'userUpdatesFlag',
    'productionQuarter',
    'maxAvailableStatus',
    'shortHorizonStatus',
    'maxAvailableCapacity',
    'shortHorizonCapacity',
    'maxAvailableApprovedBy',
    'shortHorizonApprovedBy'
  ]
};
