import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';

import '../stylus/app.css';

import config from '../config.js';
import Header from '../views/header/header.js';
import SlideMenuContainer from '../views/navigation/SlideMenuContainer.js';
import { navMenuclose } from '../actions/navigationActions.js';
import OfflineBanner from '../views/offline/offline.js';
import { Spinner, GenericError } from '../components/index.js';
import { authCheckRequested } from '../actions/authActions.js';
import { getAppConfigAction } from '../actions/appConfigActions.js';
import { getUserProfileAct } from '../actions/userProfileActions.js';
import { getUserNotifyAct } from '../actions/userNotifyActions.js';
import {
  getAppConfigData,
  getAppConfigLoading
} from '../reducers/appConfigReducer.js';
import { LoginRequired } from '../views/auth/requireLogin.js';
import {
  hasAuthCheckFinished,
  userLoggedIn,
  selectGroups,
  selectLoginError
} from '../reducers/authReducer.js';
import {
  getUserProfileData,
  getUserProfileDataLoading
} from '../reducers/userProfileReducer.js';
import { getUserNotifyData } from '../reducers/userNotifyReducer.js';

import NotificationsMessage from '../components/notifications/NotificationsMessage';
import Footer from '../views/footer/footer';
import Search from '../components/headerSearch/Search.js';
import { navRoutes } from './routes.js';

const navLinks = navRoutes.map(r => ({
  to: r.path || '',
  label: r.title,
  icon: r.icon,
  heading: r.heading,
  id: r.id,
  url: r.url || '',
  nav: r.nav || false,
  menu: r.menu || false,
  featureControl: r.featureControl,
  displayCapSearch: r.displayCapSearch || false
}));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      navlinklist: navLinks.filter(r => r.menu),
      navshowlist: navLinks.filter(r => r.menu),
      navDisplayMenu: navLinks.filter(r => r.menu),
      userGroupList: [],
      userGroups: [],
      userProfileData: {},
      getAppConfigInitiated: false
    };
    this.updateNotify = this.updateNotify.bind(this);
  }
  componentDidMount() {
    const {
      authCheckRequested,
      getUserProfileAct,
      userLoggedIn,
      getUserNotifyAct
    } = this.props;

    const { navshowlist } = this.state;
    let { showMenu } = this.state;
    authCheckRequested();
    if (userLoggedIn) {
      getUserProfileAct();
      getUserNotifyAct();
    }
    const currentPathName = window.location.pathname;
    navshowlist.forEach((menuitem, key) => {
      if (
        currentPathName === menuitem.to ||
        menuitem.to.includes(currentPathName)
      ) {
        showMenu = menuitem.nav;
      }
    });
    this.setState({ showMenu });
    // this.interval = setInterval(() => this.updateNotify(), 120000);
  }
  componentWillReceiveProps(nextProps) {
    const {
      userGroups,
      userProfileData,
      userLoggedIn,
      getAppConfigAction,
      getUserProfileAct,
      appConfigData
    } = this.props;
    const { getAppConfigInitiated } = this.state;

    if (userGroups !== nextProps.userGroups) {
      if (nextProps.userGroups && nextProps.userGroups.length > 0) {
        this.setState({ userGroups: nextProps.userGroups });
      }
    }
    if (userProfileData !== nextProps.userProfileData) {
      if (
        nextProps.userProfileData &&
        Object.keys(nextProps.userProfileData).length > 0
      ) {
        if (!getAppConfigInitiated || !appConfigData) {
          getAppConfigAction();
        }
        this.setState({
          userProfileData: nextProps.userProfileData,
          getAppConfigInitiated: true
        });
      }
    }
    if (userLoggedIn !== nextProps.userLoggedIn && nextProps.userLoggedIn) {
      getUserProfileAct();
      getUserNotifyAct();
    }
  }

  componentDidUpdate(prevProps) {
    let { location, userLoggedIn, getUserNotifyAct } = this.props;
    if (
      userLoggedIn &&
      location &&
      location.pathname !== prevProps.location.pathname &&
      (location.pathname.includes('dashboard') ||
        location.pathname.includes('user-updates'))
    ) {
      getUserNotifyAct();
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  updateNotify() {
    let { userLoggedIn, getUserNotifyAct } = this.props;
    if (userLoggedIn) {
      getUserNotifyAct();
    }
  }

  // eslint-disable-next-line max-statements
  render() {
    const { showMenu, navlinklist } = this.state;
    const { location } = this.props;
    const currentPathName = window.location.pathname;
    const currentURL = window.location.href;
    let currentEnv = null;

    const {
      navMenuclose,
      children,
      loginFinished,
      loginError,
      userNotifyData,
      appConfigData,
      appConfigLoading,
      userProfileData,
      userGroups,
      userProfileDataLoading
    } = this.props;

    const notification =
      userNotifyData && userNotifyData.strategic
        ? userNotifyData
        : {
            strategic: {
              unread: 0,
              pending: 0
            },
            revised: {
              unread: 0,
              pending: 0
            }
          };
    notification.pendingTotal =
      notification.strategic.pending + notification.revised.pending;
    notification.unreadTotal =
      notification.strategic.unread + notification.revised.unread;

    let navListToShow = [];
    let navFiltered = [];
    let noAdminUser = true;
    const isUserProfileLoaded =
      userProfileData && Object.keys(userProfileData).length > 0 ? true : false;
    if (isUserProfileLoaded && userGroups && userGroups.length > 0) {
      const defaultView = userProfileData.factoryType || 'AP';
      const adminMenu = userGroups
        .filter(i => i.name.includes('AdminCapViz'))
        .map(i => i.name.replace('AdminCapViz', ''));
      if (adminMenu && adminMenu.length > 0) {
        const nonAdminMenu = navlinklist
          .filter(
            i =>
              i.to.includes('admin/manageView') &&
              !i.to.includes(`admin/manageView/${defaultView}`)
          )
          .map(i => i.to);
        navFiltered = navlinklist.filter(
          item => !nonAdminMenu.includes(item.to)
        );
        noAdminUser = false;
      }
    }
    if (noAdminUser) {
      navFiltered = navlinklist.filter(r => !r.to.includes('admin/manageView'));
      const deli = navlinklist.findIndex(o => o.heading === 'MANAGEMENT');
      navFiltered.splice(deli, 1);
    }
    if (appConfigData) {
      const { featureConfig } = appConfigData;
      if (featureConfig && Object.keys(featureConfig).length > 0) {
        const { featureConfig } = appConfigData;
        let removeLinks = [];
        Object.keys(featureConfig).forEach(key => {
          if (!featureConfig[key]) {
            removeLinks.push(key);
          }
        });
        navListToShow = navFiltered.filter(
          item => !removeLinks.includes(item.featureControl)
        );
      } else {
        navListToShow = navFiltered.filter(r => !r.featureControl);
      }
    }
    if (
      currentURL.includes('gsm-capviz-client-dev') ||
      currentURL.includes('localhost')
    ) {
      // currentEnv = 'development';
    } else if (currentURL.includes('gsm-capviz-client-stage')) {
      currentEnv = 'stage';
    } else if (currentURL.includes('capacityvisibility-nonprod')) {
      currentEnv = 'nonprod';
    }

    window.sessionStorage.setItem('currentEnv', currentEnv);
    if (loginError) return <GenericError error={loginError} />;
    if (!loginFinished) return <Spinner large />;

    const searchShowList = navLinks
      .filter(opt => opt.displayCapSearch)
      .map(opt => opt.to);
    const toShowSearch = searchShowList.includes(currentPathName);
    let loadContent =
      userProfileData &&
      Object.keys(userProfileData).length > 0 &&
      appConfigData &&
      Object.keys(appConfigData).length > 0
        ? true
        : false;
    if (appConfigData && appConfigData.revisedHorizonWeeks) {
      localStorage.setItem(
        'revisedHorizonWeeks',
        appConfigData.revisedHorizonWeeks
      );
    }

    return (
      <DocumentTitle title={config.appName}>
        <div className="u-full-height">
          <OfflineBanner />
          <Header
            showMenu={showMenu}
            currentEnv={currentEnv}
            notification={notification}
          />
          <NotificationsMessage />
          <SlideMenuContainer
            title={config.appName}
            description={config.appFullName}
            navLinks={navListToShow}
            onClose={navMenuclose}
            currentPathName={currentPathName}
            location={location}
          >
            <div
              className={`app-container ${currentEnv} 
              ${currentEnv ? 'showEnv' : ''}`}
            >
              <LoginRequired>
                <div>
                  {loadContent && (
                    <div className="page-all-parent">
                      {toShowSearch && (
                        <Search
                          userProfileData={userProfileData}
                          revisedHorizonWeeks={
                            appConfigData.revisedHorizonWeeks || 0
                          }
                          rvsdProdQtrList={appConfigData.rvsdProdQtrList || []}
                          stratProdQtrList={
                            appConfigData.stratProdQtrList || []
                          }
                        />
                      )}
                      {children}
                    </div>
                  )}
                  {(userProfileDataLoading || appConfigLoading) &&
                    !loadContent && <Spinner large />}
                </div>
              </LoginRequired>
            </div>
          </SlideMenuContainer>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

App.propTypes = {
  authCheckRequested: PropTypes.func,
  getUserProfileAct: PropTypes.func,
  navMenuclose: PropTypes.func,
  children: PropTypes.object,
  userLoggedIn: PropTypes.any,
  loginFinished: PropTypes.bool,
  loginError: PropTypes.object,
  userGroups: PropTypes.any,
  userProfileData: PropTypes.any,
  userProfileDataLoading: PropTypes.bool,
  userNotifyData: PropTypes.any,
  getUserNotifyAct: PropTypes.func,
  appConfigData: PropTypes.object,
  appConfigLoading: PropTypes.bool
};

export { App };
export default withRouter(
  connect(
    state => ({
      loginError: selectLoginError(state),
      loginFinished: hasAuthCheckFinished(state),
      userLoggedIn: userLoggedIn(state),
      userGroups: selectGroups(state),
      userNotifyData: getUserNotifyData(state),
      userProfileData: getUserProfileData(state),
      userProfileDataLoading: getUserProfileDataLoading(state),
      appConfigData: getAppConfigData(state),
      appConfigLoading: getAppConfigLoading(state)
    }),
    {
      navMenuclose,
      authCheckRequested,
      getUserProfileAct,
      getUserNotifyAct,
      getAppConfigAction
    }
  )(App)
);
