import { fromJS } from 'immutable';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  userProfileSettingsLoading: false,
  userProfileSettingsData: {},
  userProfileSettingsError: {},
  updateUserProfileSettingsLoading: false,
  updateUserProfileSettingsSuccess: false,
  updateUserProfileSettingsFailed: false
});

const modifyUserProfileSettingsData = data => {
  const views = [];
  let toggledDashboards = ['REVISED', 'STRATEGIC'];
  if (
    data &&
    data.preference &&
    data.preference.toggledDashboards &&
    data.preference.updatedAt
  ) {
    toggledDashboards = data.preference.toggledDashboards;
  }
  if (data && data.options) {
    Object.keys(data.options).forEach(key => {
      const view = {
        name: key,
        disabled: false,
        checked: toggledDashboards.includes(key.toUpperCase()),
        fGroup: {
          checked: false,
          indeterminate: false,
          disabled: false,
          searchText: '',
          list: [],
          groupBy: false
        },
        fCodes: {
          checked: false,
          indeterminate: false,
          disabled: false,
          searchText: '',
          list: [],
          groupBy: true
        },
        fTypes: {
          checked: false,
          indeterminate: false,
          disabled: false,
          searchText: '',
          list: [],
          groupBy: true
        }
      };

      if (data.options[key]) {
        Object.keys(data.options[key]).forEach(keyGroup => {
          view.fGroup.list.push({
            name: keyGroup,
            disabled: false,
            checked: true,
            checkedAll: true,
            parent: key,
            ukey: `${[key]}-${[keyGroup]}`
          });
          //getting fcodes
          if (data.options[key][keyGroup]) {
            Object.keys(data.options[key][keyGroup]).forEach(keyFCode => {
              view.fCodes.list.push({
                name: keyFCode,
                disabled: false,
                checked: true,
                checkedAll: false,
                parent: keyGroup,
                parent2: key,
                ukey: `${[key]}-${[keyGroup]}-${[keyFCode]}`
              });

              //getting fcapacity
              if (data.options[key][keyGroup][keyFCode]) {
                data.options[key][keyGroup][keyFCode].forEach(keyFCType => {
                  let isChecked = false;
                  if (
                    data.preference &&
                    data.preference[key] &&
                    data.preference[key][keyGroup] &&
                    data.preference[key][keyGroup][keyFCode]
                  ) {
                    if (
                      data.preference[key][keyGroup][keyFCode].filter(
                        e => e.capacityTypeCode === keyFCType.capacityTypeCode
                      ).length > 0
                    ) {
                      isChecked = true;
                    }
                  }
                  const desc =
                    keyFCType.capacityTypeDescription &&
                    (keyFCType.capacityTypeDescription !== null &&
                      keyFCType.capacityTypeDescription !== 'null') &&
                    keyFCType.capacityTypeDescription !== undefined &&
                    keyFCType.capacityTypeDescription !== 'undefined'
                      ? keyFCType.capacityTypeDescription
                      : keyFCType.capacityTypeCode;
                  view.fTypes.list.push({
                    code: keyFCType.capacityTypeCode,
                    name: desc,
                    desc: desc,
                    checked: isChecked,
                    parent: keyFCode,
                    parent2: keyGroup,
                    parent3: key,
                    ukey: `${[key]}-${[keyGroup]}-${[keyFCode]}-${
                      keyFCType.capacityTypeCode
                    }`
                  });
                });
              }
            });
          }
        });
      } //getting groups

      view.fCodes.list.forEach(j => {
        const t = view.fTypes.list.filter(
          k => k.checked && k.parent === j.name
        );
        j.checked = t.length > 0 ? true : false;
      });
      view.fGroup.list.forEach(j => {
        const t = view.fCodes.list.filter(
          k => k.checked && k.parent === j.name
        );
        j.checked = t.length > 0 ? true : false;
      });
      // finally pushing all to array
      views.push(view);
    });
  }

  return { list: views };
};

export default function userProfileSettings(state = defaultState, action) {
  state = fromJS(state);

  switch (action.type) {
    case createServiceAction('userProfileSettingsData', 'success').type:
      return state.set(
        'userProfileSettingsData',
        modifyUserProfileSettingsData(action.data)
      );

    case createServiceAction('userProfileSettingsData', 'loading').type:
      return state.set('userProfileSettingsLoading', action.data);

    case createServiceAction('userProfileSettingsData', 'clear').type:
      return state.set('userProfileSettingsData', action.data);

    case createServiceAction('userProfileSettingsData', 'error').type:
      return state.set('userProfileSettingsError', action.data);

    case createServiceAction('updateUserProfileSettingsData', 'loading').type:
      return state.set('updateUserProfileSettingsLoading', action.data);

    case createServiceAction('updateUserProfileSettingsData', 'success').type:
      return state.set('updateUserProfileSettingsSuccess', action.data);

    case createServiceAction('updateUserProfileSettingsData', 'error').type:
      return state.set('updateUserProfileSettingsFailed', action.data);

    case createServiceAction('updateUserProfileSettingsData', 'clear').type:
      return state.set('updateUserProfileSettingsData', {});

    default:
      return state;
  }
}
export const getUserProfileSettingsData = state =>
  state.userProfileSettings.get('userProfileSettingsData');
export const getUserProfileSettingsLoading = state =>
  state.userProfileSettings.get('userProfileSettingsLoading');
export const getUserProfileSettingsError = state =>
  state.userProfileSettings.get('userProfileSettingsError');
export const updateUserProfileSettingsData = state =>
  state.userProfileSettings.get('updateUserProfileSettingsData');
export const getUpdatingUserProfileSettingsLoading = state =>
  state.userProfileSettings.get('updateUserProfileSettingsLoading');
export const getUpdatingUserProfileSettingsSuccess = state =>
  state.userProfileSettings.get('updateUserProfileSettingsSuccess');
export const getUpdatingUserProfileSettingsFailed = state =>
  state.userProfileSettings.get('updateUserProfileSettingsFailed');
