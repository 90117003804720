import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
const months = moment.months();

const datePickerMonthsYears = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear, 0);
  const toMonth = new Date(currentYear + 10, 11);

  const years = _.range(fromMonth.getFullYear(), toMonth.getFullYear() + 1, 1);

  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <select
        value={months[new Date(date).getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        value={new Date(date).getFullYear()}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {'<'}
      </button>
      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {'>'}
      </button>
    </div>
  );
};

datePickerMonthsYears.propTypes = {
  error: PropTypes.func
};
export default datePickerMonthsYears;
