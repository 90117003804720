import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGrid: false
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="page-align-items  ">
        <div className="dashboard">
          <div className="ncss-container">
            <div className="ncss-row page-header pb0-sm pb2-md">
              <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                <h3 className="pt3-sm main-heading  c-f-500">About</h3>
              </div>
              <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md "></div>
            </div>

            <div className="ncss-row  ">
              <div className="ncss-col-lg-12  ncss-col-sm-12 va-sm-m ">
                <Tabs>
                  <TabList>
                    <Tab className="menuOpt1Item">
                      <span className="text"> System requirements</span>
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <div className="capvis-page-container c-f-all ">
                      <div className="pl4-sm pr4-sm">
                        <ol>
                          <li>
                            <strong>Capacity Visibility Version 5.0.1</strong>
                          </li>
                          <li>
                            <strong>Operating System:</strong> Windows, Mac
                          </li>
                          <li>
                            <strong>Browser:</strong> Chrome, Mozilla Firefox.
                          </li>
                        </ol>
                        <hr />
                        <h4>
                          <strong>Windows </strong>
                        </h4>
                        <ol>
                          <li>Windows 7, Windows 8 or Windows 10 </li>
                          <li>
                            64-bit Intel® or AMD multi-core processor. Intel®
                            Core™ i5 processor or faster recommended.
                          </li>
                          <li>4 GB RAM (8 GB or more recommended)</li>
                        </ol>
                        <hr />
                        <h4>
                          <strong>Mac </strong>
                        </h4>
                        <ol>
                          <li>
                            OS X 10.11.6 or later (see compatibility notes on
                            macOS Mojave here)
                          </li>
                          <li>
                            Intel® Core™2 Duo processor. Intel® Core™ i5
                            processor or faster recommended.
                          </li>
                          <li>4 GB RAM (8 GB or more recommended)</li>
                        </ol>
                        <hr />
                        <p className="text-base">
                          <strong>Note: </strong>While these are the absolute
                          minimum requirements need to run Capacity Visibility
                          2.0 application, you may need higher specifications in
                          order to work most efficiently. <br />
                          Best experience will be by using the Windows or Mac
                          operating system on the latest Chrome browser.( see
                          below for details)
                        </p>
                        <br />
                        <table className="d-sm-t border">
                          <tbody>
                            <tr className="d-sm-tr">
                              <td>Chrome on Windows</td>
                              <td>77.0.3865.90</td>
                            </tr>
                            <tr>
                              <td>Chrome on macOS</td>
                              <td>77.0.3865.90</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AboutPage.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AboutPage)
);
