const commonCellBorder = {
  color: '#9a9a9a',
  lineStyle: 'Continuous',
  weight: 1
};
const commonWhiteBorder = {
  color: '#ffffff',
  lineStyle: 'Continuous',
  weight: 1
};
const growthDimensionConstants = {
  gridOptions: {
    groupHeaderHeight: 36,
    headerHeight: 40,
    rowHeight: 40,
    enableRowGroup: false,
    enablePivot: false,
    enableBrowserTooltips: false,
    suppressClipboardPaste: true,
    enableRangeSelection: true,
    enableCellTextSelection: true
  },
  sideBar: {
    width: 300,
    toolPanels: [
      {
        id: 'columns',
        width: 300,
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel'
      }
    ],
    defaultToolPanel: 'columns'
  },
  columnDefs: [
    {
      headerName: 'Prod Qtr',
      field: 'productionQuarter',
      width: 130,
      suppressMenu: true,
      editable: false,
      headerClass: 'gdGridHeadLabelPinned',
      filter: 'agSetColumnFilter',
      cellEditor: 'numericEditor',
      cellClass: 'GreyCloud',
      pinned: 'left'
    },
    {
      headerName: 'Factory Code',
      field: 'factoryCode',
      width: 130,
      suppressMenu: true,
      editable: false,
      headerClass: 'gdGridHeadLabelPinned',
      filter: 'agTextColumnFilter',
      cellClass: 'GreyCloud',
      pinned: 'left'
    },
    {
      headerName: 'Capacity Type Code',
      field: 'capacityTypeCode',
      width: 130,
      suppressMenu: true,
      pinned: 'left',
      editable: false,
      headerClass: 'gdGridHeadLabelPinned',
      cellClass: 'GreyCloud',
      filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Quarterly Records',
      children: [
        {
          headerName: 'Work Days',
          field: 'workingDays',
          width: 130,
          suppressMenu: true,
          editable: false,
          headerClass: 'gdGridHeadLabelPinned',
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          cellClass: 'GreyCloud',
          columnGroupShow: 'closed'
        },
        {
          headerName: 'Holidays',
          field: 'numberOfHolidays',
          width: 130,
          suppressMenu: true,
          editable: false,
          headerClass: 'gdGridHeadLabelPinned',
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          cellClass: 'GreyCloud',
          columnGroupShow: 'closed'
        },
        {
          headerName: 'Wkly Cap Qty',
          field: 'weeklyCapacityQuantity',
          width: 130,
          suppressMenu: true,
          editable: false,
          headerClass: 'gdGridHeadLabelPinned',
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          cellClass: 'GreyCloud'
        },
        {
          headerName: 'Adjd Capacity',
          field: 'adjustedCapacityQuantity',
          width: 130,
          suppressMenu: true,
          editable: false,
          headerClass: 'gdGridHeadLabelPinned',
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          cellClass: 'GreyCloud',
          columnGroupShow: 'closed'
        },
        {
          headerName: 'Fcty Max Cpty',
          field: 'FctyMaxCapacity',
          width: 130,
          suppressMenu: true,
          editable: false,
          headerClass: 'gdGridHeadLabelPinned',
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          cellClass: 'GreyCloud'
        },
        {
          headerName: 'Adjd Fcty Max Cpty',
          tooltipField: 'Adjd Fcty Max Cpty',
          field: 'FctyMaxHldCpty',
          width: 130,
          suppressMenu: true,
          editable: false,
          headerClass: 'gdGridHeadLabelPinned',
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          cellClass: 'GreyCloud',
          columnGroupShow: 'closed'
        },

        {
          headerName: 'Fcty Strat Cpty',
          field: 'maxAvailableCapacity',
          width: 130,
          editable: false,
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          suppressMenu: true,
          headerClass: 'gdGridHeadLabelPinned',
          cellClass: 'GreyCloud'
        },
        {
          headerName: 'Adjd Fcty Strat Cpty',
          field: 'FctyStratHldCpty',
          width: 130,
          editable: false,
          filter: 'agNumberColumnFilter',
          cellEditor: 'numericEditor',
          suppressMenu: true,
          headerClass: 'gdGridHeadLabelPinned',
          cellClass: 'GreyCloud',
          columnGroupShow: 'closed'
        }
      ]
    },
    {
      headerName: 'Target Capacity',
      field: 'WHQTargetCapacity',
      width: 130,
      editable: true,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridHeadLabelCapacity'
    },
    {
      headerName: 'AdJd Target Capacity',
      field: 'WHQTargetCapacity',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridHeadLabelCapacity',
      columnGroupShow: 'closed'
    },
    {
      headerName: 'Max CPTY Variance',
      field: 'GbdMaxCapVariance',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'AdJd Max CPTY Variance',
      field: 'GbdMaxCapAdjVariance',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'GBD - Productivity ',
      headerClass: 'gdGridHeadLabelCapacity',
      children: [
        {
          headerName: 'IE & Lean',
          headerClass: 'gdGridHeadLabelCapacity',
          children: [
            {
              headerName: 'Capacity -Process Optimization',
              field: 'GbdProdIELeanProcessOptCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Cost - Process Optimization',
              field: 'GbdProdIELeanProcessOptCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Capacity - New Line at Existing Building',
              field: 'GbdProdIELeanProcesNewLinCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbOdd'
            },
            {
              headerName: 'Cost - New Line at Existing Building',
              field: 'GbdProdIELeanProcesNewLinCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbOdd'
            }
          ]
        },
        {
          headerName: 'New Method of MAke',
          headerClass: 'gdGridHeadLabelCapacity',
          children: [
            {
              headerName: 'Capacity - Modernization',
              field: 'GbdProdNewMethodModernCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Cost - Modernization',
              field: 'GbdProdNewMethodModernCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Capacity - Innovation',
              field: 'GbdProdNewMethodInnovCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbOdd'
            },
            {
              headerName: 'Cost - Innovation',
              field: 'GbdProdNewMethodInnovCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            }
          ]
        }
      ]
    },
    {
      headerName: 'Validity Date',
      field: 'GbdValidyDate',
      width: 130,
      editable: true,
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'Comments',
      field: 'GbdComments',
      width: 130,
      editable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'Strat  CPTY QOQ',
      field: 'QosStrMaxCapVariance',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridHeadLabelCapacity'
    },
    {
      headerName: 'Strat CPTY AdjD QOQ',
      field: 'QosStrMaxCapAdjVariance',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridHeadLabelCapacity'
    },
    {
      headerName: 'Strat QOQ - Productivity ',
      headerClass: 'gdGridHeadLabelCapacity',
      children: [
        {
          headerName: 'IE & Lean',
          headerClass: 'gdGridHeadLabelCapacity',
          children: [
            {
              headerName: 'Capacity -Process Optimization',
              field: 'QosStrProdIELeanProcessOptCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            },
            {
              headerName: 'Cost - Process Optimization',
              field: 'QosStrProdIELeanProcessOptCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            },
            {
              headerName: 'Capacity - New Line at Existing Building',
              field: 'QosStrProdIELeanProcesNewLinCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            },
            {
              headerName: 'Cost - New Line at Existing Building',
              field: 'QosStrProdIELeanProcesNewLinCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            }
          ]
        },
        {
          headerName: 'New Method of MAke',
          headerClass: 'gdGridHeadLabelCapacity',
          children: [
            {
              headerName: 'Capacity - Modernization',
              field: 'QosStrProdNewMethodModernCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            },
            {
              headerName: 'Cost - Modernization',
              field: 'QosStrProdNewMethodModernCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            },
            {
              headerName: 'Capacity - Innovation',
              field: 'QosStrProdNewMethodInnovCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            },
            {
              headerName: 'Cost - Innovation',
              field: 'QosStrProdNewMethodInnovCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridHeadLabelCapacity'
            }
          ]
        }
      ]
    },
    {
      headerName: 'Validity Date',
      field: 'QosStrValidyDate',
      width: 130,
      editable: true,
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridHeadLabelCapacity'
    },
    {
      headerName: 'Comments',
      field: 'QosStrComments',
      width: 130,
      editable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridHeadLabelCapacity'
    },
    {
      headerName: 'Max CPTY QOQ',
      field: 'QoqMaxMaxCapVariance',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'Max CPTY AdjD QOQ',
      field: 'QoqMaxMaxCapAdjVariance',
      width: 130,
      editable: false,
      filter: 'agNumberColumnFilter',
      cellEditor: 'numericEditor',
      suppressMenu: true,
      cellClass: 'GreyCloud',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'Max QOQ - Productivity ',
      headerClass: 'gdGridHeadLabelCapacity',
      children: [
        {
          headerName: 'IE & Lean',
          headerClass: 'gdGridHeadLabelCapacity',
          children: [
            {
              headerName: 'Capacity -Process Optimization',
              field: 'QoqMaxProdIELeanProcessOptCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Cost - Process Optimization',
              field: 'QoqMaxProdIELeanProcessOptCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Capacity - New Line at Existing Building',
              field: 'QoqMaxProdIELeanProcesNewLinCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbOdd'
            },
            {
              headerName: 'Cost - New Line at Existing Building',
              field: 'QoqMaxProdIELeanProcesNewLinCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbOdd'
            }
          ]
        },
        {
          headerName: 'New Method of MAke',
          headerClass: 'gdGridHeadLabelCapacity',
          children: [
            {
              headerName: 'Capacity - Modernization',
              field: 'QoqMaxProdNewMethodModernCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Cost - Modernization',
              field: 'QoqMaxProdNewMethodModernCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            },
            {
              headerName: 'Capacity - Innovation',
              field: 'QoqMaxProdNewMethodInnovCap',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbOdd'
            },
            {
              headerName: 'Cost - Innovation',
              field: 'QoqMaxProdNewMethodInnovCost',
              width: 130,
              editable: true,
              filter: 'agNumberColumnFilter',
              cellEditor: 'numericEditor',
              suppressMenu: true,
              cellClass: 'editableCol',
              headerClass: 'gdGridRcGbEven'
            }
          ]
        }
      ]
    },
    {
      headerName: 'Validity Date',
      field: 'QoqMaxValidyDate',
      width: 130,
      editable: true,
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridRcGbEven'
    },
    {
      headerName: 'Comments',
      field: 'QoqMaxComments',
      width: 130,
      editable: true,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      cellClass: 'editableCol',
      headerClass: 'gdGridRcGbEven'
    }
  ],
  logHeader: [{}],
  excelStyles: [
    {
      id: 'greenBackground',
      interior: {
        color: '#b5e6b5',
        pattern: 'Solid'
      }
    },

    {
      id: 'header',
      interior: {
        color: '#000000',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff'
      }
    },
    {
      id: 'editableColumn',
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'nonEditableColumn',

      alignment: {
        wrapText: true
      },
      protection: {
        protected: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'footerContent',

      alignment: {
        wrapText: true
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'whiteText',

      font: {
        color: '#969696',
        size: 10
      },
      interior: {
        color: '#d4d0d0',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableGreyColumn',
      interior: {
        color: '#d4d0d0',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableYellowColumn',
      interior: {
        color: '#FFFFCC',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableRedColumn',
      interior: {
        color: '#f9baba',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'titleHeader',
      interior: {
        color: '#808080',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 12
      },
      protection: {
        protected: true,
        hideFormula: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'headGreyCloud',
      interior: {
        color: '#aeaaaa',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headLapisBlue',
      interior: {
        color: '#203763',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headBlueEyes',
      interior: {
        color: '#0372c3',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },

    {
      id: 'headViolet',
      interior: {
        color: '#4a71b7',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headSapphireBlue',
      interior: {
        color: '#0372c3',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headSilkBlue',
      interior: {
        color: '#8faad9',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'editableColHeader',
      interior: {
        color: '#62a57e',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'GreyCloud',
      interior: {
        color: '#efefef',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'LapisBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'BlueEyes',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'Violet',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'SapphireBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'SilkBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'editableCol',
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'dateEditabeCol',
      numberFormat: {
        format: 'mm/dd/yyyy'
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'numberEditabeCol',
      numberFormat: {
        format: '0'
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'peachOrange',
      interior: {
        color: '#ffcc99',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    }
  ]
};

export default growthDimensionConstants;
