import { createAction } from '../util/actionHelper.js';

export const startAuthCheck = createAction('AUTH_CHECK_START', {
  noPayload: true
});
export const finishAuthCheck = createAction('AUTH_CHECK_COMPLETE', {
  noPayload: true
});
export const setAuthCheckError = createAction('AUTH_CHECK_ERROR');

export const setUserLogin = createAction('LOGIN_SET_USER');
export const requestUserLogin = createAction('LOGIN_USER_REQUESTED');
export const requestUserLogout = createAction('LOGOUT_USER_REQUESTED');

export const authCheckRequested = createAction('AUTH_CHECK_REQUESTED', {
  noPayload: true
});

export const locationReinitilized = createAction('LOCATION_REINITILIZED', {
  noPayload: true
});
