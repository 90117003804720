/* eslint-disable max-statements */

import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../config/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';

import { put, takeEvery, call } from 'redux-saga/effects';
/*
const breakIntoParts = (num, parts) =>
  [...Array(parts)].map((_, i) => 0 | (num / parts + (i < num % parts)));
*/

export function* listener(serviceType, action) {
  try {
    // Dispatch GBD_DATA_LOADING action
    yield put(createServiceAction(serviceType, 'loading')(true));
    yield put(createServiceAction(serviceType, 'error')(''));
    yield put(createServiceAction(serviceType, 'growthLeverQuarterData')({}));
    yield put(createServiceAction(serviceType, 'clear')({}));

    let options = {};
    const { dashboard, searchfields } = action.data;

    let getDashDataUrl = `${apiConfig.growthLeverQuarterData.endPointUrl}${
      dashboard === 'MaxAvailable' ? 'strategic' : 'revised'
    }?${searchfields}`;

    // Trigger Call to API endpoint
    const res = yield call(getRequest, getDashDataUrl, options, true);
    // Parse response object
    const response = yield res.json();

    // Dispatch growthLeverGridColumns_LOADING action with response object
    yield put(createServiceAction(serviceType, 'loading')(false));
    yield put(createServiceAction(serviceType, 'success')(response));
  } catch (e) {
    yield put(createServiceAction(serviceType, 'loading')(false));
    // Dispatch GBD_DATA_ERROR action with captured error data
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* growthLeverQuarterDataSagas() {
  const serviceType = 'growthLeverQuarterData';
  // Listen to GBD_DATA_REQUESTED actions
  yield takeEvery(
    createServiceAction(serviceType, 'requested').type,
    listener,
    serviceType
  );
}

export default growthLeverQuarterDataSagas;
