import React, { Component } from 'react';

export default class CustomHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ascSort: 'inactive',
      descSort: 'inactive',
      noSort: 'inactive'
    };

    props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
  }

  componentDidMount() {
    this.onSortChanged();
  }

  render() {
    const {
      enableMenu,
      menuIcon,
      enableSorting,
      displayName,
      subTitle,
      displaySort
    } = this.props; // receive from API response
    const { ascSort, descSort, noSort } = this.state; // receive from API response
    let menu = null;
    if (enableMenu) {
      menu = (
        <div
          ref={menuButton => {
            this.menuButton = menuButton;
          }}
          className="customHeaderMenuButton"
          onClick={this.onMenuClicked.bind(this)}
        >
          <i className={`fa ${menuIcon}`}></i>
        </div>
      );
    }

    let sort = null;
    if (enableSorting) {
      sort = (
        <div className={`customGridHeadActions ${noSort}`}>
          <div
            onClick={this.onSortRequested.bind(
              this,
              ascSort === 'active' ? '' : 'asc'
            )}
            onTouchEnd={this.onSortRequested.bind(
              this,
              ascSort === 'active' ? '' : 'asc'
            )}
            className={`customSortDownLabel ${ascSort}`}
          >
            <i className="fa fa-long-arrow-alt-down"></i>
          </div>
          <div
            onClick={this.onSortRequested.bind(
              this,
              descSort === 'active' ? '' : 'desc'
            )}
            onTouchEnd={this.onSortRequested.bind(
              this,
              descSort === 'active' ? '' : 'desc'
            )}
            className={`customSortUpLabel ${descSort}`}
          >
            <i className="fa fa-long-arrow-alt-up"></i>
          </div>
          {/**
           * <div
            onClick={this.onSortRequested.bind(this, '')}
            onTouchEnd={this.onSortRequested.bind(this, '')}
            className={`customSortRemoveLabel ${noSort}`}
          >
            <i className="fa fa-times"></i>
          </div>
           */}
        </div>
      );
    }

    return (
      <div className="customHeaderContent">
        {menu}
        <div className="customHeaderLabel">
          {displayName}
          {subTitle && <div className="customHeaderSubTitle">{subTitle}</div>}
        </div>
        {displaySort && sort}
      </div>
    );
  }

  onMenuClicked() {
    const { showColumnMenu } = this.props; // receive from API response
    showColumnMenu(this.menuButton);
  }

  onSortChanged() {
    const { column } = this.props; // receive from API response
    this.setState({
      ascSort: column.isSortAscending() ? 'active' : 'inactive',
      descSort: column.isSortDescending() ? 'active' : 'inactive',
      noSort:
        !column.isSortAscending() && !column.isSortDescending()
          ? 'active'
          : 'inactive'
    });
  }

  onSortRequested(order, event) {
    const { setSort } = this.props; // receive from API response
    setSort(order, event.shiftKey);
  }
}
