import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Grid from '../grid/Grid.js';
import { H5, IconButton, Spinner } from '../index.js';
import CommentItem from './commentitem.js';

export default class CommentLogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getRowHeight(params) {
        let defaultheight = 50;
        return defaultheight;
      }
    };
    this.fileInput = React.createRef();
  }

  render() {
    const { getRowHeight } = this.state;
    const {
      LogCommentModel,
      LogCommentValue,
      toogleLogCommentModel,
      currentRowLogs,
      commentTextArea,
      handleCommentTextAreaChange,
      logHeaderGridDefault,
      logHeader,
      currentRowComments,
      isCommentTextUpdating,
      addComment2Grid,
      frameworkComponents,
      commentsLogsDataLoading
    } = this.props;

    currentRowLogs.forEach(dataItem => {
      if (dataItem.timestamp) {
        dataItem.time = moment(dataItem.timestamp).format('MM/DD/YYYY');
      }
    });
    const currentEnv = String(window.sessionStorage.getItem('currentEnv'));
    const showEnv =
      currentEnv !== null && currentEnv !== 'null' ? 'showEnv' : '';
    return (
      <Modal
        isOpen={LogCommentModel}
        className={`modal-right-container  ${showEnv}`}
        overlayClassName="modal-overlay"
      >
        <IconButton
          icon="times"
          className="modal-close-btn"
          onClick={() => toogleLogCommentModel()}
        />

        <div className="border">
          <H5 className="u-bold description pt2-sm pb4-sm">
            {LogCommentValue === 'time' ? (
              <span>
                <i className="far fa-list-alt" /> {currentRowLogs.length} Change
                Log(s)
              </span>
            ) : (
              <span>
                <i className="fas fa-comments" /> {currentRowComments.length}{' '}
                Comment(s)
              </span>
            )}
          </H5>
          <div className="ncss-col-sm-12 ">
            <div className="ncss-container comment-container">
              <div className="ncss-row">
                <div
                  className={`ncss-col-sm-12  ${
                    LogCommentValue === 'time'
                      ? 'log-section'
                      : 'comment-section'
                  }`}
                >
                  {LogCommentValue === 'time' ? (
                    <div className="log capvis-page-container c-f-all">
                      <div
                        id="myGrid"
                        style={{
                          height: 'calc( 80vh)',
                          width: '100%'
                        }}
                        className="ag-theme-balham"
                      >
                        {!commentsLogsDataLoading ? (
                          <div>
                            {currentRowLogs.length > 0 ? (
                              <Grid
                                defaultColDef={logHeaderGridDefault}
                                columnDefs={logHeader}
                                rowData={currentRowLogs}
                                onGridReady={this.onChildGridReady}
                                frameworkComponents={frameworkComponents}
                                dbGridFunctions={false}
                                getRowHeight={getRowHeight}
                                headerHeight={60}
                                setheight="80vh"
                              />
                            ) : (
                              <div className="commentNoDataMsg c-f">
                                <H5>No Logs Available! </H5>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="commentNoDataMsg c-f">
                            <Spinner large />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="comments">
                      {!commentsLogsDataLoading ? (
                        <div>
                          {currentRowComments.length > 0 ? (
                            <div>
                              {currentRowComments.map(
                                ({
                                  comment,
                                  userId,
                                  userRole,
                                  timestamp,
                                  isQuarterly
                                }) => (
                                  <div
                                    className={`com${isQuarterly}`}
                                    key={`com${timestamp}`}
                                  >
                                    <CommentItem
                                      type={LogCommentValue}
                                      content={comment}
                                      field=""
                                      date={String(timestamp)}
                                      user={userId}
                                      role={userRole}
                                      isQuarterly={isQuarterly}
                                    />
                                  </div>
                                )
                              )}
                              <div
                                style={{ float: 'left', clear: 'both' }}
                                ref={el => {
                                  this.commentSectionEnd = el;
                                }}
                              />
                            </div>
                          ) : (
                            <div className="commentNoDataMsg c-f">
                              <H5>No Comments Added Yet! </H5>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="commentNoDataMsg c-f">
                          <Spinner large />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {LogCommentValue === 'time' ? (
                  <div />
                ) : (
                  <div className="ncss-col-sm-12 comment-input pt4-sm">
                    <div className="comment-box">
                      <textarea
                        value={commentTextArea}
                        onChange={handleCommentTextAreaChange}
                        className="textareaComment p4-sm"
                        placeholder="your comments here"
                      />
                      <div className="comment-foot">
                        <i className="fas fa-comments" />
                        {isCommentTextUpdating ? (
                          <span> updating your comments</span>
                        ) : (
                          <span>Add your comments</span>
                        )}

                        <button
                          type="button"
                          className="comment-add-button"
                          onClick={() => addComment2Grid()}
                        >
                          <i className="fas fa-plus" /> ADD
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

CommentLogModal.propTypes = {
  LogCommentModel: PropTypes.bool,
  totalLength: PropTypes.any,
  LogCommentValue: PropTypes.any,
  toogleLogCommentModel: PropTypes.func,
  currentRowLogs: PropTypes.any,
  commentTextArea: PropTypes.string,
  handleCommentTextAreaChange: PropTypes.func,
  logHeaderGridDefault: PropTypes.any,
  logHeader: PropTypes.any,
  currentRowComments: PropTypes.any,
  addComment2Grid: PropTypes.func,
  isCommentTextUpdating: PropTypes.any,
  frameworkComponents: PropTypes.any
};
