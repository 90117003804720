/* eslint-disable max-statements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import _ from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Beforeunload } from 'react-beforeunload';
import qs from 'qs';
import { H1, PageError, Spinner, Button } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import NumericEditor from '../../components/grid/NumericEditor.js';
import ScoreCard from '../../components/dashboard/scorecard.js';
import UploadModal from '../../components/dashboard/uploadModal.js';
import UploadUpdateModal from '../../components/dashboard/UploadUpdateModal.js';
import CommentLogModal from '../../components/dashboard/commentLogModal.js';
import TabHeader from '../../components/dashboard/tabHeader';
import GridPagination from '../../components/dashboard/gridPagination.js';
import cellLinkRenderer from '../../components/grid/cellLinkRenderer.js';
import dayPickerFilter from '../../components/grid/dayPickerFilter.js';
import NotFound from '../../components/errors/NotFound.js';
import {
  getDashboardDataLoading,
  getDashboardData,
  getDashboardError,
  getDashboardGridLoading,
  getDashboardGridColumns,
  getDashboardGridError,
  getusrFactGrpSearchData,
  getusrFactGrpSearchLoading,
  getusrFactGrpSearchError,
  getDashboardDataUpdate,
  getUpdateDashboardCommentStatus
} from '../../reducers/dashboardReducer.js';
import {
  getCommentsLogsDataLoading,
  getCommentsLogsData,
  getCommentsLogsDataError
} from '../../reducers/commentsLogsReducer.js';
import {
  getUserProfileData,
  getUserProfileError
} from '../../reducers/userProfileReducer.js';
import { getUserNotifyData } from '../../reducers/userNotifyReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { holidayCalculator } from '../../util/dashboardGridConfigure.js';
import { AppCipher, AppDeCipher } from '../../util/cipher.js';
import {
  searchValidator,
  searchQueryFrame,
  agGridCustomFooter,
  agGridDownloadDataFormat,
  fetchAllQueryValue,
  updateErrorReport,
  updateFailedReport,
  agGridDownloadFileName,
  validateAllDataOnUpload,
  calculationAfterUpload,
  frameDataOnUpload,
  updateNewCommentEntry,
  updateUploadDataFormat,
  setParamsUpdateByProps,
  validateDataByRowOnlyOnUpload,
  onCellValueChangeUpdateFields,
  onCellValueChangFormatValidate,
  onConsoleArr,
  quarterlyGroupedData,
  generateProdQuarterWeeks,
  generateWeeklyReport,
  CustomExcelRenderer
} from '../../services/dashboardService.js';
import {
  selectFullName,
  selectEmail,
  selectUsername,
  selectGroups
} from '../../reducers/authReducer.js';
import { selectStatusButton } from '../../actions/dashboardActions';
import gridConstants from '../../constants/gridConstants.js';
import { msgConstants } from '../../constants/msgConstants.js';
import pageConstants from './Dashboard.constants.js';
import dashboardSearchConstants from './dashboardSearch.constants.js';
import { isEquivalent, verifyTrial } from '../../util/general.js';
import {
  getAppConfigData,
  getAppConfigLoading,
  getAppConfigError
} from '../../reducers/appConfigReducer.js';

import { columnConstants } from '../../constants/columnConstants.js';
const CapChangeNotificationShow = verifyTrial('ccn');

const searchfirst = true;
let noSearchInitiated = true;
const maxDownloadLimit = 4000;
export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGrid: false,
      paramsForExcel: {},
      userType: '',
      UploadModel: false,
      UploadUpdateModel: false,
      UploadUpdateModelType: 'Grid',
      UploadStatusData: [],
      LogCommentModel: false,
      LogCommentValue: '',
      UploadingData: false,
      frameworkComponents: {
        numericEditor: NumericEditor,
        cellLinkRenderer,
        agDateInput: dayPickerFilter
      },
      scoreCardCount: pageConstants.scoreCardCount,
      context: { componentParent: this },
      defaultStatus: 'MaxAvailable',
      editGrid: false,
      defaultColDef: gridConstants.defaultColDef,
      sideBar: gridConstants.sideBar,
      excelStyles: gridConstants.excelStyles,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',

      statusCode: '',
      commentTextArea: '',
      commentTextRowId: '',
      commentTextRowDataId: [],
      isCommentTextUpdating: false,
      currentRowCommentsUpdating: false,
      currentRowComments: [],
      currentRowLogs: [],
      totalLength: 0,
      uploadErrorState: [],
      gridPagination: {},
      searchGroups: dashboardSearchConstants.groups,
      searchParams: dashboardSearchConstants.fields,
      newSearchGroups: [],
      newSearchParams: [],
      searchApiQuery: '',

      getRowHeight(params) {
        let defaultheight = 56;
        if (params.data.maxAvailableReasonCode) {
          if (params.data.maxAvailableReasonCode.length > 60) {
            defaultheight = 56;
          }
        }
        if (params.data.shortHorizonReasonCode) {
          if (params.data.shortHorizonReasonCode.length > 60) {
            defaultheight = 56;
          }
        }
        return defaultheight;
      },

      changedRowsData: [],
      saveViewToogle: 0,
      gridCurrentPageData: [],
      gridCurrentRawData: [],
      gridColumnConfig: [],
      defaultSortAfterLoad: [],
      defaultSortNotifyListAfterLoad: [
        {
          colId: 'weekStartDate',
          sort: 'asc'
        }
      ],
      pageSearch: 0,
      reasonCodesAll: [],
      reasonCodes: [],
      maxColumnEditable: true,
      recentDownloadedGridFile: '',
      recentDownloadedGridFileId: '',
      updateCycleCompleteModel: false,
      updateCycleCompleteMsg: false,
      updateCycleReport: {},
      revisedHorizonWeeks: 0,
      displayView: 0,
      prodQuarterColumns: [],
      prodQuartersData: [],
      prodQuartersDataBackUp: [],
      agGridQuarterParams: {},
      isToggleOn: false,
      quarterRowDataChanged: [],
      quarterScoreCardCount: pageConstants.quarterScoreCardCount,
      quarterGridCurrentFilter: {},
      commentSession: {},
      userNotify: false,
      isUSerNotifySearchInitiated: false,
      isGridInReview: false,
      isCommentAddedInQuarterView: false,
      saveDisabled: false,
      thresholds: {
        revisedThresholdIncrease: '',
        revisedThresholdDecrease: '',
        strategicThresholdIncrease: '',
        strategicThresholdDecrease: ''
      },
      thresholdCounts: { passed: 0, all: 0 },
      modifyTHExceedsArray: [],
      userFactoryType: '',
      searchQuery: {}
    };

    this.getDashboardType = this.getDashboardType.bind(this);
    this.downloadData = this.downloadData.bind(this);
    this.saveColState = this.saveColState.bind(this);
    this.saveDataChanges = this.saveDataChanges.bind(this);

    this.uploadProcess = this.uploadProcess.bind(this);
    this.uploadStatusUpdate = this.uploadStatusUpdate.bind(this);
    this.scoreCardCalculation = this.scoreCardCalculation.bind(this);

    this.toggleUploadModel = this.toggleUploadModel.bind(this);
    this.toogleUploadUpdateModel = this.toogleUploadUpdateModel.bind(this);
    this.toogleLogCommentModel = this.toogleLogCommentModel.bind(this);
    this.displayLogEntryDetails = this.displayLogEntryDetails.bind(this);
    this.callExternalFilter = this.callExternalFilter.bind(this);
    this.onChildGridReady = this.onChildGridReady.bind(this);
    this.addComment2Grid = this.addComment2Grid.bind(this);
    this.handleCommentTextAreaChange = this.handleCommentTextAreaChange.bind(
      this
    );

    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onDisplayedColumnsChanged = this.onDisplayedColumnsChanged.bind(this);
    this.fileHandler = this.fileHandler.bind(this);
    this.onSearchEnter = this.onSearchEnter.bind(this);
    this.onSearchEnterApi = this.onSearchEnterApi.bind(this);
    this.onSearchFactoryGrpApi = this.onSearchFactoryGrpApi.bind(this);
    this.onSearchCall = this.onSearchCall.bind(this);

    this.goToPage = this.goToPage.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.onQuarterCellValueChanged = this.onQuarterCellValueChanged.bind(this);
    this.quarterScoreCardCalculation = this.quarterScoreCardCalculation.bind(
      this
    );
    this.postCommentUpdate = this.postCommentUpdate.bind(this);
    this.onUpdateAppConfig = this.onUpdateAppConfig.bind(this);
    this.onSearchByNotificationApi = this.onSearchByNotificationApi.bind(this);

    this.scoreCardEventRef = React.createRef();
  }

  componentDidMount() {
    // On page load, loading max available dashboard as default
    let defaultStatus = 'MaxAvailable';
    const { location, appConfigData, userProfileData } = this.props;
    const { pathname, query } = location;
    // checking url is to load max available or short horizon dashboard

    if (userProfileData) {
      this.setState({
        userType: userProfileData.userRole,
        userFactoryType: userProfileData.factoryType
      });
    }
    if (pathname && pathname.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    const queryString = window.location.search;
    if (
      queryString &&
      queryString.toString().length > 0 &&
      queryString.includes('?')
    ) {
      const queryObj = qs.parse(queryString.replace('?', ''));

      if (
        queryObj &&
        (queryObj.userNotify === true || queryObj.userNotify === 'true')
      ) {
        if (queryObj.productionQuarter) {
        }
        noSearchInitiated = false;
        this.setState({ userNotify: true, saveViewToogle: 0 });
      }
    }

    // Function to call API for Grid Data
    this.getDashboardType(defaultStatus);
    localStorage.setItem('isRowDataChanged', 'false');
    localStorage.setItem('isQuarterRowDataChanged', 'false');
    localStorage.setItem('isGBDQuarterRowDataChanged', 'false');
    if (appConfigData) {
      this.onUpdateAppConfig(appConfigData);
    }
    if (query && (query.userNotify === true || query.userNotify === 'true')) {
    } else {
      this.setState({ searchQuery: location.query, gridPagination: {} }, () => {
        this.onSearchByGlobalFilters();
      });
    }
  }

  // eslint-disable-next-line complexity
  componentWillReceiveProps(nextProps) {
    // On page load, loading max available dashboard as default
    let defaultStatus = 'MaxAvailable';
    const {
      searchParams,
      UploadStatusData,
      isUSerNotifySearchInitiated,
      currentRowCommentsUpdating,
      userType,
      currentRowComments,
      userFactoryType,
      searchApiQuery
    } = this.state;
    const {
      location,
      dashboardData,
      userProfileData,
      dashboardDataUpdate,
      appConfigData,
      dashboardGridColumns,
      commentUpdateStatus,
      clearDashboardData,
      userNotifyData
    } = this.props;
    const { pathname, search } = location;
    const newPathName = nextProps.location.pathname;
    const oldPathName = pathname;
    let userNotify = false;

    if (nextProps.location.search) {
      const nextPropSearch = nextProps.location.search;
      if (nextPropSearch && nextPropSearch.includes('?')) {
        const queryObj = qs.parse(nextPropSearch.replace('?', ''));
        if (queryObj) {
          if (queryObj.userNotify === true || queryObj.userNotify === 'true') {
            userNotify = true;
            if (!isUSerNotifySearchInitiated) {
              this.onSearchByNotificationApi(queryObj);
            }
          }
        }
      }
    } else {
      searchParams.find(o => o.field === 'capacityTypeCode').value = '';
      this.setState({
        searchParams
      });
    }

    if (search !== nextProps.location.search) {
      const nextPropSearch = nextProps.location.search;
      if (
        nextPropSearch.userNotify !== true ||
        nextPropSearch.userNotify !== 'true'
      ) {
        this.setState(
          { searchQuery: nextProps.location.query, gridPagination: {} },
          () => {
            this.onSearchByGlobalFilters();
          }
        );
      }
    }

    if (
      search !== nextProps.location.search &&
      nextProps.location.search === ''
    ) {
      this.setState({
        isUSerNotifySearchInitiated: true,
        gridCurrentPageData: [],
        updateCycleComplete: false,
        isToggleOn: false,
        loadGrid: true,
        paramsForExcel: {},
        changedRowsData: [],
        quarterRowDataChanged: []
      });

      clearDashboardData({});
    }

    if (newPathName && newPathName.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }

    if (userProfileData !== nextProps.userProfileData) {
      const newUsrProData = nextProps.userProfileData;
      if (newUsrProData && newUsrProData.association) {
        this.setState({
          userFactoryType: newUsrProData.factoryType
        });
      }
    }

    if (dashboardDataUpdate !== nextProps.dashboardDataUpdate) {
      const updateData = nextProps.dashboardDataUpdate;
      let UploadStatusDataNew = [];
      if (
        updateData &&
        (updateData.status === 201 ||
          (Array.isArray(updateData.status) && updateData.status.includes(201)))
      ) {
        if (updateData.outputData) {
          UploadStatusDataNew = updateErrorReport(
            updateData.outputData,
            UploadStatusData
          );
        }
      } else {
        UploadStatusDataNew = updateFailedReport(UploadStatusData);
      }
      this.setState({
        updateCycleComplete: true,
        UploadStatusData: UploadStatusDataNew
      });
      this.reloadGridContentAfterSave(nextProps.dashboardDataUpdate);
    }

    if (appConfigData !== nextProps.appConfigData) {
      this.onUpdateAppConfig(nextProps.appConfigData);
    }
    if (dashboardGridColumns !== nextProps.dashboardGridColumns) {
      if (
        nextProps.dashboardGridColumns &&
        nextProps.dashboardGridColumns.role
      ) {
        // PQ COL
        const {
          reasonCodes,
          reasonCodesAll,
          maxColumnEditable,
          prodQuarterColumns
        } = setParamsUpdateByProps(
          nextProps.dashboardGridColumns,
          defaultStatus,
          userType,
          userFactoryType
        );
        this.setState({
          reasonCodes,
          reasonCodesAll,
          maxColumnEditable,
          prodQuarterColumns: prodQuarterColumns,
          userType: nextProps.dashboardGridColumns.role
        });
      }
    }
    if (commentUpdateStatus !== nextProps.commentUpdateStatus) {
      this.postCommentUpdate(nextProps.commentUpdateStatus);
    }

    if (
      !_.isEqual(nextProps.dashboardData, dashboardData) &&
      nextProps.dashboardData.data
    ) {
      if (dashboardData.data) {
        if (nextProps.dashboardData.data.length !== dashboardData.data.length) {
          setTimeout(() => {
            this.scoreCardCalculation();
            this.quarterScoreCardCalculation();
          }, 1000);
        }
      }

      const urlSend = `${
        defaultStatus === 'MaxAvailable' ? 'strategic' : 'revised'
      }?${searchApiQuery}`;
      const urlReceived = new URL(nextProps.dashboardData.query);
      let pathandQuery = urlReceived.pathname + urlReceived.search;
      if (pathandQuery.includes(urlSend) || userNotify) {
        const prodQuartersData = quarterlyGroupedData(
          nextProps.dashboardData.data,
          defaultStatus
        );
        const prodQuartersDataBackUp = JSON.parse(
          JSON.stringify(prodQuartersData)
        );
        this.setState({
          gridCurrentRawData: nextProps.dashboardData.data,
          prodQuartersData,
          prodQuartersDataBackUp
        });
      }
    }

    if (newPathName !== oldPathName) {
      noSearchInitiated = true;
      this.getDashboardType(defaultStatus);
    }

    if (nextProps.dashboardData.role || nextProps.dashboardData.dashboard) {
      if (
        nextProps.dashboardData.role !== dashboardData.role ||
        nextProps.dashboardData.dashboard !== dashboardData.dashboard
      ) {
        this.setState({
          loadGrid: false
        });
      }
    }

    if (
      !nextProps.commentsLogsDataLoading &&
      nextProps.commentsLogsData !== undefined &&
      Array.isArray(nextProps.commentsLogsData.commentsLogsData) &&
      currentRowComments.length === 0
    ) {
      const data =
        nextProps.commentsLogsData &&
        nextProps.commentsLogsData.commentsLogsData;

      let allNotes = [];
      let logs = [],
        weekStartDate;
      const { isToggleOn } = this.state;
      let quarterComments = [];
      if (data && Array.isArray(data)) {
        data.forEach(obj => {
          const notes =
            defaultStatus === 'MaxAvailable'
              ? obj.maxAvailableNotes
              : obj.shortHorizonNotes;

          if (notes && Array.isArray(notes)) {
            allNotes.push(...notes);
          }

          if (obj.logs && Array.isArray(obj.logs)) {
            obj.logs.forEach(log => {
              const logObject = log;
              logObject.weekStartDate = obj.weekStartDate;
              logs.push(logObject);
            });
          }

          if (obj.weekStartDate) {
            weekStartDate = obj.weekStartDate;
          }
        });
        if (isToggleOn) {
          quarterComments = _.uniqWith(
            allNotes,
            (la, lb) =>
              la.comment === lb.comment &&
              la.userId === lb.userId &&
              la.timestamp === lb.timestamp
          );
          allNotes = quarterComments.filter(pp => pp.isQuarterly);
        }
      }
      const { LogCommentValue } = this.state;
      let currentRowLogs = [];

      if (LogCommentValue === 'time' && logs) {
        logs = _.orderBy(logs, ['weekStartDate'], ['asc']);
        currentRowLogs = logs;
      }
      if (!isToggleOn) {
        currentRowLogs.forEach(log => {
          log.weekStartDate = weekStartDate || '';
        });
      }
      if (!currentRowCommentsUpdating) {
        this.setState({
          currentRowComments: allNotes
        });
      }
      this.setState({
        currentRowLogs
      });
    }

    this.setState({
      userNotify,
      saveViewToogle: 0,
      userNotifyData
    });
  }

  onUpdateAppConfig(appConfigData) {
    let rvsdHorzLocal = localStorage.getItem('revisedHorizonWeeks');
    let revisedHorizonWeeks = rvsdHorzLocal ? Number(rvsdHorzLocal) : 0,
      revisedThresholdIncrease = '',
      revisedThresholdDecrease = '',
      strategicThresholdIncrease = '',
      strategicThresholdDecrease = '';
    if (appConfigData && Object.keys(appConfigData).length !== 0) {
      revisedHorizonWeeks = appConfigData.revisedHorizonWeeks
        ? appConfigData.revisedHorizonWeeks
        : revisedHorizonWeeks;
      revisedThresholdIncrease = appConfigData.revisedThresholdIncrease
        ? appConfigData.revisedThresholdIncrease
        : revisedThresholdIncrease;
      revisedThresholdDecrease = appConfigData.revisedThresholdDecrease
        ? appConfigData.revisedThresholdDecrease
        : revisedThresholdDecrease;
      strategicThresholdIncrease = appConfigData.strategicThresholdIncrease
        ? appConfigData.strategicThresholdIncrease
        : strategicThresholdIncrease;
      strategicThresholdDecrease = appConfigData.strategicThresholdDecrease
        ? appConfigData.strategicThresholdDecrease
        : strategicThresholdDecrease;

      this.setState({
        revisedHorizonWeeks: revisedHorizonWeeks
      });
      this.setState(prevState => ({
        thresholds: {
          ...prevState.thresholds,
          revisedThresholdIncrease: revisedThresholdIncrease,
          revisedThresholdDecrease: revisedThresholdDecrease,
          strategicThresholdIncrease: strategicThresholdIncrease,
          strategicThresholdDecrease: strategicThresholdDecrease
        }
      }));
    }
  }

  onSearchValidation() {
    // search form validation
    const { searchGroups, searchParams } = this.state;
    return searchValidator(searchGroups, searchParams);
  }

  onSearchEnterApi() {
    // after selecting all required search params, retriving data
    this.setState({
      gridCurrentPageData: [],
      updateCycleComplete: false,
      isToggleOn: false
    });

    const {
      defaultStatus,
      userType,
      newSearchParams,
      newSearchGroups,
      gridPagination,
      revisedHorizonWeeks,
      userFactoryType
    } = this.state;
    noSearchInitiated = false;

    const {
      requestDashboardData,
      requestDashboardGridColumns,
      currentusername
    } = this.props;

    const onSearchValidStatus = this.onSearchValidation();
    if (onSearchValidStatus.status) {
      const searchApiQuery = searchQueryFrame(
        2,
        newSearchGroups,
        newSearchParams,
        gridPagination,
        maxDownloadLimit,
        revisedHorizonWeeks
      );
      const getDashboardData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchApiQuery,
        type: 'bulk',
        factoryCode: fetchAllQueryValue(
          newSearchGroups.find(o => o.field === 'factoryCode'),
          true
        ),
        userFactoryType: userFactoryType
      };
      this.setState({
        loadGrid: true,
        paramsForExcel: {},
        changedRowsData: [],
        quarterRowDataChanged: [],
        searchApiQuery
      });
      localStorage.setItem('isRowDataChanged', 'false');
      localStorage.setItem('isQuarterRowDataChanged', 'false');
      requestDashboardGridColumns(getDashboardData);
      requestDashboardData(getDashboardData); // API  Request function for Grid Data
    } else {
      this.uploadStatusUpdate(onSearchValidStatus);
    }
  }

  onSearchByNotificationApi(obj) {
    // after selecting all required search params, retriving data
    const {
      userType,
      searchParams,
      searchGroups,
      userFactoryType
    } = this.state;
    const { location } = this.props;
    const { pathname } = location;

    let defaultStatus = 'MaxAvailable';
    const {
      requestDashboardData,
      requestDashboardGridColumns,
      currentusername
    } = this.props;

    // const searchfields = qs.stringify(newObj);
    let sf = '';
    if (obj.divisionCode) {
      sf += `divisionCode=${obj.divisionCode}`;
    }
    if (obj.factoryCode) {
      sf += `${sf ? '&' : '?'}factoryCode=${obj.factoryCode}`;
    }
    if (obj.productionQuarter) {
      sf += `${sf ? '&' : '?'}productionQuarter=${obj.productionQuarter}`;
    }
    if (obj.capacityIds) {
      sf += `${sf ? '&' : '?'}capacityIds=${obj.capacityIds}`;
    }
    searchGroups.forEach(i => {
      if (obj.hasOwnProperty(i.field)) {
        i.value = obj[i.field];
      }
    });
    searchParams.forEach(i => {
      if (obj.hasOwnProperty(i.field)) {
        i.value = obj[i.field];
      }
    });
    this.setState({
      searchGroups,
      searchParams,
      isUSerNotifySearchInitiated: true,
      gridCurrentPageData: [],
      updateCycleComplete: false,
      isToggleOn: false,
      loadGrid: true,
      paramsForExcel: {},
      changedRowsData: [],
      quarterRowDataChanged: [],
      searchApiQuery: sf
    });
    if (pathname && pathname.indexOf('revisedCapacity') !== -1) {
      defaultStatus = 'shortHorizon';
    }
    const getDashboardData = {
      user: currentusername,
      role: userType,
      dashboard: defaultStatus,
      searchfields: sf,
      type: 'notifications',
      factoryCode: obj.factoryCode,
      userFactoryType: userFactoryType
    };

    localStorage.setItem('isRowDataChanged', 'false');
    localStorage.setItem('isQuarterRowDataChanged', 'false');

    requestDashboardGridColumns(getDashboardData);
    requestDashboardData(getDashboardData); // API  Request function for Grid Data
  }

  onSearchFilterParams(query) {
    const { revisedHorizonWeeks, gridPagination } = this.state;
    let qst = '';
    if (Object.keys(query).length > 0) {
      qst = Object.keys(query)
        .map(
          key =>
            `${key}=${
              ['startWeekDate', 'endWeekDate'].includes(key)
                ? query[key].replace('-', '/')
                : query[key]
            }`
        )
        .join('&');
    }
    if (qst && revisedHorizonWeeks) {
      qst += `${qst ? '&' : '?'}boundaryWeek=${revisedHorizonWeeks}`;
    }
    if (qst && maxDownloadLimit) {
      qst += `${qst ? '&' : '?'}count=${maxDownloadLimit}`;
    }
    if (Object.keys(gridPagination).length > 0) {
      const queryString = Object.keys(gridPagination)
        .map(key => `${key}=${gridPagination[key]}`)
        .join('&');
      qst += `${qst ? '&' : '?'}${queryString}`;
    }
    if (qst.toLowerCase().indexOf('anchor') === -1) {
      qst += `&anchor=1`;
    }
    return qst;
  }

  onSearchByGlobalFilters() {
    // after selecting all required search params, retriving data
    this.setState({
      gridCurrentPageData: [],
      updateCycleComplete: false,
      isToggleOn: false
    });
    const {
      defaultStatus,
      userType,
      userFactoryType,
      searchQuery
    } = this.state;
    noSearchInitiated = false;

    const {
      requestDashboardData,
      requestDashboardGridColumns,
      currentusername,
      userProfileData
    } = this.props;

    if (userProfileData) {
      let newQuery = typeof searchQuery === 'object' ? { ...searchQuery } : {};
      if (newQuery && newQuery.factoryCode) {
        //good
      } else {
        let factoryCodes = [];
        if (searchQuery && searchQuery.factoryGroup) {
          const factoryGroups = userProfileData.association.find(
            i => i.factoryGroup === searchQuery.factoryGroup
          );
          const factoryies =
            factoryGroups && factoryGroups.factories
              ? [...factoryGroups.factories]
              : [];
          const index = factoryies.indexOf('ALL');
          if (index > -1) {
            factoryies.splice(index, 1);
          }
          newQuery.factoryCode = factoryies.sort().join();
        } else {
          factoryCodes = [...userProfileData.factoryCodesArr] || [];
          const index = factoryCodes.indexOf('ALL');
          if (index > -1) {
            factoryCodes.splice(index, 1);
          }
          newQuery.factoryCode = factoryCodes.join();
        }
      }
      try {
        // to avoid the errors can be caused sometimes

        delete newQuery.factoryGroup;
        delete newQuery.qp;
      } catch (e) {
        // this will run only if the code in the try block errors-out
      }

      const queryString = this.onSearchFilterParams(newQuery);
      const getDashboardData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: queryString,
        type: 'bulk',
        factoryCode: newQuery.factoryCode,
        userFactoryType: userFactoryType
      };
      this.setState({
        loadGrid: true,
        paramsForExcel: {},
        changedRowsData: [],
        quarterRowDataChanged: [],
        searchApiQuery: queryString
      });
      localStorage.setItem('isRowDataChanged', 'false');
      localStorage.setItem('isQuarterRowDataChanged', 'false');

      requestDashboardGridColumns(getDashboardData);
      requestDashboardData(getDashboardData); // API  Request function for Grid Data
    }
  }

  onSearchFactoryGrpApi() {
    // this.setState({ gridCurrentPageData: [] });
    const {
      searchGroups,
      searchParams,
      gridPagination,
      revisedHorizonWeeks
    } = this.state;
    noSearchInitiated = true;
    const { getusrFactGrpSearchData } = this.props;
    const allString = searchQueryFrame(
      1,
      searchGroups,
      searchParams,
      gridPagination,
      maxDownloadLimit,
      revisedHorizonWeeks
    );
    if (allString) {
      getusrFactGrpSearchData(allString);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSearchEnter() {
    const { searchGroups, searchParams } = this.state;
    this.setState({
      newSearchGroups: JSON.parse(JSON.stringify(searchGroups)),
      newSearchParams: JSON.parse(JSON.stringify(searchParams))
    });
    // updates the seach box entries, frames query and calls API
    if (
      localStorage.getItem('isRowDataChanged') === 'false' &&
      localStorage.getItem('isQuarterRowDataChanged') === 'false'
    ) {
      this.setState({ changedRowsData: [], quarterRowDataChanged: [] });
    }
    const { changedRowsData, quarterRowDataChanged } = this.state;
    if (changedRowsData.length === 0 && quarterRowDataChanged.length === 0) {
      this.setState({
        gridCurrentPageData: [],
        saveViewToogle: 0,
        statusCode: ''
      });
      setTimeout(() => {
        this.onSearchEnterApi();
      }, 300);
      return false;
    } else {
      confirmAlert({
        message: msgConstants.pageChangeAlert,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.setState({
                gridCurrentPageData: [],
                saveViewToogle: 0,
                statusCode: ''
              });
              this.setState({ changedRowsData: [], quarterRowDataChanged: [] });
              this.onSearchEnterApi();
            }
          },
          {
            label: 'No'
          }
        ]
      });
    }
  }

  onSearchCall() {
    this.setState({ gridPagination: {}, isGridInReview: false });
    this.onSearchEnter();
  }

  getDashboardType(defaultStatus) {
    // Function call on page load and reload, to initate API request for Grid Header and Data
    const {
      requestDashboardData,
      requestDashboardGridColumns,
      currentusername,
      currentusergroups,
      clearDashboardData
    } = this.props;
    clearDashboardData({});
    noSearchInitiated = true;

    const { searchBoxFields } = this.state;
    this.setState({
      gridColumnConfig: [],
      pageSearch: 0,
      searchApiQuery: searchBoxFields
    });
    // eslint-disable-next-line prefer-const
    let { userType, userFactoryType } = this.state;
    const sessionUserType = window.sessionStorage.getItem('currentUserGroup');
    if (sessionUserType) {
      userType = sessionUserType;
    } else {
      userType = currentusergroups[0].value;
    }
    // setting the factory codes for the default dropdown in serch box

    // end of update
    this.setState({ defaultStatus, userType }); // set new dashboard type
    // condition check to load the entire grid data at first itself or to load only after seach with user inputs

    if (!searchfirst) {
      const { searchBoxFields } = this.state;
      const getDashboardData = {
        user: currentusername,
        role: userType,
        dashboard: defaultStatus,
        searchfields: searchBoxFields,
        type: 'api',
        userFactoryType: userFactoryType
      };
      requestDashboardGridColumns(getDashboardData);
      requestDashboardData(getDashboardData); // API  Request function for Grid Data
      localStorage.setItem('isRowDataChanged', 'false');
    }
  }

  // eslint-disable-next-line max-statements
  onCellValueChanged = event => {
    // Function called on grid ready status
    /*
      Actions: on updating the capacity,
      1.  based on user type change the status
      2.  change approved by/ confirmed by/ as current user
      3. update the API currentusername
     */
    const {
      paramsForExcel,
      userType,
      defaultStatus,
      reasonCodes,
      isToggleOn,
      thresholds
    } = this.state;
    const { currentusername } = this.props;
    let saveDisabled = false;
    const allColumnIdName = [];
    paramsForExcel.columnApi.getAllColumns().forEach(column => {
      allColumnIdName.push({
        field: column.colId,
        name: column.colDef.headerName
      });
    });
    const commentsUpdateStatus = {
      status: false,
      message: msgConstants.inValFormatData
    };
    if (event.oldValue !== event.newValue) {
      if (
        columnConstants.editableFields4AllUsers.includes(event.colDef.field)
      ) {
        const {
          paramsForExcel,
          changedRowsData,
          gridCurrentPageData
        } = this.state;
        let rowNode = paramsForExcel.api.getDisplayedRowAtIndex(event.rowIndex);

        const editedColumn = event.colDef.field;
        const updateSingleRowChange = [];
        //const oldRowValue = gridCurrentPageData[event.rowIndex];
        const oldRowValue = gridCurrentPageData.find(
          o => o.id === rowNode.data.id
        );

        const changeAccepted = onCellValueChangFormatValidate(
          event.colDef.filter,
          event.newValue,
          editedColumn,
          reasonCodes,
          isToggleOn
        );
        const index = changedRowsData.findIndex(i => i.id === rowNode.data.id);
        if (!changeAccepted) {
          rowNode.data[event.colDef.field] = oldRowValue[editedColumn];
        }

        rowNode.data = onCellValueChangeUpdateFields(
          rowNode.data,
          changeAccepted,
          editedColumn,
          event.newValue,
          oldRowValue,
          defaultStatus,
          userType,
          currentusername,
          thresholds
        );

        if (changeAccepted) {
          updateSingleRowChange.push(rowNode.data);
        } else {
          if (oldRowValue[editedColumn] && !event.newValue) {
            saveDisabled = true;
            commentsUpdateStatus.message = msgConstants.repEmptyCapVal;
          }
          this.uploadStatusUpdate(commentsUpdateStatus);
        }

        paramsForExcel.api.redrawRows(event.rowIndex);
        const sameOld = isEquivalent(rowNode.data, oldRowValue);
        if (sameOld) {
          if (index >= 0) {
            changedRowsData.splice(index, 1);
          }
        } else {
          const newData = JSON.parse(JSON.stringify(rowNode.data));
          if (index >= 0) {
            changedRowsData[index] = newData;
          } else {
            changedRowsData.push(newData);
          }
        }
        this.setState({
          changedRowsData,
          updateCycleComplete: false,
          saveDisabled
        });
        if (changedRowsData.length > 0) {
          localStorage.setItem('isRowDataChanged', 'true');
        }

        this.scoreCardCalculation();
      }
    }
  };

  onDisplayedColumnsChanged = () => {
    let { saveViewToogle } = this.state;
    saveViewToogle += 1;
    this.setState({
      saveViewToogle
    });
  };

  setParams = params => {
    // Function called post GRID initial load to reassure column structure and fields
    const {
      gridCurrentPageData,
      defaultSortAfterLoad,
      defaultSortNotifyListAfterLoad,
      userNotify
    } = this.state;

    if (params) {
      this.setState({ paramsForExcel: params, loadGrid: false }); // update AG Grid Properties to state
      if (params.columnApi.getAllColumns()) {
        if (gridCurrentPageData.length === 0 || userNotify) {
          const AllGridData = []; // current grid
          params.api.forEachNode(node => AllGridData.push(node.data));
          this.setState({
            gridCurrentPageData: JSON.parse(JSON.stringify(AllGridData))
          });
          this.scoreCardCalculation(AllGridData); // for uploading the scorecard after grid configured on initial load
        }

        try {
          // to avoid the errors can be caused sometimes
          let toSort = userNotify
            ? defaultSortNotifyListAfterLoad
            : defaultSortAfterLoad;
          params.api.setSortModel(toSort);
          params.api.setSuppressClipboardPaste(false);
          params.api.copySelectedRowsToClipboard(false);
          params.api.closeToolPanel(); // AG Grid closed the side panel which will be open by default
        } catch (e) {
          // this will run only if the code in the try block errors-out
        }
      }
    }
  };

  getValidationDescription(descriptionObject) {
    let messageDesc = '';
    if (
      (descriptionObject.approved || descriptionObject.confirmed) &&
      descriptionObject.higher &&
      descriptionObject.lower
    ) {
      messageDesc =
        'One or more capacity values are outside the capacity change threshold. Would you like to review or do you wish to continue?';
    } else if (
      (descriptionObject.approved || descriptionObject.confirmed) &&
      descriptionObject.higher
    ) {
      messageDesc =
        'One or more capacity values are higher than the capacity change threshold. Would you like to review or do you wish to continue?';
    } else if (
      (descriptionObject.approved || descriptionObject.confirmed) &&
      descriptionObject.lower
    ) {
      messageDesc =
        'One or more capacity values are lower than the capacity change threshold. Would you like to review or do you wish to continue?';
    } else if (descriptionObject.higher && descriptionObject.lower) {
      messageDesc =
        'One or more capacity values are outside the capacity change threshold. Would you like to review or do you wish to continue?';
    } else if (descriptionObject.approved) {
      messageDesc = `The ${
        descriptionObject.approved === 1 ? 'capacity' : 'capacities'
      } you entered ${
        descriptionObject.approved === 1 ? 'is' : 'are'
      } without confirmed input. Would you like to continue approving the ${
        descriptionObject.approved === 1 ? 'capacity' : 'capacities'
      }?`;
    } else if (descriptionObject.confirmed) {
      messageDesc = `The ${
        descriptionObject.confirmed === 1 ? 'capacity' : 'capacities'
      } you entered ${
        descriptionObject.confirmed === 1 ? 'is' : 'are'
      } without factory input. Would you like to continue confirming the ${
        descriptionObject.confirmed === 1 ? 'capacity' : 'capacities'
      }?`;
    } else if (descriptionObject.higher) {
      messageDesc =
        'One or more capacity values are higher than the capacity change threshold. Would you like to review or do you wish to continue?';
    } else if (descriptionObject.lower) {
      messageDesc =
        'One or more capacity values are lower than the capacity change threshold. Would you like to review or do you wish to continue?';
    }
    return messageDesc;
  }

  getValidationMessages(thresholdList) {
    const descriptionObject = {
      confirmed: 0,
      approved: 0,
      higher: 0,
      lower: 0
    };
    const isConfirmedSkipd = thresholdList
      .filter(
        i =>
          i.inColumnSkipped.includes('maxAvailableUnitsConfirmed') ||
          i.inColumnSkipped.includes('shortHorizonUnitsConfirmed')
      )
      .map(obj => obj.inColumnSkipped.length > 0 && obj.inColumnSkipped[0]);
    descriptionObject.confirmed = isConfirmedSkipd.length;
    const isApprovedSkipd = thresholdList
      .filter(
        i =>
          i.inColumnSkipped.includes('approvedMaxCapacity') ||
          i.inColumnSkipped.includes('approvedRevisedCapacity')
      )
      .map(obj => obj.inColumnSkipped.length > 0 && obj.inColumnSkipped[0]);
    descriptionObject.approved = isApprovedSkipd.length;
    const higherThresholdArray = thresholdList
      .filter(i => i.inThreshold > 0)
      .map(obj => obj.inThreshold)
      .sort((a, b) => (a > b ? 1 : -1));
    const higherThresholdRange =
      higherThresholdArray.length > 0
        ? [
            `+${higherThresholdArray[0]}`,
            `+${higherThresholdArray[higherThresholdArray.length - 1]}`
          ]
        : [];
    const higherThresholdUniqueRange = [...new Set(higherThresholdRange)];
    descriptionObject.higher = higherThresholdRange.length;
    const lowerThresholdArray = thresholdList
      .filter(i => i.inThreshold < 0)
      .map(obj => obj.inThreshold)
      .sort((a, b) => (a < b ? 1 : -1));
    const lowerThresholdRange =
      lowerThresholdArray.length > 0
        ? [
            lowerThresholdArray[0],
            lowerThresholdArray[lowerThresholdArray.length - 1]
          ]
        : [];
    const lowerThresholdUniqueRange = [...new Set(lowerThresholdRange)];
    descriptionObject.lower = lowerThresholdRange.length;
    let messageArray = [];
    if (
      (descriptionObject.approved || descriptionObject.confirmed) &&
      (descriptionObject.higher || descriptionObject.lower)
    ) {
      messageArray.push({
        title: `One or More Capacity Values Missing`
      });
    } else if (isApprovedSkipd.length > 0) {
      messageArray.push({
        title: `Approve ${
          isApprovedSkipd.length === 1 ? 'Capacity' : 'Capacities'
        }`
      });
    } else if (isConfirmedSkipd.length > 0) {
      messageArray.push({
        title: `Confirm ${
          isConfirmedSkipd.length === 1 ? 'Capacity' : 'Capacities'
        }`
      });
    }
    if (
      higherThresholdUniqueRange.length > 0 &&
      lowerThresholdUniqueRange.length > 0
    ) {
      messageArray.push({
        title: `Higher and Lower Capacity Value(s) Entered`,
        percentage: higherThresholdUniqueRange
      });
    } else if (higherThresholdUniqueRange.length > 0) {
      messageArray.push({
        title: `Higher Capacity Value(s) Entered`,
        percentage: higherThresholdUniqueRange
      });
    } else if (lowerThresholdUniqueRange.length > 0) {
      messageArray.push({
        title: `Lower Capacity Value(s) Entered`,
        percentage: lowerThresholdUniqueRange
      });
    }
    const description = this.getValidationDescription(descriptionObject);
    return { messageArray, description };
  }

  onSaveClick() {
    //
    const {
      isToggleOn,
      changedRowsData,
      quarterRowDataChanged,
      thresholds,
      defaultStatus
    } = this.state;
    const {
      strategicThresholdIncrease,
      strategicThresholdDecrease,
      revisedThresholdIncrease,
      revisedThresholdDecrease
    } = thresholds;
    const isThresholdApplicable =
      defaultStatus === 'MaxAvailable'
        ? strategicThresholdIncrease >= 0 && strategicThresholdDecrease >= 0
        : revisedThresholdIncrease >= 0 && revisedThresholdDecrease >= 0;
    if (isThresholdApplicable) {
      const thresholdList = isToggleOn
        ? quarterRowDataChanged
        : changedRowsData;
      const { messageArray, description } = this.getValidationMessages(
        thresholdList
      );
      this.setState({ thresholdCounts: { passed: 0, all: 0 } });
      if (messageArray.length > 0) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="c-f-all bg-white p2r-sm validation-alert-box">
                {messageArray.map((message, index) => (
                  <h1 className="text-color-black  page-title-has-back fs-24px pt0-sm validation-alert-text-margin">
                    {message.title}
                    {/* <span className="text-color-grey1">
                      {message.percentage && message.percentage.length === 1
                        ? ` (${message.percentage[0]}%)`
                        : ``}
                      {message.percentage && message.percentage.length === 2
                        ? ` (${message.percentage[0]}% to  ${message.percentage[1]}%)`
                        : ``}
                    </span> */}
                    {index !== messageArray.length - 1
                      ? ' &'
                      : !message.percentage
                      ? ' ?'
                      : ''}
                  </h1>
                ))}
                <p className="fs-14px validation-alert-text-margin">
                  {description}
                </p>
                <div className="pt4-sm ta-sm-r ">
                  <button
                    onClick={() => {
                      this.reviewChanges();
                      onClose();
                    }}
                    className="  ncss-btn-secondary mr-2-sm validation-alert-button-text"
                  >
                    Review
                  </button>
                  <button
                    className="  ncss-btn-primary-dark validation-alert-button-text"
                    onClick={() => {
                      this.saveDataChanges();
                      onClose();
                    }}
                  >
                    Save &amp; Continue
                  </button>
                </div>
              </div>
            );
          }
        });
      } else {
        this.saveDataChanges();
      }
    } else {
      this.saveDataChanges();
    }
  }

  reviewChanges() {
    /**
     * 1. if isToggleOn is on  get quarterRowDataChanged and filter and push to 'quarterRowDataChangeToReview'
     * 2. update grid data as quarterRowDataChangeToReview using agGridQuarterParams
     *
     * 1. need to split the edited records to 2 set, one which is without the errors to be saved
     * 2. another to be with list of crossing threshold or column skipping and sent it to alerts
     */
    const { isToggleOn } = this.state;
    let { changedRowsData, quarterRowDataChanged } = this.state;

    const tempModifiedAllData = isToggleOn
      ? quarterRowDataChanged
      : changedRowsData;
    const modifyTHExceedsArray = tempModifiedAllData.filter(
      i => i.inColumnSkipped.length > 0 || i.inThresholdExceedCols.length > 0
    );
    const modifyTHLimitArray = tempModifiedAllData.filter(
      i =>
        i.inColumnSkipped.length === 0 && i.inThresholdExceedCols.length === 0
    );
    if (modifyTHExceedsArray.length === tempModifiedAllData.length) {
      // if all records either exceelding threshold or column skipped then no save
      this.setState({ isGridInReview: true }, () => {
        this.callExternalFilter('savecheck-threshold');
      });
    } else {
      const thresholdCounts = {
        passed: modifyTHLimitArray.length,
        all: isToggleOn ? quarterRowDataChanged.length : changedRowsData.length
      };
      if (isToggleOn) {
        quarterRowDataChanged = modifyTHLimitArray;
      } else {
        changedRowsData = modifyTHLimitArray;
      }
      this.setState(
        {
          isGridInReview: true,
          thresholdCounts: thresholdCounts,
          changedRowsData: changedRowsData,
          quarterRowDataChanged: quarterRowDataChanged,
          modifyTHExceedsArray: modifyTHExceedsArray
        },
        () => {
          this.saveDataChanges();
          // this.callExternalFilter('savecheck-threshold');
        }
      );
    }
  }

  saveDataChanges() {
    const { isToggleOn } = this.state;

    this.setState(
      {
        isCommentAddedInQuarterView: true,
        isGridInReview: false
      },
      () => {
        this.callExternalFilter('');
      }
    );

    if (isToggleOn) {
      this.saveProdQuarterData();
      setTimeout(() => {
        this.saveWeeklyData();
      }, 500);
    } else {
      this.saveWeeklyData();
    }
  }

  saveProdQuarterData = () => {
    const { currentuseremail } = this.props;
    const {
      defaultStatus,
      paramsForExcel,
      userType,
      reasonCodesAll,
      reasonCodes,
      maxColumnEditable,
      agGridQuarterParams,
      quarterRowDataChanged,
      prodQuartersDataBackUp,
      isToggleOn,
      userFactoryType
    } = this.state;
    let changedRowsData = [];
    const validatedUploadPQData = [];
    const validatedUploadPQResult = [];
    const currentFilter = agGridQuarterParams.api.getFilterModel();
    if (quarterRowDataChanged.length > 0) {
      const quarterCurrentGrid = [];

      const AllColNames = [];
      const getAllColumns = paramsForExcel.columnApi.getAllColumns();
      getAllColumns.forEach(item => {
        const itemis = {
          name: item.colDef.headerName,
          code: item.colId,
          field: item.colDef.field
        };
        AllColNames.push(itemis);
      });

      agGridQuarterParams.api.forEachNodeAfterFilterAndSort(node =>
        quarterCurrentGrid.push(node.data)
      );
      quarterRowDataChanged.forEach(qrItem => {
        const foundIndex = quarterCurrentGrid.findIndex(
          i => i.uniQId === qrItem.uniQId
        );
        const backupRow = prodQuartersDataBackUp.find(
          i => i.uniQId === qrItem.uniQId
        );
        if (userType === 'FACTORY') {
          const { newActRow, result } = validateDataByRowOnlyOnUpload(
            qrItem,
            JSON.parse(JSON.stringify(qrItem)),
            backupRow,
            userType,
            reasonCodes,
            reasonCodesAll,
            defaultStatus,
            currentuseremail,
            false,
            maxColumnEditable,
            isToggleOn
          );
          if (result.length > 0) {
            result.forEach(log => {
              const logNew = { ...log };
              logNew.id = qrItem.uniQId;

              logNew.row = foundIndex + 1;
              logNew.fieldname = AllColNames.find(
                i => i.field === log.field
              ).name;
              logNew.productionQuarter = qrItem.productionQuarter;
              logNew.factoryCode = qrItem.factoryCode;
              logNew.capacityTypeCode = qrItem.capacityTypeCode;
              logNew.weekStartDate = qrItem.weekStartDate;
              validatedUploadPQResult.push(logNew);
              const facCapCol2Check =
                defaultStatus === 'MaxAvailable'
                  ? 'maxAvailableCapacity'
                  : 'shortHorizonCapacity';
              const status2Change =
                defaultStatus === 'MaxAvailable'
                  ? 'maxAvailableStatus'
                  : 'shortHorizonStatus';
              const CPCol =
                defaultStatus === 'MaxAvailable'
                  ? 'maxAvailableEditedBy'
                  : 'shortHorizonEditedBy';
              if (!log.status) {
                if (log.field === facCapCol2Check) {
                  newActRow[status2Change] = backupRow[status2Change];
                  newActRow[CPCol] = backupRow[CPCol];
                }
                Object.keys(columnConstants.holidayColUpdate).forEach(col => {
                  if (col === log.field) {
                    const holCol = columnConstants.holidayColUpdate[col];
                    if (newActRow[col] === backupRow[col]) {
                      newActRow[holCol] = backupRow[holCol];
                    } else {
                      newActRow[holCol] = Number(
                        holidayCalculator(holCol, newActRow)
                      );
                    }
                  }
                });
              }
            });
          }
          let rowNode = agGridQuarterParams.api.getDisplayedRowAtIndex(
            foundIndex
          );
          rowNode.data = newActRow;
          agGridQuarterParams.api.redrawRows(foundIndex);

          if (newActRow.ChangeByUpload > 0) {
            newActRow.colModified = [];
            validatedUploadPQData.push(newActRow);
          }
        } else {
          validatedUploadPQData.push(qrItem);
        }
      });
      changedRowsData = generateProdQuarterWeeks(
        validatedUploadPQData,
        prodQuartersDataBackUp,
        paramsForExcel,
        false,
        defaultStatus,
        userFactoryType
      );
    }
    let showOnlyPQReport = false;
    if (changedRowsData.length === 0 && validatedUploadPQResult.length > 0) {
      showOnlyPQReport = true;
    }
    this.setState({
      quarterRowDataChanged: [],
      changedRowsData,
      validatedUploadPQResult,
      UploadStatusData: [],
      UploadUpdateModelType: 'Grid',
      UploadUpdateModel: showOnlyPQReport,
      quarterGridCurrentFilter: currentFilter
    });
    localStorage.setItem('isQuarterRowDataChanged', 'false');
    paramsForExcel.api.redrawRows();
  };

  saveWeeklyData = () => {
    // Function to call Data Save API to update changes made in AG grid data, either by editing or by uploading
    // this method is currently disabled as initially on user completes all the changes user has to click save button and to call API to update
    // now on each row updated, immediately API call will be initiated
    const { updateDashboardData, currentuseremail } = this.props;
    const {
      defaultStatus,
      changedRowsData,
      paramsForExcel,
      userType,
      gridCurrentPageData,
      reasonCodesAll,
      reasonCodes,
      maxColumnEditable,
      isToggleOn,
      thresholdCounts
    } = this.state;

    const modifiedUploadData = updateUploadDataFormat(
      changedRowsData,
      gridCurrentPageData,
      defaultStatus,
      isToggleOn
    );
    let validatedUploadData = [];
    if (modifiedUploadData.length === 0) {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.noChangeUpdate
      };
      this.uploadStatusUpdate(commentsUpdateStatus);
      this.setState({ changedRowsData: [] });
    } else {
      const stat = [];
      const CurrentGridData1 = [];
      paramsForExcel.api.forEachNode(node => CurrentGridData1.push(node.data));
      const AllColNames = [];
      const getAllColumns = paramsForExcel.columnApi.getAllColumns();
      getAllColumns.forEach(item => {
        const itemis = {
          name: item.colDef.headerName,
          code: item.colId,
          field: item.colDef.field
        };
        AllColNames.push(itemis);
      });
      modifiedUploadData.forEach(scItem => {
        const foundIndex = CurrentGridData1.findIndex(i => i.id === scItem.id);
        const foundIndBack = gridCurrentPageData.findIndex(
          i => i.id === scItem.id
        );
        if (userType === 'FACTORY') {
          const { newActRow, result } = validateDataByRowOnlyOnUpload(
            scItem,
            CurrentGridData1[foundIndex],
            gridCurrentPageData[foundIndBack],
            userType,
            reasonCodes,
            reasonCodesAll,
            defaultStatus,
            currentuseremail,
            false,
            maxColumnEditable,
            isToggleOn
          );

          if (result.length > 0) {
            const report = JSON.parse(JSON.stringify(result));
            report.forEach(log => {
              log.row = foundIndex + 1;
              log.fieldname = AllColNames.find(i => i.field === log.field).name;
              log.factoryCode = scItem.factoryCode;
              log.capacityTypeCode = scItem.capacityTypeCode;
              log.weekStartDate = scItem.weekStartDate;
              log.id = scItem.id;
              stat.push(log);
              if (
                !log.status &&
                columnConstants.statusChangingCapCols.includes(log.field)
              ) {
                const EditBy =
                  defaultStatus === 'MaxAvailable'
                    ? 'maxAvailableEditedBy'
                    : 'shortHorizonEditedBy';
                const status =
                  defaultStatus === 'MaxAvailable'
                    ? 'maxAvailableStatus'
                    : 'shortHorizonStatus';
                newActRow[EditBy] = gridCurrentPageData[foundIndBack][EditBy];
                newActRow[status] = gridCurrentPageData[foundIndBack][status];
              }
            });
          }
          paramsForExcel.api.forEachNodeAfterFilterAndSort(n => {
            if (n.data && n.data.id === scItem.id) {
              n.data = newActRow;
              paramsForExcel.api.redrawRows(n.rowIndex);
            }
          });

          if (newActRow.ChangeByUpload > 0) {
            newActRow.colModified = [];
            validatedUploadData.push(newActRow);
          }
        } else {
          validatedUploadData.push(scItem);
        }
      });
      if (stat.length > 0) {
        this.setState({
          UploadStatusData: _.sortBy(stat, 'row'),
          UploadUpdateModel: true,
          changedRowsData: [],
          UploadUpdateModelType: 'Grid'
        });
      }
      //const updateData = maxAvailableCapacity
      if (validatedUploadData.length > 0) {
        const modFormattedUploadData = updateUploadDataFormat(
          validatedUploadData,
          gridCurrentPageData,
          defaultStatus,
          isToggleOn
        );
        onConsoleArr(modFormattedUploadData);
        const AllGridData = {
          dashboard: defaultStatus,
          data: modFormattedUploadData,
          type: 'row Update',
          thresholdCounts
        }; // current grid
        updateDashboardData(AllGridData);
        const AllGridDataCopy = [];
        paramsForExcel.api.forEachNode(node => AllGridDataCopy.push(node.data));
        this.setState({
          changedRowsData: [],
          gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
        });

        localStorage.setItem('isRowDataChanged', 'false');
      } else {
        const uploadStatus = {
          status: false,
          message: msgConstants.noChangeUpdate
        };
        this.setState({
          updateCycleComplete: true
        });
        this.uploadStatusUpdate(uploadStatus);
      }
    }

    this.scoreCardCalculation();
    this.quarterScoreCardCalculation();
  };

  callExternalFilter = statusCode => {
    // based on the status wise count, on click on the numbers, paticular data will be filtered in grid
    const { paramsForExcel, isToggleOn, agGridQuarterParams } = this.state;
    this.setState(
      {
        statusCode
      },
      () => {
        let gridParams = !isToggleOn ? paramsForExcel : agGridQuarterParams;
        if (gridParams && Object.keys(gridParams).length > 0) {
          if (statusCode === '') {
            gridParams.api.setFilterModel(null);
          }
          gridParams.api.onFilterChanged();
        }
      }
    );
  };

  scoreCardCalculation = AllGridData => {
    // function calcualte the status based count in grid and displays the scorecard
    const { dashboardData } = this.props;
    const { paramsForExcel, defaultStatus, scoreCardCount } = this.state;
    if (Object.keys(paramsForExcel).length !== 0) {
      let CurrentGridData = []; // current grid
      if (AllGridData) {
        CurrentGridData = AllGridData;
      } else {
        CurrentGridData = [];
        paramsForExcel.api.forEachNodeAfterFilter(node =>
          CurrentGridData.push(node.data)
        );
      }

      scoreCardCount.forEach(scItem => {
        scItem.count = 0;
      });

      CurrentGridData.forEach(gridItem => {
        scoreCardCount.forEach(scItem => {
          if (scItem.statusCode === '') {
            scItem.count += 1;
          } else if (
            defaultStatus === 'MaxAvailable' &&
            gridItem.maxAvailableStatus === scItem.statusCode
          ) {
            scItem.count += 1;
          } else if (
            defaultStatus === 'shortHorizon' &&
            gridItem.shortHorizonStatus === scItem.statusCode
          ) {
            scItem.count += 1;
          }
        });
      });

      if (dashboardData.pagination && dashboardData.pagination.totalResources) {
        scoreCardCount[4].count = dashboardData.pagination.totalResources; //AllGridData.length;
      }

      CurrentGridData = []; // current grid
    }
    this.setState({ scoreCardCount });
  };

  displayLogEntryDetails = (rowIndex, column) => {
    // method to show the modal to list the comments or
    const { paramsForExcel, agGridQuarterParams, isToggleOn } = this.state;

    const { requestCommentsLogsData } = this.props;

    const rowNode = isToggleOn
      ? agGridQuarterParams.api.getDisplayedRowAtIndex(rowIndex)
      : paramsForExcel.api.getDisplayedRowAtIndex(rowIndex);

    let { id, ids = [] } = rowNode.data;
    if (ids && ids.length === 0) {
      ids.push(id);
    }

    const payload = { ids };
    requestCommentsLogsData(payload);
    this.setState({
      LogCommentValue: column,
      commentTextRowId: rowIndex,
      commentTextRowDataId: ids
    });
    this.toogleLogCommentModel();
  };

  downloadData = () => {
    // Function to download the current grid data to excel sheet
    // to apply custom footer which will have to drop down values for short horizon and status
    const {
      defaultStatus,
      userType,
      paramsForExcel,
      isToggleOn,
      agGridQuarterParams
    } = this.state;
    const { currentusername } = this.props;

    const fileInfo = agGridDownloadFileName(
      defaultStatus,
      userType,
      'bulk',
      currentusername,
      isToggleOn
    );

    this.setState({
      recentDownloadedGridFile: fileInfo.fileName,
      recentDownloadedGridFileId: fileInfo.fileID
    });

    const params = {
      fileName: fileInfo.fileName,
      sheetName: fileInfo.sheetName,
      processCellCallback(paramsl) {
        // data format change for excel
        return agGridDownloadDataFormat(paramsl);
      }
    };
    // for custom header
    const getAllColumns = !isToggleOn
      ? paramsForExcel.columnApi.getAllColumns()
      : agGridQuarterParams.columnApi.getAllColumns();
    let customHeader = JSON.parse(JSON.stringify(pageConstants.excelHeader));
    customHeader[0][1].data.value = AppCipher(fileInfo.fileID);
    params.customHeader = customHeader;

    if (userType === 'FACTORY') {
      params.customFooter = agGridCustomFooter(getAllColumns);
    }
    !isToggleOn
      ? paramsForExcel.api.exportDataAsExcel(params)
      : agGridQuarterParams.api.exportDataAsExcel(params);

    toast.success(msgConstants.excelDownloadSuccess, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  toggleUploadModel = () => {
    const { UploadModel } = this.state;
    if (
      localStorage.getItem('isRowDataChanged') === 'false' &&
      localStorage.getItem('isQuarterRowDataChanged') === 'false'
    ) {
      this.setState({
        changedRowsData: [],
        quarterRowDataChanged: [],
        UploadModel: !UploadModel
      });
    } else {
      const { changedRowsData, quarterRowDataChanged } = this.state;
      if (changedRowsData.length > 0 || quarterRowDataChanged.length > 0) {
        confirmAlert({
          message: msgConstants.pageChangeAlert,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  changedRowsData: [],
                  quarterRowDataChanged: []
                });
                localStorage.setItem('isRowDataChanged', 'false');
                localStorage.setItem('isQuarterRowDataChanged', 'false');
                // toggles the upload dialog
                this.setState({ UploadModel: !UploadModel });
              }
            },
            {
              label: 'No'
            }
          ]
        });
      }
    }
  };

  toogleUploadUpdateModel = () => {
    // toggles the upload dialog
    let { UploadStatusData, validatedUploadPQResult } = this.state;
    const { UploadUpdateModel, paramsForExcel } = this.state;
    if (UploadUpdateModel) {
      const AllGridDataCopy = [];
      paramsForExcel.api.forEachNode(node => AllGridDataCopy.push(node.data));
      this.setState({
        gridCurrentPageData: JSON.parse(JSON.stringify(AllGridDataCopy))
      });
      UploadStatusData = [];
      validatedUploadPQResult = [];
    }
    this.setState({
      UploadUpdateModel: !UploadUpdateModel,
      UploadStatusData,
      validatedUploadPQResult
    });
  };

  toogleLogCommentModel = () => {
    const { LogCommentModel } = this.state;
    const { clearCommentsLogsData } = this.props;
    if (LogCommentModel) {
      this.setState({ currentRowComments: [] });
    }
    this.setState({ LogCommentModel: !LogCommentModel, commentTextArea: '' });
    if (!LogCommentModel) {
      clearCommentsLogsData({});
    }
  };

  uploadStatusUpdate = toastms => {
    if (toastms.status) {
      toast.success(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } else {
      this.setState({
        editGrid: true,
        UploadingData: false,
        UploadModel: false
      });
      toast.error(toastms.message, {
        position: 'top-center',
        autoClose: toastms.autoClose ? toastms.autoClose : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  uploadProcess = data => {
    let jsonDataRaw = data;
    let jsonDataMapped = [];
    let jsonDataCalculated = [];
    const {
      paramsForExcel,
      agGridQuarterParams,
      defaultStatus,
      userType,
      reasonCodes,
      reasonCodesAll,
      gridCurrentPageData,
      maxColumnEditable,
      isToggleOn,
      prodQuartersDataBackUp,
      userFactoryType
    } = this.state;
    const {
      updateDashboardData,
      currentusername,
      currentuseremail
    } = this.props;

    const uploadStatus = {
      status: false,
      message: msgConstants.uploadFailNoData
    };
    const activeGridSet = [];
    (isToggleOn ? agGridQuarterParams : paramsForExcel).api.forEachNode(node =>
      activeGridSet.push(node.data)
    );

    const gridColumns = isToggleOn
      ? agGridQuarterParams.columnApi.getAllColumns()
      : paramsForExcel.columnApi.getAllColumns();

    // validating the raw array and updating the columns structure
    if (jsonDataRaw.length > 0) {
      jsonDataMapped = frameDataOnUpload(
        jsonDataRaw,
        activeGridSet.length,
        gridColumns,
        pageConstants.excelHeader.length
      );
      jsonDataRaw = [];
      // validating the mapped columns and updating only the editable columns
      if (jsonDataMapped.length > 0) {
        const { activeGrid, validationReport } = validateAllDataOnUpload(
          jsonDataMapped,
          activeGridSet,
          reasonCodes,
          reasonCodesAll,
          userType,
          defaultStatus,
          gridColumns,
          currentuseremail,
          maxColumnEditable,
          isToggleOn,
          userFactoryType
        );
        jsonDataMapped = [];
        // assigning the new data to the grid in dashboard
        if (activeGrid.length > 0) {
          jsonDataCalculated = calculationAfterUpload(
            activeGrid,
            userType,
            currentusername,
            defaultStatus,
            isToggleOn
          );

          if (jsonDataCalculated.length > 0) {
            (isToggleOn ? agGridQuarterParams : paramsForExcel).api.setRowData(
              jsonDataCalculated
            );
            // send data only for api
            let dataToUploadApi = [];
            if (isToggleOn) {
              // distribute quarterly aggregated values into corresponding weeks.
              const updatedQuarters = jsonDataCalculated.filter(
                quarter => quarter.ChangeByUpload > 0
              );
              dataToUploadApi = generateProdQuarterWeeks(
                updatedQuarters,
                prodQuartersDataBackUp,
                paramsForExcel,
                true,
                defaultStatus,
                userFactoryType
              );
              const activeWeeklyRows = [];
              paramsForExcel.api.forEachNode(node =>
                activeWeeklyRows.push(node.data)
              );
              const allWeeklyColumns = paramsForExcel.columnApi.getAllColumns();
              const allWeeklyFields = allWeeklyColumns.map(item => {
                return {
                  name: item.colDef.headerName,
                  code: item.colId,
                  field: item.colDef.field
                };
              });

              const weeklyReport = generateWeeklyReport(
                dataToUploadApi,
                activeWeeklyRows,
                gridCurrentPageData,
                userType,
                allWeeklyFields,
                reasonCodes,
                reasonCodesAll,
                defaultStatus,
                currentuseremail,
                true,
                maxColumnEditable,
                false,
                userFactoryType
              );
              if (validationReport.length > 0) {
                this.setState({
                  UploadStatusData: weeklyReport,
                  validatedUploadPQResult: validationReport,
                  UploadUpdateModel: true,
                  UploadUpdateModelType: 'Excel'
                });
              }
            } else {
              dataToUploadApi = jsonDataCalculated.filter(
                quarter => quarter.ChangeByUpload > 0
              );
              if (validationReport.length > 0) {
                this.setState({
                  UploadStatusData: validationReport,
                  UploadUpdateModel: true,
                  UploadUpdateModelType: 'Excel'
                });
              }
            }
            if (dataToUploadApi.length > 0) {
              onConsoleArr(dataToUploadApi);
              const modifiedUploadData = updateUploadDataFormat(
                dataToUploadApi,
                gridCurrentPageData,
                defaultStatus,
                isToggleOn
              );
              const AllGridData = {
                dashboard: defaultStatus,
                data: modifiedUploadData,
                type: 'file Update'
              }; // current grid
              this.scoreCardCalculation();
              updateDashboardData(AllGridData);

              this.setState(
                {
                  thresholdCounts: { passed: 0, all: 0 },
                  modifyTHExceedsArray: []
                },
                () => {
                  this.callExternalFilter('');
                }
              );
            } else {
              this.setState({
                updateCycleComplete: true
              });
              if (validationReport.length) {
                uploadStatus.message = msgConstants.uploadFailNoMatchData;
              }
              this.uploadStatusUpdate(uploadStatus);
            }
          } else {
            this.uploadStatusUpdate(uploadStatus);
          }
        } else {
          this.uploadStatusUpdate(uploadStatus);
        }
      } else {
        uploadStatus.message = msgConstants.uploadFailNoMatchData;
        this.uploadStatusUpdate(uploadStatus);
      }
    } else {
      uploadStatus.message = msgConstants.uploadFailFetchErr;
      this.uploadStatusUpdate(uploadStatus);
    }
    this.setState({
      editGrid: true,
      UploadingData: false,
      UploadModel: false,
      isCommentAddedInQuarterView: true
    });
  };

  saveColState = params => {
    // save the column preference and update API
    this.setState({ editGrid: true });

    // Function to call Data Save API to update changes made in AG grid data, either by editing or by uploading
    const getColumnState = params.columnApi.getColumnState();
    const getColumnDefs = params.columnApi.getAllGridColumns();
    const {
      savePreferenceData,
      history,
      currentusername,
      currentuseremail
    } = this.props;
    const { defaultStatus, agGridQuarterParams } = this.state;
    const pQColsAll = [];
    const pQCols2Show = [];
    const pQCols2Hide = [];
    agGridQuarterParams.columnApi.getAllColumns().forEach(column => {
      if (
        ![
          'productionQuarter',
          'factoryCode',
          'capacityTypeCode',
          'id'
        ].includes(column.colId)
      ) {
        pQColsAll.push(column.colId);
      }
    });
    const updateColumnPref = [];
    getColumnState.forEach((column, key) => {
      const eachCol = {
        id: getColumnDefs[key].colDef.toolPanelClass,
        displayUser: !column.hide,
        field: column.colId
      };
      updateColumnPref.push(eachCol);
      // gridColumnConfig.find(o => o.field === column.colId).hide = column.hide;

      if (pQColsAll.includes(column.colId)) {
        if (column.hide) {
          pQCols2Hide.push(column.colId);
        } else {
          pQCols2Show.push(column.colId);
        }
      }
    });
    agGridQuarterParams.columnApi.setColumnsVisible(pQCols2Show, true);
    agGridQuarterParams.columnApi.setColumnsVisible(pQCols2Hide, false);

    const UpdateData = {
      fields: updateColumnPref,
      user: currentusername,
      email: currentuseremail,
      dashboard: defaultStatus
    };
    this.setState({
      saveViewToogle: 1
    });
    savePreferenceData(UpdateData, history);
  };

  onChildGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  getContextMenuItems(params) {
    var result = ['copy'];
    return result;
  }

  addComment2Grid = () => {
    const { updateDashboardComment, currentuseremail } = this.props;
    const {
      commentTextArea,
      paramsForExcel,
      commentTextRowId,
      defaultStatus,
      totalLength,
      userType,
      isToggleOn,
      agGridQuarterParams
    } = this.state;
    let { currentRowComments, commentSession } = this.state;
    const commentsUpdateStatus = {
      status: true,
      message: msgConstants.cmntAddSuccess
    };
    if (commentTextArea.length > 0) {
      this.setState({ isCommentTextUpdating: true });
      const newcomment = updateNewCommentEntry(
        commentTextArea,
        currentRowComments,
        currentuseremail,
        userType,
        isToggleOn
      );

      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.commentSectionEnd &&
          this.commentSectionEnd.scrollIntoView({ behavior: 'smooth' });
        this.setState({ isCommentTextUpdating: false });
      });
      const rowNode = isToggleOn
        ? agGridQuarterParams.api.getDisplayedRowAtIndex(commentTextRowId)
        : paramsForExcel.api.getDisplayedRowAtIndex(commentTextRowId);

      if (defaultStatus === 'MaxAvailable') {
        let availableNotes = rowNode.data.maxAvailableNotes || [];
        let noteCount = rowNode.data.maxAvailableNoteCount || 0;
        availableNotes = newcomment;
        currentRowComments = availableNotes;
        rowNode.data.maxAvailableNotes = availableNotes;
        rowNode.data.maxAvailableNoteCount = noteCount + 1;
      } else if (defaultStatus === 'shortHorizon') {
        let availableNotes = rowNode.data.shortHorizonNotes || [];
        let noteCount = rowNode.data.shortHorizonNoteCount || 0;
        availableNotes = newcomment;
        rowNode.data.shortHorizonNotes = availableNotes;
        currentRowComments = availableNotes;
        rowNode.data.shortHorizonNoteCount = noteCount + 1;
      }
      const updateSingleRowChange = {
        comment: commentTextArea,
        dashboard: defaultStatus,
        timestamp: newcomment[newcomment.length - 1].timestamp,
        factoryCode: rowNode.data.factoryCode,
        capacityTypeCode: rowNode.data.capacityTypeCode,
        divisionCode: rowNode.data.divisionCode
      };
      if (isToggleOn) {
        agGridQuarterParams.api.redrawRows(commentTextRowId);
        const weeklyTempData = [];
        paramsForExcel.api.forEachNode(node => weeklyTempData.push(node.data));
        const capacityWeeks = [];
        weeklyTempData.forEach(gridItem => {
          if (
            gridItem.productionQuarter === rowNode.data.productionQuarter &&
            gridItem.capacityTypeCode === rowNode.data.capacityTypeCode &&
            gridItem.factoryCode === rowNode.data.factoryCode
          ) {
            const p = {
              factoryWeeklyCapacityId: gridItem.factoryWeeklyCapacityId,
              id: gridItem.id,
              weekStartDate: moment(gridItem.weekStartDate).format('MM/DD/YYYY')
            };
            capacityWeeks.push({ ...p });
            updateSingleRowChange.divisionCode = gridItem.divisionCode;
          }
        });
        updateSingleRowChange.capacityWeeks = capacityWeeks;
        updateSingleRowChange.isQuarterly = true;
      } else {
        const capacityWeeks = [];
        const p = {
          factoryWeeklyCapacityId: rowNode.data.factoryWeeklyCapacityId,
          id: rowNode.data.id,
          weekStartDate: moment(rowNode.data.weekStartDate).format('MM/DD/YYYY')
        };
        capacityWeeks.push({ ...p });
        updateSingleRowChange.divisionCode = rowNode.data.divisionCode;
        updateSingleRowChange.capacityWeeks = capacityWeeks;
        updateSingleRowChange.isQuarterly = false;
      }
      commentSession = updateSingleRowChange;
      this.setState({
        currentRowComments,
        commentTextArea: '',
        totalLength: totalLength + 1,
        commentSession,
        currentRowCommentsUpdating: true
      });
      updateDashboardComment(updateSingleRowChange);
    } else {
      commentsUpdateStatus.status = false;
      commentsUpdateStatus.message = msgConstants.cmntAddInvalid;

      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };

  fileHandler = event => {
    if (event.target.files.length) {
      this.setState({ UploadingData: true });
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.fileFormatError
      };

      const fileObj = event.target.files[0];
      const fileName = fileObj.name;
      const { recentDownloadedGridFile } = this.state;
      // check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
        if (fileObj.size < 1000) {
          // default file size of
          commentsUpdateStatus.message = msgConstants.uploadFailNoContent;
          this.uploadStatusUpdate(commentsUpdateStatus);
        } else {
          // for conflict name valdiation
          if (recentDownloadedGridFile.length === 0) {
            commentsUpdateStatus.message =
              msgConstants.uploadFailInvalidSession;
            this.uploadStatusUpdate(commentsUpdateStatus);
          } else if (fileName.includes(recentDownloadedGridFile)) {
            //ExcelRenderer to start here
            this.excelRendererFn(fileObj, commentsUpdateStatus);
          } else {
            confirmAlert({
              message: msgConstants.uploadWarnNoMatchFileName,
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    this.excelRendererFn(fileObj, commentsUpdateStatus);
                  }
                },
                {
                  label: 'No',
                  onClick: () => {
                    this.setState({ UploadingData: false, UploadModel: false });
                  }
                }
              ]
            });
          }
        }
      } else {
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    } else {
      const commentsUpdateStatus = {
        status: false,
        message: msgConstants.uploadFailNoFile
      };

      this.setState({ UploadingData: false, UploadModel: false });
      this.uploadStatusUpdate(commentsUpdateStatus);
    }
  };

  excelRendererFn(fileObj, commentsUpdateStatus) {
    const { recentDownloadedGridFileId, isToggleOn } = this.state;

    CustomExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        this.uploadStatusUpdate(commentsUpdateStatus);
      } else {
        const fileID = AppDeCipher(resp.rows[0][1]);
        const aggregationAttribute = isToggleOn ? 'QUARTERLY' : 'WEEKLY';
        const uploadedFileAttributes = fileID.split('-');

        const errorCase = { status: true, message: '' };
        if (!recentDownloadedGridFileId.includes(fileID)) {
          errorCase.status = false;
          errorCase.message = msgConstants.uploadFailNoMatchId;
          this.uploadStatusUpdate(errorCase);
        } else if (uploadedFileAttributes[3] !== aggregationAttribute) {
          errorCase.status = false;
          errorCase.message = isToggleOn
            ? msgConstants.uploadFailNotQuarterly
            : msgConstants.uploadFailNotWeekly;
          this.uploadStatusUpdate(errorCase);
        } else {
          this.uploadProcess(resp.rows);
        }
      }
    });

    setTimeout(() => {
      const { UploadModel } = this.state;
      if (UploadModel) {
        this.setState({
          editGrid: true,
          UploadingData: false,
          UploadModel: false
        });
        commentsUpdateStatus.message = msgConstants.noChangeUpdate;
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    }, 45000);
  }

  goToPage(action) {
    this.setState({ gridPagination: action }, () => {
      this.onSearchByGlobalFilters();
    });
  }

  handleCommentTextAreaChange(event) {
    this.setState({ commentTextArea: event.target.value });
  }

  reloadGridContentAfterSave = onSaveResp => {
    const {
      paramsForExcel,
      defaultStatus,
      agGridQuarterParams,
      quarterGridCurrentFilter,
      modifyTHExceedsArray,
      isToggleOn
    } = this.state;
    let logUpdateFailure = false;

    if (
      onSaveResp &&
      onSaveResp.outputData &&
      onSaveResp.outputData.updated &&
      onSaveResp.outputData.updated.length > 0
    ) {
      const updatedArr = onSaveResp.outputData.updated;

      paramsForExcel.api.forEachNode(function(rowNode) {
        const foundIndex = updatedArr.findIndex(i => i.id === rowNode.data.id);

        if (
          foundIndex >= 0 &&
          updatedArr[foundIndex].logs &&
          updatedArr[foundIndex].logs.length > 0
        ) {
          rowNode.data.logs = updatedArr[foundIndex].logs;
          rowNode.data.logCount = updatedArr[foundIndex].logs.length;
        }
        rowNode.data.isStatusBreak = false;
        rowNode.data.isThresholdExceed = false;
        rowNode.data.inThresholdExceedCols = [];
        rowNode.data.inThreshold = null;
        rowNode.data.isColumnSkipped = false;
        rowNode.data.inColumnSkipped = [];
      });
      paramsForExcel.api.redrawRows();

      // updating the updated weekly records the production  quarter grid

      const currentGridData = [];
      paramsForExcel.api.forEachNode(node => currentGridData.push(node.data));
      const prodQuartersData = quarterlyGroupedData(
        currentGridData,
        defaultStatus
      );
      agGridQuarterParams.api.setRowData(prodQuartersData);
      if (Object.keys(quarterGridCurrentFilter).length > 0) {
        agGridQuarterParams.api.setFilterModel(quarterGridCurrentFilter);
      }

      agGridQuarterParams.api.redrawRows();
      const prodQuartersDataBackUp = JSON.parse(
        JSON.stringify(prodQuartersData)
      );
      this.setState({ prodQuartersData, prodQuartersDataBackUp });
    } else {
      logUpdateFailure = true;
    }
    if (logUpdateFailure) {
      setTimeout(() => {
        this.onSearchEnterApi();

        const commentsUpdateStatus = {
          status: true,
          message: 'Resetting filters and Displaying Updated Records'
        };
        this.uploadStatusUpdate(commentsUpdateStatus);
      }, 1000);
    }
    this.scoreCardCalculation();
    this.quarterScoreCardCalculation();

    //
    if (modifyTHExceedsArray && modifyTHExceedsArray.length > 0) {
      let gridParams = isToggleOn ? agGridQuarterParams : paramsForExcel;
      let timeDelay = isToggleOn ? 2000 : 500;
      setTimeout(() => {
        gridParams.api.forEachNode(function(row, i) {
          const ix = modifyTHExceedsArray.findIndex(i => i.id === row.data.id);
          if (ix >= 0) {
            row.data = JSON.parse(JSON.stringify(modifyTHExceedsArray[ix]));
          }
        });
        gridParams.api.redrawRows();
        isToggleOn
          ? this.setState({ quarterRowDataChanged: modifyTHExceedsArray })
          : this.setState({ changedRowsData: modifyTHExceedsArray });
        isToggleOn
          ? localStorage.setItem('isQuarterRowDataChanged', 'true')
          : localStorage.setItem('isRowDataChanged', 'true');

        setTimeout(() => {
          this.setState(
            {
              thresholdCounts: { passed: 0, all: 0 },
              modifyTHExceedsArray: []
            },
            () => {
              this.callExternalFilter('savecheck-threshold');
            }
          );
        }, 100);
      }, timeDelay);
    }
  };

  setQuarterParams = params => {
    if (params) {
      const { defaultSortAfterLoad } = this.state;
      this.setState({ agGridQuarterParams: params });
      try {
        params.api.setSortModel(defaultSortAfterLoad);
        params.api.setSuppressClipboardPaste(false);
        params.api.copySelectedRowsToClipboard(false);
        params.api.closeToolPanel();
      } catch (e) {
        // this will run only if the code in the try block errors-out
      }
    }
  };

  onToggleClick = () => {
    const {
      changedRowsData,
      quarterRowDataChanged,
      isToggleOn,
      paramsForExcel,
      agGridQuarterParams,
      gridCurrentPageData,
      prodQuartersDataBackUp,
      isCommentAddedInQuarterView
    } = this.state;

    if (changedRowsData.length > 0 || quarterRowDataChanged.length > 0) {
      confirmAlert({
        message: msgConstants.pageChangeAlert,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              //
              if (isToggleOn) {
                quarterRowDataChanged.forEach(item => {
                  agGridQuarterParams.api.forEachNode(function(row, i) {
                    if (item.id === row.data.id) {
                      const oldPDData = prodQuartersDataBackUp.find(
                        o => o.uniQId === row.data.uniQId
                      );
                      row.data = JSON.parse(JSON.stringify(oldPDData));
                    }
                  });
                  agGridQuarterParams.api.redrawRows();
                });
              } else {
                changedRowsData.forEach(item => {
                  paramsForExcel.api.forEachNode(function(row, i) {
                    if (item.id === row.data.id) {
                      const oldWKData = gridCurrentPageData.find(
                        o => o.id === row.data.id
                      );
                      row.data = JSON.parse(JSON.stringify(oldWKData));
                    }
                  });
                });
                paramsForExcel.api.redrawRows();
              }

              this.setState(
                {
                  changedRowsData: [],
                  quarterRowDataChanged: [],
                  isGridInReview: false
                },
                () => {
                  this.callExternalFilter('');
                }
              );
              localStorage.setItem('isRowDataChanged', 'false');
              localStorage.setItem('isQuarterRowDataChanged', 'false');
              this.setState(state => ({
                isToggleOn: !state.isToggleOn
              }));
            }
          },
          {
            label: 'No'
          }
        ]
      });
    } else {
      this.setState(
        {
          isGridInReview: false
        },
        () => {
          this.callExternalFilter('');
        }
      );
      this.setState(
        state => ({
          isToggleOn: !state.isToggleOn
        }),
        function() {
          if (isToggleOn) {
            if (isCommentAddedInQuarterView) {
              paramsForExcel.api.redrawRows();
              this.setState({
                isCommentAddedInQuarterView: false
              });
            }
          } else {
            if (isCommentAddedInQuarterView) {
              agGridQuarterParams.api.redrawRows();
              this.setState({
                isCommentAddedInQuarterView: false
              });
            }
          }
        }
      );
    }
    this.quarterScoreCardCalculation();
  };

  onQuarterCellValueChanged = event => {
    const {
      quarterRowDataChanged,
      agGridQuarterParams,
      userType,
      defaultStatus,
      prodQuartersDataBackUp,
      reasonCodes,
      isToggleOn,
      thresholds
    } = this.state;
    const { currentusername } = this.props;
    let saveDisabled = false;
    let rowNode = agGridQuarterParams.api.getDisplayedRowAtIndex(
      event.rowIndex
    );

    const oldRowValue = prodQuartersDataBackUp.find(
      o => o.uniQId === rowNode.data.uniQId
    );

    const editedColumn = event.colDef.field;

    const commentsUpdateStatus = {
      status: false,
      message: msgConstants.inValFormatData
    };
    if (event.oldValue !== event.newValue) {
      const changeAccepted = onCellValueChangFormatValidate(
        event.colDef.filter,
        event.newValue,
        editedColumn,
        reasonCodes,
        isToggleOn
      );

      const toSendRow = JSON.parse(JSON.stringify(rowNode.data));
      if (changeAccepted === false || changeAccepted === 'false') {
        toSendRow[event.colDef.field] = oldRowValue[event.colDef.field];
      }
      rowNode.data = onCellValueChangeUpdateFields(
        toSendRow,
        changeAccepted,
        event.colDef.field,
        event.newValue,
        event.oldValue,
        defaultStatus,
        userType,
        currentusername,
        thresholds
      );

      // only for prod quarter
      const statusField =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableStatus'
          : 'shortHorizonStatus';
      const rCodeField =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableReasonCode'
          : 'shortHorizonReasonCode';
      const rCapField =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableCapacity'
          : 'shortHorizonCapacity';
      const ChangeBy =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableEditedBy'
          : 'shortHorizonEditedBy';
      const ConfirmedChangeBy =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableConfirmedBy'
          : 'shortHorizonConfirmedBy';
      const ApprovedChangeBy =
        defaultStatus === 'MaxAvailable'
          ? 'maxAvailableApprovedBy'
          : 'shortHorizonApprovedBy';
      if (changeAccepted) {
        // in prod q, on changing the reason code change status
        if (
          editedColumn === rCodeField &&
          oldRowValue[rCodeField] !== event.newValue &&
          rowNode.data[rCapField] >= 0
        ) {
          rowNode.data[statusField] = 'In Review';
          rowNode.data[ChangeBy] = userType;
        }
        if (
          editedColumn === rCodeField &&
          oldRowValue[rCodeField] === event.newValue &&
          rowNode.data[rCapField] === oldRowValue[rCapField]
        ) {
          rowNode.data[statusField] = oldRowValue[statusField];
          rowNode.data[ChangeBy] = oldRowValue[ChangeBy];
        }
      }
      //do nothing
      if (!changeAccepted) {
        if (columnConstants.statusChangingCapCols.includes(editedColumn)) {
          rowNode.data[statusField] = oldRowValue[statusField];
          if (
            editedColumn === 'maxAvailableCapacity' ||
            editedColumn === 'shortHorizonCapacity'
          ) {
            rowNode.data[ChangeBy] = oldRowValue[ChangeBy];
          } else if (
            editedColumn === 'maxAvailableUnitsConfirmed' ||
            editedColumn === 'shortHorizonUnitsConfirmed'
          ) {
            rowNode.data[ConfirmedChangeBy] = oldRowValue[ConfirmedChangeBy];
          } else if (
            editedColumn === 'approvedMaxCapacity' ||
            editedColumn === 'approvedRevisedCapacity'
          ) {
            rowNode.data[ApprovedChangeBy] = oldRowValue[ApprovedChangeBy];
          }
        }
        if (oldRowValue[editedColumn] && !event.newValue) {
          saveDisabled = true;
          commentsUpdateStatus.message = msgConstants.repEmptyCapVal;
        }
        this.uploadStatusUpdate(commentsUpdateStatus);
      }
    }
    agGridQuarterParams.api.redrawRows(event.rowIndex);
    const index = quarterRowDataChanged.findIndex(
      i => i.uniQId === rowNode.data.uniQId
    );
    const sameOld = isEquivalent(rowNode.data, oldRowValue);
    if (sameOld) {
      quarterRowDataChanged.splice(index, 1);
    } else {
      const newRowData = JSON.parse(JSON.stringify(rowNode.data));
      if (index >= 0) {
        quarterRowDataChanged[index] = newRowData;
      } else {
        quarterRowDataChanged.push(newRowData);
      }
    }
    if (quarterRowDataChanged.length > 0) {
      localStorage.setItem('isQuarterRowDataChanged', 'true');
    } else {
      localStorage.setItem('isQuarterRowDataChanged', 'false');
    }

    this.setState({ quarterRowDataChanged, saveDisabled });
    this.quarterScoreCardCalculation();
  };

  quarterScoreCardCalculation = () => {
    const {
      agGridQuarterParams,
      defaultStatus,
      quarterScoreCardCount
    } = this.state;
    if (Object.keys(agGridQuarterParams).length !== 0) {
      let CurrentGridData = []; // current grid
      let AllGridData = []; // current grid
      agGridQuarterParams.api.forEachNode(node => AllGridData.push(node.data));
      agGridQuarterParams.api.forEachNodeAfterFilter(node =>
        CurrentGridData.push(node.data)
      );
      quarterScoreCardCount.forEach(scItem => {
        scItem.count = 0;
      });
      CurrentGridData.forEach(gridItem => {
        quarterScoreCardCount.forEach(scItem => {
          if (scItem.statusCode === '') {
            scItem.count += 1;
          } else if (
            defaultStatus === 'MaxAvailable' &&
            gridItem.maxAvailableStatus === scItem.statusCode
          ) {
            scItem.count += 1;
          } else if (
            defaultStatus === 'shortHorizon' &&
            gridItem.shortHorizonStatus === scItem.statusCode
          ) {
            scItem.count += 1;
          }
        });
      });
      quarterScoreCardCount[4].count = AllGridData.length;
    }

    this.setState({ quarterScoreCardCount });
  };

  postCommentUpdate = status => {
    const {
      paramsForExcel,
      defaultStatus,
      agGridQuarterParams,
      currentRowComments,
      commentTextRowDataId,
      commentTextRowId,
      currentRowCommentsUpdating,
      isToggleOn,
      quarterRowDataChanged
    } = this.state;

    const comCount =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableNotesCount'
        : 'shortHorizonNotesCount';
    const quarterCount =
      defaultStatus === 'MaxAvailable'
        ? 'maxAvailableNotesQuarterCount'
        : 'shortHorizonNotesQuarterCount';
    if (currentRowCommentsUpdating && status) {
      if (isToggleOn) {
        const qrn = agGridQuarterParams.api.getDisplayedRowAtIndex(
          commentTextRowId
        );
        qrn.data[quarterCount] = qrn.data[quarterCount]
          ? qrn.data[quarterCount] + 1
          : 1;
        agGridQuarterParams.api.redrawRows(commentTextRowId);
        if (quarterRowDataChanged.length > 0) {
          let changedIndex = quarterRowDataChanged.findIndex(
            obj => obj.id === qrn.data['id']
          );
          if (changedIndex >= 0) {
            quarterRowDataChanged[changedIndex][quarterCount] =
              qrn.data[quarterCount];
          }
        }
        const ar = [];
        paramsForExcel.api.forEachNode(p => {
          if (commentTextRowDataId.includes(p.data.id)) {
            p.data[comCount] = p.data[comCount] ? p.data[comCount] + 1 : 1;
            p.data[quarterCount] = p.data[quarterCount]
              ? p.data[quarterCount] + 1
              : 1;
            ar.push(p.rowIndex);
          }
        });
        paramsForExcel.api.redrawRows(ar);
      } else {
        const wrn = paramsForExcel.api.getDisplayedRowAtIndex(commentTextRowId);
        wrn.data[comCount] = wrn.data[comCount] ? wrn.data[comCount] + 1 : 1;
        paramsForExcel.api.redrawRows(commentTextRowId);
      }
      this.setState({
        quarterRowDataChanged,
        currentRowComments: currentRowComments,
        totalLength: currentRowComments.length,
        shortHorizonNoteCount: currentRowComments.length,
        maxAvailableNoteCount: currentRowComments.length,
        currentRowCommentsUpdating: false,
        isCommentAddedInQuarterView: true
      });
    }
  };

  handleClick(event) {
    const { defaultStatus } = this.state;

    const { location } = this.props;
    let capacityLink = location && location.search ? location.search : '';
    if (
      localStorage.getItem('isRowDataChanged') === 'false' &&
      localStorage.getItem('isQuarterRowDataChanged') === 'false'
    ) {
      this.setState({
        changedRowsData: [],
        quarterRowDataChanged: []
      });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.history.push(
        `/upload/${
          defaultStatus === 'MaxAvailable'
            ? 'strategicCapacity'
            : 'revisedCapacity'
        }${capacityLink}`
      );
    } else {
      const { changedRowsData, quarterRowDataChanged } = this.state;
      if (changedRowsData.length > 0 || quarterRowDataChanged.length > 0) {
        confirmAlert({
          message: msgConstants.pageChangeAlert,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  changedRowsData: [],
                  quarterRowDataChanged: []
                });
                localStorage.setItem('isRowDataChanged', 'false');
                localStorage.setItem('isQuarterRowDataChanged', 'false');
                // eslint-disable-next-line react/destructuring-assignment
                this.props.history.push(
                  `/upload/${
                    defaultStatus === 'MaxAvailable'
                      ? 'strategicCapacity'
                      : 'revisedCapacity'
                  }`
                );
              }
            },
            {
              label: 'No'
            }
          ]
        });
        event.preventDefault();
      }
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      dashboardDataLoading,
      dashboardData,
      dashboardError,
      dashboardGridLoading,
      dashboardGridColumns,
      dashboardGridError,
      userProfileData,
      userProfileError,
      usrFactGrpSearchData,
      usrFactGrpSearchLoading,
      userNotifyData,
      commentsLogsData,
      commentsLogsDataLoading,
      appConfigData,
      location
    } = this.props; // receive from API response
    const {
      paramsForExcel,
      defaultStatus,
      scoreCardCount,
      loadGrid,
      currentRowComments,
      currentRowLogs,
      pageSearch,
      defaultColDef,
      editGrid,
      overlayLoadingTemplate,
      sideBar,
      frameworkComponents,
      excelStyles,
      context,
      statusCode,
      getRowHeight,
      UploadModel,
      UploadUpdateModel,
      UploadStatusData,
      UploadUpdateModelType,
      UploadingData,
      LogCommentModel,
      totalLength,
      LogCommentValue,
      commentTextArea,
      isCommentTextUpdating,
      changedRowsData,
      saveViewToogle,
      updateCycleComplete,
      updateCycleReport,
      recentDownloadedGridFile,
      prodQuartersData,
      prodQuarterColumns,
      isToggleOn,
      quarterRowDataChanged,
      validatedUploadPQResult,
      quarterScoreCardCount,
      userNotify,
      isGridInReview,
      modifyTHExceedsArray,
      gridCurrentRawData,
      saveDisabled
    } = this.state;
    let summaryScoreCount = !isToggleOn
      ? scoreCardCount
      : quarterScoreCardCount;

    const { featureConfig } = appConfigData || {};
    let gridHeight =
      window.innerHeight - 335 < 300 ? 300 : window.innerHeight - 335;

    let capacityLink = location && location.search ? location.search : '';

    return (
      <div className="page-align-items">
        <Beforeunload
          onBeforeunload={event => {
            if (changedRowsData.length === 0) {
              return true;
            }
            event.preventDefault();
          }}
        >
          <div className="dashboard">
            <div className={`ncss-container u-clearfix`}>
              {!userProfileError &&
                userProfileData &&
                userProfileData.factoryGroups && (
                  <div className={`ncss-row page-header pb0-sm pb2-md`}>
                    <div className="ncss-col-lg-6  ncss-col-md-5 ncss-col-sm-12 va-sm-m ">
                      <h3 className="pt3-sm main-heading  c-f-500">
                        {defaultStatus === 'MaxAvailable'
                          ? 'Strategic '
                          : 'Revised '}
                        Capacity View
                      </h3>
                    </div>
                    <div className="ncss-col-lg-6 ncss-col-md-7 ncss-col-sm-12 va-sm-m ta-md-r pl0-sm pr3-sm d-md-ib pb1-sm  pb1-md ">
                      <div className="ncss-row">
                        <div
                          className={`ncss-col-sm-12  pl0-sm pr0-sm ${
                            CapChangeNotificationShow ? 'pt2-sm' : ''
                          }`}
                        >
                          <NavLink
                            to={`/upload/${
                              defaultStatus === 'MaxAvailable'
                                ? 'strategicCapacity'
                                : 'revisedCapacity'
                            }${capacityLink}`}
                            onClick={this.handleClick.bind(this)}
                            className="fl-sm-r ncss-btn dashboard-button ncss-btn-accent"
                          >
                            <span className="d-sm-h d-md-ib">Bulk Edits </span>
                          </NavLink>
                          {dashboardData &&
                            gridCurrentRawData &&
                            gridCurrentRawData.length !== 0 && (
                              <div className="fl-sm-r ">
                                <Button
                                  disabled={
                                    saveDisabled ||
                                    (changedRowsData.length === 0 &&
                                      quarterRowDataChanged.length === 0)
                                  }
                                  onClick={() => this.onSaveClick()}
                                  extraClassName={`ncss-btn-black u-uppercase  dashboard-button`}
                                >
                                  <span className="d-sm-h d-md-ib"> Save </span>
                                </Button>

                                <Button
                                  onClick={() => this.toggleUploadModel()}
                                  extraClassName={`ncss-btn-black u-uppercase  dashboard-button`}
                                >
                                  <span className="d-sm-h d-md-ib">Upload</span>
                                </Button>
                                <Button
                                  onClick={() => this.downloadData()}
                                  extraClassName={`ncss-btn-black u-uppercase  dashboard-button`}
                                >
                                  <span className="d-sm-h d-md-ib">
                                    Download
                                  </span>
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.saveColState(paramsForExcel)
                                  }
                                  disabled={saveViewToogle <= 1}
                                  extraClassName={`ncss-btn-black u-uppercase  dashboard-button`}
                                >
                                  <span className="d-sm-h d-md-ib">
                                    Save view
                                  </span>
                                </Button>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              <div className="ncss-row page-menu-header">
                {!userProfileError &&
                  userProfileData &&
                  userProfileData.factoryGroups &&
                  userProfileData.factoryGroups.length > 0 &&
                  searchfirst && (
                    <TabHeader
                      defaultStatus={defaultStatus}
                      userNotifyData={userNotifyData}
                      active="dashboard"
                      featureConfig={featureConfig}
                    />
                  )}

                {dashboardData &&
                gridCurrentRawData &&
                gridCurrentRawData.length > 0 &&
                dashboardGridColumns &&
                dashboardGridColumns.columns ? (
                  <div className="ncss-col-sm-12 ncss-col-md-8 ta-sm-r fl-sm-r pb2-sm">
                    <div className="d-sm-flx flx-jc-sm-fe">
                      {summaryScoreCount &&
                        summaryScoreCount.map(
                          ({ name, icon, id, count, statusCode }) => (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                            <div
                              className="d-sm-ib ta-sm-l"
                              key={id}
                              ref={
                                statusCode === ''
                                  ? this.scoreCardEventRef
                                  : statusCode
                              }
                              onClick={() => {
                                if (count > 0) {
                                  this.callExternalFilter(statusCode);
                                }
                              }}
                            >
                              <ScoreCard
                                title={name}
                                icon={icon}
                                count={count}
                                loadGrid={loadGrid}
                              />
                            </div>
                          )
                        )}
                      <div className="d-sm-ib ta-sm-l">
                        {!userNotify && (
                          <div className="flex-child menuOpt2Item  box  ">
                            <div className="d-sm-b">
                              <span className="d-sm-h d-md-ib summary-toggle">
                                {!isToggleOn ? 'Week View' : 'Quarter View'}
                              </span>
                              <div className="day-night-wrapper">
                                <div className="toggle-wrapp">
                                  <span
                                    className={`toggle ${
                                      isToggleOn ? 'active' : ''
                                    }`}
                                    onClick={e => this.onToggleClick()}
                                  >
                                    <i
                                      className={
                                        isToggleOn
                                          ? 'fas fa-calendar-alt'
                                          : 'fas fa-calendar-week'
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
              {/* Show Search bar on conditions */}
              <div className="capvis-page-container c-f-all u-clearfix">
                {userProfileError ||
                  (userProfileData && !userProfileData.factoryGroups && (
                    <NotFound status="401" />
                  ))}

                {!userProfileError &&
                  userProfileData &&
                  userProfileData.factoryGroups &&
                  userProfileData.factoryGroups.length > 0 &&
                  searchfirst && (
                    <div className="  c-f-all u-clearfix">
                      {/* Show Page Loading Error on failure of API */}
                      {CapChangeNotificationShow &&
                        !dashboardError &&
                        !dashboardGridError &&
                        (!gridCurrentRawData ||
                          gridCurrentRawData.length === 0) && (
                          <div className="pt2-sm ncss-row text-normal">
                            <div
                              className="c-f u-full-width  u-clearfix flex-parent d-sm-flx-new  flx-jc-sm-c"
                              style={{ height: window.innerHeight - 335 }}
                            >
                              <div className="flex-item p4-sm ta-sm-c flx-as-sm-c fs-18px text-color-grey1">
                                {noSearchInitiated && !userNotify && (
                                  <div className="d-sm-flx-new flx-as-sm-c ">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M14.92 12.8L20 17.9L17.9 20L12.82 14.92C11.4067 15.88 9.86001 16.36 8.18 16.36C5.92666 16.36 4.00001 15.56 2.4 13.96C0.799992 12.36 0 10.4333 0 8.18C0 5.92666 0.799992 4.00001 2.4 2.4C4.00001 0.799992 5.92666 0 8.18 0C10.4333 0 12.36 0.799992 13.96 2.4C15.56 4.00001 16.36 5.92666 16.36 8.18C16.36 9.87334 15.88 11.4133 14.92 12.8ZM13.62 8.18C13.62 6.67333 13.0933 5.38667 12.04 4.32C10.9867 3.25333 9.70001 2.72 8.18 2.72C6.65999 2.72 5.37334 3.25333 4.32 4.32C3.26666 5.38667 2.73333 6.67333 2.72 8.18C2.70667 9.68667 3.23999 10.9667 4.32 12.02C5.40001 13.0733 6.68666 13.6067 8.18 13.62C9.67334 13.6333 10.96 13.1 12.04 12.02C13.12 10.94 13.6467 9.66001 13.62 8.18Z"
                                        fill="#757575"
                                      />
                                    </svg>
                                    &nbsp; Search for a Factory Group or Code to
                                    begin
                                  </div>
                                )}
                                {userNotify && (
                                  <div className="flx-as-sm-c">
                                    <strong> No data Available </strong>
                                  </div>
                                )}
                                {!noSearchInitiated && !dashboardDataLoading && (
                                  <div className="flx-as-sm-c">
                                    <strong> No data Available </strong>
                                    <br />
                                    The current account might not be mapped with
                                    any factories or roles
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                {/* Show Preloader on Initial Page Loading and swapping between the user type or trying for new search */}
                {(dashboardDataLoading ||
                  dashboardGridLoading ||
                  usrFactGrpSearchLoading) && (
                  <div>
                    <Spinner large />
                  </div>
                )}
                {modifyTHExceedsArray && modifyTHExceedsArray.length > 0 && (
                  <div className="partialSaveSpinner">
                    <Spinner large />
                  </div>
                )}
                {/* Show Page Loading Error on failure of API */}
                {(!noSearchInitiated || userNotify) &&
                  (dashboardError || dashboardGridError) &&
                  (!dashboardDataLoading || !dashboardGridLoading) && (
                    <PageError response={dashboardError} />
                  )}
                {/* Show Counts and Grid */}
                <div className="vertical-align-baseline bg-white c-f-border-5">
                  <div className="container p0-top-sm m0-sm">
                    {dashboardData &&
                      !dashboardDataLoading &&
                      dashboardGridColumns &&
                      !dashboardGridLoading && (
                        <div
                          className={`u-clearfix" 
                      ${isGridInReview ? 'inReview' : ' '}`}
                        >
                          <div>
                            {gridCurrentRawData &&
                            gridCurrentRawData.length > 0 &&
                            dashboardGridColumns.columns ? (
                              <div>
                                <div
                                  className={`u-clearfix ${
                                    isToggleOn ? 'd-sm-h' : 'd-sm-b'
                                  } r-${gridCurrentRawData.length}  c-${
                                    dashboardGridColumns.columns.length
                                  }`}
                                >
                                  <div
                                    id="myGrid"
                                    className="ag-theme-balham capacitySearchWeekGrid"
                                  >
                                    <Grid
                                      columnDefs={dashboardGridColumns.columns}
                                      defaultColDef={defaultColDef}
                                      rowData={gridCurrentRawData}
                                      setParams={this.setParams}
                                      edit={editGrid}
                                      floatingFilter
                                      overlayLoadingTemplate={
                                        overlayLoadingTemplate
                                      }
                                      sideBar={sideBar}
                                      showFilter
                                      frameworkComponents={frameworkComponents}
                                      onCellValueChanged={
                                        this.onCellValueChanged
                                      }
                                      onDisplayedColumnsChanged={
                                        this.onDisplayedColumnsChanged
                                      }
                                      excelStyles={excelStyles}
                                      context={context}
                                      statusCode={statusCode}
                                      defaultStatus={defaultStatus}
                                      getRowHeight={getRowHeight}
                                      headerHeight={50}
                                      dbGridFunctions
                                      internalFilterChangedCallBack={() => {
                                        this.scoreCardCalculation();
                                      }}
                                      getContextMenuItems={
                                        this.getContextMenuItems
                                      }
                                      enableRangeSelection={true}
                                      gridParent="dashboard"
                                      setheight={gridHeight}
                                    />

                                    {/* Pagination       */}
                                    {!userNotify &&
                                      gridCurrentRawData &&
                                      dashboardData.pagination &&
                                      dashboardGridColumns.columns && (
                                        <GridPagination
                                          pagination={dashboardData.pagination}
                                          dataLength={gridCurrentRawData.length}
                                          goToPage={this.goToPage}
                                          view="grid"
                                        />
                                      )}
                                  </div>
                                </div>
                                <div
                                  className={` ${
                                    isToggleOn ? 'd-sm-b' : 'd-sm-h'
                                  } r-${gridCurrentRawData.length}  c-${
                                    dashboardGridColumns.columns.length
                                  }`}
                                >
                                  {prodQuartersData &&
                                    prodQuartersData.length > 0 && (
                                      <div
                                        id="myQuarterGrid"
                                        className="ag-theme-balham capacitySearchQuarterGrid"
                                      >
                                        <Grid
                                          columnDefs={prodQuarterColumns}
                                          defaultColDef={defaultColDef}
                                          rowData={prodQuartersData}
                                          floatingFilter
                                          showFilter
                                          frameworkComponents={
                                            frameworkComponents
                                          }
                                          excelStyles={excelStyles}
                                          context={context}
                                          statusCode={statusCode}
                                          defaultStatus={defaultStatus}
                                          getRowHeight={getRowHeight}
                                          headerHeight={50}
                                          dbGridFunctions
                                          internalFilterChangedCallBack={() => {
                                            this.quarterScoreCardCalculation();
                                          }}
                                          getContextMenuItems={
                                            this.getContextMenuItems
                                          }
                                          setParams={this.setQuarterParams}
                                          onCellValueChanged={
                                            this.onQuarterCellValueChanged
                                          }
                                          enableRangeSelection={true}
                                          setheight={gridHeight}
                                        />
                                      </div>
                                    )}
                                </div>
                                <div className="u-clearfix" />
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>
                        </div>
                      )}

                    {usrFactGrpSearchData &&
                      usrFactGrpSearchData.length === 0 &&
                      pageSearch === 1 && (
                        <div className="bg-white border-light-grey p3-sm ta-sm-l">
                          <H1>
                            No Factory Codes under selected Factory Group is
                            Associated for You !
                          </H1>
                        </div>
                      )}
                  </div>
                </div>
                <UploadModal
                  UploadModel={UploadModel}
                  UploadingData={UploadingData}
                  fileHandler={this.fileHandler}
                  toggleUploadModel={this.toggleUploadModel}
                  recentDownloadedGridFile={recentDownloadedGridFile}
                />
                <UploadUpdateModal
                  UploadUpdateModel={UploadUpdateModel}
                  UploadStatusData={UploadStatusData}
                  validatedUploadPQResult={validatedUploadPQResult}
                  toogleUploadUpdateModel={this.toogleUploadUpdateModel}
                  UploadUpdateModelType={UploadUpdateModelType}
                  updateCycleComplete={updateCycleComplete}
                  updateCycleReport={updateCycleReport}
                  isQuarterlyUpdate={isToggleOn}
                />
                <CommentLogModal
                  LogCommentModel={LogCommentModel}
                  totalLength={totalLength}
                  LogCommentValue={LogCommentValue}
                  toogleLogCommentModel={this.toogleLogCommentModel}
                  currentRowLogs={currentRowLogs}
                  commentTextArea={commentTextArea}
                  isCommentTextUpdating={isCommentTextUpdating}
                  handleCommentTextAreaChange={this.handleCommentTextAreaChange}
                  logHeaderGridDefault={pageConstants.logHeaderGridDefault}
                  logHeader={pageConstants.pqLogHeader}
                  currentRowComments={currentRowComments}
                  addComment2Grid={this.addComment2Grid}
                  frameworkComponents={frameworkComponents}
                  commentsLogsData={commentsLogsData}
                  commentsLogsDataLoading={commentsLogsDataLoading}
                />
              </div>
            </div>
          </div>
        </Beforeunload>
      </div>
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.object,
  userProfileData: PropTypes.any,
  userProfileError: PropTypes.any,
  requestUserProfileData: PropTypes.func,
  clearUserProfileData: PropTypes.func,
  dashboardDataLoading: PropTypes.bool,
  dashboardData: PropTypes.object,
  dashboardError: PropTypes.any,
  dashboardGridLoading: PropTypes.bool,
  dashboardGridColumns: PropTypes.object,
  dashboardGridError: PropTypes.any,
  requestDashboardData: PropTypes.func,
  clearDashboardData: PropTypes.func,
  clearCommentsLogsData: PropTypes.func,
  requestDashboardGridColumns: PropTypes.func,
  updateDashboardGridColumns: PropTypes.func,
  updateDashboardData: PropTypes.func,
  clearUpdateDashboardData: PropTypes.func,
  updateDashboardComment: PropTypes.func,
  savePreferenceData: PropTypes.func,
  location: PropTypes.object,
  currentuserfullname: PropTypes.string,
  currentusername: PropTypes.string,
  currentuseremail: PropTypes.string,
  currentusergroups: PropTypes.array,
  getUsrFactGrpSearchData: PropTypes.object,
  dashboardDataUpdate: PropTypes.any,
  appConfigData: PropTypes.object,
  appConfigLoading: PropTypes.any,
  appConfigError: PropTypes.object,
  commentUpdateStatus: PropTypes.any,
  commentsLogsData: PropTypes.any
};

const mapStateToProps = state => ({
  dashboardDataLoading: getDashboardDataLoading(state),
  dashboardData: getDashboardData(state),
  userProfileData: getUserProfileData(state),
  userProfileError: getUserProfileError(state),
  dashboardError: getDashboardError(state),
  dashboardGridLoading: getDashboardGridLoading(state),
  dashboardGridColumns: getDashboardGridColumns(state),
  dashboardGridError: getDashboardGridError(state),
  name: selectFullName(state),
  currentuserfullname: selectFullName(state),
  currentuseremail: selectEmail(state),
  currentusername: selectUsername(state),
  currentusergroups: selectGroups(state),
  usrFactGrpSearchData: getusrFactGrpSearchData(state),
  usrFactGrpSearchLoading: getusrFactGrpSearchLoading(state),
  usrFactGrpSearchError: getusrFactGrpSearchError(state),
  dashboardDataUpdate: getDashboardDataUpdate(state),
  appConfigData: getAppConfigData(state),
  appConfigLoading: getAppConfigLoading(state),
  appConfigError: getAppConfigError(state),
  commentUpdateStatus: getUpdateDashboardCommentStatus(state),
  commentsLogsData: getCommentsLogsData(state),
  commentsLogsDataLoading: getCommentsLogsDataLoading(state),
  commentsLogsDataError: getCommentsLogsDataError(state),
  userNotifyData: getUserNotifyData(state)
});

const mapDispatchToProps = dispatch => ({
  requestDashboardData: status => {
    // Action for Making the status button as selected (Action required, In progress or completed)
    dispatch(selectStatusButton(status));
    // Get data to show on grid
    dispatch(createServiceAction('dashboardData', 'requested')(status));
  },

  getusrFactGrpSearchData: query => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'requested')(query));
  },
  clearUsrFactGrpSearchData: () => {
    //search user assoicated factory codes based on group input
    dispatch(createServiceAction('usrFactGrpSearchData', 'clear')(null));
  },
  clearDashboardData: status => {
    // Get data to show on graph and count
    dispatch(createServiceAction('dashboardData', 'clear')(status));
    dispatch(createServiceAction('dashboardError', 'clear')({}));
  },
  clearDashboardGridColumns: columndata => {
    // Get data to show on graph and count
    dispatch(createServiceAction('dashboardGridColumns', 'clear')(columndata));
    dispatch(createServiceAction('dashboardGridError', 'clear')({}));
  },
  updateDashboardGridColumns: columndata => {
    dispatch(createServiceAction('dashboardGridColumns', 'update')(columndata));
  },
  requestDashboardGridColumns: requestHeader => {
    // Get data to show on graph and count
    dispatch(
      createServiceAction('dashboardGridColumns', 'requested')(requestHeader)
    );
  },
  savePreferenceData: (data, history) => {
    dispatch(
      createServiceAction(
        'savePreferenceData',
        'submited'
      )({
        ...data,
        history
      })
    );
  },
  updateDashboardComment: (data, history) => {
    dispatch(
      createServiceAction(
        'updateDashboardComment',
        'submited'
      )({
        ...data,
        history
      })
    );
  },
  updateDashboardData: data => {
    dispatch(
      createServiceAction('dashboardDataUpdate', 'submited')({ ...data })
    );
  },
  clearUpdateDashboardData: data => {
    dispatch(createServiceAction('dashboardDataUpdate', 'clear')({}));
  },
  requestCommentsLogsData: data => {
    dispatch(createServiceAction('commentsLogsData', 'requested')(data));
  },
  clearCommentsLogsData: data => {
    dispatch(createServiceAction('commentsLogsData', 'clear')(data));
    dispatch(createServiceAction('commentsLogsDataError', 'clear')({}));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
