import { createAction } from '../util/actionHelper.js';

export const notifyOffline = createAction('OFFLINE_MODE_CHANGE', offline => ({
  offline
}));

export const watchOnlineState = store => {
  const notify = () => store.dispatch(notifyOffline(!navigator.onLine));

  // Initialize the state
  notify();

  // There is a problem with registering these events
  // If you try attach them before 'load' fires they just no-op (Edge/Firefox)
  // If 'load' has already fired it will not fire again
  // To ensure they work in all cases you must do both (yay browser inconsistencies!)
  // To ensure you are not double notified 'load' must also clean up the original listeners
  window.addEventListener('online', notify);
  window.addEventListener('offline', notify);

  window.addEventListener('load', () => {
    window.removeEventListener('online', notify);
    window.removeEventListener('offline', notify);
    window.addEventListener('online', notify);
    window.addEventListener('offline', notify);
  });
};
