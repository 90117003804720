import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { divisionCodes } from '../../constants/appConstants';

export default class CellLinkRenderer extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod(entryCol) {
    const { context, node } = this.props;
    context &&
      context.componentParent.displayLogEntryDetails(node.rowIndex, entryCol);
  }
  onProductionQuarterClick() {
    const { context, data } = this.props;
    const { dashboard, linkDashboard } = data;
    const productionQuarterLink = this.ChangeNotificationToDashboardQuery(
      linkDashboard ? linkDashboard : dashboard
    );
    context &&
      context.componentParent.onProductionQuarterLinkClick(
        data,
        productionQuarterLink
      );
  }

  invokeStatusClass(opt) {
    switch (opt) {
      case 'In Review':
      case 'Factory':
        return 'bg-grey-lite';
      case 'Confirmed':
        return 'bg-amber-lite';
      case 'Approved':
        return 'bg-success-lite';
      case 'Max':
        return 'bg-grey1-lite';
      default:
        return '';
    }
  }
  prodQuarterFormat(value) {
    if (value) {
      const yearIs = String(value) || '';
      return `${yearIs.substring(4, 5)}-${yearIs.substring(0, 4)}`;
    }
    return value;
  }
  NumberSeperatedByComma(value) {
    if (!!(value % 1)) {
      const na = String(value).split('.');
      const n2 = na[1] ? `.${na[1]}` : '';
      return `${na[0]
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${n2}`;
    }
    return value.toLocaleString('en-US');
  }

  ChangeNotificationToDashboardQuery(view) {
    const { data } = this.props;
    const {
      factoryCode,
      capacityTypeCode,
      productionQuarter,
      capacityWeeks,
      factoryGroup
    } = data;
    let { divisionCode } = data;

    if (typeof divisionCode === 'string') {
      divisionCode = divisionCodes[divisionCode];
    }

    const pQ =
      productionQuarter && productionQuarter.toString().includes('-Q')
        ? productionQuarter.replace('-Q', '')
        : productionQuarter;
    let weeks = [];
    if (capacityWeeks && capacityWeeks.length > 0) {
      weeks = capacityWeeks.map(function(obj) {
        return obj.capacityId;
      });
    }

    const pqc = {
      factoryGroup,
      factoryCode,
      divisionCode,
      capacityTypeCode,
      productionQuarter: pQ
    };
    const pqcStr = qs.stringify(pqc);
    const toret = `/dashboard/${
      view === 'STRATEGIC' ? 'strategicCapacity' : 'revisedCapacity'
    }?userNotify=true&${pqcStr}&capacityIds=${weeks.join(',')}`;
    return toret;
  }

  // eslint-disable-next-line complexity
  render() {
    const { colDef, value, data, context, rowIndex } = this.props;
    const {
      maxAvailableNotes,
      shortHorizonNotes,
      logs,
      logCount,
      userUpdatesAction,
      capacityWeeks,
      previousValue,
      currentValue,
      changeValue,
      changeTime,
      readStatus,
      pending,
      maxAvailableNotesCount,
      shortHorizonNotesCount,
      maxAvailableNotesQuarterCount,
      shortHorizonNotesQuarterCount
    } = data;

    // Disable capacity weeks link button if all of the weeks are in the past
    let disableLink = false;
    if (capacityWeeks && capacityWeeks.length > 0) {
      const weeks = capacityWeeks.map(week => new Date(week.weekStartDate));
      const sortedWeeks = weeks.sort((a, b) => a.getTime() - b.getTime());

      let diff = Date.now() - sortedWeeks[sortedWeeks.length - 1].getTime();
      if (diff > 0) {
        diff /= 60 * 60 * 24 * 7 * 1000;
        if (diff >= 1) {
          disableLink = true;
        }
      }
    }

    let totalCommentLength = 0;
    let totalTimeLength = 0;
    const defaultStatus = context.componentParent
      ? context.componentParent.state.defaultStatus
      : '';
    const isToggleOn = context.componentParent
      ? context.componentParent.state.isToggleOn
      : false;
    if (defaultStatus === 'MaxAvailable') {
      totalCommentLength = maxAvailableNotes ? maxAvailableNotes.length : 0;
    } else {
      totalCommentLength = shortHorizonNotes ? shortHorizonNotes.length : 0;
    }

    if (logs) {
      totalTimeLength = logs ? logs.length : 0;
    }
    if (defaultStatus === 'MaxAvailable') {
      totalCommentLength = maxAvailableNotesCount ? maxAvailableNotesCount : 0;
    } else {
      totalCommentLength = shortHorizonNotesCount ? shortHorizonNotesCount : 0;
    }
    if (isToggleOn) {
      if (defaultStatus === 'MaxAvailable') {
        totalCommentLength = maxAvailableNotesQuarterCount
          ? maxAvailableNotesQuarterCount
          : 0;
      } else {
        totalCommentLength = shortHorizonNotesQuarterCount
          ? shortHorizonNotesQuarterCount
          : 0;
      }
    }

    if (logCount) {
      totalTimeLength = logCount ? logCount : 0;
    }

    return (
      <span>
        {colDef.field === 'creationDate' && (
          <span>
            {value} {data.time && <span>{data.time} </span>}
          </span>
        )}

        {colDef.field === 'shortHorizonNotes' ||
        colDef.field === 'maxAvailableNotes' ||
        colDef.field === 'maxAvailableNotesCount' ||
        colDef.field === 'shortHorizonNotesCount' ? (
          <span>
            <button
              onClick={() => this.invokeParentMethod(colDef.field)}
              className={`ncss-btn-sm-sp  ${
                Number(totalCommentLength) > 0
                  ? 'ncss-btn-accent'
                  : 'ncss-btn-secondary'
              }`}
            >
              <i className="fas fa-comments" /> {Number(totalCommentLength)}
            </button>
            <button
              onClick={() => this.invokeParentMethod('time')}
              className={`ncss-btn-sm-sp  ${
                Number(totalTimeLength) > 0
                  ? 'ncss-btn-accent'
                  : 'ncss-btn-secondary'
              }`}
            >
              <i className="far fa-list-alt" /> {Number(totalTimeLength)}
            </button>
          </span>
        ) : (
          <span />
        )}

        {colDef.field === 'userUpdatesCheck' && (
          <span onClick={() => this.invokeParentMethod(colDef.field)}>
            &nbsp;
          </span>
        )}

        {colDef.field === 'userUpdatesFlag' && (
          <span
            className={`userUpdatesFlag
            ${readStatus} 
            ${!pending ? 'noPending' : ''} u-cursor-pointer  ${
              userUpdatesAction ? 'd-' : 'text-color-accent'
            }`}
          >
            <span className="noShowUI">{rowIndex}</span>
            <span className="create-icon-svg d-sm-b">
              <svg
                width="12"
                height="12"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid meet"
                fill="currentColor"
              >
                <g>
                  <polygon
                    stroke="null"
                    id="svg_1"
                    points="10.623931646347046,0 10.623931646347046,6.262124061584473 2.623671770095825,6.262124061584473 2.623671770095825,11.89011001586914 1.376068115234375,11.89011001586914 1.376068115234375,0 "
                  />
                </g>
              </svg>
            </span>
          </span>
        )}
        {colDef.field === 'productionQuarter' && (
          <span className="cellLineBox u-cursor-pointer">
            <button
              onClick={() => this.onProductionQuarterClick()}
              style={{ backgroundColor: 'white' }}
              disabled={disableLink}
            >
              <span
                className={
                  disableLink
                    ? 'text-color-grey1'
                    : ' text-color-blue1 hover-underline '
                }
              >
                <span className="u-bold">{value}</span>
                <br />
                {capacityWeeks && capacityWeeks.length > 0 && (
                  <span className="small d-block clear-fix">
                    {capacityWeeks.length} weeks
                  </span>
                )}
              </span>
            </button>
          </span>
        )}
        {colDef.field === 'changeType' && value && (
          <span className={`cellLineBox`}>
            <span className={`cellStatusBox ${this.invokeStatusClass(value)}`}>
              {value}
            </span>
          </span>
        )}
        {colDef.field === 'delta' && (
          <span className={`cellLineBox`}>
            <span>
              <span className="u-bold">
                {previousValue
                  ? this.NumberSeperatedByComma(previousValue)
                  : previousValue}
                <i className="fas fa-arrow-right"></i>
                {currentValue
                  ? this.NumberSeperatedByComma(currentValue)
                  : currentValue}
              </span>
              <span className="text-color-secondary">
                {value}
                {'% '}(
                {changeValue ? this.NumberSeperatedByComma(changeValue) : 0})
              </span>
            </span>
          </span>
        )}
        {colDef.field === 'lastModified' && value && (
          <span className={`cellLineBox`}>
            <span>{changeTime}</span>
          </span>
        )}
      </span>
    );
  }
}
CellLinkRenderer.propTypes = {
  context: PropTypes.any,
  node: PropTypes.any,
  colDef: PropTypes.any,
  value: PropTypes.any,
  data: PropTypes.any
};
