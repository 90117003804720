import { addNotification, notificationRemove, requestNotification } from '../actions/notificationActions.js';

import { put, takeEvery, delay } from 'redux-saga/effects';

let notificationId = 0;

export function* notify(action) {
  const { message, closeOnClick = true, type = 'success', timeoutMs = 3000 } = action.payload;
  notificationId += 1;
  // create closed value so other notifications' incrementing doesn't interfere with timeout
  const id = notificationId;
  yield put(addNotification({ message, type, closeOnClick, id }));

  if (timeoutMs !== 0) {
    yield delay(timeoutMs);
    yield put(notificationRemove(id));
  }

  return id;
}

export function* notifyError(action) {
  const { message } = action.payload;
  yield put(requestNotification({ message, closeOnClick: true, type: 'error', timeoutMs: 0 }));
}

function* notificationSagas() {
  yield takeEvery('NOTIFICATION_REQUESTED', notify);
  yield takeEvery('NOTIFICATION_ERROR_REQUESTED', notifyError);
}

export default notificationSagas;
