import { createAction } from '../util/actionHelper.js';

export const navMenuToggle = createAction('NAVIGATION_MENU_TOGGLE', {
  noPayload: true
});
export const navMenuclose = createAction('NAVIGATION_MENU_CLOSE', {
  noPayload: true
});
export const openNavMenu = createAction('NAVIGATION_MENU_OPEN', {
  noPayload: true
});
