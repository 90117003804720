import { numberWithCommas } from '../../util/general.js';

const commonCellBorder = {
  color: '#9a9a9a',
  lineStyle: 'Continuous',
  weight: 1
};
const commonWhiteBorder = {
  color: '#ffffff',
  lineStyle: 'Continuous',
  weight: 1
};
const growthLeverConstants = {
  gridOptions: {
    groupHeaderHeight: 36,
    headerHeight: 40,
    rowHeight: 40,
    enableRowGroup: false,
    enablePivot: false,
    enableBrowserTooltips: false,
    suppressClipboardPaste: true,
    enableRangeSelection: true,
    enableCellTextSelection: true
  },
  sideBar: {
    width: 300,
    toolPanels: [
      {
        id: 'columns',
        width: 300,
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true
        }
      }
    ],
    defaultToolPanel: 'columns'
  },
  columnDefs: [
    {
      headerName: ' ',
      field: 'indicator',
      headerComponentParams: { displaySort: false },
      width: 60,
      suppressMenu: true,
      editable: false,
      filter: false,
      cellRenderer: 'LeversMinimalCustomCell',
      isAP: true,
      isFW: true
    },
    {
      headerName: 'Prod Qtr',
      field: 'productionQuarter',
      headerComponentParams: { displaySort: true },
      width: 150,
      suppressMenu: true,
      editable: false,
      filter: 'agSetColumnFilter',
      cellRenderer: 'LeversMinimalCustomCell',
      valueFormatter: params => {
        const yearIs = String(params.value) || '';
        if (params.value) {
          return `${yearIs.substring(0, 4)}-Q${yearIs.substring(4, 5)}`;
        }
        return params.value;
      },
      isAP: true,
      isFW: true
    },
    {
      headerName: 'Factory Code',
      field: 'factoryCode',
      headerComponentParams: { displaySort: true },
      width: 150,
      suppressMenu: true,
      editable: false,
      filter: 'agTextColumnFilter',
      isAP: true,
      isFW: true
    },
    {
      headerName: 'Cpty Type Code',
      field: 'capacityTypeCode',
      headerComponentParams: { displaySort: true },
      width: 180,
      suppressMenu: true,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: { selectAllOnMiniFilter: true },
      isAP: true,
      isFW: true
    },
    {
      headerName: 'Capacity Type Desc',
      field: 'capacityTypeDescription',
      headerComponentParams: { displaySort: true },
      width: 180,
      suppressMenu: true,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: { selectAllOnMiniFilter: true },
      isAP: true,
      isFW: true
    },
    {
      headerName: 'Fcty Max Cpty',
      field: 'FctyMaxCapacity',
      headerComponentParams: { displaySort: true },
      width: 160,
      suppressMenu: true,
      editable: false,
      filter: 'agNumberColumnFilter',
      isAP: true,
      isFW: true,
      valueFormatter: params => {
        if (params.value) {
          return numberWithCommas(params.value);
        }
        return params.value;
      }
    },

    {
      headerName: 'Adjd Fcty Max Cpty',
      tooltipField: 'Adjd Fcty Max Cpty',
      field: 'FctyMaxHldCpty',
      headerComponentParams: { displaySort: true },
      width: 130,
      suppressMenu: true,
      editable: true,
      filter: 'agNumberColumnFilter',
      isAP: false,
      isFW: false,
      valueFormatter: params => {
        if (params.value) {
          return numberWithCommas(params.value);
        }
        return params.value;
      }
    },
    {
      headerName: 'Max Reason Code',
      field: 'maxCapacityReasonCode',
      headerComponentParams: { displaySort: true },
      width: 270,
      suppressMenu: true,
      editable: false,
      filter: 'agNumberColumnFilter',
      isAP: true,
      isFW: true
    },
    {
      headerName: 'Max Cpty Var',
      field: 'GbdMaxCapVariance',
      headerComponentParams: {
        displaySort: true,
        subTitle: 'Max - Strat = Variance'
      },
      width: 275,
      editable: false,
      filter: 'agTextColumnFilter',
      cellRenderer: 'LeversMinimalCustomCell',
      suppressMenu: true,
      isAP: false,
      isFW: true
    },
    {
      headerName: 'Adjd Max Cpty Var',
      field: 'GbdMaxCapAdjVariance',
      headerComponentParams: {
        displaySort: true,
        subTitle: 'Adjd Max - Adjd Strat = Variance'
      },
      width: 300,
      editable: false,
      cellRenderer: 'LeversMinimalCustomCell',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      isAP: true,
      isFW: false
    },
    {
      headerName: 'Fcty Cpty QoQ Var',
      field: 'CapQMaxCapVariance',
      headerComponentParams: {
        displaySort: true,
        subTitle: 'Current - Prev = Variance'
      },
      width: 275,
      editable: false,
      cellRenderer: 'LeversMinimalCustomCell',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      isAP: false,
      isFW: true
    },
    {
      headerName: 'Adjd Fcty Cpty QoQ Var',
      field: 'CapQMaxCapAdjVariance',
      headerComponentParams: {
        displaySort: true,
        subTitle: 'Adjd Current - Adjd Prev = Adjd Variance'
      },
      width: 305,
      editable: false,
      cellRenderer: 'LeversMinimalCustomCell',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      isAP: true,
      isFW: false
    },
    {
      headerName: 'Max Cpty QoQ Var',
      field: 'MaxQMaxCapVariance',
      headerComponentParams: {
        displaySort: true,
        subTitle: 'Current - Prev = Variance'
      },
      width: 275,
      editable: false,
      cellRenderer: 'LeversMinimalCustomCell',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      isAP: false,
      isFW: true
    },
    {
      headerName: 'Adjd Max Cpty QoQ Var',
      field: 'MaxQMaxCapAdjVariance',
      headerComponentParams: {
        displaySort: true,
        subTitle: 'Adjd Current - Adjd Prev = Adjd Variance'
      },
      width: 305,
      editable: false,
      cellRenderer: 'LeversMinimalCustomCell',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      isAP: true,
      isFW: false
    },
    {
      headerName: 'id',
      field: 'id',
      width: 1,
      editable: false,
      cellClass: 'whiteText',
      filter: false,
      suppressMenu: true,
      isAP: true,
      isFW: true,
      cellStyle: { width: 10, opacity: 0, padding: 0 },
      lockVisible: true,
      unSortIcon: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true
      }
    }
  ],
  logHeader: [{}],
  excelStyles: [
    {
      id: 'greenBackground',
      interior: {
        color: '#b5e6b5',
        pattern: 'Solid'
      }
    },

    {
      id: 'header',
      interior: {
        color: '#000000',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff'
      }
    },
    {
      id: 'editableColumn',
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'nonEditableColumn',

      alignment: {
        wrapText: true
      },
      protection: {
        protected: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'footerContent',

      alignment: {
        wrapText: true
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'whiteText',

      font: {
        color: '#969696',
        size: 10
      },
      interior: {
        color: '#d4d0d0',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableGreyColumn',
      interior: {
        color: '#d4d0d0',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableYellowColumn',
      interior: {
        color: '#FFFFCC',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'editableRedColumn',
      interior: {
        color: '#f9baba',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'titleHeader',
      interior: {
        color: '#808080',
        pattern: 'Solid'
      },
      alignment: {
        wrapText: true
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 12
      },
      protection: {
        protected: true,
        hideFormula: true
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'headGreyCloud',
      interior: {
        color: '#aeaaaa',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headLapisBlue',
      interior: {
        color: '#203763',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headBlueEyes',
      interior: {
        color: '#0372c3',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },

    {
      id: 'headViolet',
      interior: {
        color: '#4a71b7',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headSapphireBlue',
      interior: {
        color: '#0372c3',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'headSilkBlue',
      interior: {
        color: '#8faad9',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'editableColHeader',
      interior: {
        color: '#62a57e',
        pattern: 'Solid'
      },
      font: {
        fontName: 'Arial Narrow',
        color: '#ffffff',
        size: 11
      }
    },
    {
      id: 'GreyCloud',
      interior: {
        color: '#efefef',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'LapisBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'BlueEyes',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'Violet',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'SapphireBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'SilkBlue',
      interior: {
        color: '#ffffff',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonWhiteBorder
      }
    },
    {
      id: 'editableCol',
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'dateEditabeCol',
      numberFormat: {
        format: 'mm/dd/yyyy'
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'numberEditabeCol',
      numberFormat: {
        format: '0'
      },
      interior: {
        color: '#ebf3df',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    },
    {
      id: 'peachOrange',
      interior: {
        color: '#ffcc99',
        pattern: 'Solid'
      },
      borders: {
        borderBottom: commonCellBorder,
        borderLeft: commonCellBorder,
        borderRight: commonCellBorder,
        borderTop: commonCellBorder
      }
    }
  ]
};

export default growthLeverConstants;
