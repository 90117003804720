import React from 'react';
import PropTypes from 'prop-types';

const SingleInput = ({
  title,
  className,
  name,
  required,
  inputType,
  content,
  controlFunc,
  placeholder,
  disabled,
  style
}) => (
  <div className="form-group">
    <div className="form-label">
      {title}
      {required && !disabled ? <span style={{ color: 'red' }}>*</span> : null}
    </div>
    <input
      className={className}
      name={name}
      type={inputType || 'text'}
      value={content}
      onChange={controlFunc}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
    />
  </div>
);

SingleInput.propTypes = {
  inputType: PropTypes.any,
  title: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  controlFunc: PropTypes.func,
  content: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default SingleInput;
