import * as moment from 'moment';
const dashBoardConstants = {
  PageTitle: ' DASHBOARD',
  factoryUserPageTitle: 'SHORT DASHBOARD',
  nikeUserPageTitle: 'NIKE DASHBOARD',
  downloadButton: 'DOWNLOAD',
  uploadButton: 'UPLOAD',
  saveInfoButton: 'SAVE INFO',
  savePreferenceButton: 'SAVE PREFERENCE',
  logHeaderGridDefault: {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true
  },
  logHeader: [
    {
      headerName: 'Column Changed',
      field: 'change',
      tooltipField: 'change',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 160
    },
    {
      headerName: 'Value ',
      field: 'value',
      tooltipField: 'value',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 190
    },
    {
      headerName: 'User',
      field: 'user',
      tooltipField: 'user',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      valueFormatter(params) {
        if (params.value) {
          return params.value.split('@')[0];
        }
        return params.value;
      },
      width: 190
    },
    {
      headerName: 'Date Time',
      field: 'time',
      tooltipField: 'timestamp',
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 190,
      filterParams: {
        comparator(filterLocalDateAtMidnight, cellValue) {
          const dateAsString = cellValue;
          if (dateAsString == null) return -1;
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[0]) - 1,
            Number(dateParts[1])
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true
      },
      valueFormatter(params) {
        if (params.data.timestamp) {
          const date = new Date(params.data.timestamp);
          return `${date.getMonth() +
            1}/${date.getDate()}/${date.getFullYear()} 
                  ${date.toLocaleTimeString()}`;
        }
        return params.value;
      }
    }
  ],

  pqLogHeader: [
    {
      headerName: 'Column Changed',
      field: 'change',
      tooltipField: 'change',
      filter: 'agSetColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 160
    },
    {
      headerName: 'Value ',
      field: 'value',
      tooltipField: 'value',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 190
    },
    {
      headerName: 'Week Start Date',
      field: 'weekStartDate',
      tooltipField: '',
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 160,
      filterParams: {
        comparator(filterLocalDateAtMidnight, cellValue) {
          const dateAsString = cellValue;
          if (dateAsString == null) return -1;
          const momentdate = moment(cellValue).format('MM/DD/YYYY');
          const cellDate = Date.parse(momentdate);
          const filterdate = Date.parse(filterLocalDateAtMidnight);
          if (filterdate === cellDate) {
            return 0;
          }
          if (cellDate < filterdate) {
            return -1;
          }
          if (cellDate > filterdate) {
            return 1;
          }
        },
        browserDatePicker: true
      },
      valueFormatter: params => moment(params.value).format('MM/DD/YYYY')
    },
    {
      headerName: 'Changed In',
      field: 'isQuarterly',
      tooltipField: 'changedAt',
      filter: 'agSetColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 160,
      valueFormatter: (params) => {
        return params.value === true || params.value === 'true'
          ? 'QUARTER VIEW'
          : 'WEEK VIEW';
      }
    },
    {
      headerName: 'User',
      field: 'user',
      tooltipField: 'user',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      valueFormatter(params) {
        if (params.value) {
          return params.value.split('@')[0];
        }
        return params.value;
      },
      width: 190
    },
    {
      headerName: 'Date Time',
      field: 'time',
      tooltipField: 'timestamp',
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      headerClass: 'litegrey',
      width: 190,
      filterParams: {
        comparator(filterLocalDateAtMidnight, cellValue) {
          const dateAsString = cellValue;
          if (dateAsString == null) return -1;
          const dateParts = dateAsString.split('/');
          const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[0]) - 1,
            Number(dateParts[1])
          );
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        browserDatePicker: true
      },
      valueFormatter(params) {
        if (params.data.timestamp) {
          const date = new Date(params.data.timestamp);
          return `${date.getMonth() +
            1}/${date.getDate()}/${date.getFullYear()} 
                  ${date.toLocaleTimeString()}`;
        }
        return params.value;
      }
    }
  ],
  scoreCardCount: [
    {
      name: 'Reviewed',
      icon: 'far fa-edit',
      id: 0,
      count: 0,
      statusCode: 'In Review'
    },
    {
      name: 'Confirmed',
      icon: 'fas fa-check',
      id: 1,
      count: 0,
      statusCode: 'Confirmed'
    },
    {
      name: 'Approved',
      icon: 'fas fa-check-double text-color-success',
      id: 2,
      count: 0,
      statusCode: 'Approved'
    },
    {
      name: 'In View',
      icon: 'fas fa-eye',
      id: 4,
      count: 0,
      statusCode: ''
    },
    {
      name: 'Total',
      icon: 'fas fa-th',
      id: 5,
      count: 0,
      statusCode: ''
    }
  ],
  sampleLogItem: {
    user: '',
    id: 0,
    field: 'Status',
    value: 'In Review'
  },
  sampleCommentItem: {
    user: '',
    id: 0,
    content: 'Some text content'
  },
  excelHeader: [
    [
      {
        styleId: 'titleHeader',
        data: {
          type: 'String',
          value: 'FILE ID'
        }
      },
      {
        styleId: 'titleHeader',
        mergeAcross: 5,
        data: {
          type: 'String',
          value: ''
        }
      }
    ],

    [
      {
        styleId: 'titleHeader',
        data: {
          type: 'String',
          value: 'NOTE '
        }
      },
      {
        styleId: 'titleHeader',
        mergeAcross: 5,
        data: {
          type: 'String',
          value: 'DO NOT MODIFY THE ABOVE FILE ID WHILE UPLOAD'
        }
      }
    ],
    []
  ],
  gridWidth: [
    {
      field: 'factoryCode',
      width: 80
    },
    {
      field: 'factoryDescription',
      width: 190
    },
    {
      field: 'capacityTypeCode',
      width: 120
    },
    {
      field: 'capacityTypeDescription',
      width: 120
    },
    {
      field: 'capacityTypeClass',
      width: 100
    },
    {
      field: 'weekStartDate',
      width: 180
    },
    {
      field: 'weeklyCapacityQuantity',
      width: 120
    },
    {
      field: 'adjustedCapacityQuantity',
      width: 120
    },
    {
      field: 'maxAvailableCapacity',
      width: 130
    },
    {
      field: 'maxAvailableDelta',
      width: 100
    },
    {
      field: 'maxAvailableReasonCode',
      width: 200
    },
    {
      field: 'maxAvailableStatus',
      width: 90
    },
    {
      field: 'maxAvailableUnitsConfirmed',
      width: 100
    },
    {
      field: 'maxAvailableConfirmedBy',
      width: 150
    },
    {
      field: 'approvedMaxCapacity',
      width: 120
    },
    {
      field: 'maxAvailableApprovedBy',
      width: 150
    },
    {
      field: 'maxAvailableNotes',
      width: 160
    },
    {
      field: 'id',
      width: 1
    },
    {
      field: 'weeklyRevisedCapacityQuantity',
      width: 80
    },
    {
      field: 'shortHorizonCapacity',
      width: 120
    },
    {
      field: 'shortHorizonDelta',
      width: 100
    },
    {
      field: 'shortHorizonReasonCode',
      width: 200
    },
    {
      field: 'shortHorizonStatus',
      width: 90
    },
    {
      field: 'shortHorizonUnitsConfirmed',
      width: 90
    },
    {
      field: 'shortHorizonConfirmedBy',
      width: 150
    },
    {
      field: 'approvedRevisedCapacity',
      width: 110
    },
    {
      field: 'shortHorizonApprovedBy',
      width: 150
    },
    {
      field: 'shortHorizonNotes',
      width: 160
    },
    {
      field: 'maxCapacityReasonCode',
      width: 200
    }
  ],
  quarterScoreCardCount: [
    {
      name: 'Reviewed',
      icon: 'far fa-edit',
      id: 0,
      count: 0,
      statusCode: 'In Review'
    },
    {
      name: 'Confirmed',
      icon: 'fas fa-check',
      id: 1,
      count: 0,
      statusCode: 'Confirmed'
    },
    {
      name: 'Approved',
      icon: 'fas fa-check-double text-color-success',
      id: 2,
      count: 0,
      statusCode: 'Approved'
    },
    {
      name: 'In View',
      icon: 'fas fa-eye',
      id: 4,
      count: 0,
      statusCode: ''
    },
    {
      name: 'Total',
      icon: 'fas fa-th',
      id: 5,
      count: 0,
      statusCode: ''
    }
  ]
};

export default dashBoardConstants;
