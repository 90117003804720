import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import PropTypes from 'prop-types';
import appHistory from '../util/history.js';
import UserProfileSettings from '../views/userProfileSettings/userProfileSettings.js';
import LandingPage from '../views/landingPage/landingPage.js';
import AboutPage from '../views/about/AboutPage.js';
import Admin from '../views/adminCapacity/CapacityAdmin.js';
import Dashboard from '../views/dashboard/Dashboard.js';
import LeverAdmin from '../views/adminLever/LeverAdmin.js';
import LrcpAdmin from '../views/adminLrcp/LrcpAdmin.js';
import uploadView from '../views/dashboard/uploadView.js';
import { InvalidRoute } from '../components/invalid_route/InvalidRoute.js';
import UserUpdates from '../views/user-updates/UserUpdates.js';
import UserProfileFeatures from '../views/userProfileFeatures/userProfileFeatures.js';
import GrowthbyDimension from '../views/growthDimension/growthDimension.js';
import GrowthLever from '../views/growthLever/growthLever.js';
import growthLeverQuarter from '../views/growthLeverQuarter/growthLeverQuarter.js';
import {
  ProfileStroke,
  GridView,
  Notification,
  TrendingUp,
  InfoCircle,
  QuestionCircle,
  ShopSNKRS
} from '@nike/nike-design-system-icons';

export const routes = [
  {
    path: '/profile/settings',
    title: 'My Profile',
    component: UserProfileSettings,
    nav: true,
    menu: true,
    icon: <ProfileStroke size="s" className="mr3-sm" />,
    heading: 'MYSTUFF',
    id: 3
  },
  {
    path: '/profile/features',
    title: 'New Features',
    component: UserProfileFeatures,
    nav: true,
    menu: false,
    icon: 'fas fa-list-alt',
    heading: '',
    id: 1
  },
  {
    path: '/new',
    title: 'New Features',
    component: UserProfileFeatures,
    nav: true,
    menu: false,
    icon: 'fas fa-list-alt',
    heading: '',
    id: 23232
  },
  {
    path: '/',
    title: 'HOMEPAGE',
    component: LandingPage,
    nav: true,
    menu: false,
    icon: 'fas fa-list-alt',
    heading: '',
    id: 1
  },
  {
    path: '/dashboard/strategicCapacity',
    title: 'Strategic Capacity View',
    component: Dashboard,
    nav: true,
    menu: true,
    icon: <GridView size="s" className="mr3-sm" />,
    heading: 'STRATEGIC DASHBOARD',
    id: 11,
    displayCapSearch: true
  },
  {
    path: '/upload/strategicCapacity',
    title: 'STRATEGIC CAPACITY ',
    component: uploadView,
    nav: true,
    menu: false,
    icon: 'fas fa-table',
    id: 21,
    displayCapSearch: true
  },
  {
    path: '/user-updates/strategicCapacity',
    title: 'Notification Changes ',
    component: UserUpdates,
    icon: <Notification size="s" className="mr3-sm" />,
    nav: true,
    menu: true,
    id: 331,
    displayCapSearch: true
  },
  {
    path: '/lever-quarter/strategicCapacity',
    title: 'Strategic Capacity View',
    component: growthLeverQuarter,
    nav: true,
    menu: false,
    icon: 'fas fa-list-alt',
    heading: 'Lever',
    displayCapSearch: true,
    id: 232
  },
  {
    path: '/growth-dimension/strategicCapacity',
    title: 'Capacity Levers',
    component: GrowthbyDimension,
    icon: '  fas fa-chart-line ',
    nav: true,
    menu: false,
    featureControl: 'lever',
    id: 331,
    displayCapSearch: true
  },
  {
    path: '/levers/strategicCapacity',
    title: 'Capacity Levers',
    component: GrowthLever,
    icon: <TrendingUp size="s" className="mr3-sm" />,
    nav: true,
    menu: true,
    featureControl: 'lever',
    id: 332,
    displayCapSearch: true
  },
  {
    path: '/dashboard/revisedCapacity',
    title: 'Revised Capacity View',
    component: Dashboard,
    nav: true,
    menu: true,
    icon: <GridView size="s" className="mr3-sm" />,
    heading: 'REVISED DASHBOARD',
    id: 12,
    displayCapSearch: true
  },
  {
    path: '/upload/revisedCapacity',
    title: 'Revised Capacity  Bulk Upload  ',
    component: uploadView,
    nav: true,
    menu: false,
    icon: 'far fa-list-alt',
    id: 22,
    displayCapSearch: true
  },

  {
    path: '/user-updates/revisedCapacity',
    title: 'Notification Changes',
    component: UserUpdates,
    icon: <Notification size="s" className="mr3-sm" />,
    nav: true,
    menu: true,
    id: 332,
    displayCapSearch: true
  },
  {
    path: '/lever-quarter/revisedCapacity',
    title: 'Strategic Capacity View',
    component: growthLeverQuarter,
    nav: true,
    menu: false,
    icon: 'fas fa-list-alt',
    heading: 'Lever',
    displayCapSearch: true,
    id: 233
  },
  {
    path: '/growth-dimension/revisedCapacity',
    title: 'Capacity Levers',
    component: GrowthbyDimension,
    icon: '  fas fa-chart-line ',
    nav: true,
    menu: false,
    featureControl: 'lever',
    id: 333,
    displayCapSearch: true
  },
  {
    path: '/levers/revisedCapacity',
    title: 'Capacity Levers',
    component: GrowthLever,
    icon: <TrendingUp size="s" className="mr3-sm" />,
    nav: true,
    menu: true,
    featureControl: 'lever',
    id: 334,
    displayCapSearch: true
  },
  {
    path: '/about',
    title: 'About',
    component: AboutPage,
    nav: true,
    menu: true,
    icon: <InfoCircle size="s" className="mr3-sm" />,
    heading: 'INFO',
    id: 41
  },
  {
    path: '/admin/manageLevers/:DC',
    title: 'Apparel : AP Levers',
    component: LeverAdmin,
    nav: true,
    menu: false,
    icon: 'no app-shoe-icon app-menu-icon',
    heading: '',
    id: 8004
  },
  {
    path: '/admin/manageLrcp/:DC',
    title: 'Apparel : AP LRCP',
    component: LrcpAdmin,
    nav: true,
    menu: false,
    icon: 'no app-shoe-icon app-menu-icon',
    heading: '',
    id: 8005
  },
  {
    path: '',
    component: Admin,
    nav: true,
    title: '',
    menu: true,
    heading: 'MANAGEMENT',
    id: 500
  },
  {
    path: '/admin/manageView/AP',
    title: 'Apparel : AP Admin  ',
    component: Admin,
    nav: true,
    menu: true,
    icon: <ProfileStroke size="s" className="mr3-sm" />,
    heading: '',
    id: 5001
  },
  {
    path: '/admin/manageView/FW',
    title: 'Footwear : FW Admin',
    component: Admin,
    nav: true,
    menu: true,
    icon: <ShopSNKRS size="s" className="mr3-sm" />,
    heading: '',
    id: 5002
  },
  {
    path: '/help',
    title: 'Get Help',
    component: null,
    nav: false,
    menu: false,
    icon: 'fas fa-question-circle',
    heading: 'Support',
    id: 31,
    url: 'mailto:CapacityVizSupport@nike.com'
  },

  {
    path: '',
    title: 'Raise Issue',
    component: null,
    nav: true,
    menu: true,
    icon: <QuestionCircle size="s" className="mr3-sm" />,
    heading: 'Support',
    id: 32,
    url: 'https://niketech.service-now.com/nikenow'
  },
  { component: InvalidRoute, id: 12, nav: false, menu: false }
];

export const navRoutes = routes;

const Routes = ({ component, history = appHistory }) => {
  const Component = component;
  return (
    <ConnectedRouter history={history}>
      <Component>
        <Switch>
          {routes.map(route => (
            <Route
              key={route.id}
              path={route.path}
              exact
              component={route.component}
            />
          ))}
        </Switch>
      </Component>
    </ConnectedRouter>
  );
};

Routes.propTypes = {
  component: PropTypes.func,
  history: PropTypes.func
};
export default Routes;
